import { createSlice } from "@reduxjs/toolkit";

export const userDetailsSlice = createSlice({
    name: "userDetails",
    initialState: {
        isUserLoggedIn: false,
        customerDetailsObj: {
            basicDetails: {},
            moreDetails: {}
        },
        proImage: null
    },
    reducers: {
        setUserLoggedInTrue: (state) => {
            state.isUserLoggedIn = true;
        },
        setUserLoggedInFalse: (state) => {
            state.isUserLoggedIn = false;
        },
        setCustomerDetails: (state, action) => {
            state.customerDetailsObj = { ...state.customerDetailsObj, ...action.payload }
        },
    
        setProImage: (state, action) => {
            state.proImage = action.payload;
        },
    }
});

export const { setUserLoggedInTrue, setUserLoggedInFalse, setCustomerDetails, setProImage } = userDetailsSlice.actions;    // exporting reducer funtion

export const isUserLoggedIn = state => state.userDetails.isUserLoggedIn;  // exporting redux state
export const customerDetails = state => state.userDetails.customerDetailsObj;
export const proImage = state => state.userDetails.proImage;

export default userDetailsSlice.reducer;