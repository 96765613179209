import React, { useEffect, useState } from "react";
import axios from 'axios';
import { MW_URL } from "../../config";
import LoadingAnimation from '../../utils/loadingAnimation'

const DashboardScreen = () => {

    const [height, setHeight] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [allStudents, setAllStudents] = useState(0);
    const [allCounsellors, setAllCounsellors] = useState(0);
    const [allColleges, setAllColleges] = useState(0);

    useEffect(() => {
        console.log("1212")
        var windowHeight = window.innerHeight;
        setHeight(windowHeight + "px");
        axios.post(`${MW_URL}` + "users", {})
            .then(getUsersResponse => {
                var allStudents = 0;
                var allCounsellors = 0;
                var allColleges = 0;
                if (getUsersResponse && getUsersResponse.data && getUsersResponse.data[0]) {
                    for (var i = 0; i < getUsersResponse.data.length; i++) {
                        var currentObj = getUsersResponse.data[i];
                        if (currentObj.userType === "student") {
                            allStudents += 1;
                        }
                        if (currentObj.userType === "counsellor") {
                            allCounsellors += 1;
                        }
                    }
                    axios.post(`${MW_URL}` + "getAllCollegeDetails", {})
                        .then(getCollegeDetailsResponse => {
                            setTimeout(() => {
                                setIsLoading(false)
                            }, 500);
                            if (getCollegeDetailsResponse && getCollegeDetailsResponse.data && getCollegeDetailsResponse.data[0]) {
                                allColleges = getCollegeDetailsResponse.data.length;
                            }
                            setAllColleges(allColleges)
                        })
                        .catch(err => {
                            console.log("err >> ", err);
                        })
                    setAllStudents(allStudents)
                    setAllCounsellors(allCounsellors)
                }
            })
            .catch(err => {
                console.log("err >> ", err);
            })
    })


    return (
        <div>
            {isLoading ? <LoadingAnimation isVisible={true} /> : null}
            <div className="col-md-12 px-5 dashboard-page-container d-flex flex-column text-center justify-content-center align-items-center" style={{ minHeight: height }}>
                <div className="col-md-12 px-3">
                    <div className="col-md-12 row m-0">
                        <div className="col-md-6">
                            <div className="display-box mx-auto mb-5 d-flex">
                                <div className="col-md-7 text-left py-3">
                                    <div className="small-text">Total</div>
                                    <div className="big-text">Students</div>
                                </div>
                                <div className="col-md-5 py-3">
                                    <div className="number-box col-md-12 p-0">{parseInt(allStudents)}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="display-box mx-auto mb-5 d-flex">
                                <div className="col-md-7 text-left py-3">
                                    <div className="small-text">Total</div>
                                    <div className="big-text">Counsellors</div>
                                </div>
                                <div className="col-md-5 py-3">
                                    <div className="number-box col-md-12 p-0">{parseInt(allCounsellors)}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="display-box mx-auto mb-5 d-flex">
                                <div className="col-md-7 text-left py-3">
                                    <div className="small-text">Total</div>
                                    <div className="big-text">Countries</div>
                                </div>
                                <div className="col-md-5 py-3">
                                    <div className="number-box col-md-12 p-0">8</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="display-box mx-auto mb-5 d-flex">
                                <div className="col-md-7 text-left py-3">
                                    <div className="small-text">Total</div>
                                    <div className="big-text">Universities</div>
                                </div>
                                <div className="col-md-5 py-3">
                                    <div className="number-box col-md-12 p-0">{parseInt(allColleges)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardScreen;