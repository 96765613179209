import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import {AiOutlineCloseCircle} from "react-icons/ai"
import AddLogoImg from '../../../../assets1/images/add-logo.jpg'



const CompareModal = ({setOpenCompareModal, selectColToCompare}) => {
    console.log(selectColToCompare, "selectColToCompare")
  return (
    <div className='compareModal'>
        <Row>
            <Col sm={1} className=''>       
                <AiOutlineCloseCircle onClick={() => setOpenCompareModal(false)} className='compareModalCloseBtn' />
            </Col>
            <Col lg={11}>
                <Row className='mr-3' >
                    {selectColToCompare?.map((obj, index) => (
                        <Col key={index} xs={12} md={3} >
                            <div className='compareWrap text-center'>
                                <div className='my-3'>
                                    {obj.logo? (<img width={50} height={50} src={obj.logo} alt='logo' />)
                                        :(<img width={50} height={50} src={AddLogoImg} alt="logo" />
                                    )}
                                    <div className='compareNameWrap'>
                                        <p className='statHead'>{obj.name}</p>
                                    </div>
                                    <button className='compareChangeBtn'>Change</button>
                                </div>
                                <Row className=''>
                                    <Col>
                                        <p className='colLightText mb-1'>Before AID</p>
                                        <p className='statText '>${obj?.tutionOutOfState}</p>
                                    </Col>
                                    <Col>
                                        <p className='colLightText mb-1'>After AID</p>
                                        <p className='statText '>$ {obj?.tutionInState}</p>
                                    </Col>
                                </Row>
                                <div className='border-top border-bottom'>
                                    <p className='compareText1 m-1 '>Acceptance Rate 2019</p>
                                    <p className='compareText2'>8%</p>
                                </div>
                                <div>
                                    <p className='compareText1 m-1 '>Graduation Rate 2019</p>
                                    <p className='compareText2'>98%</p>
                                </div>
                            </div>

                        </Col>
                    ))} 

                    
                    {/* <Col sx={12} className='compareWrap text-center mr-3'>
                        <div className='my-3'>
                            <img width={50} height={50} src='https://upload.wikimedia.org/wikipedia/en/thumb/b/bb/Carnegie_Mellon_University_seal.svg/1200px-Carnegie_Mellon_University_seal.svg.png' alt='logo' />
                            <p className='statHead'>College Name</p>
                            <button className='compareChangeBtn'>Change</button>
                        </div>
                        <Row className=''>
                            <Col>
                                <p className='colLightText mb-1'>Before AID</p>
                                <p className='statText '>$77,490</p>
                            </Col>
                            <Col>
                                <p className='colLightText mb-1'>After AID</p>
                                <p className='statText '>$38,160</p>
                            </Col>
                        </Row>
                        <div className='border-top border-bottom'>
                            <p className='compareText1 m-1 '>Acceptance Rate 2019</p>
                            <p className='compareText2'>17.5%</p>
                        </div>
                        <div>
                            <p className='compareText1 m-1 '>Graduation Rate 2019</p>
                            <p className='compareText2'>90%</p>
                        </div>
                    </Col>
                    <Col sx={12} className='compareWrap text-center mr-3'>
                        <div className='my-3'>
                            <img width={50} height={50} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0xAXFXvNGFf_kfe3Xb0V3CyeqkG_MTvfnUPjwkaNTLXd3shEx-GnO4nCLCciXlLo5rt0&usqp=CAU' alt='logo' />
                            <p className='statHead'>College Name</p>
                            <button className='compareChangeBtn'>Change</button>
                        </div>
                        <Row className=''>
                            <Col>
                                <p className='colLightText mb-1'>Before AID</p>
                                <p className='statText '>$77,490</p>
                            </Col>
                            <Col>
                                <p className='colLightText mb-1'>After AID</p>
                                <p className='statText '>$38,160</p>
                            </Col>
                        </Row>
                        <div className='border-top border-bottom'>
                            <p className='compareText1 m-1 '>Acceptance Rate 2019</p>
                            <p className='compareText2'>8%</p>
                        </div>
                        <div>
                            <p className='compareText1 m-1 '>Graduation Rate 2019</p>
                            <p className='compareText2'>98%</p>
                        </div>
                    </Col>
                    <Col sx={12} className='compareWrap text-center mr-3'>
                        <div className='my-3'>
                            <img width={50} height={50} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0xAXFXvNGFf_kfe3Xb0V3CyeqkG_MTvfnUPjwkaNTLXd3shEx-GnO4nCLCciXlLo5rt0&usqp=CAU' alt='logo' />
                            <p className='statHead'>College Name</p>
                            <button className='compareChangeBtn'>Change</button>
                        </div>
                        <Row className=''>
                            <Col>
                                <p className='colLightText mb-1'>Before AID</p>
                                <p className='statText '>$77,490</p>
                            </Col>
                            <Col>
                                <p className='colLightText mb-1'>After AID</p>
                                <p className='statText '>$38,160</p>
                            </Col>
                        </Row>
                        <div className='border-top border-bottom'>
                            <p className='compareText1 m-1 '>Acceptance Rate 2019</p>
                            <p className='compareText2'>8%</p>
                        </div>
                        <div>
                            <p className='compareText1 m-1 '>Graduation Rate 2019</p>
                            <p className='compareText2'>98%</p>
                        </div>
                    </Col> */}
                </Row>
            </Col>
        </Row>
            
    </div>
  )
}

export default CompareModal