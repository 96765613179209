

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Table,Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import NoDataFound from './noDataFound.js';


//sample code
{/* <TableComponent
    headerArray={[
        {key:"billDate",label:"Bill DATE",},
        {key:"billId",label:"BILL ID"},
        {key:"billAmount",label:"Bill Amount"},
        {key:"billDueDate",label:"BILL DUE DATE",
            formatter:(rowData,callback)=>{
                return(
                    <div>
                        <button onClick={()=>callback({"type":"download","billId":rowData.billId})}>download</button>
                        <button onClick={()=>callback({"type":"print","billId":rowData.billId})}>print</button>
                    </div>
                );
            }
        }
    ]}
    payload={{
        totalRecords:5,
        data:[{
            "billDate" : "11",
            "billId" : "22",
            "billAmount" : "33",
            "billDueDate" : "44",
            "billDueDate2" : "45"
        },
        {
            "billDate" : "12",
            "billId" : "22",
            "billAmount" : "32",
            "billDueDate" : "42"
        }]
    }}
    limit={2}
    tableUniqueId = "invoiceTable"
    isRowClickable = {true}
    paginationRequired={false}
    paginationType="client"//server
    isSearchRequired={false}
    callback={this.invoiceHistoryTableHandler}
/> */}


class TableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            searchData:"",
            invoiceOffset:1,
            tableBodyDataBackup:[],
            tableBodyData:[],
            totalRecordsBackup:-1,
            totalRecords:-1
        }
    }

    componentDidMount(){
        if(this.props.paginationType === "client"){
            this.callbackHandler({"type":"firstTimeLoad","offset":1,"limit":10000000})
        }else{
            this.callbackHandler({"type":"firstTimeLoad","offset":1,"limit":this.props.limit})
        }
    }

    UNSAFE_componentWillReceiveProps(newProp){
        if(JSON.stringify(this.state.tableBodyData) !== JSON.stringify(newProp.payload.data) && this.state.searchData === ""){
            this.setState({
                tableBodyData:newProp.payload.data,
                tableBodyDataBackup:newProp.payload.data,
                totalRecords:newProp.payload.totalRecords,
                totalRecordsBackup:newProp.payload.totalRecords
            });
        }else if(newProp.payload.totalRecords == 0 && this.state.totalRecords == -1){
            this.setState({
                totalRecords:newProp.payload.totalRecords,
                totalRecordsBackup:newProp.payload.totalRecords
            });
        }
    }

    paginationItemClicked(receivedOffset){
        //window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        if(receivedOffset === "prev"){
            if(this.props.paginationType === "server"){
                this.callbackHandler({"type":"pagination","offset":this.state.invoiceOffset-1,"limit":this.props.limit});
            }
            this.setState({
                invoiceOffset:this.state.invoiceOffset-1
            });
        }else if(receivedOffset === "next"){
            if(this.props.paginationType === "server"){
                this.callbackHandler({"type":"pagination","offset":this.state.invoiceOffset+1,"limit":this.props.limit});
            }
            this.setState({
                invoiceOffset:this.state.invoiceOffset+1
            });
        }else{
            if(this.props.paginationType === "server"){
                this.callbackHandler({"type":"pagination","offset":receivedOffset,"limit":this.props.limit});
            }
            this.setState({
                invoiceOffset:receivedOffset
            });
        }
    }

    callbackHandler=(receivedData)=>{
        var dataToSendBack=receivedData;
        if(dataToSendBack.type && dataToSendBack.type === "rowClick"){
            dataToSendBack["rowData"]=decodeURI(dataToSendBack["rowData"]);
        }else if(this.state.searchData !=="" && dataToSendBack.type && dataToSendBack.type === "pagination"){
            dataToSendBack["type"]="search";
            dataToSendBack["searchKeyword"]=this.state.searchData;
        }
        this.props.callback(dataToSendBack);
    }

    tableSearchHandler=(event)=>{
        var searchResultArray = [];
        if(this.props.paginationType === "client"){
            var searchData = event.target.value;
            this.state.searchData = searchData.toLowerCase();
            for(var i=0;i<this.state.tableBodyDataBackup.length;i++){
                var currentRowObject = this.state.tableBodyDataBackup[i];
                var needToAppend = false;
                for(var j=0;j<this.props.headerArray.length;j++){
                    var currentHeaderObject = this.props.headerArray[j];
                    if(!currentHeaderObject.formatter){
                        if((currentRowObject[currentHeaderObject.key].toLowerCase()).indexOf(searchData) != -1){
                            needToAppend = true;
                        }
                    }
                }
                if(needToAppend){
                    searchResultArray.push(currentRowObject);
                }
            }
            this.setState({
                tableBodyData:searchResultArray,
                invoiceOffset:1,
                totalRecords:searchResultArray.length
            });
        }else if(this.props.paginationType === "server"){
            if(this.state.totalRecords <= this.props.limit){
                var searchData1 = event.target.value;
                this.state.searchData = searchData1;
                for(var k=0;k<this.state.tableBodyDataBackup.length;k++){
                    var currentRowObjects = this.state.tableBodyDataBackup[k];
                    var needToAppend = false;
                    for(var m=0;m<this.props.headerArray.length;m++){
                        var currentHeaderObjects = this.props.headerArray[m];
                        if((currentRowObject[currentHeaderObjects.key]).indexOf(searchData1) != -1){
                            needToAppend = true;
                        }
                    }
                    if(needToAppend){
                        searchResultArray.push(currentRowObject);
                    }
                }
                this.setState({
                    tableBodyData:searchResultArray,
                    totalRecords:searchResultArray.length
                });
            }else{
                var searchData = event.target.value;
                this.state.searchData = searchData;
                this.callbackHandler({"type":"search","searchKeyword":searchData,offset:this.state.invoiceOffset,limit:this.props.limit});    
            }
        }
    }

    renderHeader(){
        const allHeaders = this.props.headerArray.map((item, index) => {
            return (
                <th className="pl-1" key={index} data-title="gfgffg">{item.label}</th>
            )
        })
        return (<tr>{allHeaders}</tr>);
    }

    renderSingleRow(receivedSingleRowData){
        var encodedSingleRowData = encodeURI(JSON.stringify(receivedSingleRowData));
        const singleRow = this.props.headerArray.map((singleHeaderData,index) => {
            var optionalFormatter = singleHeaderData.formatter;
            if(optionalFormatter){
                return(<td data-title="Action" key={index} style={{borderColor:'#eaedf0',color:'#404042'}}>{optionalFormatter(receivedSingleRowData,this.callbackHandler)}</td>)
            }else{
                if(this.props.isRowClickable){
                    return (<td data-title={singleHeaderData["label"]} key={index} style={{borderColor:'#eaedf0',color:'#404042',cursor:"pointer"}} onClick={()=>this.callbackHandler({"type":"rowClick","rowData":encodedSingleRowData})}>{receivedSingleRowData[singleHeaderData["key"]]}</td>)
                }else{
                    return (<td data-title={singleHeaderData["label"]} key={index} style={{borderColor:'#eaedf0',color:'#404042'}}>{receivedSingleRowData[singleHeaderData["key"]]}</td>)
                }
            }
        });
        return (
            <tr data-title="gf8666" rowdata={encodedSingleRowData} className="animated fadeIn">{singleRow}</tr>
        );
    }

    renderRows(receivedData){
        const allRows = receivedData.map((singleRowData, index) => {
            return (this.renderSingleRow(singleRowData));
        });
         if(receivedData.length>0){
            return allRows;
        }else{
            return (<tr className="animated fadeIn"><td className="noDataContainerForMobile" colSpan={this.props.headerArray.length}><NoDataFound content={this.props.noDataContentText}/></td></tr>);
        }
    }

    renderTableBody(){
        if(this.props.paginationType === "server"){
            return(this.renderRows(this.state.tableBodyData));
        }else if(this.props.paginationType === "client"){
            var rowsToRender=[];
            var fullData = this.state.tableBodyData;
            var startingPoint = (this.state.invoiceOffset-1)*this.props.limit;
            var endingPoint = startingPoint+this.props.limit;
            rowsToRender = fullData.slice(startingPoint,endingPoint);
            return(
                this.renderRows(rowsToRender)
            );
        }
    }

    renderPagination() {
        var totalRecord = this.state.totalRecords;
        var limit = this.props.limit
        var paginationLink=parseInt(totalRecord/limit);
        if(totalRecord % limit > 0){paginationLink =paginationLink+1}
        let items = [];
    
        if(totalRecord > 0){
            var invoiceOffset = this.state.invoiceOffset;
            var limit = 3

                //prev
                var disabled= "";
                if(invoiceOffset == 1){
                    disabled = "disabled"
                } else { disabled = "" }

               if(this.state.invoiceOffset > 1) 
                    items.push(<PaginationItem><PaginationLink previous onClick={() => { this.paginationItemClicked("prev") }}/></PaginationItem>)
                  
                //current
                for (let i = 1; i <= paginationLink; i++) {
                        if (i == 1 && invoiceOffset-limit > 1){
                            items.push(
                                <PaginationItem active={false} ><PaginationLink style={{zIndex:'auto'}} href="#" onClick={() => {this.paginationItemClicked(1) }}>1</PaginationLink></PaginationItem>
                            );

                                if (invoiceOffset-limit > 2){
                                    items.push(
                                        <PaginationItem disabled={true} ><PaginationLink style={{zIndex:'auto'}} href="#">...</PaginationLink></PaginationItem>
                                    );
                            }
                        }
    
                     if (i >= invoiceOffset-limit && i <= invoiceOffset+limit){
                        if (i != invoiceOffset){
                            items.push(
                                <PaginationItem active={false} ><PaginationLink style={{zIndex:'auto'}} onClick={() => {this.paginationItemClicked(i) }}>{i}</PaginationLink></PaginationItem>
                            );
                        } else {
                            items.push(
                                <PaginationItem active={true} ><PaginationLink style={{zIndex:'auto'}} onClick={() => {this.paginationItemClicked(i) }}>{i}</PaginationLink></PaginationItem>
                            );
                        }
                    }
    
                  if (i == paginationLink && invoiceOffset+limit< paginationLink){
                    if (invoiceOffset+limit < paginationLink-1){
                        items.push(
                            <PaginationItem disabled={true}><PaginationLink style={{zIndex:'auto'}} href="#">...</PaginationLink></PaginationItem>
                        );
                    }
                        items.push(
                            <PaginationItem ><PaginationLink style={{zIndex:'auto'}} href="#" onClick={() => {this.paginationItemClicked(paginationLink) }}>{paginationLink}</PaginationLink></PaginationItem>
                        );
                    }
     
                }
    
                //next
                var disabled= "";
                if(invoiceOffset == paginationLink){
                    disabled = "disabled"
                } else { disabled = "" }
    
                if (invoiceOffset < paginationLink){
                    items.push(
                        <PaginationItem disabled={this.state.invoiceOffset==paginationLink?true:false}><PaginationLink next onClick={() => { this.paginationItemClicked("next") }}/></PaginationItem>
                    )
                }
                return (
                        <Pagination size="sm">{items}</Pagination>
                    );
            }
            else{
                return null;
            }
    }

    render() {
        
        return (
            <div className="commonTableComponent" id={this.props.uniqueTableReferenceId} ref={this.props.uniqueTableReferenceId}>
                <div style={{padding: "5px 0px",display: "flex",justifyContent: "space-between"}}>
                    {/* todo:for custom button */}
                    <div></div>
                    {this.props.isSearchRequired?
                        <div>
                            <input className="form-control mt-2" onChange={this.tableSearchHandler} type="text" placeholder="Search" style={{width: 150,fontSize: 12,height: 30, border: '1px solid #ced4da'}}/>
                        </div>
                        :
                        null
                    }
                </div>
                <Table id={this.props.tableUniqueId} style={{outlineColor:'#eaedf0',marginTop:10,border:'1px solid #eaedf0',borderShadow:6,borderSizing:'border-box',borderRadius:6}}>
                    <thead style={{backgroundColor:'rgba(2,133,202,0.1)',color:'#231F20',fontSize:14,border:'1px solid #eaedf0',borderRadius:6,borderSizing:'border-box'}}>
                        {this.renderHeader()}
                    </thead>
                    <tbody>
                        {this.renderTableBody()}
                    </tbody>
                </Table>
                {this.props.paginationRequired && this.state.totalRecords > this.props.limit?
                    <div style={{"display":"flex","justifyContent":"flex-end"}} id="paginationContainer">
                        {this.renderPagination()}
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}

TableComponent.defaultProps = {
    headerArray:[],
    payload:{
        totalRecords:0,
        data:[]
    },
    limit:2,
    uniqueTableReferenceId:"unSpecifiedId_"+moment().valueOf(),
    isRowClickable:false,
    paginationRequired:true,
    paginationType:"client",
    isSearchRequired:true,
    noDataContentText : "No Data Found"
}

TableComponent.propTypes = {
    limit: PropTypes.number,
    uniqueTableReferenceId:PropTypes.string,
    isRowClickable: PropTypes.bool,
    paginationRequired: PropTypes.bool,
    paginationType: PropTypes.string,
    isSearchRequired: PropTypes.bool,
    callback: PropTypes.func,
    noDataContentText: PropTypes.string,
}

const mapStateToProps = (state, ownProperties) => {
    return {
        locales: state.locales
    }
}

const TableComponentContainer = connect(
    mapStateToProps
)(TableComponent)

export default TableComponentContainer;

