import { setTimeout } from "timers";

function showToast(message){
    document.getElementById("toastPopup").textContent = message;
    document.getElementById("toastPopup").classList.remove("d-none");
    setTimeout(function(){
        document.getElementById("toastPopup").classList.add("d-none");
    },2000);

}

export default {
    showToast
}