
import React from 'react';
//import ReactLoading from 'react-loading';

class LoadAnimationComponent extends React.Component{
    render(){
        return (
            this.props.isVisible?
                // <div id="loaderContainer" className="d-flex align-items-center justify-content-center" style={{zIndex:2000}}>
                //     <div style={{zIndex:2000}} className="d-flex flex-column align-items-center justify-content-center" id="loaderContainerInnerDiv">
                //         <ReactLoading type="cylon" color="#000" delay={50} height='50px' width='50px' />
                //         <span>Please wait..</span>
                //     </div>
                // </div>
                <div className="loaderOuterContainer">
                <ul className="loader">
                    <li className="center"></li>
                    <li className="item item-1"></li>
                    <li className="item item-2"></li>
                    <li className="item item-3"></li>
                    <li className="item item-4"></li>
                    <li className="item item-5"></li>
                    <li className="item item-6"></li>
                    <li className="item item-7"></li>
                    <li className="item item-8"></li>
                </ul>
                </div>
            :
                null
        );
    }
}

export default LoadAnimationComponent;

//type : blank,balls,bars,bubbles,cubes,cylon,spin,spinningBubbles,spokes
