import React from 'react'
import imgGal1 from '../../../../assets1/images/img-gall-1.jpg'
import imgGal2 from '../../../../assets1/images/img-gal-2.webp'
import imgGal3 from '../../../../assets1/images/img-gal-3.jpg'
import imgGal4 from '../../../../assets1/images/img-gal-4.webp'
import imgGal5 from '../../../../assets1/images/img-gal-5.jpg'
import imgGal6 from '../../../../assets1/images/img-gal-6.webp'
import imgGal7 from '../../../../assets1/images/img-gal-7.webp'



const GallerySec = () => {
  return (
    <div className='galleryWrap py-3'>
        <div className='d-flex'>
            <img className='galleryImg' height={250} width={250} src={imgGal1} />
            <img className='galleryImg' width={145} height={250} src={imgGal2} />
            <div bg-info>
                <img className='galleryImg' width={150} height={125} src={imgGal3} />
                <img className='galleryImg' width={150} height={125} src={imgGal4} />
            </div>
            <img className='galleryImg' width={145} height={250} src={imgGal5} />
        </div>
        <div className='d-flex '>
            <img className='galleryImg' width={125} height={125} src={imgGal3} />
            <img className='galleryImg' width={125} height={125} src={imgGal4} />
            <div>
            <img className='galleryImg' width={295} height={125} src={imgGal6} />
            </div>
            <img className='galleryImg' width={145} height={125} src={imgGal7} />
        </div>
        
    </div>
  )
}

export default GallerySec