import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { MW_URL } from "../../../config";
import moment from "moment";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Calendar } from 'react-modern-calendar-datepicker';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import {BsInbox} from 'react-icons/bs'
import LoadingAnimation from "../../../utils/loadingAnimation";
import ModalComponent from "../../../utils/modalComponent";
import { Input } from "reactstrap";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { FaCaretDown, FaTrash, FaTimes } from "react-icons/fa";
import GoogleMeet from "../../../imageAssets/googleMeet.png";
import ProfilePicSample from "../../../imageAssets/profilepicsample.png";

import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Stack from "@mui/material/Stack";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import MobileTimePicker from "@mui/lab/MobileTimePicker";
import DesktopTimePicker from "@mui/lab/DesktopTimePicker";
import MomentUtils from "@date-io/moment";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";
import ValidateForm from "../../../utils/formValidation";




const Calender = () => {

  const currentDate = new Date();
  var currentMonth = currentDate.toLocaleString('default', { month: 'long' });
  var currentYear = currentDate.getFullYear();
  const currentMonthNumber = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const currentDay = currentDate.getDate().toString().padStart(2, '0');
  const currentHour = currentDate.getHours().toString().padStart(2, '0');
  const currentMinute = currentDate.getMinutes().toString().padStart(2, '0');

  const [isLoading, setIsLoading] = useState(true);
  const [currentEvents, setCurrentEvents] = useState([]);

  const createUniquieId = () => {
    const head = Date.now().toString(36);
    const tail = Math.random().toString(36).substr(2);
    return head + tail;
  };
  let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today
  var currentTime = moment().format().split("+")[0]; //2021-12-16T11:45:25
  const validRangeStart = `${currentYear}-${currentMonthNumber}-${currentDay}T${currentHour}:${currentMinute}`;
  var today = new Date();
  today = today.toLocaleDateString("en-US");
  today = today.split("/");
  const defaultValue = {
    year: parseInt(today[2]),
    month: parseInt(today[0]),
    day: parseInt(today[1]),
  };

  const calendarRef = useRef();
  const [height, setHeight] = useState("");
  const [selectedDay, setSelectedDay] = useState(defaultValue);
  const [eventEditModalVisible, seteventEditModalVisible] = useState(false);
  const [eventAddModalVisible, setEventAddModalVisible] = useState(false);
  const [currentClickInfo, setCurrentClickInfo] = useState({});
  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventGuestTxt, seteventGuestTxt] = useState("");
  const [eventSelectedInfo, setEventSelectedInfo] = useState({});
  const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
  const [dropdownDiv, setdropdownDiv] = useState(false);
  const [calanderViewType, setcalanderViewType] = useState("Month");
  const [googleMeetLinkAdded, setgoogleMeetLinkAdded] = useState(true);
  const [googleMeetId, setgoogleMeetId] = useState("");
  const [listOfUsers, setlistOfUsers] = useState([]);
  const [showGuestSuggestionsDiv, setshowGuestSuggestionsDiv] = useState(false);
  const [guestSuggestionList, setguestSuggestionList] = useState([]);
  const [guestSelectedList, setguestSelectedList] = useState([]);
  const [selectedEventHostId, setselectedEventHostId] = useState("");
  // const [currentMonthNew, setCurrentMonthNew] = useState(currentMonth);
  // const [currentYearNew, setCurrentYearNew] = useState(currentYear);
  const [currentMonthNew, setCurrentMonthNew] = useState(new Date().toLocaleString('default', { month: 'long' }));
  const [currentYearNew, setCurrentYearNew] = useState(new Date().getFullYear()); 

  let iscurrentDate = false; 

  const [selectedStartTime, handleStartTimeChange] = useState(
    moment().format()
  );
  const [selectedEndTime, handleEndTimeChange] = useState();

  const [value, setValue] = React.useState(
    new Date("2018-01-01T00:00:00.000Z")
  );


  useEffect(() => {
    getAllEventsFun();
    getlistofusersFromDb();
  }, []);

  const getlistofusersFromDb = () => {
    axios
      .post(`${MW_URL}` + "users", {})
      .then((getUsersResponse) => {
        if (
          getUsersResponse &&
          getUsersResponse.data &&
          getUsersResponse.data[0]
        ) {
          var userSuggList = [];
          var userList = [];
          for (var i = 0; i < getUsersResponse.data.length; i++) {
            var currentObj = getUsersResponse.data[i];
            if (
              currentObj.counsellorId ===
              JSON.parse(localStorage.getItem("userData")).email
            ) {
              userSuggList.push({
                key: currentObj.email,
                username: currentObj.username,
                email: currentObj.email,
                userType: currentObj.userType,
              });
            }
          }
          for (var i = 0; i < getUsersResponse.data.length; i++) {
            var currentObj = getUsersResponse.data[i];
            userList.push({
              key: currentObj.email,
              username: currentObj.username,
              email: currentObj.email,
              userType: currentObj.userType,
            });
          }
          console.log("userList", userSuggList);
          setlistOfUsers(userList);
          setguestSuggestionList(userSuggList);
        }
        getAllEventsFun();
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };

  const getAllEventsFun = () => {
    console.log("getAllEventsFun");
    axios
      .post(`${MW_URL}` + "getAllEvents", {})
      .then((getAllEventsResponse) => {
        setIsLoading(false);
        var eventsList = [];
        getAllEventsResponse.data.map((event) => {
          if (
            event.eventGuests.indexOf(
              JSON.parse(localStorage.getItem("userData")).email
            ) != -1
          ) {
            eventsList.push({
              id: event.eventId,
              title: event.title,
              start: event.start,
              end: event.end,
              googleMeetId: event.googleMeetId,
              description: event.description,
              eventGuests: event.eventGuests,
              eventHostId: event.eventHostId,
            });
          }
        });
        setCurrentEvents(eventsList);
        console.log(eventsList, "eventlist")
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };

  const handleEventClick = (clickInfo) => {
    setCurrentClickInfo(clickInfo);
    console.log(clickInfo, "f")
    seteventEditModalVisible(true);
  };

  const eventEditModalHandler = (dataObject) => {
    if (dataObject.type === "cancel") {
      seteventEditModalVisible(false);
      setEventName("");
      setEventDescription("");
      seteventGuestTxt("");
      setguestSelectedList([]);
    } else if (dataObject.type === "submit" && ValidateForm.inputFieldMandatoryCheck("alertEditFormContainer", false)) {
      console.log("just okk---")
      if (
        selectedEventHostId ==
        JSON.parse(localStorage.getItem("userData")).email
      ) {
        setTimeout(() => {
          console.log(
            "currentClickInfo",
            JSON.stringify(currentClickInfo.event)
          );
          var googleMeetLinkAddedToSend = googleMeetLinkAdded
            ? googleMeetId
            : googleMeetLinkAdded;
          setEventName("");
          setEventDescription("");
          seteventGuestTxt("");
          var selectedGuestListToSend = [];
          selectedGuestListToSend.push(
            JSON.parse(localStorage.getItem("userData")).email
          );
          if (guestSelectedList.length > 0) {
            guestSelectedList.map((guest) => {
              console.log("guest", guest);
              selectedGuestListToSend.push(guest.email);
            });
          }
          var dataToSend = {
            id: currentClickInfo.event.id,
            title: eventName,
            description: eventDescription,
            start: selectedStartTime,
            end: selectedEndTime,
            allDay: "false",
            googleMeetId: googleMeetLinkAddedToSend,
            eventHostId: JSON.parse(localStorage.getItem("userData")).email,
            eventGuests: selectedGuestListToSend,
          };
          setgoogleMeetLinkAdded(true);
          console.log("dataToSend", dataToSend);
          // setIsLoading(true);
          // axios
          //   .post(`${MW_URL}` + "editEvent", dataToSend)
          //   .then((response) => {
          //     var status = "NA";
          //     if (response && response.data && response.data.STATUS)
          //       status = response.data.STATUS;
          //     console.log("status", status);
          //     console.log("response", JSON.stringify(response));
          //     if (status.toUpperCase() === "SUCCESS") {
          //       //currentClickInfo.event.remove()
          //       getAllEventsFun();
          //       seteventEditModalVisible(false);
          //       setguestSelectedList([]);
          //     } else {
          //       //setIsLoading(false)
          //       seteventEditModalVisible(false);
          //       setguestSelectedList([]);
          //     }
          //   })
          //   .catch((err) => {
          //     console.log("err >> ", err);
          //   });
        }, 500);
      } else {
      }
    }
  };

  const deleteEventHandler = () => {
    setEventName("");
    setEventDescription("");
    seteventGuestTxt("");
    setgoogleMeetLinkAdded(true);

    var dataToSend = {
      id: currentClickInfo.event.id,
    };
    axios
      .post(`${MW_URL}` + "deleteEvent", dataToSend)
      .then((response) => {
        var status = "NA";
        if (response && response.data && response.data.STATUS)
          status = response.data.STATUS;
        console.log("status", status);
        console.log("response", JSON.stringify(response));
        if (status.toUpperCase() === "SUCCESS") {
          currentClickInfo.event.remove();
          getAllEventsFun();
          seteventEditModalVisible(false);
        } else {
          //setIsLoading(false)
          seteventEditModalVisible(false);
        }
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };
  const eventAddModalHandler = (dataObject) => {
    console.log("clicked", dataObject)
    if (dataObject.type === "cancel") {
      setEventAddModalVisible(false);
      setgoogleMeetLinkAdded(true);
      setshowGuestSuggestionsDiv(false);
      setEventName("");
      setEventDescription("");
      seteventGuestTxt("");
      setguestSuggestionList([]);
      setguestSelectedList([]);
    } else if (dataObject.type === "submit" && ValidateForm.inputFieldMandatoryCheck("alertFormContainer", false)) {
        console.log("sub")
      // let calendarApi = eventSelectedInfo.view.calendar;
      // calendarApi.unselect(); // clear date selection
      // calendarApi.addEvent({
      //     id: createUniquieId(),
      //     title: eventName,
      //     start: eventSelectedInfo.startStr,
      //     end: eventSelectedInfo.endStr,
      //     allDay: eventSelectedInfo.allDay
      // })
      setEventName("");
      setEventDescription("");
      seteventGuestTxt("");
      setshowGuestSuggestionsDiv(false);
      var googleMeetLinkAddedToSend = googleMeetLinkAdded
        ? googleMeetId
        : googleMeetLinkAdded;
      var selectedGuestListToSend = [];
      selectedGuestListToSend.push(
        JSON.parse(localStorage.getItem("userData")).email
      );
      if (guestSelectedList.length > 0) {
        guestSelectedList.map((guest) => {
          console.log("guest", guest);
          selectedGuestListToSend.push(guest.email);
        });
      }

      var dataToSend = {
        id: createUniquieId(),
        title: eventName,
        description: eventDescription,
        start: selectedStartTime,
        end: selectedEndTime,
        allDay: false,
        googleMeetId: googleMeetLinkAddedToSend,
        eventHostId: JSON.parse(localStorage.getItem("userData")).email,
        eventGuests: selectedGuestListToSend,
      };
      console.log(dataToSend, "selectedEndTime", selectedEndTime);
      console.log("eventSelectedInfo.allDay", eventSelectedInfo.allDay);
      setIsLoading(true);
      axios
        .post(`${MW_URL}` + "addEvent", dataToSend)
        .then((response) => {
          setIsLoading(false)
          var status = "NA";
          console.log("1212", response)
          if (response && response.data && response.data.STATUS)
            console.log("121222", response.data.STATUS)
            status = response.data.STATUS;
          if (status.toUpperCase() === "SUCCESS") {
            console.log("121552")
            getAllEventsFun();
            setEventAddModalVisible(false);
            setgoogleMeetLinkAdded(true);
            setguestSelectedList([]);
          } else if (status === "INVALID_USER") {
            console.log("1212gg")
            setEventAddModalVisible(false);
            setgoogleMeetLinkAdded(true);
            setguestSelectedList([]);
          } else if (status === "FAILED")
            setEventAddModalVisible(false);
            setgoogleMeetLinkAdded(true);
            setguestSelectedList([]);
            toast.error(response.data.MESSAGE, {
              autoClose: 10000,
            });
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    }
  };

  const addGuestToselectedList = (guest) => {
    setguestSelectedList([...guestSelectedList, guest]);
    console.log("guestSelectedList", guestSelectedList);
    setshowGuestSuggestionsDiv(false);
    seteventGuestTxt("");
  };

  const removeGuestFromSelectedLust = (guest) => {
    const newList = guestSelectedList.filter(
      (user) => user.email !== guest.email
    );
    setguestSelectedList(newList);
  };

  const fullcalendarViewChange = (viewType) => {
    setdropdownDiv(false);
    setcalanderViewType(viewType);
    if (viewType == "Day") {
      calendarRef.current.getApi().changeView("timeGridDay");
    } else if (viewType == "Week") {
      calendarRef.current.getApi().changeView("dayGridWeek");
    } else if (viewType == "Month") {
      calendarRef.current.getApi().changeView("dayGridMonth");
    }
  };

  const handleDateSelect = (selectInfo) => {
    const selectedDate = new Date(selectInfo.startStr);
    const currentDate = new Date();
  
    // Compare only the date portion (year, month, and day)
    selectedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
  
    if (selectedDate < currentDate) {
      // The selected date is in the past
      toast.error("Selected a previous date", {
        autoClose: 5000,
      });
      
    } else {
      console.log("selectInfo.startStr", selectInfo.startStr);
      setEventSelectedInfo(selectInfo);
      setgoogleMeetId(createUniquieId());
      handleStartTimeChange(selectInfo.startStr);
      handleEndTimeChange(selectInfo.endStr);
      setEventAddModalVisible(true);    
    }
  };

  function updateEventTimeHandler(eventid, startTime, endTime) {
    console.log(endTime, "end")
    let dataToSend = {
      id: eventid,

      start: startTime,
      end: endTime,
    };
    console.log("dataToSend", dataToSend);
    setIsLoading(true);
    axios
      .post(`${MW_URL}` + "editEventTime", dataToSend)
      .then((response) => {
        var status = "NA";
        if (response && response.data && response.data.STATUS)
          status = response.data.STATUS;
        console.log("status", status);
        console.log("response", JSON.stringify(response));
        if (status.toUpperCase() === "SUCCESS") {
          //currentClickInfo.event.remove()
          getAllEventsFun();
          seteventEditModalVisible(false);
          setguestSelectedList([]);
        } else {
          //setIsLoading(false)
          seteventEditModalVisible(false);
          setguestSelectedList([]);
        }
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  }

  const showGuestSuggestions = (guestTxt) => {
    seteventGuestTxt(guestTxt);
    if (guestTxt.length > 1) {
      setshowGuestSuggestionsDiv(true);
      var userList = [];
      listOfUsers.map((users) => {
        if (
          users.email.match(guestTxt) != null ||
          users.username.match(guestTxt)
        ) {
          //push if its not already been added
          guestSelectedList.indexOf(users) === -1
            ? userList.push(users)
            : console.log("This item already exists");
        }
      });
      setguestSuggestionList(userList);
      console.log(userList, "guest")
    } else {
      setshowGuestSuggestionsDiv(false);
    }
  };

  const selectAllowCallback = (selectInfo) => {
    const selectedStartTime = moment(selectInfo.start).format('YYYY-MM-DDTHH:mm');
    console.log(selectedStartTime, validRangeStart, "testttt----")
    console.log(selectedStartTime >= validRangeStart, "true/false")
    return selectedStartTime >= validRangeStart;
  };

  const handlePrev = () => {
    if (calendarRef.current) {
      updateMonthYear(-1);
    }
  };

  const handleNext = () => {
    if (calendarRef.current) {
      updateMonthYear(1);
    }
  };

  // const handleDateClick = (arg) => {
  //   const clickedDate = arg.date;
  //   setCurrentMonthNew(clickedDate.toLocaleString('default', { month: 'long' }));
  //   setCurrentYearNew(clickedDate.getFullYear());
  // };

  const updateMonthYear = (change) => {
    const currentDate = calendarRef.current.getApi().getDate();
    const newMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + change);
    setCurrentMonthNew(newMonth.toLocaleString('default', { month: 'long' }));
    setCurrentYearNew(newMonth.getFullYear());
    if(change === 1) {
          calendarRef.current.getApi().next();
    } else if (change === -1) {
          calendarRef.current.getApi().prev();
    }
  };



  return (
    <div className='p-1' style={{"background":"#FFFFFF"}}>
      {isLoading ? <LoadingAnimation isVisible={true} /> : null}
        <div className='mt-3'>
            <p className='docHeadText'>Calendar</p>
        </div>
        <Row >
            <Col sm={8} className='px-3' >
                <div className='monthHead'>
                    {/* <div className='inboxSec'><BsInbox /> <span className='mx-1'> inbox</span></div> */}

                    <div className='monthSec'>
                        <span ><FaChevronLeft className="mx-2" onClick={handlePrev}/></span>
                        {currentMonthNew} {currentYearNew} 
                        <span ><FaChevronRight className="mx-2" onClick={handleNext}/></span>
                    </div>

                    <div className="mx-2">
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ cursor: "pointer" }}
                        onClick={() => setdropdownDiv(!dropdownDiv)}
                      >
                        <div className="calDropText pr-2">{calanderViewType}</div>
                        <FaCaretDown style={{ fontSize: 12 }} />
                      </div>
                      {dropdownDiv ? (
                        <div className="col-md-12 m-0 p-0">
                          <div
                            className="calDropCard p-3"
                            style={{
                              borderRadius: 15,
                              position: "absolute",
                              right: 0,
                              backgroundColor: "white",
                              zIndex: 5,
                              cursor: "pointer",
                            }}
                          >
                            <div
                              onClick={() => {
                                fullcalendarViewChange("Day");
                              }}
                            >
                              Day
                            </div>
                            <div
                              onClick={() => {
                                fullcalendarViewChange("Month");
                              }}
                            >
                              Month
                            </div>
                            <div
                              onClick={() => {
                                fullcalendarViewChange("Week");
                              }}
                            >
                              Week
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    
                </div>
            <FullCalendar
                ref={calendarRef}
                initialView="dayGridMonth"
                // dateClick={this.handleDateClick}
                displayEventTime={true}
                selectable={true}
                editable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={true}
                // dateClick={handleDateClick}
                selectAllow={selectAllowCallback}
                plugins={[
                    dayGridPlugin,
                    interactionPlugin,
                    timeGridPlugin,
                    // resourceTimeGridPlugin
                ]}
                eventClick={info => {
                    console.log(info);
                    setCurrentClickInfo(info);
                    setEventName(info.event.title);
                    handleStartTimeChange(info.event.start);
                    console.log("eventClick start time", info.event.start);
                    handleEndTimeChange(info.event.end);
                    console.log("info.event", JSON.stringify(info.event));
                    setEventDescription(info.event.extendedProps.description);
                    console.log(
                      "info.event.extendedProps",
                      info.event.extendedProps
                    );
                    if (info.event.extendedProps.googleMeetId == "false") {
                      setgoogleMeetLinkAdded(false);
                      setgoogleMeetId(createUniquieId());
                    } else {
                      setgoogleMeetLinkAdded(true);
                      setgoogleMeetId(info.event.extendedProps.googleMeetId);
                    }
                    seteventGuestTxt("");
                    seteventEditModalVisible(true);
                    var guestListReceived = info.event.extendedProps.eventGuests;
                    var listToUpdate = [];
                    listOfUsers.map((user) => {
                      guestListReceived.map((guest) => {
                        if (
                          user.email == guest &&
                          guest !=
                            JSON.parse(localStorage.getItem("userData")).email
                        ) {
                          listToUpdate.push(user);
                        }
                      });
                    });
                    setguestSelectedList(listToUpdate);
                    setselectedEventHostId(info.event.extendedProps.eventHostId);
                }}
                events={currentEvents}
                select={handleDateSelect}
                allDaySlot={false}
                slotDuration="01:00:00"
                slotLaneClassNames="sloteLaneStyle2"
                eventDrop={info => {
                  updateEventTimeHandler(
                    info.event.id,
                    info.event.start,
                    info.event.end
                  );
                  setCurrentClickInfo(info);
                  handleStartTimeChange(info.event.start);
                  handleEndTimeChange(info.event.end);
                  console.log(info.event.end, "end2")
                }}
                eventResize={info => {
                  updateEventTimeHandler(
                    info.event.id,
                    info.event.start,
                    info.event.end
                  );
  
                  setCurrentClickInfo(info);
                  handleStartTimeChange(info.event.start);
                  handleEndTimeChange(info.event.end);
                  console.log(info.event.end, "end3")
                }}

                />


            </Col>
            <Col sm={4} className='px-3' >
                <div className='eventHead mb-3'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <p className='eventText'>Events</p>
                        <button onClick={() => setEventAddModalVisible(true)} className='eventAddBtn'>Add</button>
                    </div>
                </div>

                <div className="eventWrapper">
                  {currentEvents?.map((obj, index) => {
                      var startDate = moment(obj.start, "YYYY-MM-DD");
                      iscurrentDate = startDate.isSameOrAfter(new Date(), "day");

                      if (iscurrentDate) {
                          return (
                              <div key={index} className='eventListWrap'>
                                  <div className='eventList p-2'>
                                      <div className='d-flex'>
                                          <div className='eventDot'></div>
                                          <div className='eventTime'>{moment(obj.start).format('YYYY-MM-DD')} | {moment(obj.start).format('hh:mm A')} - {moment(obj.end).format('hh:mm A')}</div>
                                      </div>
                                      <p className='eventTitle m-0'>{obj.title}</p>
                                      <p className='eventDisc'>{obj.description}</p>
                                  </div>
                              </div>
                          )
                      }
                  })}
                  {!iscurrentDate &&(
                  <div className="h-100 d-flex align-items-center">
                      <p className="inboxSec">No Events Today</p>
                  </div>
                  )}
                </div>

            </Col>
        </Row>

        <ModalComponent
          isVisible={eventEditModalVisible}
          mainHeading="Alert"
          cancelButtonText="Cancel"
          submitButtonText="Save"
          callback={eventEditModalHandler}
        >
          <div className="pt-2 pb-5" id="alertEditFormContainer">
            {Object.keys(currentClickInfo).length > 0 ? (
              selectedEventHostId ==
              JSON.parse(localStorage.getItem("userData")).email ? (
                <div>
                  <div className="col-md-12 p-0 text-left">
                    <div className="d-flex justify-content-center pb-2">
                      Please edit your appoinment
                    </div>
                    <div className="form-group">
                    <Input
                      type="text"
                      autoComplete="new-password"
                      style={{ width: "95%" }}
                      className="text-box-style mb-3 py-2 input_data mandatoryField"
                      placeholder="Event Name"
                      value={eventName}
                      onChange={(event) => {
                        setEventName(event.target.value);
                      }}
                    />
                    <div className="errorDesc"></div>
                    </div>
                    <Input
                      type="text"
                      style={{ width: "95%" }}
                      className="text-box-style mb-3 py-2 "
                      placeholder="Add Event description"
                      value={eventDescription}
                      onChange={(event) => {
                        setEventDescription(event.target.value);
                      }}
                    />
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Stack spacing={3}>
                        <div className="mb-3 d-flex" style={{ width: "95%" }}>
                          <MobileTimePicker
                            label="Start Time"
                            value={selectedStartTime}
                            onChange={(newValue) => {
                              console.log(
                                "startt newValue>>>>>>",
                                newValue.format()
                              );
                              console.log(
                                "start newValue======",
                                newValue.format()
                              );
                              handleStartTimeChange(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            minutesStep={5}
                          />
                          <MobileTimePicker
                            label="End Time"
                            value={selectedEndTime}
                            onChange={(newValue) => {
                              console.log(
                                "end newValue+++++++",
                                newValue.format()
                              );
                              console.log("end newValue-------", newValue);
                              handleEndTimeChange(newValue);
                            }}
                            // minTime={selectedStartTime}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                      </Stack>
                    </LocalizationProvider>
                    <div>
                      <Input
                        type="text"
                        style={{ width: "95%" }}
                        className="text-box-style  py-2"
                        placeholder="Add Participants"
                        value={eventGuestTxt}
                        onChange={(event) => {
                          showGuestSuggestions(event.target.value);
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          width: "95%",
                          zIndex: 3,
                          backgroundColor: "white",
                        }}
                      >
                        {showGuestSuggestionsDiv ? (
                          <div
                            style={{ maxHeight: "200px", overflow: "scroll" }}
                          >
                            {guestSuggestionList.map((user) => {
                              console.log("user", user);
                              return (
                                <div
                                  className="p-2 d-flex align-items-center guest-suggestion"
                                  onClick={() => {
                                    addGuestToselectedList(user);
                                  }}
                                >
                                  <img
                                    height={40}
                                    src={ProfilePicSample}
                                    alt="profilepic"
                                  />
                                  <div className="p-0 m-0 pl-2">
                                    <div
                                      className="p-0 m-0"
                                      style={{
                                        fontSize: 14,
                                        fontWeight: 600,
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {user.username}
                                    </div>
                                    <div
                                      className="p-0 m-0"
                                      style={{ fontSize: 12 }}
                                    >
                                      {user.email}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                      <div className="p-2 d-flex align-items-center">
                        <div className="col-md-1 p-0 m-0">
                          <img
                            height={40}
                            src={ProfilePicSample}
                            alt="profilepic"
                          />
                        </div>
                        <div className="col-md-9 p-0 m-0 pl-3">
                          <div
                            className="p-0 m-0"
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              textTransform: "capitalize",
                            }}
                          >
                            {
                              JSON.parse(localStorage.getItem("userData"))
                                .username
                            }
                          </div>
                          <div className="p-0 m-0" style={{ fontSize: 12 }}>
                            {JSON.parse(localStorage.getItem("userData")).email}
                          </div>
                        </div>
                      </div>
                      <div>
                        {guestSelectedList.map((user) => {
                          return (
                            <div className="p-2 d-flex align-items-center">
                              <div className="col-md-1 p-0 m-0">
                                <img
                                  height={40}
                                  src={ProfilePicSample}
                                  alt="profilepic"
                                />
                              </div>
                              <div className="col-md-9 p-0 m-0 pl-3">
                                <div
                                  className="p-0 m-0"
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {user.username}
                                </div>
                                <div
                                  className="p-0 m-0"
                                  style={{ fontSize: 12 }}
                                >
                                  {user.email}
                                </div>
                              </div>
                              <div
                                className="col-md-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  removeGuestFromSelectedLust(user);
                                }}
                              >
                                <FaTimes style={{ fontSize: 12 }} />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="mt-3 d-flex align-items-center">
                      <img
                        className="pr-1"
                        height={25}
                        src={GoogleMeet}
                        alt="googlemeet"
                      />
                      <div
                        className="col-md-9 p-2 ml-3"
                        style={{
                          backgroundColor: "#6C63FF",
                          color: "white",
                          borderRadius: 10,
                          fontSize: 14,
                        }}
                        onClick={() => {
                          googleMeetLinkAdded
                            ? window.open(
                                "https://meet.google.com/lookup/" +
                                  googleMeetId,
                                "_blank"
                              )
                            : setgoogleMeetLinkAdded(true);
                        }}
                      >
                        {googleMeetLinkAdded
                          ? "Google Meet Video Conferencing"
                          : "Add Google Meet Video Conferencing"}
                      </div>
                      {googleMeetLinkAdded ? (
                        <div
                          className="col-md-1"
                          style={{ fontWeight: 900, cursor: "pointer" }}
                          onClick={() => {
                            setgoogleMeetLinkAdded(!googleMeetLinkAdded);
                          }}
                        >
                          X
                        </div>
                      ) : null}
                    </div>
                    <div className="errorDesc"></div>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      deleteEventHandler();
                    }}
                  >
                    <FaTrash style={{ fontSize: 12 }} />
                    <div className="pl-2">Delete this event</div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="col-md-12 p-0 text-left">
                    <div className="d-flex justify-content-center pb-2">
                      Event Details
                    </div>
                    <div className="form-group">
                    <Input
                      type="text"
                      style={{ width: "95%" }}
                      className="text-box-style mb-3 py-2 input_data mandatoryField"
                      placeholder="Event Name"
                      value={eventName}
                    />
                    <div className="errorDesc"></div>
                    </div>
                    <Input
                      type="text"
                      style={{ width: "95%" }}
                      className="text-box-style mb-3 py-2 "
                      placeholder="Add Event description"
                      value={eventDescription}
                    />
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Stack spacing={3}>
                        <div className="mb-3 d-flex" style={{ width: "95%" }}>
                          <MobileTimePicker
                            label="Start Time"
                            value={selectedStartTime}
                            onChange={(newValue) => {}}
                            renderInput={(params) => <TextField {...params} />}
                            minutesStep={5}
                          />
                          <MobileTimePicker
                            label="End Time"
                            value={selectedEndTime}
                            onChange={(newValue) => {}}
                            // minTime={selectedStartTime}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </div>
                      </Stack>
                    </LocalizationProvider>
                    <div>
                      <div>Participants</div>
                      <div className="p-2 d-flex align-items-center">
                        <div className="col-md-1 p-0 m-0">
                          <img
                            height={40}
                            src={ProfilePicSample}
                            alt="profilepic"
                          />
                        </div>
                        <div className="col-md-9 p-0 m-0 pl-3">
                          <div
                            className="p-0 m-0"
                            style={{
                              fontSize: 14,
                              fontWeight: 600,
                              textTransform: "capitalize",
                            }}
                          >
                            {
                              JSON.parse(localStorage.getItem("userData"))
                                .username
                            }
                          </div>
                          <div className="p-0 m-0" style={{ fontSize: 12 }}>
                            {JSON.parse(localStorage.getItem("userData")).email}
                          </div>
                        </div>
                      </div>
                      <div>
                        {guestSelectedList.map((user) => {
                          return (
                            <div className="p-2 d-flex align-items-center">
                              <div className="col-md-1 p-0 m-0">
                                <img
                                  height={40}
                                  src={ProfilePicSample}
                                  alt="profilepic"
                                />
                              </div>
                              <div className="col-md-9 p-0 m-0 pl-3">
                                <div
                                  className="p-0 m-0"
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {user.username}
                                </div>
                                <div
                                  className="p-0 m-0"
                                  style={{ fontSize: 12 }}
                                >
                                  {user.email}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="mt-3 d-flex align-items-center">
                      {googleMeetLinkAdded ? (
                        <div className="col-md-12 d-flex align-items-center justify-content-center">
                          <img
                            className="pr-1"
                            height={25}
                            style={{ cursor: "pointer" }}
                            src={GoogleMeet}
                            alt="googlemeet"
                            onClick={() => {
                              window.open(
                                "https://meet.google.com/lookup/" +
                                  googleMeetId,
                                "_blank"
                              );
                            }}
                          />
                          <div
                            className="col-md-10 ml-2 p-2"
                            style={{
                              backgroundColor: "#6C63FF",
                              color: "white",
                              borderRadius: 10,
                              fontSize: 14,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              window.open(
                                "https://meet.google.com/lookup/" +
                                  googleMeetId,
                                "_blank"
                              );
                            }}
                          >
                            {"Google Meet Video Conferencing"}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )
            ) : null}
          </div>
        </ModalComponent>

        <ModalComponent
          isVisible={eventAddModalVisible}
          mainHeading="Alert"
          cancelButtonText="Close"
          submitButtonText="Add"
          callback={eventAddModalHandler}
        >
          <div className="pt-2 pb-5 " id="alertFormContainer">
            <div className="col-md-12 p-0 text-left">
              <div className="form-group">
              <label>Please enter a new title for your appoinment</label>
              <Input
                type="text"
                autoComplete="new-password"
                style={{ width: "95%" }}
                className="text-box-style mb-3 py-2 input_data mandatoryField"
                placeholder="Event Name"
                value={eventName}
                onChange={(event) => {
                  setEventName(event.target.value);
                }}
              />
              <div className="errorDesc"></div>
              </div>
              <Input
                type="text"
                style={{ width: "95%" }}
                className="text-box-style mb-3 py-2 "
                placeholder="Add Event description"
                value={eventDescription}
                onChange={(event) => {
                  setEventDescription(event.target.value);
                }}
              />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Stack spacing={3}>
                  <div className="mb-3 d-flex" style={{ width: "95%" }}>
                    <MobileTimePicker
                      label="Start Time"
                      value={selectedStartTime}
                      onChange={(newValue) => {
                        console.log("Start Time newValue", newValue);
                        console.log("Start Time newValue", newValue.format());
                        handleStartTimeChange(newValue.format());
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      minutesStep={5}
                    />
                    <MobileTimePicker
                      label="End Time"
                      value={selectedEndTime}
                      onChange={(newValue) => {
                        console.log("End Time newValue", newValue);
                        console.log("End Time newValue", newValue.format());
                        console.log(
                          "maxTime",
                          moment().format().split("T")[0] +
                            "T" +
                            newValue.format().split("T")[1]
                        );
                        handleEndTimeChange(
                          moment().format().split("T")[0] +
                            "T" +
                            newValue.format().split("T")[1]
                        );
                      }}
                      maxTime={moment().endOf("day")}
                      minTime={moment(selectedStartTime).add(15, "minutes")}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </div>
                </Stack>
              </LocalizationProvider>
              <div className="p-0 m-0">
                <div>
                  <Input
                    type="text"
                    style={{ width: "95%" }}
                    className="text-box-style py-2 "
                    placeholder="Add Participants"
                    value={eventGuestTxt}
                    onChange={(event) => {
                      showGuestSuggestions(event.target.value);
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      width: "95%",
                      zIndex: 3,
                      backgroundColor: "white",
                    }}
                  >
                    {showGuestSuggestionsDiv ? (
                      <div style={{ maxHeight: "200px", overflow: "scroll" }}>
                        {guestSuggestionList.map((user) => {
                          console.log("user", user);
                          return (
                            <div
                              className="p-2 d-flex align-items-center guest-suggestion"
                              onClick={() => {
                                addGuestToselectedList(user);
                              }}
                            >
                              <img
                                height={40}
                                src={ProfilePicSample}
                                alt="profilepic"
                              />
                              <div className="p-0 m-0 pl-2">
                                <div
                                  className="p-0 m-0"
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 600,
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {user.username}
                                </div>
                                <div
                                  className="p-0 m-0"
                                  style={{ fontSize: 12 }}
                                >
                                  {user.email}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                  <div className="p-2 d-flex align-items-center">
                    <div className="col-md-1 p-0 m-0">
                      <img
                        height={40}
                        src={ProfilePicSample}
                        alt="profilepic"
                      />
                    </div>
                    <div className="col-md-9 p-0 m-0 pl-3">
                      <div
                        className="p-0 m-0"
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          textTransform: "capitalize",
                        }}
                      >
                        {JSON.parse(localStorage.getItem("userData")).username}
                      </div>
                      <div className="p-0 m-0" style={{ fontSize: 12 }}>
                        {JSON.parse(localStorage.getItem("userData")).email}
                      </div>
                    </div>
                  </div>
                  <div>
                    {guestSelectedList.map((user) => {
                      return (
                        <div className="p-2 d-flex align-items-center">
                          <div className="col-md-1 p-0 m-0">
                            <img
                              height={40}
                              src={ProfilePicSample}
                              alt="profilepic"
                            />
                          </div>
                          <div className="col-md-9 p-0 m-0 pl-3">
                            <div
                              className="p-0 m-0"
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                textTransform: "capitalize",
                              }}
                            >
                              {user.username}
                            </div>
                            <div className="p-0 m-0" style={{ fontSize: 12 }}>
                              {user.email}
                            </div>
                          </div>
                          <div
                            className="col-md-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              removeGuestFromSelectedLust(user);
                            }}
                          >
                            <FaTimes style={{ fontSize: 12 }} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="mt-3 d-flex align-items-center">
                  <img
                    className="pr-1"
                    height={25}
                    src={GoogleMeet}
                    alt="googlemeet"
                  />
                  <div
                    className="col-md-9 p-2 ml-3"
                    style={{
                      backgroundColor: "#6C63FF",
                      color: "white",
                      borderRadius: 10,
                      fontSize: 14,
                    }}
                    onClick={() => {
                      googleMeetLinkAdded
                        ? window.open(
                            "https://meet.google.com/lookup/" + googleMeetId,
                            "_blank"
                          )
                        : setgoogleMeetLinkAdded(true);
                    }}
                  >
                    {googleMeetLinkAdded
                      ? "Google Meet Video Conferencing"
                      : "Add Google Meet Video Conferencing"}
                  </div>
                  {googleMeetLinkAdded ? (
                    <div
                      className="col-md-1"
                      style={{ fontWeight: 900, cursor: "pointer" }}
                      onClick={() => {
                        setgoogleMeetLinkAdded(!googleMeetLinkAdded);
                      }}
                    >
                      X
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="errorDesc"></div>
            </div>
          </div>
        </ModalComponent>

        <ToastContainer position="top-center" />
    </div>
  )
}

export default Calender