import React, { useEffect, useState } from "react";
import LoadingAnimation from "../../utils/loadingAnimation";
import {
  FaSearch,
  FaCaretDown,
  FaCaretLeft,
  FaCaretRight,
  FaExpand,
  FaEdit,
  FaEye,
  FaReply,
  FaRegCheckCircle,
} from "react-icons/fa";
import EssayImg from "../../imageAssets/essayimg.png";
import UniNameImg from "../../imageAssets/universitynameimg.png";
import ProfilePicSample from "../../imageAssets/profilepicsample.png";
import DocumentBg from "../../imageAssets/documentbg.png";
import RightArrow from "../../imageAssets/rightarrow.png";
import CloseRed from "../../imageAssets/closeRed.png";
import RadioButtonSelected from "../../imageAssets/radiobutonselected.png";
import RadioButtonUnSelected from "../../imageAssets/radiobutonunselected.png";
import moment from "moment";
import ArrowDown from "../../imageAssets/arrowdown.png";
import RoundArrowRight from "../../imageAssets/roundArrowRight.png";
import RoundArrowLeft from "../../imageAssets/roundArrowLeft.png";
import PlusIconRound from "../../imageAssets/plusIconRound.png";
import StatsImg from "../../imageAssets/statsimg.png";
import axios from "axios";
import { MW_URL } from "../../config";
// import { Editor } from "@tinymce/tinymce-react";
import ModalComponent from "../../utils/modalComponent";
import { findNotifDate, findNotiTimeAgeFun } from "../../modules/parserHelper";
import { Button } from "reactstrap";
import { color } from "@mui/system";
import { roundToNearestMinutes } from "date-fns/esm";
import { queries } from "@testing-library/react";
import { useHistory } from 'react-router-dom';
// import EditorJS from '@editorjs/editorjs';
// import List from '@editorjs/list';
// import ImageTool from '@editorjs/image';
// const Header = require('@editorjs/header');
const StudentsPage = () => {
  const createUniquieId = () => {
    const head = Date.now().toString(36);
    const tail = Math.random().toString(36).substr(2);
    return head + tail;
  };

  const [height, setHeight] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [todolist, settodolist] = useState([
    { id: 1, title: "meeting with jessica smith", selected: true },
    { id: 2, title: "Begin editing common apps", selected: true },
    { id: 3, title: "Contact hoppe james", selected: false },
    { id: 4, title: "Watch sherlock", selected: false },
    { id: 5, title: "Build hatch module", selected: false },
  ]);
  const [notificationDiv, setnotificationDiv] = useState(false);
  const [listOfStudents, setlistOfStudents] = useState([]);
  const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
  const [essaysList, setessaysList] = useState([]);
  const [updatesList, setupdatesList] = useState([]);
  const [counsellorStudentDiv, setcounsellorStudentDiv] = useState(true);
  const [textEditorMain, settextEditorMain] = useState(true);
  const [feedbackPage, setfeedbackPage] = useState(false);
  const [queriesList, setqueriesList] = useState([]);
  const [essayContent, setessayContent] = useState(
    "<h1>HEADING</h1><p>TYPE OR PASTE YOUR TEXT HERE</p>"
  );
  const [essayId, setessayId] = useState("");
  const [isNewEssay, setisNewEssay] = useState(true);
  const [feedbacksList, setfeedbacksList] = useState({
    feedbacks: [],
    currentPage: 1,
    feedbackPerPage: 4,
  });
  const [editorVisible, setEditorVisible] = useState(false);
  const [eventEditModalVisible, seteventEditModalVisible] = useState(false);
  const [feedbackInTextarea, setfeedbackInTextarea] = useState("");
  const history = useHistory();
  const [selectedStudentDetails, setselectedStudentDetails] = useState({
    username: "username",
  });
  const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState(
    []
  );
  const [collageFilterCollageId, setcollageFilterCollageId] = useState("");
  const [essaysTemplateList, setessaysTemplateList] = useState([]);
  const [editorData, setEditorData] = useState(null);
  const [essayCollId, setessayCollId] = useState("");
  const [essayReqId, setessayReqId] = useState("");
  const [editable, setEditable] = useState();
  useEffect(() => {
    var windowHeight = window.innerHeight;
    setHeight(windowHeight + "px");
    getlistofusersFromDb();
    getUpdateDetails();
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [isUseEffectCallRequired]);

  const getlistofusersFromDb = () => {
    axios
      .post(`${MW_URL}` + "users", {})
      .then((getUsersResponse) => {
        if (
          getUsersResponse &&
          getUsersResponse.data &&
          getUsersResponse.data[0]
        ) {
          axios
            .post(`${MW_URL}` + "getUpdates", {
              recipientId: JSON.parse(localStorage.getItem("userData")).email,
              senderId: false,
            })
            .then((getUpdatesResponse) => {
              // console.log("getUpdatesResponse", getUpdatesResponse.data);
              //  setupdatesList(getUpdatesResponse.data);

              var userList = [];
              for (var i = 0; i < getUsersResponse.data.length; i++) {
                var currentObj = getUsersResponse.data[i];
                var currentDate = new Date();
                currentDate = currentDate.setDate(currentDate.getDate() - 2);
                if (
                  currentObj.counsellorId ===
                  JSON.parse(localStorage.getItem("userData")).email
                ) {
                  userList.push({
                    key: currentObj.email,
                    username: currentObj.username,
                    email: currentObj.email,
                    userType: currentObj.userType,
                    major: currentObj.major,
                    updates: getUpdatesResponse.data,
                    updatesNum: getUpdatesResponse.data.filter(
                      (updte) =>
                        updte.senderId == currentObj.email &&
                        moment(updte.updatedDate, "YYYY-MM-DD").isSameOrBefore(
                          new Date(),
                          "day"
                        ) &&
                        moment(updte.updatedDate, "YYYY-MM-DD").isSameOrAfter(
                          currentDate,
                          "day"
                        )
                    ).length,
                  });
                }
              }
              // console.log("userList===>", userList);
              setlistOfStudents(userList);
              let params = new URLSearchParams(window.location.search);
              if (params.get("studentEmail")) {
                // console.log("studentEmail", params.get("studentEmail"));
                const selectedUser = userList.find(
                  (user) => user.email == params.get("studentEmail")
                );
                // console.log("selectedUser", selectedUser);
                showTextEditorMain(selectedUser);
              }
            })
            .catch((err) => {
              console.log("err >> ", err);
            });
        }
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };

  const getStudentEssays = (student) => {
    // console.log("==============getStudentEssays fun  ========", student);
    setIsLoading(true);
    var selectedCollages = [];
    var selectedCollagesIds = [];
    axios
      .post(`${MW_URL}` + "getSelectedListOfCollages", {
        email: student.email,
      })
      .then((getSelectedListOfCollagesresponse) => {
        if (
          getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
            .length > 0
        ) {
          getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
            (collage) => {
              selectedCollages.push(collage);
              selectedCollagesIds.push(collage.collageId);
            }
          );
          setlistOfSelectedCollagesArr(selectedCollages);
          var essayReqIds = [];
          var templateArr = [];
          var essaysListToPush = [];
          var essaysRequirementIds = [];
          axios
            .post(`${MW_URL}` + "get_essay_requirement", selectedCollages)
            .then((getEssaysTemplateResponse) => {
              var status = "NA";
              if (
                getEssaysTemplateResponse &&
                getEssaysTemplateResponse.data &&
                getEssaysTemplateResponse.data.STATUS
              )
                status = getEssaysTemplateResponse.data.STATUS;
              if (status.toUpperCase() === "SUCCESS") {
                setessaysTemplateList(getEssaysTemplateResponse.data.data);
                getEssaysTemplateResponse.data.data.map((templateRes) => {
                  if (templateRes.essayRequirements.length != 0) {
                    templateRes.essayRequirements.map((esaaReq) => {
                      essayReqIds.push(esaaReq.essayReqId);
                      templateArr.push({
                        template: esaaReq.question,
                        collageId: templateRes.collageId,
                        name: templateRes.name,
                        essayReqId: esaaReq.essayReqId,
                        length: esaaReq.length,
                        requirement: esaaReq.requirement,
                        answered: essaysRequirementIds.includes(
                          esaaReq.essayReqId
                        ),
                      });
                    });
                  }
                });
                axios
                  .post(`${MW_URL}` + "getEssays", { ownerId: student.email })
                  .then((getAllEssaysResponse) => {
                    var essaysListToPush = [];
                    var essaysRequirementIds = [];
                    if (getAllEssaysResponse.data.length > 0) {
                      getAllEssaysResponse.data.map((essay) => {
                        if (
                          essay.ownerId == student.email &&
                          selectedCollagesIds.includes(essay.collageId) &&
                          essayReqIds.includes(essay.essayReqId)
                        ) {
                          essaysListToPush.push({
                            essayId: essay.essayId,
                            content: essay.content,
                            newEditorContentEssay:essay.newEditorcontentEssay,
                            typeOfDocument: essay.typeOfDocument,
                            collageId: essay.collageId,
                            ownerId: essay.ownerId,
                            feedbacks: essay.feedbacks,
                            essayReqId: essay.essayReqId,
                            isApproved: essay.isApproved,
                          });
                        }
                        essaysRequirementIds.push(essay.essayReqId);
                      });
                      const params = new URLSearchParams(
                        window.location.search
                      );
                      if (params.get("id")) {
                        const selectedEssay = essaysListToPush.find(
                          (essay) => essay.essayId == params.get("id")
                        );
                        viewEssay(selectedEssay);
                      } else {
                        console.log("No params");
                      }
                      axios
                        .post(`${MW_URL}` + "getUpdates", {
                          recipientId: JSON.parse(
                            localStorage.getItem("userData")
                          ).email,
                          senderId: student.email,
                        })
                        .then((getUpdatesResponse) => {
                          setupdatesList(getUpdatesResponse.data);
                          setessaysTemplateList(
                            templateArr.map((reqEssay) => {
                              return {
                                ...reqEssay,
                                answered: essaysRequirementIds.includes(
                                  reqEssay.essayReqId
                                ),
                              };
                            })
                          );
                          setIsLoading(false);
                        })
                        .catch((err) => {
                          console.log("err >> ", err);
                        });
                    } else {
                      setessaysList([]);
                    }
                    setessaysList(essaysListToPush);
                  })
                  .catch((err) => {
                    console.log("err >> ", err);
                  });
              } else {
                setIsLoading(false);
              }
            })
            .catch((err) => {
              console.log("err >> ", err);
            });
        } else {
          setlistOfSelectedCollagesArr([]);
          setessaysTemplateList([]);
          setessaysList([]);
          setupdatesList([]);
          setIsLoading(false);
        }
      })
      .catch((err) => { });
  };

  const getUpdateDetails = () => {
    axios
      .post(`${MW_URL}` + "getUpdates", {
        recipientId: JSON.parse(localStorage.getItem("userData")).email,
        senderId: false,
      })
      .then((getUpdatesResponse) => {
        // console.log("getUpdatesResponse", getUpdatesResponse.data);
        setupdatesList(getUpdatesResponse.data);

        axios
          .post(`${MW_URL}` + "getQueriesByRecipientId", {
            recipientId: JSON.parse(localStorage.getItem("userData")).email,
            senderId: false,
          })
          .then((getQueriesByRecipientIdResponse) => {
            setqueriesList(getQueriesByRecipientIdResponse.data);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };

  const eventEditModalHandler = (dataObject) => {
    if (dataObject.type === "cancel") {
      seteventEditModalVisible(false);
    } else if (dataObject.type === "submit") {
      setfeedbacksList({
        ...feedbacksList,
        feedbacks: [
          ...feedbacksList.feedbacks,
          {
            feedback: feedbackInTextarea,
            usertype: JSON.parse(localStorage.getItem("userData")).userType,
          },
        ],
      });
      var dataToSend = {
        essayId: essayId,
        collageId: essayCollId,
        feedbacks: [
          ...feedbacksList.feedbacks,
          {
            feedback: feedbackInTextarea,
            usertype: JSON.parse(localStorage.getItem("userData")).userType,
          },
        ],
        updateId: createUniquieId(),
        recipientId: selectedStudentDetails.email,
        senderId: JSON.parse(localStorage.getItem("userData")).email,
        message:
          JSON.parse(localStorage.getItem("userData")).username +
          " added a feedback",
      };
      setIsLoading(true);
      axios
        .post(`${MW_URL}` + "updatefeedbacks", dataToSend)
        .then((response) => {
          setIsLoading(false);
          var status = "NA";
          if (response && response.data && response.data.STATUS)
            status = response.data.STATUS;
          if (status.toUpperCase() === "SUCCESS") {
            setIsLoading(false);
            setfeedbackInTextarea("");
            seteventEditModalVisible(false);
            setfeedbacksList({
              ...feedbacksList,
              feedbacks: [
                ...feedbacksList.feedbacks,
                {
                  feedback: feedbackInTextarea,
                  usertype: JSON.parse(localStorage.getItem("userData"))
                    .userType,
                },
              ],
              currentPage: Math.ceil(
                (feedbacksList.feedbacks.length + 1) /
                feedbacksList.feedbackPerPage
              ),
            });
          } else {
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    }
  };

  const deleteFeedback = (index) => {
    setIsLoading(true);
    var { feedbacks, currentPage, feedbackPerPage } = feedbacksList;

    //calculate the feedback index to delete
    var feedbackindextodelete = (currentPage - 1) * feedbackPerPage + index;

    feedbacks.splice(feedbackindextodelete, 1);

    var dataToSend = {
      essayId: essayId,
      feedbacks: feedbacks,
      updateId: createUniquieId(),
      recipientId: selectedStudentDetails.email,
      senderId: JSON.parse(localStorage.getItem("userData")).email,
      message:
        JSON.parse(localStorage.getItem("userData")).username +
        " deleted a feedback",
    };
    setIsLoading(true);
    axios
      .post(`${MW_URL}` + "updatefeedbacks", dataToSend)
      .then((response) => {
        var status = "NA";
        if (response && response.data && response.data.STATUS)
          status = response.data.STATUS;
        if (status.toUpperCase() === "SUCCESS") {
          setIsLoading(false);
          setfeedbacksList({
            ...feedbacksList,
            feedbacks: feedbacks,
          });
        } else {
        }
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };

  const showCounsellorStudents = () => {
    setcounsellorStudentDiv(true);
    settextEditorMain(false);
  };

  const showTextEditorMain = (student) => {
    // setEditorVisible(true)
    setselectedStudentDetails(student);
    getStudentEssays(student);
    setcounsellorStudentDiv(false);
    settextEditorMain(true);
  };
  const showfeedbackPage = () => {
    // setIsLoading(true);
    setcounsellorStudentDiv(false);
    settextEditorMain(false);
  };

  var todayDate = moment().format("dddd/MMM/DD/YYYY");
  var today = todayDate.split("/");
  const defaultDateValue = {
    weekday: today[0],
    year: parseInt(today[3]),
    month: parseInt(today[2]),
    day: today[1],
  };

  const updateTodoList = (todoSelected) => {
    settodolist(
      todolist.map((todo) => {
        if (todo.id == todoSelected.id) {
          if (todo.selected) {
            return {
              ...todo,
              selected: false,
            };
          } else {
            return {
              ...todo,
              selected: true,
            };
          }
        } else {
          return {
            ...todo,
            selected: todo.selected,
          };
        }
      })
    );
  };

  const handleOnChange = (e) => {
    setessayContent(e.target.getContent());
  };
  const handleOnEditorChange = (content) => {
    setessayContent(content);
  };

  const addNewEssay = (template) => {
    // setIsLoading(true);
    // setEditorVisible(true)
    // setEditable(true);
    var essaytemplate = {
      time: "",
      blocks: [
        {
          type: "",
          data: {
            text: ""
          }
        }
      ],
      version: "2.11.10"
    }

    essaytemplate = {
      time: "",
      blocks: [
        {
          type: "paragraph",
          data: {
            text: template.template
          }
        }
      ],
      version: "2.11.10"
    }
    editorinitializw(essaytemplate)
    setisNewEssay(true);
    setfeedbacksList({
      ...feedbacksList,
      feedbacks: [],
    });
    setessayId(createUniquieId());
    //resetEssayContent();
    setessayContent(template.template);
    setessayCollId(template.collageId);
    setessayReqId(template.essayReqId);
    showfeedbackPage();
  };

  const editEssay = (essay) => {
    // setEditorVisible(true)
    setEditable(true);
    editorinitializw(essay.newEditorContentEssay);
    setIsLoading(false)
    setisNewEssay(false);
    setfeedbacksList({
      currentPage: 1,
      feedbackPerPage: 4,
      feedbacks: essay.feedbacks,
    });
    setEditable(true);
    setessayId(essay.essayId);
    setessayContent(essay.content);
    setessayCollId(essay.collageId);
    setessayReqId(essay.essayReqId);
    showfeedbackPage();
  };
  const viewEssay = (essay) => {
    setEditable(false);
    editorinitializw(essay.newEditorContentEssay);
    setIsLoading(false)
    setisNewEssay(false);
    setfeedbacksList({
      currentPage: 1,
      feedbackPerPage: 4,
      feedbacks: essay.feedbacks,
    });
    setessayId(essay.essayId);
    setessayContent(essay.content);
    setessayCollId(essay.collageId);
    setessayReqId(essay.essayReqId);
    showfeedbackPage();
  };
  const updateEssayInDb = () => {
    editEssayInDb();
  };

  const editEssayInDb = () => {
    var essayCont = []
    let essayCollege = listOfSelectedCollagesArr.find((col) => {
      return col.collageId == essayCollId;
    });
    editorData.save().then((outputData) => {
        outputData.blocks.map((es) => {
          essayCont.push(es.data.text);
        })
        var esContent = essayCont.toString();
    var dataToSend = {
      essayId: essayId,
      content: esContent,
      newEditorContent: outputData,
      typeOfDocument: "essay",
      collageId: essayCollId,
      ownerId: selectedStudentDetails.email,
      feedbacks: feedbacksList.feedbacks,
      essayReqId: essayReqId,
      updateId: createUniquieId(),
      recipientId: selectedStudentDetails.email,
      senderId: JSON.parse(localStorage.getItem("userData")).email,
      message:
        JSON.parse(localStorage.getItem("userData")).username +
        " updated " +
        essayCollege.name +
        "'s essay" + essayContent
    };
    setIsLoading(true);
    axios
      .post(`${MW_URL}` + "editEssay", dataToSend)
      .then((response) => {
        var status = "NA";
        if (response && response.data && response.data.STATUS)
          status = response.data.STATUS;
        if (status.toUpperCase() === "SUCCESS") {
          setIsLoading(false);
          // showTextEditorMain(selectedStudentDetails);
          showCounsellorStudents();
        } else {
        }
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
    }).catch((error) => {
      console.log('Saving failed: ', error)
    });
  };
  const approveEssay = (essay) => {
    var dataToSend = {
      essayId: essay.essayId,
      isApproved: true,
    };
    setIsLoading(roundToNearestMinutes);
    setIsLoading(true);
    axios
      .post(`${MW_URL}` + "approveEssay", dataToSend)
      .then((response) => {
        setIsLoading(false);
        var status = "NA";
        if (response && response.data && response.data.STATUS)
          status = response.data.STATUS;
        setIsUseEffectCallRequired(true);
        // window.location.reload(false);
        setessaysList(
          essaysList.map((essays) => {
            if (essay.essayId == essays.essayId) {
              if (essays.isApproved) {
                return {
                  ...essays,
                  isApproved: false,
                };
              } else {
                return {
                  ...essays,
                  isApproved: true,
                };
              }
            } else {
              return {
                ...essays,
                isApproved: essays.isApproved,
              };
            }
          })
        );
      })
      .catch((err) => {
        console.log("err >> ", err);
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };
  const testCall = () => {
    axios
      .post(`${MW_URL}` + "sampleCall", {})
      .then((response) => { })
      .catch((err) => { });
  };
  var student = []
  listOfStudents.map((stud) => {
    // console.log("time", stud)
    student.push(stud.email)
  })
  // console.log("time22", student)
  var times = []
  updatesList.map((time) => {
    if (student.includes(time.senderId)) {
      times = moment.utc(time.updatedDate).local().startOf('seconds').fromNow()
      // console.log("time22", times)
    }
  })

  const editorinitializw = (newEssayContent) => {
    // const Editor = {
    //   holderId: 'editorjs',
    //   readOnly: editable,
    //   placeholder: 'Write Essay',
    //   autofocus: true,
    //   // data:newEssayContent,
    //   tools: {
    //     header: {
    //       class: Header,
    //       inlineToolbar: ['link']
    //     },
    //     list: {
    //       class: List,
    //       inlineToolbar: ['link', 'bold']
    //     },
    //   },
    //   data: typeof newEssayContent === "string" ? JSON.parse(newEssayContent) : newEssayContent
    // }
    // const editor = new EditorJS(Editor);
    // setEditorData(editor)
}
  return (
    <div>
      {isLoading ? <LoadingAnimation isVisible={true} /> : null}
      <div
        className="text-editor-page-container pb-5"
        style={{ minHeight: height }}
      >
        <div>
          <div
            className="col-md-12 pl-5 pt-5  d-flex justify-content-between"
            style={{ width: "100%" }}
          >
            <div
              className="col-md-6 searchbox-style d-flex align-items-center"
              style={{ height: 30 }}
            >
              <div className="col-md-11">
                <input
                  style={{ border: 0, width: "100%" }}
                  type="text"
                  id="search"
                  name="search"
                  placeholder="TYPE ''MICHAEL''"
                />
              </div>
              <div className="col-md-1 p-0 m-0 d-flex justify-content-end">
                <FaSearch style={{ fontSize: 12 }} />
              </div>
            </div>
            <div className="col-md-3 p-0 pr-4 d-flex justify-content-end align-items-center text-right">
              <div className="pr-2 col-md-8">
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    textTransform: "uppercase",
                  }}
                >
                  {JSON.parse(localStorage.getItem("userData")).username}
                </div>
                <div style={{ fontSize: 7 }}>WELCOME BACK</div>
              </div>
              <div className="p-0 m-0 col-md-3" style={{ zIndex: 2 }}>
                {notificationDiv ? (
                  <div className="col-md-12 m-0 p-0 animated fade-in-right">
                    <div
                      className="m-3 p-0 py-4 pl-4 card-design"
                      style={{
                        width: 240,
                        borderRadius: 15,
                        position: "absolute",
                        right: 0,
                      }}
                    >
                      {notificationRow()}
                      {notificationRow()}
                      {notificationRow()}
                      {notificationRow()}
                      {notificationRow()}
                    </div>
                  </div>
                ) : null}
                {/* <div className="col-md-12" style={{ zIndex: 2 }}>
                  <div
                    className="d-flex justify-content-center"
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      fontSize: 10,
                      width: 15,
                      height: 15,
                      position: "absolute",
                      right: "5%",
                      top: 4,
                      borderRadius: "50%",
                    }}
                  >
                    5
                  </div>
                </div> */}
                <img
                  onClick={() => setnotificationDiv(!notificationDiv)}
                  className="col-md-12 p-0"
                  style={{ zIndex: 1, cursor: "pointer" }}
                  height={60}
                  src={ProfilePicSample}
                  alt="dpsaple"
                />
              </div>
            </div>
          </div>
          {counsellorStudentDiv ? (
            <div>
              <div className="px-4 pt-4 d-flex justify-content-between align-items-center">
                <div className="col-md-6 d-flex">
                  <div className="heading-style pr-2">batch 2021-2022</div>
                  <div className="p-0">
                    <img height={6} src={ArrowDown} alt="arrowdown" />
                  </div>
                </div>
                <div className="col-md-6  p-0 ">
                  <div className="d-flex justify-content-end align-items-center">
                    <div
                      className="link-button-style pr-2"
                      onClick={() => {
                        testCall();
                      }}
                    >
                      View All
                    </div>
                    <img
                      className="pr-1"
                      height={15}
                      src={RoundArrowLeft}
                      alt="statsimg"
                    />
                    <img height={15} src={RoundArrowRight} alt="statsimg" />
                  </div>
                </div>
              </div>
              <div className="col-md-12 pl-4 pt-4 d-flex">
                {listOfStudents.map((student) => {
                  return studentDp(student);
                })}
              </div>
              <div className="col-md-12 d-flex">
                <div className="col-md-8 mt-4 ml-4">
                  {listOfStudents.map((student) => {
                    // console.log("student ===>", student)
                    return (
                      <div
                        className=" col-md-12 d-flex justify-content-between align-items-center"
                        style={{
                          borderBottom: "1px solid #ccc",
                        }}
                      >
                        <div
                          className="col-md-2 "
                          onClick={() => {
                            showTextEditorMain(student);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            style={{ display: "block" }}
                            height={60}
                            src={ProfilePicSample}
                          />
                        </div>
                        <div
                          className="col-md-2 mt-3 d-flex justify-content-between align-items-center"
                          style={{
                            alignItems: "end",
                          }}
                        >
                          <b>{student.username}</b>
                        </div>
                        <div
                          className="col-md-2 mt-3 d-flex justify-content-between align-items-center"
                          style={{
                            alignItems: "end",
                          }}
                        >
                          {student.major}
                        </div>
                        <div
                          className="col-md-3 mt-3 d-flex justify-content-between align-items-center"
                          style={{
                            alignItems: "end",
                            fontSize: 10,
                            color: "grey",
                            backgroundColor: "red"
                          }}
                        >
                          {student.updatesNum}
                        </div>
                        <div
                          className="col-md-3 mt-3 d-flex justify-content-between align-items-center"
                          style={{
                            alignItems: "end",
                            fontSize: 10,
                            color: "grey",
                          }}
                        >
                          {student.updatesNum} recent updates
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="col-md-4">
                  <div className="p-0 pr-3 pt-4">
                    <div>
                      <div className="col-md-8 p-0 m-0 heaidng-style">
                        Stats
                      </div>
                      <div className="col-md-12 p-0 mt-4 card-design">
                        {statsComponent()}
                      </div>
                    </div>
                    <div>
                      <div className="d-flex p-0 py-3 pt-4 m-0 justify-content-between">
                        <div className="col-md-10 p-0 m-0 heaidng-style">
                          queries
                        </div>
                        <div className="col-md-2 p-0 m-0 d-flex justify-content-between align-items-center">
                          <FaCaretLeft style={{ fontSize: 12 }} />
                          <FaCaretRight style={{ fontSize: 12 }} />
                        </div>
                      </div>
                      <div>{queriesComponent()}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : textEditorMain ? (
            <div>
              <div className="col-md-12 pl-4 pt-4 d-flex justify-content-between">
                {bottomLeftComponent()}
                <div className="col-md-6 pl-4">
                  <div className="heaidng-style">essays</div>
                  <div className="col-md-12 m-0 p-0 mt-3 d-flex flex-column align-items-center">
                    <div
                      className="col-md-12"
                      style={{
                        maxHeight: (window.innerHeight * 72) / 100 + "px",
                        overflow: "scroll",
                      }}
                    >
                      <div style={{ fontSize: 12, fontWeight: 600 }}>
                        Answered Essays
                      </div>
                      {essaysList.length == 0 ? (
                        <div className="col-md-12 d-flex align-items-center justify-content-center">
                          No Essays Submitted
                        </div>
                      ) : null}
                      {essaysList.map((essay) => {
                        if (collageFilterCollageId == "") {
                          return essaysComponent(essay);
                        } else if (collageFilterCollageId == essay.collageId) {
                          return essaysComponent(essay);
                        } else {
                          return null;
                        }
                      })}
                      <div style={{ fontSize: 12, fontWeight: 600 }}>
                        Requirement
                      </div>
                      {essaysTemplateList.map((template) => {
                        if (collageFilterCollageId == "") {
                          return essayTemplateComponent(template);
                        } else if (
                          collageFilterCollageId == template.collageId
                        ) {
                          return essayTemplateComponent(template);
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="p-0 m-0"
              style={{ minHeight: (window.innerHeight * 65) / 100 + "px" }}
            >
              <div
                className="col-md-12 d-flex py-3 px-4"
                style={{ height: "100%" }}
              >
                <div className="col-md-7 pr-0">
                  {/* <Editor
                    apiKey="ja9tz9km2k08vicadj1vsr91jfmjaz9usmtzwc9zdywcx8wh"
                    value={essayContent}
                    disabled={editable}
                    // initialValue="<h1>HEADING</h1><p>TYPE OR PASTE YOUR TEXT HERE</p>"
                    init={{
                      height: 500,
                      menubar: false,
                      toolbar: editable
                        ? false
                        : "undo redo | formatselect | bold italic | \
                                        alignleft aligncenter alignright | \
                                        bullist numlist outdent indent | help",
                    }}
                    onChange={handleOnChange}
                    onEditorChange={handleOnEditorChange}
                    onInit={() => {
                      setIsLoading(false);
                    }}
                  />*/}
               <div id='editorjs'></div>
                 
                  <div className="col-md-12 pt-2 d-flex justify-content-between">
                    <div
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={() => {
                        const params = new URLSearchParams(
                          window.location.search
                        );
                        if (params.get("id")) {
                          history.push("/counsellorStudents");
                        }
                        showCounsellorStudents();
                      }}
                    >
                     {"< Back"}
                    </div>
                    {editable === true ? (
                      <div
                        style={{ fontWeight: 800, cursor: "pointer" }}
                        onClick={() => {
                          updateEssayInDb();
                        }}
                      >
                        Upload Document
                      </div>
                    ) : null}
                  </div>
                </div>
                <div
                  className="col-md-1 d-flex align-items-center justify-content-center"
                  style={{ height: (window.innerHeight * 65) / 100 + "px" }}
                >
                  <div
                    style={{
                      backgroundColor: "#FAB566",
                      height: (window.innerHeight * 55) / 100 + "px",
                      width: "3px",
                    }}
                  ></div>
                </div>
                <div className="col-md-3 pl-0 pt-5">
                  <div
                    className="pb-2 pl-5 ml-2"
                    style={{ fontWeight: "800", fontSize: 20 }}
                  >
                    FEEDBACK
                  </div>
                  {renderFeedback()}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalComponent
        isVisible={eventEditModalVisible}
        mainHeading="Add a feedback"
        cancelButtonText="Cancel"
        submitButtonText="Save"
        callback={eventEditModalHandler}
      >
        <div className="pt-2 pb-5">
          <textarea
            value={feedbackInTextarea}
            placeholder="TYPE YOUR FEEDBACK HERE"
            onChange={(event) => {
              setfeedbackInTextarea(event.target.value);
            }}
            id="w3review"
            name="w3review"
            rows="4"
            cols="42"
          >
            Write a feedback
          </textarea>
        </div>
      </ModalComponent>
    </div>
  );

  function handleClick(event) {
    setfeedbacksList({
      ...feedbacksList,
      currentPage: Number(event.target.id),
    });
  }

  function renderFeedback() {
    const { feedbacks, currentPage, feedbackPerPage } = feedbacksList;

    // Logic for displaying todos
    const indexOfLastFeedback = currentPage * feedbackPerPage;
    const indexOfFirstFeedback = indexOfLastFeedback - feedbackPerPage;
    const currentFeedbacks = feedbacks.slice(
      indexOfFirstFeedback,
      indexOfLastFeedback
    );

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(feedbacks.length / feedbackPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div>
        <div>
          {currentFeedbacks.map((feedback, index) => {
            return feedbackComponent(feedback, index);
          })}
        </div>
        <div
          className="col-md-12 d-flex align-items-center pt-3 px-5"
          style={{
            justifyContent: pageNumbers.length > 0 ? "space-between" : "center",
          }}
        >
          {pageNumbers.map((number, index) => {
            return (
              <div
                style={{
                  cursor: "pointer",
                  color: currentPage == number ? "black" : "grey",
                }}
                key={number}
                id={number}
                onClick={handleClick}
              >
                {number}
              </div>
            );
          })}
          <img
            height={30}
            style={{ cursor: "pointer" }}
            src={PlusIconRound}
            alt="addicon"
            onClick={() => {
              seteventEditModalVisible(true);
            }}
          />
        </div>
      </div>
    );
  }

  function feedbackComponent(feedbackContent, index) {
    return (
      <div className="col-md-12 m-0 p-0 d-flex align-items-center">
        <img
          style={{ cursor: "pointer" }}
          height={15}
          src={CloseRed}
          alt="CloseRed"
          onClick={() => {
            deleteFeedback(index);
          }}
        />
        <div
          className="col-md-10 p-0 pt-3 pb-3 ml-3"
          style={{ borderBottom: "1px solid #00000029" }}
        >
          <div
            className=""
            style={{
              maxHeight: (window.innerHeight * 6) / 100 + "px",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              fontSize: 8,
              fontWeight: 600,
              textAlign: "left",
              textOverflow: "ellipsis",
              color: feedbackContent.usertype == "counsellor" ? "red" : "black",
            }}
          >
            {feedbackContent.feedback}
          </div>
        </div>
      </div>
    );
  }

  function notificationRow(
    head = "TRENDING",
    content = "Ivy league schools make SAT scores optional"
  ) {
    return (
      <div className="col-md-12 p-2 pl-2">
        <div className="trending-head text-left">{head}</div>
        <div className="col-md-4 divider-bar"></div>
        <div
          className="col-md-8 m-0 p-0 pt-1 text-left"
          style={{ fontSize: 10, lineHeight: 1 }}
        >
          {content}
        </div>
      </div>
    );
  }

  function studentDp(student) {
    // console.log("student ====>", student);
    return (
      <div
        key={student.key}
        className="d-flex flex-column align-items-center"
        style={{ cursor: "pointer" }}
        onClick={() => {
          showTextEditorMain(student);
        }}
      >
        <div>
          <div className="col-md-12" style={{ zIndex: 2 }}>
            <div
              className="d-flex justify-content-center"
              style={{
                backgroundColor: "red",
                color: "white",
                fontSize: 10,
                width: 18,
                height: 18,
                fontWeight: 800,
                position: "absolute",
                right: "14%",
                top: 14,
                borderRadius: "50%",
              }}
            >
              {student.updatesNum}
            </div>
          </div>
          <img
            className="col-md-12 p-0"
            style={{ zIndex: 1 }}
            height={140}
            src={ProfilePicSample}
            alt="dpsaple"
          />
        </div>
        <div className="student-name" style={{ cursor: "pointer" }}>
          {student.username}
        </div>
        <div className="student-dept" style={{ cursor: "pointer" }}>
          Psycology
        </div>
      </div>
    );
  }

  function essaysComponent(essay) {
    let essayCollege = listOfSelectedCollagesArr.find((col) => {
      return col.collageId == essay.collageId;
    });

    // console.log("essay =====>", essay);
    const styles = {
      approved: {
        color: "green",
      },
      notapproved: {
        color: "red",
      },
    };
    return (
      <div
        key={essay.essayId}
        className="col-md-12 m-0 p-0 d-flex align-items-center"
        style={{ borderBottom: "1px solid #00000029" }}
      >
        <div className="col-md-2 p-0 m-0 d-flex align-items-center justify-content-center">
          <img height={65} src={EssayImg} alt="essayimg" />
        </div>
        <div className="p-0 pt-3 col-md-10">
          <div
            className="d-flex pr-1"
            style={{
              height: (window.innerHeight * 4) / 100 + "px",
              fontSize: 7,
              fontWeight: 600,
              whiteSpace: "pre-line",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
            }}
          >
            {essay.content.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, "")}
          </div>
          <div className="d-flex">
            <div className="pr-2" style={{ fontSize: 8, color: "grey" }}>
              8 mins ago
            </div>
            <div style={{ fontSize: 8 }}>{essayCollege.name}</div>
          </div>
          <div className="d-flex justify-content-end p-0 pr-3">
            <FaRegCheckCircle
              className="pr-2"
              cursor={"pointer"}
              size={25}
              style={essay.isApproved ? styles.approved : styles.notapproved}
              onClick={() => {
                approveEssay(essay);
              }}
            ></FaRegCheckCircle>
            <FaExpand
              className="pr-2"
              style={{ fontSize: 16, color: "grey", cursor: "pointer" }}
            />
            {essay.isApproved == false ? (
              <FaEdit
                className="pr-2"
                style={{ fontSize: 16, color: "grey", cursor: "pointer" }}
                onClick={() => {
                  editEssay(essay);
                }}
              />
            ) : null}
            <FaEye
              className="pr-1"
              style={{ fontSize: 16, color: "grey", cursor: "pointer" }}
              onClick={() => {
                viewEssay(essay);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  function essayTemplateComponent(template) {
    return (
      <div
        className="col-md-12 m-0 p-0 d-flex align-items-center"
        style={{ borderBottom: "1px solid #00000029" }}
      >
        <div className="col-md-2 p-0 m-0 d-flex align-items-center justify-content-center">
          <img height={65} src={EssayImg} alt="essayimg" />
        </div>
        <div className="p-0 pt-3 col-md-10">
          <div
            className="d-flex pr-1"
            style={{
              height: (window.innerHeight * 4) / 100 + "px",
              fontSize: 7,
              fontWeight: 600,
              whiteSpace: "pre-line",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
            }}
          >
            {template.template}
          </div>
          <div className="d-flex">
            <div className="pr-2" style={{ fontSize: 8, color: "grey" }}>
              8 mins ago
            </div>
            {/* <img height={15} src={UniNameImg} alt="uninameimg" /> */}
            <div className="pl-2" style={{ fontSize: 8, color: "grey" }}>
              {template.name}
            </div>
          </div>
          <div className="d-flex justify-content-end p-0 pr-3">
            {template.answered ? null : (
              <FaReply
                className="pr-1"
                style={{ fontSize: 16, color: "grey", cursor: "pointer" }}
                onClick={() => {
                  addNewEssay(template);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  function bottomLeftComponent() {
    return (
      <div className="col-md-6">
        <div className="d-flex justify-content-between">
          <div className="col-md-8 heaidng-style">documents</div>
          <div className="col-md-4 d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center">
              <div className="link-text-style">Deadline</div>
              <FaCaretDown style={{ fontSize: 12 }} />
            </div>
            <div className="link-text-style">View All</div>
            <FaCaretLeft style={{ fontSize: 12 }} />
            <FaCaretRight style={{ fontSize: 12 }} />
          </div>
        </div>
        <div className="col-md-12 d-flex " style={{ overflow: "scroll" }}>
          {listOfSelectedCollagesArr.length == 0 ? (
            <div
              className="p-5 align-items-center justify-content-center link-text-style"
              style={{ textAlign: "center", fontSize: 14 }}
            >
              No Collages Selected by the Student
            </div>
          ) : (
            listOfSelectedCollagesArr.map((selectedColl) => {
              return documentComponent(selectedColl);
            })
          )}
        </div>
        <div className="pt-4 pl-0 ml-0 d-flex justify-content-between">
          <div className="col-md-6 pt-5 mt-5 pr-5 pl-0 ml-0">
            <div className="d-flex flex-column align-items-center">
              <div>
                <img
                  className="col-md-12 p-0"
                  style={{ zIndex: 1 }}
                  height={140}
                  src={ProfilePicSample}
                  alt="dpsaple"
                />
              </div>
              <div className="student-name">
                {selectedStudentDetails.username}
              </div>
              <div
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() => {
                  showCounsellorStudents();
                }}
              >
              {"< Back"}
              </div>
            </div>
          </div>
          <div className="col-md-6 pt-4">
            <div className="heaidng-style">updates</div>
            <div
              className="col-md-12 p-0 pt-2 mt-4 card-design"
              style={{ maxHeight: 200, overflow: "scroll" }}
            >
              {updatesList.map((update) => {
                if (
                  update.updateDetails &&
                  update.updateDetails.type == "essay"
                ) {
                  return updatesComponent(update);
                }
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function documentComponent(selectedColl) {
    var essayArr = []
    var essayContent = {
      content: ""
    };
    essaysList.map((essay) => {
      // console.log("ss", essay)
      if (essay.collageId == selectedColl.collageId) {
        essayArr.push(essay)
        essayContent = {
          content: essayArr[0].content
        }
      }
    })
    return (
      <div
        key={selectedColl._id}
        className="col-md-4"
        style={{ height: 200, cursor: "pointer" }}
        onClick={() => {
          if (collageFilterCollageId == "") {
            setcollageFilterCollageId(selectedColl.collageId);
          } else {
            setcollageFilterCollageId("");
          }
        }}
      >
        <div
          className="p-4"
          style={{ position: "absolute", zIndex: 2, width: 150 }}
        >
          <div
            className=""
            style={{ fontSize: 10, fontWeight: 700, lineHeight: 1, height: 30 }}
          >
            {selectedColl.name}
          </div>
          <div
            className="pt-2"
            style={{
              fontSize: 6,
              lineHeight: 1,
              color: "grey",
              height: 40,
              overflow: "hidden",
            }}
          >
            {essayContent.content ? (essayContent.content.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, "")) : "No Essay Added"}
          </div>
          <div
            className="pt-2"
            style={{
              width: "100%",
              borderBottom: "1px solid #00000040",
              height: 2,
            }}
          ></div>
          <div className="d-flex align-items-end flex-column pt-2">
            <div style={{ fontSize: 6, color: "grey" }}>
              4 FEEDBACKS RECEIVED
            </div>
            <div style={{ fontSize: 6, color: "grey" }}>7 SUGGESTED EDITS</div>
          </div>
          <div className="pt-2 d-flex justify-content-end">
            <img height={10} src={RightArrow} alt="rightarrow" />
          </div>
        </div>
        <img
          style={{ position: "absolute" }}
          width={150}
          src={DocumentBg}
          alt="docimg"
        />
      </div>
    );
  }

  function updatesComponent(update) {
    return (
      <div
        key={update.updateId}
        className="d-flex justify-content-start align-items-center p-2"
        style={{ borderBottom: "1px solid #00000020", cursor: "pointer" }}
        onClick={() => {
          const selectedEssay = essaysList.find(
            (essay) => essay.essayId == update.updateDetails.essayId
          );
          viewEssay(selectedEssay);
        }}
      >
        <img height={30} src={ProfilePicSample} alt="profilepic" />
        <div className="pl-2">
          <div style={{ fontSize: 10, fontWeight: 600 }}>{update.message}</div>
          <div style={{ fontSize: 8, color: "grey" }}>
            {findNotiTimeAgeFun(update.notiTimeAgo)}
          </div>
        </div>
      </div>
    );
  }

  function statsComponent() {
    const studLen = listOfStudents.length;
    const updateLen = updatesList.length;
    const queryLen = queriesList.length;
    return (
      <div className="col-md-12 p-2 d-flex justify-content-between overflow-hidden">
        <div
          style={{ position: "absolute", bottom: -20, left: -20 }}
          className="col-md-3 m-0 p-0"
        >
          <img height={80} src={StatsImg} alt="statsimg" />
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-11 d-flex py-3 justify-content-end">
          <div className="col-md-4 d-flex align-items-center flex-column">
            <div style={{ fontWeight: 800, fontSize: 20, lineHeight: 1 }}>
              {studLen}
            </div>
            <div
              style={{
                fontWeight: 800,
                fontSize: 8,
                lineHeight: 1,
                color: "grey",
              }}
            >
              <div>Students</div>
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center flex-column">
            <div style={{ fontWeight: 800, fontSize: 20, lineHeight: 1 }}>
              {updateLen}
            </div>
            <div
              style={{
                fontWeight: 800,
                fontSize: 8,
                lineHeight: 1,
                color: "grey",
              }}
            >
              Updates
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center flex-column">
            <div style={{ fontWeight: 800, fontSize: 20, lineHeight: 1 }}>
              {queryLen}
            </div>
            <div
              style={{
                fontWeight: 800,
                fontSize: 8,
                lineHeight: 1,
                color: "grey",
              }}
            >
              Queries
            </div>
          </div>
        </div>
      </div>
    );
  }
  function queriesComponent() {
    return (
      <div className="d-flex justify-content-between align-items-center p-2">
        <img height={50} src={ProfilePicSample} alt="profilepic" />
        <div className="pl-3 p-0" style={{ borderLeft: "2px solid #00000020" }}>
          <div style={{ fontSize: 8, fontWeight: 700 }}>
            harvard good concept.. need to work on the laguage and devlivery
          </div>
          <div style={{ fontSize: 8, color: "grey" }}>12 mins</div>
        </div>
      </div>
    );
  }
};

export default StudentsPage;
