import * as React from "react";

const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={22}
    viewBox="0 0 28 22"
    {...props}
  >
    <g
      id="Icon_feather-menu"
      data-name="Icon feather-menu"
      transform="translate(-3 -7.5)"
    >
      <path
        id="Path_317"
        data-name="Path 317"
        d="M4.5,18h25"
        transform="translate(0 1)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      />
      <path
        id="Path_318"
        data-name="Path 318"
        d="M4.5,9h25"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      />
      <path
        id="Path_319"
        data-name="Path 319"
        d="M4.5,27h25"
        transform="translate(0 1)"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      />
    </g>
  </svg>
);

export default SVGComponent;

