import React, { useEffect, useState } from "react";
import axios from "axios";
import { MW_URL } from "../../../../config";
import LoadingAnimation from "../../../../utils/loadingAnimation";
import {PiFileTextDuotone} from 'react-icons/pi'
import QuillEditor from '../../quillEditor';


const DocumentSec = ({selectedCollege}) => {

    console.log(selectedCollege.collageId, "sel col12")

    const [isLoading, setIsLoading] = useState(true);
    const [selColDoc, setSelColDoc] = useState()
    


    useEffect(() => {
        axios
        .post(`${MW_URL}` + "documents/" + selectedCollege.collageId)
        .then((reponse) => {
            setSelColDoc(reponse.data)
            console.log(reponse, "response")
        })
    }, [selectedCollege]);

    const fileUploadBtnClicked = (rowData) => {
        console.log("acc", rowData);
        
          axios
            .post(`${MW_URL}` + "documents/" + rowData.collegeId)
            .then((getDocDetailsResponse) => {
            //   setIsUseEffectCallRequired(false);
              var allCollegeDetail = [];
              console.log("getDocDetailsResponse", getDocDetailsResponse);
              if (
                getDocDetailsResponse &&
                getDocDetailsResponse.data &&
                getDocDetailsResponse.data[0]
              ) {
                for (var i = 0; i < getDocDetailsResponse.data.length; i++) {
                  var currentObj = getDocDetailsResponse.data[i];
                  var tempObj = {
                    collegeId: currentObj.collegeId,
                    docList: currentObj.document_masters,
                  };
    
                  allCollegeDetail.push(tempObj.docList);
                }
              }
            //   setDocListPayload(allCollegeDetail);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log("err >> ", err);
            });
    
        //   setfileUploadModalOpen(true);
    }

    

  return (
    <div>
        <div className="row my-2">

            {/* <div className="col-4 ">
                <div className='docWrapper'>
                    <div className='docFontWrap'>
                        <div className='docFont'><PiFileTextDuotone /></div>
                    </div>
                    {/* <input className='docNameWrap' placeholder='Enter Document Name' type='text' /> */}
                    {/* <div className="py-2">
                        <QuillEditor placeholder={'Enter Document Name'} customClass={'docTextEditor1'} />
                    </div>
                    <button className='docAddBtn'>Add</button>
                </div>
            </div> */} 
            {/* 
            <div className="col-4 ">
                <div className='docWrapper'>
                    <div className='docImgWrap'>
                        <img className="docImg" src='https://wpamelia.com/wp-content/uploads/2019/04/Lipsum.jpg' />
                    </div>
                    <p className='colNormalText2 my-2 p-2'>Document Name</p>
                    <button className='docEditBtn'>Edit</button>
                </div>
            </div> */}

            {/* <div className="col-4 ">
                <div className='docWrapper'>
                    <div className='docImgWrap'>
                        <img className="docImg" src='https://wpamelia.com/wp-content/uploads/2019/04/Lipsum.jpg' />
                    </div>
                    <div className='docNameWrap2'>Document Name</div>
                    <button className='docRemoveBtn'>Remove</button>
                </div>
            </div> */}

            {selColDoc?.map((obj) => (
                <div className="col-4 ">
                    <div className='docWrapper'>
                        <div className='docImgWrap'>
                            <img className="docImg" src='https://wpamelia.com/wp-content/uploads/2019/04/Lipsum.jpg' />
                        </div>
                        <div className='docNameWrap2'>{obj.document_masters[0].fileName}</div>
                        <button className='docRemoveBtn'>Remove</button>
                    </div>
                </div>
            ))}
            {selColDoc?.length < 1 && (<p>No files requires</p>)}

            
        </div>
    </div>
  )
}

export default DocumentSec ;