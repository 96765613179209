import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';

const AluminiSec = () => {
  return (
    <div>
        <Row className='my-3'>
            <Col sm={6} md={3}>
                <Card className='aluminiCard'>
                    <Card.Img className='aluminiProImg' variant="top" src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80" />
                    <p className='colNormalText ml-1 pl-1'>Alumini Name</p>
                    <Card.Body className='py-0'>
                        <Row>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText  mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                            <Col xs={4} className=' px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum  m-0'>03</p>
                            </Col>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={6} md={3}>
                <Card className='aluminiCard'>
                    <Card.Img className='aluminiProImg' variant="top" src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80" />
                    <p className='colNormalText ml-1 pl-1'>Alumini Name</p>
                    <Card.Body className='py-0'>
                        <Row>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText  mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                            <Col xs={4} className=' px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum  m-0'>03</p>
                            </Col>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={6} md={3}>
                <Card className='aluminiCard'>
                    <Card.Img className='aluminiProImg' variant="top" src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80" />
                    <p className='colNormalText ml-1 pl-1'>Alumini Name</p>
                    <Card.Body className='py-0'>
                        <Row>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText  mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                            <Col xs={4} className=' px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum  m-0'>03</p>
                            </Col>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={6} md={3}>
                <Card className='aluminiCard'>
                    <Card.Img className='aluminiProImg' variant="top" src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80" />
                    <p className='colNormalText ml-1 pl-1'>Alumini Name</p>
                    <Card.Body className='py-0'>
                        <Row>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText  mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                            <Col xs={4} className=' px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum  m-0'>03</p>
                            </Col>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={6} md={3}>
                <Card className='aluminiCard'>
                    <Card.Img className='aluminiProImg' variant="top" src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80" />
                    <p className='colNormalText ml-1 pl-1'>Alumini Name</p>
                    <Card.Body className='py-0'>
                        <Row>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText  mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                            <Col xs={4} className=' px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum  m-0'>03</p>
                            </Col>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={6} md={3}>
                <Card className='aluminiCard'>
                    <Card.Img className='aluminiProImg' variant="top" src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80" />
                    <p className='colNormalText ml-1 pl-1'>Alumini Name</p>
                    <Card.Body className='py-0'>
                        <Row>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText  mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                            <Col xs={4} className=' px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum  m-0'>03</p>
                            </Col>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={6} md={3}>
                <Card className='aluminiCard'>
                    <Card.Img className='aluminiProImg' variant="top" src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80" />
                    <p className='colNormalText ml-1 pl-1'>Alumini Name</p>
                    <Card.Body className='py-0'>
                        <Row>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText  mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                            <Col xs={4} className=' px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum  m-0'>03</p>
                            </Col>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={6} md={3}>
                <Card className='aluminiCard'>
                    <Card.Img className='aluminiProImg' variant="top" src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80" />
                    <p className='colNormalText ml-1 pl-1'>Alumini Name</p>
                    <Card.Body className='py-0'>
                        <Row>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText  mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                            <Col xs={4} className=' px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum  m-0'>03</p>
                            </Col>
                            <Col xs={4} className='px-1'>
                                <p className='colLightText mb-1'>sc</p>
                                <p className='aluminiNum'>03</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </div>
  )
}

export default AluminiSec