import React, { useEffect, useState } from "react";
import axios from "axios";
import { MW_URL } from "../../../config";

import CollegeLeft from './portal/collegeLeft';
import CollegeRight from './portal/collegeRight';
import LoadingAnimation from "../../../utils/loadingAnimation";
import CompareModal from "./portal/CompareModal";
import CollageFilters from "./portal/collageFilters";




const College = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState([])
  const [listOfSelectedCollageDetailsArr, setlistOfSelectedCollageDetailsArr] = useState([]);
  const [listOfCollageDetailsArr, setlistOfCollageDetailsArr] = useState([]);
  const [listOfCollageDetailsTempArr, setlistOfCollageDetailsTempArr] = useState([]);
  const [listOfSelectedCollagesIds, setlistOfSelectedCollagesIds] = useState([]);
  const [collageMetaData, setcollageMetaData] = useState({
    page: 0,
    total: 0,
    per_page: 100,
  });
  const [collageSearchKeyword, setcollageSearchKeyword] = useState("");
  const [selectedCollege, setSelectedCollege] = useState([])
  const [openCompareModal, setOpenCompareModal] = useState(false)
  const [selectColToCompare, setSelectColToCompare] = useState([])
  const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
  const [filterStringToSend, setfilterStringToSend] = useState(false);
  const [showFilterSec, setShowFilterSec] = useState(false)



  useEffect(() => {
    collagedetailsfromapi(1, false);
  }, []);

  console.log(filterStringToSend, 'filterStringToSend')


  const collagedetailsfromapi = (page, filter) => {
    setIsLoading(true);
    console.log("collagedetailsfromapi");
    var selectedCollIds = [];
    var selectedCollages = [];
    axios
      .post(`${MW_URL}` + "getSelectedListOfCollages", {
        email: JSON.parse(localStorage.getItem("userData")).email,
      })
      .then((getSelectedListOfCollagesresponse) => {
        // console.log(
        //   "getSelectedListOfCollagesresponse",
        //   getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
        // );
        setlistOfSelectedCollageDetailsArr(
          getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
        );
        getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
          (collage) => {
            selectedCollIds.push(collage?.collageId);
            selectedCollages.push(collage);
          }
        );
        setlistOfSelectedCollagesArr(selectedCollages);
        setlistOfSelectedCollagesIds(selectedCollIds)

        // axios
        // .post(`${MW_URL}` + "documents/" + selectedCollIds[0])
        // .then((reponse) => {
        //   console.log(reponse, "response")
        // })

        axios
          .post(`${MW_URL}` + "getCollegeDetailsByOffset", {
            offset: page,
            limit: 100,
            filter: filter,
          })
          .then((collegescorecardresponse) => {
            console.log(
              "collegescorecardresponse",
              collegescorecardresponse.data
            );
            var tempArr = [];
            collegescorecardresponse?.data?.collageDetails?.map((coll, index) => {
              selectedCollIds.includes(coll?.collageId)
                ? tempArr.push({ ...coll, selected: true })
                : tempArr.push({ ...coll, selected: false });
            });
            // console.log("tempArr", tempArr);
            setSelectedCollege(tempArr[0])
            if (page == 0) {
              setlistOfCollageDetailsArr(tempArr);
              setlistOfCollageDetailsTempArr(tempArr);
            } else {
              setlistOfCollageDetailsArr([
                ...listOfCollageDetailsArr,
                ...tempArr,
              ]);
              setlistOfCollageDetailsTempArr([
                ...listOfCollageDetailsArr,
                ...tempArr,
              ]);
            }
            setlistOfCollageDetailsArr(tempArr);
            setlistOfCollageDetailsTempArr(tempArr);
            setcollageMetaData(collegescorecardresponse.data.metadata);
            // console.log(collegescorecardresponse.data.metadata, "meta")
            setIsLoading(false);
          })
          .catch((err) => { });
      })
      .catch((err) => { });
  };

  const searchCollageByKeyword = () => {
    // console.log("collageSearchKeyword", collageSearchKeyword);
    var selectedCollIds = [];
    listOfSelectedCollageDetailsArr.map((collage) => {
      selectedCollIds.push(collage?.collageId);
    });

    setIsLoading(true);
    axios
      .post(`${MW_URL}` + "getCollegeDetailsByOffsetAndSearchKeyword", {
        offset: 0,
        limit: 10,
        keyword: collageSearchKeyword,
      })
      .then((getCollegeDetailsResponse) => {
        // console.log("getCollegeDetailsResponse", getCollegeDetailsResponse);
        var tempArr = [];
        getCollegeDetailsResponse.data.collageDetails.map((coll, index) => {
          selectedCollIds.includes(coll?.collageId)
            ? tempArr.push({ ...coll, selected: true })
            : tempArr.push({ ...coll, selected: false });
        });
        // console.log("tempArr", tempArr);
        setlistOfCollageDetailsArr(tempArr);
        setcollageMetaData(getCollegeDetailsResponse.data.metadata);
        console.log("getCollegeDetailsResponse.data.metadata", getCollegeDetailsResponse.data.metadata);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };

  return (
    <div>
      {!openCompareModal && (
        <div className='row m-1' style={{ "backgroundColor": " #FFFFFF" }}>
          {isLoading ? <LoadingAnimation isVisible={true} /> : null}
          <div className='col-4' style={{ "backgroundColor": "#F5F7FB" }}>
            <CollegeLeft
              collageMetaData={listOfCollageDetailsArr}
              listOfCollageDetailsArr={listOfCollageDetailsTempArr}
              setlistOfCollageDetailsArr={setlistOfCollageDetailsArr}
              setcollageSearchKeyword={setcollageSearchKeyword}
              searchCollageByKeyword={searchCollageByKeyword}
              setSelectedCollege={setSelectedCollege}
              selectedCollege={selectedCollege}
              setOpenCompareModal={setOpenCompareModal}
              setSelectColToCompare={setSelectColToCompare}
              listOfSelectedCollagesArr={listOfSelectedCollagesArr}
              listOfSelectedCollageDetailsArr={listOfSelectedCollageDetailsArr}
              selectColToCompare={selectColToCompare}
              setShowFilterSec={setShowFilterSec}
              showFilterSec={showFilterSec}
              setfilterStringToSend={setfilterStringToSend}
              collagedetailsfromapi={collagedetailsfromapi}
            />
          </div>


          <div className='col-8' style={{maxHeight:"100vh", overflow:"auto"}}>
            <CollegeRight
              selectedCollege={selectedCollege}
              setlistOfSelectedCollageDetailsArr={setlistOfSelectedCollageDetailsArr}
              listOfSelectedCollageDetailsArr={listOfSelectedCollageDetailsArr}
              listOfSelectedCollagesIds={listOfSelectedCollagesIds}
              setlistOfCollageDetailsArr={setlistOfCollageDetailsArr}
              listOfCollageDetailsArr={listOfCollageDetailsArr}
              setIsUseEffectCallRequired={setIsUseEffectCallRequired}
            />
          </div>
        </div>
      )}

      {openCompareModal && (<CompareModal
        setOpenCompareModal={setOpenCompareModal}
        selectColToCompare={selectColToCompare} />)}
    </div>
  )
}

export default College