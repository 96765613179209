
import React from 'react';
import PropTypes from 'prop-types';

class NoDataFoundComponent extends React.Component{
    render(){
        return (
         <div className="alert alert-info w-100">{this.props.content}</div>
        );
    }
}

NoDataFoundComponent.defaultProps = {
    content: "no data found"
}
NoDataFoundComponent.propTypes = {
    content: PropTypes.string
}

export default NoDataFoundComponent;
