import React, { useEffect, useState } from "react";
import Portal from "./portalScreen";
import SignUp from "./signUpScreen";
import TellUsMore from "./tellUsMoreScreen";
import SplashLoader from "./splashLoadingScreen";
import LorScreen from "./lorScreen"
import { Button, Input } from "reactstrap";
import {
  isMainLoading,
  setMainLoadingTrue,
  setMainLoadingFalse,
  setCurrentPage,
  currentPageScreen,
} from "../stateManager/reducers/generalReducer";
import { useDispatch, useSelector } from "react-redux";
import googlePlay from "../imageAssets/googlePlay.png";
import appStore from "../imageAssets/appStore.png";
import Logo from "../imageAssets/SixdeeWhiteLogo.png";
import GeneralUtils from "../utils/generalUtils";
import axios from "axios";
import { MW_URL } from "../config";
import CloudImg from "../imageAssets/cloudimg.png";
import AppleImg from "../imageAssets/appleround.png";
import GoogleImg from "../imageAssets/googleround.png";
import FbImg from "../imageAssets/fbround.png";
import LoadingAnimation from "../utils/loadingAnimation";
import ValidateForm from "../utils/formValidation";
import { ToastContainer, toast } from "react-toastify";
const LandingScreen = () => {
  const isMainLoader = useSelector(isMainLoading);
  const currentPage = useSelector(currentPageScreen);
  const [height, SetHeight] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    var windowHeight = window.innerHeight;
    SetHeight(windowHeight + "px");
    dispatch(setMainLoadingTrue());
    callApi();
  }, []);

  const callApi = () => {
    setTimeout(() => {
      dispatch(setMainLoadingFalse());
      dispatch(setCurrentPage(""));
      console.log("window.location.pathname", window.location.pathname);
      if (window.location.pathname == "/lor") {
        dispatch(setCurrentPage("lor"));
      }
      let params = new URLSearchParams(window.location.search);
      if (params.get("counsellorId")) {
        signUpBtnClicked();
      }
    }, 10);
  };

  const signUpBtnClicked = () => {
    dispatch(setCurrentPage("signUp"));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const loginBtnClicked = () => {
    if (ValidateForm.inputFieldMandatoryCheck("login", false)) {
      if (ValidateForm.inputFieldValueCompositionCheck("login", false))
        var dataToSend = {
          email: state.email,
          password: state.password,
        };
      setIsLoading(true);
      axios
        .post(`${MW_URL}` + "signIn", dataToSend)
        .then((response) => {
          setIsLoading(false);
          var status = "NA";
          if (response && response.data && response.data.status)
            status = response.data.status;
          console.log("response", JSON.stringify(response));
          if (status.toUpperCase() === "SUCCESS") {
            var userObj = {
              username: response.data.username,
              userType: response.data.userType,
              email: response.data.email,
              counsellorId: response.data.counsellorId,
            };
            console.log("userObj", userObj);
            localStorage.setItem("userData", JSON.stringify(userObj));
            localStorage.setItem("isUserLoggedIn", "true");
            dispatch(setCurrentPage("portal"));
          } else if (status === "INVALID_USER") {
            toast.error("Invalid Username or Password", {
              autoClose: 2000,
              pauseOnHover: true,
            });
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    }

    // localStorage.setItem("isUserLoggedIn", "true");
    // dispatch(setCurrentPage("portal"))
  };

  const addAdmin = () => {
    setIsLoading(true);
      axios
        .post(`${MW_URL}` + "createAdmin", {})
        .then((response) => {
          setIsLoading(false);
          var status = "NA";
          if (response && response.data && response.data.status)
            status = response.data.status;
          console.log("response", JSON.stringify(response));
          if (status.toUpperCase() === "SUCCESS") {
           alert("succes")
          } else if (status === "INVALID_USER") {
            toast.error("Invalid Username or Password", {
              autoClose: 2000,
              pauseOnHover: true,
            });
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
  };

  if (isMainLoader) {
    return <SplashLoader />;
  } else {
    if (currentPage === "portal") {
      return <Portal />;
    } else if (currentPage === "signUp") {
      return <SignUp />;
    } else if (currentPage === "tellUsMore") {
      return <TellUsMore />;
    } else if (currentPage === "lor") {
      return <LorScreen />;
    } else {
      return (
        <div>
          {isLoading ? <LoadingAnimation isVisible={true} /> : null}
          <div id="landingPageContainer">
            <div className="col-md-12 p-0 row m-0">
              <div
                id="login-page-left-part"
                className="col-md-6 p-0 d-flex text-center align-items-center"
              >
                {leftComponent()}
              </div>
              <div
                id="login-page-right-part"
                className="col-md-6 p-0 d-flex text-center align-items-center"
                style={{ minHeight: height }}
              >
                {rightComponent()}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  function rightComponent() {
    return (
      <div className="col-md-12 p-0">
        <div className="col-md-12 p-0">
          <img height={120} src={CloudImg} alt="cloudimg" />
        </div>
        <div className="col-md-12 p-0 normal-text pt-3 pb-4">
          Start Building
        </div>
        <div className="col-md-12 p-0">
          <Button
            className="sign-up-btn px-5"
            onClick={() => signUpBtnClicked()}
          >
            Sign Up
          </Button>
        </div>
      </div>
    );
  }

  

  function leftComponent() {
    return (
      <div className="col-md-12 p-0">
        <div className="col-md-12 p-0 large-text">Sign In</div>
        <div>
          <img height={40} src={AppleImg} alt="apple" />
          <img className="px-2" height={40} src={GoogleImg} alt="goole" />
          <img height={40} src={FbImg} alt="fb" />
        </div>
        <div className="col-md-12 p-0 small-text pt-3 pb-4" style={{cursor:"pointer"}} onClick={addAdmin}>
          Or use your account
        </div>
        <div id="login">
          <div className="col-md-12 p-0 d-flex text-center flex-column align-items-center form-group">
            <div className="" style={{ width: "40%" }}>
              <Input
                type="email"
                autoComplete="new-password"
                className="text-box-style my-3 py-2  input_data mandatoryField specialCharactersAllowed typeEmail"
                name="email"
                id="loginEmail"
                placeholder="e-mail"
                onChange={handleChange}
              />
              <div className="errorDesc" style={{ marginLeft: "6cm" }}></div>
            </div>
          </div>
          <div className="col-md-12 p-0 d-flex text-center flex-column align-items-center form-group">
            <div className="" style={{ width: "40%" }}>
              <Input
                type="password"
                autoComplete="new-password"
                className="text-box-style mb-3 py-2 input_data mandatoryField"
                name="password"
                id="loginPassword"
                placeholder="password"
                onChange={handleChange}
              />
              <div className="errorDesc" style={{ marginLeft: "6cm" }}></div>
            </div>
          </div>
        </div>
        <div className="col-md-12 p-0 small-text pt-3 pb-4">
          Forgot Your Password?
        </div>
        <div className="col-md-12 p-0">
          <Button className="login-btn px-5" onClick={() => loginBtnClicked()}>
            Login
          </Button>
        </div>
        <ToastContainer />
      </div>
    );
  }
};

export default LandingScreen;
