import React, { useEffect } from "react";
import Navbar from "../components/navbar";
import Home from "./portal/home";
import AdminDashboard from "./portal/adminDashboard";
import AdminCollege from "./portal/adminCollege";
import AdminEssay from "./portal/adminEssay";
import AdminDocument from "./portal/adminDocuments";
import AdminUsers from "./portal/adminUsers";
import CounsellorDashboard from "./portal/counsellorDashboard";
import CounsellorStudents from "./portal/counsellorStudents";
import CounsellorAppointments from "./portal/counsellorAppointments";
import ProfileBuilder from "./portal/profileBuilder";
import CollegeSearch from "./portal/collegeSearch";
import TextEditor from "./portal/textEditor";
import UploadDocs from "./portal/uploadDocs";
import Appoinments from "./portal/appoinments";
import AluminiNetwork from "./portal/aluminiNetwork";

import "../styleAssets/portalPage/portalStyles.scss";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
} from "react-router-dom";
import TellUsMoreScreen2 from "./tellUsMoreScreen2";
import Dashboard from "../components/students/dashboard";
import College from "../components/students/college";
import ProfileBuilder2 from "../components/students/profileBuilder/profileBuilder";
import Documents from "../components/students/documents";
import Calender from "../components/students/calender";
import Essays from "../components/students/essays";
import Queries from "../components/students/queries";
import CounsellorDash from "../components/councellor/dashboard";
import CounsellorStud from "../components/councellor/students";
import CounsellorInbox from "../components/councellor/inbox";
import Inbox from "../components/students/inbox";

import { useNavigate } from 'react-router'
import CouncellorQuery from "../components/councellor/queries";


const PortalScreen = () => {

    return (
        <Router>
            <div className="col-md-12 p-0 row m-0">
                <div className="col-md-2 p-0">
                    <Navbar />
                </div>
                <div className="col-md-10 p-0">
                    <div className="portal-outer-container" style={{ maxHeight: "100vh"}} >
                        <Switch>
                            <Route path="/home">
                                <Home />
                            </Route>
                            <Route path="/adminDashboard">
                                <AdminDashboard />
                            </Route>
                            <Route path="/adminCollege">
                                <AdminCollege />
                            </Route>
                            <Route path="/adminUsers">
                                <AdminUsers />
                            </Route>
                            <Route path="/adminEssay">
                                <AdminEssay />
                            </Route>
                            <Route path="/adminDocuments">
                                <AdminDocument />
                            </Route>
                            <Route path="/counsellorDashboard">
                                {/* <CounsellorDashboard />  */}
                                <CounsellorDash />
                            </Route>
                            <Route path="/counsellorStudents">
                                {/* <CounsellorStudents /> */}
                                <CounsellorStud />
                            </Route>
                            <Route path="/counsellorAppointments">
                                {/* <Appoinments /> */}
                                <Calender />
                            </Route>
                            <Route path="/counsellorInbox">
                                <CounsellorInbox />
                            </Route>
                            <Route path="/counsellorQueries">
                                <CouncellorQuery />
                            </Route>
                            <Route path="/profileBuilder">
                                {/* <ProfileBuilder /> */}
                                <ProfileBuilder2 />
                            </Route>
                            <Route path="/collegeSearch">
                                <CollegeSearch />
                            </Route>
                            <Route path="/textEditor">
                                <TextEditor />
                            </Route>
                            <Route path="/uploadDocs">
                                <UploadDocs />
                            </Route>
                            <Route path="/appoinments">
                                <Appoinments />
                            </Route>
                            <Route path="/aluminiNetwork">
                                <AluminiNetwork />
                            </Route>

                            {/* new */}

                            <Route exact path="/">
                                <Dashboard />
                                {/* <Home /> */}

                            </Route>

                            <Route path="/college">
                                <College />
                            </Route>

                            <Route path="/documents">
                                <Documents />
                            </Route>

                            <Route path="/calender">
                                <Calender />
                            </Route>

                            <Route path="/essays">
                                <Essays />
                            </Route>

                            <Route path="/queries">
                                <Queries />
                            </Route>

                            <Route path="/inbox">
                                <Inbox />
                            </Route>

                            {/* counsellor */}

                            {/* <Route path="/cdash">
                                <CounsellorDash />
                            </Route> */}


                        </Switch>
                    </div>
                </div>
            </div>
        </Router>
    )
}


export default PortalScreen;