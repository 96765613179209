import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal } from 'react-bootstrap';
import axios from "axios";
import { MW_URL } from "../../../config";
import LoadingAnimation from "../../../utils/loadingAnimation";
import { CircularProgressbar } from "react-circular-progressbar";


import DocumentSec from '../college/portal/documentSec';
import EssaySec from './portal/EssaySec';
import TimelineSec from './portal/TimelineSec';
import AluminiSec from '../college/portal/AluminiSec';
import GallerySec from '../college/portal/GallerySec';
import {PiFileTextDuotone} from 'react-icons/pi'
import {FaSearch} from "react-icons/fa";
import QuillEditor from "../quillEditor";
import {AiOutlineCloseCircle} from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';




const Documents = () => {

    const [tab, setTab] = useState("1")
    const [show, setShow] = useState(false);
    const [selectedCollegeDoc, setSelectedCollegeDoc] = useState();
    const [newFileToUse, setNewFileToUse] = useState(0);
    const [fileToUseData, setFileToUseData] = useState([]);
    const [studentUploadDocList, setStudentUploadDocList] = useState([]) ;
    const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);



    const [isLoading, setIsLoading] = useState(true);
    const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState(
        []
    );
    const [docId, setDocumentId] = useState([]);
    const [listOfSelectedDocumentArr, setlistOfSelectedDocumentsArr] = useState(
        []
    );
    const [collageDocMappingArr, setcollageDocMappingArr] = useState([]);
    const [collageDocMappingArrTemp, setcollageDocMappingArrTemp] = useState([]);
    const [uploadedDoc, setUploadedDocPayload] = useState([]);
    const [SearchKeyword, setSearchKeyword] = useState("");
    const [docValidation, setValidation] = useState(false);

    var isAccepted = true






    useEffect(() => {
        getAllEssays();
    }, []);

    const getAllEssays = () => {
        setIsLoading(true);
        var selectedCollages = [];
        var selectedCollagesIds = [];
        var templateArr = [];
        axios
          .post(`${MW_URL}` + "getSelectedListOfCollages", {
            email: JSON.parse(localStorage.getItem("userData")).email,
          })
          .then((getSelectedListOfCollagesresponse) => {
            if (
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
                .length > 0
            ) {
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
                (collage) => {
                  selectedCollages.push(collage);
                  selectedCollagesIds.push(collage.id);
                }
              );
            //   console.log("selectedCollages", selectedCollages);
              setlistOfSelectedCollagesArr(selectedCollages);
    
              axios
                .post(`${MW_URL}` + "documentsbyuser", selectedCollages)
                .then((documentsbyuserresponse) => {
                    // console.log("documentsbyuser", documentsbyuserresponse);
                  var documentId = [];
                  var documentsByUserResFromApi = documentsbyuserresponse;
                  documentsbyuserresponse.data.map((ids) => {
                    {
                      documentId.push(ids);
                    }
                  });
                  //  console.log("documentId", documentId);
                  setDocumentId(documentId);
                  axios
                    .post(`${MW_URL}` + "getDocByCollege", documentId)
                    .then((getDocumentDetailResponse) => {
                      var status = "NA";
                      var documentsDetails = [];
                      status = getDocumentDetailResponse.data.STATUS;
                      if (status.toUpperCase() === "SUCCESS") {
                          // console.log("getDocumentDetailResponse.data",getDocumentDetailResponse.data);
                        setlistOfSelectedDocumentsArr(
                          getDocumentDetailResponse.data.data
                        );
                        if (
                          getDocumentDetailResponse &&
                          getDocumentDetailResponse.data &&
                          getDocumentDetailResponse.data.data
                        ) {
                          var collageDocMapArrTemp = [];
    
                          selectedCollages.map((collage) => {
                            var collageDocMapObjTemp = { collage: collage };
                            var documentsdetArr = [];
                            documentsByUserResFromApi.data.map(
                              (documentsCollageMap) => {
                                if (documentsCollageMap.collageId == collage.collageId) {
                                  documentsdetArr.push(
                                    getDocumentDetailResponse.data.data.filter(
                                      (doc) =>
                                        doc.documentId ==
                                        documentsCollageMap.documentId
                                    )
                                  );
                                }
                              }
                            );
                            collageDocMapObjTemp.documents = documentsdetArr;
                            collageDocMapArrTemp.push(collageDocMapObjTemp);
                          });
                          console.log("selectedCollegeDoc-12",selectedCollegeDoc);
                          setcollageDocMappingArr(collageDocMapArrTemp);
                          setcollageDocMappingArrTemp(collageDocMapArrTemp);

                          setIsLoading(false);
                        }
                      } else {
                        setIsLoading(false);
                      }
                    });
                  axios
                    .post(`${MW_URL}` + "getAllStudentsDocs", {
                      email: JSON.parse(localStorage.getItem("userData")).email,
                    })
                    .then((getStudDOcResponse) => {
                      setIsLoading(false);
                      // setIsUseEffectCallRequired(false);
                      var allDetail = [];
                      if (
                        getStudDOcResponse &&
                        getStudDOcResponse.data &&
                        getStudDOcResponse.data[0]
                      ) {
                        var DocMapArrTemp = [];
    
                        selectedCollages.map((collage) => {
                          var DocMapObjTemp = { collage: collage };
                          var documentsdet = [];
                          documentsByUserResFromApi.data.map(
                            (documentsCollageMap) => {
                              if (documentsCollageMap.collageId == collage.collageId) {
                                documentsdet.push(
                                  getStudDOcResponse.data.filter(
                                    (doc) =>
                                      doc.documentId ==
                                      documentsCollageMap.documentId && doc.collegeId ==
                                      documentsCollageMap.collageId
                                  )
                                );
                              }
                            }
                          );
                          DocMapObjTemp.documents = documentsdet;
                          DocMapArrTemp.push(DocMapObjTemp);
                        });
                        console.log(":;;loading");
                        setUploadedDocPayload(DocMapArrTemp);
                        console.log(DocMapArrTemp, "DocMapArrTemp")
                        setIsLoading(false);
                      }
                    })
                    .catch((err) => {
                      console.log("err >> ", err);
                    })
                    .catch((err) => {
                      console.log("err >> ", err);
                    });
                })
    
                .catch((err) => {
                  console.log("err >> ", err);
                });
            } else {
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      };
    
    const handleSearch = () => {
        if (SearchKeyword.length >= 2) {
          const lowerCaseKeyword = SearchKeyword.toLowerCase();
          const filteredCollages = collageDocMappingArrTemp?.filter((collage) => {
            const collageName = collage.collage.name.toLowerCase();
            return collageName.includes(lowerCaseKeyword);
          });
          setcollageDocMappingArr(filteredCollages);
        } else {
          setcollageDocMappingArr(collageDocMappingArrTemp);
        }
      };
    
    const handleSearchKeyChange = (value) => {
      if (value === '') {
        setSearchKeyword(value)
        setcollageDocMappingArr(collageDocMappingArrTemp);
      } else {
        setSearchKeyword(value)
      }
    }
    
    const handleDocEditClick = (obj) => {
      setShow(true)
      setSelectedCollegeDoc(obj)
      getAllStudentUploadDocs(obj?.collage?.collageId)
      console.log(obj, "colldoc")
      const uploadedlen = uploadedDoc?.length;
      const len = listOfSelectedDocumentArr.length;
      {docDetailComponent({
        collageName: obj.collage.name,
        fileRequired: obj.documents.length,
        fileUploaded: 0,
        collageId: obj.collage.collageId,
        deadline: obj.collage.deadline,
      })}
      console.log(obj, "1212")
      {obj.documents.map((docData) => {
        if (docData[0]) {
          return docDetailUploadComponent({
            fileName: docData[0].fileName,
            fileRequired: len,
            fileUploaded: uploadedlen,
            collage: obj,
            fileSize: docData[0].fileSize,
            fileType: docData[0].fileType,
            requirement: docData[0].requirement,
            documentId: docData[0].documentId,
            file: docData[0].file,
            deadline: docData[0].deadline,
          });
        }
      })}

    }

    const getAllStudentUploadDocs = (collegeId) => {
      console.log("okkk---->", collegeId)
      let dataToSend = {
        collegeId: collegeId,
        email: JSON.parse(localStorage.getItem("userData")).email
      }
      axios
        .post(`${MW_URL}` + "getAllDocumentsById", dataToSend)
        .then((getDocumentDetailResponse) => {
          console.log(getDocumentDetailResponse, "stud uploadeddoc list")
          setStudentUploadDocList(getDocumentDetailResponse.data)

        })
    }

    function docDetailComponent(submissionData) {
      var fileToUse = 0;
      console.log("sub...>",submissionData)
  
      uploadedDoc?.map((docs) => {
        // console.log("docs...>",docs)
        if (docs.collage.collageId == submissionData.collageId) {
          if (docs.documents.length) {
            docs.documents.map((d) => {
              if (d.length) {
                fileToUse += 1
              }
            })
          }
        }
      });
      setNewFileToUse(fileToUse)
    }

    function docDetailUploadComponent(submissionData, data) {
      var fileToUse = []; //use this variable to save needed data
      var inputFieldId = submissionData.documentId + "-" + submissionData.collageId;
      const ar = [];
      console.log("uploadedDoc", uploadedDoc);
      console.log("submissiondata-2", submissionData);
      uploadedDoc?.map((docs) => {
        {
          ar.push(docs.collage)
          docs.documents.map((doc) => {
            // console.log(doc, "doc-foormat")
            ar.map((colid) => {
              if (doc.length > 0) {
                // console.log("11", doc[0].collegeId, submissionData.collage.collage.collageId)
                if (doc[0].collegeId == submissionData.collage.collage.collageId) {
                  console.log("12", doc[0].documentId, submissionData.documentId)
                  if (doc[0].documentId == submissionData.documentId) {
                    // console.log("submissionData.documentId===>",submissionData.documentId);
                    // console.log("doc===>",docs);
                    console.log("doc=",doc[0].file, doc[0].fileName, doc[0].documentId );
                    // filesToUse.push(
                      // file: doc[0].file,
                      // fileName: doc[0].fileName,
                      // doc[0].documentId
                      // collageId: colid.collageId,
                    // )
                    // setFileToUseData(fileToUse)
                    // console.log(fileToUse, "new file")
                    setFileToUseData((prevData) => [...prevData, doc[0].documentId])
                    console.log("fileToUseData", fileToUseData)


                  }
                }
              }
            })
          });
        }
      });

    }

    function download(data, Name) {
      // console.log("decode", Name)
      const linkSource = `${data}`;
      const downloadLink = document.createElement("a");
      const fileName = Name;
  
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }

    function downloads(data, Name) {
      // console.log("decode", data);
      const linkSource = `${data}`;
      const downloadLink = document.createElement("a");
      const fileName = Name;
  
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }

    const downloadfile = (rowData) => {
      setIsLoading(true)
      axios
        .post(`${MW_URL}` + "getDocById", { documentId: rowData.documentId })
        .then((getDocumentDetailResponse) => {
          // console.log("data", getDocumentDetailResponse.data.data)
          var status = "NA";
          var documentFile = [];
          status = getDocumentDetailResponse.data.STATUS;
          if (status.toUpperCase() === "SUCCESS") {
            if (
              getDocumentDetailResponse &&
              getDocumentDetailResponse.data
            ) {
              getDocumentDetailResponse.data.data.map((file) => {
                {
                  documentFile.push(file.file)
                }
              });
            }
            setIsLoading(false)
            download(documentFile, rowData.fileName);
          }
        }).catch((err) => {
          console.log("err >> ", err);
        });
    }

    const downloadfiles = (rowData, collageId) => {
      // console.log("stud", rowData)
      if (rowData && collageId) {
        setIsLoading(true)
        axios
          .post(`${MW_URL}` + "getStudentDocfile", {
            email: JSON.parse(localStorage.getItem("userData")).email,
            documentId: rowData.documentId,
            collageId: collageId,
          })
          .then((getDocumentDetailResponse) => {
            // console.log("data", getDocumentDetailResponse.data.data)
            var status = "NA";
            var documentFile = [];
            status = getDocumentDetailResponse.data.STATUS;
            if (status.toUpperCase() === "SUCCESS") {
              if (
                getDocumentDetailResponse &&
                getDocumentDetailResponse.data
              ) {
                getDocumentDetailResponse.data.data.map((file) => {
                  {
                    documentFile.push(file.file)
                  }
                });
              }
              setIsLoading(false)
              downloads(documentFile, rowData.fileName);
            }
          }).catch((err) => {
            console.log("err >> ", err);
          });
      }
      else {
        toast.error("No file Uploaded", {
          autoClose: 1000,
        })
      }
    }

    const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
  
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    const uploadFile = async (e, submissionData) => {
      console.log(e.target.value, submissionData, "select")
      const file = e.target.files[0];
      const sizeInBytes = file.size;
      const sizeFile = sizeInBytes / 1024;
      var fileExtension = file.name.split('.').pop();
      fileExtension = fileExtension.toUpperCase()
      var fileTy = submissionData.fileType.toUpperCase()
      // console.log(fileExtension, fileTy)
      if (sizeFile <= submissionData.fileSize && fileExtension == fileTy) {
        const base64 = await convertBase64(file);
        // setEditState({
        //   documentId: submissionData.documentId,
        //   collageId: submissionData.collageId,
        //   fileName: submissionData.fileName,
        //   file: base64,
        // });
        // setValidation(true)
        var dataToSend = {
          collegeId: selectedCollegeDoc?.collage["collageId"],
          documentId: submissionData?.documentId,
          fileName: file?.fileName,
          file: base64,
          email: JSON.parse(localStorage.getItem("userData")).email,
  
        };
        console.log("DatatoSent", dataToSend);
        setIsLoading(true);
  
        axios
          .post(`${MW_URL}` + "studentUploadDoc", dataToSend)
          .then((getUploadDetailResponse) => {
            if (
              getUploadDetailResponse &&
              getUploadDetailResponse.data &&
              getUploadDetailResponse.data.STATUS === "SUCCESS"
            ) {
              
              setIsLoading(false);
              // setIsUseEffectCallRequired(true);
              setShow(false)
              window.location.reload();

            } else {
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      } else {
        diffToast();
      }
      }

    const diffToast = () => toast.error("File Size or File Type Different from Requered", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
  
    });

    function progressCircle(percentile) {
      var leftHeight = 0;
      var rightHeight = 0;
      if (percentile > 50) {
        leftHeight = 100;
        rightHeight = (percentile - 50) * 2;
      } else {
        rightHeight = 0;
        leftHeight = percentile * 2;
      }
      return (
        <div className="p-0 m-0">
          <div style={{ width: 30, height: 30 }}>
            <CircularProgressbar
              value={percentile}
              strokeWidth={10}
              text={`${percentile}%`}
              styles={{
                text: {
                  fill: "grey",
                  fontSize: "24px",
                },
                path: {
                  stroke: `#6C63FF`,
                },
              }}
            />
          </div>
          <ToastContainer />
        </div>
      );
    }

    const createUniquieId = () => {
      const head = Date.now().toString(36);
      const tail = Math.random().toString(36).substr(2);
      return head + tail;
    };

    const transferBtnClick = () => {
      console.log(selectedCollegeDoc, "docs to transfer")
      var dataToSend = {
        documentId: createUniquieId(),
        senderId : JSON.parse(localStorage.getItem("userData")).email,
        recipientId : JSON.parse(localStorage.getItem("userData")).counsellorId,
        collageId : selectedCollegeDoc?.collage["collageId"],
        isTransferred : true,
      }
      console.log(dataToSend, "data")
      axios
          .post(`${MW_URL}` + "addTransferredDoc", dataToSend)
          .then((addTransferredDocsresponse) => {
            console.log(addTransferredDocsresponse)
            toast.success("Documents Transfered successfully", {
              autoClose: 1000,
            })
          })
    }

    const getStatus = (doc) => {
      if (doc.isAccepted && !doc.isPending) return 'accepted';
      if (!doc.isAccepted && !doc.isPending) return 'rejected';
      if (!doc.isAccepted && doc.isPending) return 'pending';
      return null;
    };


  return (
    <div className='document'>
        {isLoading ? <LoadingAnimation isVisible={true} /> : null}
        <div className="row my-3 py-2 m-2 justify-content-evenly">
            <div className="col-6">
                <p className='docHeadText'>Documents</p>
            </div>
            <div className="col-6">
                <div className='d-flex justify-content-end'>
                    <div className='docSearchBox'>
                        <input type='search' value={SearchKeyword}  onChange={(e) => handleSearchKeyChange(e.target.value)} placeholder='Search Documents' />
                        <div onClick={() => handleSearch()} className='p-2 '><FaSearch /></div>
                    </div>
                </div>
            </div>
        </div>

        <div className='colTab mb-3'>
            <button onClick={() => setTab("1")} className={tab === "1" ? 'colTabBtnSel': 'colTabBtn'}>Documents</button>
            <button onClick={() => setTab("2")} className={tab === "2" ? 'colTabBtnSel': 'colTabBtn'}>Essays</button>
            <button onClick={() => setTab("3")} className={tab === "3" ? 'colTabBtnSel': 'colTabBtn'}>Timeline</button>
        </div>

        {tab === '1' && (
            <div className='docRow'>
                {/* <div className='docCol'>
                        <div className='docFontWrap'>
                            <div className='docFont'><PiFileTextDuotone /></div>
                        </div>
                        {/* <input className='docNameWrap' placeholder='Enter Document Name' type='text' /> */}
                        {/* <div className="py-2">
                            <QuillEditor placeholder={'Enter Document Name'} customClass={'docTextEditor1'} />
                        </div>
                        <button className='docAddBtn'>Add</button>
                </div> */} 

                {
                  collageDocMappingArr?.map((obj, index) => (
                    <div key={index} className='docCol'>
                          <div className='docImgWrap'>
                              <img className="docImg" src='https://wpamelia.com/wp-content/uploads/2019/04/Lipsum.jpg' />
                          </div>
                          <div className="docColName">
                            <p className='colNormalText2 mt-2 pt-1 mb-0 pb-0 px-2 '>{obj?.collage?.name}</p>
                          </div>
                          <p className='docSpanText p-0 pb-1 px-2 m-0 '>{obj?.collage?.deadline}</p>
                          {!obj?.collage?.deadline && (<p className='docSpanText p-0 pb-1 px-2 m-0 '>deadline</p>)}
                          <button onClick={() => handleDocEditClick(obj)} className='docEditBtn'>Add / Edit</button>
                  </div>
                  ))
                }
                
                {/* <div className='docCol'>
                        <div className='docImgWrap'>
                            <img className="docImg" src='https://wpamelia.com/wp-content/uploads/2019/04/Lipsum.jpg' />
                        </div>
                        <p className='colNormalText2 mt-2 pt-1 mb-0 pb-0 px-2 '>Document 123</p>
                        <p className='docSpanText p-0 pb-1 px-2 m-0 '>03 feb 2023</p>
                        <button className='docEditBtn'>Edit</button>
                </div> */}

                <Modal show={show} onHide={() => setShow(false)}>
                    <div className=" docModal p-2">
                        <div className='d-flex justify-content-end'>
                            <AiOutlineCloseCircle onClick={() => setShow(false)} className='essayCloseBtn'/>
                        </div>
                        <p className="colNormalText">{selectedCollegeDoc?.collage.name}</p>

                        {
                          selectedCollegeDoc?.documents?.length > 0 ? (
                            <div>
                              <div>
                              <div className="row m-2 border-bottom">
                                    <div className="col-1">
                                        {selectedCollegeDoc?.documents?.length > 0
                                        ? progressCircle(
                                          parseInt(
                                            (newFileToUse / selectedCollegeDoc?.documents?.length) *
                                            100
                                          )
                                        )
                                        : progressCircle(parseInt(0))}
                                    </div>
                                    
                                    {selectedCollegeDoc?.documents?.length === newFileToUse && (
                                      <div className="col-3">
                                          <button onClick={() => transferBtnClick()} className="essayBtn2">Transfer</button>
                                      </div>
                                    )}

                                    <div className="col-4">
                                        <p className="colNormalText2 m-0">{newFileToUse}</p>
                                        <p className="colLightText m-0">Files Uploaded</p>
                                    </div>
                                    <div className="col-4">
                                        <p className="colNormalText2 m-0">{selectedCollegeDoc?.documents?.length}</p>
                                        <p className="colLightText m-0">Files Required</p>
                                    </div>

                                    
                                </div>                               
                              </div>
      
                              <div className="docModalContentList">
                                {selectedCollegeDoc?.documents?.map((obj, index) => (
                                  <div className="docModalContentWrap py-2 my-2" key={index}>
                                    <div className="row m-2">
                                        <div className="col">
                                            <p className="colNormalText2 m-0">{obj[0]?.fileName}</p>
                                            <p className="colLightText m-0">filename</p>
                                        </div>
                                        <div className="col">
                                            <p className="colNormalText2 m-0">{obj[0]?.fileSize}</p>
                                            <p className="colLightText m-0">fileSize</p>
                                        </div>
                                        <div className="col">
                                            <p className="colNormalText2 m-0">{obj[0].fileType}</p>
                                            <p className="colLightText m-0">fileType</p>
                                        </div>
                                    </div>
                                    <div className="row m-2">
                                        <div className="col">
                                            <p className="colNormalText2 m-0">{obj[0]?.requirement}</p>
                                            <p className="colLightText m-0">requirement</p>
                                        </div>
                                        <div className="col">
                                            <p className="colNormalText2 m-0">{obj[0]?.deadline}</p>
                                            <p className="colLightText m-0">Deadline</p>
                                        </div>
                                        <div className="col">
                                          {selectedCollegeDoc?.documents?.length === newFileToUse && (
                                            <>
                                              {studentUploadDocList?.map((doc, index) => (
                                                doc.documentId === obj[0].documentId && (
                                                  <p className={`${getStatus(doc)}Status`} key={index}>{getStatus(doc)}</p>
                                                )
                                              ))}
                                            </>
                                          )}
                                       
                                        </div>
                                    </div>
                                    <div className="row my-2">
                                      <div className="col"><button className="essayBtn2" onClick={() => downloadfile(obj[0])} >Sample File</button></div>
                                      
                                      <div>
                                      {selectedCollegeDoc?.documents?.length === newFileToUse ? (
                                        <>
                                          {studentUploadDocList?.map((doc, index) => (
                                            doc.documentId === obj[0].documentId && (
                                              getStatus(doc) !== 'accepted' ? (
                                                <div>
                                                    <div className="col"><input id={`fileInput-${index}`} hidden type="file" onChange={(e) => {uploadFile(e, obj[0])}} accept={"application/"+obj[0].fileType.toLowerCase()+','+"image/"+obj[0].fileType.toLowerCase()} placeholder="Select file" /></div>
                                                    <label className="essayBtn1" htmlFor={`fileInput-${index}`} >Select File</label>
                                                </div>
                                              ) : <div style={{"display":"block"}}>
                                                    <button disabled style={{"opacity":"0.4"}} className="essayBtn1" >Select File</button>
                                              </div>
                                            )
                                          ))}
                                        </>
                                      ) : (
                                        <>
                                        <div className="col"><input id={`fileInput-${index}`} hidden type="file" onChange={(e) => {uploadFile(e, obj[0])}} accept={"application/"+obj[0].fileType.toLowerCase()+','+"image/"+obj[0].fileType.toLowerCase()} placeholder="Select file" /></div>
                                        <label className="essayBtn1" htmlFor={`fileInput-${index}`} >Select File</label>
                                        </>
                                      )}
                                      </div>


                                      {fileToUseData.includes(obj[0].documentId) ? 
                                      (<div className="col">
                                        <button onClick={() => downloadfiles(obj[0], selectedCollegeDoc?.collage.collageId)} className="essayBtn2" >Download File</button>
                                      </div>)
                                        :(<div className="col"><button className="outlineRedBtn" >No File</button></div>)
                                      }
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : (
                              <div className="noFilesReqWrap">
                                <p className="essayNormalText2 ">No files Required</p>
                              </div>
                          )
                        }


                    </div>      
                </Modal>

            </div>
        )}

        {tab === '2' && (<EssaySec />)}
        {tab === '3' && (<TimelineSec />)}

    </div>
  )
    }

export default Documents;