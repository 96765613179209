import React, { useEffect, useState, useRef, useCallback } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import axios from "axios";
import { MW_URL } from "../../../../config";
import ModalComponent from "../../../../utils/modalComponent";
import { Input, Button } from "reactstrap";
import { FaAngleLeft, FaAngleRight, FaUpload } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";






const Stats = () => {

    const percentage = 66;

    const [isLoading, setIsLoading] = useState(true);
    const [resumefromdb, setResume] = useState([]);
    const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState(
        []
    );
    const [todolistMap, settodolistMap] = useState(null);
    const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
    const [essaysList, setessaysList] = useState([]);
    const [essaysTemplateList, setessaysTemplateList] = useState([]);
    const [updatesList, setupdatesList] = useState([]);
    const [docId, setDocumentId] = useState([]);
    const [uploadedDoc, setUploadedDocPayload] = useState([]);

    const [newResult, setNewResult] = useState(0)
    const [sopResult, setSopResult] = useState(0)

    // mail

    const [mailModalOpen, setMailModalOpen] = useState(false);
    const [editState, setEditState] = React.useState({
      emailId: "",
      resume: "",
    });
    const [recommendationArray, setrecommendationArray] = useState([]);
    const [docValidation, setValidation] = useState(false);
    const [newsList, setNewsDetailsPayload] = useState([]);



    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setEditState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const styles = {
      approved: {
        backgroundColor: " #6C63FF",
      },
      notapproved: {
        color: "red",
      },
    };




    useEffect(() => {
        getAllEssays();        
      }, []);
    
      function getNewsFromApiAndLorcall() {
        axios
          .post(`${MW_URL}` + "getRecommendationByStudentId", {
            studentId: JSON.parse(localStorage.getItem("userData")).email,
          })
          .then((recByIdRes) => {
            // console.log("recByIdREs", recByIdRes);
            if (
              recByIdRes &&
              recByIdRes.data &&
              recByIdRes.data &&
              recByIdRes.data.STATUS &&
              recByIdRes.data.STATUS == "SUCCESS"
            ) {
              // console.log("recByIdREs", recByIdRes.data.data);
              setrecommendationArray(recByIdRes.data.data);
            }
            axios
              .post(`${MW_URL}` + "getNewsFromApi", {})
              .then((getNewsResponse) => {
                // console.log("getNews", getNewsResponse);
                setIsUseEffectCallRequired(false);
                var allNewsDetail = [];
                if (
                  getNewsResponse &&
                  getNewsResponse.data.articles &&
                  getNewsResponse.data.articles[0]
                ) {
                  for (var i = 0; i < getNewsResponse.data.articles.length; i++) {
                    var currentObj = getNewsResponse.data.articles[i];
                    var tempObj = {
                      author: currentObj.author,
                      content: currentObj.content,
                      description: currentObj.summary,
                      publishedat: currentObj.publishedAt,
                      // source: currentObj.source.name,
                      title: currentObj.title,
                      url: currentObj.link,
                    };
                    allNewsDetail.push(tempObj);
                  }
                }else{
                    setIsLoading(false);
                }
                setIsLoading(false);
                setNewsDetailsPayload(allNewsDetail);
                // getAllEventsFun();
              })
              .catch((err) => {
                console.log("err >> ", err);
              });
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      }

    const getAllEssays = () => {
        setIsLoading(true);
        var selectedCollages = [];
        var selectedCollagesIds = [];
        axios
          .post(`${MW_URL}` + "getSelectedListOfCollages", {
            email: JSON.parse(localStorage.getItem("userData")).email,
          })
          .then((getSelectedListOfCollagesresponse) => {
            setResume(getSelectedListOfCollagesresponse.data[0].resume);
            if (
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
                .length > 0
            ) {
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
                (collage) => {
                  selectedCollages.push(collage);
                  selectedCollagesIds.push(collage.collageId);
                }
              );
              setlistOfSelectedCollagesArr(selectedCollages);
    
              if (getSelectedListOfCollagesresponse.data[0].todoList) {
                var mapToSet = new Map(
                  Object.entries(getSelectedListOfCollagesresponse.data[0].todoList)
                );
                selectedCollages.map((colgSelected) => {
                  if (mapToSet.get(colgSelected.collageId) == null)
                    mapToSet.set(colgSelected.collageId, []);
                });
                settodolistMap(mapToSet);
              } else {
                let todoMap = new Map();
                selectedCollages.map((colgSelected) => {
                  if (todoMap.get(colgSelected.collageId) == null)
                    todoMap.set(colgSelected.collageId, []);
                });
                settodolistMap(todoMap);
              }
        
              var templateArr = [];
              axios
                .post(`${MW_URL}` + "getEssays", {
                  ownerId: JSON.parse(localStorage.getItem("userData")).email,
                })
                .then((getAllEssaysResponse) => {
                  setIsUseEffectCallRequired(false);
                  var essaysListToPush = [];
                  var essaysRequirementIds = [];
                  getAllEssaysResponse.data.map((essay) => {
                    if (
                      essay.ownerId ==
                        JSON.parse(localStorage.getItem("userData")).email &&
                      selectedCollagesIds.includes(essay.collageId)
                    ) {
                      essaysListToPush.push({
                        essayId: essay.essayId,
                        content: essay.content,
                        typeOfDocument: essay.typeOfDocument,
                        collageId: essay.collageId,
                        ownerId: essay.ownerId,
                        feedbacks: essay.feedbacks,
                        essayReqId: essay.essayReqId,
                        updatedDate: essay.updatedDate,
                        isApproved: essay.isApproved,
                      });
                      essaysRequirementIds.push(essay.essayReqId);
                    }
                  });
                  setessaysList(essaysListToPush);
                //   defaultDropdownSet(selectedCollages);
                  axios
                    .post(`${MW_URL}` + "get_essay_requirement", selectedCollages)
                    .then((getEssaysTemplateResponse) => {
                      var status = "NA";
                      if (
                        getEssaysTemplateResponse &&
                        getEssaysTemplateResponse.data &&
                        getEssaysTemplateResponse.data.STATUS
                      )
                        status = getEssaysTemplateResponse.data.STATUS;
                      if (status.toUpperCase() === "SUCCESS") {
                        setessaysTemplateList(getEssaysTemplateResponse.data.data);
                        console.log("dd", getEssaysTemplateResponse.data.data)
                        getEssaysTemplateResponse.data.data.map(
                          (templateRes, temploopindex) => {
                            if (templateRes.essayRequirements.length != 0) {
                              templateRes.essayRequirements.map((esaaReq) => {
                                templateArr.push({
                                  template: esaaReq.question,
                                  collageId: templateRes.collageId,
                                  name: templateRes.name,
                                  essayReqId: esaaReq.essayReqId,
                                  length: esaaReq.length,
                                  requirement: esaaReq.requirement,
                                  answered: essaysRequirementIds?.includes(
                                    esaaReq.essayReqId
                                  ),
                                });
                              });
                            }
                            if (
                              getEssaysTemplateResponse.data.data.length ==
                              temploopindex + 1
                            ) {
                              axios
                                .post(`${MW_URL}` + "getUpdates", {
                                  recipientId: JSON.parse(
                                    localStorage.getItem("userData")
                                  ).email,
                                  senderId: false,
                                })
                                .then((getUpdatesResponse) => {
                                  setupdatesList(getUpdatesResponse.data);
                                  setessaysTemplateList(templateArr);
                                  essayComponent(templateArr, essaysListToPush);
                                  console.log("dfd", templateArr)
    
    
                                  axios
                                    .post(
                                      `${MW_URL}` + "documentsbyuser",
                                      selectedCollages
                                    )
                                    .then((documentsbyuserresponse) => {
                                      //   console.log("documentsbyuser", documentsbyuserresponse);
                                      var documentId = [];
                                      var documentsByUserResFromApi =
                                        documentsbyuserresponse;
                                      documentsbyuserresponse.data.map((ids) => {
                                        {
                                          documentId.push(ids);
                                        }
                                      });
                                      var requredDoc = [];
                                      setDocumentId(documentId);
                                      documentId.map((doc) => {
                                        requredDoc.push({
                                          DocId: doc.documentId,
                                          CollageId: doc.collageId,
                                        });
                                      });
                                      axios
                                        .post(
                                          `${MW_URL}` + "getAllStudentsDocsCount",
                                          {
                                            email: JSON.parse(
                                              localStorage.getItem("userData")
                                            ).email,
                                          }
                                        )
                                        .then((getStudDOcResponse) => {
                                          setIsUseEffectCallRequired(false);
                                          var allDetail = [];
                                          if (
                                            getStudDOcResponse &&
                                            getStudDOcResponse.data &&
                                            getStudDOcResponse.data[0]
                                          ) {
                                            var DocMapArrTemp = [];
                                            var DocMapObjTemp = [];
                                            getStudDOcResponse.data.map(
                                              (documentsCollageMap) => {
                                                requredDoc.map((reqDocId) => {
                                                  if (
                                                    documentsCollageMap?.documentId?.includes(
                                                      reqDocId.DocId
                                                    ) &&
                                                    documentsCollageMap?.collegeId?.includes(
                                                      reqDocId.CollageId
                                                    )
                                                  ) {
                                                    DocMapObjTemp.push({
                                                      doc: documentsCollageMap?.documentId,
                                                    });
                                                  }
                                                });
                                                if (
                                                  DocMapObjTemp.doc ==
                                                  requredDoc.DocId
                                                ) {
                                                  DocMapArrTemp.push(DocMapObjTemp);
                                                }
                                              }
                                            );
                                            // console.log("collageDocMapArrTemp",collageDocMapArrTemp);
                                            setUploadedDocPayload(DocMapArrTemp[0]);
                                            sopComponent(DocMapArrTemp[0], documentId)
                                          }
                                          getNewsFromApiAndLorcall();
                                        });
                                    });
                                })
    
                                .catch((err) => {
                                  console.log("err >> ", err);
                                })
                                .catch((err) => {
                                  console.log("err >> ", err);
                                })
                                .catch((err) => {
                                  console.log("err >> ", err);
                                })
                                .catch((err) => {
                                  console.log("err >> ", err);
                                });
                            }
                          }
                        );
                      } else {
                        getNewsFromApiAndLorcall();
                      }
                    })
                    .catch((err) => {
                      console.log("err >> ", err);
                    });
                })
                .catch((err) => {
                  console.log("err >> ", err);
                });
            } else {
              getNewsFromApiAndLorcall();
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      };

    const essayComponent = (essaysTemplateList, essaysList) => {
        var templateList = [];
        essaysTemplateList.map((template) => {
            console.log(template, "tem")
          templateList.push(template.essayReqId);
        });
        var counter = 0;
        essaysList.map((essay) => {
            console.log(essay, "ess")
          if (templateList?.includes(essay.essayReqId)) {
            if (essay.isApproved == true) {
              counter = counter + 1;
            }
          }
        });
        const result = Math.round((counter * 100) / essaysTemplateList.length);
        setNewResult(result)
    }

    const sopComponent = (uploadedDoc, DocId) => {
        const result = Math.round((uploadedDoc.length * 100) / DocId.length);
        setSopResult(result)
    }

    const mailModalHandler = (dataObject) => {
      if (dataObject.type === "cancel") {
        setMailModalOpen(false);
        setEditState({
          emailId: "",
        });
      } else if (dataObject.type === "submit") {
        setIsLoading(true);
        var dataToSend = {
          emailId: editState.emailId,
          studentId: JSON.parse(localStorage.getItem("userData")).email,
          studentName: JSON.parse(localStorage.getItem("userData")).username,
          updateRecipientId: JSON.parse(localStorage.getItem("userData"))
            .counsellorId,
        };
  
        axios
          .post(`${MW_URL}` + "lorMailHandler", dataToSend)
          .then((lorMailHandlerRes) => {
            // console.log("lorMailHandlerRes====>", lorMailHandlerRes);
            if (
              lorMailHandlerRes &&
              lorMailHandlerRes.data &&
              lorMailHandlerRes.data.STATUS === "FINISHED"
            ) {
              setEditState({
                emailId: "",
              });
  
              axios
                .post(`${MW_URL}` + "getRecommendationByStudentId", {
                  studentId: JSON.parse(localStorage.getItem("userData")).email,
                })
                .then((recByIdRes) => {
                  setMailModalOpen(false);
                  setIsLoading(false);
                  // console.log("recByIdREs", recByIdRes);
                  if (
                    recByIdRes &&
                    recByIdRes.data &&
                    recByIdRes.data &&
                    recByIdRes.data.STATUS &&
                    recByIdRes.data.STATUS == "SUCCESS"
                  ) {
                    // console.log("recByIdREs", recByIdRes.data.data);
                    setrecommendationArray(recByIdRes.data.data);
                  }
                })
                .catch((err) => {
                  console.log("err >> ", err);
                });
            } else {
              console.log("HERE");
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      }
    };

    const uploadFile = async (e) => {
      const file = e.target.files[0];
      var fileExtension = file.name.split(".").pop();
      fileExtension = fileExtension.toUpperCase();
      if (fileExtension == "PDF") {
        const base64 = await convertBase64(file);
        setEditState({
          resume: base64,
        });
        setValidation(true);
      } else {
        toast.error("Please Upload PDF files", {
          autoClose: 1000,
        });
      }
    };

    function resendRequenstMail(recId) {
      setIsLoading(true);
      axios
        .post(`${MW_URL}` + "resendRecommendationByRecommendationId", {
          studentName: JSON.parse(localStorage.getItem("userData")).username,
          recommendationId: recId,
        })
        .then((resendRecRes) => {
          axios
            .post(`${MW_URL}` + "getRecommendationByStudentId", {
              studentId: JSON.parse(localStorage.getItem("userData")).email,
            })
            .then((recByIdRes) => {
              //  setMailModalOpen(false);
              setIsLoading(false);
              // console.log("recByIdREs", recByIdRes);
              if (
                recByIdRes &&
                recByIdRes.data &&
                recByIdRes.data &&
                recByIdRes.data.STATUS &&
                recByIdRes.data.STATUS == "SUCCESS"
              ) {
                // console.log("recByIdREs", recByIdRes.data.data);
                setrecommendationArray(recByIdRes.data.data);
              }
            })
            .catch((err) => {
              console.log("err >> ", err);
            });
        });
    }

    function download(data) {
      // console.log("decode", Name)
      const linkSource = `${data}`;
      const downloadLink = document.createElement("a");
      const fileName = "resume";
  
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }

    const uploadBtn = () => {
      if (docValidation) {
        var dataToSend = {
          resume: editState.resume,
          email: JSON.parse(localStorage.getItem("userData")).email,
        };
        // console.log("DatatoSent", dataToSend);
        setIsLoading(true);
  
        axios
          .post(`${MW_URL}` + "studentUploadresume", dataToSend)
          .then((getUploadDetailResponse) => {
            if (
              getUploadDetailResponse &&
              getUploadDetailResponse.data &&
              getUploadDetailResponse.data.STATUS === "SUCCESS"
            ) {
              setEditState({
                file: "",
              });
              axios
                .post(`${MW_URL}` + "getSelectedListOfCollages", {
                  email: JSON.parse(localStorage.getItem("userData")).email,
                })
                .then((getSelectedListOfCollagesresponse) => {
                  setResume(getSelectedListOfCollagesresponse.data[0].resume);
                  // setMailModalOpen(false);
                  setIsLoading(false);
                });
            } else {
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      } else {
        toast.error("Please Upload PDF files", {
          autoClose: 2500,
        });
      }
    };

    const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
  
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };
  

  return (
    <div className='dashStats '>
        <h3 className='dashHead3'>Stats</h3>
        <div className='row'>
            <div className='col-4'>
                <div className='statBlocks '>
                    <div className='d-flex justify-content-around'>
                        <div className='py-2'>
                            <CircularProgressbar value={newResult} text={`${newResult} %`} strokeWidth={8}
                                styles={buildStyles({
                                    strokeLinecap: 'round',
                                    pathColor: "#FF981D",
                                    // pathColor: `#FF981D, ${percentage / 100})`,
                                    textColor: '#FF981D',
                                    trailColor: '#d6d6d6',
                                })}
                            />
                        </div>
                        <div className='py-2 col-6'>
                            <p className='dashTitle2'>Essays</p>
                            <p className='profileTitle'>{essaysTemplateList.length}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-4'>
                <div onClick={() => setMailModalOpen(true)}  className='statBlocks '>
                    <div className='d-flex justify-content-around'>
                        <div className='py-2'>
                            <CircularProgressbar value={20} text={"50%"} strokeWidth={8}
                                styles={buildStyles({
                                    strokeLinecap: 'round',
                                    pathColor: "#FF981D",
                                    // pathColor: `#FF981D, ${percentage / 100})`,
                                    textColor: '#FF981D',
                                    trailColor: '#d6d6d6',
                                })}
                            />
                        </div>
                        <div className='py-2 col-6'>
                            <p className='dashTitle2'>LOR</p>
                            <p className='profileTitle'>10</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-4'>
                <div className='statBlocks '>
                    <div className='d-flex justify-content-around'>
                        <div className='py-2'>
                            <CircularProgressbar value={sopResult} text={`${sopResult} %`} strokeWidth={8}
                                styles={buildStyles({
                                    strokeLinecap: 'round',
                                    pathColor: "#FF981D",
                                    // pathColor: `#FF981D, ${percentage / 100})`,
                                    textColor: '#FF981D',
                                    trailColor: '#d6d6d6',
                                })}
                            />
                        </div>
                        <div className='py-2 col-6'>
                            <p className='dashTitle2'>SOP</p>
                            <p className='profileTitle'>10</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <ModalComponent
        isVisible={mailModalOpen}
        mainHeading="Email"
        callback={mailModalHandler}
        modalSize="xl"
      >
        {/* {isLoading ? (
          <LoadingAnimation isVisible={false} />
        ) : ( */}
          <div className="col-md-12 p-0">
            <div className="d-flex col-md-12 p-o m-0">
              <div className=" col-md-8 d-flex justify-content-center align-items-center">
                <div className="col-md-4 d-flex tansfer-button ">
                  <input
                    className="justify-content-center align-items-center"
                    style={{ backgroundColor: "#6C63FF" }}
                    type="file"
                    id="resume"
                    hidden
                    onChange={(e) => {
                      uploadFile(e);
                    }}
                  />
                  <div>
                    <FaUpload className="ml-4 mr-1" style={{ fontSize: 10 }} />
                    <label
                      className="dashNormalText2"
                      id="resume"
                      for="resume"
                      style={{ cursor: "pointer" }}
                    >
                      Select resume
                    </label>
                  </div>
                </div>

                <div className="col-md-3 p-0">
                  <Button
                    className="login-btn px-5"
                    style={editState.resume ? styles.approved : null}
                    onClick={() => uploadBtn()}
                  >
                    upload
                  </Button>
                </div>
              </div>
              {resumefromdb ? (
                <Button
                  className="login-btn px-5"
                  style={resumefromdb ? styles.approved : null}
                  onClick={() => download(resumefromdb)}
                >
                  Download
                </Button>
              ) : null}
            </div>

            {resumefromdb ? (
              <div className="col-md-10 p-0 d-flex justify-content-center form-group flex-column mt-3 ml-5">
                <Input
                  type="text"
                  style={{ width: "100%" }}
                  className="text-box-style my-3 py-2 input_data mandatoryField"
                  name="emailId"
                  placeholder="EmailIds separated by commas"
                  value={editState.emailId}
                  onChange={handleInputChange}
                />
                <div className="errorDesc"></div>
              </div>
            ) : (
              <div className="col-md-10 p-0 d-flex justify-content-center form-group flex-column mt-3 ml-5">
                Upload your resume to send lor requests!
              </div>
            )}
            <div>
              {recommendationArray.length > 0 ? (
                <div
                  className="d-flex col-md-12"
                  style={{ border: "1px solid #00000020" }}
                >
                  <div
                    className="p-1 px-2 col-md-7"
                    style={{
                      fontWeight: "600",
                      alignSelf: "center",
                      borderRight: "1px solid #00000020",
                    }}
                  >
                    Recipient Mail
                  </div>
                  <div
                    className="p-1 px-2 col-md-3"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #00000020",
                      borderLeft: "1px solid #00000020",
                    }}
                  >
                    Status
                  </div>
                  <div
                    className="p-1 px-2 col-md-2"
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    Action
                  </div>
                </div>
              ) : null}
              {recommendationArray.map((recommendation) => {
                return (
                  <div
                    className="d-flex col-md-12"
                    style={{ border: "1px solid #00000020" }}
                    key={recommendation.recommendationId}
                  >
                    <div
                      className="p-1 px-2 col-md-7"
                      style={{
                        fontWeight: "600",
                        alignSelf: "center",
                        borderRight: "1px solid #00000020",
                      }}
                    >
                      {recommendation.recipientId}
                    </div>
                    <div
                      className="p-1 px-2 col-md-3"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #00000020",
                        borderLeft: "1px solid #00000020",
                      }}
                    >
                      {recommendation.status}
                    </div>
                    <div
                      className="p-1 px-2 col-md-2"
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      {recommendation.action > 0 ? (
                        <div
                          onClick={() => {
                            resendRequenstMail(recommendation.recommendationId);
                          }}
                          style={{ color: "blueviolet", cursor: "pointer" }}
                        >
                          Remind
                        </div>
                      ) : (
                        <div>no action</div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        {/* // )} */}
      </ModalComponent>


    </div>
  )
}

export default Stats