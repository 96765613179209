import React, { useEffect, useState } from "react";
import LoadingAnimation from '../../utils/loadingAnimation';
import Boypicsample from "../../imageAssets/boypicsample.png";
import Manpicsample from "../../imageAssets/manpicsample.png";
import Manwearingeyeglasses from "../../imageAssets/manwearingeyeglasses.png";
import Womanpics from "../../imageAssets/womanpics.png";
import Womanpicsample from "../../imageAssets/womanpicsample.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import FeatherMenuIcon from "../../assets/svg/feathermenuicon";
import { FaCaretLeft, FaCaretRight, FaBars } from "react-icons/fa";
import Cookingimg from "../../imageAssets/cookingimg.png";
import Basketballimg from "../../imageAssets/basketballimg.png";
import Debate from "../../imageAssets/debate.png";
import Travelvlog from "../../imageAssets/travelvlog.png";
import Parttime from "../../imageAssets/parttime.png";
import Socilawork from "../../imageAssets/socilawork.png";
import Messageicon from "../../imageAssets/messageicon.png";


const AluminiNetworkScreen = () => {

  const [height, setHeight] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [fileterMenu, setfileterMenu] = useState(false);
  const [centerimghoverdiv, setcenterimghoverdiv] = useState(false);
  const [aluminiimgdiv, setaluminiimgdiv] = useState(true);
  const [aluminidetaildiv, setaluminidetaildiv] = useState(false);
  const [aluminidetailslide1, setaluminidetailslide1] = useState(true);
  const [aluminidetailslide2, setaluminidetailslide2] = useState(false);
  const [universitySubLink, setuniversitySubLink] = useState(false);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  function showimgslide() {
    setaluminiimgdiv(true)
    setaluminidetaildiv(false)
  }

  function showaluminislide1() {
    setaluminidetailslide1(true);
    setaluminidetailslide2(false);
  }

  function showaluminislide2() {
    setaluminidetailslide1(false);
    setaluminidetailslide2(true);
  }


  useEffect(() => {
    var windowHeight = window.innerHeight;
    setHeight(windowHeight + "px");
    setTimeout(() => {
      setIsLoading(false)
    }, 2000);
  })

  const backbutton = () => {
    document.getElementById("filterdiv").classList.remove('animated', 'slideInRight');
    document.getElementById("filterdiv").classList.add('animated', 'slideOutRight');
    document.getElementById("FilterBackButton").classList.add('animated', 'slideOutLeft');

    setTimeout(() => {
      setfileterMenu(false);
    }, 800);
  }

  const showaluminidetailscene = () => {
    setaluminiimgdiv(false)
    setaluminidetaildiv(true)
  }

  return (
    <div>
      {isLoading ? <LoadingAnimation isVisible={true} /> : null}
      <div className="col-md-12 alumini-network-page-container d-flex flex-column p-0 m-0" style={{ minHeight: height }}>
        {fileterMenu ? (<div className="d-flex" style={{ position: "relative" }}>
          <div id="filterdiv" className={fileterMenu ? "animated slideInRight d-flex flex-column align-items-center justify-content-center" : null} style={{ position: "absolute", right: 0, minHeight: height, width: 300, backgroundColor: "white", zIndex: 3 }}>
            <div className="pb-3" style={{ fontWeight: 100 }}>FILTER</div>
            {filterMenu()}
          </div>
          <div id="FilterBackButton" className={fileterMenu ? "animated slideInLeft" : null} style={{ fontSize: 50, fontWeight: 800, cursor: "pointer", position: "absolute", top: 30, left: 40, zIndex: 5 }} onClick={() => { backbutton() }}>{"<"}</div>
        </div>) : null}
        {aluminiimgdiv ? (<div className="col-md-12" id="aluminiimgslide">
          <div className="col-md-12 p-5 d-flex justify-content-end" style={{ position: "relative" }}>
            <div style={{ cursor: "pointer" }} onClick={() => { setfileterMenu(true) }}>
              <FaBars style={{ fontSize: 20, cursor: "pointer" }} />
            </div>
          </div>
          <div className="col-md-12 p-0 m-0" >
            <div style={{ overflow: "hidden", position: "fixed", left: 100 }} className="col-md-12 p-0 pt-5 m-0 d-flex justify-content-between align-items-center">
              <img className="pl-0 py-2 pr-2" src={Boypicsample} alt="Boypicsample" style={{ width: 280, height: 330 }} />
              <img className="p-2" src={Womanpicsample} alt="womanpicsample" style={{ width: 280, height: 330 }} />
              <div className={aluminiimgdiv ? "animated slideInLeft p-2" : "p-2"} onMouseOver={() => { setcenterimghoverdiv(true) }} onMouseOut={() => { setcenterimghoverdiv(false) }}>
                <div style={{ width: 350, height: 450, position: "absolute", zIndex: 5 }} onClick={() => { showaluminidetailscene() }}>
                </div>
                {centerimghoverdiv ? (<div style={{ cursor: "pointer" }} className={centerimghoverdiv ? "animated fadeIn centerimghoverdiv" : "animated fadeOut centerimghoverdiv"}>
                  <div style={{ position: "absolute", left: 25, bottom: 40 }}>
                    <div style={{ color: "white", fontSize: 20, lineHeight: 1, fontWeight: 800 }}>JACOB DANIEL</div>
                    <div style={{ color: "white", fontSize: 12, lineHeight: 1.5 }}>USC SENIOR</div>
                  </div>
                </div>) : null}
                <img src={Manpicsample} alt="Manpicsample" style={{ width: 350, height: 450, zIndex: 1 }} />
              </div>
              <img className="p-2" src={Manwearingeyeglasses} alt="Manwearingeyeglasses" style={{ width: 280, height: 330 }} />
              <img className="pl-2 py-2 pr-0" src={Womanpicsample} alt="Womanpicsample" style={{ width: 280, height: 330 }} />
            </div>
          </div>
        </div>) : null}

        {aluminidetaildiv ? (<div id="aluminidetailscene" className="col-md-12 p-5 pl-5 m-0" style={{ position: "absolute" }}>
          <div className={fileterMenu ? "col-md-12" : "col-md-12 animated slideInLeft"} style={{ fontSize: 50, fontWeight: 800, height: 60 }} ><div className="col-md-1" style={{ cursor: "pointer" }} onClick={() => { showimgslide() }}>{fileterMenu ? "" : "<"}</div> </div>
          <div className="col-md-12 p-4 d-flex" >
            <div className="col-md-1 d-flex align-items-center">
              <FaCaretLeft style={{ fontSize: 40, cursor: "pointer" }} onClick={() => { showaluminislide1() }} />
            </div>
            <div className="col-md-4">
              <img src={Manpicsample} className={aluminidetaildiv ? "animated fadeInRight" : ""} alt="Manpicsample" style={{ width: 300, height: 450, zIndex: 1 }} />
            </div>
            <div className="col-md-6 p-0 d-flex" style={{}}>
              {aluminidetailslide1 ? (<div id="aluminidetailslide1" className={aluminidetailslide1 ? "animated fadeIn col-md-12 d-flex p-0" : "col-md-12 d-flex p-0"}>
                <div className="col-md-6 p-0 pl-3">
                  <div className="p-0">
                    <div className="aluminidetailimghoverdiv d-flex flex-column align-items-center justify-content-center">
                      <div style={{ color: "white" }}>
                        <div className="p-5"> HOLDS 2 MICHELLIN</div>
                      </div>
                    </div>
                    <img src={Cookingimg} alt="Manpicsample" style={{ height: 220, width: 230, zIndex: 1 }} />
                  </div>
                  <div className="pt-2">
                    <div className="aluminidetailimghoverdiv d-flex flex-column align-items-center justify-content-center">
                      <div style={{ color: "white" }}>
                        <div className="p-5"> PLAYED FOR LAKERS</div>
                      </div>
                    </div>
                    <img src={Basketballimg} alt="Manpicsample" style={{ height: 220, width: 230, zIndex: 1 }} />
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-center justify-content-center" style={{ backgroundColor: "white" }}>
                  <div className="p-3">
                    <div style={{ fontSize: 10, fontWeight: 800 }}>ABOUT ME</div>
                    <div style={{ fontSize: 8, fontWeight: 200, color: "#707070" }}>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available. Lorem ipsum may be used as a placeholder before the final copy is available.</div>
                    <div className="pt-3 d-flex justify-content-between">
                      <div>
                        <div style={{ fontSize: 10, fontWeight: 800 }}>SATs</div>
                        <div>1500</div>
                      </div>
                      <div>
                        <div style={{ fontSize: 10, fontWeight: 800 }}>APs</div>
                        <div>6.0</div>
                      </div>
                      <div>
                        <div style={{ fontSize: 10, fontWeight: 800 }}>GPA</div>
                        <div>4.0</div>
                      </div>
                    </div>
                    <div className="col-md-12 p-0 pt-3 d-flex justify-content-between">
                      <div className="col-md-5  d-flex align-items-center justify-content-center" style={{ fontSize: 6, color: "white", backgroundColor: "#7C73FD", borderRadius: 15 }}>
                        <div className="pr-2">COMPARE</div>
                        <img src={Messageicon} alt="messageicon" style={{ height: 7, width: 7, zIndex: 1 }} />

                      </div>
                      <div className="col-md-5 p-1 py-2 d-flex align-items-center justify-content-center" style={{ fontSize: 6, color: "white", backgroundColor: "#7C73FD", borderRadius: 15 }}>
                        <div className="pr-2">CONNECT</div>
                        <img src={Messageicon} alt="messageicon" style={{ height: 7, width: 7, zIndex: 1 }} />

                      </div>
                    </div>
                  </div>
                </div>
              </div>) : null}

              {aluminidetailslide2 ? (<div id="aluminidetailslide2" className={aluminidetailslide2 ? "animated fadeIn col-md-12 d-flex p-0" : "col-md-12 d-flex p-0"} style={{ position: "absolute" }}>
                <div className="col-md-6 p-0 pl-3 pr-2">
                  <div className="">
                    <div className="aluminidetailimghoverdiv2top d-flex flex-column align-items-center justify-content-center">
                      <div style={{ color: "white" }}>
                        <div className="p-5">TRAVEL VLOGGER</div>
                      </div>
                    </div>
                    <img src={Travelvlog} alt="Manpicsample" style={{ height: 220, width: 220, zIndex: 1 }} />
                  </div>
                  <div className="pt-3">
                    <div className="aluminidetailimghoverdiv2botoom d-flex flex-column align-items-center justify-content-center">
                      <div style={{ color: "white" }}>
                        <div className="p-5"> SOCIAL WORK</div>
                      </div>
                    </div>
                    <img src={Socilawork} alt="Manpicsample" style={{ height: 210, width: 220, zIndex: 1 }} />
                  </div>
                </div>
                <div className="col-md-6 p-0 pl-3">
                  <div className="pl-2">
                    <div className="aluminidetailimghoverdiv2top d-flex flex-column align-items-center justify-content-center">
                      <div style={{ color: "white" }}>
                        <div className="p-5">DEBATE CLUB PRESIDENT</div>
                      </div>
                    </div>
                    <img src={Debate} alt="Manpicsample" style={{ height: 220, width: 220, zIndex: 1 }} />
                  </div>
                  <div className="pt-3 pl-2">
                    <div className="aluminidetailimghoverdiv2botoom d-flex flex-column align-items-center justify-content-center">
                      <div style={{ color: "white" }}>
                        <div className="p-5">PART-TIME TUTOR</div>
                      </div>
                    </div>
                    <img src={Parttime} alt="Manpicsample" style={{ height: 210, width: 220, zIndex: 1 }} />
                  </div>
                </div>
              </div>) : null}


            </div>
            <div className="col-md-1 d-flex align-items-center">
              <FaCaretRight style={{ fontSize: 40, cursor: "pointer" }} onClick={() => { showaluminislide2() }} />
            </div>
          </div>
        </div>) : null}


      </div>
    </div>
  )

  function filterMenu() {
    return (<div className="d-flex flex-column align-items-center">
      <div className="py-2">
        <div className="menuLink" style={{ cursor: "pointer" }} onClick={() => { setuniversitySubLink(!universitySubLink) }}>UNIVERSITY</div>
        {universitySubLink ? (<div className={universitySubLink ? "animated fadeInUp d-flex flex-column align-items-center" : null}>
          <div className="menuSubLink py-2">HARVARD</div>
          <div className="menuSubLink py-2">DARTMOUTH</div>
          <div className="menuSubLink py-2">YALE</div>
          <div className="menuSubLink py-2">CORNELL</div>
          <div className="menuSubLink py-2">ASHOKA</div>
          <div className="menuSubLink py-2">USC</div>
          <div className="menuSubLink py-2">UBC</div>
        </div>) : null}
      </div>
      <div className="menuLink py-2 ">MAJOR</div>
      <div className="menuLink py-2">COUNTRY</div>
      <div className="menuLink py-2">YEAR</div>
      <div className="menuLink py-2">EXPERTISE</div>
      <div className="menuLink py-2">INTERNSHIP</div>
    </div>)
  }
}

export default AluminiNetworkScreen;