import React, { useEffect, useState } from "react";
import LoadingAnimation from "../../../../utils/loadingAnimation";
import axios from "axios";
import { MW_URL } from "../../../../config";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Checkbox from "@mui/material/Checkbox";
import { FaCaretDown, FaTrash } from "react-icons/fa";
import { AiOutlineCloseCircle } from 'react-icons/ai';






const CollageFilters = ({ setfilterStringToSend, collagedetailsfromapi, setShowFilterSec, addFilter, setAddFilter }) => {

    // const [isLoading, setIsLoading] = useState(true);
    // const [addUniversitySecVisible, setAddUniversitySecVisible] = useState(false);
    // const [showAllSecVisible, setshowAllSecVisible] = useState(false);
    // const [universityDetails, setUniversityDetails] = useState(false);
    // const [countrySecVisible, SetCountrySecVisible] = useState(false);
    // const [featherDropdownVisible, SetfeatherDropdownVisible] = useState(false);
    // const [compareUniversitySecVisible, setCompareUniversitySecVisible] =
    //   useState(false);
    const [dropdownDivMajor, setdropdownDivMajor] = useState(false);
    const [dropdownDivScholarship, setdropdownDivScholarship] = useState(false);
    const [dropdownDivTution, setdropdownDivTution] = useState(false);
    const [dropdownDivTypeofschool, setdropdownDivTypeofschool] = useState(false);
    const [dropdownDivTestscores, setdropdownDivTestscores] = useState(false);
    // const [finalCompareSecVisible, setFinalCompareSecVisible] = useState(true);
    // const [listOfCollagesArr, setlistOfCollagesArr] = useState([]);
    // const [listOfCollageDetailsArr, setlistOfCollageDetailsArr] = useState([]);
    // const [listOfSelectedCollageDetailsArr, setlistOfSelectedCollageDetailsArr] =
    //   useState([]);
    // const [resetUniversitySecVisible, setResetUniversitySecVisible] =
    //   useState(false);
    // const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
    const [maxPriceFilterVal, setmaxPriceFilterVal] = useState(35000);
    const [maxActScoreFilterVal, setmaxActScoreFilterVal] = useState(20);
    const [maxSatScoreFilterVal, setmaxSatScoreFilterVal] = useState(1000);
    // const [collageSearchKeyword, setcollageSearchKeyword] = useState("");
    // const [scoreType, setScoreType] = useState("satscore");
    // const [scoreoptions, setScoreOptions] = useState([]);
    // var [data, setData] = useState(null);
    const [actScoreChecked, setactScoreChecked] = useState(true);
    const [satScoreChecked, setsatScoreChecked] = useState(true);
    const [maxPriceChecked, setmaxPriceChecked] = useState(true);

    useEffect(() => {
        if (addFilter == true) {
            onFilterBtnClick();
            setAddFilter(false);
        }
    }, [addFilter])



    const maxpricevaluechange = (event, newValue) => {
        setmaxPriceFilterVal(newValue);
        console.log(newValue, "newvalue")
    };

    const maxactscorevaluechange = (event, newValue) => {
        setmaxActScoreFilterVal(newValue);
        console.log(newValue, "newvalue-actscore")
    };

    const maxsatscorevaluechange = (event, newValue) => {
        setmaxSatScoreFilterVal(newValue);
        console.log(newValue, "newvalue-sat")
    };

    const actcheckboxvaluechange = (event, newValue) => {
        setactScoreChecked(newValue);
        console.log(newValue, "act-val-cahnged")
    };

    const satcheckboxvaluechange = (event, newValue) => {
        setsatScoreChecked(newValue);
        console.log(newValue, "sat-val-cahnged")
    };

    const maxPricecheckboxvaluechange = (event, newValue) => {
        setmaxPriceChecked(newValue);
    };


    const onFilterBtnClick = () => {
        if (
            maxPriceFilterVal ||
            maxActScoreFilterVal ||
            maxSatScoreFilterVal
        ) {
            setfilterStringToSend({
                maxPriceFilterVal: maxPriceChecked
                    ? maxPriceFilterVal
                    : maxPriceChecked,
                maxActScoreFilterVal: actScoreChecked
                    ? maxActScoreFilterVal
                    : actScoreChecked,
                maxSatScoreFilterVal: satScoreChecked
                    ? maxSatScoreFilterVal
                    : satScoreChecked,
            });
            collagedetailsfromapi(0, {
                maxPriceFilterVal: maxPriceChecked
                    ? maxPriceFilterVal
                    : maxPriceChecked,
                maxActScoreFilterVal: actScoreChecked
                    ? maxActScoreFilterVal
                    : actScoreChecked,
                maxSatScoreFilterVal: satScoreChecked
                    ? maxSatScoreFilterVal
                    : satScoreChecked,
            });
        } else {
            // console.log("maxPriceFilterVal =", maxPriceFilterVal);
            // console.log("maxActScoreFilterVal =", maxActScoreFilterVal);
            // console.log("maxSatScoreFilterVal =", maxSatScoreFilterVal);
            setfilterStringToSend(false);
        }
    }

    return (
        <div>
            <div className="gx-3 pt-4 pb-4">
                <div>
                    <div className="mb-4">
                        <div style={{ fontWeight: "bold" }}>Scholarships</div>
                        <div
                            className="headings-style collegefiltersheading"
                            onClick={() =>
                                setdropdownDivScholarship(!dropdownDivScholarship)
                            }
                        >
                            {"Select"}
                            <FaCaretDown style={{ fontSize: 15 }} />
                        </div>
                        {dropdownDivScholarship ? (
                            <div className="col-md-12 m-0 p-0 animated fadeInDown mt-2 collegefilterdropdown">
                                <div className="form-group collegefilterformgroup">
                                    <Checkbox
                                        sx={{
                                            border: "#9F9F9F",
                                            color: "#6A5DFB",
                                            "& .Mui-checked,": {
                                                color: "#6A5DFB !important",
                                            }
                                        }}
                                    />
                                    MERIT SCHOLRSHIPS
                                </div>
                                <div className="form-group collegefilterformgroup">
                                    <Checkbox
                                        sx={{
                                            border: "#6C63FF",
                                            color: "#6C63FF",
                                            "& .Mui-checked,": {
                                                color: "#6A5DFB !important",
                                            }
                                        }}
                                    />
                                    FINANCIAL AID
                                </div>
                                <div className="form-group">
                                    <Checkbox
                                        sx={{
                                            border: "#6C63FF",
                                            color: "#6C63FF",
                                            "& .Mui-checked,": {
                                                color: "#6A5DFB !important",
                                            }
                                        }}
                                    />
                                    FULL RIDE
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                {/* Majors */}
                <div >
                    <div className="mb-4">
                        <div style={{ fontWeight: "bold" }}>Majors</div>
                        <div
                            className="headings-style collegefiltersheading"
                            onClick={() => setdropdownDivMajor(!dropdownDivMajor)}
                        >
                            {"Select"}
                            <FaCaretDown style={{ fontSize: 15 }} />
                            {dropdownDivMajor ? (
                                <div className="col-md-12 m-0 p-0"></div>
                            ) : null}
                        </div>
                    </div>
                </div>
                {/* Type of Schools */}
                <div >
                    <div className="mb-4">
                        <div style={{ fontWeight: "bold" }}>Type of School</div>
                        <div
                            className="headings-style collegefiltersheading"
                            onClick={() =>
                                setdropdownDivTypeofschool(!dropdownDivTypeofschool)
                            }
                        >
                            {"Select"}
                            <FaCaretDown style={{ fontSize: 15 }} />
                        </div>

                        {dropdownDivTypeofschool ? (
                            <div className="col-md-12 m-0 p-0 animated fadeInDown mt-2 collegefilterdropdown">
                                <h6>
                                    <b>PUBLIC/PRIVATE</b>
                                </h6>
                                <div className="row gx-3">
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <Checkbox
                                                sx={{
                                                    border: "#6C63FF",
                                                    color: "#6C63FF",
                                                    "& .Mui-checked,": {
                                                        color: "#6A5DFB !important",
                                                    }
                                                }}
                                            />
                                            PUBLC
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <Checkbox
                                                sx={{
                                                    border: "#6C63FF",
                                                    color: "#6C63FF",
                                                    "& .Mui-checked,": {
                                                        color: "#6A5DFB !important",
                                                    }
                                                }}
                                            />
                                            PRIVATE
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <Checkbox
                                                sx={{
                                                    border: "#6C63FF",
                                                    color: "#6C63FF",
                                                    "& .Mui-checked,": {
                                                        color: "#6A5DFB !important",
                                                    }
                                                }}
                                            />
                                            FOR PROFIT
                                        </div>
                                    </div>
                                </div>
                                <h6>
                                    <b>SIZE OF SCHOOL</b>
                                </h6>
                                <div className="row gx-3">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <Checkbox
                                                sx={{
                                                    border: "#6C63FF",
                                                    color: "#6C63FF",
                                                    "& .Mui-checked,": {
                                                        color: "#6A5DFB !important",
                                                    }
                                                }}
                                            />
                                            SMALL
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <Checkbox
                                                sx={{
                                                    border: "#6C63FF",
                                                    color: "#6C63FF",
                                                    "& .Mui-checked,": {
                                                        color: "#6A5DFB !important",
                                                    }
                                                }}
                                            />
                                            MEDIUM
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <Checkbox
                                                sx={{
                                                    border: "#6C63FF",
                                                    color: "#6C63FF",
                                                    "& .Mui-checked,": {
                                                        color: "#6A5DFB !important",
                                                    }
                                                }}
                                            />
                                            LARGE
                                        </div>
                                    </div>
                                </div>
                                <h6>
                                    <b>SINGLE SEX OR CO-ED</b>
                                </h6>
                                <div className="row gx-3">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Checkbox
                                                sx={{
                                                    border: "#6C63FF",
                                                    color: "#6C63FF",
                                                    "& .Mui-checked,": {
                                                        color: "#6A5DFB !important",
                                                    }
                                                }}
                                            />
                                            CO-ED
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Checkbox
                                                sx={{
                                                    border: "#6C63FF",
                                                    color: "#6C63FF",
                                                    "& .Mui-checked,": {
                                                        color: "#6A5DFB !important",
                                                    }
                                                }}
                                            />
                                            ALL MEN
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <Checkbox
                                                sx={{
                                                    border: "#6C63FF",
                                                    color: "#6C63FF",
                                                    "& .Mui-checked,": {
                                                        color: "#6A5DFB !important",
                                                    }
                                                }}
                                            />
                                            ALL WOMEN
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                    </div>
                </div>
                {/* Tution */}
                <div >
                    <div className="mb-4">
                        <div
                            className="headings-style d-flex justify-content-between align-items-center"
                            style={{ color: "#000000", fontWeight: "bold" }}
                            onClick={() => setdropdownDivTution(!dropdownDivTution)}
                        >
                            TUTION
                            <div
                                className=" d-flex  align-items-center justify-content-center"
                            >
                                {/* <div style={{ fontSize: 12, fontWeight: "400" }}>
                                        {" "}
                                        Max Price
                                    </div> */}
                                <div style={{ fontSize: 15, fontWeight: "800" , color:"#6A5DFB"}}>
                                    $ {maxPriceFilterVal ? maxPriceFilterVal : 9999}
                                </div>
                            </div>
                            {/* <FaCaretDown style={{ fontSize: 15 }} /> */}
                        </div>

                        {true ? (
                            <div className="col-md-12 m-0 p-0 animated fadeInDown mt-2">
                                <div className="d-flex align-items-center justify-content-center">
                                    <Box width={200}>
                                        <Slider
                                            defaultValue={maxPriceFilterVal}
                                            valueLabelDisplay="auto"
                                            min={0}
                                            max={100000}
                                            disabled={!maxPriceChecked}
                                            onChange={maxpricevaluechange}
                                            sx={{
                                                "& .MuiSlider-root,": {
                                                    color: "#6A5DFB !important"
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Checkbox
                                        sx={{
                                            border: "#6C63FF",
                                            color: "#6C63FF",
                                            "& .Mui-checked,": {
                                                color: "#6A5DFB !important",
                                            }
                                        }}
                                        checked={maxPriceChecked}
                                        onChange={maxPricecheckboxvaluechange}
                                    />
                                </div>

                            </div>
                        ) : null}
                    </div>
                </div>
                {/* Test Scores */}
                <div>
                    <div style={{ fontWeight: "bold" }}>Test Scores</div>
                    <div
                        className="headings-style collegefiltersheading"
                        onClick={() =>
                            setdropdownDivTestscores(!dropdownDivTestscores)
                        }
                    >
                        {"Select"}
                        <FaCaretDown style={{ fontSize: 15 }} />
                    </div>
                    {dropdownDivTestscores ? (
                        <div className="col-md-12 m-0 p-0 animated fadeInDown mt-2 collegefilterdropdown">
                            ACT SCORE{" "}
                            {maxActScoreFilterVal ? maxActScoreFilterVal : 20}
                            <div className="d-flex align-items-center justify-content-center">
                                <Box width={200}>
                                    <Slider
                                        defaultValue={
                                            maxActScoreFilterVal
                                                ? maxActScoreFilterVal
                                                : 20
                                        }
                                        valueLabelDisplay="auto"
                                        min={0}
                                        max={50}
                                        disabled={!actScoreChecked}
                                        onChange={maxactscorevaluechange}
                                        sx={{
                                            "& .MuiSlider-root,": {
                                                color: "#6A5DFB !important"
                                            }
                                        }}
                                    />
                                </Box>
                                <Checkbox
                                    sx={{
                                        border: "#6C63FF",
                                        color: "#6C63FF",
                                        "& .Mui-checked,": {
                                            color: "#6A5DFB !important",
                                        }
                                    }}
                                    checked={actScoreChecked}
                                    onChange={actcheckboxvaluechange}
                                />
                            </div>
                            SAT SCORE{" "}
                            {maxSatScoreFilterVal ? maxSatScoreFilterVal : 1000}
                            <div className="d-flex align-items-center justify-content-center">
                                <Box width={200}>
                                    <Slider
                                        defaultValue={
                                            maxSatScoreFilterVal
                                                ? maxSatScoreFilterVal
                                                : 1000
                                        }
                                        valueLabelDisplay="auto"
                                        min={0}
                                        max={2000}
                                        disabled={!satScoreChecked}
                                        onChange={maxsatscorevaluechange}
                                        sx={{
                                            "& .MuiSlider-root,": {
                                                color: "#6A5DFB !important"
                                            }
                                        }}
                                    />
                                </Box>
                                <Checkbox
                                    sx={{
                                        border: "#6C63FF",
                                        color: "#6C63FF",
                                        "& .Mui-checked,": {
                                            color: "#6A5DFB !important",
                                        }
                                    }}
                                    checked={satScoreChecked}
                                    onChange={satcheckboxvaluechange}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>

            </div>

            {/* <div className="m-2">
                <button className="essayBtn2" onClick={() => onFilterBtnClick()}>Add</button>
            </div> */}

        </div>
    )
}

export default CollageFilters;