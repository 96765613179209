import React, { forwardRef, useImperativeHandle } from 'react'
import {
  FaMailBulk,
  FaPhone,
  FaAddressCard,
  FaLinkedinIn
} from "react-icons/fa";
import ProfilePicSample from "../../imageAssets/profilepicsample.png";
import { useDispatch, useSelector } from "react-redux";
import {
  professionaltitle,
  phonenumber,
  profiledescription,
  emailadd,
  portfolioaddress,
  linkedinusername,
  EducationDetials,
  languages,
  skills,
  experienceDetails,
  basicDetailsLabel,
  educationLable,
  experienceLabel,
  languageLabel,
  skillsLabel,
} from "../../stateManager/reducers/portfolioDetailsReducer";

const Template2 = forwardRef((props, ref) => {
  const professionaltitlefromredux = useSelector(professionaltitle);
  const phfromredux = useSelector(phonenumber);
  const profiledescfromredux = useSelector(profiledescription);
  const emailaddfromredux = useSelector(emailadd);
  const portfolioaddfromredux = useSelector(portfolioaddress);
  const linkedinunfromredux = useSelector(linkedinusername);
  const languageDetailsfromredux = useSelector(languages);
  const experienceDetailsfromredux = useSelector(experienceDetails);
  const educationDetailsfromredux = useSelector(EducationDetials);
  const skillsDetailsfromredux = useSelector(skills);
  const basicDetailsLabelfromredux = useSelector(basicDetailsLabel);
  const EduDetailsLabelfromredux = useSelector(educationLable);
  const ExpDetailsLabelfromredux = useSelector(experienceLabel);
  const LangDetailsLabelfromredux = useSelector(languageLabel);
  const skillsDetailsLabelfromredux = useSelector(skillsLabel);
  //   useImperativeHandle(ref, () => ({
  //     handleSaveToPDF(event) {
  //         event.preventDefault();
  //         window.print();
  //     },
  //   }))

  const handleSaveToPDF = (event) => {
    event.preventDefault();
    window.print();
  };
  var languagess = languageDetailsfromredux.split(',');
  var skillss = skillsDetailsfromredux.split(',');
  var langList;
  var skillList;
  return (

    <div className='col-md-12 d-flex' style={{ width: "210mm", height: "297mm", backgroundColor: "#E8E8E8" }}>
      <div className='col-md-6 p-0' style={{ backgroundColor: "#E8E8E8" }} >
        <div className="" style={{ textTransform: "uppercase", textAlign: "left", fontSize: 20 }}>
          <b>{JSON.parse(localStorage.getItem("userData")).username}</b></div>
        <div className="" style={{ textTransform: "uppercase", textAlign: "left", fontSize: 17 }}>{professionaltitlefromredux}</div>
        <div className='mt-4 '>
          <div className='d-flex' style={{ alignItems: "center" }}>
            <FaPhone style={{ fontSize: 12, cursor: "pointer", color: "#ffdf00" }} />
            <div className='pl-2' style={{ fontSize: 12, }}>{phfromredux}</div>
          </div>
          <div className='d-flex' style={{ alignItems: "center" }}>
            <FaMailBulk style={{ fontSize: 12, cursor: "pointer", color: "#ffdf00" }} />
            <div className='pl-2' style={{ fontSize: 12, }}>{emailaddfromredux}</div>
          </div>
          <div className='d-flex' style={{ alignItems: "center" }}>
            <FaAddressCard style={{ fontSize: 12, cursor: "pointer", color: "#ffdf00" }} />
            <div className='pl-2' style={{ fontSize: 12, }}>{portfolioaddfromredux}</div>
          </div>
        </div>
        <div className="mt-4 " style={{ color: "#ffdf00", fontSize: 17 }}>{basicDetailsLabelfromredux}</div>
        <div className='' style={{ fontSize: 12 }}>{profiledescfromredux}
        </div>
        <div className="mt-4" style={{ color: "#ffdf00", fontSize: 17 }}>{ExpDetailsLabelfromredux}</div>

        <div style={{ fontSize: 12 }}>
          {experienceDetailsfromredux.map(expDetails => {
            return (
              <div className=''>
                <b className='mt-5'>{expDetails.experienceProjectName} -{expDetails.experienceStartDate} {expDetails.experienceEndDate}</b><br></br>
                <div><b><i>{expDetails.experiencePosition}</i></b>
                </div>
                <div style={{ fontSize: 12, }} >
                  {expDetails.experienceDescription}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='col-md-6' style={{ backgroundColor: "white", width: "100%" }} >
        <div>
          <img
            style={{ display: "block", border: "solid", color: "#ffdf00", borderRadius: "50%" }}
            height={160}
            src={ProfilePicSample}
          />
        </div>
        <div className=''>
          <div className="mt-4" style={{ color: "#ffdf00", fontSize: 17 }}><b>{EduDetailsLabelfromredux}</b></div>
          {educationDetailsfromredux.map(eduDetails => {
            return (
              <div style={{ fontSize: 12 }} className="mt-3">
                {eduDetails.educationYear}<br></br>
                <b>{eduDetails.educationDetailsCourse}</b><br></br>
                {eduDetails.educationDetailsUniversity}
              </div>
            )
          })}
          <div className="mt-4" style={{ color: "#ffdf00", fontSize: !7 }}><b>{LangDetailsLabelfromredux}</b></div>
          <div style={{ fontSize: 12, }}>
            <ul>
              {langList = languagess.map((lang) =>
                <li>{lang}</li>
              )}
            </ul>
          </div>
          <div className="mt-4 " style={{ color: "#ffdf00", fontSize: 17 }}><b>{skillsDetailsLabelfromredux}</b></div>
          <div style={{ fontSize: 12, }}>
            <ul>
              {skillList = skillss.map((skill) =>
                <li>{skill}</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
});
export default Template2;