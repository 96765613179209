import React, { useEffect, useState } from "react";
import LoadingAnimation from "../utils/loadingAnimation";
import Logo from "../imageAssets/crownicon.png";
import { Button, Input } from "reactstrap";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import axios from "axios";
import { MW_URL } from "../config";
// import EditorJS from '@editorjs/editorjs';
// import Editor from "../components/editorconfig";
// import List from '@editorjs/list';
// import ImageTool from '@editorjs/image';
// const Header = require('@editorjs/header');
export default function LorScreen() {
  // var holderId = "editorjs";
  const [base64value, setBase64file] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showRecommendationPage, setshowRecommendationPage] = useState(false);
  const [showThanksForSubmission, setshowThanksForSubmission] = useState(false);
  const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
  const [height, SetHeight] = useState("");
  const [recommendationId, setrecommendationId] = useState("");
  const [editorData, setEditorData] = useState("");
  const [lorContent, setlorContent] = useState("");

  useEffect(() => {
    var windowHeight = window.innerHeight;
    SetHeight(windowHeight + "px");
    getPdffile();
  }, []);

  useEffect(() => {
    
  }, [])

  const editorinitializw = () => {
    // const editor = new EditorJS({
    //   // holder: 'editorjs',
    //   holderId: holderId,
    //   placeholder: 'Write Letter of Recomendation',
    //   tools: {
    //     header: {
    //       class: Header,
    //       inlineToolbar: ['link']
    //     },
    //     list: {
    //       class: List,
    //       inlineToolbar: ['link', 'bold']
    //     },
    //   }
    // });
    // setEditorData(editor)
  }
  const getPdffile = () => {

    setIsLoading(true);
    axios
      .post(`${MW_URL}` + "getDocById", { documentId: "l50r9ngdxnwqvx7wlr" })
      .then((getDocumentDetailResponse) => {
        var status = "NA";
        var documentFile = [];
        status = getDocumentDetailResponse.data.STATUS;
        if (status.toUpperCase() === "SUCCESS") {
          if (getDocumentDetailResponse && getDocumentDetailResponse.data) {
            getDocumentDetailResponse.data.data.map((file) => {
              {
                documentFile.push(file.file);
              }
            });
          }
          // setBase64file(documentFile);

          let params = new URLSearchParams(window.location.search);

          if (params.get("recomendationId")) {
            setrecommendationId(params.get("recomendationId"));
            axios
              .post(`${MW_URL}` + "checkRecommendation", {
                recommendationId: params.get("recomendationId"),
              })
              .then((checkRecommendationResponse) => {
                console.log(
                  "data checkRecommendationResponse",
                  checkRecommendationResponse.data.resume
                );
                if (checkRecommendationResponse.data.resume) {
                  setBase64file(checkRecommendationResponse.data.resume);
                }
                var status = "NA";
                status = checkRecommendationResponse.data.STATUS;
                console.log("status ===>", status);
                if (status.toUpperCase() === "SUCCESS") {
                  setshowRecommendationPage(true);
                  editorinitializw();
                  setIsLoading(false);
                  if (checkRecommendationResponse.data.lorstatus == "submitted")
                    setshowThanksForSubmission(true);
                } else if (status.toUpperCase() === "NOFILE") {
                  setshowRecommendationPage(false);
                  setIsLoading(false);
                } else {
                }
              })
              .catch((err) => {
                console.log("err >> ", err);
              });
          }
        }
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };
  const base64 = base64value;
  var url = "";
  if (base64) {
    const pdfContentType = "application/pdf";
    const base64toBlob = (data) => {
      const base64WithoutPrefix = data.substr(
        `data:${pdfContentType};base64,`.length
      );
      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      let out = new Uint8Array(length);
      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }
      return new Blob([out], { type: pdfContentType });
    };

    url = URL.createObjectURL(base64toBlob(base64));
  }
  function submitRecommendation() {
    editorData.save().then((outputData) => {
      console.log('Article data: ', outputData)

      // if (lorContent == "") return;
      // console.log(lorContent);
      setIsLoading(true);
      axios
        .post(`${MW_URL}` + "addRecommendation", {
          recommendationId: recommendationId,
          description: outputData,
        })
        .then((addRecommendationResponse) => {
          console.log("data", addRecommendationResponse);
          setlorContent("");
          setshowThanksForSubmission(true);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    }).catch((error) => {
      console.log('Saving failed: ', error)
    });
  }
  const handleInputChange = (e) => {
    setlorContent(e.target.value);
  };

  return (
    <div>
      {isLoading ? <LoadingAnimation isVisible={true} /> : null}
      {showRecommendationPage ? (
        showThanksForSubmission ? (
          <div>Thanks for submiting lor</div>
        ) : (
          <div id="letterOfRecommendationPageContainer">
            <div
              className="col-md-12 d-flex  lor-head align-items-center"
              style={{
                width: "100%",
                height: "2cm",
                backgroundColor: "#6C63FF",
                textAlign: "center",
                color: "white",
              }}
            >
              <div className="col-md-4 py-5 d-flex justify-content-left align-items-center">
                <div className="">
                  <img src={Logo} alt="logo" height="30" color="#6C63FF" />
                </div>
                <div className="text-center pl-2" style={{ fontWeight: 600 }}>
                  PROSPECT
                </div>
              </div>
              <h3 className="col-md-4">
                Letter of Recommendation
              </h3>
            </div>

            <div className="d-flex">
              <div className="col-md-6">
                <div
                  className="col-md-12 align-items-center"
                  style={{ marginTop: "1cm" }}
                >

                  {/* <div id={holderId}></div> */}
                  {/* <Editor 
                  
                 
                 ></Editor> */}


                  {/* <textarea
                    placeholder=" Type a letter of recommendation"
                    value={lorContent}
                    onChange={handleInputChange}
                    style={{
                      width: "600px",
                      height: "450px",
                      resize: "none",
                      border: "box",
                      border: "3px solid #ccc",
                      transition: "0.5s",
                    }}
                  ></textarea> */}
                  <div className="col-md-12 align-items-end">
                    <Button
                      className="sign-up-btn px-5 "
                      style={{ marginLeft: "6cm", backgroundColor: "#6C63FF" }}
                      onClick={submitRecommendation}
                    >
                      Upload
                    </Button>
                  </div>
                </div>
              </div>

              <div className="col-md-5">
                <div
                  style={{
                    height: "750px",
                    marginTop: "1cm",
                  }}
                  className="pdf-container"
                >
                  {url && (
                    <>
                      {" "}
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                        <Viewer fileUrl={url}></Viewer>
                      </Worker>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div id="alreadyPageContainer">
          <div
            className="col-md-12 lor-head align-items-center"
            style={{
              width: "100%",
              height: "2cm",
              backgroundColor: "#6C63FF",
              textAlign: "center",
              color: "white",
            }}
          >
            <h3>
              <br></br>Letter of Recommendation
            </h3>
          </div>

          <div className="row">
            <div className="col-md-2">
              <div className="navbar-brand col-md-12 py-5 d-flex justify-content-left align-items-center">
                <div className="">
                  <img src={Logo} alt="logo" height="30" color="#6C63FF" />
                </div>
                <div className="text-center pl-2" style={{ fontWeight: 600 }}>
                  PROSPECT
                </div>
              </div>
            </div>
            <div className="col-md-5">Already Submitted</div>
          </div>
        </div>
      )}
    </div>
  );
}
