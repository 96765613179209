import React, {useState, useEffect} from 'react'
import axios from "axios";
import { MW_URL } from "../../../../config";
import moment from "moment";
import LoadingAnimation from "../../../../utils/loadingAnimation";

const Queries = ({selectedStudent}) => {

    const [queriesList, setqueriesList] = useState([]);
    const [updatesList, setupdatesList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    console.log(selectedStudent, "stud")


    useEffect(() => {
        getUpdateDetails();
    }, []);

    const getUpdateDetails = () => {
        axios
          .post(`${MW_URL}` + "getUpdates", {
            recipientId: JSON.parse(localStorage.getItem("userData")).email,
            senderId: false,
          })
          .then((getUpdatesResponse) => {
            // console.log("getUpdatesResponse", getUpdatesResponse.data);
            setupdatesList(getUpdatesResponse.data);
    
            axios
              .post(`${MW_URL}` + "getQueriesByRecipientId", {
                recipientId: JSON.parse(localStorage.getItem("userData")).email,
                senderId: false,
              })
              .then((getQueriesByRecipientIdResponse) => {
                setqueriesList(getQueriesByRecipientIdResponse.data);
                console.log("queries", getQueriesByRecipientIdResponse.data)
                setIsLoading(false);
              })
              .catch((err) => {
                console.log("err >> ", err);
              });
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      };

  return (
    <div className='m-2 counsStudentBottonWrap'>
        {queriesList?.map((obj, index) => {
          if (obj?.senderId === selectedStudent?.email){
            return(
              <div key={index} className='essaySec p-2 m-2'>
                  <div className='d-flex justify-content-between'>
                      <p className='essayHeadText3'>{obj?.senderName}</p>
                      <p className='queryLightText'>{moment(obj?.updatedDate).format('DD/MM/yyyy')}</p>
                  </div>
                  <p className='essayNormalText2 m-0'>{obj?.message}</p>
              </div>
            )}
        })}
{/*        
        <div className='essaySec p-2 m-2'>
            <div className='d-flex justify-content-between'>
                <p className='essayHeadText3'>Title Here</p>
                <p className='queryLightText'>03 Jan 2022</p>
            </div>
            <p className='essayNormalText2 m-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s</p>
        </div> */}
    </div>
  )
}

export default Queries