import { useState, useEffect } from 'react';
import axios from "axios";
import { MW_URL } from "../../../../config";
import moment from "moment";
import ModalComponent from "../../../../utils/modalComponent";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import QuillEditor from '../../../students/quillEditor';
import {GoPencil} from 'react-icons/go';
import {GrNext} from 'react-icons/gr'
import {GrPrevious} from 'react-icons/gr'
import {AiTwotoneDelete} from 'react-icons/ai'
import LoadingAnimation from "../../../../utils/loadingAnimation";
import {MdPending} from "react-icons/md"
import {FaTrashAlt} from 'react-icons/fa'
import AlertModal from '../../../widgets/AlertModal';
import {BsCheckCircle} from 'react-icons/bs'
import {IoMdCloseCircleOutline} from 'react-icons/io'






const Essays = ({selectedStudent, selectedCollageId}) => {

    const [lgShow, setLgShow] = useState(false);
    const [feedShow, setFeedShow] = useState(false);
    const [openModal, setOpenModel] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedEssay, setSelectedEssay] = useState({})
    const [essayCollId, setessayCollId] = useState("");
    const [tab, setTab] = useState("1")
    const [showFeedDelete, setShowFeedDelete] = useState(false)
    const [feedIndex, setFeedIndex] = useState(0)
    const [currentDateTime, setCurrentDateTime] = useState(new Date());


    const [isLoading, setIsLoading] = useState(true);
    const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState(
        []
      );
    const [essaysTemplateList, setessaysTemplateList] = useState([]);

    const [updatesList, setupdatesList] = useState([]);
    const [essaysList, setessaysList] = useState([]);
    const [eventEditModalVisible, seteventEditModalVisible] = useState(false);
    const [feedbacksList2, setfeedbacksList2] = useState([])
    const [feedbacksList, setfeedbacksList] = useState({
        feedbacks: [],
        currentPage: 1,
        feedbackPerPage: 4,
      });
    const [feedbackInTextarea, setfeedbackInTextarea] = useState("");
    const [essayId, setessayId] = useState("");
    const [editorData, setEditorData] = useState("");
    const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);


    useEffect(() => {
        getStudentEssays(selectedStudent);
      }, [selectedStudent, isUseEffectCallRequired]);

    useEffect(() => {
        const interval = setInterval(() => {
          setCurrentDateTime(new Date());
        }, 1000); // Update every second
    
        // Cleanup the interval when the component unmounts
        return () => clearInterval(interval);
      }, []);
  
    const formattedDateTime = currentDateTime.toLocaleString();
    const filteredEssays = essaysList?.filter((obj) => obj.essayId === selectedEssay?.essayId);

    const getStudentEssays = (student) => {
        console.log("==============getStudentEssays fun  ========", student);
        setIsLoading(true);
        setIsUseEffectCallRequired(false)
        var selectedCollages = [];
        var selectedCollagesIds = [];
        axios
          .post(`${MW_URL}` + "getSelectedListOfCollages", {
            email: student?.email,
          })
          .then((getSelectedListOfCollagesresponse) => {
            if (
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
                .length > 0
            ) {
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
                (collage) => {
                  selectedCollages.push(collage);
                  selectedCollagesIds.push(collage.collageId);
                }
              );
              setlistOfSelectedCollagesArr(selectedCollages);
              var essayReqIds = [];
              var templateArr = [];
              var essaysListToPush = [];
              var essaysRequirementIds = [];
              axios
                .post(`${MW_URL}` + "get_essay_requirement", selectedCollages)
                .then((getEssaysTemplateResponse) => {
                  var status = "NA";
                  if (
                    getEssaysTemplateResponse &&
                    getEssaysTemplateResponse.data &&
                    getEssaysTemplateResponse.data.STATUS
                  )
                    status = getEssaysTemplateResponse.data.STATUS;
                  if (status.toUpperCase() === "SUCCESS") {
                    setessaysTemplateList(getEssaysTemplateResponse.data.data);
                    getEssaysTemplateResponse.data.data.map((templateRes) => {
                      if (templateRes.essayRequirements.length != 0) {
                        templateRes.essayRequirements.map((esaaReq) => {
                            console.log(templateRes, "temp -collage- de")
                          essayReqIds.push(esaaReq.essayReqId);
                          templateArr.push({
                            template: esaaReq.question,
                            collageId: templateRes.collageId,
                            name: templateRes.name,
                            essayReqId: esaaReq.essayReqId,
                            length: esaaReq.length,
                            requirement: esaaReq.requirement,
                            answered: essaysRequirementIds.includes(
                              esaaReq.essayReqId
                            ),
                          });
                        });
                      }
                    });
                    axios
                      .post(`${MW_URL}` + "getEssays", { ownerId: student?.email })
                      .then((getAllEssaysResponse) => {
                        var essaysListToPush = [];
                        var essaysRequirementIds = [];
                        if (getAllEssaysResponse.data.length > 0) {
                          getAllEssaysResponse.data.map((essay) => {
                            // console.log(essay, "essay by stud")
                            if (
                              essay.ownerId == student?.email &&
                              selectedCollagesIds.includes(essay.collageId) &&
                              essayReqIds.includes(essay.essayReqId)
                            ) {
                              essaysListToPush.push({
                                essayId: essay.essayId,
                                content: essay.content,
                                newEditorContentEssay:essay.newEditorcontentEssay,
                                typeOfDocument: essay.typeOfDocument,
                                collageId: essay.collageId,
                                ownerId: essay.ownerId,
                                feedbacks: essay.feedbacks,
                                essayReqId: essay.essayReqId,
                                isApproved: essay.isApproved,
                                updatedDate: moment(essay.updatedDate).format("DD-MM-YYYY"),
                              });
                            }
                            essaysRequirementIds.push(essay.essayReqId);
                          });
                          const params = new URLSearchParams(
                            window.location.search
                          );
                          if (params.get("id")) {
                            const selectedEssay = essaysListToPush.find(
                              (essay) => essay.essayId == params.get("id")
                            );
                            // viewEssay(selectedEssay);
                          } else {
                            console.log("No params");
                          }
                          axios
                            .post(`${MW_URL}` + "getUpdates", {
                              recipientId: JSON.parse(
                                localStorage.getItem("userData")
                              ).email,
                              senderId: student?.email,
                            })
                            .then((getUpdatesResponse) => {
                              setupdatesList(getUpdatesResponse.data);
                              setessaysTemplateList(
                                templateArr.map((reqEssay) => {
                                  return {
                                    ...reqEssay,
                                    answered: essaysRequirementIds.includes(
                                      reqEssay.essayReqId
                                    ),
                                  };
                                })
                              );
                              setIsLoading(false);
                            })
                            .catch((err) => {
                              console.log("err >> ", err);
                            });
                        } else {
                          setessaysList([]);
                          setIsLoading(false);
                        }
                        setessaysList(essaysListToPush);
                        console.log(essaysListToPush, "essay list")
                      })
                      .catch((err) => {
                        console.log("err >> ", err);
                      });
                  } else {
                    setIsLoading(false);
                  }
                })
                .catch((err) => {
                  console.log("err >> ", err);
                });
            } else {
              setlistOfSelectedCollagesArr([]);
              setessaysTemplateList([]);
              setessaysList([]);
              setupdatesList([]);
              setIsLoading(false);
            }
          })
          .catch((err) => { });
      };

    const approveEssay = (essay) => {
        var dataToSend = {
          essayId: essay.essayId,
          isApproved: true,
        };
        // setIsLoading(roundToNearestMinutes);
        setIsLoading(true);
        axios
          .post(`${MW_URL}` + "approveEssay", dataToSend)
          .then((response) => {
            setIsLoading(false);
            var status = "NA";
            if (response && response.data && response.data.STATUS)
              status = response.data.STATUS;
            // setIsUseEffectCallRequired(true);
            // window.location.reload(false);
            setessaysList(
              essaysList.map((essays) => {
                if (essay.essayId == essays.essayId) {
                  if (essays.isApproved) {
                    return {
                      ...essays,
                      isApproved: false,
                    };
                  } else {
                    return {
                      ...essays,
                      isApproved: true,
                    };
                  }
                } else {
                  return {
                    ...essays,
                    isApproved: essays.isApproved,
                  };
                }
              })
            );
          })
          .catch((err) => {
            console.log("err >> ", err);
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      };

    const createUniquieId = () => {
        const head = Date.now().toString(36);
        const tail = Math.random().toString(36).substr(2);
        return head + tail;
    };
    
    const feedBackBtnClick = (obj) => {
        setFeedShow(true)
        console.log(obj, "sel-obj-feeds")
        setSelectedEssay(obj)
        setfeedbacksList2(obj?.feedbacks)
    }

    const feedBackDeleteBtnClick = () => {
        console.log(selectedEssay?.feedbacks, feedIndex , "feed delete clicked")
        selectedEssay?.feedbacks.splice(feedIndex, 1)
        var dataToSend = {
            essayId: selectedEssay?.essayId,
            feedbacks: feedbacksList2,
            updateId: createUniquieId(),
            recipientId: selectedStudent?.email,
            senderId: JSON.parse(localStorage.getItem("userData")).email,
            message:
            JSON.parse(localStorage.getItem("userData")).username +
            " deleted a feedback",
        };
        console.log(dataToSend, "data")
        setIsLoading(true);
        axios
            .post(`${MW_URL}` + "updatefeedbacks", dataToSend)
            .then((response) => {
            var status = "NA";
            if (response && response.data && response.data.STATUS)
                status = response.data.STATUS;
            if (status.toUpperCase() === "SUCCESS") {
                setIsLoading(false);
                setShowFeedDelete(false)
                // setfeedbacksList({
                //   ...feedbacksList,
                //   feedbacks: feedbacks,
                // });
                console.log("feed updated success")
            } else {
            }
            })
            .catch((err) => {
            console.log("err >> ", err);
            });
    }

    const handleFeedBackDelete = (i) => {
      setFeedIndex(i)
      setShowFeedDelete(true)
    }

    const addFeedbackBtnClick = () => {
      if(feedbackInTextarea) {
        selectedEssay?.feedbacks.push({
              feedback: feedbackInTextarea,
              usertype: JSON.parse(localStorage.getItem("userData")).userType,
              userName: JSON.parse(localStorage.getItem("userData")).username,
              updatedDate: formattedDateTime 
          })
          var dataToSend = {
              essayId: selectedEssay?.essayId,
              feedbacks: selectedEssay?.feedbacks,
              updateId: createUniquieId(),
              recipientId: selectedStudent?.email,
              senderId: JSON.parse(localStorage.getItem("userData")).email,
              message:
                JSON.parse(localStorage.getItem("userData")).username +
                " added a feedback",
          };
          console.log(dataToSend, "data2snd")
          setIsLoading(true);
          axios
              .post(`${MW_URL}` + "updatefeedbacks", dataToSend)
              .then((response) => {
              setIsLoading(false);
              var status = "NA";
              if (response && response.data && response.data.STATUS)
                  status = response.data.STATUS;
              if (status.toUpperCase() === "SUCCESS") {
                  setIsLoading(false);
                  setfeedbackInTextarea("");
                  seteventEditModalVisible(false);
                  setfeedbacksList({
                  ...feedbacksList,
                  feedbacks: [
                      ...feedbacksList.feedbacks,
                      {
                      feedback: feedbackInTextarea,
                      usertype: JSON.parse(localStorage.getItem("userData"))
                          .userType,
                      },
                  ],
                  currentPage: Math.ceil(
                      (feedbacksList.feedbacks.length + 1) /
                      feedbacksList.feedbackPerPage
                  ),
                  });
              } else {
              }
              })
              .catch((err) => {
              console.log("err >> ", err);
              });
      } else {
        console.log("no feedbackInTextarea")
      }
    }

    const handleClickEssay = (obj) => {
        setLgShow(true)
        setSelectedEssay(obj)
        setessayCollId(obj.collageId)
        console.log(obj, "clicked essay")
    }

    const handleEssayEditClick = (obj) => {
        setOpenModel(true)
        setSelectedEssay(obj)
        console.log(obj, "dddgg")
    }

    const editEssayInDb = () => {
      let essayCollege = listOfSelectedCollagesArr.find((col) => { return col.collageId == selectedEssay.collageId })
      console.log(essayCollege, selectedEssay.collageId, "1212")

      var dataToSend = {
        essayId: selectedEssay?.essayId,
        content: editorData,
        newEditorContent: editorData,
        typeOfDocument: "essay",
        collageId: selectedEssay.collageId,
        ownerId: selectedStudent?.email,
        feedbacks: feedbacksList.feedbacks,
        essayReqId: selectedEssay.essayReqId,
        updateId: createUniquieId(),
        recipientId: selectedStudent?.email,
        senderId: JSON.parse(localStorage.getItem("userData")).email,
        message:
          JSON.parse(localStorage.getItem("userData")).username +
          " edited " + essayCollege.name + "'s essay",
      };
      setIsLoading(true);
      // console.log("1215", dataToSend)

      axios
        .post(`${MW_URL}` + "editEssay", dataToSend)
        .then((response) => {
          var status = "NA";
          if (response && response.data && response.data.STATUS)
            status = response.data.STATUS;
          if (status.toUpperCase() === "SUCCESS") {
            // hidefeedbackPage();
            // resetEssayContent();
            setIsUseEffectCallRequired(true)
            setOpenModel(false)

          } else {
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });

      // editorData.save().then((outputData) => {
      //   outputData.blocks.map((es) => {
      //     essayCont.push(es.data.text);
      //   })
      //   console.log("clicked---12")
      //   var esContent = essayCont.toString();
      //   var dataToSend = {
      //     essayId: essayId,
      //     content: esContent,
      //     newEditorContent: outputData,
      //     typeOfDocument: "essay",
      //     collageId: essayCollId,
      //     ownerId: JSON.parse(localStorage.getItem("userData")).email,
      //     feedbacks: feedbacksList.feedbacks,
      //     essayReqId: essayReqId,
      //     updateId: createUniquieId(),
      //     recipientId: JSON.parse(localStorage.getItem("userData")).counsellorId,
      //     senderId: JSON.parse(localStorage.getItem("userData")).email,
      //     message:
      //       JSON.parse(localStorage.getItem("userData")).username +
      //       " edited " + essayCollege.name + "'s essay",
      //   };
      //   setIsLoading(true);
      //   axios
      //     .post(`${MW_URL}` + "editEssay", dataToSend)
      //     .then((response) => {
      //       var status = "NA";
      //       if (response && response.data && response.data.STATUS)
      //         status = response.data.STATUS;
      //       if (status.toUpperCase() === "SUCCESS") {
      //         // hidefeedbackPage();
      //         // resetEssayContent();
      //         setIsLoading(false);
      //       } else {
      //       }
      //     })
      //     .catch((err) => {
      //       console.log("err >> ", err);
      //     });
      // }).catch((error) => {
      //   console.log('Saving failed: ', error)
      // });
    };




  return (
    <div className='m-1 counsStudentBottonWrap'>
        {isLoading ? <LoadingAnimation isVisible={true} /> : null}

        {essaysList?.map((obj, index) => {
          if(selectedCollageId === "all" || obj.collageId === selectedCollageId){
            return (
            <div key={index} className='essaySec px-2 m-2'>
                <div onClick={() => handleClickEssay(obj)}>
                    <div className='d-flex justify-content-between'>
                        <p className='essayHeadText3'>{selectedStudent?.username}</p>
                        <div className='queryLightText'>{obj?.updatedDate}</div>
                    </div>
                    <p className='essayNormalText2'>{obj?.content.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, "")}</p>
                </div>
                <div className='d-flex justify-content-between '>
                    <div className='d-flex'>
                      <div className='counStudBoldText4'>{obj?.feedbacks?.length}<span className='essayNormalText2 mx-1' >Feedbacks</span></div>
                      {obj?.isApproved ? (
                        <p onClick={() => approveEssay(obj)} className='approveBtn2 px-2 mx-2'>Approved <BsCheckCircle/> </p> 
                      ) : (
                          <p onClick={() => approveEssay(obj)} className='notApproveBtn2 px-2 mx-2'>Not Approved <IoMdCloseCircleOutline fontSize={18} /></p>
                      )}
                    </div>
                    {listOfSelectedCollagesArr?.map((collage) => (
                        collage.collageId === obj.collageId && (
                          <div className='d-flex'>
                            <p key={collage.collageId} className='counStudNormalText3 mr-2'>{collage?.name} -</p>
                            <p key={collage?.collageId} className='dashDeadlineText '>{collage?.deadline}</p>
                          </div>
                            )
                    ))}
    
                </div>
            </div>
            )
          }
        })}

       

        {/* <div className='essaySec px-2 m-2'>
            <div className='d-flex justify-content-between'>
                <p className='essayHeadText3'>Essay Title Here</p>
                <div className='queryLightText'>09 Feb 2023</div>
            </div>
            <p className='essayNormalText2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s</p>
            <div className='d-flex'>
                <p className='counStudNormalText3 mr-2'>The University of Iowa</p>
                <div className='counStudBoldText4 mx-3'>03<span className='essayNormalText2 mx-1' >Comments</span></div>
            </div>
        </div> */}

        <Modal
            size="xl"
            show={lgShow}
            onHide={() => setLgShow(false)}
        >
            <div className='d-flex justify-content-between '>
                <p className='essayHeadText4 m-3 p-2 '>Edit Essay </p>
                <p className='essayHeadText4 my-3 py-2' style={{"marginLeft":"30%"}}>Feedbacks </p>
                <div className='m-2'>
                    <AiOutlineCloseCircle onClick={() => setLgShow(false)} className='essayCloseBtn'/>
                </div>
              </div>
              <div className='row'>
                    <div className='col-7'>
                      <div className='essayModal'>
                        {/* <div className='essayModalContent'>
                          <p className='essayNormalText1'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s</p>
                        </div> */}
                        <div>

                          {filteredEssays[0]?.isApproved ? (
                            <div>
                              <div>
                                <QuillEditor readOnly={true} defaultContent={filteredEssays[0]?.content} customClass={'essayModalContent'} />
                              </div>
                              <div className='modalSaveBtn mr-3'>
                                <p onClick={() => approveEssay(filteredEssays[0])} className={filteredEssays[0]?.isApproved ? 'approveBtn2 p-1' : 'notApproveBtn2 p-1'}>Approved <BsCheckCircle/> </p> 
                              </div>
                           </div>
                          ) : (
                            <div>
                              <div >
                                <QuillEditor editorData={editorData} setEditorData={setEditorData} defaultContent={filteredEssays[0]?.content} customClass={'essayModalContent'} />
                              </div>
                              <div className='modalSaveBtn mr-3'>
                                <p onClick={() => approveEssay(filteredEssays[0])} className='notApproveBtn2 p-1 mr-3 '>Not Approved <IoMdCloseCircleOutline fontSize={18} /></p>
                                <div>
                                  <button onClick={() => editEssayInDb()} className='essayBtn2'>Save</button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                      </div>
                    </div>
                    <div className='col'>
                      {/* <p>feedback</p> */}
                      <div className='feedListWrap'>
                      {selectedEssay?.feedbacks?.map((obj, index) => (
                        <div className='feedbackWrap p-2 my-2'>
                          <div>
                              <p className='essayNormalText1 m-0'>{obj.feedback}</p>
                              <div className='d-flex'>
                                <p className='essayNormalText2 m-0'>{obj.userName} -</p>
                                <p className='queryLightText2 m-0 mx-2'>{obj.updatedDate}</p>
                              </div>
                          </div>
                          <div className='px-2'>
                            <FaTrashAlt onClick={() => handleFeedBackDelete(index)} fill='red' fontSize={18} />
                          </div>
                        </div>

                        // if(obj.usertype === "counsellor"){
                        //   return(
                        //     <div className='d-flex'>
                        //       <AiTwotoneDelete onClick={() => feedBackDeleteBtnClick(index)} className='m-1' />
                        //       <p className='text-danger essayNormalText1'>{obj.feedback}</p>
                        //     </div>)
                        // } else {
                        //   return(
                        //     <div className='d-flex'>
                        //       <AiTwotoneDelete onClick={() => feedBackDeleteBtnClick(index)} className='m-1' />
                        //       <p className='essayNormalText1'>{obj.feedback}</p>
                        //     </div>)
                        // }
                      ))}
                      </div>
                    
                      <div className='addFeedBackWrap'>
                          <textarea 
                            className='essayNormalText1 queryTextInbox'
                            value={feedbackInTextarea} 
                            onChange={(event) => {
                              setfeedbackInTextarea(event.target.value);
                            }}
                            placeholder='Type your feedback here'></textarea>

                            <button className='essayBtn1' onClick={() => addFeedbackBtnClick()}>submit</button>
                      </div>
                    
                    
                    </div>
              </div>
        </Modal>

        <AlertModal
          show={showFeedDelete}
          onHide={() => setShowFeedDelete(false)}
          title='Alert!'
          message='Are you sure you want to delete this feedback?'
          onCancel={() => setShowFeedDelete(false)}
          onConfirm={() => feedBackDeleteBtnClick()}
        />

        {/* <Modal
            size="lg"
            show={feedShow}
            onHide={() => setFeedShow(false)}
        >
            <div className='CouncEssayModal m-2 p-1'>
                <div className='d-flex justify-content-between'>
                    <p className='essayHeadText1'>Feedback</p>
                    <AiOutlineCloseCircle onClick={() => setFeedShow(false)} className='essayCloseBtn'/>
                </div>
                <div className='feedListWrap'>
                {feedbacksList2?.map((obj, index) => {
                    if(obj.usertype === "counsellor"){
                        return(
                          <div className='d-flex text-danger'>
                            <AiTwotoneDelete onClick={() => feedBackDeleteBtnClick(index)} className='m-1' />
                            <p className='text-danger essayNormalText1'>{obj.feedback}</p>
                          </div>)
                      } else {
                        return(
                          <div className='d-flex'>
                            <AiTwotoneDelete onClick={() => feedBackDeleteBtnClick(index)} className='m-1' />
                            <p className='essayNormalText1'>{obj.feedback}</p>
                          </div>)
                      }
                })}
                </div>

                    <div className='addFeedBackWrap'>
                          <textarea 
                            className='essayNormalText1 queryTextInbox'
                            value={feedbackInTextarea} 
                            onChange={(event) => {
                              setfeedbackInTextarea(event.target.value);
                            }}
                            placeholder='Type your feedback here'></textarea>

                            <button className='essayBtn1' onClick={() => addFeedbackBtnClick()}>submit</button>
                      </div>

            </div>
        </Modal> */}
        
        {/* <Modal  show={openModal} size='lg' onHide={()=> setOpenModel(false)} >
              <div className='d-flex justify-content-between '>
                <p className='essayHeadText4 m-3 p-2 '>Edit Essay </p>
                <div className='m-2'>
                    <AiOutlineCloseCircle onClick={() => setOpenModel(false)} className='essayCloseBtn'/>
                </div>
              </div>
              <div className='row'>
                    <div className='col'>
                      <div className='essayModal'>
                        <div className='essayModalContent'>
                          <p className='essayNormalText1'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s</p>
                        </div>

                          {selectedEssay?.isApproved ? (
                            <div>
                              <QuillEditor readOnly={true} defaultContent={selectedEssay.content} customClass={'essayModalContent'} />
                            </div>
                          ) : (
                            <div >
                              <QuillEditor editorData={editorData} setEditorData={setEditorData} defaultContent={selectedEssay.content} customClass={'essayModalContent'} />
                            </div>
                          )}

                        <div className='modalSaveBtn mr-3'>
                          <button onClick={() => editEssayInDb()} className='essayBtn2'>Save</button>
                        </div>

                      </div>
                    </div>
              </div>
        </Modal> */}


    </div>
  )
}

export default Essays;