import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setMainLoadingTrue, setMainLoadingFalse, setCurrentPage } from "../stateManager/reducers/generalReducer";
import { customerDetails, setCustomerDetails } from "../stateManager/reducers/userDetailsReducer";
import { useForm } from "../utils/useForm";
import ValidateForm from "../utils/formValidation";
import GreenCheck from "../imageAssets/Check_Green.png"
import { BiLogoFacebook } from 'react-icons/bi'
import { BiLogoGoogle } from 'react-icons/bi'
import { BiLogoApple } from 'react-icons/bi'
import { ToastContainer, toast } from "react-toastify";


const SignUpScreen2 = () => {

    const customerDetailsFromRedux = useSelector(customerDetails);
    if (Object.keys(customerDetailsFromRedux.basicDetails).length > 0) {
        var value = customerDetailsFromRedux.basicDetails.value
    }

    var [value, handleChange] = useForm({
        name: Object.keys(customerDetailsFromRedux.basicDetails).length > 0 ? customerDetailsFromRedux.basicDetails.value.name : "",
        email: Object.keys(customerDetailsFromRedux.basicDetails).length > 0 ? customerDetailsFromRedux.basicDetails.value.email : "",
        password: Object.keys(customerDetailsFromRedux.basicDetails).length > 0 ? customerDetailsFromRedux.basicDetails.value.password : "",
        gSuite: Object.keys(customerDetailsFromRedux.basicDetails).length > 0 ? customerDetailsFromRedux.basicDetails.value.gSuite : "",
        confirmPassword: Object.keys(customerDetailsFromRedux.basicDetails).length > 0 ? customerDetailsFromRedux.basicDetails.value.confirmPassword : ""
    })

    const [height, SetHeight] = useState("");
    const [showNext, setShowNext] = useState(false)
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(setMainLoadingTrue())
        var windowHeight = window.innerHeight;
        SetHeight(windowHeight + "px");
        dispatch(setMainLoadingFalse())
    }, [])

    function checkFields() {
        if (value.name !== "" && value.email !== "" && value.password !== "" && value.confirmPassword !== "") {
            setShowNext(true)
        } else {
            setShowNext(false)
        }
    }

    const submitSignupBtnClicked = (e) => {
        e.preventDefault()
        console.log("test-12")
        if (ValidateForm.inputFieldMandatoryCheck("SignUpPageContainer2", false)) {
            if (ValidateForm.inputFieldValueCompositionCheck("SignUpPageContainer2", false)) {
                if (value.password === value.confirmPassword) {
                    console.log("password not matching", value)
                    dispatch(setCustomerDetails({ "basicDetails": { value } }));
                    console.log("customerDetailsFromRedux >> ", customerDetailsFromRedux)
                    dispatch(setCurrentPage("tellUsMore"))
                } else {
                    toast.error("password not matching", {
                        autoClose: 2000,
                        pauseOnHover: true,
                    });
                    console.log("password not matching")
                }
            }
        }

    }

    const loginBtnClicked = () => {
        dispatch(setCurrentPage("landing"))
    }

    return (
        <div className='row' >
            <div className='col-5 leftBg'></div>
            <div className='col-7 px-0'>
                <div className='signupHead'>
                    <div className='row mx-2'>
                        <div className='col-8'>
                            <div className=''>
                                <h3 className='signupText'>Sign Up</h3>
                                {showNext ? (
                                    <div className='signupSubhead2' >
                                        <div className=''><img width={20} height={20} src={GreenCheck} /></div>
                                        <div className='midLine'></div>
                                        <div className='currentSec d-flex align-items-center'>2</div>
                                    </div>
                                ) : (
                                    <div className='signupSubhead2' >
                                        <div className='currentSec  d-flex align-items-center'>1</div>
                                        <div className='midLine'></div>
                                        <div className='nextSec'>2</div>
                                    </div>
                                )
                                }

                            </div>
                        </div>
                        <div className='col-4'><div className='bg-title1'></div></div>
                    </div>

                </div>
                <div className='formSection' id="SignUpPageContainer2" >
                    <form className='signupForms'>
                        <div className="form-group">
                            <label htmlFor='signupName'>Name<span className="text-danger">*</span></label>
                            <Input
                                type="text"
                                autoComplete="new-password"
                                name='name'
                                className="input input_data mandatoryField typeText"
                                value={value.name}
                                onChange={handleChange}
                                id="signupName"
                                placeholder="Enter your Name"
                                onInput={checkFields}
                            />
                            <div className="errorDesc"></div>
                        </div>
                        <div className="form-group">
                            <label htmlFor='signupEmail'>Email<span className="text-danger">*</span></label>
                            <Input
                                type="email"
                                autoComplete="new-password"
                                name='email'
                                className="input input_data mandatoryField specialCharactersAllowed typeEmail"
                                value={value.email}
                                id="signupEmail"
                                placeholder="Enter your Email"
                                onChange={handleChange}
                                onInput={checkFields}
                            />
                            <div className="errorDesc"></div>
                        </div>

                        <div className="form-group">
                            <label htmlFor='signupPassword'>Password<span className="text-danger">*</span></label>
                            <Input
                                type="password"
                                autoComplete="new-password"
                                name='password'
                                className="input input_data mandatoryField specialCharactersAllowed"
                                value={value.password}
                                id="signupPassword"
                                placeholder="Enter your Password"
                                onChange={handleChange}
                                onInput={checkFields}
                            />
                            <div className="errorDesc"></div>
                        </div>

                        <div className="form-group">
                            <label htmlFor='signupConfirmPassword'>Confirm Password<span className="text-danger">*</span></label>
                            <Input
                                type="password"
                                autoComplete="new-password"
                                name='confirmPassword'
                                className="input input_data mandatoryField specialCharactersAllowed"
                                value={value.confirmPassword}
                                id="signupConfirmPassword"
                                placeholder="Enter your Password"
                                onChange={handleChange}
                                onInput={checkFields}
                            />
                            <div className="errorDesc"></div>
                        </div>


                        <div>
                            <label htmlFor='signupGsuite'>G Suite</label>
                            <Input
                                type="text"
                                autoComplete="new-password"
                                name='gSuite'
                                className="input"
                                value={value.gSuite}
                                id="signupGsuite"
                                placeholder="Enter your G Suite"
                                onChange={handleChange}
                                onInput={checkFields}
                            />
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                            {showNext ? (<button onClick={(e) => submitSignupBtnClicked(e)} className='signupNextBtn'>
                                <p className='signupBtnText'>Next</p>
                            </button>)
                                : (<button disabled className='signupNextBtndis'>
                                    <p className='signupBtnTextdis'>Next</p>
                                </button>)
                            }
                        </div>
                    </form>
                </div>
                <div className='signupHorizontal'></div>

                <div className='otherSignup'>
                    <div>
                        <p className="m-0">Or you can also Sign Up with</p>
                        <div className='loginMethods'>
                            <div className='my-2 mx-4 loginMethodsBg'><BiLogoApple className='loginMethodFont' /></div>
                            <div className='my-2 mx-2 loginMethodsBg'><BiLogoGoogle className='loginMethodFont' /></div>
                            <div className='my-2 mx-4 loginMethodsBg'><BiLogoFacebook className='loginMethodFont' /></div>
                        </div>
                    </div>
                </div>
                <div className='signupBottomText'>
                    <p>Already Registered? <span onClick={() => loginBtnClicked()} className='linkText'> Login</span></p>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default SignUpScreen2