import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../imageAssets/crownicon.png";
import { DropdownButton, Dropdown } from "react-bootstrap";
import {
  FaHome,
  FaAddressCard,
  FaSearch,
  FaFile,
  FaUpload,
  FaCalendar,
  FaUsers,
  FaUniversity,
} from "react-icons/fa";
import {MdOutlineSpaceDashboard} from 'react-icons/md'
import {TbBuildingBank} from 'react-icons/tb'
import {CgFileDocument} from 'react-icons/cg'
import {AiOutlineCalendar, AiOutlineProfile} from 'react-icons/ai'
import {SlPeople} from 'react-icons/sl'
import {BsFileText} from 'react-icons/bs'
import {BsQuestionSquare} from 'react-icons/bs'
import {BsInbox} from 'react-icons/bs'
import {FiSettings} from 'react-icons/fi'
import {IoIosNotificationsOutline} from 'react-icons/io'
import {FiLogOut} from 'react-icons/fi'
import {PiStudentLight} from 'react-icons/pi'



import { useHistory } from "react-router-dom";
import AlertModal from "./widgets/AlertModal";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const [height, setHeight] = useState("");
  const [isAdmin, setAdmin] = useState(false);
  const [isCounsellor, setCounsellor] = useState(false);
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);

  const history = useHistory();

  const logoutBtnClicked = () => {
    localStorage.clear();
   window.location.replace("/");
  };

  useEffect(() => {
    var windowHeight = window.innerHeight;
    setHeight(windowHeight + "px");
    var retrievedUserData = JSON.parse(localStorage.getItem("userData"));
    if (retrievedUserData && retrievedUserData.userType === "admin"){
      setAdmin(true);
      history.push("/adminDashboard");
    }
    if (retrievedUserData && retrievedUserData.userType === "counsellor") {
      setCounsellor(true);
      history.push("/counsellorDashboard");
    }
  }, []);

  return (
    <div>
      <nav
        className="navbar is-primary navbar-outer-container px-0"
        role="navigation"
        aria-label="main navigation"
        style={{
          position: "fixed",
          zIndex: 2,
          width: "15%",
          minHeight: "600px",
        }}
      >           
        <div className="col-md-12  d-flex justify-content-between flex-column align-items-center p-0">
          <div className="col-md-12 px-0" style={{marginTop: "0%"}}>
            {/* <div className="navbar-brand col-md-12 py-5 d-flex justify-content-left align-items-center">
              <div className="">
                <img src={Logo} alt="logo" height="30" />
              </div>
              <div className="text-center pl-2" style={{ fontWeight: 600 }}>
                PROSPECT
              </div>
            </div> */}

            {/* new logo */}
            <div className="logoBg"></div>


            <div
              className={`navbar-menu col-md-12 p-0 ${isOpen && "is-active"}`}
            >
              <div className="navbar-start d-flex justify-content-around align-items-center flex-column">
                {isAdmin ? (
                  <div className="col-md-12 p-0">
                    <div className="col-md-12 p-0">
                      <NavLink
                        className="navbar-item col-md-12 py-3 mt-3 mb-1 d-flex justify-content-left align-items-center"
                        activeClassName="is-active"
                        exact
                        to="/adminDashboard"
                      >
                        <div className="col-md-2">
                          <FaHome style={{ fontSize: 20 }} />
                        </div>
                        <div className="col-md-10 d-flex align-items-left">
                          HOME
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-md-12 p-0">
                      <NavLink
                        className="navbar-item col-md-12 py-3 mb-1"
                        activeClassName="is-active"
                        exact
                        to="/adminUsers"
                      >
                        <div className="col-md-2">
                          <FaUsers style={{ fontSize: 20 }} />
                        </div>
                        <div className="col-md-10 d-flex align-items-left">
                          USERS
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-md-12 p-0">
                      <NavLink
                        className="navbar-item col-md-12 py-3 mb-1"
                        activeClassName="is-active"
                        exact
                        to="/adminCollege"
                      >
                        <div className="col-md-2">
                          <FaUniversity style={{ fontSize: 20 }} />
                        </div>
                        <div className="col-md-10 d-flex align-items-left">
                          COLLEGES
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-md-12 p-0">
                      <NavLink
                        className="navbar-item col-md-12 py-3 mb-1"
                        activeClassName="is-active"
                        exact
                        to="/adminDocuments"
                      >
                        <div className="col-md-2">
                          <FaFile style={{ fontSize: 20 }} />
                        </div>
                        <div className="col-md-10 d-flex align-items-left">
                          DOCUMENTS
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-md-12 p-0">
                      <NavLink
                        className="navbar-item col-md-12 py-3 mb-1"
                        activeClassName="is-active"
                        exact
                        to="/adminEssay"
                      >
                        <div className="col-md-2">
                          <FaUniversity style={{ fontSize: 20 }} />
                        </div>
                        <div className="col-md-10 d-flex align-items-left">
                          ESSAY
                        </div>
                      </NavLink>
                    </div>
                    <div
                      className="col-md-12 p-0"
                      // style={{ height: 100 }}
                    ></div>
                  </div>
                ) : (
                  <div className="col-md-12 p-0">
                    {isCounsellor ? (
                      <div className="col-md-12 p-0">
                        {/* <div className="col-md-12 p-0">
                          <NavLink
                            className="navbar-item col-md-12 py-3 mt-3 mb-1 d-flex justify-content-left align-items-center"
                            activeClassName="is-active"
                            exact
                            to="/counsellorDashboard"
                          >
                            <div className="col-md-2">
                              <FaHome style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              HOME 
                            </div>
                          </NavLink>
                        </div>
                        <div className="col-md-12 p-0">
                          <NavLink
                            className="navbar-item col-md-12 py-3 mb-1"
                            activeClassName="is-active"
                            exact
                            to="/counsellorStudents"
                          >
                            <div className="col-md-2">
                              <FaUsers style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              STUDENTS
                            </div>
                          </NavLink>
                        </div>
                        <div className="col-md-12 p-0">
                          <NavLink
                            className="navbar-item col-md-12 py-3 mb-1"
                            activeClassName="is-active"
                            exact
                            to="/counsellorAppointments"
                          >
                            <div className="col-md-2">
                              <FaCalendar style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              APPOINTMENTS
                            </div>
                          </NavLink>
                        </div> */}

                        {/* new */}
                        <div className="col-md-12 p-0">
                           <NavLink
                            className="navbar-item col-md-12 py-2 my-1 d-flex justify-content-left align-items-center"
                            activeClassName="is-active"
                            exact
                            to="/counsellorDashboard"
                          >
                            <div className="col-md-2">
                              <MdOutlineSpaceDashboard style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              Dashboard
                            </div>
                          </NavLink>
                        </div>
                        <div className="col-md-12 p-0">
                           <NavLink
                            className="navbar-item col-md-12 py-2 my-1 d-flex justify-content-left align-items-center"
                            activeClassName="is-active"
                            exact
                            to="/counsellorStudents"
                          >
                            <div className="col-md-2">
                              <PiStudentLight style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              Students
                            </div>
                          </NavLink>
                        </div>
                        <div className="col-md-12 p-0 ">
                          <NavLink
                            className="navbar-item col-md-12 py-2 my-1  mb-1"
                            activeClassName="is-active"
                            to="/counsellorAppointments"
                          >
                            <div className="col-md-2">
                              <AiOutlineCalendar style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              Calender
                            </div>
                          </NavLink>
                        </div>
                        <div className="col-md-12 p-0 ">
                          <NavLink
                            className="navbar-item col-md-12 py-2 my-1"
                            activeClassName="is-active"
                            to="/counsellorInbox"
                          >
                            <div className="col-md-2">
                              <BsInbox style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              Inbox
                            </div>
                          </NavLink>
                        </div>
                        <div className="col-md-12 p-0 ">
                          <NavLink
                            className="navbar-item col-md-12 py-2 my-1 mb-1"
                            activeClassName="is-active"
                            to="/counsellorQueries"
                          >
                            <div className="col-md-2">
                              <BsQuestionSquare style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              Queries
                            </div>
                          </NavLink>
                        </div>

                        <div
                          className="col-md-12 p-0"
                          style={{ height: 100 }}
                        ></div>
                      </div>
                    ) : (
                      // <div className="col-md-12 p-0">
                      //   <div className="col-md-12 p-0">
                      //     <NavLink
                      //       className="navbar-item col-md-12 py-3 mt-3 mb-1 d-flex justify-content-left align-items-center"
                      //       activeClassName="is-active"
                      //       exact
                      //       to="/"
                      //     >
                      //       <div className="col-md-2">
                      //         <FaHome style={{ fontSize: 20 }} />
                      //       </div>
                      //       <div className="col-md-10 d-flex align-items-left">
                      //         HOME
                      //       </div>
                      //     </NavLink>
                      //   </div>
                      //   <div className="col-md-12 p-0 ">
                      //     <NavLink
                      //       className="navbar-item col-md-12 py-3 mb-1"
                      //       activeClassName="is-active"
                      //       to="/profileBuilder"
                      //     >
                      //       <div className="col-md-2">
                      //         <FaAddressCard style={{ fontSize: 20 }} />
                      //       </div>
                      //       <div className="col-md-10 d-flex align-items-left">
                      //         PROFILE BUILDER
                      //       </div>
                      //     </NavLink>
                      //   </div>
                      //   <div className="col-md-12 p-0 ">
                      //     <NavLink
                      //       className="navbar-item col-md-12 py-3 mb-1"
                      //       activeClassName="is-active"
                      //       to="/collegeSearch"
                      //     >
                      //       <div className="col-md-2">
                      //         <FaSearch style={{ fontSize: 20 }} />
                      //       </div>
                      //       <div className="col-md-10 d-flex align-items-left">
                      //         COLLEGE SEARCH
                      //       </div>
                      //     </NavLink>
                      //   </div>
                      //   <div className="col-md-12 p-0 ">
                      //     <NavLink
                      //       className="navbar-item col-md-12 py-3 mb-1"
                      //       activeClassName="is-active"
                      //       to="/textEditor"
                      //     >
                      //       <div className="col-md-2">
                      //         <FaFile style={{ fontSize: 20 }} />
                      //       </div>
                      //       <div className="col-md-10 d-flex align-items-left">
                      //         TEXT EDITOR
                      //       </div>
                      //     </NavLink>
                      //   </div>
                      //   <div className="col-md-12 p-0 ">
                      //     <NavLink
                      //       className="navbar-item col-md-12 py-3 mb-1"
                      //       activeClassName="is-active"
                      //       to="/uploadDocs"
                      //     >
                      //       <div className="col-md-2">
                      //         <FaUpload style={{ fontSize: 20 }} />
                      //       </div>
                      //       <div className="col-md-10 d-flex align-items-left">
                      //         {" "}
                      //         UPLOAD DOCS
                      //       </div>
                      //     </NavLink>
                      //   </div>
                      //   <div className="col-md-12 p-0 ">
                      //     <NavLink
                      //       className="navbar-item col-md-12 py-3 mb-1"
                      //       activeClassName="is-active"
                      //       to="/appoinments"
                      //     >
                      //       <div className="col-md-2">
                      //         <FaCalendar style={{ fontSize: 20 }} />
                      //       </div>
                      //       <div className="col-md-10 d-flex align-items-left">
                      //         {" "}
                      //         APPOINMENTS
                      //       </div>
                      //     </NavLink>
                      //   </div>
                      //   <div className="col-md-12 p-0">
                      //     <NavLink
                      //       className="navbar-item col-md-12 py-3 mb-3 mb-1"
                      //       activeClassName="is-active"
                      //       to="/aluminiNetwork"
                      //     >
                      //       <div className="col-md-2">
                      //         <FaUsers style={{ fontSize: 20 }} />
                      //       </div>
                      //       <div className="col-md-10 d-flex align-items-left">
                      //         {" "}
                      //         ALUMINI NETWORK
                      //       </div>
                      //     </NavLink>
                      //   </div>
                      // </div>

                      // new sidebar
                      <div className="col-md-12 p-0">
                        <div className="col-md-12 p-0">
                           <NavLink
                            className="navbar-item col-md-12 py-2 my-1 d-flex justify-content-left align-items-center"
                            activeClassName="is-active"
                            exact
                            to="/"
                          >
                            <div className="col-md-2">
                              <MdOutlineSpaceDashboard style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              Dashboard
                            </div>
                          </NavLink>
                        </div>
                        <div className="col-md-12 p-0">
                           <NavLink
                            className="navbar-item col-md-12 py-2 my-1 d-flex justify-content-left align-items-center"
                            activeClassName="is-active"
                            exact
                            to="/profileBuilder"
                          >
                            <div className="col-md-2">
                              <AiOutlineProfile style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              ProfileBuilder
                            </div>
                          </NavLink>
                        </div>
                        <div className="col-md-12 p-0 ">
                          <NavLink
                            className="navbar-item col-md-12 py-2 my-1 mb-1"
                            activeClassName="is-active"
                            to="/college"
                          >
                            <div className="col-md-2">
                              <TbBuildingBank style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              College
                            </div>
                          </NavLink>
                        </div>
                        <div className="col-md-12 p-0 ">
                          <NavLink
                            className="navbar-item col-md-12 py-2 my-1  mb-1"
                            activeClassName="is-active"
                            to="/documents"
                          >
                            <div className="col-md-2">
                              <CgFileDocument style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              Documents
                            </div>
                          </NavLink>
                        </div>
                        <div className="col-md-12 p-0 ">
                          <NavLink
                            className="navbar-item col-md-12 py-2 my-1  mb-1"
                            activeClassName="is-active"
                            to="/calender"
                          >
                            <div className="col-md-2">
                              <AiOutlineCalendar style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              Calender
                            </div>
                          </NavLink>
                        </div>
                        <div className="col-md-12 p-0 ">
                          <NavLink
                            className="navbar-item col-md-12 py-2 my-1 mb-1"
                            activeClassName="is-active"
                            to="/aluminiNetwork"
                          >
                            <div className="col-md-2">
                              <SlPeople style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              Alumini
                            </div>
                          </NavLink>
                        </div>
                        <div className="col-md-12 p-0 ">
                          <NavLink
                            className="navbar-item col-md-12 py-2 my-1 mb-1"
                            activeClassName="is-active"
                            to="/essays"
                          >
                            <div className="col-md-2">
                              <BsFileText style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              Essays
                            </div>
                          </NavLink>
                        </div>
                        <div className="col-md-12 p-0 ">
                          <NavLink
                            className="navbar-item col-md-12 py-2 my-1 mb-1"
                            activeClassName="is-active"
                            to="/queries"
                          >
                            <div className="col-md-2">
                              <BsQuestionSquare style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              Queries
                            </div>
                          </NavLink>
                        </div>
                        <div className="col-md-12 p-0 ">
                          <NavLink
                            className="navbar-item col-md-12 py-2 my-1"
                            activeClassName="is-active"
                            to="/inbox"
                          >
                            <div className="col-md-2">
                              <BsInbox style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              Inbox
                            </div>
                          </NavLink>
                      </div>
                      </div>

                    )}
                  </div>
                )}

                {/* <div className="col-md-12 p-0 mt-4 mb-1">
                  <div
                    className="navbar-item py-2 mx-auto"
                    onClick={() => logoutBtnClicked()}
                    style={{
                      cursor: "pointer",
                      width: "80%",
                      color: "red",
                      fontWeight: 600,
                      fontSize: 12,
                      border: "1px solid red",
                      borderRadius: 4,
                    }}
                  >
                    SIGN OUT
                  </div>
                </div> */}

                {/* new logout sec */}
                <div className="col-md-12 p-0 mt-4 mb-1" >
                  {/* <div
                    className="navbar-item py-2 mx-auto"
                    onClick={() => logoutBtnClicked()}
                    style={{
                      cursor: "pointer",
                      width: "80%",
                      color: "red",
                      fontWeight: 600,
                      fontSize: 12,
                      border: "1px solid red",
                      borderRadius: 4,
                    }}
                  >
                    SIGN OUT
                  </div> */}
                      {/* <div className="col-md-12 p-0 ">
                          <NavLink
                            className="navbar-item col-md-12 py-2 my-1"
                            activeClassName="is-active"
                            to="/counsellorInbox"
                          >
                            <div className="col-md-2">
                              <BsInbox style={{ fontSize: 20 }} />
                            </div>
                            <div className="col-md-10 d-flex align-items-left">
                              Inbox
                            </div>
                          </NavLink>
                      </div> */}

                      <div className="col-md-12 p-0 ">
                        <NavLink
                          className="navbar-item col-md-12 py-2 my-1"
                          activeClassName="is-active"
                          to="/settings"
                        >
                          <div className="col-md-2">
                            <FiSettings style={{ fontSize: 20 }} />
                          </div>
                          <div className="col-md-10 d-flex align-items-left">
                            Settings
                          </div>
                        </NavLink>
                      </div>

                      <div className="col-md-12 p-0 ">
                        <NavLink
                          className="navbar-item col-md-12 py-2 my-1"
                          activeClassName="is-active"
                          to="/notification"
                        >
                          <div className="col-md-2">
                            <IoIosNotificationsOutline style={{ fontSize: 20 }} />
                          </div>
                          <div className="col-md-10 d-flex align-items-left">
                            Notifications
                          </div>
                        </NavLink>
                      </div>

                      <div className="col-md-12 p-0 " style={{"cursor": "pointer"}} onClick={() => setShowLogoutAlert(true)}>
                        <div
                          className="navbar-item col-md-12 py-2 my-1"
                        >
                          <div className="col-md-2">
                            <FiLogOut style={{ fontSize: 20 }} />
                          </div>
                          <div className="col-md-10 d-flex align-items-left" >
                            Logout
                          </div>
                        </div>
                      </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </nav>

      <AlertModal
          show={showLogoutAlert}
          onHide={() => setShowLogoutAlert(false)}
          title='Alert!'
          message='Are you sure you want to logout?'
          onCancel={() => setShowLogoutAlert(false)}
          onConfirm={() => logoutBtnClicked()}
      />
    </div>
  );
};

export default Navbar;
