import React from 'react'
import { Calendar } from 'react-modern-calendar-datepicker'

const Calender = () => {

  return (
    <div className='counseler-calander' style={{"height" : "50vh"}}>
        <Calendar 
            calendarClassName='test-new'
            calendarTodayClassName="custom-today-day"
        />
    </div>
  )
}

export default Calender;