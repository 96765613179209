import React, { useEffect, useState } from "react";
import axios from 'axios';
import { MW_URL } from "../config";
import { customerDetails, setCustomerDetails } from "../stateManager/reducers/userDetailsReducer";
import GreenCheck from "../imageAssets/Check_Green.png"
import { Button, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setMainLoadingTrue, setMainLoadingFalse, setCurrentPage } from "../stateManager/reducers/generalReducer";
import {BiLogoFacebook} from 'react-icons/bi'
import {BiLogoGoogle} from 'react-icons/bi'
import {BiLogoApple} from 'react-icons/bi'
import { useForm } from "../utils/useForm";
import ValidateForm from "../utils/formValidation";
import { proImage, setProImage } from "../stateManager/reducers/userDetailsReducer";
import { ToastContainer, toast } from 'react-toastify';



const TellUsMoreScreen2 = () => {

    const [height, SetHeight] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const profileImage2 = useSelector(proImage);
    const [listOfCouncellors, setlistOfCouncellors] = useState([]);

    const customerDetailsFromRedux = useSelector(customerDetails);
    if (Object.keys(customerDetailsFromRedux.moreDetails).length > 0) {
        var value = customerDetailsFromRedux.moreDetails.value
    }
    const [counsellorDetails, setCounsellorDetails] = useState("");
    var [value, handleChange] = useForm({
        // major: Object.keys(customerDetailsFromRedux.moreDetails).length > 0 ? customerDetailsFromRedux.moreDetails.value.major : "",
        mobile: Object.keys(customerDetailsFromRedux.moreDetails).length > 0 ? customerDetailsFromRedux.moreDetails.value.mobile : "",
        school: Object.keys(customerDetailsFromRedux.moreDetails).length > 0 ? customerDetailsFromRedux.moreDetails.value.school : "",
        address: Object.keys(customerDetailsFromRedux.moreDetails).length > 0 ? customerDetailsFromRedux.moreDetails.value.address : "",
        country: Object.keys(customerDetailsFromRedux.moreDetails).length > 0 ? customerDetailsFromRedux.moreDetails.value.country : "",
        course: Object.keys(customerDetailsFromRedux.moreDetails).length > 0 ? customerDetailsFromRedux.moreDetails.value.course : "",
        university: Object.keys(customerDetailsFromRedux.moreDetails).length > 0 ? customerDetailsFromRedux.moreDetails.value.university : "",
        counsellorId: Object.keys(customerDetailsFromRedux.moreDetails).length > 0 ? customerDetailsFromRedux.moreDetails.value.counsellorId : ""
    })
    

    useEffect(() => {
        dispatch(setMainLoadingTrue())
        var windowHeight = window.innerHeight;
        SetHeight(windowHeight + "px");
        dispatch(setMainLoadingFalse());
        // verifyCounsellor();
        axios.post(`${MW_URL}` + "users", {})
            .then(getUsersResponse => {
                if (getUsersResponse && getUsersResponse.data && getUsersResponse.data[0]) {
                    var userList = []
                    for (var i = 0; i < getUsersResponse.data.length; i++) {
                        var currentObj = getUsersResponse.data[i];
                        if (currentObj.userType === "counsellor") {
                            userList.push({ "username": currentObj.username, "email": currentObj.email, "userType": currentObj.userType })
                        }
                    }
                    console.log("userList", userList);
                    // setlistOfCouncellors(userList);
                }
            })
            .catch(err => {
                console.log("err >> ", err);
            })
    }, [])

    const createUniquieId = () => {
        const head = Date.now().toString(36);
        const tail = Math.random().toString(36).substr(2);
        return head + tail;
        };

    const onChangePicture = (e) => {
        if (e.target.files[0]) {
            console.log("picture >> ", e.target.files);
            //setPicture(e.target.files[0]);
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                //setImgData(reader.result);
                dispatch(setProImage(reader.result))
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };


    const verifyCounsellor = (e) => {
        e.preventDefault()
        console.log("1515")
        var status = "NA";
        // const params = new URLSearchParams(window.location.search).get("counsellorId");
        // alert(params)
        // console.log("1512", value.counsellor)
        if (value.counsellorId) {
            axios
                .post(`${MW_URL}` + "verifyConsellor", {
                    counsellorId: value.counsellorId,
                })
                .then((response) => {
                    status = response.data.STATUS
                    if (response.data[0]) {
                        if (response.data[0].email === value.counsellorId) {
                            setCounsellorDetails({counsellorName:response.data[0].username,
                             counsellorId:response.data[0].email,   
                            })
                        }
                    }
                    else if (status.toUpperCase() === "FAILED") {
                        toast.error("Invalid Link", {
                            autoClose: 2000,
                        })
                    }
                })
                .catch((err) => {
                    console.log("err >> ", err);
                });
        }
    }

    const beginBtnClicked = (e) => {
        e.preventDefault()
        console.log("test-click")
        if (ValidateForm.inputFieldMandatoryCheck("tellUsMorePageContainer", false)) {
            console.log("test-click12")
            if (ValidateForm.inputFieldValueCompositionCheck("tellUsMorePageContainer", false)) {
                console.log("test-click13")
                dispatch(setCustomerDetails({ "moreDetails": { value } }));
                console.log("customerDetailsFromRedux >> ", customerDetailsFromRedux)
                //dispatch(setCurrentPage("tellUsMore"))

                //localStorage.setItem("isUserLoggedIn", "true");
                //dispatch(setCurrentPage("portal"))
                var dataToSend = {
                    "username": customerDetailsFromRedux.basicDetails.value.name,
                    "password": customerDetailsFromRedux.basicDetails.value.password,
                    "email": customerDetailsFromRedux.basicDetails.value.email,
                    "userType": "student",
                    "major": value.major,
                    "mobile": value.mobile,
                    "counsellorId": counsellorDetails.counsellorId,
                    "address": value.address,
                }

                var dataToSend2 = {
                    profileImageId: createUniquieId(),
                    profileImage: profileImage2,
                    senderId: customerDetailsFromRedux.basicDetails.value.email,
                }

                setIsLoading(true)
                console.log(customerDetailsFromRedux.basicDetails, value)
                console.log(dataToSend, "datatosnd")
                console.log(dataToSend2, "datatosnd")
    
                axios.post(`${MW_URL}` + "addStudentProfileImage", dataToSend2)
                    .then((getResponse) => {
                        console.log(getResponse, "getResponse")
                        if(getResponse.status === 200){
                            console.log("pro image addes")
                        }
                    })

                axios.post(`${MW_URL}` + "signUp", dataToSend)
                    .then(response => {
                        setIsLoading(false)
                        console.log(response,"doneeeeee12222-------->>>>")
                        var status = "NA";
                        if (response && response.data && response.data.STATUS) status = response.data.STATUS
                        if (status.toUpperCase() === "SUCCESS") {
                            toast.success("User has been created", {
                                autoClose: 2000,
                                pauseOnHover: true,
                              });
                            setTimeout(() => {
                                dispatch(setCurrentPage(""))
                                dispatch(setCustomerDetails({ "basicDetails": {} }));
                            }, 2000); 
                        } else if (status === "USER_ALREADY_EXISTS") {
                            toast.error("User already exists", {
                                autoClose: 2000,
                                pauseOnHover: true,
                              });
                        }
                    })
                    .catch(err => {
                        console.log("err >> ", err);
                    })
            }
        }
    }
    
    const handleBackBtn = () => {
        dispatch(setCurrentPage("signUp"))
    }

    const handleLoginBtn = () => {
        dispatch(setCurrentPage("login"))
    }


  return (
    <div className='row'>
    <div className='col-5 leftBg'></div>
    <div className='col-7 px-0'>
        <div className='signupHead'>
            <div className='row mx-2'>
                <div className='col-8'>
                    <div className=''>
                        <h3 className='signupText'>Tell Us More</h3>
                        <div className='tellusSubhead2' >
                            <div className=''><img width={20} height={20} src={GreenCheck} /></div>
                            <div className='midLine'></div>
                            <div className='currentSec'>2</div>
                        </div>
                    </div> 
                </div>
                <div className='col-4'><div className='bg-title1'></div></div>
            </div>
        </div>
        <div id="tellUsMorePageContainer">
            <form className="">
                <div className="row my-2 mx-3">
                    <div className="col-6 px-4">
                        <div className='inputSec form-group'>
                            <label htmlFor='signupProfilePic'>Profile Picture</label>
                            <Input
                                type="file"
                                autoComplete="new-password"
                                name='signupProfilePic'
                                className="input"
                                accept=".png, .jpg, .jpeg" 
                                // value={formData.email}
                                id="signupProfilePic"
                                placeholder="Upload a Profile Picture"
                                onChange={onChangePicture}
                            />
                        </div>

                        <div className='inputSec form-group'>
                            <label htmlFor='mobile'>Mobile Number<span className="text-danger">*</span></label>
                            <Input
                                type="number"
                                autoComplete="new-password"
                                name='mobile'
                                className="input input_data mandatoryField typeContactNum"
                                value={value.mobile}
                                id="mobile"
                                placeholder="Enter your Mobile"
                                onChange={handleChange}
                            />
                            <div className="errorDesc"></div>
                        </div>
                        
                        <div className='inputSec form-group'>
                            <label htmlFor='course'>Course</label><br></br>
                            <div>
                                <select name="course" className="signupSelect" value={value.course} onChange={handleChange} >
                                    <option selected>Select</option>
                                    <option value="1">course One</option>
                                    <option value="2">course Two</option>
                                    <option value="3">cource Three</option>
                                </select>
                            </div>
                       </div>

                       <div className='inputSec form-group'>
                            <label htmlFor='counsellorId'>counsellorId<span className="text-danger">*</span></label>
                            <Input
                                type="text"
                                autoComplete="new-password"
                                name='counsellorId'
                                className="input input_data mandatoryField specialCharactersAllowed typeEmail"
                                value={value.counsellorId}
                                id="counsellorId"
                                placeholder="Enter councellor Id"
                                onChange={handleChange}
                            />
                            <div className="errorDesc"></div>
                       </div>

                       <div className="d-flex align-items-center">
                        <div>
                            <div onClick={(e) => verifyCounsellor(e)} className="essayBtn1 verificationButton">Verify Councellor</div>
                            </div>
                            <div className="verifyText">{counsellorDetails?.counsellorId ? (<div>Verified</div>) : (<div>Not verified</div>)}</div>
                       </div>

                    </div>
                    <div className="col-6 px-4">
                        <div className='inputSec form-group'>
                            <label htmlFor='school'>School</label>
                            <Input
                                type="text"
                                autoComplete="new-password"
                                name='school'
                                className="input input_data typeText"
                                value={value.school}
                                id="school"
                                placeholder="Enter your School"
                                onChange={handleChange}
                            />
                             <div className="errorDesc"></div>
                        </div>

                        <div className='inputSec form-group'>
                            <label htmlFor='course'>Country</label>
                            <div>
                                <select className="signupSelect" name="country" value={value.country} onChange={handleChange} >
                                    <option value="">country</option>
                                    <option value="0">United States</option>
                                    <option value="1">United Kingdom</option>
                                    <option value="2">Canada</option>
                                    <option value="3">Australia</option>
                                    <option value="4">India</option>

                                </select>
                            </div>
                            <div className="errorDesc"></div>
                       </div>

                       <div className='inputSec form-group'>
                            <label htmlFor='course'>University Preference</label>
                            <div>
                                <select className="signupSelect" name="university" value={value.university} onChange={handleChange} >
                                    <option value="">University</option>
                                    <option value="0">University-1</option>
                                    <option value="1">University-2</option>
                                    <option value="2">University-3</option>
                                </select>
                            </div>
                       </div>

                    </div>
                </div>
            </form>

            <div className='text-center mt-5 py-3'>
                    <button className='btnBack' onClick={()=> handleBackBtn()}>Back</button>
                    {counsellorDetails?.counsellorId ? (
                        <button onClick={(e) => beginBtnClicked(e)} className='btnFinish'>Finish</button>
                    ) : (<button className='btnFinishDisabled'>Finish</button>)}
                </div>
 
        </div>
        
        <div className='signupHorizontal'></div>

        <div className='otherSignup'>
            <div>
                <p className='m-0'>Or you can also login with</p>
                <div className='loginMethods'>
                    <div className='my-2 mx-4 loginMethodsBg'><BiLogoApple className='loginMethodFont' /></div>
                    <div className='my-2 mx-2 loginMethodsBg'><BiLogoGoogle className='loginMethodFont' /></div>
                    <div className='my-2 mx-4 loginMethodsBg'><BiLogoFacebook className='loginMethodFont' /></div>
                </div>
            </div>
        </div>
        <div className='signupBottomText'>
            <p>Already Registered? <span className='linkText' onClick={() => handleLoginBtn()}> Login</span></p>
        </div>
    </div>
    <ToastContainer />
</div>
  )
}

export default TellUsMoreScreen2