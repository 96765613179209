import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import axios from 'axios';
import { MW_URL } from "../../../config";
import moment from "moment";
import { Button, Modal, Row, Col } from 'react-bootstrap';
import LoadingAnimation from "../../../utils/loadingAnimation";
import {FaReply} from 'react-icons/fa'
import StudentProfile from "../portals/StudentImage";



const CouncellorQuery = () => {

    const [listOfStudents, setlistOfStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedStudentId, setSelectedStudentId] = useState("all")
    const [selQuery, setSelQuery] = useState({})
    const [queriesList, setqueriesList] = useState([]);
    const [show, setShow] = useState(false);
    const [queryReply, setqueryReply] = useState("");








    useEffect(() => {
        getlistofusersFromDb();
        getAllEventsFun();
    }, [])
    
    const createUniquieId = () => {
        const head = Date.now().toString(36);
        const tail = Math.random().toString(36).substr(2);
        return head + tail;
      };

    const getlistofusersFromDb = () => {
        axios
          .post(`${MW_URL}` + "users", {})
          .then((getUsersResponse) => {
            if (
              getUsersResponse &&
              getUsersResponse.data &&
              getUsersResponse.data[0]
            ) {
              axios
                .post(`${MW_URL}` + "getUpdates", {
                  recipientId: JSON.parse(localStorage.getItem("userData")).email,
                  senderId: false,
                })
                .then((getUpdatesResponse) => {
                  // console.log("getUpdatesResponse", getUpdatesResponse.data);
                  //  setupdatesList(getUpdatesResponse.data);
      
                  var userList = [];
                  for (var i = 0; i < getUsersResponse.data.length; i++) {
                    var currentObj = getUsersResponse.data[i];
                    var currentDate = new Date();
                    currentDate = currentDate.setDate(currentDate.getDate() - 2);
                    if (
                      currentObj.counsellorId ===
                      JSON.parse(localStorage.getItem("userData")).email
                    ) {
                      userList.push({
                        key: currentObj.email,
                        username: currentObj.username,
                        email: currentObj.email,
                        userType: currentObj.userType,
                        major: currentObj.major,
                        updates: getUpdatesResponse.data,
                        updatesNum: getUpdatesResponse.data.filter(
                          (updte) =>
                            updte.senderId == currentObj.email &&
                            moment(updte.updatedDate, "YYYY-MM-DD").isSameOrBefore(
                              new Date(),
                              "day"
                            ) &&
                            moment(updte.updatedDate, "YYYY-MM-DD").isSameOrAfter(
                              currentDate,
                              "day"
                            )
                        ).length,
                      });
                    }
                  }
                  console.log("userList===>", userList);
                  setlistOfStudents(userList);
                //   setlistOfStudentsTemp(userList)
                //   setSelectedStudent(userList[0])
                  let params = new URLSearchParams(window.location.search);
                  if (params.get("studentEmail")) {
                    // console.log("studentEmail", params.get("studentEmail"));
                    const selectedUser = userList.find(
                      (user) => user.email == params.get("studentEmail")
                    );
                    // console.log("selectedUser", selectedUser);
                    // showTextEditorMain(selectedUser);
                  }
                  setIsLoading(false)
                })
                .catch((err) => {
                  console.log("err >> ", err);
                });
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      };

    const getAllEventsFun = () => {
        // console.log("getAllEventsFun");
        axios
          .post(`${MW_URL}` + "getAllEvents", {})
          .then((getAllEventsResponse) => {
            var eventsList = [];
            getAllEventsResponse.data.map((event) => {
              if (
                event.eventGuests.indexOf(
                  JSON.parse(localStorage.getItem("userData")).email
                ) != -1
              ) {
                eventsList.push({
                  id: event.eventId,
                  title: event.title,
                  start: event.start,
                  end: event.end,
                });
              }
            });
            // setCurrentEvents(eventsList);
            axios
              .post(`${MW_URL}` + "getUpdates", {
                recipientId: JSON.parse(localStorage.getItem("userData")).email,
                senderId: false,
              })
              .then((getUpdatesResponse) => {
                console.log("getUpdatesResponse", getUpdatesResponse.data);
                // setupdatesList(getUpdatesResponse.data);
    
                axios
                  .post(`${MW_URL}` + "getQueriesByRecipientId", {
                    recipientId: JSON.parse(localStorage.getItem("userData")).email,
                    senderId: false,
                  })
                  .then((getQueriesByRecipientIdResponse) => {
                    setqueriesList(getQueriesByRecipientIdResponse.data);
                    console.log(getQueriesByRecipientIdResponse.data, "qrlist");
                    setIsLoading(false);
                  })
                  .catch((err) => {
                    console.log("err >> ", err);
                  });
              })
              .catch((err) => {
                console.log("err >> ", err);
              });
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      };
    
    const handleCollageChange = (e) => {
        const selectedValue = e.target.value;
        console.log(selectedValue, "selectedValue")
        setSelectedStudentId(selectedValue)
        const selectedCollageObj = listOfStudents.find(obj => obj.email === selectedValue);
      }

    const queryReplyHandler = (e) => {
        const { name, value } = e.target;
        setqueryReply(value);
      };
    
    const queryReplyButtonHandler = () => {
        var dataToSend = {
          queryId: selQuery.queryId,
          replys: [queryReply],
          updateId: createUniquieId(),
          recipientId: selQuery.senderId,
          senderId: JSON.parse(localStorage.getItem("userData")).email,
          message:
            JSON.parse(localStorage.getItem("userData")).username +
            " replayed to your query",
        };
        if (dataToSend.replys[0]) {
          setIsLoading(true);
          axios
            .post(`${MW_URL}` + "updateQueryReply", dataToSend)
            .then((response) => {
              var status = "NA";
              if (response && response.data && response.data.STATUS)
                status = response.data.STATUS;
              if (status.toUpperCase() === "SUCCESS") {
                setIsLoading(false);
                window.location.reload();
              } else {
              }
            })
            .catch((err) => {
              console.log("err >> ", err);
            });
        }
      };

    
  return (
    <div className='queries'>
        {isLoading ? <LoadingAnimation isVisible={true} /> : null}
        <div className='m-2 d-flex align-items-center'>
          <div className='pt-3'>
            <p className='essayHeadText4'>Queries</p>
          </div>
          <select onChange={handleCollageChange} className="deadlineSelect mx-3 w-25">
                {listOfStudents < 1 && (<option>No Students Selected</option>)}
                <option value="all" >All</option>
                { listOfStudents.map((obj, index) => (
                    <option key={index} defaultValue={obj.email} value={obj.email} >{obj.username}</option>
                ))}
          </select>
        </div>

        <Row className='p-2'>
            <Col sm={8} style={{"height":"82vh", "overflow":"scroll"}}>
                {queriesList.map((obj, index) => {
                    if(selectedStudentId === "all" || obj.senderId === selectedStudentId){
                        return (
                            <div onClick={() => setSelQuery(obj)} key={index} className='essaySec px-2 m-1'>
                                <div className='d-flex justify-content-between'>
                                    <p className={selQuery === obj ? 'essayHeadText3 selectedText' : 'essayHeadText3'}>{obj.senderName}</p>
                                    {obj?.replys?.length < 1 && (<FaReply fontSize={12} fill="red" />)}
                                    
                                </div>
                                <p className='essayNormalText2 m-0'>{obj.message}</p>
                                <p className='queryLightText py-2'>{moment(obj.updatedDate).format('DD/MM/yyyy')}</p>
                            </div>
                        )}
                })}


            </Col>
            <Col sm={4}>
                      {/* rightside */}
                      {Object.keys(selQuery).length > 0 && (
                      <div className="counQueriesCard2 m-2 p-2">
                        <div className="d-flex">
                            {/* <div className="counsQueryImgbg"></div> */}
                            <div className="mr-2">
                                <StudentProfile studentId={selQuery?.senderId} className="counsQueryImgbg" />
                            </div>
                            <div>
                            <p className="dashNormalText2 m-0">{selQuery?.senderName}</p>
                            <p className="counDashNormalText">
                                {selQuery?.message}
                            </p>
                                {selQuery?.replys?.length > 0 ? (null) : (
                                <div>
                                    <div className="d-flex justify-content-between">
                                    {/* <p className="counDashLinkText" onClick={() => setShow(true)}>Reply</p> */}
                                    {show && (
                                        <textarea
                                        className="relay-text"
                                        style={{ width: "100%", backgroundColor : "#F4F4F4" }}
                                        value={queryReply}
                                        onChange={queryReplyHandler}
                                        ></textarea>
                                    )}
                                    </div>
                                    <p
                                        className="counDashLinkText no-btn"
                                        onClick={() => setShow(true)}
                                    >
                                        {show === true ? <p onClick={() => queryReplyButtonHandler()}>send</p> : <p >reply</p>}
                                    </p>
                                </div>
                                )}
                            
                            </div>
                        </div>
                        {selQuery?.replys?.length > 0 && (
                            <div className="counQueriesCard3">
                            {/* <div className="counsQueryImgbg"></div> */}
                                <div className="mr-2">
                                <StudentProfile studentId={selQuery?.recipientId} className="counsQueryImgbg" />
                            </div>
                            <div>
                                <p className="dashNormalText2 m-0">{selQuery?.recipientName}</p>
                                <p className="counDashNormalText">{selQuery?.replys}</p>
                            </div>
                            </div>
                        )}
                    </div> )}
                </Col>
        </Row>
    </div>
  )
}

export default CouncellorQuery