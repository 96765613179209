import React, { useEffect, useState, useRef, memo } from "react";
import RadioButtonSelected from "../imageAssets/radiobutonselected.png";
import RadioButtonUnSelected from "../imageAssets/radiobutonunselected.png";
import { FaPlus,  } from "react-icons/fa";
import { IoRemoveCircleOutline} from "react-icons/io5";

const TodoList = ({
  todolist,
  updateTodoList = () => { },
  setaddTodoModalVisible = () => { },
  collageId,
  edittodolist = () => { },
  showSaveButtonForTodoUpdate,
  setshowSaveButtonForTodoUpdate = () => { }
}) => {
  const [todoListLocal, settodoListLocal] = useState([]);
  useEffect(() => {
    settodoListLocal(todolist);
    setshowSaveButtonForTodoUpdate(false);
  }, [todolist]);

  const addtodoList = () => { };
  const updateTodoListLocal = (todoSelected) => {
    setshowSaveButtonForTodoUpdate(true);
    settodoListLocal(
      todoListLocal.map((todo) => {
        if (todo.id == todoSelected.id) {
          if (todo.selected) {
            return {
              ...todo,
              selected: false,
            };
          } else {
            return {
              ...todo,
              selected: true,
            };
          }
        } else {
          return {
            ...todo,
            selected: todo.selected,
          };
        }
      })
    );
  };
  const deleteTodoListLocal = (todoSelected) => {
    setshowSaveButtonForTodoUpdate(true);
    const newList =
      todoListLocal.filter((todo) => todo.id !== todoSelected.id)
    settodoListLocal(newList)
    return {
      ...newList,
    };
  };

  return (
    <div>
      <div
        className="col-md-12 p-0 mt-2 mr-2"
        style={{ maxHeight: 130, overflow: "scroll" }}
      >
        {todoListLocal.map((todo) => {
          return (
            <div
              className="col-md-12 p-0 m-0 py-1 pr-1 d-flex justify-content-between"
              key={todo.id}
            >
              <div className="col-md-2 p-0 m-0 " style={{alignItems:"center"}}>
                <IoRemoveCircleOutline
                  color="red"
                  style={{fontSize:16 , cursor:"pointer"}}
                  onClick={() => {
                    deleteTodoListLocal(todo);
                  }}>
                </IoRemoveCircleOutline>
              </div>
              <div
              style={{display:"flex", justifyContent:"center",alignContent:"center",flexDirection:"column"}}
                onClick={() => {
                  updateTodoListLocal(todo);
                }}
                className={
                  todo.selected
                    ? "col-md-8 p-0 m-0 radioButtonSelectText"
                    : "col-md-8 p-0 m-0 radioButtonUnSelectText"
                }
              >
                {todo.title}
              </div>
              <div
              className="col-md-2 p-0 m-0"
              style={{ cursor:"pointer"}}
                onClick={() => {
                  updateTodoListLocal(todo);
                }}
              >
                <img
                  height={20}
                  src={
                    todo.selected ? RadioButtonSelected : RadioButtonUnSelected
                  }
                  alt="statsimg"
                />
              </div>
              
            </div>


          );
        })}

      </div>
      <div
        className="d-flex justify-content-between p-2"
        style={{
          width: "100%",
          textAlign: "center",
          alignContent: "center",
        }}
      >
        {collageId ? (
          <FaPlus
            style={{ fontSize: 12, cursor: "pointer" }}
            onClick={() => {
              setaddTodoModalVisible(true);
            }}
          />
        ) : null}

        {showSaveButtonForTodoUpdate ? (
          <div
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => {
              edittodolist(todoListLocal);
            }}
          >
            save
          </div>
        ) : null}
      </div>
    </div>

  );
};

export default memo(TodoList);
