import React, { useEffect, useState } from "react";
import LoadingAnimation from "../../utils/loadingAnimation";
import axios from "axios";
import { MW_URL } from "../../config";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Collage2 from "../../imageAssets/CollegeLogo/addLogo.jpg";
import { FaCaretLeft, FaCaretRight, FaBars, FaBook, FaTrophy, FaCheck } from "react-icons/fa";
import Uni1 from "../../imageAssets/university1.png";
import Uni4 from "../../imageAssets/university4.png";
import { Button, Input } from "reactstrap";
import { FaCaretDown, FaTrash } from "react-icons/fa";
import EssayImg from "../../imageAssets/essayimg.png";
import Us from "../../imageAssets/us.png";
import Uk from "../../imageAssets/uk.png";
import India from "../../imageAssets/india.png";
import Europe from "../../imageAssets/europe.png";
import {
  FaUser,
  FaFilter,
  FaCodeBranch,
  FaTimes,
  FaSearch,
} from "react-icons/fa";
import TickWhite from "../../imageAssets/tickwhite.png";
import { green } from "@mui/material/colors";
import { pink, blue, white, black } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
const CollegeSearchScreen = () => {
  const [height, setHeight] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [addUniversitySecVisible, setAddUniversitySecVisible] = useState(false);
  const [showAllSecVisible, setshowAllSecVisible] = useState(false);
  const [universityDetails, setUniversityDetails] = useState(false);
  const [countrySecVisible, SetCountrySecVisible] = useState(false);
  const [featherDropdownVisible, SetfeatherDropdownVisible] = useState(false);
  const [compareUniversitySecVisible, setCompareUniversitySecVisible] =
    useState(false);
  const [dropdownDivMajor, setdropdownDivMajor] = useState(false);
  const [dropdownDivScholarship, setdropdownDivScholarship] = useState(false);
  const [dropdownDivTution, setdropdownDivTution] = useState(false);
  const [dropdownDivTypeofschool, setdropdownDivTypeofschool] = useState(false);
  const [dropdownDivTestscores, setdropdownDivTestscores] = useState(false);
  const [finalCompareSecVisible, setFinalCompareSecVisible] = useState(true);
  const [listOfCollagesArr, setlistOfCollagesArr] = useState([]);
  const [listOfCollageDetailsArr, setlistOfCollageDetailsArr] = useState([]);
  const [listOfSelectedCollageDetailsArr, setlistOfSelectedCollageDetailsArr] =
    useState([]);
  const [resetUniversitySecVisible, setResetUniversitySecVisible] =
    useState(false);
  const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
  const [maxPriceFilterVal, setmaxPriceFilterVal] = useState(35000);
  const [maxActScoreFilterVal, setmaxActScoreFilterVal] = useState(20);
  const [maxSatScoreFilterVal, setmaxSatScoreFilterVal] = useState(1000);
  const [collageSearchKeyword, setcollageSearchKeyword] = useState("");
  const [scoreType, setScoreType] = useState("satscore");
  const [scoreoptions, setScoreOptions] = useState([]);
  var [data, setData] = useState(null);
  const [actScoreChecked, setactScoreChecked] = useState(true);
  const [satScoreChecked, setsatScoreChecked] = useState(true);
  const [maxPriceChecked, setmaxPriceChecked] = useState(true);
  const [collageMetaData, setcollageMetaData] = useState({
    page: 0,
    total: 0,
    per_page: 100,
  });
  const [filterStringToSend, setfilterStringToSend] = useState(false);

  var collageDetailLogo =
    require("../../imageAssets/CollegeLogo/addLogo.jpg").default;

  if (universityDetails.logo) {
    collageDetailLogo = universityDetails.logo;
  }

  useEffect(() => {
    var windowHeight = window.innerHeight;
    setHeight(windowHeight + "px");
    //listOfCollages();
    collagedetailsfromapi(1, false);
  }, []);
  ChartJS.register(ArcElement, Tooltip, Legend);
  const addBtnClicked = (type) => {
    if (type === "add") {
      setFinalCompareSecVisible(false);
      setAddUniversitySecVisible(true);
    }
    if (type === "compare") setFinalCompareSecVisible(true);
    if (type === "show all") {
      setFinalCompareSecVisible(false);
      setAddUniversitySecVisible(false);
      setshowAllSecVisible(true);
    }
  };
  const addUniversityBtnClicked = () => {
    setAddUniversitySecVisible(false);
  };
  const universityDetailsClicked = (collageDetails) => {
    setUniversityDetails(collageDetails);
    // console.log("collageDetails", collageDetails);
  };

  const triggerCallback = (type) => {
    if (type === "cancel") setUniversityDetails(false);
    if (type === "filter") SetCountrySecVisible(true);
    if (type === "compare") setCompareUniversitySecVisible(true);
    if (type === "reset") {
      setCompareUniversitySecVisible(false);
      setFinalCompareSecVisible(false);
    }

    if (type === "close") {
      SetfeatherDropdownVisible(false);
      SetCountrySecVisible(false);
      // console.log("maxPriceFilterVal", maxPriceFilterVal);
    }
    if (type === "add") setAddUniversitySecVisible(true);
  };

  const collagedetailsfromapi = (page, filter) => {
    setIsLoading(true);
    console.log("collagedetailsfromapi filter", filter);

    var selectedCollIds = [];
    axios
      .post(`${MW_URL}` + "getSelectedListOfCollages", {
        email: JSON.parse(localStorage.getItem("userData")).email,
      })
      .then((getSelectedListOfCollagesresponse) => {
        // console.log(
        //   "getSelectedListOfCollagesresponse",
        //   getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
        // );
        setlistOfSelectedCollageDetailsArr(
          getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
        );
        getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
          (collage) => {
            selectedCollIds.push(collage.collageId);
          }
        );
        axios
          .post(`${MW_URL}` + "getCollegeDetailsByOffset", {
            offset: page,
            limit: 100,
            filter: filter,
          })
          .then((collegescorecardresponse) => {
            console.log(
              "collegescorecardresponse",
              collegescorecardresponse.data
            );
            var tempArr = [];
            collegescorecardresponse.data.collageDetails.map((coll, index) => {
              selectedCollIds.includes(coll.collageId)
                ? tempArr.push({ ...coll, selected: true })
                : tempArr.push({ ...coll, selected: false });
            });
            // console.log("tempArr", tempArr);
            if (page == 0) {
              setlistOfCollageDetailsArr(tempArr);
            } else {
              setlistOfCollageDetailsArr([
                ...listOfCollageDetailsArr,
                ...tempArr,
              ]);
            }
            setcollageMetaData(collegescorecardresponse.data.metadata);
            setIsLoading(false);
          })
          .catch((err) => { });
      })
      .catch((err) => { });
  };

  function updatecollageselection(id) {
    setlistOfCollageDetailsArr(
      listOfCollageDetailsArr.map((item) => {
        if (item.collageId == id) {
          if (item.selected) {
            setlistOfSelectedCollageDetailsArr(
              listOfSelectedCollageDetailsArr.filter(
                (item) => item.collageId != id
              )
            );
            return {
              ...item,
              selected: false,
            };
          } else {
            setlistOfSelectedCollageDetailsArr([
              ...listOfSelectedCollageDetailsArr,
              item,
            ]);
            return {
              ...item,
              selected: true,
            };
          }
        } else {
          return {
            ...item,
            selected: item.selected,
          };
        }
      })
    );

    // if item is in the listOfSelectedCollageDetailsArr remove it
    // if item is not in the listOfSelectedCollageDetailsArr add it

    // listOfSelectedCollageDetailsArr.map((item) => {
    //   if (item.id == id) {
    //     //remove from listOfSelectedCollageDetailsArr
    //   } else {
    //     //add to listOfSelectedCollageDetailsArr
    //   }
    // });
  }
  const handleScore = (e) => {
    setScoreType(e.target.value)
  }
  function handleScroll() {
    window.scroll({
      right: 0,
      behavior: "smooth",
    });
  }

  function updateListofCollages() {
    setIsLoading(true);
    // console.log(
    //   "listOfSelectedCollageDetailsArr",
    //   listOfSelectedCollageDetailsArr
    // );
    axios
      .post(`${MW_URL}` + "addCollageList", {
        listOfSelectedCollages: listOfSelectedCollageDetailsArr,
        email: JSON.parse(localStorage.getItem("userData")).email,
      })
      .then((response) => {
        setIsLoading(false);
        setAddUniversitySecVisible(false);
      })
      .catch((err) => { });
  }

  const maxpricevaluechange = (event, newValue) => {
    setmaxPriceFilterVal(newValue);
    console.log(newValue, "newvalue")
  };

  const maxactscorevaluechange = (event, newValue) => {
    setmaxActScoreFilterVal(newValue);
    console.log(newValue, "newvalue-actscore")
  };

  const maxsatscorevaluechange = (event, newValue) => {
    setmaxSatScoreFilterVal(newValue);
    console.log(newValue, "newvalue-sat")
  };

  const actcheckboxvaluechange = (event, newValue) => {
    setactScoreChecked(newValue);
    console.log(newValue, "act-val-cahnged")
  };

  const satcheckboxvaluechange = (event, newValue) => {
    setsatScoreChecked(newValue);
    console.log(newValue, "sat-val-cahnged")
  };

  const maxPricecheckboxvaluechange = (event, newValue) => {
    setmaxPriceChecked(newValue);
  };

  const searchCollageByKeyword = () => {
    // console.log("collageSearchKeyword", collageSearchKeyword);
    var selectedCollIds = [];
    listOfSelectedCollageDetailsArr.map((collage) => {
      selectedCollIds.push(collage.collageId);
    });

    setIsLoading(true);
    axios
      .post(`${MW_URL}` + "getCollegeDetailsByOffsetAndSearchKeyword", {
        offset: 0,
        limit: 10,
        keyword: collageSearchKeyword,
      })
      .then((getCollegeDetailsResponse) => {
        // console.log("getCollegeDetailsResponse", getCollegeDetailsResponse);
        var tempArr = [];
        getCollegeDetailsResponse.data.collageDetails.map((coll, index) => {
          selectedCollIds.includes(coll.collageId)
            ? tempArr.push({ ...coll, selected: true })
            : tempArr.push({ ...coll, selected: false });
        });
        // console.log("tempArr", tempArr);
        setlistOfCollageDetailsArr(tempArr);
        setcollageMetaData(getCollegeDetailsResponse.data.metadata);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };
  React.useEffect(()=>{
    setScoreOptions([
      { value: 'satscore', name: 'SAT Score' ,id:'satscore'},
      { value: 'actscore', name: 'ACT Score' ,id:'actscore'}            
  ]);
  },[]) 
 React.useEffect(()=>{ if (scoreType == 'satscore') {
  if(universityDetails){
    console.log(universityDetails, "universityDetails")
  if (universityDetails?.satScore?.critical_reading||
    universityDetails?.satScore?.math||
    universityDetails?.satScore?.writing) {
    setData({
      labels: ['critical reading', 'math', 'writing'],
      datasets: [
        {
          label: '# of Votes',
          data: [universityDetails?.satScore?.critical_reading,
          universityDetails?.satScore?.math,
          universityDetails?.satScore?.writing],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
          ],
          borderWidth: 1,
        },
      ],
    });
  }
  else{
    setData(null)
  }
}
}
else if (scoreType == 'actscore') {
  if (universityDetails) {
    if(universityDetails?.actScore?.cumulative||
      universityDetails?.actScore?.english||
      universityDetails?.actScore?.math||
      universityDetails?.actScore?.writing){
   setData({
      labels: ['cumulative', 'english', 'math', 'writing'],
      datasets: [
        {
          label: '# of Votes',
          data: [universityDetails?.actScore?.cumulative,
          universityDetails?.actScore?.english,
          universityDetails?.actScore?.math,
          universityDetails?.actScore?.writing,
          ],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 0.2)',
          ],
          borderWidth: 1,
        },
      ],
    });
  }
  else{
    setData(null)
  }
}
}
},[scoreType,universityDetails])

console.log(filterStringToSend, "filter")


  return (
    <div>
      {isLoading ? <LoadingAnimation isVisible={true} /> : null}

      {universityDetails ? (
        <div className=" d-flex commonPopupOuterMostContainer">
          <div
            style={{ flex: "3" }}
            className="leftTransparentPart"
            onClick={() => triggerCallback("cancel")}
          ></div>
          <div style={{ flex: "1" }} className="contentPart">
            <div className=" d-flex row formsContainerBox p-4">
              <div className="col-md-12 text-center">
                <img
                  className="image-design-uni"
                  src={collageDetailLogo}
                  alt="designportfolio"
                />
                <div style={{ fontSize: 15, fontWeight: 500 }}>
                  {universityDetails.name}
                </div>
                <div style={{ fontSize: 10, fontWeight: 500 }}>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                </div>
                <div
                  className="col-md-12 py-3 row p-0 m-0 mx-auto"
                  style={{ color: "#707070", fontSize: 12 }}
                >

                {universityDetails.tutionOutOfState ? (
                  <div className="col-md-4 p-0">
                    <div>
                    <FaBook style={{ fontSize: 15 }}/> ${universityDetails.tutionOutOfState}
                    </div>
                    <div>Before Aid</div>
                  </div>
                ) : null}

                {universityDetails.tutionInState ? (
                  <div className="col-md-4 p-0">
                    <div>
                    <FaTrophy style={{ fontSize: 15 }}/>  ${universityDetails.tutionInState}y
                    </div>
                    <div>After Aid</div>
                  </div>
                ) : null}
                {universityDetails.admissions ? (
                  universityDetails.admissions.overall ? (
                     <div className="col-md-4 p-0">
                    <div>
                    <FaCheck style={{ fontSize: 15 }}/> {universityDetails.admissions.overall}
                    </div>
                    <div>Acceptance</div>
                  </div> ) : null
                ) : null}

                </div>
                {/*<div
                  className="col-md-12 py-3 row p-0 m-0 mx-auto"
                  style={{ color: "#707070", fontSize: 12 }}
                >
                  {universityDetails.tutionOutOfState ? (
                    <div className="col-md-6 p-0">
                      <div>
                      <FaBook style={{ fontSize: 15 }}/> ${universityDetails.tutionOutOfState}
                      </div>
                      <div>Out Of State</div>
                    </div>
                  ) : null}
                  {universityDetails.tutionInState ? (
                    <div className="col-md-6 p-0">
                      <div>
                      <FaTrophy style={{ fontSize: 15 }}/>  ${universityDetails.tutionInState}
                      </div>
                      <div>In State</div>
                    </div>
                  ) : null}
                  </div>*/}

                {universityDetails["latest.cost.tuition.program_year"] ? (
                  <div
                    className="d-flex justify-content-center align-items-center p-2"
                    style={{
                      color: "#707070",
                      fontSize: 12,
                      width: "100%",
                    }}
                  >
                    <div>
                      <div>
                        ${universityDetails["latest.cost.tuition.program_year"]}
                      </div>
                      <div>Tution Fee</div>
                    </div>
                  </div>
                ) : null}

                <div className="col-md-12 row p-0">
                  <div className="p-0">
                    <select
                      name="Deadline"
                      style={{ border: "none", width: "100%" }}
                      id="deadline"
                      className="opacity"
                      onChange={handleScore}
                      value={scoreType}
                    >
                    {scoreoptions.map((col) => {
                      return (
                        <option key={col.id} value={col.id}>
                          {col.name}
                        </option>
                      );
                    })}
                    </select>
                  </div>
                  {data ? ( <div>
                   
                      <Pie data={data} />
                   
                  </div> ) : <div><p>No Data</p></div>}
                </div>

                <div className="py-3" style={{ fontSize: 12, fontWeight: 800 }}>
                  PROMPTS 2021
                </div>
                <div
                  className="col-md-12 m-0 p-0 d-flex py-1 align-items-center"
                  style={{ borderBottom: "1px solid #00000029" }}
                >
                  <img height={65} src={EssayImg} alt="essayimg" />
                  <div
                    style={{
                      fontSize: 7,
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s somrthing something
                    some more bit more
                  </div>
                </div>
                <div
                  className="col-md-12 m-0 p-0 d-flex py-1 align-items-center"
                  style={{ borderBottom: "1px solid #00000029" }}
                >
                  <img height={65} src={EssayImg} alt="essayimg" />
                  <div
                    style={{
                      fontSize: 7,
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s somrthing something
                    some more bit more
                  </div>
                </div>
                <div
                  className="col-md-12 m-0 p-0 d-flex py-1 align-items-center"
                  style={{ borderBottom: "1px solid #00000029" }}
                >
                  <img height={65} src={EssayImg} alt="essayimg" />
                  <div
                    style={{
                      fontSize: 7,
                      fontWeight: 600,
                      textAlign: "left",
                    }}
                  >
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s somrthing something
                    some more bit more
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {showAllSecVisible ? (
        <div
          className="college-search-page-container"
          style={{ minHeight: height }}
        >
          <div
            className="p-5"
            style={{
              textTransform: "uppercase",
              color: "#D0D0D0",
              fontWeight: "800",
            }}
          >
            <div
              className="col-md-12 p-0 d-flex justify-content-end"
              onClick={() => setshowAllSecVisible(false)}
            >
              <FaTimes style={{ fontSize: 22, cursor: "pointer" }} />
            </div>
            <div className="headings-style p-5">Universities</div>
            <div
              className="row ml-5"
              style={{ overflow: "scroll", height: 400 }}
            >
              {listOfCollageDetailsArr.map((collage) => {
                return (
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {universityShowAllComponent("", collage.name, collage)}
                  </div>
                );
              })}
              {Math.floor(collageMetaData.total / collageMetaData.per_page) !=
                collageMetaData.page && collageMetaData.total != 0 ? (
                <div
                  className="p-2 d-flex justify-content-center"
                  style={{ width: "100%", color: "blue", cursor: "pointer" }}
                  onClick={() => {
                    collagedetailsfromapi(
                      collageMetaData.page + 1,
                      filterStringToSend
                    );
                  }}
                >
                  Show more
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : addUniversitySecVisible == false &&
        compareUniversitySecVisible == false ? (
        <div
          className="college-search-page-container"
          style={{ minHeight: height }}
        >
          <div className="p-5">
            {countrySecVisible == false ? (
              <div className="col-md-12 p-0 d-flex justify-content-end">
                <div>
                  <div
                    onClick={() => {
                      SetfeatherDropdownVisible(!featherDropdownVisible);
                    }}
                  >
                    <FaBars style={{ fontSize: 20, cursor: "pointer" }} />
                  </div>
                  {featherDropdownVisible ? (
                    <div
                      className="animated fadeInDown pt-2 d-flex flex-column align-items-center justify-content-center"
                      style={{ position: "absolute" }}
                    >
                      <div className="py-2">
                        <div
                          className="d-flex flex-column align-items-center justify-content-center featherdropdownstyle"
                          style={{
                            backgroundColor: "white",
                            height: 25,
                            width: 25,
                            borderRadius: 30,
                          }}
                        >
                          <FaUser style={{ fontSize: 12, cursor: "pointer" }} />
                        </div>
                      </div>
                      <div className="py-2">
                        <div
                          className="d-flex flex-column align-items-center justify-content-center featherdropdownstyle"
                          style={{
                            backgroundColor: "white",
                            height: 25,
                            width: 25,
                            borderRadius: 30,
                          }}
                          onClick={() => triggerCallback("filter")}
                        >
                          <FaFilter
                            style={{ fontSize: 12, cursor: "pointer" }}
                          />
                        </div>
                      </div>
                      <div className="py-2">
                        <div
                          className="d-flex flex-column align-items-center justify-content-center featherdropdownstyle"
                          style={{
                            backgroundColor: "white",
                            height: 25,
                            width: 25,
                            borderRadius: 30,
                          }}
                          onClick={() => triggerCallback("compare")}
                        >
                          <FaCodeBranch
                            style={{ fontSize: 12, cursor: "pointer" }}
                          />dd
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div
                className="col-md-12 p-0 d-flex justify-content-end"
                onClick={() => {
                  triggerCallback("close");
                  // console.log(satScoreChecked);
                  // console.log(actScoreChecked);
                  if (
                    maxPriceFilterVal ||
                    maxActScoreFilterVal ||
                    maxSatScoreFilterVal
                  ) {
                    setfilterStringToSend({
                      maxPriceFilterVal: maxPriceChecked
                        ? maxPriceFilterVal
                        : maxPriceChecked,
                      maxActScoreFilterVal: actScoreChecked
                        ? maxActScoreFilterVal
                        : actScoreChecked,
                      maxSatScoreFilterVal: satScoreChecked
                        ? maxSatScoreFilterVal
                        : satScoreChecked,
                    });
                    collagedetailsfromapi(0, {
                      maxPriceFilterVal: maxPriceChecked
                        ? maxPriceFilterVal
                        : maxPriceChecked,
                      maxActScoreFilterVal: actScoreChecked
                        ? maxActScoreFilterVal
                        : actScoreChecked,
                      maxSatScoreFilterVal: satScoreChecked
                        ? maxSatScoreFilterVal
                        : satScoreChecked,
                    });
                  } else {
                    // console.log("maxPriceFilterVal =", maxPriceFilterVal);
                    // console.log("maxActScoreFilterVal =", maxActScoreFilterVal);
                    // console.log("maxSatScoreFilterVal =", maxSatScoreFilterVal);
                    setfilterStringToSend(false);
                  }
                }}
              >
                <FaTimes style={{ fontSize: 22, cursor: "pointer" }} />
              </div>
            )}

            {countrySecVisible ? (
              <div className="p-2">
                <div
                  className="headings-style p-4"
                  style={{ color: "#000000" }}
                >
                  Country
                </div>
                <div
                  style={{}}
                  className="col-md-12 p-0 m-0 d-flex justify-content-between align-items-end"
                >
                  <div className="p-0 d-flex flex-column align-items-center justify-content-center">
                    <img src={Us} alt="us" />
                    <div
                      className="py-3 text-uppercase"
                      style={{ fontSize: 10, fontWeight: 500 }}
                    >
                      United States
                    </div>
                  </div>
                  <div className="p-0 d-flex flex-column align-items-center justify-content-center">
                    <img src={Uk} alt="uk" />
                    <div
                      className="py-3 text-uppercase"
                      style={{ fontSize: 10, fontWeight: 500 }}
                    >
                      United Kingdom
                    </div>
                  </div>
                  <div className="p-0 d-flex flex-column align-items-center justify-content-center">
                    <img src={India} alt="india" />
                    <div
                      className="py-3 text-uppercase"
                      style={{ fontSize: 10, fontWeight: 500 }}
                    >
                      India
                    </div>
                  </div>
                  <div className="p-0 d-flex flex-column align-items-center justify-content-center">
                    <img src={Europe} alt="europe" />
                    <div
                      className="py-3 text-uppercase"
                      style={{ fontSize: 10, fontWeight: 500 }}
                    >
                      Europe
                    </div>
                  </div>
                </div>
                <div className="row gx-3 pt-4">
                  <div className="col-md-3">
                    <div className="mb-3">
                      <div
                        className="headings-style"
                        style={{ color: "#000000", cursor: "pointer" }}
                        onClick={() =>
                          setdropdownDivScholarship(!dropdownDivScholarship)
                        }
                      >
                        SCHOLARSHIPS
                        <FaCaretDown style={{ fontSize: 15 }} />
                      </div>
                      {dropdownDivScholarship ? (
                        <div className="col-md-12 m-0 p-0 animated fadeInDown">
                          <div className="form-group">
                            <Checkbox
                              sx={{
                                border: "#6C63FF",
                                color: "#6C63FF",
                              }}
                            />
                            MERIT SCHOLRSHIPS
                          </div>
                          <div className="form-group">
                            <Checkbox
                              sx={{
                                border: "#6C63FF",
                                color: "#6C63FF",
                              }}
                            />
                            FINANCIAL AID
                          </div>
                          <div className="form-group">
                            <Checkbox
                              sx={{
                                border: "#6C63FF",
                                color: "#6C63FF",
                              }}
                            />
                            FULL RIDE
                          </div>
                        </div>
                      ) : null}
                      <div
                        className="headings-style"
                        style={{ color: "#000000", cursor: "pointer" }}
                        onClick={() =>
                          setdropdownDivTestscores(!dropdownDivTestscores)
                        }
                      >
                        TEST SCORES
                        <FaCaretDown style={{ fontSize: 15 }} />
                      </div>
                      {dropdownDivTestscores ? (
                        <div className="col-md-12 m-0 p-0 animated fadeInDown">
                          <div className="d-flex align-items-center justify-content-center">
                            <Box width={200}>
                              <Slider
                                defaultValue={
                                  maxActScoreFilterVal
                                    ? maxActScoreFilterVal
                                    : 20
                                }
                                valueLabelDisplay="auto"
                                min={0}
                                max={50}
                                disabled={!actScoreChecked}
                                onChange={maxactscorevaluechange}
                              />
                            </Box>
                            <Checkbox
                              sx={{
                                border: "#6C63FF",
                                color: "#6C63FF",
                              }}
                              checked={actScoreChecked}
                              onChange={actcheckboxvaluechange}
                            />
                          </div>
                          ACT SCORE{" "}
                          {maxActScoreFilterVal ? maxActScoreFilterVal : 20}
                          <div className="d-flex align-items-center justify-content-center">
                            <Box width={200}>
                              <Slider
                                defaultValue={
                                  maxSatScoreFilterVal
                                    ? maxSatScoreFilterVal
                                    : 1000
                                }
                                valueLabelDisplay="auto"
                                min={0}
                                max={2000}
                                disabled={!satScoreChecked}
                                onChange={maxsatscorevaluechange}
                              />
                            </Box>
                            <Checkbox
                              sx={{
                                border: "#6C63FF",
                                color: "#6C63FF",
                              }}
                              checked={satScoreChecked}
                              onChange={satcheckboxvaluechange}
                            />
                          </div>
                          SAT SCORE{" "}
                          {maxSatScoreFilterVal ? maxSatScoreFilterVal : 1000}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <div
                        className="headings-style"
                        style={{ color: "#000000", cursor: "pointer" }}
                        onClick={() => setdropdownDivMajor(!dropdownDivMajor)}
                      >
                        MAJORS
                        <FaCaretDown style={{ fontSize: 15 }} />
                        {dropdownDivMajor ? (
                          <div className="col-md-12 m-0 p-0"></div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <div
                        className="headings-style"
                        style={{ color: "#000000", cursor: "pointer" }}
                        onClick={() => setdropdownDivTution(!dropdownDivTution)}
                      >
                        TUTION <FaCaretDown style={{ fontSize: 15 }} />
                      </div>

                      {dropdownDivTution ? (
                        <div className="col-md-12 m-0 p-0 animated fadeInDown">
                          <div className="d-flex align-items-center justify-content-center">
                            <Box width={200}>
                              <Slider
                                defaultValue={maxPriceFilterVal}
                                valueLabelDisplay="auto"
                                min={0}
                                max={100000}
                                disabled={!maxPriceChecked}
                                onChange={maxpricevaluechange}
                              />
                            </Box>
                            <Checkbox
                              sx={{
                                border: "#6C63FF",
                                color: "#6C63FF",
                              }}
                              checked={maxPriceChecked}
                              onChange={maxPricecheckboxvaluechange}
                            />
                          </div>
                          <div
                            className=" d-flex flex-column align-items-center justify-content-center"
                            style={{ width: "100%" }}
                          >
                            <div style={{ fontSize: 12, fontWeight: "400" }}>
                              {" "}
                              Max Price
                            </div>
                            <div style={{ fontSize: 24, fontWeight: "800" }}>
                              $ {maxPriceFilterVal ? maxPriceFilterVal : 9999}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <div
                        className="headings-style"
                        style={{ color: "#000000", cursor: "pointer" }}
                        onClick={() =>
                          setdropdownDivTypeofschool(!dropdownDivTypeofschool)
                        }
                      >
                        TYPE OF SCHOOL
                        <FaCaretDown style={{ fontSize: 15 }} />
                      </div>

                      {dropdownDivTypeofschool ? (
                        <div className="col-md-12 m-0 p-0 animated fadeInDown">
                          <h6>
                            <b>PUBLIC/PRIVATE</b>
                          </h6>
                          <div className="row gx-3">
                            <div className="col-md-5">
                              <div className="form-group">
                                <Checkbox
                                  sx={{
                                    border: "#6C63FF",
                                    color: "#6C63FF",
                                  }}
                                />
                                PUBLC
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group">
                                <Checkbox
                                  sx={{
                                    border: "#6C63FF",
                                    color: "#6C63FF",
                                  }}
                                />
                                PRIVATE
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <Checkbox
                                  sx={{
                                    border: "#6C63FF",
                                    color: "#6C63FF",
                                  }}
                                />
                                FOR PROFIT
                              </div>
                            </div>
                          </div>
                          <h6>
                            <b>SIZE OF SCHOOL</b>
                          </h6>
                          <div className="row gx-3">
                            <div className="col-md-4">
                              <div className="form-group">
                                <Checkbox
                                  sx={{
                                    border: "#6C63FF",
                                    color: "#6C63FF",
                                  }}
                                />
                                SMALL
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <Checkbox
                                  sx={{
                                    border: "#6C63FF",
                                    color: "#6C63FF",
                                  }}
                                />
                                MEDIUM
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <Checkbox
                                  sx={{
                                    border: "#6C63FF",
                                    color: "#6C63FF",
                                  }}
                                />
                                LARGE
                              </div>
                            </div>
                          </div>
                          <h6>
                            <b>SINGLE SEX OR CO-ED</b>
                          </h6>
                          <div className="row gx-3">
                            <div className="col-md-6">
                              <div className="form-group">
                                <Checkbox
                                  sx={{
                                    border: "#6C63FF",
                                    color: "#6C63FF",
                                  }}
                                />
                                CO-ED
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <Checkbox
                                  sx={{
                                    border: "#6C63FF",
                                    color: "#6C63FF",
                                  }}
                                />
                                ALL MEN
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <Checkbox
                                  sx={{
                                    border: "#6C63FF",
                                    color: "#6C63FF",
                                  }}
                                />
                                ALL WOMEN
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="pt-1">
                {/* <div className="headings-style">suggested</div>
                <div className="d-flex justify-content-between pt-3">
                  {portfoliComponent(Collage4, "johns opkins")}
                  {portfoliComponent(Collage2, "mit")}
                  {portfoliComponent(Collage3, "dartmount")}
                  {portfoliComponent(Collage4, "stanford")}
                </div> */}
                <div className="pt-5 d-flex justify-content-between">
                  <div className="headings-style">Universities</div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div
                      className="col-md-6 searchbox-style d-flex align-items-center"
                      style={{ height: 20 }}
                    >
                      <div className="col-md-11 p-0 m-0">
                        <input
                          style={{
                            border: 0,
                            width: "100%",
                            height: 20,
                            fontSize: 15,
                            outline: "none"
                          }}
                          type="text"
                          id="search"
                          name="search"
                          placeholder="Search"
                          value={collageSearchKeyword}
                          onChange={(event) => {
                            setcollageSearchKeyword(event.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-1 p-0 m-0 d-flex justify-content-end">
                        <FaSearch
                          style={{ fontSize: 10, cursor: "pointer" }}
                          onClick={() => {
                            searchCollageByKeyword();
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="pr-2"
                      style={{
                        fontSize: 12,
                        color: "#6C63FF",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        addBtnClicked("show all");
                        //samplecall()
                      }}
                    >
                      Show All
                    </div>
                    <div>
                      <Button
                        className="px-2"
                        style={{
                          fontSize: 13,
                          color: "white",
                          fontWeight: "800",
                          backgroundColor: "#6C63FF",
                          // border: "0",
                        }}
                        onClick={() => addBtnClicked("add")}
                      >
                        Add
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="px-2"
                        style={{
                          fontSize: 13,
                          color: "white",
                          fontWeight: "800",
                          backgroundColor: "#6C63FF",
                          // border: "0",
                        }}
                        onClick={() => triggerCallback("filter")}
                      >
                        Filter
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-12 d-flex justify-content-between pt-5">
                  <div
                    className="col-md-1 d-flex align-items-center"
                    onClick={() => {
                      handleScroll();
                      document.getElementById("queryDiv").scrollLeft -= 20;
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <FaCaretLeft style={{ fontSize: 20 }} />
                  </div>
                  <div
                    id="queryDiv"
                    className="col-md-10 p-0 mt-1 d-flex"
                    style={{ overflow: "scroll" }}
                  >
                    {listOfCollagesArr.map((collage) => {
                      return universityComponent("", collage.name);
                    })}
                  </div>
                  <div
                    className="col-md-1 d-flex align-items-center"
                    onClick={() => {
                      handleScroll();
                      document.getElementById("queryDiv").scrollLeft += 20;
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <FaCaretRight style={{ fontSize: 20 }} />
                  </div>
                </div> */}

                <div className="col-md-12 d-flex justify-content-between pt-5">

                  <div
                    className="row m-0"
                    style={{ overflow: "scroll", height: 400 }}
                  >
                    {listOfCollageDetailsArr.map((collage) => {
                      return universityComponent("", collage.name, collage);
                    })}
                    {Math.floor(collageMetaData.total / collageMetaData.per_page) !=
                      collageMetaData.page && collageMetaData.total != 0 ? (
                      <div
                        className="p-2 d-flex justify-content-center"
                        style={{ width: "100%", color: "blue", cursor: "pointer" }}
                        onClick={() => {
                          collagedetailsfromapi(
                            collageMetaData.page + 1,
                            filterStringToSend
                          );
                        }}
                      >
                        Show more
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : finalCompareSecVisible ? (
        <div
          className="col-md-12 row p-2 compare-section"
          style={{ minHeight: height }}
        >
          <div>
            <div className="d-flex flex-1 flex-column justify-content-center align-items-center left-section">
              <div className="p-4 row justify-content-center align-items-center">
                <img
                  className="image-design-uni"
                  src={Uni1}
                  width={250}
                  height={200}
                  alt="designportfolio"
                />
                <div
                  className="py-4 col-md-12 text-center"
                  style={{ fontSize: 16, fontWeight: 800 }}
                >
                  AVERAGE ANNUAL COST
                </div>
                <div
                  className="col-md-12 py-3 row p-0 m-0 mx-auto text-center"
                  style={{ color: "#707070", fontSize: 12 }}
                >
                  <div className="col-md-3"></div>
                  <div className="col-md-3 p-0 text-center">
                    <div>$77,490</div>
                    <div style={{ fontWeight: 900, color: "#000000" }}>
                      Before Aid
                    </div>
                  </div>
                  <div className="col-md-3 p-0 text-center">
                    <div>$31.680</div>
                    <div style={{ fontWeight: 900, color: "#000000" }}>
                      After Aid
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                </div>
                <div className="p-2">
                  <div
                    className="py-1"
                    style={{ fontSize: 15, fontWeight: 600, color: "#000000" }}
                  >
                    ACCEPTANCE RATE 2019
                  </div>
                  <div
                    className="pb-1 text-center"
                    style={{ fontSize: 35, fontWeight: 800, color: "#707070" }}
                  >
                    8%
                  </div>
                  <div
                    className="pb-1 text-center"
                    style={{ fontSize: 15, fontWeight: 800, color: "#000000" }}
                  >
                    GRADUATION RATE 2019
                  </div>
                  <div
                    className="pb-1 text-center"
                    style={{ fontSize: 35, fontWeight: 900, color: "#707070" }}
                  >
                    98%
                  </div>
                  <div
                    className="pb-1 text-center"
                    style={{ fontSize: 10, fontWeight: 500, color: "#6C63FF" }}
                  >
                    Explore Courses
                  </div>
                </div>
              </div>
            </div>
            <div className="seperator"></div>
            <div className="d-flex flex-1 flex-column justify-content-center-align-items-center right-section">
              <div className="p-4 row justify-content-center align-items-center">
                <img
                  className="image-design-uni"
                  src={Uni4}
                  width={250}
                  height={200}
                  alt="designportfolio"
                />
                <div
                  className="py-4 col-md-12 text-center"
                  style={{ fontSize: 16, fontWeight: 800 }}
                >
                  AVERAGE ANNUAL COST
                </div>
                <div
                  className="col-md-12 py-3 row p-0 m-0 mx-auto text-center"
                  style={{ color: "#707070", fontSize: 12 }}
                >
                  <div className="col-md-3"></div>
                  <div className="col-md-3 p-0 text-center">
                    <div>$77,490</div>
                    <div style={{ fontWeight: 900, color: "#000000" }}>
                      Before Aid
                    </div>
                  </div>
                  <div className="col-md-3 p-0 text-center">
                    <div>$31.680</div>
                    <div style={{ fontWeight: 900, color: "#000000" }}>
                      After Aid
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                </div>
                <div className="p-2">
                  <div
                    className="py-1"
                    style={{ fontSize: 15, fontWeight: 600, color: "#000000" }}
                  >
                    ACCEPTANCE RATE 2019
                  </div>
                  <div
                    className="pb-1 text-center"
                    style={{ fontSize: 35, fontWeight: 800, color: "#707070" }}
                  >
                    17.5%
                  </div>
                  <div
                    className="pb-1 text-center"
                    style={{ fontSize: 15, fontWeight: 800, color: "#000000" }}
                  >
                    GRADUATION RATE 2019
                  </div>
                  <div
                    className="pb-1 text-center"
                    style={{ fontSize: 35, fontWeight: 900, color: "#707070" }}
                  >
                    90%
                  </div>
                  <div
                    className="pb-1 text-center"
                    style={{ fontSize: 10, fontWeight: 500, color: "#6C63FF" }}
                  >
                    Explore Courses
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 text-center">
            <Button
              className="px-2"
              style={{
                fontSize: 10,
                color: "#000000",
                fontWeight: "800",
                backgroundColor: "unset",
                border: "0",
              }}
              onClick={() => triggerCallback("reset")}
            >
              Reset
            </Button>
          </div>
        </div>
      ) : (
        <div
          className="college-search-page-container"
          style={{ minHeight: height }}
        >
          <div
            className="p-3"
            style={{
              textTransform: "uppercase",
              color: "#D0D0D0",
              fontWeight: "800",
            }}
          >
            {/* <div
              className="col-md-12 p-0 d-flex justify-content-end"
              onClick={() => setAddUniversitySecVisible(false)}
            >
              <FaTimes style={{ fontSize: 22, cursor: "pointer" }} />
            </div> */}
            <div className="headings-style p-5">Universities</div>
            <div
              className="row m-0"
              style={{ overflow: "scroll", height: 400 }}
            >
              {listOfCollageDetailsArr.map((collage) => {
                return (
                  <div
                    className="col-md-3 p-0 m-0"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {universityAddComponent(
                      "",
                      collage.name,
                      collage.selected,
                      collage.collageId,
                      collage
                    )}
                  </div>
                );
              })}
              {Math.floor(collageMetaData.total / collageMetaData.per_page) !=
                collageMetaData.page && collageMetaData.total != 0 ? (
                <div
                  className="p-2 d-flex justify-content-center"
                  style={{ width: "100%", color: "blue", cursor: "pointer" }}
                  onClick={() => {
                    collagedetailsfromapi(
                      collageMetaData.page + 1,
                      filterStringToSend
                    );
                  }}
                >
                  Show more add
                </div>
              ) : null}
            </div>
          </div>
          <div
            className="text-center"
            style={{ width: "100%", height: "100%", padding: 20 }}
          >
            {compareUniversitySecVisible ? (
              <button
                style={{
                  boxShadow: "unset",
                  outline: "none",
                  width: 140,
                  height: 38,
                  border: "1px solid #6C63FF",
                  borderRadius: 19,
                  backgroundColor: "#6C63FF",
                  color: "#FFFFFF",
                }}
                onClick={() => addBtnClicked("compare")}
              >
                Compare
              </button>
            ) : (
              <button
                style={{
                  boxShadow: "unset",
                  outline: "none",
                  width: 140,
                  height: 38,
                  border: "1px solid #6C63FF",
                  borderRadius: 19,
                  backgroundColor: "#6C63FF",
                  color: "#FFFFFF",
                }}
                onClick={() => {
                  updateListofCollages();
                }}
              >
                Add
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );

  function portfoliComponent(imagesrc = "", title = "") {
    return (
      <div>
        <div>
          <div className="col-md-12 p-0">
            <img
              className="image-design"
              src={imagesrc}
              width={160}
              height={100}
              alt="designportfolio"
            />
          </div>
          <div
            className="d-flex justify-content-center pt-3 text-uppercase"
            style={{ fontSize: 12, fontWeight: 300 }}
          >
            {title}
          </div>
        </div>
      </div>
    );
  }

  function universityComponent(
    imagesrc = Collage2,
    collageName = "Clg Name",
    collageDetails
  ) {
    var logo = require("../../imageAssets/CollegeLogo/addLogo.jpg").default;

    if (collageDetails.logo) {
      logo = collageDetails.logo;
    }
    return (
      <div
        key={collageDetails.collageId}
        className="mb-3 col-md-3 align-items-center justify-content-center"
      >
        <div>
          <div
            className="col-md-12 p-2 d-flex flex-column align-items-center justify-content-center"
            onClick={() => universityDetailsClicked(collageDetails)}
            style={{ height: 200, backgroundColor: "white" }}
          >
            <img
              className="image-design-uni"
              src={logo}
              width={100}
              alt="designportfolio"
            />
            <div
              style={{
                whiteSpace: "pre-line",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}
            >
              {collageName}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function universityShowAllComponent(
    imagesrc = Collage2,
    collageName = "Clg Name",
    collageDetails
  ) {
    var logo = require("../../imageAssets/CollegeLogo/addLogo.jpg").default;

    if (collageDetails.logo) {
      logo = collageDetails.logo;
    }
    return (
      <div
        key={collageDetails.collageId}
        className="m-2 align-items-center justify-content-center"
        style={{ width: 200, height: 200 }}
      >
        <div>
          <div
            className="col-md-12 p-2 d-flex flex-column align-items-center justify-content-center"
            style={{ height: 200, backgroundColor: "white" }}
            onClick={() => universityDetailsClicked(collageDetails)}
          >
            <img
              className="image-design-uni"
              src={logo}
              width={100}
              alt="designportfolio"
            />
            <div
              style={{
                whiteSpace: "pre-line",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}
            >
              {collageName}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function universityAddComponent(
    imagesrc = Collage2,
    collageName = "Clg Name",
    selected = false,
    collgId = "",
    collageDetails
  ) {
    var logo = require("../../imageAssets/CollegeLogo/addLogo.jpg").default;

    if (collageDetails.logo) {
      logo = collageDetails.logo;
    }
    return (
      <div
        key={collageDetails.collageId}
        className="m-2 align-items-center justify-content-center"
        style={{ width: 200, height: 200, cursor: "pointer" }}
      >
        <div
          onClick={() => {
            updatecollageselection(collgId);
          }}
        >
          <div
            className="col-md-12 p-2 d-flex flex-column align-items-center justify-content-center"
            style={{ height: 200, width: 200, backgroundColor: "white" }}
          >
            <img
              className="image-design-uni"
              src={logo}
              width={80}
              alt={collageName}
            />
            <div
              style={{
                whiteSpace: "pre-line",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}
            >
              {collageName}
            </div>
            {selected ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: "#00000030",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                }}
              >
                <img src={TickWhite} height={25} alt="tickimg" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
};

export default CollegeSearchScreen;
