import React, {useEffect, useState} from 'react'
import axios from 'axios';
import { MW_URL } from "../../../config";


import {FaSearch} from "react-icons/fa";
import {IoIosPaperPlane} from 'react-icons/io';
import {FiPaperclip} from 'react-icons/fi';
import {BiMessageAltDots} from 'react-icons/bi'
import LoadingAnimation from "../../../utils/loadingAnimation";
import moment from "moment";
import StudentProfile from '../portals/StudentImage';



const Inbox = () => {

    const [chatList, setChatList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [studentList, setStudentList] = useState([]);
    const [studentListTemp, setStudentListTemp] = useState([]);

    const [chat, setChat] = useState("");
    const [studEmailSelected, setStudEmailSelected] = useState("");
    const [studNameSelected, setStudNameSelected] = useState("");
    const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
    const [searchStudentKey, setSearchStudentKey] = useState([])



    useEffect(() => {
        getlistofusersFromDb()
    }, []);

    useEffect(() => {
        getAllChats2()
    }, [isUseEffectCallRequired]);

    const createUniquieId = () => {
        const head = Date.now().toString(36);
        const tail = Math.random().toString(36).substr(2);
        return head + tail;
    };
  

    // const getAllChats = () => {
    //     console.log(JSON.parse(localStorage.getItem("userData")).email, "email")
    //     axios
    //         // .post(`${MW_URL}` + "getChatsByRecipientId", {
    //         //     recipientId : JSON.parse(localStorage.getItem("userData")).email,
    //         // })
    //         .post(`${MW_URL}` + "getChatsBysenderId", {
    //             senderId : JSON.parse(localStorage.getItem("userData")).email,
    //         })
    //         .then((getChatsResponse) => {

    //             setChatList(getChatsResponse.data.reverse());
    //             console.log(getChatsResponse.data, "ya");

    //             setIsUseEffectCallRequired(false)
    //             // console.log(newData, "new")
    //         })
    // }

    const getAllChats2 = () => {
        const recipientId = JSON.parse(localStorage.getItem("userData")).email;
        const senderId = JSON.parse(localStorage.getItem("userData")).email; // Use the same user for sender and recipient for this example
    
        const getChatsByRecipient = axios.post(`${MW_URL}` + "getChatsByRecipientId", { recipientId });
        const getChatsBySender = axios.post(`${MW_URL}` + "getChatsBysenderId", { senderId });

        console.log(getChatsByRecipient, "getChatsByRecipient")
        console.log(getChatsBySender, "getChatsBySender")
    
        Promise.all([getChatsByRecipient, getChatsBySender])
            .then(([recipientResponse, senderResponse]) => {
                // Concatenate the arrays and sort by updatedDate
                const combinedChats = recipientResponse.data.concat(senderResponse.data);
                const sortedChats = combinedChats.sort((a, b) => new Date(a.updatedDate) - new Date(b.updatedDate));
    
                setChatList(sortedChats);
                console.log(sortedChats, "sortedChats")
                setIsUseEffectCallRequired(false);
                // setIsLoading(false)
            })
            .catch(error => {
                console.error("Error fetching chat data:", error);
                // setIsLoading(false)
            });
    };

    // get users list from db and filter out all students to show in chat side list
    const getlistofusersFromDb = () => {
        setIsLoading(true);
        axios
        .post(`${MW_URL}` + "users", {})
        .then((getUsersResponse) => {
            if (
            getUsersResponse &&
            getUsersResponse.data &&
            getUsersResponse.data[0]
            ) {
                axios
                .post(`${MW_URL}` + "getUpdates", {
                  recipientId: JSON.parse(localStorage.getItem("userData")).email,
                  senderId: false,
                })
                .then((getUpdatesResponse) => {   
                    var userList = [];
                    for (var i = 0; i < getUsersResponse.data.length; i++) {
                        var currentObj = getUsersResponse.data[i];
                        var currentDate = new Date();
                        currentDate = currentDate.setDate(currentDate.getDate() - 2);
                        if (
                        currentObj.counsellorId ===
                        JSON.parse(localStorage.getItem("userData")).email
                        ) {
                        userList.push({
                            key: currentObj?.email,
                            username: currentObj?.username,
                            email: currentObj?.email,
                            userType: currentObj?.userType,
                            major: currentObj?.major,
                            updates: getUpdatesResponse?.data,
                            updatesNum: getUpdatesResponse?.data.filter(
                            (updte) =>
                                updte.senderId == currentObj?.email &&
                                moment(updte.updatedDate, "YYYY-MM-DD").isSameOrBefore(
                                new Date(),
                                "day"
                                ) &&
                                moment(updte.updatedDate, "YYYY-MM-DD").isSameOrAfter(
                                currentDate,
                                "day"
                                )
                            ).length,
                    }) 
                }
            }
                console.log("userList", userList);

                setStudentList(userList);
                setStudentListTemp(userList)
                handleChangeCounsellorSelect(userList[0])
                setIsLoading(false)
                })
                .catch((err) => {
                  console.log("err >> ", err);
                });
            }
          })
        .catch((err) => {
            console.log("err >> ", err);
        });
    };

     // handle change on the student select
     const handleChangeCounsellorSelect = (stud) => {
        setStudEmailSelected(stud?.email);
        setStudNameSelected(stud?.username);
        console.log(stud, "em", 'name')
      };

    const onSubmitChat = () => {
          var queryDataTosend = {
            chatId: createUniquieId(),
            recipientId: studEmailSelected,
            recipientName: studNameSelected,
            senderId: JSON.parse(localStorage.getItem("userData")).email,
            senderName: JSON.parse(localStorage.getItem("userData")).username,
            message: chat,
            updateId: createUniquieId(),
            updateMessage:
              JSON.parse(localStorage.getItem("userData")).username +
              " added a chat",
          };
          console.log("queryDataTosend", queryDataTosend);
          axios
          .post(`${MW_URL}` + "addChat", queryDataTosend)
          .then((response) => {
            var status = "NA";
            if (response && response.data && response.data.STATUS)
                status = response.data.STATUS;
                console.log(response.data, "data")
            if (status.toUpperCase() === "SUCCESS") {
                setIsUseEffectCallRequired(true)
                setChat("")
                console.log("completed")
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
    }

    const searchSelectedStudent = () => {
        if (searchStudentKey?.length >= 2) {
            const lowerCaseSearchKey = searchStudentKey?.toLowerCase();
            const filteredStudents = studentListTemp?.filter((student) => {
              const studentName = student?.username.toLowerCase();
              return studentName.includes(lowerCaseSearchKey);
            });
            setStudentList(filteredStudents);
          } else {
            setStudentList(studentListTemp);
          }

    }

    // const getLastChat = () => {
    //     console.log("xxxxx")
    //     const filteredChats = chatList?.filter(chat => {
    //         return (
    //           chat?.senderId === JSON.parse(localStorage.getItem("userData")).email &&
    //           chat?.recipientId === "amal@gmail.com"
    //         );
    //       });
    //     //   console.log(chatList, "newChatlist")
    //       return filteredChats && filteredChats.length > 0 ? filteredChats[filteredChats.length - 1].message : '';
    // }

  return (
    <div className='councInbox'>
        {isLoading ? <LoadingAnimation isVisible={true} /> : null}
        <div className='row'>
            <div className='col-4'>
                <div className='councInboxLeftSec'>
                    <p className='counStudHeadText p-3'>Inbox</p>

                    <div className='colSearchBox m-3 ' style={{"width":"320px"}}>
                        <input onChange={(e) => setSearchStudentKey(e.target.value)} type='search' placeholder='Search Inbox' />
                        <div onClick={() => searchSelectedStudent()} className='px-2 py-1'><FaSearch /></div>
                    </div>

                    <div className='userChatList'>
                        {studentList.map((obj, index) => (
                            <div onClick={() => handleChangeCounsellorSelect(obj)} key={index} class="row m-2 ">
                                <div className=' m-2'>
                                    <StudentProfile studentId={obj?.email} className="counStudBgCircle" />
                                </div>
                                <div class="col-7 py-1 px-0">
                                    <div className=''>
                                        <p className='counStudNormalText m-0'>{obj?.username}</p>
                                        <p className='colLightText m-0'>last message</p>
                                    </div>
                                </div>
                                <div class="col py-2">
                                    <div className='d-flex justify-content-end'>
                                        <p className='colLightText2'>12:56</p>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {/* <div class="row m-2 councInboxStudSel">
                            <div class="counStudBgCircle m-2">
                            </div>
                            <div class="col-7 py-1 px-0">
                                <div className=''>
                                    <p className='counStudNormalText m-0'>Student Name</p>
                                    <p className='colLightText m-0'>Lorem Ipsum is simply dummy</p>
                                </div>
                            </div>
                            <div class="col py-2">
                                <div className='d-flex justify-content-end'>
                                    <p className='colLightText2'>12:56</p>
                                </div>                            </div>
                        </div>

                        <div class="row m-2 ">
                            <div class="counStudBgCircle m-2">
                            </div>
                            <div class="col-7 py-1 px-0">
                                <div className=''>
                                    <p className='counStudNormalText m-0'>Student Name</p>
                                    <p className='colLightText m-0'>Lorem Ipsum is simply dummy</p>
                                </div>
                            </div>
                            <div class="col py-2">
                                <div className='d-flex justify-content-end'>
                                    <p className='colLightText2'>12:56</p>
                                </div>
                            </div>
                        </div> */}


                    </div>

                </div>
            </div>
            <div className='col-8'>
                <div className='councInboxRightSec p-3'>

                    <div className='d-flex my-2'>
                        <div className=' m-2'>
                            <StudentProfile studentId={studEmailSelected} className="counStudBgCircle" />
                        </div>
                        <div className='my-1'>
                            <p className='counInboxBoldText m-0'>{studNameSelected}</p>
                            <div className='d-flex '>
                                <p className='colLightText m-0'>Online</p>
                                <div className='counOnlineCircle m-1'></div>
                            </div>
                        </div>
                    </div>

                    <div className='chatArea'>

                        {chatList?.map((obj, index) => {
                            if(obj.recipientId === studEmailSelected && obj.senderId === JSON.parse(localStorage.getItem("userData")).email ){
                                return(
                                    <div key={index} className='d-flex justify-content-end m-3'>
                                        <div className='messageWrapOutSm p-2'>
                                            <div className='d-flex justify-content-between'>
                                                <p className='colLightText'>{obj.message}</p>
                                                <p className='queryLightText'>{moment(obj.updatedDate).format("HH:mm")}</p>
                                            </div>
                                        </div>
                                    </div> 
                                )
                            }if(obj.senderId === studEmailSelected){
                                return(
                                    <div key={index} className='d-flex justify-content-start m-3'>
                                        <div className='messageWrapInSm p-2'>
                                            <div className='d-flex justify-content-between'>
                                                <p className='colLightText'>{obj.message}</p>
                                                <p className='queryLightText'>{moment(obj.updatedDate).format("HH:mm")}</p>
                                            </div>
                                        </div>
                                    </div> 
                                )
                            }
                        })}

                        {/* {chatList?.map((obj, index) => {
                            if(obj.recipientId === studEmailSelected){
                                return(
                                    <div className='d-flex justify-content-end m-3'>
                                        <div className='messageWrapOutSm p-2'>
                                            <div className='d-flex justify-content-between'>
                                                <p className='colLightText'>{obj.message}</p>
                                                <p className='queryLightText'>{moment(obj.updatedDate).format("HH:mm")}</p>
                                            </div>
                                        </div>
                                    </div> 
                                )
                            }
                        })} */}
                        
                        {/* <div className='d-flex justify-content-end m-3'>
                            <div className='messageWrapOutLg'></div>
                        </div> */}

                    </div>

                    <div className='inboxTextArea my-3'>
                        <input value={chat} onChange={(e) => setChat(e.target.value)} type='text' placeholder='Write your message' />
                        <div className='d-flex'>
                            <div className='councInboxDoc mx-2'><FiPaperclip /></div>
                            {chat?.length > 0 ? (
                                <div onClick={() => onSubmitChat()} className='councInboxSend'><IoIosPaperPlane fill='#FFFFFF' /></div> 
                            ) : (
                                <div className='councInboxSend'><BiMessageAltDots fill='#FFFFFF' /></div>
                            )}
                        </div>
                        
                    </div>


                </div>
            </div>
        </div>
    </div>
  )
}

export default Inbox