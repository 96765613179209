import React, { useEffect, useState, useRef, useCallback } from "react";
import moment from "moment";
import axios from 'axios';
import {BsPlusCircle} from 'react-icons/bs'
import { MW_URL } from "../../../../config";
import ModalComponent from "../../../../utils/modalComponent";
import {AiTwotoneDelete} from 'react-icons/ai'
import LoadingAnimation from "../../../../utils/loadingAnimation";
import AlertModal from "../../../widgets/AlertModal";
import ValidateForm from "../../../../utils/formValidation";




const Todo = ({
  listOfSelectedCollagesArr,
  setSelectedCollageId,
  selCollageId,
  selCollage,
  setSelCollage
}) => {

    var todayDate = moment().format('DD MMM YYYY')

    const [isLoading, setIsLoading] = useState(false);
    const [todolist, settodolist] = useState([]);
    const [addTodoModalVisible, setaddTodoModalVisible] = useState(false);
    const [showSaveButtonForTodoUpdate, setshowSaveButtonForTodoUpdate] =
    useState(false);
    const [todoInTextarea, settodoInTextarea] = useState("");
    const [todolistMap, settodolistMap] = useState(null);
    const [selectedTodo, setSelectedTodo] = useState();
    const [showTodoDelete, setShowTodoDelete] = useState(false);

  

    useEffect(() => {
        getAllEssays();   
    }, [selCollageId]);

    const getAllEssays = () => {
        setIsLoading(true)
        var selectedCollages = []
        axios.post(`${MW_URL}` + "getSelectedListOfCollages", {
            email: JSON.parse(localStorage.getItem("userData")).email,
        }).then((getSelectedListOfCollagesresponse) => {
            if (getSelectedListOfCollagesresponse.data[0].todoList) {
                var mapToSet = new Map(
                  Object.entries(getSelectedListOfCollagesresponse.data[0].todoList)
                );
                selectedCollages.map((colgSelected) => {
                  if (mapToSet.get(colgSelected.collageId) == null)
                    mapToSet.set(colgSelected.collageId, []);
                });
                settodolist(mapToSet.get(selCollageId));
                settodolistMap(mapToSet);
                setIsLoading(false)
              } else {
                let todoMap = new Map();
                selectedCollages.map((colgSelected) => {
                  if (todoMap.get(colgSelected.collageId) == null)
                    todoMap.set(colgSelected.collageId, []);
                });
                settodolistMap(todoMap);
                setIsLoading(false)
              }
        })
    }

    const createUniquieId = () => {
        const head = Date.now().toString(36);
        const tail = Math.random().toString(36).substr(2);
        return head + tail;
      };

    function addtodoList() {
        setaddTodoModalVisible(false);
        const currentCollageTodoList = todolistMap?.get(selCollageId) || [];
  
        const newTodoItem = {
          id: createUniquieId(), // You need to define createUniquieId() function
          title: todoInTextarea,
          selected: false
        };

        const updatedCollageTodoList = [...currentCollageTodoList, newTodoItem];

        const updatedTodolistMap = new Map(todolistMap);
        updatedTodolistMap.set(selCollageId, updatedCollageTodoList);

        console.log(updatedTodolistMap, "updated");

        axios
          .post(`${MW_URL}` + "addtodoList", {
            todoList: Object.fromEntries(updatedTodolistMap),
            email: JSON.parse(localStorage.getItem("userData")).email
          })
          .then((response) => {
            settodolistMap(updatedTodolistMap);
            settodoInTextarea("");
            setIsLoading(false);
            getAllEssays();
          })
          .catch((err) => {
            console.error(err);
          });
      }

    const addTodoModalHandler = (dataObject) => {
        if (dataObject.type === "cancel") {
          setaddTodoModalVisible(false);
        } else if (dataObject.type === "submit" && ValidateForm.inputFieldMandatoryCheck("todoContainer", false)) {
          addtodoList();
        }
      };

    const updateTodoList = (todoSelected) => {
        console.log("todoSelected", todoSelected);
        setshowSaveButtonForTodoUpdate(true);
        settodolist(
          todolist.map((todo) => {
            console.log(todo, "todo")
            if (todo.id == todoSelected.id) {
              if (todo.selected) {
                return {
                  ...todo,
                  selected: false,
                };
              } else {
                return {
                  ...todo,
                  selected: true,
                };
              }
            } else {
              return {
                ...todo,
                selected: todo.selected,
              };
            }
          })
        );
      };

    const handleTodoDelete = (todoSelected) => {
        setSelectedTodo(todoSelected)
        setShowTodoDelete(true)
    }
    
    const TodoDeleteBtnClick = () => {
        setshowSaveButtonForTodoUpdate(true);
        const newList =
          todolist.filter((todo) => todo.id !== selectedTodo.id)
        settodolist(newList)
        setShowTodoDelete(false)
        return {
          ...newList,
        };
    }

    const edittodolist = async (todolistforselectedcollage) => {
      setIsLoading(true);
      // console.log("todolistforselectedcollage", todolistforselectedcollage);
      var todolistToPass = new Map(
        todolistMap.set(selCollageId, todolistforselectedcollage)
      );
      axios
        .post(`${MW_URL}` + "addtodoList", {
          todoList: Object.fromEntries(todolistToPass),
          email: JSON.parse(localStorage.getItem("userData")).email,
        })
        .then((response) => {
          settodolistMap(
            new Map(todolistMap.set(selCollageId, todolistforselectedcollage))
          );
          setshowSaveButtonForTodoUpdate(false);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("err");
        });
    };

    const handleCollageChange = (e) => {
      const selectedValue = e.target.value;
      setSelectedCollageId(selectedValue)
      const selectedCollageObj = listOfSelectedCollagesArr.find(obj => obj.collageId === selectedValue);
  
      if (selectedCollageObj) {
        setSelCollage(selectedCollageObj.name);
        // Now you can use the selectedCollageName as needed
        // ... your code here ...
      }
    }



  return (
    <div className='dashStats'>
      {isLoading ? <LoadingAnimation isVisible={true} /> : null} 
        <h3 className='dashHead3'>To-do </h3>
        <div className='d-flex'>
            <div className="col-sm-6 d-flex justify-content-start">
                <p className='dashNormalText'>{todayDate}</p>
                
            </div>

            <div className="col-sm-6 d-flex justify-content-end">

                {showSaveButtonForTodoUpdate && (
                    <div
                      style={{ color: "red", cursor: "pointer" }}
                      className="mx-2"
                      onClick={() => {
                        edittodolist(todolist);
                      }}
                    >
                    save
                  </div>
                )}
                <select onChange={handleCollageChange} className="deadlineSelect mb-2 mx-2">
                  {listOfSelectedCollagesArr < 1 && (<option>No Collages Selected</option>)}

                { listOfSelectedCollagesArr.map((obj, index) => (
                    <option key={index} defaultValue={obj.collageId} value={obj.collageId} >{obj.name}</option>
                ))}
                </select>
                {listOfSelectedCollagesArr.length > 0 && (
                    <div onClick={() => setaddTodoModalVisible(true)} ><BsPlusCircle className='addTodoBtn' /></div>
                )}
            </div>
        </div>

        <div className="updateSec">
            {todolist?.length > 0 ? (
                <div>
                    {todolist?.map((obj, index) => (
                    <div key={index} className='dashTodoCollage'>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className='dashNormalText2'>{selCollage} 
                                    {obj.selected ? (<span className='dashSpanMutedText'>Done</span>) 
                                        :(<span className='dashSpanText'>In Progress</span> )
                                    }
                                </div>
                                <div className='dashLightText'>{obj.title}</div> 
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                {obj.selected ? (<button onClick={() => updateTodoList(obj)} className='todoSetBtn'>
                                    Set In progress
                                </button>) 
                                    :(<button onClick={() => updateTodoList(obj)} className='todoSetBtn'>
                                    Set us done
                                </button> )
                                }
                                <div onClick={() => handleTodoDelete(obj)} className="m-1">
                                  <AiTwotoneDelete fill="#bc4749"/>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            ) : (<p className="dashNormalText2 noDataText"> No to-do</p>)}
            <div>
            </div>
        </div>

        <ModalComponent
        isVisible={addTodoModalVisible}
        mainHeading="Add todo"
        cancelButtonText="Cancel"
        submitButtonText="Add"
        callback={addTodoModalHandler}
      >
        <div className="form-group" id="todoContainer">
          <textarea
            className="essayNormalText1 queryTextInbox input input_data mandatoryField"
            value={todoInTextarea}
            placeholder="Type your todo here"
            onChange={(event) => {
              settodoInTextarea(event.target.value);
            }}
            id="w3review"
            name="w3review"
            rows="4"
            cols="42"
          >
            Write a todo
          </textarea>
          <div className="errorDesc"></div>
        </div>
      </ModalComponent>

      <AlertModal
          show={showTodoDelete}
          onHide={() => setShowTodoDelete(false)}
          title='Alert!'
          message='Are you sure you want to delete this todo?'
          onCancel={() => setShowTodoDelete(false)}
          onConfirm={() => TodoDeleteBtnClick()}
      />

    </div>
  )
}

export default Todo