
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal,ModalBody} from 'reactstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import {AiOutlineCloseCircle} from 'react-icons/ai';

// import AnimationWrapper from './animationWrapper.js';

class ModalComponent extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    triggerCallback = (receivedType) => {
        if (receivedType === "submit") {
                        this.props.callback({
                            type: receivedType,
                            uniqueModalReferenceId: this.props.uniqueModalReferenceId
                        })
        } else {
            this.props.callback({
                type: receivedType,
                uniqueModalReferenceId: this.props.uniqueModalReferenceId
            })
        }
    }

    render() {
        return (
            <div>
                {this.props.isVisible ? <div className="commonPopupFormBackDrop animated fadeIn"></div> : null}
                {/* <AnimationWrapper.cssTransition isVisible={this.props.isVisible}> */}
                <Modal isOpen={this.props.isVisible} size={this.props.modalSize} centered>
                    <div className='d-flex justify-content-between '>
                        <div className="essayHeadText4 m-2">{this.props.mainHeading}</div>
                        <div className='m-2'>
                            <AiOutlineCloseCircle onClick={() => this.triggerCallback("cancel")} className='essayCloseBtn'/>
                        </div>
                    </div>
                        <ModalBody>
                        <div className="py-2 col-md-12 popupOuterMostContainer" style={{textAlign:'center'}} id={this.props.uniqueModalReferenceId}>
                        <div style={{width: '100%',overflowY: 'auto',height: '100%',overflowX: 'hidden'}}>
                            {this.props.children}
                        </div>
        
                        {this.props.isButtonGroupRequired ?
                                <div className="buttonGroup d-flex flex-row justify-content-center">
                                    {/* {this.props.isCancelButtonVisible ?
                                        <button type="button" className="cancelButton mr-2" onClick={() => this.triggerCallback("cancel")}>{this.props.cancelButtonText}</button>
                                        : null} */}
                                   
                                    {/* {this.props.isSubmitButtonVisible ?
                                        <button type="button" className="submitButton ml-2" onClick={() => this.triggerCallback("submit")}>{this.props.submitButtonText}</button>
                                        : null} */}
                                </div>
                                : null}
                        </div>
                        </ModalBody>

                        {this.props.isSubmitButtonVisible && (
                        <div className='modalSaveBtn m-3'>
                            <button type="button" className="essayBtn2 ml-2" onClick={() => this.triggerCallback("submit")}>{this.props.submitButtonText}</button>
                         </div>  )}             

                        
                         {/* <div className='modalSaveBtn mr-3'>
                            <button className='essayBtn2'>Send</button>
                        </div>  */}
                    </Modal>
                {/* </AnimationWrapper.cssTransition> */}
            </div>
        );
    }
}

ModalComponent.defaultProps = {
    isVisible: false,
    isCancelButtonVisible: true,
    isSubmitButtonVisible: true,
    cancelButtonText: "Cancel",
    submitButtonText: "OK",
    children: ">>noChildrensToShow",
    mainHeading: ">>emptyMainHeading",
    uniqueModalReferenceId: "NA",
    isButtonGroupRequired: true,
    contentPartBackgroundColor:'#FFFFFF',
    modalSize:"sm"
}

ModalComponent.propTypes = {
    isVisible: PropTypes.bool,
    isCancelButtonVisible: PropTypes.bool,
    isSubmitButtonVisible: PropTypes.bool,
    cancelButtonText: PropTypes.string,
    submitButtonText: PropTypes.string,
    mainHeading: PropTypes.string,
    callback: PropTypes.func,
    uniqueModalReferenceId: PropTypes.string,
    isButtonGroupRequired: PropTypes.bool,
    contentPartBackgroundColor:PropTypes.string,
    modalSize:PropTypes.string,
}

const mapStateToProps = (state, ownProperties) => {
    return {
        locales: state.locales
    }
}

const ModalComponentContainer = connect(
    mapStateToProps
)(ModalComponent)

export default ModalComponentContainer;
