import React, { useEffect, useState, useRef } from 'react'
import { VscBook } from "react-icons/vsc";
import { GiGraduateCap } from "react-icons/gi";
import {BsCheck2Square} from "react-icons/bs";
import {AiOutlinePercentage} from "react-icons/ai"
import {BiSolidNetworkChart} from "react-icons/bi"
import {BiSolidEdit} from "react-icons/bi"
import Nav from 'react-bootstrap/Nav';
import DocumentSec from './documentSec';
import EssaySec from './EssaySec';
import TimelineSec from './TimelineSec';
import AluminiSec from './AluminiSec';
import GallerySec from './GallerySec';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Modal from 'react-bootstrap/Modal';
import {AiOutlineCloseCircle} from 'react-icons/ai';



const CollegeRight = ({
    selectedCollege,
    updateListofCollages,
    setlistOfSelectedCollageDetailsArr,
    listOfSelectedCollageDetailsArr,
    listOfSelectedCollagesIds,
    setlistOfCollageDetailsArr,
    listOfCollageDetailsArr,
    setIsUseEffectCallRequired,
    }) => {

    const [tab, setTab] = useState("1")
    // const [selectedCollege, setUniversityDetails] = useState(selectedCollege);
    var [data, setData] = useState(null);
    const [scoreType, setScoreType] = useState("satscore");
    const [scoreoptions, setScoreOptions] = useState([]);
    const [showChart, setShowChart] = useState(false)
    ChartJS.register(ArcElement, Tooltip, Legend);


    React.useEffect(()=>{
        setScoreOptions([
          { value: 'satscore', name: 'SAT Score' ,id:'satscore'},
          { value: 'actscore', name: 'ACT Score' ,id:'actscore'}            
      ]);
      },[]) 

    React.useEffect(()=>{ if (scoreType == 'satscore') {
        if(selectedCollege){
        if (selectedCollege?.satScore?.critical_reading||
          selectedCollege?.satScore?.math||
          selectedCollege?.satScore?.writing) {
          setData({
            labels: ['critical reading', 'math', 'writing'],
            datasets: [
              {
                label: '# of Votes',
                data: [selectedCollege?.satScore?.critical_reading,
                selectedCollege?.satScore?.math,
                selectedCollege?.satScore?.writing],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1,
              },
            ],
          });
        }
        else{
          setData(null)
        }
      }
      }
      else if (scoreType == 'actscore') {
        if (selectedCollege) {
            console.log(selectedCollege, "selectedCollege")
          if(selectedCollege?.actScore?.cumulative||
            selectedCollege?.actScore?.english||
            selectedCollege?.actScore?.math||
            selectedCollege?.actScore?.writing){
         setData({
            labels: ['cumulative', 'english', 'math', 'writing'],
            datasets: [
              {
                label: '# of Votes',
                data: [selectedCollege?.actScore?.cumulative,
                selectedCollege?.actScore?.english,
                selectedCollege?.actScore?.math,
                selectedCollege?.actScore?.writing,
                ],
                backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                ],
                borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 0.2)',
                ],
                borderWidth: 1,
              },
            ],
          });
        }
        else{
          setData(null)
        }
      }
      }
      },[scoreType, selectedCollege])

    console.log(selectedCollege, "data")


    const handleCollageSelectClick = (id) => {
        updatecollageselection(id)

        // console.log(listOfCollageDetailsArr, "col Ww")
    }


    function updatecollageselection(id) {
        // console.log("check:", id);
        setlistOfCollageDetailsArr((prevList) => {
          return prevList.map((item) => {
            if (item.collageId === id) {
              if (item.selected) {
                setlistOfSelectedCollageDetailsArr((prevSelectedList) =>
                  prevSelectedList.filter((selectedItem) => selectedItem.collageId !== id)
                );
                // console.log("Collage deselected:", id);
              } else {
                setlistOfSelectedCollageDetailsArr((prevSelectedList) => [...prevSelectedList, item]);
                // console.log("Collage selected:", id);
              }
              return {
                ...item,
                selected: !item.selected,
              };
            }
            return item;
          });
        });
    }
      

  return (
    <div >
        <div className=' mb-0'>
            <div className='colCoverImage' style={{"backgroundImage":"url('https://uiservicecenter.uiowa.edu/sites/uiservicecenter.uiowa.edu/files/styles/ultrawide__1024_x_439/public/2020-09/2018_09_12-Drone%20Campus%20Images-0001-HDR.jpg?h=19ad3fdd&itok=AMhmE0fL')"}} ></div>
            <div className='row my-3'>
                <div className='col-9'>
                    <div className='row'>
                        <div className='col-9'>
                            <h3 className='colHeadText'>{selectedCollege.name}</h3>
                        </div>
                        <div className='col-3'>
                            {listOfSelectedCollageDetailsArr.some((collage) => collage.collageId === selectedCollege.collageId) ? (
                                <button onClick={() => handleCollageSelectClick(selectedCollege.collageId)} className='colSelectedBtn'>Selected</button>
                            ): (
                                <button onClick={() => handleCollageSelectClick(selectedCollege.collageId)} className='colSelectBtn'>Select</button>
                            )}

                        </div>
                    </div>
                    <p className='colNormalText2'>{selectedCollege.description}</p>
                </div>
                <div className='col-3'>
                    {selectedCollege.logo? (<img className='colLogo' src={selectedCollege.logo} alt='logo' />) 
                    : (<img className='colLogo' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS34wans8tigXl6AjCPkZCAJGnnNqC_9xBrQi--mGsP&s" alt="logo" />)}
                    
                </div>
            </div>
        </div>

        <p className='statHead m-0'>Stats</p>

        
        <div className="row">

            {selectedCollege?.tutionOutOfState && (
            <div className="col-3 statBox2 m-2">
                <div className='row'>
                    <div className='col-3 '><VscBook className='statFont' /></div>
                    <div className='col-9'>
                        <p className='colLightText m-0'>Before AID</p>
                        <p className='statText'>${selectedCollege?.tutionOutOfState}</p>
                    </div>
                </div>
            </div> )}

            {selectedCollege?.tutionInState && (
            <div className="col-3 statBox2 m-2">
                <div className='row'>
                    <div className='col-3 '><GiGraduateCap className='statFont' /></div>
                    <div className='col-9'>
                        <p className='colLightText m-0'>After AID</p>
                        <p className='statText'>$ {selectedCollege?.tutionInState}</p>
                    </div>
                </div>
            </div> )}
            
            {selectedCollege?.admissions?.overall && (
            <div className="col-3 statBox2 m-2">
                <div className='row'>
                    <div className='col-3 '><BsCheck2Square className='statFont' /></div>
                    <div className='col-9'>
                        <p className='colLightText m-0'>Acceptance</p>
                        <p className='statText'>{selectedCollege?.admissions?.overall}</p>
                    </div>
                </div>
            </div> )}

            <div className="col-3 statBox2 m-2">
                <div className='row'>
                    <div className='col-3 '><AiOutlinePercentage className='statFont' /></div>
                    <div className='col-9'>
                        <p className='colLightText m-0'>M-F Ratio</p>
                        <p className='statText'>3:1</p>
                    </div>
                </div>
            </div>

            <div className='col-3 statBox2 m-2'>
                <div className='row'>
                    <div className='col-3 '><BiSolidNetworkChart className='statFont2' /></div>
                    <div className='col-9'>
                        <p className='colLightText m-0'>First Gen</p>
                        <p className='statText'>30%</p>
                    </div>
                </div>
            </div>
            <div className='col-3 statBox2 m-2'>
                <div className='row'>
                    <div className='col-3 '><BiSolidEdit className='statFont2' /></div>
                    <div className='col-9'>
                        <p className='colLightText m-0' style={{minWidth:"110px", "overflow":"hidden"}} >Test Requirement</p>
                        <p className='statText'>REQ.</p>
                    </div>
                </div>
            </div>

              {data && (
                <div className='m-2'>
                    <button className='essayBtn1' onClick={()=> setShowChart(true)}>Chart</button>
                </div>
              )}

        
        </div>


        <Modal show={showChart} onHide={() => setShowChart(false)}>
              <div className='d-flex justify-content-between '>
                <p className='essayHeadText4 m-3 p-2 '>Chart</p>
                <div className='m-2'>
                    <AiOutlineCloseCircle onClick={() => setShowChart(false)} className='essayCloseBtn'/>
                </div>
              </div>


            <div className='essayModal' >

              <div className="m-2 w-50">
                  <select
                      name="Deadline"
                      style={{ border: "none", width: "100%" }}
                      id="deadline"
                      className="opacity"
                      onChange={(e) => setScoreType(e.target.value)}
                      value={scoreType}
                  >
                  {scoreoptions.map((col) => {
                      return (
                      <option key={col.id} value={col.id}>
                          {col.name}
                      </option>
                      );
                  })}
                  </select>
              </div>
      
                <div style={{"height":"52px"}}>
                      <Pie data={data} />
                </div>
            </div>
        </Modal>
       

        <div className=' mt-1' style={{"height":"152px"}}>
            {/* diff tab section */}
            <div className='colTab'>
                <button onClick={() => setTab("1")} className={tab === "1" ? 'colTabBtnSel': 'colTabBtn'}>Documents</button>
                <button onClick={() => setTab("2")} className={tab === "2" ? 'colTabBtnSel': 'colTabBtn'}>Essays</button>
                <button onClick={() => setTab("3")} className={tab === "3" ? 'colTabBtnSel': 'colTabBtn'}>Timeline</button>
                <button onClick={() => setTab("4")} className={tab === "4" ? 'colTabBtnSel': 'colTabBtn'}>Alumini</button>
                <button onClick={() => setTab("5")} className={tab === "5" ? 'colTabBtnSel': 'colTabBtn'}>Gallery</button>
            </div>
            {tab === '1' && (<DocumentSec selectedCollege={selectedCollege} />)}
            {tab === '2' && (<EssaySec />)}
            {tab === '3' && (<TimelineSec />)}
            {tab === '4' && (<AluminiSec />)}
            {tab === '5' && (<GallerySec />)}
        </div>
    </div>
  )
}

export default CollegeRight