import * as React from "react";

const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={65}
    height={65}
    viewBox="0 0 65 65"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1={0.5}
        x2={0.5}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#9086f9" />
        <stop offset={1} stopColor="#756bfe" />
      </linearGradient>
    </defs>
    <circle
      id="Ellipse_89"
      data-name="Ellipse 89"
      cx={32.5}
      cy={32.5}
      r={32.5}
      fill="url(#linear-gradient)"
    />
    <g
      id="Icon_feather-bell"
      data-name="Icon feather-bell"
      transform="translate(17.745 22.865) rotate(-16)"
    >
      <path
        id="Path_302"
        data-name="Path 302"
        d="M19.006,7.8A7.705,7.705,0,0,0,11.4,0,7.705,7.705,0,0,0,3.8,7.8c0,9.1-3.8,11.706-3.8,11.706H22.808s-3.8-2.6-3.8-11.706"
        transform="translate(0 0)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      />
      <path
        id="Path_303"
        data-name="Path 303"
        d="M5.19,0A3,3,0,0,1,2.595,1.495,3,3,0,0,1,0,0"
        transform="translate(8.809 25.511)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3}
      />
    </g>
  </svg>
);

export default SVGComponent;

