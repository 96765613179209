import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal } from 'react-bootstrap';
import axios from "axios";
import { MW_URL } from "../../../../config";
import LoadingAnimation from "../../../../utils/loadingAnimation";
import { CircularProgressbar } from "react-circular-progressbar";
import Button from 'react-bootstrap/Button';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import QuillEditor from '../../../students/quillEditor';
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";



const Documents = ({selectedStudent}) => {

    const [lgShow, setLgShow] = useState(false);
    const [show, setShow] = useState(false);

    const [selectedCollegeDoc, setSelectedCollegeDoc] = useState();


    const [isLoading, setIsLoading] = useState(true);
    const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState(
        []
    );
    const [docId, setDocumentId] = useState([]);
    const [viewDoc, setViewDoc] = useState();
    const [selectedDoc, setSelectedDoc] = useState({});
    const [studentUploadDocList, setStudentUploadDocList] = useState([]) ;
    const [selectedDocId, setSelectedDocId] = useState();


    const [listOfSelectedDocumentArr, setlistOfSelectedDocumentsArr] = useState(
        []
    );
    const [collageDocMappingArr, setcollageDocMappingArr] = useState([]);
    const [collageDocMappingArrTemp, setcollageDocMappingArrTemp] = useState([]);
    const [uploadedDoc, setUploadedDocPayload] = useState([]);
    const [SearchKeyword, setSearchKeyword] = useState("");
    const [docValidation, setValidation] = useState(false);
    const [newFileToUse, setNewFileToUse] = useState(0);
    const [fileToUseData, setFileToUseData] = useState([]);
    const [transferredData, setTransferredData] = useState([]);
    const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);


    // const documents = [
    //     {
    //       id: 1,
    //       fileType: "pdf",
    //       fileData: selectedDoc?.file,
    //     },
    //   ];



    const [editState, setEditState] = React.useState({
        collageId: "",
        documentId: "",
        fileName: "",
        file: "",
        collageId: "",
      });


    useEffect(() => {
        getAllEssays();
        getTransferedDocs();
    }, [selectedStudent, isUseEffectCallRequired]);

    const getAllEssays = () => {
        console.log("ok")
        setIsLoading(true);
        var selectedCollages = [];
        var selectedCollagesIds = [];
        var templateArr = [];
        axios
          .post(`${MW_URL}` + "getSelectedListOfCollages", {
            email: selectedStudent.email,
          })
          .then((getSelectedListOfCollagesresponse) => {
            if (
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
                .length > 0
            ) {
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
                (collage) => {
                  selectedCollages.push(collage);
                  selectedCollagesIds.push(collage.id);
                }
              );
            //   console.log("selectedCollages", selectedCollages);
              setlistOfSelectedCollagesArr(selectedCollages);
    
              axios
                .post(`${MW_URL}` + "documentsbyuser", selectedCollages)
                .then((documentsbyuserresponse) => {
                    // console.log("documentsbyuser", documentsbyuserresponse);
                  var documentId = [];
                  var documentsByUserResFromApi = documentsbyuserresponse;
                  documentsbyuserresponse.data.map((ids) => {
                    {
                      documentId.push(ids);
                    }
                  });
                  //  console.log("documentId", documentId);
                  setDocumentId(documentId);
                  axios
                    .post(`${MW_URL}` + "getDocByCollege", documentId)
                    .then((getDocumentDetailResponse) => {
                      var status = "NA";
                      var documentsDetails = [];
                      status = getDocumentDetailResponse.data.STATUS;
                      if (status.toUpperCase() === "SUCCESS") {
                          // console.log("getDocumentDetailResponse.data",getDocumentDetailResponse.data);
                        setlistOfSelectedDocumentsArr(
                          getDocumentDetailResponse.data.data
                        );
                        if (
                          getDocumentDetailResponse &&
                          getDocumentDetailResponse.data &&
                          getDocumentDetailResponse.data.data
                        ) {
                          var collageDocMapArrTemp = [];
    
                          selectedCollages.map((collage) => {
                            var collageDocMapObjTemp = { collage: collage };
                            var documentsdetArr = [];
                            documentsByUserResFromApi.data.map(
                              (documentsCollageMap) => {
                                if (documentsCollageMap.collageId == collage.collageId) {
                                  documentsdetArr.push(
                                    getDocumentDetailResponse.data.data.filter(
                                      (doc) =>
                                        doc.documentId ==
                                        documentsCollageMap.documentId
                                    )
                                  );
                                }
                              }
                            );
                            collageDocMapObjTemp.documents = documentsdetArr;
                            collageDocMapArrTemp.push(collageDocMapObjTemp);
                          });
                          // console.log("selectedCollegeDoc-12",selectedCollegeDoc);
                          setcollageDocMappingArr(collageDocMapArrTemp);
                          console.log("collageDocMapArrTemp",collageDocMapArrTemp);
                          setcollageDocMappingArrTemp(collageDocMapArrTemp);

                          setIsLoading(false);
                        }
                      } else {
                        setIsLoading(false);
                      }
                    });
                  axios
                    .post(`${MW_URL}` + "getAllStudentsDocs", {
                      email: selectedStudent.email,
                    })
                    .then((getStudDOcResponse) => {
                      setIsLoading(false);
                      // setIsUseEffectCallRequired(false);
                      var allDetail = [];
                      if (
                        getStudDOcResponse &&
                        getStudDOcResponse.data &&
                        getStudDOcResponse.data[0]
                      ) {
                        var DocMapArrTemp = [];
    
                        selectedCollages.map((collage) => {
                          var DocMapObjTemp = { collage: collage };
                          var documentsdet = [];
                          documentsByUserResFromApi.data.map(
                            (documentsCollageMap) => {
                              if (documentsCollageMap.collageId == collage.collageId) {
                                documentsdet.push(
                                  getStudDOcResponse.data.filter(
                                    (doc) =>
                                      doc.documentId ==
                                      documentsCollageMap.documentId && doc.collegeId ==
                                      documentsCollageMap.collageId
                                  )
                                );
                              }
                            }
                          );
                          DocMapObjTemp.documents = documentsdet;
                          DocMapArrTemp.push(DocMapObjTemp);
                        });
                        console.log(":;;loading");
                        setUploadedDocPayload(DocMapArrTemp);
                        console.log(DocMapArrTemp, "DocMapArrTemp")
                        setIsLoading(false);
                      }
                    })
                    .catch((err) => {
                      console.log("err >> ", err);
                    })
                    .catch((err) => {
                      console.log("err >> ", err);
                    });
                })
    
                .catch((err) => {
                  console.log("err >> ", err);
                });
            } else {
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      };

      const handleDocEditClick = (obj) => {
        setShow(true)
        setSelectedCollegeDoc(obj)
        getAllStudentUploadDocs(obj?.collage?.collageId)
        console.log(obj, "colldoc")
        const uploadedlen = uploadedDoc?.length;
        const len = listOfSelectedDocumentArr.length;
        {docDetailComponent({
          collageName: obj.collage.name,
          fileRequired: obj.documents.length,
          fileUploaded: 0,
          collageId: obj.collage.collageId,
          deadline: obj.collage.deadline,
        })}
        console.log(obj, "1212")
        {obj.documents.map((docData) => {
          if (docData[0]) {
            return docDetailUploadComponent({
              fileName: docData[0].fileName,
              fileRequired: len,
              fileUploaded: uploadedlen,
              collage: obj,
              fileSize: docData[0].fileSize,
              fileType: docData[0].fileType,
              requirement: docData[0].requirement,
              documentId: docData[0].documentId,
              file: docData[0].file,
              deadline: docData[0].deadline,
            });
          }
        })}
  
      }
  
      function docDetailComponent(submissionData) {
        var fileToUse = 0;
        console.log("sub...>",submissionData)
    
        uploadedDoc?.map((docs) => {
          // console.log("docs...>",docs)
          if (docs.collage.collageId == submissionData.collageId) {
            if (docs.documents.length) {
              docs.documents.map((d) => {
                if (d.length) {
                  fileToUse += 1
                }
              })
            }
          }
        });
        setNewFileToUse(fileToUse)
      }
  
      function docDetailUploadComponent(submissionData, data) {
        var fileToUse = []; //use this variable to save needed data
        var inputFieldId = submissionData.documentId + "-" + submissionData.collageId;
        const ar = [];
        console.log("uploadedDoc", uploadedDoc);
        console.log("submissiondata-2", submissionData);
        uploadedDoc?.map((docs) => {
          {
            ar.push(docs.collage)
            docs.documents.map((doc) => {
              // console.log(doc, "doc-foormat")
              ar.map((colid) => {
                if (doc.length > 0) {
                  // console.log("11", doc[0].collegeId, submissionData.collage.collage.collageId)
                  if (doc[0].collegeId == submissionData.collage.collage.collageId) {
                    console.log("12", doc[0].documentId, submissionData.documentId)
                    if (doc[0].documentId == submissionData.documentId) {
                      // console.log("submissionData.documentId===>",submissionData.documentId);
                      // console.log("doc===>",docs);
                      console.log("doc=",doc[0].file, doc[0].fileName, doc[0].documentId );
                      // filesToUse.push(
                        // file: doc[0].file,
                        // fileName: doc[0].fileName,
                        // doc[0].documentId
                        // collageId: colid.collageId,
                      // )
                      // setFileToUseData(fileToUse)
                      // console.log(fileToUse, "new file")
                      setFileToUseData((prevData) => [...prevData, doc[0].documentId])
                      console.log("fileToUseData", fileToUseData)
  
  
                    }
                  }
                }
              })
            });
          }
        });
  
      }

      const getTransferedDocs = () => {
        axios
          .post(`${MW_URL}` + "getTransferredDocs", {recipientId : JSON.parse(localStorage.getItem("userData")).email})
          .then((getTransferredDocsresponse) => {
            console.log(getTransferredDocsresponse, "getTransferredDocsresponse")
            if(getTransferredDocsresponse?.data){
              setTransferredData(getTransferredDocsresponse?.data)
              console.log(getTransferredDocsresponse?.data, "data")
            }else{
              setTransferredData([])
            }
          })
      }

      const handleViewFile = (filedata, data) => {
          setLgShow(true)
          setSelectedDocId(data)
          console.log(selectedDoc, "selectedDoc")
          // console.log(filedata, fileToUseData, "fileToUseData")
          // console.log(data, "data")
            axios
              .post(`${MW_URL}` + "getStudentDocfile", {
                email: selectedStudent?.email,
                documentId: data,
                collageId: selectedCollegeDoc?.collage?.collageId,
              })
              .then((getDocumentDetailResponse) => {
                console.log("doc-data", getDocumentDetailResponse.data.data)
                // setViewDoc(getDocumentDetailResponse.data.data[0].file)
                setSelectedDoc(getDocumentDetailResponse.data.data[0])
              })

      }

      const getAllStudentUploadDocs = (collegeId) => {
        console.log("okkk---->", collegeId)
        let dataToSend = {
          collegeId: collegeId,
          email: selectedStudent?.email
        }
        axios
          .post(`${MW_URL}` + "getAllDocumentsById", dataToSend)
          .then((getDocumentDetailResponse) => {
            console.log(getDocumentDetailResponse, "stud uploadeddoc list")
            setStudentUploadDocList(getDocumentDetailResponse.data)
  
          })
      }

      const acceptDocBtn = () => {
        console.log("accept-122")
        let dataToSend = {
          email: selectedStudent?.email,
          documentId: selectedDoc?.documentId,
          file: selectedDoc?.file,
          collegeId: selectedDoc?.collegeId,
        }
        console.log(dataToSend, "datatosnnd")
        axios
          .post(`${MW_URL}` + "acceptDocs", dataToSend)
          .then((getDocumentDetailResponse) => {
            console.log("accept doc", getDocumentDetailResponse)
            console.log("accept-1211")
            setShow(false)
            handleDocClose()
          })
      }

      const rejectDocBtn = () => {
        console.log("reject-122")
        let dataToSend = {
          email: selectedStudent?.email,
          documentId: selectedDoc?.documentId,
          file: selectedDoc?.file,
          collegeId: selectedDoc?.collegeId,
        }
        console.log(dataToSend, "datatosnnd")
        axios
          .post(`${MW_URL}` + "rejectDocs", dataToSend)
          .then((getDocumentDetailResponse) => {
            console.log("reject doc", getDocumentDetailResponse)
            console.log("reject-1211")
            setShow(false)
            handleDocClose()
          })
      }


      const getStatus = (doc) => {
        if (doc?.isAccepted && !doc?.isPending) return 'accepted';
        if (!doc?.isAccepted && !doc?.isPending) return 'rejected';
        if (!doc?.isAccepted && doc?.isPending) return 'pending';
        return null;
      };

      const handleDocClose = () => {
        setLgShow(false);
        setSelectedDoc({});
      }

    //   const downloadfiles = (rowData, collageId) => {
    //     console.log("stud", rowData, collageId)
    //     if (rowData && collageId) {
    //         console.log("stud-12")
    //       setIsLoading(true)
    //       axios
    //         .post(`${MW_URL}` + "getStudentDocfile", {
    //           email: selectedStudent.email,
    //           documentId: rowData.documentId,
    //           collageId: collageId,
    //         })
    //         .then((getDocumentDetailResponse) => {
    //           console.log("data", getDocumentDetailResponse.data.data)
    //           var status = "NA";
    //           var documentFile = [];
    //           status = getDocumentDetailResponse.data.STATUS;
    //           if (status.toUpperCase() === "SUCCESS") {
    //             if (
    //               getDocumentDetailResponse &&
    //               getDocumentDetailResponse.data
    //             ) {
    //                 console.log("stud-123")
    //               getDocumentDetailResponse.data.data.map((file) => {
    //                 {
    //                   documentFile.push(file.file)
    //                 }
    //               });
    //             }
    //             setIsLoading(false)
    //             downloads(documentFile, rowData.fileName);
    //           }
    //         }).catch((err) => {
    //           console.log("err >> ", err);
    //         });
    //     }
    //     else {
    //     //   toast.error("No file Uploaded", {
    //     //     autoClose: 1000,
    //         console.log("err")
    //     //   })
    //     }
    //   }

    //   function downloads(data, Name) {
    //     console.log("decode", data);
    //     const linkSource = `${data}`;
    //     const downloadLink = document.createElement("a");
    //     const fileName = Name;
    
    //     downloadLink.href = linkSource;
    //     downloadLink.download = fileName;
    //     downloadLink.click();
    //   }
  
    
  return (
    <div className="counsStudentBottonWrap">
        <div className='row'>
            {collageDocMappingArr?.map((obj, index) => {
              if(transferredData?.some(obj2 => obj2?.collageId === obj?.collage?.collageId && obj2?.senderId === selectedStudent.email)){
                return(
                  <div className='col-4'>
                    <div key={index} className='docCol'>
                          <div className='docImgWrap'>
                              <img className="docImg" src='https://wpamelia.com/wp-content/uploads/2019/04/Lipsum.jpg' />
                          </div>
                          <div className="docColName">
                            <p className='colNormalText2 mt-2 pt-1 mb-0 pb-0 px-2 '>{obj?.collage?.name}</p>
                          </div>
                          <p className='docSpanText p-0 pb-1 px-2 m-0 '>{obj?.collage?.deadline}</p>
                          {!obj?.collage?.deadline && (<p className='docSpanText p-0 pb-1 px-2 m-0 '>deadline</p>)}
                          <button onClick={() => handleDocEditClick(obj)} className='docEditBtn'>Add</button>
                  </div>
                </div>
                )
              }
            })}

            {/* {collageDocMappingArr?.map((obj, index) => (
                <div className='col-4'>
                    <div key={index} className='docCol'>
                          <div className='docImgWrap'>
                              <img className="docImg" src='https://wpamelia.com/wp-content/uploads/2019/04/Lipsum.jpg' />
                          </div>
                          <div className="docColName">
                            <p className='colNormalText2 mt-2 pt-1 mb-0 pb-0 px-2 '>{obj?.collage?.name}</p>
                          </div>
                          <p className='docSpanText p-0 pb-1 px-2 m-0 '>{obj?.collage?.deadline}</p>
                          {!obj?.collage?.deadline && (<p className='docSpanText p-0 pb-1 px-2 m-0 '>deadline</p>)}
                          <button onClick={() => handleDocEditClick(obj)} className='docEditBtn'>Add</button>
                  </div>
                </div>
            ))}  */}

            

            {/* <div className='col-4'>
                <div className='docCol'>
                    <div className='docImgWrap'>
                        <img className="docImg" src='https://wpamelia.com/wp-content/uploads/2019/04/Lipsum.jpg' />
                    </div>
                    <div className="docColName">
                        <p className='colNormalText2 mt-2 pt-1 mb-0 pb-0 px-2 '>Document 123</p>
                    </div>
                        <p className='docSpanText p-0 pb-1 px-2 m-0 '>09 Feb 2023</p>
                    <button className='docEditBtn'>Edit</button>
                </div>
            </div> */}
        </div>

        <Modal
            size="xl"
            show={lgShow}
            onHide={() => setLgShow(false)}
        >
            <div className='CounDocModal'>
                {/* <div className='d-flex justify-content-between '>
                    <p className='essayHeadText4 m-3 p-2 '>Edit Essay </p>
                    <div className='m-2'>
                        <AiOutlineCloseCircle onClick={() => setLgShow(false)} className='essayCloseBtn'/>
                    </div>
                </div> */}
                <div className='row m-2'>
                    <div className='col-8'>
                    {studentUploadDocList?.map((doc, index) => (
                        doc.documentId === selectedDocId && (
                          <></>
                          // <DocViewer
                          //   pluginRenderers={DocViewerRenderers}
                          //   documents={[{ url: selectedDoc?.file, type: 'base64' }]}
                          //   config={{
                          //     header: {
                          //       disableHeader: false,
                          //       disableFileName: true,
                          //       retainURLParams: false
                          //     }
                          //   }}
                          //   style={{ height: 500 }}
                          // />
                        )
                    ))}  
                          
                        {/* <img className='p-1' height={510} src='https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Lorem_Ipsum_Article.png/466px-Lorem_Ipsum_Article.png?20150528112327' /> */}
                        <div className='d-flex justify-content-between '>
                            <div className="m-2">
                              <p className={`${getStatus(selectedDoc)}Status`}>{getStatus(selectedDoc)}</p>
                            </div>
                            {/* <div className="mt-2">
                              {getStatus(selectedDoc) === "pending" && (
                              <>
                                <button onClick={() => acceptDocBtn()} className='essayBtn1'>Accept</button>
                                <button onClick={() => rejectDocBtn()} className='essayRejectBtn1 mx-3'>Reject</button>
                              </>
                              )}
                              {getStatus(selectedDoc) === "accepted" && (
                              <button onClick={() => rejectDocBtn()} className='essayRejectBtn1 mx-3'>Reject</button>
                              )}
                              {getStatus(selectedDoc) === "rejected" && (
                              <button onClick={() => acceptDocBtn()} className='essayBtn1'>Accept</button>
                              )}
                            </div> */}
                            <div className="mt-2">
                              {getStatus(selectedDoc) === "pending" && (
                                <>
                                  <button onClick={acceptDocBtn} className='essayBtn1'>Accept</button>
                                  <button onClick={rejectDocBtn} className='essayRejectBtn1 mx-3'>Reject</button>
                                </>
                              )}
                              {getStatus(selectedDoc) === "accepted" && (
                                <button onClick={rejectDocBtn} className='essayRejectBtn1 mx-3'>Reject</button>
                              )}
                              {getStatus(selectedDoc) === "rejected" && (
                                <button onClick={acceptDocBtn} className='essayBtn1'>Accept</button>
                              )}
                            </div>
                        </div>
                    </div>
                    <div className='col-4' style={{"background":"#F5F7FB"}}>
                        <div className='d-flex justify-content-end'>
                            <AiOutlineCloseCircle onClick={() => handleDocClose()} className='essayCloseBtn'/>
                        </div>
                        <div>
                            <QuillEditor placeholder={'Add notes'} customClass={'counsAddNotesSec'} />
                        </div>
                        <div className='text-center m-2'>
                            <button className='essayBtn2 m-2'>Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

        <Modal show={show} onHide={() => setShow(false)}>
                    <div className=" docModal p-2">
                        <div className='d-flex justify-content-end'>
                            <AiOutlineCloseCircle onClick={() => setShow(false)} className='essayCloseBtn'/>
                        </div>
                        <p className="colNormalText">{selectedCollegeDoc?.collage.name}</p>

                        {
                          selectedCollegeDoc?.documents?.length > 0 ? (
                            <div>
                              <div>
                              <div className="row m-2 border-bottom">
                                    <div className="col-6">
                                        <p className="colNormalText2 m-0">{newFileToUse}</p>
                                        <p className="colLightText m-0">Files Uploaded</p>
                                    </div>
                                    <div className="col-6">
                                        <p className="colNormalText2 m-0">{selectedCollegeDoc?.documents?.length}</p>
                                        <p className="colLightText m-0">Files Required</p>
                                    </div>

                                    
                                </div>                               
                              </div>
      
                              <div className="docModalContentList">
                                {selectedCollegeDoc?.documents?.map((obj, index) => (
                                  <div className="docModalContentWrap py-2 my-2" key={index}>
                                    <div className="row m-2">
                                        <div className="col">
                                            <p className="colNormalText2 m-0">{obj[0]?.fileName}</p>
                                            <p className="colLightText m-0">filename</p>
                                        </div>
                                        <div className="col">
                                            <p className="colNormalText2 m-0">{obj[0]?.fileSize}</p>
                                            <p className="colLightText m-0">fileSize</p>
                                        </div>
                                        <div className="col">
                                            <p className="colNormalText2 m-0">{obj[0].fileType}</p>
                                            <p className="colLightText m-0">fileType</p>
                                        </div>
                                    </div>
                                    <div className="row m-2">
                                        <div className="col">
                                            <p className="colNormalText2 m-0">{obj[0]?.requirement}</p>
                                            <p className="colLightText m-0">requirement</p>
                                        </div>
                                        <div className="col">
                                            <p className="colNormalText2 m-0">{obj[0]?.deadline}</p>
                                            <p className="colLightText m-0">Deadline</p>
                                        </div>
                                        <div className="col">
                                              {studentUploadDocList?.map((doc, index) => (
                                                doc.documentId === obj[0].documentId && (
                                                  <p className={`${getStatus(doc)}Status`} key={index}>{getStatus(doc)}</p>
                                                )
                                              ))}                                       
                                        </div>
                                    </div>
                     
                                    <div className="row my-2">
                                      {fileToUseData.includes(obj[0].documentId) ? 
                                      (<div className="col">
                                        <button onClick={() => handleViewFile(fileToUseData, obj[0].documentId)} className="essayBtn2" >View File</button>
                                      </div>)
                                        :(<div className="col"><button className="outlineRedBtn" >No File</button></div>)
                                      }
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ) : (
                              <div className="noFilesReqWrap">
                                <p className="essayNormalText2 ">No files Required</p>
                              </div>
                          )
                        }


                    </div>      
                </Modal>

    </div>
  )
}

export default Documents