import React, {useState } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { MW_URL } from "../config";
import { currentPageScreen, setCurrentPage, isMainLoading } from '../stateManager/reducers/generalReducer'
import ValidateForm from "../utils/formValidation";
import axios from "axios";
import { Button, Input, InputGroup, InputGroupText } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import SplashLoader from "./splashLoadingScreen";
import Portal from "./portalScreen";
import SignUp from "./signUpScreen";
import TellUsMore from "./tellUsMoreScreen";
import LorScreen from "./lorScreen"
import LoadingAnimation from "../utils/loadingAnimation";
import {BiLogoFacebook} from 'react-icons/bi'
import {BiLogoGoogle} from 'react-icons/bi'
import {BiLogoApple} from 'react-icons/bi'
import SignUpScreen2 from './signUpScreen2';
import TellUsMoreScreen2 from './tellUsMoreScreen2';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


const LandingScreen2 = () => {

    const isMainLoader = useSelector(isMainLoading);
    const currentPage = useSelector(currentPageScreen);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const dispatch = useDispatch();

    const loginBtnClicked = (e) => {
        e.preventDefault()
        console.log("1212")
        if (ValidateForm.inputFieldMandatoryCheck("login", false)) {
            if (ValidateForm.inputFieldValueCompositionCheck("login", false))
            console.log("001")
            var dataToSend = {
                    email: email,
                    password: password,
                };
            setIsLoading(true);
            console.log("0", dataToSend)
            axios
                .post(`${MW_URL}` + "signIn", dataToSend)
                .then((response) => {
                    console.log(response, "haa")
                setIsLoading(false);
                var status = "NA";
                if (response && response.data && response.data.status)
                    status = response.data.status;
                console.log("response", JSON.stringify(response));
                if (status.toUpperCase() === "SUCCESS") {
                    var userObj = {
                    username: response.data.username,
                    userType: response.data.userType,
                    email: response.data.email,
                    counsellorId: response.data.counsellorId,
                    };
                    console.log("userObj", userObj);
                    localStorage.setItem("userData", JSON.stringify(userObj));
                    localStorage.setItem("isUserLoggedIn", "true");
                    dispatch(setCurrentPage("portal"));
                } else if (status === "INVALID_USER") {
                    toast.error("Invalid Username or Password", {
                    autoClose: 2000,
                    pauseOnHover: true,
                    });
                }
                })
                .catch((err) => {
                console.log("err >> ", err);
                });
        }
    }

    const signUpBtnClicked = () => {
        dispatch(setCurrentPage("signUp"));
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        updateButtonState(event.target.value, password);
    };

    // Function to handle changes in the password input field
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        updateButtonState(email, event.target.value);
    };

    // Function to update the state of the login button based on input values
    const updateButtonState = (email, password) => {
        setIsButtonDisabled(email.trim() === '' || password.trim() === '');
    };


    if (isMainLoader) {
        return <SplashLoader />
    } else {
        if (currentPage === "portal") {
            return <Portal />;
          } else if (currentPage === "signUp") {
            // return <TellUsMoreScreen2 />
            return <SignUpScreen2 />;
          } else if (currentPage === "tellUsMore") {
            return <TellUsMoreScreen2 />;
          } else if (currentPage === "lor") {
            return <LorScreen />;
          } else {
            return (
                <div>
                    {isLoading ? <LoadingAnimation isVisible={true} /> : null}
                     <div className="row" style={{"height":"100vh", maxWidth:"100vw"}}>
                        <div className="col-5 bg-1 p-0"></div>
                        <div className="col-7 p-0">
                            <div className='bg-title'></div>
                            <div id="login" className='d-flex justify-content-center'>
                                <form className='loginForm' onSubmit={(e) => loginBtnClicked(e)}>
                                    <h3 className='loginHeading'>Login</h3>
                                    <div className='mt-3'>
                                        <label htmlFor='loginEmail'>Email</label>
                                        <Input
                                            type="email"
                                            // autoComplete="new-password"
                                            name='email'
                                            className="input"
                                            value={email}
                                            id="loginEmail"
                                            placeholder="Enter your email"
                                            onChange={(e) => handleEmailChange(e)}
                                        />
                                    </div>
                                    
                                    <div className='mt-3'>
                                        <label htmlFor='loginPassword'>Password</label>
                                        <InputGroup>
                                            <Input
                                                type="password"
                                                // autoComplete="new-password"
                                                name='password'
                                                className="input"
                                                value={password}
                                                id="loginPassword"
                                                placeholder="Enter your password"
                                                onChange={(e) => handlePasswordChange(e)}
                                            />
                                        </InputGroup>
                                    </div>
                                    <p className='linkText1 my-2'>Forgot Password</p>
                                    <button disabled={isButtonDisabled} type='submit'>Login</button>
                                </form>
                                <ToastContainer />
                            </div>
                            
                            <div className='otherLogin'>
                                <div>
                                    <p className='m-0'>Or you can also login with</p>
                                    <div className='loginMethods'>
                                        <div className='my-2 mx-4 loginMethodsBg'><BiLogoApple className='loginMethodFont' /></div>
                                        <div className='my-2 mx-2 loginMethodsBg'><BiLogoGoogle className='loginMethodFont' /></div>
                                        <div className='my-2 mx-4 loginMethodsBg'><BiLogoFacebook className='loginMethodFont' /></div>
                                    </div>
                                </div>
                            </div>
            
                            <div className='bottomText'>
                                <p>Don't have an account? <span className='linkText' onClick={()=>signUpBtnClicked()}> Sign Up</span></p>
                            </div>
                        </div>
                    </div>
                </div>
              )
         }
    }
}

export default LandingScreen2;