import React, { useEffect } from 'react'
import axios from 'axios';
import { MW_URL } from "../../../../config";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Modal } from 'react-bootstrap';
import {GoPencil} from 'react-icons/go';
import {AiOutlineArrowLeft} from 'react-icons/ai';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import ModalComponent from "../../../../utils/modalComponent";
import { useState } from 'react';
import QuillEditor from '../../quillEditor';
import { findNotifDate } from '../../../../modules/parserHelper';
import LoadingAnimation from "../../../../utils/loadingAnimation";




const EssaySec = () => {

    const [openModal, setOpenModel] = useState(false)
    const [selectedEssay, setSelectedEssay] = useState({})

    const [isLoading, setIsLoading] = useState(true);
    const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState(
        []
      );
      const [listOfSelectedCollagesArrtemp, setlistOfSelectedCollagesArrtemp] = useState(
        []
      );
    const [essaysTemplateList, setessaysTemplateList] = useState([]);
    const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
    const [essaysList, setessaysList] = useState([]);
    const [updatesList, setupdatesList] = useState([]);
    const [essayCollId, setessayCollId] = useState("");
    const [essayReqId, setessayReqId] = useState("");
    const [editorData, setEditorData] = useState(null);
    const [essayId, setessayId] = useState("");
    const [feedbacksList, setfeedbacksList] = useState({
        feedbacks: [],
        currentPage: 1,
        feedbackPerPage: 4,
    });



    useEffect(() => {
        getAllEssays();   
    }, []);

    const getAllEssays = () => {
        setIsLoading(true);
        var selectedCollages = [];
        var selectedCollagesIds = [];
        axios
          .post(`${MW_URL}` + "getSelectedListOfCollages", {
            email: JSON.parse(localStorage.getItem("userData")).email,
          })
          .then((getSelectedListOfCollagesresponse) => {
            if (
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
                .length > 0
            ) {
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
                (collage) => {
                  selectedCollages.push(collage);
                  selectedCollagesIds.push(collage.collageId);
                }
              );
              // console.log("q", selectedCollages)
              setlistOfSelectedCollagesArr(selectedCollages);
              setlistOfSelectedCollagesArrtemp(selectedCollages);
  
              var templateArr = [];
              var essaysRequirementIds = [];
              var essayReqIds = [];
              axios
                .post(`${MW_URL}` + "get_essay_requirement", selectedCollages)
                .then((getEssaysTemplateResponse) => {
                  // console.log(
                  //   "getEssaysTemplateResponse",
                  //   getEssaysTemplateResponse
                  // );
                  var status = "NA";
                  if (
                    getEssaysTemplateResponse &&
                    getEssaysTemplateResponse.data &&
                    getEssaysTemplateResponse.data.STATUS
                  )
                    status = getEssaysTemplateResponse.data.STATUS;
                  if (status.toUpperCase() === "SUCCESS") {
                    setessaysTemplateList(getEssaysTemplateResponse.data.data);
                    getEssaysTemplateResponse.data.data.map(
                      (templateRes) => {
                        if (templateRes.essayRequirements.length != 0) {
                          templateRes.essayRequirements.map((esaaReq) => {
                            essayReqIds.push(esaaReq.essayReqId);
                            templateArr.push({
                              template: esaaReq.question,
                              collageId: templateRes.collageId,
                              name: templateRes.name,
                              essayReqId: esaaReq.essayReqId,
                              length: esaaReq.length,
                              requirement: esaaReq.requirement,
                              answered: essaysRequirementIds.includes(
                                esaaReq.essayReqId
                              ),
                            });
                          });
                        }
    
                      })
                    axios
                      .post(`${MW_URL}` + "getEssays", {
                        ownerId: JSON.parse(localStorage.getItem("userData")).email,
                      })
                      .then((getAllEssaysResponse) => {
                        setIsUseEffectCallRequired(false);
                        var essaysListToPush = [];
                        var essaysRequirementIds = [];
                        getAllEssaysResponse.data.map((essay) => {
                          if (
                            essay.ownerId ==
                            JSON.parse(localStorage.getItem("userData")).email &&
                            selectedCollagesIds.includes(essay.collageId) &&
                            essayReqIds.includes(essay.essayReqId)
                          ) {
                            essaysListToPush.push({
                              essayId: essay.essayId,
                              content: essay.content,
                              newEditorContentEssay: essay.newEditorcontentEssay,
                              typeOfDocument: essay.typeOfDocument,
                              collageId: essay.collageId,
                              ownerId: essay.ownerId,
                              feedbacks: essay.feedbacks,
                              essayReqId: essay.essayReqId,
                              updatedDate: essay.updatedDate,
                              isApproved: essay.isApproved,
                            });
                            essaysRequirementIds.push(essay.essayReqId);
                          }
                        });
                        setessaysList(essaysListToPush);
                        console.log(essaysListToPush, "ddf")
    
                        // const params = new URLSearchParams(
                        //   window.location.search
                        // );
                        // if (params.get("id")) {
                        //   if (params.get("id") == "recent") {
                        //     viewEssay(essaysListToPush[0]);
                        //   } else {
                        //     const selectedEssay = essaysListToPush.find(
                        //       (essay) => essay.essayId == params.get("id")
                        //     );
                        //     // console.log("selectedEssay====>", selectedEssay);
                        //     viewEssay(selectedEssay);
                        //   }
                        // } else {
                        //   console.log("No params");
                        // }
    
                        axios
                          .post(`${MW_URL}` + "getUpdates", {
                            recipientId: JSON.parse(
                              localStorage.getItem("userData")
                            ).email,
                            senderId: false,
                          })
                          .then((getUpdatesResponse) => {
    
                            setupdatesList(getUpdatesResponse.data);
                            setessaysTemplateList(
                              templateArr.map((reqEssay) => {
                                return {
                                  ...reqEssay,
                                  answered: essaysRequirementIds.includes(
                                    reqEssay.essayReqId
                                  ),
                                };
                              })
                            );
                            setIsLoading(false);
                          })
                          .catch((err) => {
                            console.log("err >> ", err);
                          });
                      })
                      .catch((err) => {
                        console.log("err >> ", err);
                      });
                  } else {
                    setIsLoading(false);
                  }
                })
                .catch((err) => {
                  console.log("err >> ", err);
                });
            } else {
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      };  

  return (
    <div className=''>
        {isLoading ? <LoadingAnimation isVisible={true} /> : null}
         <div className="row studCollBottonWrap py-3">
            {essaysList.map((obj, index) => (
                <div className="col-6  ">
                    <div key={index} className='essayTable'>
                        <div className='essayTableHeadWrap'>
                            <p className='essayNumber my-2'>{obj.content.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, "")}</p>
                        </div>
                        <table className="">
                            <thead className='essayThead'>
                                <th className=''>Status</th>
                                <th className='px-3'>Feedbacks</th>
                                <th className=''>Suggestions</th>
                            </thead>
                            <tbody>
                                {obj.isApproved ? <td className='essayStatusText'>Approved</td> : <td className='essayStatusText'>In progress</td>}
                                <td className='essayNumber px-3'>{obj.feedbacks.length}</td>
                                <td className='essayNumber'>03</td>
                            </tbody>
                        </table>
                    </div>                
                </div>
            ))}

            

            {/* <div className="col-6  ">
                <div className='essayTable'>
                    <p className='essayNumber my-2'>Essay 2</p>
                    <table className="">
                        <thead className='essayThead'>
                            <th className=''>Status</th>
                            <th className='px-3'>Comments</th>
                            <th className=''>Suggestions</th>
                        </thead>
                        <tbody>
                            <td className='essayStatusText'>In progress</td>
                            <td className='essayNumber px-3'>03</td>
                            <td className='essayNumber'>03</td>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-6 ">
                <div className='essayTable'>
                    <p className='essayNumber my-2'>Essay 3</p>
                    <table className="">
                        <thead className='essayThead'>
                            <th className=''>Status</th>
                            <th className='px-3'>Comments</th>
                            <th className=''>Suggestions</th>
                        </thead>
                        <tbody>
                            <td className='essayStatusText'>In progress</td>
                            <td className='essayNumber px-3'>03</td>
                            <td className='essayNumber'>03</td>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-6 ">
                <div className='essayTable'>
                    <p className='essayNumber my-2'>Essay 4</p>
                    <table className="">
                        <thead className='essayThead'>
                            <th className=''>Status</th>
                            <th className='px-3'>Comments</th>
                            <th className=''>Suggestions</th>
                        </thead>
                        <tbody>
                            <td className='essayStatusText'>In progress</td>
                            <td className='essayNumber px-3'>03</td>
                            <td className='essayNumber'>03</td>
                        </tbody>
                    </table>
                </div>
            </div> */}
        </div>
    </div>
  )
}

export default EssaySec