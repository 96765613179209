

import React from 'react';
import { CSSTransition, TransitionGroup, SwitchTransition } from 'react-transition-group';

function cssTransition(props) {
    return (
        <CSSTransition
            in={props.isVisible}
            key={props.key}
            timeout={props.timeout}
            classNames={props.classNames}
            unmountOnExit={props.unmountOnExit}>
            {props.children}
        </CSSTransition>
    );
}
cssTransition.defaultProps = {
    isVisible: false,
    key:1, 
    timeout: 1000,
    classNames: {
        enterActive: 'animated slideInRight',
        exitActive: 'animated slideOutRight'
    },
    unmountOnExit: true,
    children: "<div>no childrens found</div>"
}

function transitionGroupContent(props) {
    return props.children.map((item, key) => {
        return (
            <CSSTransition
                key={key}
                timeout={props.timeout}
                classNames={props.classNames}
                unmountOnExit={props.unmountOnExit}
            >
                {item}
            </CSSTransition>
        );
    });
}

function transitionGroup(props) {
    return (
        <TransitionGroup>
            {transitionGroupContent(props)}
        </TransitionGroup>
    );
}

transitionGroup.defaultProps = {
    timeout: 1000,
    classNames: {
        enterActive: 'animated fadeIn',
        exitActive: 'animated fadeOut'
    },
    unmountOnExit: true
}

function switchTransitionContent(props) {
    for (let i = 0; i < props.children.length; i++) {
        if (props.switchTrigger === props["triggerValueForChild" + i]) {
            return (
                <CSSTransition
                    key={i}
                    timeout={props.timeout}
                    classNames={props.classNames}
                    unmountOnExit={props.unmountOnExit}
                >
                    {props.children[i]}
                </CSSTransition>
            );
        }
    }
}

function switchTransition(props) {
    return (
        <SwitchTransition mode='out-in' >
            {switchTransitionContent(props)}
        </SwitchTransition>
    );
}
switchTransition.defaultProps = {
    timeout: 1000,
    classNames: {
        enterActive: '',
        exitActive: ''
    },
    unmountOnExit: true,
    children: "no children"
}

export default { cssTransition, transitionGroup, switchTransition }
