import React, { useEffect, useState, useRef, useCallback } from "react";
import moment from "moment";
import axios from 'axios';
import {BsPlusCircle} from 'react-icons/bs'
import { MW_URL } from "../../../../config";
import ModalComponent from "../../../../utils/modalComponent";
import {AiTwotoneDelete} from 'react-icons/ai'
import AlertModal from "../../../widgets/AlertModal";
import ValidateForm from "../../../../utils/formValidation";




const Todo = () => {

    var todayDate = moment().format('DD MMM YYYY')

    const [isLoading, setIsLoading] = useState(true);
    const [todolist, settodolist] = useState([]);
    const [addTodoModalVisible, setaddTodoModalVisible] = useState(false);
    const [todoInTextarea, settodoInTextarea] = useState("");
    const [selectedTodo, setSelectedTodo] = useState();
    const [showTodoDelete, setShowTodoDelete] = useState(false);


    const [showSaveButtonForTodoUpdate, setshowSaveButtonForTodoUpdate] =
    useState(false);
    // const [todolistMap, settodolistMap] = useState(null);
  

    useEffect(() => {
      gettodo();
    }, []);

    // const getAllEssays = () => {
    //     setIsLoading(true)
    //     var selectedCollages = []
    //     axios.post(`${MW_URL}` + "getSelectedListOfCollages", {
    //         email: JSON.parse(localStorage.getItem("userData")).email,
    //     }).then((getSelectedListOfCollagesresponse) => {
    //         if (getSelectedListOfCollagesresponse.data[0].todoList) {
    //             var mapToSet = new Map(
    //               Object.entries(getSelectedListOfCollagesresponse.data[0].todoList)
    //             );
    //             selectedCollages.map((colgSelected) => {
    //               if (mapToSet.get(colgSelected.collageId) == null)
    //                 mapToSet.set(colgSelected.collageId, []);
    //             });
    //             settodolist(mapToSet.get(selCollageId));
    //             settodolistMap(mapToSet);
    //           } else {
    //             let todoMap = new Map();
    //             selectedCollages.map((colgSelected) => {
    //               if (todoMap.get(colgSelected.collageId) == null)
    //                 todoMap.set(colgSelected.collageId, []);
    //             });
    //             settodolistMap(todoMap);
    //           }
    //     })
    // }

    // const createUniquieId = () => {
    //     const head = Date.now().toString(36);
    //     const tail = Math.random().toString(36).substr(2);
    //     return head + tail;
    //   };

    // function addtodoList() {
    //     setIsLoading(true);
    //     setaddTodoModalVisible(false);
    //     var currentCollageTodoLIst = todolistMap.get(selCollageId);
    //     console.log(currentCollageTodoLIst, "cr_todo_colge")
    //     var todolistToPass = new Map(
    //     todolistMap.set(selCollageId, [
    //         ...currentCollageTodoLIst,
    //         { id: createUniquieId(), title: todoInTextarea, selected: false },
    //     ])
    //     );
    //     // console.log("todolistToPass", todolistToPass);
    //     axios
    //     .post(`${MW_URL}` + "addtodoList", {
    //         todoList: Object.fromEntries(todolistToPass),
    //         email: JSON.parse(localStorage.getItem("userData")).email,
    //     })
    //     .then((response) => {
    //         settodolistMap(
    //         new Map(
    //             todolistMap.set(selCollageId, [
    //             ...currentCollageTodoLIst,
    //             { id: createUniquieId(), title: todoInTextarea, selected: false },
    //             ])
    //         )
    //         );
    //         settodoInTextarea("");
    //         setIsLoading(false);
    //         getAllEssays();
    //     })
    //     .catch((err) => {
    //         console.log("err");
    //     });
    //   }

    // const addTodoModalHandler = (dataObject) => {
    //     if (dataObject.type === "cancel") {
    //       setaddTodoModalVisible(false);
    //     } else if (dataObject.type === "submit") {
    //       addtodoList();
    //     }
    //   };

    // const updateTodoList = (todoSelected) => {
    //     console.log("todoSelected", todoSelected);
    //     settodolist(
    //       todolist.map((todo) => {
    //         console.log(todo, "todo")
    //         if (todo.id == todoSelected.id) {
    //           if (todo.selected) {
    //             return {
    //               ...todo,
    //               selected: false,
    //             };
    //           } else {
    //             return {
    //               ...todo,
    //               selected: true,
    //             };
    //           }
    //         } else {
    //           return {
    //             ...todo,
    //             selected: todo.selected,
    //           };
    //         }
    //       })
    //     );
    //   };

    const createUniquieId = () => {
      const head = Date.now().toString(36);
      const tail = Math.random().toString(36).substr(2);
      return head + tail;
    };
    const updateTodoList = (todoSelected) => {
      setshowSaveButtonForTodoUpdate(true);
      settodolist((prevTodoList) =>
        prevTodoList.map((todo) => {
          if (todo.id === todoSelected.id) {
            return {
              ...todo,
              selected: !todo.selected, // Toggle the 'selected' property
            };
          }
          return todo; // No change to other todo items
        })
      );

      // settodolist(
      //   todolist.map((todo) => {
      //     if (todo.id == todoSelected.id) {
      //       if (todo.selected) {
      //         return {
      //           ...todo,
      //           selected: false,
      //         };
      //       } else {
      //         return {
      //           ...todo,
      //           selected: true,
      //         };
      //       }
      //     } else {
      //       return {
      //         ...todo,
      //         selected: todo.selected,
      //       };
      //     }
      //   })
      // );
    };

    const gettodo = () => {
      var selectedCollages = [];
      var selectedCollagesIds = [];
      axios
        .post(`${MW_URL}` + "getSelectedListOfCollages", {
          email: JSON.parse(localStorage.getItem("userData")).email,
        })
        .then((getSelectedListOfCollagesresponse) => {
          if (getSelectedListOfCollagesresponse.data[0].todoforcounsellor) {
            settodolist(
              getSelectedListOfCollagesresponse.data[0].todoforcounsellor
            );
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    };

    const addTodoModalHandler = (dataObject) => {
      if (dataObject.type === "cancel") {
        setaddTodoModalVisible(false);
      } else if (dataObject.type === "submit" && ValidateForm.inputFieldMandatoryCheck("todoContainer", false)) {
        addtodoList();
      }
    };

    function addtodoList() {
      setIsLoading(true);
      setaddTodoModalVisible(false);
      var todolistToPass = [
        ...todolist,
        { id: createUniquieId(), title: todoInTextarea, selected: false },
      ];
  
      axios
        .post(`${MW_URL}` + "addtodoListCounsellor", {
          todoList: todolistToPass,
          email: JSON.parse(localStorage.getItem("userData")).email,
        })
        .then((response) => {
          settodolist([
            ...todolist,
            { id: createUniquieId(), title: todoInTextarea, selected: false },
          ]);
          settodoInTextarea("");
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("err");
        });
    }

    const edittodolist = async (todolistforselectedcollage) => {
      setIsLoading(true);
      axios
      .post(`${MW_URL}` + "addtodoListCounsellor", {
        todoList: todolistforselectedcollage,
        email: JSON.parse(localStorage.getItem("userData")).email,
      })
      .then((response) => {
        settodolist([...todolistforselectedcollage]);
        setshowSaveButtonForTodoUpdate(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err");
      });
    };

    const handleTodoDelete = (todoSelected) => {
      setSelectedTodo(todoSelected)
      setShowTodoDelete(true)
    }

    const TodoDeleteBtnClick = () => {
      setshowSaveButtonForTodoUpdate(true);
      const newList =
        todolist.filter((todo) => todo.id !== selectedTodo?.id)
      settodolist(newList)
      setShowTodoDelete(false)
      return {
        ...newList,
      };
    }


  return (
    <div className='dashStats'>
        <h3 className='dashHead3'>Todo </h3>
        <div className='d-flex'>
            <div className="col-sm-6" style={{paddingTop: "10px"}}>
                <p className='dashNormalText'>{todayDate}</p>
            </div>

            <div className="col-sm-6 d-flex justify-content-end">
                  {showSaveButtonForTodoUpdate && (
                      <div
                        style={{ color: "red", cursor: "pointer" }}
                        className="mx-2"
                        onClick={() => {
                          edittodolist(todolist);
                        }}
                      >
                      save
                    </div>
                  )}
                <div onClick={() => setaddTodoModalVisible(true)} ><BsPlusCircle className='addTodoBtn' /></div>
            </div>
        </div>

        <div className="updateSec" style={{height : "20vh"}}>
            {todolist?.length > 0 ? (
                <div>
                    {todolist?.map((obj, index) => (
                    <div className='dashTodoCollage'>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className='dashNormalText2'>Todo list 
                                    {obj.selected ? (<span className='dashSpanMutedText'>Done</span>) 
                                        :(<span className='dashSpanText'>In Progress</span> )
                                    }
                                </div>
                                <div className='dashLightText'>{obj.title}</div> 
                            </div>
                            <div className="col-sm-6 d-flex justify-content-end">
                                {obj.selected ? (<button onClick={() => updateTodoList(obj)} className='todoSetBtn'>
                                    Set In progress
                                </button>) 
                                    :(<button onClick={() => updateTodoList(obj)} className='todoSetBtn'>
                                    Set us done
                                </button> )
                                }

                                <div onClick={() => handleTodoDelete(obj)} className="m-1">
                                  <AiTwotoneDelete fill="#bc4749"/>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            ) : (<p className="dashNormalText2"> No todos...</p>)}
            <div>
            </div>
        </div>

        <ModalComponent
        isVisible={addTodoModalVisible}
        mainHeading="Add todo"
        cancelButtonText="Cancel"
        submitButtonText="Add"
        callback={addTodoModalHandler}
      >
        <div className="pt-2 pb-5 form-group" id="todoContainer">
          <textarea
            className="essayNormalText1 queryTextInbox input input_data mandatoryField"
            value={todoInTextarea}
            placeholder="TYPE YOUR TODO HERE"
            onChange={(event) => {
              settodoInTextarea(event.target.value);
            }}
            id="w3review"
            name="w3review"
            rows="4"
            cols="42"
          >
            Write a todo
          </textarea>
          <div className="errorDesc"></div>
        </div>
      </ModalComponent>

      <AlertModal
          show={showTodoDelete}
          onHide={() => setShowTodoDelete(false)}
          title='Alert!'
          message='Are you sure you want to delete this todo?'
          onCancel={() => setShowTodoDelete(false)}
          onConfirm={() => TodoDeleteBtnClick()}
      />
    </div>
  )
}

export default Todo;