import React, { useState , useRef, useEffect} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function QuillEditor({placeholder, customClass, editorData, setEditorData, defaultContent, readOnly}) {
  const [value, setValue] = useState(defaultContent);
  const [showToolbar, setShowToolbar] = useState(false);
  const modalRef = useRef(null);


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideModal);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideModal);
    };
  }, []);

  const handleEditorClick = () => {
    setShowToolbar(true); // Toggle the showToolbar state
  };

  const handleClickOutsideModal = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowToolbar(false);
    }
  };

  const handleChange = (content) => {
    if (content === '<p><br></p>') {
      content = ''; // Set content to an empty string
    }
    console.log("changed")
    console.log(content, "content")
    setEditorData(content);
  };


  return (
    <div>
         <div className={`quill-editor ${showToolbar ? 'active' : ''} `} ref={modalRef} onClick={handleEditorClick} >
            <div className={customClass}>
                <ReactQuill 
                  onChange={handleChange}
                  placeholder={placeholder} 
                  defaultValue={defaultContent}
                  readOnly={readOnly}
                />
            </div>
         </div>
    </div>
  )
}

export default QuillEditor;