

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormValidation from './formValidation.js';
import AnimationWrapper from './animationWrapper.js';
import { propTypes } from 'react-bootstrap/esm/Image';

class SliderModalComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    triggerCallback = (receivedType) => {
        if (receivedType === "submit" && this.props.isValidationRequired) {
            if (FormValidation.inputFieldMandatoryCheck(this.props.uniqueModalReferenceId, this.props.locales)) {
                if (FormValidation.inputFieldValueCompositionCheck(this.props.uniqueModalReferenceId, this.props.locales)) {
                    if (FormValidation.inputFieldMaxLengthCheck(this.props.uniqueModalReferenceId, this.props.locales)) {
                        this.props.callback({
                            type: receivedType,
                            uniqueModalReferenceId: this.props.uniqueModalReferenceId
                        })
                    }
                }
            }
        } else {
            this.props.callback({
                type: receivedType,
                uniqueModalReferenceId: this.props.uniqueModalReferenceId
            })
        }
    }

    render() {
        return (
            <div>
                {this.props.isVisible ? <div className="commonPopupFormBackDrop animated fadeIn"></div> : null}
                <AnimationWrapper.cssTransition isVisible={this.props.isVisible}>
                    <div ref={this.props.uniqueModalReferenceId} id={this.props.uniqueModalReferenceId} className="commonPopupOuterMostContainer">
                        <div style={{ flex: this.props.leftPartFlexValue }} className="leftTransparentPart" onClick={() => this.triggerCallback("cancel")}></div>
                        <div style={{ flex: this.props.rightPartFlexValue,backgroundColor:this.props.contentPartBackgroundColor }} className="contentPart">
                            <div className="sliderMainHeading">{this.props.mainHeading}</div>
                            <div className={this.props.isOnboardingPayment ? "row formsContainerBox p-0" : "row formsContainerBox"}>
                                <div style={{width: '100%',overflowY: 'auto',height: '100%',overflowX: 'hidden'}}>
                                    {this.props.children}
                                </div>
                            </div>
                            {this.props.isButtonGroupRequired ?
                                <div className="buttonGroup d-flex flex-row justify-content-end">
                                    {this.props.isCancelButtonVisible ?
                                        <button type="button" className="btn whiteButton ml-2" onClick={() => this.triggerCallback("cancel")}>{this.props.cancelButtonText}</button>
                                        : null}
                                    {this.props.isResetButtonVisible ?
                                        <button type="button" className="btn whiteButton ml-2" onClick={() => this.triggerCallback("reset")}>{this.props.resetButtonText}</button>
                                        : null}
                                    {this.props.isSubmitButtonVisible ?
                                        <button type="button" disabled={this.props.isSubmitButtonDisabled?true:false} className={this.props.isSubmitButtonDisabled?"btn redButton disabledButton ml-2":"btn redButton ml-2"} onClick={() => this.triggerCallback("submit")}>{this.props.submitButtonText}</button>
                                        : null}
                                     {this.props.isPayNowButtonVisible ?
                                        <iframe title="" src={"./PayPal.html?clientId=" + this.props.paypalClientId + "&amount=" + this.props.amount} style={{ width: '50%', border: 'none' }} className={this.props.isPaypalButtonRendered ? "payPalButtonRendered" : "paypalBtnPreRendered"} frameBorder="0"> </iframe>
                                    : null }
                                </div>
                                : null}
                        </div>
                    </div>
                </AnimationWrapper.cssTransition>
            </div>
        );
    }
}

SliderModalComponent.defaultProps = {
    isVisible: false,
    isCancelButtonVisible: true,
    isResetButtonVisible: true,
    isSubmitButtonVisible: true,
    cancelButtonText: "cancel",
    resetButtonText: "reset",
    submitButtonText: "submit",
    children: ">>noChildrensToShow",
    mainHeading: ">>emptyMainHeading",
    uniqueModalReferenceId: "NA",
    leftPartFlexValue: 1,
    rightPartFlexValue: 8,
    isButtonGroupRequired: true,
    isSubmitButtonDisabled: false,
    contentPartBackgroundColor:'#FFFFFF',
    isValidationRequired:true,
    isOnboardingPayment: false,
    amount: ""
}

SliderModalComponent.propTypes = {
    isVisible: PropTypes.bool,
    isCancelButtonVisible: PropTypes.bool,
    isResetButtonVisible: PropTypes.bool,
    isSubmitButtonVisible: PropTypes.bool,
    cancelButtonText: PropTypes.string,
    resetButtonText: PropTypes.string,
    submitButtonText: PropTypes.string,
    mainHeading: PropTypes.string,
    callback: PropTypes.func,
    uniqueModalReferenceId: PropTypes.string,
    leftPartFlexValue: PropTypes.number,
    rightPartFlexValue: PropTypes.number,
    isButtonGroupRequired: PropTypes.bool,
    isSubmitButtonDisabled: PropTypes.bool,
    contentPartBackgroundColor:PropTypes.string,
    isValidationRequired: PropTypes.bool,
    isOnboardingPayment: PropTypes.bool,
    amount: propTypes.string
}

const mapStateToProps = (state, ownProperties) => {
    return {
        locales: state.locales
    }
}

const SliderModalComponentContainer = connect(
    mapStateToProps
)(SliderModalComponent)

export default SliderModalComponentContainer;
