import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from 'axios';
import { MW_URL } from "../../../../config";
import moment from "moment";
import LoadingAnimation from "../../../../utils/loadingAnimation";


const Deadline = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [selectedStudent, setSelectedStudent] = useState({})
    const [listOfStudents, setlistOfStudents] = useState([]);
    const [listOfStudentsTemp, setlistOfStudentsTemp] = useState([]);
    const [essaysList, setessaysList] = useState([]);
    const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState(
        []
      );
    const [essaysTemplateList, setessaysTemplateList] = useState([]);


    const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);

    useEffect(() => {
        getlistofusersFromDb();
    }, [])

    useEffect(() => {
        getStudentEssays(selectedStudent);
    }, [selectedStudent])


    const getlistofusersFromDb = () => {
        axios
          .post(`${MW_URL}` + "users", {})
          .then((getUsersResponse) => {
            if (
              getUsersResponse &&
              getUsersResponse.data &&
              getUsersResponse.data[0]
            ) {
              axios
                .post(`${MW_URL}` + "getUpdates", {
                  recipientId: JSON.parse(localStorage.getItem("userData")).email,
                  senderId: false,
                })
                .then((getUpdatesResponse) => {
                  // console.log("getUpdatesResponse", getUpdatesResponse.data);
                  //  setupdatesList(getUpdatesResponse.data);
    
                  var userList = [];
                  for (var i = 0; i < getUsersResponse.data.length; i++) {
                    var currentObj = getUsersResponse.data[i];
                    var currentDate = new Date();
                    currentDate = currentDate.setDate(currentDate.getDate() - 2);
                    if (
                      currentObj.counsellorId ===
                      JSON.parse(localStorage.getItem("userData")).email
                    ) {
                      userList.push({
                        key: currentObj.email,
                        username: currentObj.username,
                        email: currentObj.email,
                        userType: currentObj.userType,
                        major: currentObj.major,
                        updates: getUpdatesResponse.data,
                        updatesNum: getUpdatesResponse.data.filter(
                          (updte) =>
                            updte.senderId == currentObj.email &&
                            moment(updte.updatedDate, "YYYY-MM-DD").isSameOrBefore(
                              new Date(),
                              "day"
                            ) &&
                            moment(updte.updatedDate, "YYYY-MM-DD").isSameOrAfter(
                              currentDate,
                              "day"
                            )
                        ).length,
                      });
                    }
                  }
                  console.log("userList===>", userList);
                  setlistOfStudents(userList);
                  setlistOfStudentsTemp(userList)
                  setSelectedStudent(userList[0])
                  let params = new URLSearchParams(window.location.search);
                  if (params.get("studentEmail")) {
                    // console.log("studentEmail", params.get("studentEmail"));
                    const selectedUser = userList.find(
                      (user) => user.email == params.get("studentEmail")
                    );
                    // console.log("selectedUser", selectedUser);
                    // showTextEditorMain(selectedUser);
                  }
                  setIsLoading(false)
                })
                .catch((err) => {
                  console.log("err >> ", err);
                });
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      };

    const getStudentEssays = (student) => {
        console.log("==============getStudentEssays fun  ========", student);
        // setIsLoading(true);
        setIsUseEffectCallRequired(false)
        var selectedCollages = [];
        var selectedCollagesIds = [];
        axios
          .post(`${MW_URL}` + "getSelectedListOfCollages", {
            email: student?.email,
          })
          .then((getSelectedListOfCollagesresponse) => {
            if (
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
                .length > 0
            ) {
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
                (collage) => {
                  selectedCollages.push(collage);
                  selectedCollagesIds.push(collage.collageId);
                }
              );
              setlistOfSelectedCollagesArr(selectedCollages);
              var essayReqIds = [];
              var templateArr = [];
              var essaysListToPush = [];
              var essaysRequirementIds = [];
              axios
                .post(`${MW_URL}` + "get_essay_requirement", selectedCollages)
                .then((getEssaysTemplateResponse) => {
                  var status = "NA";
                  if (
                    getEssaysTemplateResponse &&
                    getEssaysTemplateResponse.data &&
                    getEssaysTemplateResponse.data.STATUS
                  )
                    status = getEssaysTemplateResponse.data.STATUS;
                  if (status.toUpperCase() === "SUCCESS") {
                    setessaysTemplateList(getEssaysTemplateResponse.data.data);
                    getEssaysTemplateResponse.data.data.map((templateRes) => {
                      if (templateRes.essayRequirements.length != 0) {
                        templateRes.essayRequirements.map((esaaReq) => {
                            console.log(templateRes, "temp -collage- de")
                          essayReqIds.push(esaaReq.essayReqId);
                          templateArr.push({
                            template: esaaReq.question,
                            collageId: templateRes.collageId,
                            name: templateRes.name,
                            essayReqId: esaaReq.essayReqId,
                            length: esaaReq.length,
                            requirement: esaaReq.requirement,
                            answered: essaysRequirementIds.includes(
                              esaaReq.essayReqId
                            ),
                          });
                        });
                      }
                    });
                    axios
                      .post(`${MW_URL}` + "getEssays", { ownerId: student.email })
                      .then((getAllEssaysResponse) => {
                        var essaysListToPush = [];
                        var essaysRequirementIds = [];
                        if (getAllEssaysResponse.data.length > 0) {
                          getAllEssaysResponse.data.map((essay) => {
                            // console.log(essay, "essay by stud")
                            if (
                              essay.ownerId == student.email &&
                              selectedCollagesIds.includes(essay.collageId) &&
                              essayReqIds.includes(essay.essayReqId)
                            ) {
                              essaysListToPush.push({
                                essayId: essay.essayId,
                                content: essay.content,
                                newEditorContentEssay:essay.newEditorcontentEssay,
                                typeOfDocument: essay.typeOfDocument,
                                collageId: essay.collageId,
                                ownerId: essay.ownerId,
                                feedbacks: essay.feedbacks,
                                essayReqId: essay.essayReqId,
                                isApproved: essay.isApproved,
                                updatedDate: moment(essay.updatedDate).format("DD-MM-YYYY"),
                              });
                            }
                            essaysRequirementIds.push(essay.essayReqId);
                          });
                          const params = new URLSearchParams(
                            window.location.search
                          );
                          if (params.get("id")) {
                            const selectedEssay = essaysListToPush.find(
                              (essay) => essay.essayId == params.get("id")
                            );
                            // viewEssay(selectedEssay);
                          } else {
                            console.log("No params");
                          }
                          axios
                            .post(`${MW_URL}` + "getUpdates", {
                              recipientId: JSON.parse(
                                localStorage.getItem("userData")
                              ).email,
                              senderId: student.email,
                            })
                            .then((getUpdatesResponse) => {
                            //   setupdatesList(getUpdatesResponse.data);
                              setessaysTemplateList(
                                templateArr.map((reqEssay) => {
                                  return {
                                    ...reqEssay,
                                    answered: essaysRequirementIds.includes(
                                      reqEssay.essayReqId
                                    ),
                                  };
                                })
                              );
                              setIsLoading(false);
                            })
                            .catch((err) => {
                              console.log("err >> ", err);
                            });
                        } else {
                          console.log("essay == 0")
                          setessaysList([]);
                        }
                        setessaysList(essaysListToPush);
                        console.log(essaysListToPush, "essay list")
                      })
                      .catch((err) => {
                        console.log("err >> ", err);
                      });
                  } else {
                    setIsLoading(false);
                  }
                })
                .catch((err) => {
                  console.log("err >> ", err);
                });
            } else {
              setlistOfSelectedCollagesArr([]);
            //   setessaysTemplateList([]);
              setessaysList([]);
            //   setupdatesList([]);
              setIsLoading(false);
            }
          })
          .catch((err) => { });
      };
    

    const handleCollageChange = (e) => {
        console.log(e.target.value, "sel")
        setSelectedStudent(
            listOfStudents.find((stud) => stud.email === e.target.value)
        )
    }
    


  return (
    <div className='dashStats pr-3'>
        {isLoading ? <LoadingAnimation isVisible={true} /> : null}
        <div className='row my-1'>
            <div className='col-6'>
                <h3 className='dashHead3'>Deadline</h3>
            </div>
            <div className='col-6 d-flex justify-content-end '>
                {/* <button className='todoSetBtn'>
                All Collage -
                </button>  */}
                <select onChange={(e) => handleCollageChange(e)} className="deadlineSelect">
                    {listOfStudents.map((obj, index) => (
                        <option value={obj.email} key={index}>{obj.username}</option>
                    ))}
                    
                </select>

  
            </div>
        </div>

        <div className="counsDashDeadlineWrap">
            {essaysList?.map((obj, index) => (
                <div key={index} className='dashTodoCollage'>
                    <div className='row '>
                        <div className='col-8'>
                            {listOfSelectedCollagesArr?.map((collage) => (
                                collage?.collageId === obj?.collageId && (
                                    <p key={collage?.collageId} className='dashNormalText2'>{collage?.name}</p>
                                )
                            ))}
                            <div className='dashLightText'>{obj?.content.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, "")}</div> 
                        </div>
                        <div className='col-4'>
                            {listOfSelectedCollagesArr?.map((collage) => (
                                collage.collageId === obj?.collageId && (
                                    <p key={collage?.collageId} className='dashDeadlineText'>{collage?.deadline}</p>
                                )
                            ))}
                        </div>
                    </div>
                </div>
            ))}

            {/* <div className='dashTodoCollage'>
                <div className='row '>
                    <div className='col-8'>
                        <div className='dashNormalText'>Title </div>
                        <div className='dashLightText'>Lorem Ipsum is simply dummy text of the printing and…</div> 
                    </div>
                    <div className='col-4 '>
                        <div className='dashDeadlineText'>08/02/2022</div>
                    </div>
                </div>
            </div> */}
        </div>
    </div>
    
  )
}

export default Deadline;