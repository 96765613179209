import React from "react";
import { useEffect, useState, useRef, useCallback } from "react";
import axios from 'axios';
import { MW_URL } from "../../../config";
import moment from "moment";
import Queries from "./portal/queries";
import Stats from "./portal/Stats";
import Todo from "./portal/Todo";
import Updates from "./portal/Updates";
import Calender from "./calender/Index";
import Deadline from "./portal/Deadline";

const CounsellorDash = () => {

  const [refBtnText, setRefBtnText] = useState("Copy Referal Link")
  const username = JSON.parse(localStorage.getItem("userData")).username
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStudent, setSelectedStudent] = useState({})
  const [listOfStudents, setlistOfStudents] = useState([]);
  const [listOfStudentsTemp, setlistOfStudentsTemp] = useState([]);

  useEffect(() => {
    getlistofusersFromDb();
}, [])


const getlistofusersFromDb = () => {
  axios
    .post(`${MW_URL}` + "users", {})
    .then((getUsersResponse) => {
      if (
        getUsersResponse &&
        getUsersResponse.data &&
        getUsersResponse.data[0]
      ) {
        axios
          .post(`${MW_URL}` + "getUpdates", {
            recipientId: JSON.parse(localStorage.getItem("userData")).email,
            senderId: false,
          })
          .then((getUpdatesResponse) => {
            // console.log("getUpdatesResponse", getUpdatesResponse.data);
            //  setupdatesList(getUpdatesResponse.data);

            var userList = [];
            for (var i = 0; i < getUsersResponse.data.length; i++) {
              var currentObj = getUsersResponse.data[i];
              var currentDate = new Date();
              currentDate = currentDate.setDate(currentDate.getDate() - 2);
              if (
                currentObj.counsellorId ===
                JSON.parse(localStorage.getItem("userData")).email
              ) {
                userList.push({
                  key: currentObj.email,
                  username: currentObj.username,
                  email: currentObj.email,
                  userType: currentObj.userType,
                  major: currentObj.major,
                  updates: getUpdatesResponse.data,
                  updatesNum: getUpdatesResponse.data.filter(
                    (updte) =>
                      updte.senderId == currentObj.email &&
                      moment(updte.updatedDate, "YYYY-MM-DD").isSameOrBefore(
                        new Date(),
                        "day"
                      ) &&
                      moment(updte.updatedDate, "YYYY-MM-DD").isSameOrAfter(
                        currentDate,
                        "day"
                      )
                  ).length,
                });
              }
            }
            console.log("userList===>", userList);
            setlistOfStudents(userList);
            setlistOfStudentsTemp(userList)
            setSelectedStudent(userList[0])
            let params = new URLSearchParams(window.location.search);
            if (params.get("studentEmail")) {
              // console.log("studentEmail", params.get("studentEmail"));
              const selectedUser = userList.find(
                (user) => user.email == params.get("studentEmail")
              );
              // console.log("selectedUser", selectedUser);
              // showTextEditorMain(selectedUser);
            }
            setIsLoading(false)
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      }
    })
    .catch((err) => {
      console.log("err >> ", err);
    });
};

  const copyLink = () => {
    // navigator.clipboard.writeText("http://10.0.13.70/?counsellorId="
    //  +JSON.parse(localStorage.getItem("userData")).email );
    navigator.clipboard.writeText(JSON.parse(localStorage.getItem("userData")).email)
    setRefBtnText('Referal Link Copied!');
  }

  return (
    <div className="row dashborad" style={{ marginTop: "2%" }}>
      <div className="col-md-5 p-0 " >
      <div className="m-2 ">
        <Stats />
      </div>
      <div className="m-2 " style={{paddingTop : "5%"}}>
        <Todo />
      </div>
      <div className="m-2 " style={{paddingTop : "5%"}}>
        <Updates />
      </div>
      </div>

      <div className="col-md-4 p-1 pl-3">
        <div className="mx-2">
          <Calender />
        </div>

         <div className="d-flex mx-2">
              <div className="mx-2 dashHead3">{username}</div>
              <button className="referalBtn"
                onClick={() => copyLink()}
              >
                {refBtnText}
              </button>
         </div>

        <div className="m-2">
          <Deadline />
        </div>
      </div>


      <div className="col-md-3 p-0 ">
        <Queries listOfStudents={listOfStudents} selectedStudent={selectedStudent} setSelectedStudent={setSelectedStudent} />
      </div>


    </div>
  );
};

export default CounsellorDash;
