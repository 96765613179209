import React from 'react'
import { Calendar } from 'react-modern-calendar-datepicker'

const CalenderSec = () => {

  return (
    <div className='w-50'>
        <Calendar 
            calendarClassName='test-new'
            calendarTodayClassName="custom-today-day"
        />
    </div>
  )
}

export default CalenderSec