

import Toast from './toast.js'

//available classes to use
//mandatoryField : will be used in inputFieldMandatoryCheck(),to check if value empty or not
//specialCharactersAllowed : will be used in inputFieldValueCompositionCheck(),to check 
//                if value composition is matching with inputFieldValueCompositionWithSpecialCharactersRegex
//typeEmail : will be used in inputFieldValueCompositionCheck(),to check if value composition is matching with emailRegex
//typePassword : will be used in inputFieldValueCompositionCheck(),to check if value composition is matching with passwordRegex   

function inputFieldMandatoryCheck(formElemId, showToast) {
    console.log("hoeee")
    var isContinue = true;
    var allInputElements = document.getElementById(formElemId).getElementsByClassName('input_data');
    for (var i = 0; i < allInputElements.length; i++) {
        var currentInputElement = allInputElements.item(i);
        var currentErrorDescElement = currentInputElement.closest(".form-group").getElementsByClassName("errorDesc")[0];
        var currentMandatoryErrorMessage = currentInputElement.getAttribute("mandatoryErrorMessage");
        currentInputElement.classList.remove("erroredInput");//remove class if already errored
        currentErrorDescElement.innerHTML = "";
        if (currentInputElement.classList.contains('mandatoryField') || currentInputElement.classList.contains('mandatoryFieldSpecial') || currentInputElement.classList.contains('mandatoryFieldWithoutStyle') || currentInputElement.classList.contains('mandatoryFieldWithSozaStyle')) {
            if (currentInputElement.classList.contains('typeReactSelect')) {
                if (currentInputElement.querySelectorAll('div[class$="multiValue"]').length < 1) {
                    isContinue = false;
                    currentInputElement.classList.add("erroredInput");
                    if (currentMandatoryErrorMessage) {
                        currentErrorDescElement.innerHTML = currentMandatoryErrorMessage;
                    } else {
                        currentErrorDescElement.innerHTML = "mandatory field";
                    }
                }
            } else if (currentInputElement.value === "") {
                isContinue = false;
                currentInputElement.classList.add("erroredInput");
                if (currentMandatoryErrorMessage) {
                    currentErrorDescElement.innerHTML = currentMandatoryErrorMessage;
                } else {
                    currentErrorDescElement.innerHTML = "Mandatory field";
                }
            }
        }
    }
    if (showToast) {
        if (!isContinue) { Toast.showToast("Fill all mandatory fields"); }
    }
    return isContinue;
}

function inputFieldValueCompositionCheck(formElemId, showToast) {

    //regex initialisation
    var emailRegexConfig = "^([a-zA-Z0-9_.+-]){2,20}\\@(([a-zA-Z0-9-]){1,8}\\.)+([a-zA-Z0-9]){1,6}$";
    var contactNumRegexConfig = "^[0-9]{10}$";
    //var contactNumRegexConfig = "^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$";
    var inputFieldValueCompositionRegexConfig = "^[a-zA-Z0-9]+[a-zA-Z0-9 ]*[a-zA-Z0-9 ]*$";
    var inputFieldValueCompositionWithSpecialCharactersRegexConfig = "^([a-zA-Z0-9_ @.,/-: #-]+)$";
    var passwordRegexConfig = "^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&])[A-Za-z0-9@$!%*?&]{8,16})$";
    var numberRegexConfig = "^[0-9]+$";
    var zipRegexConfig = "^[0-9-]+$";
    var nameRegexConfig = "^[A-za-z0-9]{2,15}[a-zA-Z0-9_]$";
    var textRegexConfig = "^[a-zA-Z]+$";
    var descriptionRegexConfig = "^[0-9./$ ]*[A-Za-z]{3,}[0-9A-Za-z ]*$";
    var pinRegexConfig = "^[0-9]{4}$";
    var dataRegexConfig = "^[A-Za-z0-9-_]{3,20}$";

    let inputFieldValueCompositionRegex = new RegExp(inputFieldValueCompositionRegexConfig);
    let inputFieldValueCompositionWithSpecialCharactersRegex = new RegExp(inputFieldValueCompositionWithSpecialCharactersRegexConfig);
    let passwordRegex = new RegExp(passwordRegexConfig);
    let emailRegex = new RegExp(emailRegexConfig);
    let numberRegex = new RegExp(numberRegexConfig);
    let contactNumRegex = new RegExp(contactNumRegexConfig);
    let zipRegex = new RegExp(zipRegexConfig);
    let nameRegex = new RegExp(nameRegexConfig);
    let textRegex = new RegExp(textRegexConfig);
    let dataRegex = new RegExp(dataRegexConfig);
    let descriptionRegex = new RegExp(descriptionRegexConfig);
    let pinRegex = new RegExp(pinRegexConfig);
    var isContinue = true;
    var allInputElements = document.getElementById(formElemId).getElementsByClassName('input_data');
    for (var i = 0; i < allInputElements.length; i++) {
        var currentInputElement = allInputElements.item(i);
        var currentErrorDescElement = currentInputElement.closest(".form-group").getElementsByClassName("errorDesc")[0];
        var currentInputElementValue = "";

        if (currentInputElement.getAttribute("originalValue")) {
            currentInputElementValue = currentInputElement.getAttribute("originalValue");
        } else {
            currentInputElementValue = currentInputElement.value;
        }

        var currentValueCompositionErrorMessage = currentInputElement.getAttribute("valueCompositionErrorMessage");
        var isSpecialCharactersAllowed = false;
        var isEmail = false;
        var isPassword = false;
        var isNumber = false;
        var isContactNum = false;
        var isZip = false;
        var isTypeName = false;
        var isTypeText = false;
        var isMandatory = false;
        var isTypeEmailOrName = false;
        var isValidDescription = false;
        var isValidData = false;
        var isPin = false;

        if (currentInputElement.classList.contains('specialCharactersAllowed')) { isSpecialCharactersAllowed = true }
        if (currentInputElement.classList.contains('typeEmail')) { isEmail = true }
        if (currentInputElement.classList.contains('typePassword')) { isPassword = true }
        if (currentInputElement.classList.contains('typeNumber')) { isNumber = true }
        if (currentInputElement.classList.contains('typeText')) { isTypeText = true }
        if (currentInputElement.classList.contains('typeContactNum')) { isContactNum = true }
        if (currentInputElement.classList.contains('typeZip')) { isZip = true }
        if (currentInputElement.classList.contains('typeName')) { isTypeName = true }
        if (currentInputElement.classList.contains('validDescription')) { isValidDescription = true }
        if (currentInputElement.classList.contains('validData')) { isValidData = true }
        if (currentInputElement.classList.contains('typeEmailOrName')) { isTypeEmailOrName = true }
        if (currentInputElement.classList.contains('mandatoryField')) { isMandatory = true }
        if (currentInputElement.classList.contains('mandatoryFieldSpecial')) { isMandatory = true }
        if (currentInputElement.classList.contains('mandatoryFieldWithoutStyle')) { isMandatory = true }
        if (currentInputElement.classList.contains('typePin')) { isPin = true }

        // currentInputElement.classList.remove("erroredInput");//remove class if already errored
        if (currentErrorDescElement.innerHTML === "") { currentErrorDescElement.innerHTML = ""; }
        if (isEmail) {
            if (currentInputElementValue !== "" && emailRegex.test(currentInputElementValue) !== true) {
                isContinue = false;
                currentInputElement.classList.add("erroredInput");
                if (currentValueCompositionErrorMessage) {
                    currentErrorDescElement.innerHTML = currentValueCompositionErrorMessage;
                } else {
                    currentErrorDescElement.innerHTML = "Please enter valid Email Address";
                }
            }
        } else if (isPassword) {
            if (currentInputElementValue !== "" && passwordRegex.test(currentInputElementValue) !== true) {
                isContinue = false;
                currentInputElement.classList.add("erroredInput");
                if (currentValueCompositionErrorMessage) {
                    currentErrorDescElement.innerHTML = currentValueCompositionErrorMessage;
                } else {
                    currentErrorDescElement.innerHTML = "Invalid format";
                }
            }
        } else if (isSpecialCharactersAllowed) {
            if (currentInputElementValue !== "" && inputFieldValueCompositionWithSpecialCharactersRegex.test(currentInputElementValue) !== true) {
                isContinue = false;
                currentInputElement.classList.add("erroredInput");
                if (currentValueCompositionErrorMessage) {
                    currentErrorDescElement.innerHTML = currentValueCompositionErrorMessage;
                } else {
                    currentErrorDescElement.innerHTML = "Please enter valid value";
                }
            }
        } else if (isNumber) {
            if (currentInputElementValue !== "" && numberRegex.test(currentInputElementValue) !== true) {
                isContinue = false;
                currentInputElement.classList.add("erroredInput");
                if (currentValueCompositionErrorMessage) {
                    currentErrorDescElement.innerHTML = currentValueCompositionErrorMessage;
                } else {
                    currentErrorDescElement.innerHTML = "Please enter only numeric value";
                }
            }
        } else if (isContactNum) {
            if (isMandatory && currentInputElementValue !== "" && contactNumRegex.test(currentInputElementValue) !== true) {
                isContinue = false;
                currentInputElement.classList.add("erroredInput");
                if (currentValueCompositionErrorMessage) {
                    currentErrorDescElement.innerHTML = currentValueCompositionErrorMessage;
                } else {
                    currentErrorDescElement.innerHTML = "Please enter valid phone number";
                }
            }
            else if (currentInputElementValue !== "+1" && contactNumRegex.test(currentInputElementValue) !== true) {
                isContinue = false;
                currentInputElement.classList.add("erroredInput");
                if (currentValueCompositionErrorMessage) {
                    currentErrorDescElement.innerHTML = currentValueCompositionErrorMessage;
                } else {
                    currentErrorDescElement.innerHTML = "Please enter valid phone number";
                }
            }
        } else if (isZip) {
            if (currentInputElementValue !== "" && zipRegex.test(currentInputElementValue) !== true) {
                isContinue = false;
                currentInputElement.classList.add("erroredInput");
                if (currentValueCompositionErrorMessage) {
                    currentErrorDescElement.innerHTML = currentValueCompositionErrorMessage;
                } else {
                    currentErrorDescElement.innerHTML = "invalid values";
                }
            }
        } else if (isTypeName) {
            if (currentInputElementValue !== "" && nameRegex.test(currentInputElementValue) !== true) {
                isContinue = false;
                currentInputElement.classList.add("erroredInput");
                if (currentValueCompositionErrorMessage) {
                    currentErrorDescElement.innerHTML = currentValueCompositionErrorMessage;
                } else {
                    currentErrorDescElement.innerHTML = "Please enter valid name";
                }
            }
        } else if (isTypeText) {
            if (currentInputElementValue !== "" && textRegex.test(currentInputElementValue) !== true) {
                isContinue = false;
                currentInputElement.classList.add("erroredInput");
                if (currentValueCompositionErrorMessage) {
                    currentErrorDescElement.innerHTML = currentValueCompositionErrorMessage;
                } else {
                    currentErrorDescElement.innerHTML = "Please enter valid name";
                }
            }
        } else if (isValidDescription) {
            if (currentInputElementValue !== "" && descriptionRegex.test(currentInputElementValue) !== true) {
                isContinue = false;
                currentInputElement.classList.add("erroredInput");
                if (currentValueCompositionErrorMessage) {
                    currentErrorDescElement.innerHTML = currentValueCompositionErrorMessage;
                } else {
                    currentErrorDescElement.innerHTML = "invalid values";
                }
            }
        } else if (isValidData) {
            if (currentInputElementValue !== "" && dataRegex.test(currentInputElementValue) !== true) {
                isContinue = false;
                currentInputElement.classList.add("erroredInput");
                if (currentValueCompositionErrorMessage) {
                    currentErrorDescElement.innerHTML = currentValueCompositionErrorMessage;
                } else {
                    currentErrorDescElement.innerHTML = "invalid values";
                }
            }
        } else if (isTypeEmailOrName) {
            if (currentInputElementValue !== "" && (nameRegex.test(currentInputElementValue) !== true && emailRegex.test(currentInputElementValue) !== true)) {
                isContinue = false;
                currentInputElement.classList.add("erroredInput");
                if (currentValueCompositionErrorMessage) {
                    currentErrorDescElement.innerHTML = currentValueCompositionErrorMessage;
                } else {
                    currentErrorDescElement.innerHTML = "Please enter valid username";
                }
            }
        } else if (isPin) {
            if (currentInputElementValue !== "" && pinRegex.test(currentInputElementValue) !== true) {
                isContinue = false;
                currentInputElement.classList.add("erroredInput");
                if (currentValueCompositionErrorMessage) {
                    currentErrorDescElement.innerHTML = currentValueCompositionErrorMessage;
                } else {
                    currentErrorDescElement.innerHTML = "PIN should be a 4 digit number";
                }
            }
        } else {
            // if (currentInputElementValue !== "" && inputFieldValueCompositionRegex.test(currentInputElementValue) !== true) {
            //     isContinue = false;
            //     currentInputElement.classList.add("erroredInput");
            //     if (currentValueCompositionErrorMessage) {
            //         currentErrorDescElement.innerHTML = currentValueCompositionErrorMessage;
            //     } else {
            //         currentErrorDescElement.innerHTML = "Please enter correct values";
            //     }
            // }
        }
    }
    if (showToast) {
        if (!isContinue) { Toast.showToast("Invalid values"); }
    }

    return isContinue;
}

function inputFieldMaxLengthCheck(formElemId, showToast) {
    var inputFieldMaxLengthRegexConfig = "^.{0,50}$";
    let inputFieldMaxLengthRegex = new RegExp(inputFieldMaxLengthRegexConfig);
    var isContinue = true;
    var allInputElements = document.getElementById(formElemId).getElementsByClassName('input_data');
    for (var i = 0; i < allInputElements.length; i++) {
        var currentInputElement = allInputElements.item(i);
        var currentErrorDescElement = currentInputElement.closest(".form-group").getElementsByClassName("errorDesc")[0];
        var currentInputElementValue = currentInputElement.value;
        var currentMaxLengthErrorMessage = currentInputElement.getAttribute("maxLengthErrorMessage");

        // var allowedMinLength = currentInputElement.getAttribute("minLength"); 
        // var allowedMaxLength = currentInputElement.getAttribute("maxLength"); 
        // var currentValueLength = currentInputElementValue.length;

        if (currentErrorDescElement.innerHTML === "") { currentErrorDescElement.innerHTML = ""; }
        // if(allowedMaxLength && (currentValueLength > allowedMaxLength)){
        //     isContinue = false;
        //     currentInputElement.classList.add("erroredInput");
        //     if(currentMaxLengthErrorMessage){
        //         currentErrorDescElement.innerHTML = currentMaxLengthErrorMessage;
        //     }else{
        //         currentErrorDescElement.innerHTML = "max length exceeded";
        //     }
        // }else 
        if (inputFieldMaxLengthRegex.test(currentInputElementValue) !== true) {
            isContinue = false;
            currentInputElement.classList.add("erroredInput");
            if (currentMaxLengthErrorMessage) {
                currentErrorDescElement.innerHTML = currentMaxLengthErrorMessage;
            } else {
                currentErrorDescElement.innerHTML = "max length exceeded";
            }
        }
    }
    if (showToast) {
        if (!isContinue) { Toast.showToast("max length exceeded"); }
    }
    return isContinue;
}

export default {
    inputFieldMandatoryCheck, inputFieldValueCompositionCheck, inputFieldMaxLengthCheck
}
