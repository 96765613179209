import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const AlertModal = ({ show, onHide, title, message, onCancel, onConfirm }) => {
  return (
    <Modal centered size='sm' show={show} onHide={onHide}>
      <div>
        <div className='alertModalHead'>
          <p className='alertHeadText mx-3 my-2'>{title}</p>
          <div className='m-2'>
            <AiOutlineCloseCircle onClick={onHide} className='alertCloseBtn' />
          </div>
        </div>
        <div className='text-center mt-3 mb-5'>
          <p className='normalText2'>{message}</p>
        </div>
        <div className='d-flex justify-content-center pb-3'>
          <button onClick={onCancel} className='essayRejectBtn1 mx-3'>
            Cancel
          </button>
          <button onClick={onConfirm} className='essayRejectBtn2 mx-3'>
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AlertModal;
