import React, { useEffect, useState } from "react";
import UnderConstruction from "../../imageAssets/underConstruction.png";
import TableComponent from "../../utils/tableComponent";
import LoadingAnimation from "../../utils/loadingAnimation";
import { FaEdit, FaEye, FaTrash, FaFileDownload, FaFile, FaTrashAlt } from "react-icons/fa";

import ModalComponent from "../../utils/modalComponent";
import SliderModal from "../../utils/sliderModal.js";
import ValidateForm from "../../utils/formValidation";
import { Button, Input } from "reactstrap";
import axios from "axios";
import { MW_URL } from "../../config";
import { FaCaretDown } from "react-icons/fa";
import Checkbox from "@mui/material/Checkbox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { width } from "@mui/system";
import moment from 'moment';
import {
  Dropdown,
  MultiSelect,
  SelectItem,
  SelectPanel,
} from "react-multi-select-component";
import AlertModal from "../../components/widgets/AlertModal";
import { ToastContainer, toast } from 'react-toastify';


const UsersScreen = () => {
  const createUniquieId = () => {
    const head = Date.now().toString(36);
    const tail = Math.random().toString(36).substr(2);
    return head + tail;
  };
  const [height, setHeight] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownDivScholarship, setdropdownDivScholarship] = useState(false);
  const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
  const [viewMoreSliderVisible, setViewMoreSliderVisible] = useState(false);
  const [base64Value, setBase64] = useState(false);
  const [collegeDetailsPayload, setCollegeDetailsPayload] = useState([]);
  const [CollegeList, setCollegeDetailsoptions] = useState([]);
  const [payloadLength, setPayloadLength] = useState([]);
  const [selected, setSelected] = useState([]);
  const [studentActiveTab, setStudentActiveTab] = useState(true);
  const [counsellorActiveTab, setCounsellorActiveTab] = useState(false);
  const [currentTab, setCurrentTab] = useState("students");
  const [docModalOpen, setDocModalOpen] = useState(false);
  const [docListModalOpen, setDocListModalOpen] = useState(false);
  var [isAllSelected, setIsallSelected] = useState(false);
  const [currentClgReqArr, setcurrentClgReqArr] = useState([]);
  const [totalCollegeLength, setTotalCollegeLength] = useState([]);
  const [currentDocReqArr, setDocListPayload] = useState([]);
  const [showDocDelete, setShowDocDelete] = useState(false)
  const [docId, setDocId] = useState("")
  const [file, setfile] = useState({
    file: []
  });
  const [startDate, setStartDate] = useState(new Date());
  const [documentDetailsPayload, setdocumentDetailsPayload] = useState({
    totalRecords: 0,
    data: []
  });
  const [editState, setEditState] = React.useState({
    fileName: "",
    fileType: "",
    fileSize: "",
    fileType: "",
    requirement: "",
    file: "",
    documentId: "",
    deadline: ""
  });
  const handleChange = (e) => {
    console.log("test1212-----1211", e)
    const { name, value } = e.target;
    console.log(name, value, "test-----1211")
    setEditState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    var windowHeight = window.innerHeight;
    setHeight(windowHeight + "px");
    setIsLoading(true);
    setCollegeDetailsPayload({
      totalRecords: 0,
      data: [],
    });
    console.log("use Effect");
    axios
      .post(`${MW_URL}` + "getAllCollegeDetails", {})
      .then((getCollegeDetailsResponse) => {
        setIsUseEffectCallRequired(false);
        var allCollegeDetail = [];
        var opt = [];
        if (
          getCollegeDetailsResponse &&
          getCollegeDetailsResponse.data &&
          getCollegeDetailsResponse.data[0]
        ) {
          for (var i = 0; i < getCollegeDetailsResponse.data.length; i++) {
            var currentObj = getCollegeDetailsResponse.data[i];
            var to = {
              label: currentObj.name,
              value: currentObj.collageId,
            }
            var tempObj = {
              id: currentObj.collageId,
              label: currentObj.name,
              value: currentObj.collageId,
              name: currentObj.name,
              description: currentObj.description,
              country: currentObj.country,
              essayRequirements: currentObj.essayRequirements,
            };
            allCollegeDetail.push(tempObj);
            opt.push(to)
          }
        }
        setCollegeDetailsPayload({
          totalRecords: allCollegeDetail.length,
          data: allCollegeDetail,
        });
        console.log(opt, "collage opt")
        setCollegeDetailsoptions(opt);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  }, [isUseEffectCallRequired]);


  useEffect(() => {
    var windowHeight = window.innerHeight;
    setHeight(windowHeight + "px");
    setIsLoading(true)
    axios.post(`${MW_URL}` + "documents", {})
      .then(getDocumentDetailsResponse => {
        setIsLoading(false);
        setIsUseEffectCallRequired(false);
        console.log(getDocumentDetailsResponse,"getDocumentDetailsResponse---")
        var allDocumentDetail = [];
        if (getDocumentDetailsResponse && getDocumentDetailsResponse.data && getDocumentDetailsResponse.data[0]) {
          for (var i = 0; i < getDocumentDetailsResponse.data.length; i++) {
            var currentObj = getDocumentDetailsResponse.data[i];
            var tempObj = {
              "id": currentObj["_id"],
              "name": currentObj.fileName,
              "fileType": currentObj.fileType,
              "fileSize": currentObj.fileSize,
              "requirement": currentObj.requirement,
              "deadline": currentObj.deadline,
              file: currentObj.file,
              documentId: currentObj.documentId,

            }
            allDocumentDetail.push(tempObj)
          }
        }
        // setfile({
        //   file:allDocumentDetail
        // })
        setdocumentDetailsPayload({
          "totalRecords": allDocumentDetail.length,
          "data": allDocumentDetail,

        })
      })

      .catch(err => {
        console.log("err >> ", err);
      })

  }, [isUseEffectCallRequired])

   const downloadfile = (rowData) => {
     console.log("row",rowData)
    setIsLoading(true)
    axios
      .post(`${MW_URL}` + "getDocById", { documentId: rowData.documentId })
      .then((getDocumentDetailResponse) => {
        console.log("data", getDocumentDetailResponse.data.data)
        var status = "NA";
        var documentFile = [];
        status = getDocumentDetailResponse.data.STATUS;
        if (status.toUpperCase() === "SUCCESS") {
          if (
            getDocumentDetailResponse &&
            getDocumentDetailResponse.data
          ) {
            getDocumentDetailResponse.data.data.map((file) => {
              {
                documentFile.push(file.file)
              }
            });
          }
          setIsLoading(false)
          download(documentFile, rowData.name);
        }
      }).catch((err) => {
        console.log("err >> ", err);
      });
  }
  const collegeDetailsTableHandler = (dataObject) => {
    if (
      dataObject.type === "firstTimeLoad" ||
      dataObject.type === "pagination"
    ) {
    }
  };
  const triggerCallBack = () => {
    setEditState({
      counsellorName: "",
      counsellorEmail: "",
      counsellorMobileNum: "",
      counsellorId: "",
    });
    setViewMoreSliderVisible(false);
  };

  const uploadFile = async (e) => {
    // console.log(e.target.files)
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBase64(base64);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const documentDetailsTableHandler = (dataObject) => {
    if (dataObject.type === "firstTimeLoad" || dataObject.type === "pagination") {

    }
  }
  const options = [
    {
      label: collegeDetailsPayload.label,
      value: collegeDetailsPayload.collageId,
    },
  ];
  // if (startDate) {
  //   const date = startDate.toISOString();
  //   var deadline = date.substring(0, date.indexOf('T'));
  //   console.log("////---deadline", deadline)
  // }
  const docListModalHandler = (dataObject) => {
    const date = startDate.toISOString();
    var deadline = date.substring(0, date.indexOf('T'));

    if (dataObject.type === "cancel") {
      setDocListModalOpen(false);
      setEditState({
        fileName: "",
        requirement: "",
        fileSize: "",
        collegeId: "",
        fileType: "",
        file: "",
        deadline: "",
      });
      // window.location.reload();
    }
    else if (dataObject.type === "submit") {
      if (ValidateForm.inputFieldMandatoryCheck("editdocument", false)) {
        if (
          ValidateForm.inputFieldValueCompositionCheck(
            "editdocument",
            false
          )
        )
          var dataToSend = {
            documentId: editState.documentId,
            fileName: editState.fileName,
            fileSize: editState.fileSize,
            fileType: editState.fileType,
            requirement: editState.requirement,
            file: base64Value,
            length: payloadLength,
            deadline: editState.deadline,
          };

        console.log("aaa", dataToSend)
        setIsLoading(false);
        axios
          .post(`${MW_URL}` + "editdocument", dataToSend)
          .then((geteditdocumentsResponse) => {
            setIsLoading(false);
            if (
              geteditdocumentsResponse &&
              geteditdocumentsResponse.data &&
              geteditdocumentsResponse.data.STATUS === "SUCCESS"
            ) {
              setEditState({
                fileName: "",
                requirement: "",
                fileSize: "",
                collegeId: "",
                fileType: "",
                file: "",
                deadline: "",

                length: [],
                isAllSelected: false,
                deadline: startDate,
              });
              toast.success("Document edited successfully", {
                autoClose: 1000,
              });
              setIsUseEffectCallRequired(true);
            } else {
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
        setDocListModalOpen(false);
        // window.location.reload();
      };
    }
  }
    const docModalHandler = (dataObject) => {
      const date = startDate.toISOString();
      var deadline = date.substring(0, date.indexOf('T'));

      if (dataObject.type === "cancel") {
        setDocModalOpen(false);
        setEditState({
          fileName: "",
          requirement: "",
          fileSize: "",
          collegeId: "",
          fileType: "",
          file: "",
          deadline: "",
        });
      } else if (dataObject.type === "submit") {
        if (ValidateForm.inputFieldMandatoryCheck("adddocument", false)) {
          if (
            ValidateForm.inputFieldValueCompositionCheck(
              "adddocument",
              false
            )
          )
            var dataToSend = {
              documentId: createUniquieId(),
              collegeId: selected,
              fileName: editState.fileName,
              fileSize: editState.fileSize,
              fileType: editState.fileType,
              requirement: editState.requirement,
              file: base64Value,
              length: payloadLength,
              isAllSelected: isAllSelected,
              deadline: deadline,
            };
          setIsLoading(true);
          axios
            .post(`${MW_URL}` + "uploaddocuments", dataToSend)
            .then((getAddEssayResponse) => {
              console.log(getAddEssayResponse, "res----errrrr012")
              setIsLoading(false);
              if (
                getAddEssayResponse &&
                getAddEssayResponse.data &&
                getAddEssayResponse.data.STATUS === "SUCCESS"
              ) {
                setDocModalOpen(false)
                toast.success("Document uploaded successfully", {
                  autoClose: 1000,
                });
                setEditState({
                  fileName: "",
                  requirement: "",
                  fileSize: "",
                  collegeId: "",
                  fileType: "",
                  file: "",
                  deadline: "",

                  length: [],
                  isAllSelected: false,
                  deadline: startDate,
                });
                setStartDate(new Date())
                setIsUseEffectCallRequired(true);
              } else {
                console.log(getAddEssayResponse, "res----errrrr")
              }
            })
            .catch((err) => {
              console.log("err >> ", err);
            });
        };
      }
    }
    const tablinkClicked = (type) => {
      if (type === "students") {
        setStudentActiveTab(true)
        setCounsellorActiveTab(false)
        setCurrentTab(type)
      } else {
        setStudentActiveTab(false)
        setCounsellorActiveTab(true)
        setCurrentTab(type)
      }
    }

    const OnChangeMultiSelect = (option) => {
      setSelected(option);
      if (collegeDetailsPayload.totalRecords == option.length) {
        isAllSelected = true;
        setIsallSelected(isAllSelected);
      }
    };

    const actionDocBtnClicked = (type, receivedData) => {

      setDocModalOpen(true);

    }
    const handleStudentDeleteBtnClick = (docId) => {
      console.log(docId, "stud id")
      setShowDocDelete(true)
      setDocId(docId)
    }
    const actionDocListBtnClicked = (type, receivedData) => {
      console.log(receivedData, "receivedData")
      if (type === "delete") {
        setIsLoading(true);
        axios
          .post(`${MW_URL}` + "deletedocument/" + docId, {})
          .then((getDeleteCollegeResponse) => {
            setIsLoading(false);
            if (
              getDeleteCollegeResponse &&
              getDeleteCollegeResponse.data &&
              getDeleteCollegeResponse.data.STATUS === "SUCCESS"
            ) {
              setIsUseEffectCallRequired(true);
              setShowDocDelete(false)
              toast.success("Document deleted successfully", {
                autoClose: 2000,
                pauseOnHover: true,
              });
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
        // window.location.reload();
      } else if (type === "edit") {
        setEditState({
          documentId: receivedData.documentId,
          fileName: receivedData.name,
          fileSize: receivedData.fileSize,
          requirement: receivedData.requirement,
          fileType: receivedData.fileType,
          file: receivedData.file,
          Id: receivedData.id,
          deadline: receivedData.deadline
        });
        setDocListModalOpen(true);
      }
    };
    function download(data, Name) {
      console.log("decode", Name)
      const linkSource = `${data}`;
      const downloadLink = document.createElement('a');
      const fileName = Name;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }

    const handleDateChange = (date) => {
      // Check if the selected date is not a past date
      if (date >= new Date()) {
        setStartDate(date);
      } else {
        // You can show an error message or handle it in any other way
        toast.error("Unable to select past dates", {
          autoClose: 1000,
        })
      }
    };

    const handleDateEditChange = (date) => {
      console.log(date, "date--")
      if (date >= new Date()) {
        // const formattedDate = new Date(date).toISOString().split('T')[0];
        const formattedDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
          .toISOString()
          .split('T')[0];
        console.log(formattedDate, "formattedDate--")

        setEditState((prevState) => ({
          ...prevState,
          "deadline": formattedDate,
        }));
      } else {
        // You can show an error message or handle it in any other way
        toast.error("Unable to select past dates", {
          autoClose: 1000,
        })
      }

    }
    

    return (
      <div>
        {isLoading ? <LoadingAnimation isVisible={true} /> : null}
        <div
          className="college-page-container col-md-12 px-5 d-flex flex-column pt-sm-5 pt-2"
          style={{ minHeight: height }}
        >
          <div className="col-md-12 px-3">
            <div className="col-md-12 pt-3 px-0">
              <div className="col-md-12 tab-data">
                <div className="p-0 d-flex" >
                  <div
                    className="py-1  ml-0 my-2 col-md-2 d-flex justify-content-center add-college-btn mt-4"
                    onClick={() => actionDocBtnClicked()}
                  >
                    Add Document
                  </div>
                </div>
                <div className="col-md-12 college-details-container p-4">
                  <TableComponent
                    headerArray={[
                      {
                        key: "name",
                        label: "File Name",
                      },
                      {
                        key: "fileType",
                        label: "File Type",
                      },
                      {
                        key: "fileSize",
                        label: "File Size",
                      },
                      {
                        key: "requirement",
                        label: "Requirement",
                      },
                      {
                        key: "deadline",
                        label: "Deadline",
                      },
                      {
                        key: "requirement",
                        label: "Action",
                        formatter: (rowData, callback) => {
                          return (
                            <div>
                              <span className="pl-2">

                                <FaFileDownload

                                  onClick={() => downloadfile(rowData)}


                                  style={{ color: "#6C63FF", cursor: "pointer" }}
                                />
                              </span>


                              <span className="pl-2">
                                <FaEdit
                                  onClick={() =>
                                    actionDocListBtnClicked("edit", rowData)
                                  }
                                  style={{ color: "#6C63FF", cursor: "pointer" }}
                                />

                              </span>
                              <span className="ml-2">
                                <FaTrash
                                  onClick={() =>
                                    handleStudentDeleteBtnClick(rowData.documentId)
                                  }
                                  style={{ color: "#6C63FF", cursor: "pointer" }}
                                />

                              </span>
                            </div>
                          );
                        },
                      },
                    ]}
                    payload={documentDetailsPayload}
                    // isSearchRequired={true}
                    noDataContentText="No Document data Found"
                    limit={10}
                    tableUniqueId="documentDetailsTable"
                    paginationRequired={true}
                    paginationType="client"
                    callback={collegeDetailsTableHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          <ModalComponent
            isVisible={docModalOpen}
            mainHeading="Add Document"
            callback={docModalHandler}
            modalSize="xl"
          >
            <div id="adddocument">
              <div className="col-md-12 p-0 d-flex form-group">
                <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column mt-3">
                  <Input
                    type="text"
                    autoComplete="new-password"
                    style={{ width: "95%" }}
                    className="text-box-style my-3 py-2 input_data mandatoryField"
                    name="fileName"
                    placeholder="File Name"
                    value={editState.fileName}
                    onChange={handleChange}
                  />
                  <div className="errorDesc"></div>
                </div>
                <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column">
                  <label for="file-select">Choose File Type:</label>
                  <select
                    name="fileType"
                    value={editState.fileType}
                    onChange={handleChange}
                    className="file-select input_data mandatoryField"
                    style={{ width: "95%", height: 40 }}
                    required
                  >
                    <option value=""> choose an option</option>
                    <option value="jpeg">JPEG</option>
                    <option value="jpg">JPG</option>
                    <option value="png">PNG</option>
                    <option value="pdf">PDF</option>
                    <option value="docx">DOCX</option>
                    <option value="Doc">Doc</option>
                  </select>
                  <div className="errorDesc"></div>
                </div>
                <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column">
                  <label for="file-select">Choose File Size:</label>
                  <select
                    name="fileSize"
                    value={editState.fileSize}
                    onChange={handleChange}
                    className="file-select input_data mandatoryField"
                    style={{ width: "95%", height: 40 }}
                  >
                    <option value=""> choose an option</option>
                    <option value="50">50 KB</option>
                    <option value="100">100 KB</option>
                    <option value="150">150 KB</option>
                    <option value="200">200 KB</option>
                    <option value="250">250 KB</option>
                    <option value="300">300 KB</option>
                  </select>
                  <div className="errorDesc"></div>
                </div>
              </div>
              <div className="col-md-12 p-0 d-flex form-group">
                <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column mt-4 ">
                  <select
                    name="requirement"
                    value={editState.requirement}
                    onChange={handleChange}
                    className="requirement input_data mandatoryField"
                    style={{ width: "95%", height: 40 }}
                  >
                    <option value="">Choose Requirement</option>
                    <option value="Mandatory">Mandatory</option>
                    <option value="optional">Optional</option>
                  </select>
                  <div className="errorDesc"></div>
                </div>
                <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column ">
                  <label for="file">Upload File</label>
                  <Input
                    type="file"
                    style={{ width: "95%" }}
                    id="file"
                    name="file"
                    className="input_data mandatoryField"
                    // accept=".png, .jpg, .jpeg, .pdf"
                    accept={`.${editState.fileType}`}
                    onChange={(e) => {
                      uploadFile(e);
                    }}
                  />
                  <div className="errorDesc"></div>
                </div>
                <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column ">
                  <div className="" style={{ width: "100%" }}>
                    <label for="Date">Select Deadline</label>
                    <DatePicker style={{ width: 100 }}
                      selected={startDate}
                      onChange={handleDateChange}
                      value={moment(startDate).format('DD/MM/YYYY')}
                      format="dd/MM/yyyy"
                      className="input_data mandatoryField"
                      dateFormat={moment(startDate).format('dd/MM/yyyy')}
                      placeholderText="Select Date"
                      name="Date"
                    />
                    <div className="errorDesc"></div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 p-0 d-flex form-group">
                <div className="col-md-6 p-0 d-flex justify-content-start form-group flex-column ">
                  <div className="" style={{ width: "65%" }}>
                    <label>Select College</label>
                    {/* <pre>{JSON.stringify(selected)}</pre> */}
                    <MultiSelect
                      options={CollegeList}
                      value={selected}
                      onChange={OnChangeMultiSelect}
                      labelledBy="Select"
                    />
                  </div>
                </div>
              </div>
            </div>



          </ModalComponent>
          <ModalComponent
            isVisible={docListModalOpen}
            mainHeading="Document List"
            callback={docListModalHandler}
            modalSize="xl"
          >
            <div id="editdocument">
            <div className="col-md-12 p-0 d-flex form-group">
              <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column mt-3">
                <Input
                  type="text"
                  autoComplete="new-password"
                  style={{ width: "95%" }}
                  className="text-box-style my-3 py-2 input_data mandatoryField"
                  name="fileName"
                  placeholder="File Name"
                  value={editState.fileName}
                  onChange={handleChange}
                />
                <div className="errorDesc"></div>
              </div>
              <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column">
                <label for="file-select">Choose File Type:</label>
                <select
                  name="fileType"
                  value={editState.fileType}
                  onChange={handleChange}
                  className="file-select input_data mandatoryField"
                  style={{ width: "95%", height: 40 }}
                >
                  <option value=""> choose an option</option>
                  <option value="jpeg">JPEG</option>
                  <option value="jpg">JPG</option>
                  <option value="png">PNG</option>
                  <option value="pdf">PDF</option>
                  <option value="docx">DOCX</option>
                  <option value="Doc">Doc</option>
                </select>
                <div className="errorDesc"></div>
              </div>
              <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column">
                <label for="file-select">Choose File Size:</label>
                <select
                  name="fileSize"
                  value={editState.fileSize}
                  onChange={handleChange}
                  className="file-select input_data mandatoryField"
                  style={{ width: "95%", height: 40 }}
                >
                  <option value=""> choose an option</option>
                  <option value="50">50 KB</option>
                  <option value="100">100 KB</option>
                  <option value="150">150 KB</option>
                  <option value="200">200 KB</option>
                  <option value="250">250 KB</option>
                  <option value="300">300 KB</option>
                </select>
                <div className="errorDesc"></div>
              </div>
            </div>
            <div className="col-md-12 p-0 d-flex form-group">
              <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column mt-4 ">
                <select
                  name="requirement"
                  value={editState.requirement}
                  onChange={handleChange}
                  className="requirement input_data mandatoryField"
                  style={{ width: "95%", height: 40 }}
                >
                  <option value="">Choose Requirement</option>
                  <option value="Mandatory">Mandatory</option>
                  <option value="optional">Optional</option>
                </select>
                <div className="errorDesc"></div>
              </div>
              <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column ">
                <label for="file">Upload File{editState.fileType}</label>
                <Input
                  type="file"
                  style={{ width: "95%" }}
                  id="file"
                  name="file"
                  className="input_data mandatoryField"
                  // accept=".jpg,.doc"
                  accept={`.${editState.fileType}`}
                  onChange={(e) => {
                    uploadFile(e);
                  }}
                />
                <div className="errorDesc"></div>
              </div>
              <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column mt-2 ">
                <label for="Date">Select Deadline</label>
                <DatePicker style={{ width: 100 }}
                  // selected={editState.deadline}
                  onChange={handleDateEditChange}
                  value={editState.deadline}
                  // value={moment(startDate).format('DD/MM/YYYY')}
                  format="dd/MM/yyyy"
                  // dateFormat={moment(startDate).format('dd/MM/yyyy')}
                  className="input_data mandatoryField"
                  placeholderText="Select Date"
                  name="deadline"
                />
                <div className="errorDesc"></div>
              </div>
            </div>
            </div>
            <div>
              <div className="mb-2">
                {currentDocReqArr.length > 0 ? (
                  <div
                    className="d-flex col-md-12"
                    style={{ border: "1px solid #00000020" }}
                  >
                    <div
                      className="p-1 px-2 col-md-3"
                      style={{
                        fontWeight: "600",
                        alignSelf: "center",
                        borderRight: "1px solid #00000020",
                      }}
                    >
                      File Name
                    </div>
                    <div
                      className="p-1 px-2 col-md-3"
                      style={{
                        fontWeight: "600",
                        alignSelf: "center",
                        borderRight: "1px solid #00000020",
                      }}
                    >
                      File Type
                    </div>
                    <div
                      className="p-1 px-2 col-md-3"
                      style={{
                        fontWeight: "600",
                        alignSelf: "center",
                        borderRight: "1px solid #00000020",
                      }}
                    >
                      File Size
                    </div>
                    <div
                      className="p-1 px-2 col-md-3"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #00000020",
                        borderLeft: "1px solid #00000020",
                      }}
                    >
                      Required or optional
                    </div>
                  </div>
                ) : null}

                {currentDocReqArr.map((docReq) => {
                  return (
                    docReq.map((doc) => {
                      return (
                        <div
                          documentId={doc.documentId}
                          className=" d-flex col-md-12"
                          style={{ border: "1px solid #00000020" }}
                        >
                          <div
                            className="p-1 px-2 col-md-3"
                            style={{
                              alignSelf: "center",
                              borderRight: "1px solid #00000020",
                              alignItems: "center",
                            }}
                          >
                            {doc.fileName}
                          </div>
                          <div
                            className="p-1 px-2 col-md-3"
                            style={{
                              borderRight: "1px solid #00000020",
                              borderLeft: "1px solid #00000020",
                              alignItems: "center",
                            }}
                          >
                            {doc.fileType}
                          </div>
                          <div
                            className="p-1 px-2 col-md-3"
                            style={{
                              borderRight: "1px solid #00000020",
                              borderLeft: "1px solid #00000020",
                              alignItems: "center",
                            }}
                          >
                            {doc.fileSize}
                          </div>
                          <div
                            className="p-1 px-2 col-md-3"
                            style={{
                              borderRight: "1px solid #00000020",
                              borderLeft: "1px solid #00000020",
                              alignItems: "center",
                            }}
                          >
                            {doc.requirement}
                          </div>
                        </div>
                      );
                    }))
                })}
              </div>
            </div>
          </ModalComponent>
          <AlertModal
              show={showDocDelete}
              onHide={() => setShowDocDelete(false)}
              title='Alert!'
              message='Are you sure you want to delete this student?'
              onCancel={() => setShowDocDelete(false)}
              onConfirm={() => actionDocListBtnClicked("delete")}
          />
          <ToastContainer />
        </div>
      </div>
    );
  };

  export default UsersScreen;
