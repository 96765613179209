import React, { useEffect, useState } from "react";
import LoadingAnimation from "../../utils/loadingAnimation";
import { FaSearch } from "react-icons/fa";
import ProfilePicSample from "../../imageAssets/profilepicsample.png";
import ZipImg from "../../imageAssets/zipimg.png";
import {
  FaExpand,
  FaUpload,
  FaCaretDown,
  FaFileDownload,
} from "react-icons/fa";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import { MW_URL } from "../../config";
import { Button, Input } from "reactstrap";
import { ar, id } from "date-fns/locale";
import Collapsible from "../../components/collapsible";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { padding } from "@mui/system";
import JSZip from "jszip";
import { format } from "date-fns";

const UploadDocsScreen = () => {
  const [height, setHeight] = useState("");
  const [notificationDiv, setnotificationDiv] = useState(false);
  const [documentDiv, setDocumentDiv] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
  const [docValidation, setValidation] = useState(false);
  const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState(
    []
  );
  const [listOfSelectedDocumentArr, setlistOfSelectedDocumentsArr] = useState(
    []
  );
  const [listOfSudentUploadedDoc, setStudentUploadedDocArr] = useState([]);
  const [docId, setDocumentId] = useState([]);
  const [essaysTemplateList, setessaysTemplateList] = useState([]);
  const [essaysList, setessaysList] = useState([]);
  const [currentId, setCurrentId] = useState([]);
  const [submissionlistState, setsubmissionlistState] = useState([]);
  const [collageIds, setCollegeId] = useState([]);
  const [collageFilterCollageId, setcollageFilterCollageId] = useState("");
  const [collageDocMappingArr, setcollageDocMappingArr] = useState([]);
  const [collageDocMappingArrTemp, setcollageDocMappingArrTemp] = useState([]);
  const [uploadedDoc, setUploadedDocPayload] = useState([]);
  const [base64Value, setBase64] = useState(false);
  const [SearchKeyword, setSearchKeyword] = useState("");
  const [editState, setEditState] = React.useState({
    collageId: "",
    documentId: "",
    fileName: "",
    file: "",
    collageId: "",
  });
  const diffToast = () => toast.error("File Size or File Type Different from Requered", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,

  });

  useEffect(() => {
    var windowHeight = window.innerHeight;
    setHeight(windowHeight + "px");
    getAllEssays();
  }, [isUseEffectCallRequired]);
  const transferBtnClicked = () => { };
  
  const getAllEssays = () => {
    setIsLoading(true);
    var selectedCollages = [];
    var selectedCollagesIds = [];
    var templateArr = [];
    axios
      .post(`${MW_URL}` + "getSelectedListOfCollages", {
        email: JSON.parse(localStorage.getItem("userData")).email,
      })
      .then((getSelectedListOfCollagesresponse) => {
        if (
          getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
            .length > 0
        ) {
          getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
            (collage) => {
              selectedCollages.push(collage);
              selectedCollagesIds.push(collage.id);
            }
          );
          // console.log("selectedCollages", selectedCollages);
          setlistOfSelectedCollagesArr(selectedCollages);

          axios
            .post(`${MW_URL}` + "documentsbyuser", selectedCollages)
            .then((documentsbyuserresponse) => {
              //   console.log("documentsbyuser", documentsbyuserresponse);
              var documentId = [];
              var documentsByUserResFromApi = documentsbyuserresponse;
              documentsbyuserresponse.data.map((ids) => {
                {
                  documentId.push(ids);
                }
              });
              //  console.log("documentId", documentId);
              setDocumentId(documentId);
              axios
                .post(`${MW_URL}` + "getDocByCollege", documentId)
                .then((getDocumentDetailResponse) => {
                  var status = "NA";
                  var documentsDetails = [];
                  status = getDocumentDetailResponse.data.STATUS;
                  if (status.toUpperCase() === "SUCCESS") {
                    //   console.log("getDocumentDetailResponse.data",getDocumentDetailResponse.data);
                    setlistOfSelectedDocumentsArr(
                      getDocumentDetailResponse.data.data
                    );
                    if (
                      getDocumentDetailResponse &&
                      getDocumentDetailResponse.data &&
                      getDocumentDetailResponse.data.data
                    ) {
                      var collageDocMapArrTemp = [];

                      selectedCollages.map((collage) => {
                        var collageDocMapObjTemp = { collage: collage };
                        var documentsdetArr = [];
                        documentsByUserResFromApi.data.map(
                          (documentsCollageMap) => {
                            if (documentsCollageMap.collageId == collage.collageId) {
                              documentsdetArr.push(
                                getDocumentDetailResponse.data.data.filter(
                                  (doc) =>
                                    doc.documentId ==
                                    documentsCollageMap.documentId
                                )
                              );
                            }
                          }
                        );
                        collageDocMapObjTemp.documents = documentsdetArr;
                        collageDocMapArrTemp.push(collageDocMapObjTemp);
                      });
                      // console.log("collageDocMapArrTemp",collageDocMapArrTemp);
                      setcollageDocMappingArr(collageDocMapArrTemp);
                      setcollageDocMappingArrTemp(collageDocMapArrTemp);
                      setIsLoading(false);
                    }
                  } else {
                    setIsLoading(false);
                  }
                });
              axios
                .post(`${MW_URL}` + "getAllStudentsDocs", {
                  email: JSON.parse(localStorage.getItem("userData")).email,
                })
                .then((getStudDOcResponse) => {
                  setIsLoading(false);
                  setIsUseEffectCallRequired(false);
                  var allDetail = [];
                  if (
                    getStudDOcResponse &&
                    getStudDOcResponse.data &&
                    getStudDOcResponse.data[0]
                  ) {
                    var DocMapArrTemp = [];

                    selectedCollages.map((collage) => {
                      var DocMapObjTemp = { collage: collage };
                      var documentsdet = [];
                      documentsByUserResFromApi.data.map(
                        (documentsCollageMap) => {
                          if (documentsCollageMap.collageId == collage.collageId) {
                            documentsdet.push(
                              getStudDOcResponse.data.filter(
                                (doc) =>
                                  doc.documentId ==
                                  documentsCollageMap.documentId && doc.collegeId ==
                                  documentsCollageMap.collageId
                              )
                            );
                          }
                        }
                      );
                      DocMapObjTemp.documents = documentsdet;
                      DocMapArrTemp.push(DocMapObjTemp);
                    });
                    // console.log("collageDocMapArrTemp",collageDocMapArrTemp);
                    setUploadedDocPayload(DocMapArrTemp);
                    setIsLoading(false);
                  }
                })
                .catch((err) => {
                  console.log("err >> ", err);
                })
                .catch((err) => {
                  console.log("err >> ", err);
                });
            })

            .catch((err) => {
              console.log("err >> ", err);
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };

  const downloadfile = (rowData) => {
    setIsLoading(true)
    axios
      .post(`${MW_URL}` + "getDocById", { documentId: rowData.documentId })
      .then((getDocumentDetailResponse) => {
        console.log("data", getDocumentDetailResponse.data.data)
        var status = "NA";
        var documentFile = [];
        status = getDocumentDetailResponse.data.STATUS;
        if (status.toUpperCase() === "SUCCESS") {
          if (
            getDocumentDetailResponse &&
            getDocumentDetailResponse.data
          ) {
            getDocumentDetailResponse.data.data.map((file) => {
              {
                documentFile.push(file.file)
              }
            });
          }
          setIsLoading(false)
          download(documentFile, rowData.fileName);
        }
      }).catch((err) => {
        console.log("err >> ", err);
      });
  }

  const downloadfiles = (rowData) => {
    console.log("stud", rowData)
    if (rowData) {
      setIsLoading(true)
      axios
        .post(`${MW_URL}` + "getStudentDocfile", {
          email: JSON.parse(localStorage.getItem("userData")).email,
          documentId: rowData.documentId,
          collageId: rowData.collageId,
        })
        .then((getDocumentDetailResponse) => {
          console.log("data", getDocumentDetailResponse.data.data)
          var status = "NA";
          var documentFile = [];
          status = getDocumentDetailResponse.data.STATUS;
          if (status.toUpperCase() === "SUCCESS") {
            if (
              getDocumentDetailResponse &&
              getDocumentDetailResponse.data
            ) {
              getDocumentDetailResponse.data.data.map((file) => {
                {
                  documentFile.push(file.file)
                }
              });
            }
            setIsLoading(false)
            downloads(documentFile, rowData.fileName);
          }
        }).catch((err) => {
          console.log("err >> ", err);
        });
    }
    else {
      toast.error("No file Uploaded", {
        autoClose: 1000,
      })
    }
  }
  const uploadBtn = () => {
    if (docValidation) {
      var dataToSend = {
        collegeId: editState.collageId,
        documentId: editState.documentId,
        fileName: editState.fileName,
        file: editState.file,
        email: JSON.parse(localStorage.getItem("userData")).email,

      };
      // console.log("DatatoSent", dataToSend);
      setIsLoading(true);

      axios
        .post(`${MW_URL}` + "studentUploadDoc", dataToSend)
        .then((getUploadDetailResponse) => {
          if (
            getUploadDetailResponse &&
            getUploadDetailResponse.data &&
            getUploadDetailResponse.data.STATUS === "SUCCESS"
          ) {
            setIsLoading(false);
            setIsUseEffectCallRequired(true);
            // window.location.reload();
            setEditState({
              collageId: "",
              documentId: "",
              fileName: "",
              file: "",
              collageId: "",
            })
          } else {
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    } else {
      diffToast();
    }
  }
  const uploadFile = async (e, submissionData) => {
    const file = e.target.files[0];
    const sizeInBytes = file.size;
    const sizeFile = sizeInBytes / 1024;
    var fileExtension = file.name.split('.').pop();
    fileExtension = fileExtension.toUpperCase()
    var fileTy = submissionData.fileType.toUpperCase()
    // console.log(fileExtension, fileTy)
    if (sizeFile <= submissionData.fileSize && fileExtension == fileTy) {
      const base64 = await convertBase64(file);
      setEditState({
        documentId: submissionData.documentId,
        collageId: submissionData.collageId,
        fileName: submissionData.fileName,
        file: base64,
      });
      setValidation(true)
    }
    else {
      // console.log("File size or type mismatch")
      // alert("File size or type mismatch")
      diffToast();
    }
  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  // console.log(base64Value)
  function makesubmissionList(selectedCollages, essaysListToPush, templateArr) {
    var submissionlist = [];
    selectedCollages.map((coll) => {
      submissionlist.push({
        collageId: coll._id,
        collageName: coll.name,
        fileUploaded: essaysListToPush.filter(
          (essay) => essay.collageId == coll._id
        ).length,
        fileRequired: templateArr.filter(
          (essayTmplte) => essayTmplte.collId == coll._id
        ).length,
      });
    });

    setsubmissionlistState(submissionlist);
  }

  // var zip = new JSZip();

  // zip.file();

  // var img = zip.folder();
  // img.file( imgData, {base64: true});

  // zip.generateAsync({type:"blob"}).then(function(content) {
  //     saveAs(content, );
  // });
  const files = [];
  const handleSearch = (value) => {
    setSearchKeyword(value)
    var temp = [];
    var Tempres = [];
    collageDocMappingArr.map((collage) => {
      temp = collage.collage.name
      var searchRes = temp.toLowerCase().includes(SearchKeyword.toLowerCase());
      if (searchRes) {
        Tempres.push(collage)
        setcollageDocMappingArr(Tempres)
      }
      else {
        setcollageDocMappingArr([])
      }
    });
    if (SearchKeyword.length < 2) {
      setcollageDocMappingArr(collageDocMappingArrTemp)
    }
  };
  return (

    <div>
      {isLoading ? <LoadingAnimation isVisible={true} /> : null}
      <div
        className="upload-docs-page-container pb-5"
        style={{ minHeight: height }}
      >
        <div
          className="col-md-12 pl-5 pt-5  d-flex justify-content-between"
          style={{ width: "100%" }}
        >
          <div
            className="col-md-6 searchbox-style d-flex align-items-center"
            style={{ height: 30 }}
          >
            <div className="col-md-11">
              <input
                style={{ border: 0, width: "100%", outline: "none" }}

                type="text"
                id="search"
                name="search"
                placeholder="Search"
                value={SearchKeyword}
                onChange={(event) => {
                  handleSearch(event.target.value);
                }}
              />
            </div>
            <div className="col-md-1 p-0 m-0 d-flex justify-content-end">
              <FaSearch style={{ fontSize: 12 }}
              // onClick={() => {
              //   searchByKeyword();
              // }}

              />
            </div>
          </div>
          <div className="col-md-3 p-0 pr-4 d-flex justify-content-end align-items-center text-right">
            <div className="pr-2 col-md-8">
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  textTransform: "uppercase",
                }}
              >
                {JSON.parse(localStorage.getItem("userData")).username}
              </div>
              <div style={{ fontSize: 7 }}>WELCOME BACK</div>
            </div>
            <div className="p-0 m-0 col-md-3" style={{ zIndex: 2 }}>
              {notificationDiv ? (
                <div className="col-md-12 m-0 p-0">
                  <div
                    className="m-3 p-0 py-4 pl-4 card-design"
                    style={{
                      width: 240,
                      borderRadius: 15,
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    {notificationRow()}
                    {notificationRow()}
                    {notificationRow()}
                    {notificationRow()}
                    {notificationRow()}
                  </div>
                </div>
              ) : null}
              <div className="col-md-12" style={{ zIndex: 2 }}>
                <div
                  className="d-flex justify-content-center"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    fontSize: 10,
                    width: 15,
                    height: 15,
                    position: "absolute",
                    right: "5%",
                    top: 4,
                    borderRadius: "50%",
                  }}
                >
                  5
                </div>
              </div>
              <img
                onClick={() => setnotificationDiv(!notificationDiv)}
                className="col-md-12 p-0"
                style={{ zIndex: 1, cursor: "pointer" }}
                height={60}
                src={ProfilePicSample}
                alt="dpsaple"
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 p-0 px-5 py-2">
          <div className="col-md-12 p-0 pb-3 d-flex align-items-center justify-content-between">
            <div className="sub-headings col-md-2 p-0 m-0">
              {/* Previous work */}
            </div>
            {/* <div className="col-md-8 p-0 d-flex justify-content-between">
              <div className="col-md-3 d-flex filter-dropdowns">
                <input
                  type="file"
                  style={{ width: "95%" }}
                  hidden
                  id="uid"
                  name="file"
                  placeholder="Select File"
                  onChange={(e) => {
                    uploadFile(e);
                  }}
                />
                <FaUpload style={{ fontSize: 15 }} className="mr-2 mt-1" />
                <label for="uid" className="txt">select file</label>
              </div>
              <div
                className="col-md-2  align-items-center filter-dropdowns"
                style={{ width: "100%" }}
              >
                <label for="safty" className="txt">Safty</label>
                <FaCaretDown style={{ fontSize: 15 }} className=" ml-3 mt-1" />
                <select
                  name="safty"
                  id="safty"
                  className="opacity"

                >
                  <option value="safty">safty</option>
                  <option value="safty1">safty</option>
                </select>

              </div>
              <div
                className="col-md-2 align-items-center filter-dropdowns"
                style={{ width: "10%", height: "0%" }}
              >
                <label for="country" className="txt">country</label>
                <FaCaretDown style={{ fontSize: 15 }} className=" ml-3 mt-1" />
                <select
                  name="country"
                  id="country"
                  className="opacity"

                >
                  <option value="India">India</option>
                  <option value="usa">usa</option>
                  <option value="uk">uk</option>
                </select>
              </div>
              <div
                className="col-md-2  filter-dropdowns"
                style={{ width: "100%", height: "10%" }}
              ><label for="deadline" className="txt">deadline</label>
                <FaCaretDown style={{ fontSize: 15 }} className=" ml-3 mt-1" />
                <select
                  name="deadline"
                  id="deadline"
                  className="opacity"
                >
                  <option value="deadline1">deadline1</option>
                  <option value="deadline2">deadline2</option>
                  <option value="deadline3">deadline3</option>
                </select>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-md-12 p-0 px-5 py-2 ">
          <div className="sub-headings pb-3">Completed submissions</div>
          {collageDocMappingArr.map((collage) => {
            const uploadedlen = uploadedDoc.length;
            const len = listOfSelectedDocumentArr.length;
            // console.log(collage.collage.id)
            return (
              <Collapsible>
                {docDetailComponent({
                  collageName: collage.collage.name,
                  fileRequired: collage.documents.length,
                  fileUploaded: 0,
                  collageId: collage.collage.collageId,
                  deadline: collage.collage.deadline,
                })}
                {collage.documents.map((docData) => {
                  if (docData[0]) {
                    return docDetailUploadComponent({
                      fileName: docData[0].fileName,
                      fileRequired: len,
                      fileUploaded: uploadedlen,
                      collageId: collage.collage.collageId,
                      fileSize: docData[0].fileSize,
                      fileType: docData[0].fileType,
                      requirement: docData[0].requirement,
                      documentId: docData[0].documentId,
                      file: docData[0].file,
                      deadline: docData[0].deadline,
                    });
                  }
                })}
              </Collapsible>
            );
          })}
        </div>
        {/* <div className="col-md-12 p-0 px-5 py-2">
          <div className="sub-headings pb-3">Incomplete submissions</div>
          {submissionlistState.length == 0 ? <div>no collages selected</div> : submissionlistState.map((submissionData) => {
            return docDetailComponent(submissionData)
          })}
        </div> */}
      </div>
    </div>
  );

  function notificationRow(
    head = "TRENDING",
    content = "Ivy league schools make SAT scores optional"
  ) {
    return (
      <div className="col-md-12 p-2 pl-2">
        <div className="trending-head text-left">{head}</div>
        <div className="col-md-4 divider-bar"></div>
        <div
          className="col-md-8 m-0 p-0 pt-1 text-left"
          style={{ fontSize: 10, lineHeight: 1 }}
        >
          {content}
        </div>
      </div>
    );
  }

  function docDetailComponent(submissionData) {
    var fileToUse = 0;
    // console.log("sub...>",submissionData)

    uploadedDoc.map((docs) => {
      // console.log("docs...>",docs)
      if (docs.collage.collageId == submissionData.collageId) {
        if (docs.documents.length) {
          docs.documents.map((d) => {
            if (d.length) {
              fileToUse += 1
            }
          })
        }
      }
    });

    return (
      <div className="p-3 detail-card d-flex justify-content-between align-items-center p-0 px-4 m-0">
        <div className=" p-2 px-4 zip-button d-flex align-items-center justify-content-center">
          <div className="pr-1">ZIP</div>

          <div>
            <img height={6} src={ZipImg} alt="sipimg" />
          </div>
        </div>
        {docDetail(submissionData.deadline, "Deadline")}
        {docDetail(submissionData.collageName, "Collage")}
        {docDetail(fileToUse, "Files Uploaded")}
        {docDetail(submissionData.fileRequired, "Files Required")}
        {/* {docDetail(submissionData.collageId)} */}

        {/* {setCurrentId(submissionData.id)} */}
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="tansfer-button p-2 px-4 m-0"
            onClick={() => transferBtnClicked()}
          >
            Transfer
          </div>

          <div className="expand-box d-flex justify-content-center align-items-cener m-2 ">

          </div>

          {submissionData.fileRequired > 0
            ? progressCircle(
              parseInt(
                (fileToUse / submissionData.fileRequired) *
                100
              )
            )
            : progressCircle(parseInt(0))}
        </div>
      </div>
    );
  }

  function download(data, Name) {
    // console.log("decode", Name)
    const linkSource = `${data}`;
    const downloadLink = document.createElement("a");
    const fileName = Name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }



  function downloads(data, Name) {
    // console.log("decode", data);
    const linkSource = `${data}`;
    const downloadLink = document.createElement("a");
    const fileName = Name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  function docDetailUploadComponent(submissionData, data) {
    var fileToUse; //use this variable to save needed data
    var inputFieldId = submissionData.documentId + "-" + submissionData.collageId;
    const ar = [];
    console.log("uploadedDoc", uploadedDoc);
    uploadedDoc.map((docs) => {
      {
        ar.push(docs.collage)
        docs.documents.map((doc) => {
          ar.map((colid) => {
            if (doc.length > 0) {
              if (doc[0].collegeId == submissionData.collageId) {
                if (doc[0].documentId == submissionData.documentId) {
                  // console.log("submissionData.documentId===>",submissionData.documentId);
                  // console.log("doc===>",docs);
                  return fileToUse = {
                    file: doc[0].file,
                    fileName: doc[0].fileName,
                    documentId: doc[0].documentId,
                    collageId: colid.collageId,
                  };
                }
              }
            }
          })
        });
      }
    });
    // if(fileToUse){
    //       const base64Data = fileToUse.file;
    // // const base64 = await fetch(base64Data);
    // const base64Response = fetch(`data:image/jpeg;base64,${base64Data}`);
    // const byteCharacters =fileToUse.file.split(',')[1];
    // const blob =  byteCharacters.lob();
    //     console.log("content",blob)

    // const byteCharacters = atob(fileToUse.file.split(',')[1]);
    // var ab= new ArrayBuffer(byteCharacters.length)
    // var ia=new Uint8Array(ab);

    // for (var i = 0; i < byteCharacters.length; i++) {
    //   ia[i] = byteCharacters.charCodeAt(i);
    // }
    // const as= new Blob([ab],{type:'image/png'})


    // console.log("content>>>",as)


    // const zip = require('jszip')();

    // let files = as;
    // zip.file(files);

    // zip.generateAsync({ type: "blob" }).then(content => {
    //   download(content, "example.zip");
    //   console.log("content", content)
    // });}

    return (
      <div className="">
        <div className="p-3 detail-card d-flex justify-content-between align-items-center docBottomDetail px-4 m-0 ">
          <div
            className="tansfer-button p-2 px-4 m-0"
            onClick={() => downloadfile(submissionData)}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <FaFileDownload
              style={{ fontSize: 10 }}
              className="mr-2"
            ></FaFileDownload>
            Sample File
          </div>

          {docDetail(submissionData.fileName, "fileName")}
          {docDetail(submissionData.fileSize, "fileSize")}
          {docDetail(submissionData.fileType, "fileType")}
          {docDetail(submissionData.requirement, "requirement")}
          {docDetail(submissionData.uploadedFile)}
          {docDetail(submissionData.deadline, "Deadline")}

          <div className="d-flex justify-content-between align-items-center">
            <div className="tansfer-button  justify-content-between align-items-center">
            {console.log("ss",submissionData)}
              <input
                type="file"
                id={inputFieldId}
                hidden
                accept={"application/"+submissionData.fileType.toLowerCase()+','+"image/"+submissionData.fileType.toLowerCase()}
                onChange={(e) => {
                  uploadFile(e, submissionData);
                }}
              />
              <div>
                <FaUpload className="ml-4 mr-1" style={{ fontSize: 10 }} />
                <label className="p-2 pl-0 m-0 mr-2 pr-4 m-0" for={inputFieldId} style={{ cursor: "pointer" }}>Select file</label>
              </div>

            </div>

            {editState.file && editState.collageId == submissionData.collageId && editState.documentId == submissionData.documentId ? (

              <div
                className="tansfer-button p-2 px-4 m-0 ml-2"
                onClick={() => uploadBtn()}
                style={{ cursor: "pointer" }}
              >
                Upload
              </div>
            ) : null}
            {fileToUse ? (
              <div
                className="tansfer-button p-2 px-3 m-0 ml-2"

                onClick={() => downloadfiles(fileToUse)}
                style={{ cursor: "pointer" }}
              >
                <FaFileDownload
                  style={{ fontSize: 10 }}
                  className="ml-2 mr-2"
                ></FaFileDownload>
                Download
              </div>) : <div
                className="tansfer-buttonNone p-2 px-3 m-0 ml-2"

                onClick={() => downloadfiles(fileToUse)}
                style={{ cursor: "pointer" }}
              >
              <FaFileDownload
                style={{ fontSize: 10,color:"red" }}
                className="ml-2 mr-2"
              ></FaFileDownload>
              Download
            </div>}

          </div>
        </div>
      </div>
    );
  }

  function docDetail(topTxt = "", bottomTxt = "") {
    return (
      <div className="d-flex align-items-center flex-column">
        <div className="docTopDetail">{topTxt}</div>
        <div className="d-flex align-items-center docBottomDetail">
          {bottomTxt}
        </div>
      </div>
    );
  }

  function progressCircle(percentile) {
    var leftHeight = 0;
    var rightHeight = 0;
    if (percentile > 50) {
      leftHeight = 100;
      rightHeight = (percentile - 50) * 2;
    } else {
      rightHeight = 0;
      leftHeight = percentile * 2;
    }
    return (
      <div className="p-0 m-0">
        <div style={{ width: 30, height: 30 }}>
          <CircularProgressbar
            value={percentile}
            strokeWidth={10}
            text={`${percentile}%`}
            styles={{
              text: {
                fill: "grey",
                fontSize: "24px",
              },
              path: {
                stroke: `#6C63FF`,
              },
            }}
          />
        </div>
        <ToastContainer />
      </div>
    );
  }
};

export default UploadDocsScreen;
