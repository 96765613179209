import React, { useState, useEffect } from "react";

const SplashLoadingScreen = () => {

    const [height, SetHeight] = useState("");

    useEffect(() => {
        var windowHeight = window.innerHeight;
        SetHeight(windowHeight + "px");
    }, [])

    return (
        <div className="splashscreen-container d-flex justify-content-center align-items-center" style={{ minHeight: height }}>
            <div>
                <div className="loading-text mb-3 text-center">Please wait...</div>
                <div className="loader"></div>

            </div>
        </div>
    )
}


export default SplashLoadingScreen;