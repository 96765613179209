import { createSvgIcon } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";

const createUniquieId = () => {
    const head = Date.now().toString(36);
    const tail = Math.random().toString(36).substr(2);
    return head + tail;
};

export const portfolioDetailsSlice = createSlice({
    name: "portfolioDetails",
    initialState: {
        professionaltitle: "sample profession",
        phonenumber: "+1234567890",
        profiledescription: "description sample",
        emailadd: "emailid@sample.com",
        portfolioaddress: "Country, City, Zip",
        linkedinusername: "username",
        basicDetailsLabel: "Profile",
        educationLable: "Eduction",
        experienceLabel: "Experience",
        languageLabel: "Languages",
        skillsLabel: "Skills",

        EducationDetials: [{
            educationDetailsId: createUniquieId(),
            educationYear: "Start-End",
            educationDetailsCourse: "Please Enter Your Major",
            educationDetailsUniversity: "Enter Your University",
        }],
        experienceDetails: [{
            experienceDetailsId: createUniquieId(),
            experienceProjectName: "Company/place",
            experienceStartDate: "Start",
            experienceEndDate: "End",
            experiencePosition: "Position",
            experienceDescription: "description",
        }],
        languages: "language",
        skills: "skills"
    },
    reducers: {

        setProfessionalTitle: (state, action) => {
            state.professionaltitle = action.payload
        },

        setPhoneNumber: (state, action) => {
            state.phonenumber = action.payload
        },

        setProfileDescription: (state, action) => {
            state.profiledescription = action.payload
        },

        setEmailAdd: (state, action) => {
            state.emailadd = action.payload
        },

        setPortfolioAddress: (state, action) => {
            state.portfolioaddress = action.payload
        },

        setLinkedinUsername: (state, action) => {
            state.linkedinusername = action.payload
        },
        setBasicDetailsLabel: (state, action) => {
            state.basicDetailsLabel = action.payload
        },
        setEducationLabel: (state, action) => {
            state.educationLable = action.payload
        },
        setExperienceLabel: (state, action) => {
            state.experienceLabel = action.payload
        },
        setLanguageLabel: (state, action) => {
            state.languageLabel = action.payload
        },
        setSkillsLabel: (state, action) => {
            state.skillsLabel = action.payload
        },
        setProImage: (state, action) => {
            state.proImage = action.payload;
        },
        setexperienceDetailsStartDate: (state, action) => {
            const index = state.experienceDetails.findIndex(x => x.experienceDetailsId == action.payload.id)
            if (index != -1) {
                state.experienceDetails[index].experienceStartDate = action.payload.value;
            }
        },
        setexperienceDetailsEndDate: (state, action) => {
            const index = state.experienceDetails.findIndex(x => x.experienceDetailsId == action.payload.id)
            if (index != -1) {
                state.experienceDetails[index].experienceEndDate = action.payload.value;
            }
        },
        setexperienceDetailsPosition: (state, action) => {
            const index = state.experienceDetails.findIndex(x => x.experienceDetailsId == action.payload.id)
            if (index != -1) {
                state.experienceDetails[index].experiencePosition = action.payload.value;
            }
        },
        setexperienceDetailsProjectName: (state, action) => {
            const index = state.experienceDetails.findIndex(x => x.experienceDetailsId == action.payload.id)
            if (index != -1) {
                state.experienceDetails[index].experienceProjectName = action.payload.value;
            }
        },
        setexperienceDetailsDescription: (state, action) => {
            const index = state.experienceDetails.findIndex(x => x.experienceDetailsId == action.payload.id)
            if (index != -1) {
                state.experienceDetails[index].experienceDescription = action.payload.value;
            }
        },
        setEductionDetailsYear: (state, action) => {
            const index = state.EducationDetials.findIndex(x => x.educationDetailsId == action.payload.id)
            if (index != -1) {
                state.EducationDetials[index].educationYear = action.payload.value;
            }
        },
        setEducationDetailsCourse: (state, action) => {
            const index = state.EducationDetials.findIndex(x => x.educationDetailsId == action.payload.id)
            if (index != -1) {
                state.EducationDetials[index].educationDetailsCourse = action.payload.value;
            }

        },
        setEducationDetailsUniversity: (state, action) => {
            const index = state.EducationDetials.findIndex(x => x.educationDetailsId == action.payload.id)
            if (index != -1) {
                state.EducationDetials[index].educationDetailsUniversity = action.payload.value;
            }
        },
        setLanguages: (state, action) => {
            state.languages = action.payload;
        },
        setSkills: (state, action) => {
            state.skills = action.payload;
        },
        setPushNewEducationDetails: (state, action) => {
            state.EducationDetials.push({
                educationDetailsId: createUniquieId(),
                educationYear: "2015-2018",
                educationDetailsCourse: "Please Enter Your Major",
                educationDetailsUniversity: "Enter Your University",
            })
        },
        setPushNewExperienceDetails: (state, action) => {
            state.experienceDetails.push({
                experienceDetailsId: createUniquieId(),
                experienceProjectName: "Project Name",
                experienceStartDate: "Start",
                experienceEndDate: "End",
                experiencePosition: "Position",
                experienceDescription: "description",
            })
        },
        setDeleteEducationDetails: (state, action) => {
            const newList = state.EducationDetials.filter((event) =>
                event.educationDetailsId !== action.payload)
            state.EducationDetials = newList;
        },
        setDeleteExperienceDetails: (state, action) => {
            const newList = state.experienceDetails.filter((event) =>
                event.experienceDetailsId !== action.payload)
            state.experienceDetails = newList;
        },
    }
});

export const { setProfessionalTitle,
    setPhoneNumber,
    setProfileDescription,
    setEmailAdd,
    setPortfolioAddress,
    setLinkedinUsername,
    setEductionDetailsYear,
    setEducationDetailsCourse,
    setEducationDetailsUniversity,
    setexperienceDetailsStartDate,
    setexperienceDetailsEndDate,
    setexperienceDetailsPosition,
    setexperienceDetailsDescription,
    setexperienceDetailsProjectName,
    setLanguages,
    setexperienceDetails,
    setPushNewExperienceDetails,
    setDeleteEducationDetails,
    setDeleteExperienceDetails,
    setPushNewEducationDetails,
    setSkills,
    setBasicDetailsLabel,
    setEducationLabel,
    setExperienceLabel,
    setLanguageLabel,
    setSkillsLabel,
} = portfolioDetailsSlice.actions;    // exporting reducer funtion

export const professionaltitle = state => state.portfolioDetails.professionaltitle;  // exporting redux state
export const phonenumber = state => state.portfolioDetails.phonenumber;
export const profiledescription = state => state.portfolioDetails.profiledescription;
export const emailadd = state => state.portfolioDetails.emailadd;
export const portfolioaddress = state => state.portfolioDetails.portfolioaddress;
export const linkedinusername = state => state.portfolioDetails.linkedinusername;
export const educationYear = state => state.portfolioDetails.educationYear;
export const educationDetailsCourse = state => state.portfolioDetails.educationDetailsCourse;
export const educationDetailsUniversity = state => state.portfolioDetails.educationDetailsUniversity;
export const EducationDetials = state => state.portfolioDetails.EducationDetials;
export const experienceDetails = state => state.portfolioDetails.experienceDetails;
export const languages = state => state.portfolioDetails.languages;
export const skills = state => state.portfolioDetails.skills;
export const basicDetailsLabel = state => state.portfolioDetails.basicDetailsLabel;
export const educationLable = state => state.portfolioDetails.educationLable;
export const experienceLabel = state => state.portfolioDetails.experienceLabel;
export const languageLabel = state => state.portfolioDetails.languageLabel;
export const skillsLabel = state => state.portfolioDetails.skillsLabel;
export default portfolioDetailsSlice.reducer;