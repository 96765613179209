import React, { useEffect, useState, useRef } from "react";
import LoadingAnimation from "../../utils/loadingAnimation";
import DesignPortfolio from "../../imageAssets/portfolio2.png";
import ArticlePortfolio from "../../imageAssets/articleportfolio.png";
import ArtPresentationPortfolio from "../../imageAssets/artpresentationportfolio.png";
import WebdevPortfolio from "../../imageAssets/portfolio1.png";
import TickWhite from "../../imageAssets/tickwhite.png";
import UploadfilesImg1 from "../../imageAssets/uploadfilesimg1.png";
import UploadfilesImg2 from "../../imageAssets/uploadfilesimg2.png";
import UploadfilesImg3 from "../../imageAssets/uploadfilesimg3.png";
import UploadfilesImg4 from "../../imageAssets/uploadfilesimg4.png";
import { FaPlus, FaTrash } from "react-icons/fa";
import Template1 from "../../components/portfoliotemplates/template1";
import Template2 from "../../components/portfoliotemplates/template2";
import { Input } from "reactstrap";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import {
  setProfessionalTitle,
  setPhoneNumber,
  setProfileDescription,
  setEmailAdd,
  setPortfolioAddress,
  setLinkedinUsername,
  setEductionDetailsYear,
  setEducationDetailsCourse,
  setEducationDetailsUniversity,
  setexperienceDetailsProjectName,
  setexperienceDetailsStartDate,
  setexperienceDetailsEndDate,
  setexperienceDetailsPosition,
  setexperienceDetailsDescription,
  setPushNewExperienceDetails,
  setDeleteEducationDetails,
  setDeleteExperienceDetails,
  setPushNewEducationDetails,
  setLanguages,
  setSkills,
  setBasicDetailsLabel,
  setEducationLabel,
  setExperienceLabel,
  setLanguageLabel,
  setSkillsLabel
} from "../../stateManager/reducers/portfolioDetailsReducer";
import {
  professionaltitle,
  phonenumber,
  profiledescription,
  emailadd,
  portfolioaddress,
  linkedinusername,
  EducationDetials,
  experienceDetails,
  languages,
  skills,
  basicDetailsLabel,
  educationLable,
  experienceLabel,
  languageLabel,
  skillsLabel,
} from "../../stateManager/reducers/portfolioDetailsReducer";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Card } from "react-bootstrap";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { FaArrowLeft } from "react-icons/fa";

const ProfileBuilderScreen = () => {
  const [height, setHeight] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // { "title": "Article Portfolio", "img": ArticlePortfolio, "selected": false }, { "title": "art presentation", "img": ArtPresentationPortfolio, "selected": true }
  const [showbuildprofile, setshowbuildprofile] = useState(false);
  const [showTemplateSelection, setshowTemplateSelection] = useState(true);
  const [portfolioDetails, setportfolioDetails] = useState({
    professionaltitle: "",
    something: "something",
  });
  const myRef = useRef();
  const [portfoliotemplates, setportfoliotemplates] = useState([
    {
      id: 1,
      title: "Portfolio1",
      img: WebdevPortfolio,
      selected: false,
      template: <Template1 ref={myRef} />,
    },
    {
      id: 2,
      title: "Portfolio2",
      img: DesignPortfolio,
      selected: false,
      template: <Template2 ref={myRef} />,
    },
  ]);

  const dispatch = useDispatch();
  const professionaltitlefromredux = useSelector(professionaltitle);
  const phfromredux = useSelector(phonenumber);
  const profiledescfromredux = useSelector(profiledescription);
  const emailaddfromredux = useSelector(emailadd);
  const portfolioaddfromredux = useSelector(portfolioaddress);
  const linkedinunfromredux = useSelector(linkedinusername);
  const educationDetailsfromredux = useSelector(EducationDetials);
  const languagefromredux = useSelector(languages);
  const experiencefromredux = useSelector(experienceDetails);
  const skillsfromredux = useSelector(skills);
  const basicDetailLabelfromredux = useSelector(basicDetailsLabel);
  const educationLabelfromredux = useSelector(educationLable);
  const experienceLabelfromredux = useSelector(experienceLabel);
  const languageLabelfromredux = useSelector(languageLabel);
  const skillsLabelfromredux = useSelector(skillsLabel);
  useEffect(() => {
    var windowHeight = window.innerHeight;
    setHeight(windowHeight + "px");
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  });

  const handleSaveToPDF = (event) => {
    // event.preventDefault();
    // window.print();
    myRef.current?.handleSaveToPDF();
  };

  function printDocument() {
    const input = document.getElementById("portfoliocontainer");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
    });
  }

  function showUploadDoc() {
    setshowbuildprofile(true);
    window.setTimeout(function () {
      document
        .getElementById("UploadfilesImg1")
        .classList.add("animationClassLargeImg");
      document
        .getElementById("UploadfilesImg4")
        .classList.add("animationClassLargeImg");
      document
        .getElementById("UploadfilesImg2")
        .classList.add("animationClassSmallImg");
      document
        .getElementById("UploadfilesImg3")
        .classList.add("animationClassSmallImg");
    }, 50);
  }
  function updateportfolioselection(portfolio) {
    setportfoliotemplates(
      portfoliotemplates.map((item) => {
        if (item.id == portfolio.id) {
          if (item.selected) {
            return {
              ...item,
              selected: false,
            };
          } else {
            return {
              ...item,
              selected: true,
            };
          }
        } else {
          return {
            ...item,
            selected: false,
          };
        }
      })
    );
  }
  const setNewEducation = () => {
    dispatch(setPushNewEducationDetails());
  };
  const setNewExperience = () => {
    dispatch(setPushNewExperienceDetails());
  };
 const setDeleteExperience = (expid) => {
    confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure Want to delete Experience ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>dispatch(setDeleteExperienceDetails(expid)),
        },
        {
          label: 'No',
         
        }
      ],
    });
  };
  const setDeleteEducation = (eduid) => {
    confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure Want to delete Education ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>dispatch(setDeleteEducationDetails(eduid)),
        },
        {
          label: 'No',
         
        }
      ],
    });
  };
  return (
    <div>
      {isLoading ? <LoadingAnimation isVisible={true} /> : null}
      <div
        className="profile-builder-page-container "
        style={{ minHeight: height }}
      >
        {showTemplateSelection ? (
          <div className="p-5" id="templateselectioncontainter">
            <div className="p-5">
              <div className="headings-style">TEMPLATE</div>
              <div className="d-flex pt-3">
                {portfoliotemplates.map((portfolio, index) => {
                  return portfoliComponent(portfolio);
                })}
              </div>
            </div>
            {portfoliotemplates.find((pf) => pf.selected == true) ? (
              <div className="col-md-12 pt-5 d-flex align-items-center flex-column">
                <div
                  className="text-uppercase p-2 col-md-2 d-flex justify-content-center fromComputer-button"
                  onClick={() => {
                    setshowTemplateSelection(false);
                  }}
                >
                  create profile
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="p-0 m-0 pl-3" id="templateeditcontainer">
            <div className="d-flex p-2 px-4 m-0 align-items-center justify-content-between">
              <FaArrowLeft
                style={{ fontSize: 34, cursor: "pointer" }}
                onClick={() => {
                  setshowTemplateSelection(true);
                }}
              />
              <div
                style={{ cursor: "pointer" }}
                className="transfer-button p-2 px-4 m-0"
                onClick={() => printDocument()}
              >
                save to pdf
              </div>
            </div>

            <div className="d-flex">
              <div className="col-md-7 printable" id="portfoliocontainer">
                {portfoliotemplates.find((pf) => pf.selected == true).template}
              </div>

              <div className="col-md-5 non-printable">
                <Accordion>
                  <div className="col-md-12 m-0 p-0">
                    <div className="col-md-12 m-0 p-0">
                      <Accordion.Toggle
                        className="col-md-12 m-0 p-3"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          textAlign: "center",
                          fontWeight: "800",
                          fontSize: 14,
                        }}
                        variant="link"
                        eventKey={"portfoliobasicdetailsaccordian"}
                      >
                        BASIC DETAILS
                      </Accordion.Toggle>
                    </div>

                    <Accordion.Collapse
                      eventKey={"portfoliobasicdetailsaccordian"}
                    >
                      <div
                        className="panel-body"
                        style={{ textTransform: "none" }}
                      >
                        <label className="pt-2" style={{ width: "100%" }}>
                          Basic Details Label
                        </label>
                        <input
                          style={{ width: "100%" }}
                          className="inputfieldstyle"
                          type="text"
                          value={basicDetailLabelfromredux}
                          onChange={(event) => {
                            setBasicLabel(event);
                          }}
                          placeholder="Phone number"
                        ></input>

                        <label style={{ width: "100%" }}>
                          Professional Title
                        </label>
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          className="inputfieldstyle"
                          value={professionaltitlefromredux}
                          onChange={(event) => {
                            setprofessionaltitle(event);
                          }}
                          placeholder="professional title"
                        ></input>

                        <label className="pt-2" style={{ width: "100%" }}>
                          Phone Number
                        </label>
                        <input
                          style={{ width: "100%" }}
                          className="inputfieldstyle"
                          type="text"
                          value={phfromredux}
                          onChange={(event) => {
                            setpn(event);
                          }}
                          placeholder="Phone number"
                        ></input>

                        <label className="pt-2" style={{ width: "100%" }}>
                          Email
                        </label>
                        <input
                          style={{ width: "100%" }}
                          className="inputfieldstyle"
                          type="text"
                          value={emailaddfromredux}
                          onChange={(event) => {
                            setemailaddress(event);
                          }}
                          placeholder="Email Address"
                        ></input>

                        <label className="pt-2" style={{ width: "100%" }}>
                          Address
                        </label>
                        <input
                          style={{ width: "100%" }}
                          className="inputfieldstyle"
                          type="text"
                          value={portfolioaddfromredux}
                          onChange={(event) => {
                            setaddress(event);
                          }}
                          placeholder="Address"
                        ></input>

                        <label className="pt-2" style={{ width: "100%" }}>
                          LinkedIn
                        </label>
                        <input
                          style={{ width: "100%" }}
                          className="inputfieldstyle"
                          type="text"
                          value={linkedinunfromredux}
                          onChange={(event) => {
                            setlinkedinun(event);
                          }}
                          placeholder="LinkedIn Username"
                        ></input>

                        <label className="pt-2" style={{ width: "100%" }}>
                          Profile Description
                        </label>
                        <textarea
                          style={{ width: "100%" }}
                          className="inputfieldstyle"
                          value={profiledescfromredux}
                          onChange={(event) => {
                            setprofiledescription(event);
                          }}
                          placeholder="professional title"
                        ></textarea>
                      </div>
                    </Accordion.Collapse>
                  </div>

                  <div className="col-md-12 m-0 p-0 mt-2">
                    <div className="col-md-12 m-0 p-0">
                      <Accordion.Toggle
                        className="col-md-12 m-0 p-3"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          textAlign: "center",
                          fontWeight: "800",
                          fontSize: 14,
                        }}
                        variant="link"
                        eventKey={"portfolioeducationdetailsaccordian"}
                      >
                        EDUCATION
                      </Accordion.Toggle>
                    </div>

                    <Accordion.Collapse
                      eventKey={"portfolioeducationdetailsaccordian"}
                    >
                      <div
                        className="panel-body"
                        style={{ textTransform: "none" }}
                      >
                        <label style={{ width: "100%" }}>
                          Education Label
                        </label>
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          className="inputfieldstyle"
                          value={
                            educationLabelfromredux
                          }
                          onChange={(event) => {
                            setEduLabel(event);
                          }}
                          placeholder="Education Label"
                        ></input>

                        {educationDetailsfromredux.map((eduDetail) => {
                          return (
                            <div>
                              <label style={{ width: "100%" }}>
                                Year Start-End
                              </label>
                              <input
                                style={{ width: "100%" }}
                                type="text"
                                className="inputfieldstyle"
                                value={
                                  eduDetail.educationYear
                                }
                                onChange={(event) => {
                                  setEducation(event, eduDetail.educationDetailsId);
                                }}
                                placeholder="Education Year"
                              ></input>

                              <label className="pt-2" style={{ width: "100%" }}>
                                Degree/Major Name
                              </label>
                              <input
                                style={{ width: "100%" }}
                                type="text"
                                className="inputfieldstyle"
                                value={
                                  eduDetail.educationDetailsCourse
                                }
                                onChange={(event) => {
                                  setEducationCourse(event, eduDetail.educationDetailsId);
                                }}
                                placeholder=" Degree/Major Name"
                              ></input>
                              <label className="pt-2" style={{ width: "100%" }}>
                                University
                              </label>
                              <input
                                style={{ width: "100%" }}
                                type="text"
                                className="inputfieldstyle"
                                value={
                                  eduDetail.educationDetailsUniversity
                                }
                                onChange={(event) => {
                                  setEducationUniversity(event, eduDetail.educationDetailsId);
                                }}
                                placeholder="University"
                              ></input>
                              <FaTrash className="" style={{ color: "red", marginLeft: "6cm" }}
                                onClick={() => setDeleteEducation(eduDetail.educationDetailsId)}
                              ></FaTrash>
                            </div>
                          );
                        })}
                        <FaPlus className="ml-4" onClick={() => setNewEducation()}></FaPlus>
                      </div>
                    </Accordion.Collapse>
                  </div>

                  <div className="col-md-12 m-0 p-0 mt-2">
                    <div className="col-md-12 m-0 p-0">
                      <Accordion.Toggle
                        className="col-md-12 m-0 p-3"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          textAlign: "center",
                          fontWeight: "800",
                          fontSize: 14,
                        }}
                        variant="link"
                        eventKey={"portfolioworkdetailsaccordian"}
                      >
                        WORK EXPERIENCE
                      </Accordion.Toggle>
                    </div>

                    <Accordion.Collapse
                      eventKey={"portfolioworkdetailsaccordian"}
                    >
                      <div
                        className="panel-body"
                        style={{ textTransform: "none" }}
                      >
                        <label style={{ width: "100%" }}>
                          Experience Label
                        </label>
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          className="inputfieldstyle"
                          value={
                            experienceLabelfromredux
                          }
                          onChange={(event) => {
                            setExpLabel(event);
                          }}
                          placeholder="Experience Label"
                        ></input>

                        {experiencefromredux.map((expDetail) => {
                          return (
                            <div>
                              <div className="col-md-12">
                                <label style={{ width: "100%" }}>
                                  Professional Title
                                </label>
                                <input
                                  style={{ width: "100%" }}
                                  type="text"
                                  className="inputfieldstyle"
                                  value={expDetail.experienceProjectName}
                                  onChange={(event) => {
                                    setprofessionalProjectName(event, expDetail.experienceDetailsId);
                                  }}
                                  placeholder="Company/Location"
                                ></input>

                                <div className="row">
                                  <div className="col-md-3">
                                    <label style={{ width: "100%" }}>Start</label>
                                    <input
                                      style={{ width: "100%" }}
                                      type="text"
                                      className="inputfieldstyle"
                                      value={expDetail.experienceStartDate}
                                      onChange={(event) => {
                                        setStartDate(event, expDetail.experienceDetailsId);
                                      }}
                                      placeholder="Start"
                                    ></input>
                                  </div>
                                  <div className="col-md-3">
                                    <label style={{ width: "100%" }}>End</label>
                                    <input
                                      style={{ width: "100%" }}
                                      type="text"
                                      className="inputfieldstyle"
                                      value={expDetail.experienceEndDate}
                                      onChange={(event) => {
                                        setEndDate(event, expDetail.experienceDetailsId);
                                      }}
                                      placeholder="end"
                                    ></input>
                                  </div>
                                  <div className="col-md-6">
                                    <label style={{ width: "100%" }}>Position</label>
                                    <input
                                      style={{ width: "100%" }}
                                      type="text"
                                      className="inputfieldstyle"
                                      value={expDetail.experiencePosition}
                                      onChange={(event) => {
                                        setPosition(event, expDetail.experienceDetailsId);
                                      }}
                                      placeholder="Position"
                                    ></input>
                                  </div>
                                </div>
                                <label className="pt-2" style={{ width: "100%" }}>
                                  Description
                                </label>
                                <textarea
                                  style={{ width: "100%" }}
                                  className="inputfieldstyle"
                                  value={expDetail.experienceDescription}
                                  onChange={(event) => {
                                    setExpdescription(event, expDetail.experienceDetailsId);
                                  }}
                                  placeholder="Proffessional Description"
                                ></textarea>
                              </div>
                              <FaTrash className="" style={{ color: "red", marginLeft: "6cm" }}
                                onClick={() => setDeleteExperience(expDetail.experienceDetailsId)}
                              ></FaTrash>
                            </div>
                          )
                        })}
                        <FaPlus className="ml-4"
                          onClick={() => setNewExperience()}
                        ></FaPlus>
                      </div>

                    </Accordion.Collapse>
                  </div>
                  <div className="col-md-12 m-0 p-0 mt-2">
                    <div className="col-md-12 m-0 p-0">
                      <Accordion.Toggle
                        className="col-md-12 m-0 p-3"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          textAlign: "center",
                          fontWeight: "800",
                          fontSize: 14,
                        }}
                        variant="link"
                        eventKey={"portfoliolanguagesaccordian"}
                      >
                        Languages
                      </Accordion.Toggle>
                    </div>
                    <Accordion.Collapse
                      eventKey={"portfoliolanguagesaccordian"}
                    >
                      <div
                        className="panel-body"
                        style={{ textTransform: "none" }}
                      >
                        <label style={{ width: "100%" }}>
                          Language Label
                        </label>
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          className="inputfieldstyle"
                          value={
                            languageLabelfromredux
                          }
                          onChange={(event) => {
                            setlangLabel(event);
                          }}
                          placeholder="Language Label"
                        ></input>

                        <div>
                          <label style={{ width: "100%" }}>
                            Languages
                          </label>
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            className="inputfieldstyle"
                            value={languagefromredux}
                            onChange={(event) => {
                              setlang(event);
                            }}
                            placeholder="Language1,Language2.."
                          ></input>
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>
                  <div className="col-md-12 m-0 p-0 mt-2">
                    <div className="col-md-12 m-0 p-0">
                      <Accordion.Toggle
                        className="col-md-12 m-0 p-3"
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          textAlign: "center",
                          fontWeight: "800",
                          fontSize: 14,
                        }}
                        variant="link"
                        eventKey={"portfolioskillsdetailsaccordian"}
                      >
                        SKILLS
                      </Accordion.Toggle>
                    </div>

                    <Accordion.Collapse
                      eventKey={"portfolioskillsdetailsaccordian"}
                    >
                      <div
                        className="panel-body"
                        style={{ textTransform: "none" }}
                      >
                        <label style={{ width: "100%" }}>
                          Skills Label
                        </label>
                        <input
                          style={{ width: "100%" }}
                          type="text"
                          className="inputfieldstyle"
                          value={
                            skillsLabelfromredux
                          }
                          onChange={(event) => {
                            setSkillsLab(event);
                          }}
                          placeholder="SkillS Label"
                        ></input>

                        <div>
                          <label style={{ width: "100%" }}>
                            Skills
                          </label>
                          <input
                            style={{ width: "100%" }}
                            type="text"
                            className="inputfieldstyle"
                            value={skillsfromredux}
                            onChange={(event) => {
                              setskill(event);
                            }}
                            placeholder="Skill1,Skill2.."
                          ></input>
                        </div>

                      </div>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  function setprofessionaltitle(event) {
    dispatch(setProfessionalTitle(event.target.value));
  }
  function setpn(event) {
    dispatch(setPhoneNumber(event.target.value));
  }

  function setprofiledescription(event) {
    dispatch(setProfileDescription(event.target.value));
  }

  function setemailaddress(event) {
    dispatch(setEmailAdd(event.target.value));
  }

  function setaddress(event) {
    dispatch(setPortfolioAddress(event.target.value));
  }

  function setlinkedinun(event) {
    dispatch(setLinkedinUsername(event.target.value));
  }
  function setEducation(event, id) {
    dispatch(setEductionDetailsYear({ value: event.target.value, id: id }));

  }
  function setEducationCourse(event, id) {
    dispatch(setEducationDetailsCourse({ value: event.target.value, id: id }));
  }
  function setEducationUniversity(event, id) {
    dispatch(setEducationDetailsUniversity({ value: event.target.value, id: id }));
  }
  function setprofessionalProjectName(event, id) {
    dispatch(setexperienceDetailsProjectName({ value: event.target.value, id: id }));
  }
  function setStartDate(event, id) {
    dispatch(setexperienceDetailsStartDate({ value: event.target.value, id: id }));
  }
  function setEndDate(event, id) {
    dispatch(setexperienceDetailsEndDate({ value: event.target.value, id: id }));
  }
  function setPosition(event, id) {
    dispatch(setexperienceDetailsPosition({ value: event.target.value, id: id }));
  }
  function setExpdescription(event, id) {
    dispatch(setexperienceDetailsDescription({ value: event.target.value, id: id }));
  }
  function setlang(event) {
    dispatch(setLanguages(event.target.value));
  }
  function setskill(event) {
    dispatch(setSkills(event.target.value));
  }
  function setBasicLabel(event) {
    dispatch(setBasicDetailsLabel(event.target.value));
  }
  function setEduLabel(event) {
    dispatch(setEducationLabel(event.target.value));
  }
  function setExpLabel(event) {
    dispatch(setExperienceLabel(event.target.value));
  }
  function setlangLabel(event) {
    dispatch(setLanguageLabel(event.target.value));
  }
  function setSkillsLab(event) {
    dispatch(setSkillsLabel(event.target.value));
  }
  function portfoliComponent(portfolio) {
    return (
      <div className="mr-4" key={portfolio.id}>
        <div>
          <div
            className="col-md-12 d-flex p-0"
            onMouseOver={console.log("pp",portfolio.id)}
            onClick={() => {
              updateportfolioselection(portfolio);
            }}
          >
            {portfolio.selected ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: "#00000030",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                }}
              >
                <img src={TickWhite} height={25} alt="tickimg" />
              </div>
            ) : null}
            <img
              className="image-design"
              src={portfolio.img}
              width={350}
              height={450}
              alt="designportfolio"
             
            />
          </div>
          <div
            className="d-flex justify-content-center pt-3 text-uppercase"
            style={{ fontSize: 12, fontWeight: 300 }}
          >
            {portfolio.title}
          </div>
        </div>
      </div>
    );
  }
};

export default ProfileBuilderScreen;
