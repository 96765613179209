import React, { useEffect, useState, useCallback } from "react";
import LoadingAnimation from "../../utils/loadingAnimation";
import {
  FaSearch,
  FaCaretDown,
  FaCaretLeft,
  FaCaretRight,
  FaExpand,
  FaEdit,
  FaEye,
  FaBars,
  FaReply,
} from "react-icons/fa";
import EssayImg from "../../imageAssets/essayimg.png";
import UniNameImg from "../../imageAssets/universitynameimg.png";
import ProfilePicSample from "../../imageAssets/profilepicsample.png";
import DocumentBg from "../../imageAssets/documentbg.png";
import RightArrow from "../../imageAssets/rightarrow.png";
import StatsImg from "../../imageAssets/statsimg.png";
import CloseRed from "../../imageAssets/closeRed.png";
import RadioButtonSelected from "../../imageAssets/radiobutonselected.png";
import RadioButtonUnSelected from "../../imageAssets/radiobutonunselected.png";
import PlusIconRound from "../../imageAssets/plusIconRound.png";
import moment from "moment";
// import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { MW_URL } from "../../config";
import ModalComponent from "../../utils/modalComponent";
import Collapsible from "../../components/collapsible";
import { findNotifDate, findNotiTimeAgeFun } from "../../modules/parserHelper";
import TodoList from "../../components/todolist";
import { useHistory } from 'react-router-dom';
import { isBefore } from "date-fns/esm";
// import EditorJS from '@editorjs/editorjs';
// import List from '@editorjs/list';
// import ImageTool from '@editorjs/image';
import { es } from "date-fns/locale";
// const Header = require('@editorjs/header');
const TextEditorScreen = () => {
  const createUniquieId = () => {
    const head = Date.now().toString(36);
    const tail = Math.random().toString(36).substr(2);
    return head + tail;
  };

  const [height, setHeight] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [textEditorMain, settextEditorMain] = useState(true);
  const [feedbackPage, setfeedbackPage] = useState(false);
  const [sidemenu, setsidemenu] = useState(false);
  const [todolist, settodolist] = useState([]);
  const [notificationDiv, setnotificationDiv] = useState(false);
  const [essayContent, setessayContent] = useState(
    "<h1>HEADING</h1><p>TYPE OR PASTE YOUR TEXT HERE</p>"
  );
  const [newEssayContent, setNewEssayContent] = useState();

  const [essayCollId, setessayCollId] = useState("");
  const [essayReqId, setessayReqId] = useState("");
  const [essayId, setessayId] = useState("");
  const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
  const [essaysList, setessaysList] = useState([]);
  const [essaysTemplateList, setessaysTemplateList] = useState([]);
  const [isNewEssay, setisNewEssay] = useState(true);
  const [feedbacksList, setfeedbacksList] = useState({
    feedbacks: [],
    currentPage: 1,
    feedbackPerPage: 4,
  });
  const [eventEditModalVisible, seteventEditModalVisible] = useState(false);
  const [feedbackInTextarea, setfeedbackInTextarea] = useState("");
  const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState(
    []
  );
  const [listOfSelectedCollagesArrtemp, setlistOfSelectedCollagesArrtemp] = useState(
    []
  );
  const [collageFilterCollageId, setcollageFilterCollageId] = useState("");
  const [updatesList, setupdatesList] = useState([]);
  const [editable, setEditable] = useState(false);
  const [ColId, setColId] = useState([]);
  const [addTodoModalVisible, setaddTodoModalVisible] = useState(false);
  const [todolistMap, settodolistMap] = useState(null);
  const [todoInTextarea, settodoInTextarea] = useState("");
  const [SearchKeyword, setSearchKeyword] = useState("");
  const history = useHistory();
  const [editorData, setEditorData] = useState(null);
  const [showSaveButtonForTodoUpdate, setshowSaveButtonForTodoUpdate] =
    useState(false);
  const setTodoToPassFun = useCallback(
    (todoListToSet) => {
      console.log("setTodoToPassFun");
      settodolist(todoListToSet);
    },
    [todolist]
  );
  useEffect(() => {
    var windowHeight = window.innerHeight;
    setHeight(windowHeight + "px");
    getAllEssays();
  }, [isUseEffectCallRequired]);

  useEffect(() => { }, [essaysTemplateList]);

  const getAllEssays = () => {
    setIsLoading(true);
    var selectedCollages = [];
    var selectedCollagesIds = [];
    axios
      .post(`${MW_URL}` + "getSelectedListOfCollages", {
        email: JSON.parse(localStorage.getItem("userData")).email,
      })
      .then((getSelectedListOfCollagesresponse) => {
        if (
          getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
            .length > 0
        ) {
          getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
            (collage) => {
              selectedCollages.push(collage);
              selectedCollagesIds.push(collage.collageId);
            }
          );
          // console.log("q", selectedCollages)
          setlistOfSelectedCollagesArr(selectedCollages);
          setlistOfSelectedCollagesArrtemp(selectedCollages);
          if (getSelectedListOfCollagesresponse.data[0].todoList) {
            var mapToSet = new Map(
              Object.entries(getSelectedListOfCollagesresponse.data[0].todoList)
            );
            selectedCollages.map((colgSelected) => {
              if (mapToSet.get(colgSelected.collageId) == null)
                mapToSet.set(colgSelected.collageId, []);
            });
            settodolistMap(mapToSet);
          } else {
            let todoMap = new Map();
            selectedCollages.map((colgSelected) => {
              if (todoMap.get(colgSelected.collageId) == null)
                todoMap.set(colgSelected.collageId, []);
            });
            settodolistMap(todoMap);
          }
          var templateArr = [];
          var essaysRequirementIds = [];
          var essayReqIds = [];
          axios
            .post(`${MW_URL}` + "get_essay_requirement", selectedCollages)
            .then((getEssaysTemplateResponse) => {
              // console.log(
              //   "getEssaysTemplateResponse",
              //   getEssaysTemplateResponse
              // );
              var status = "NA";
              if (
                getEssaysTemplateResponse &&
                getEssaysTemplateResponse.data &&
                getEssaysTemplateResponse.data.STATUS
              )
                status = getEssaysTemplateResponse.data.STATUS;
              if (status.toUpperCase() === "SUCCESS") {
                setessaysTemplateList(getEssaysTemplateResponse.data.data);
                getEssaysTemplateResponse.data.data.map(
                  (templateRes) => {
                    if (templateRes.essayRequirements.length != 0) {
                      templateRes.essayRequirements.map((esaaReq) => {
                        essayReqIds.push(esaaReq.essayReqId);
                        templateArr.push({
                          template: esaaReq.question,
                          collageId: templateRes.collageId,
                          name: templateRes.name,
                          essayReqId: esaaReq.essayReqId,
                          length: esaaReq.length,
                          requirement: esaaReq.requirement,
                          answered: essaysRequirementIds.includes(
                            esaaReq.essayReqId
                          ),
                        });
                      });
                    }

                  })
                axios
                  .post(`${MW_URL}` + "getEssays", {
                    ownerId: JSON.parse(localStorage.getItem("userData")).email,
                  })
                  .then((getAllEssaysResponse) => {
                    setIsUseEffectCallRequired(false);
                    var essaysListToPush = [];
                    var essaysRequirementIds = [];
                    getAllEssaysResponse.data.map((essay) => {
                      if (
                        essay.ownerId ==
                        JSON.parse(localStorage.getItem("userData")).email &&
                        selectedCollagesIds.includes(essay.collageId) &&
                        essayReqIds.includes(essay.essayReqId)
                      ) {
                        essaysListToPush.push({
                          essayId: essay.essayId,
                          content: essay.content,
                          newEditorContentEssay: essay.newEditorcontentEssay,
                          typeOfDocument: essay.typeOfDocument,
                          collageId: essay.collageId,
                          ownerId: essay.ownerId,
                          feedbacks: essay.feedbacks,
                          essayReqId: essay.essayReqId,
                          updatedDate: essay.updatedDate,
                          isApproved: essay.isApproved,
                        });
                        essaysRequirementIds.push(essay.essayReqId);
                      }
                    });
                    setessaysList(essaysListToPush);

                    const params = new URLSearchParams(
                      window.location.search
                    );
                    if (params.get("id")) {
                      if (params.get("id") == "recent") {
                        viewEssay(essaysListToPush[0]);
                      } else {
                        const selectedEssay = essaysListToPush.find(
                          (essay) => essay.essayId == params.get("id")
                        );
                        // console.log("selectedEssay====>", selectedEssay);
                        viewEssay(selectedEssay);
                      }
                    } else {
                      console.log("No params");
                    }

                    axios
                      .post(`${MW_URL}` + "getUpdates", {
                        recipientId: JSON.parse(
                          localStorage.getItem("userData")
                        ).email,
                        senderId: false,
                      })
                      .then((getUpdatesResponse) => {

                        setupdatesList(getUpdatesResponse.data);
                        setessaysTemplateList(
                          templateArr.map((reqEssay) => {
                            return {
                              ...reqEssay,
                              answered: essaysRequirementIds.includes(
                                reqEssay.essayReqId
                              ),
                            };
                          })
                        );
                        setIsLoading(false);
                      })
                      .catch((err) => {
                        console.log("err >> ", err);
                      });
                  })
                  .catch((err) => {
                    console.log("err >> ", err);
                  });
              } else {
                setIsLoading(false);
              }
            })
            .catch((err) => {
              console.log("err >> ", err);
            });
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };
  var todayDate = moment().format("dddd/MMM/DD/YYYY");
  var today = todayDate.split("/");
  const defaultDateValue = {
    weekday: today[0],
    year: parseInt(today[3]),
    month: parseInt(today[2]),
    day: today[1],
  };

  const addNewEssay = (template) => {
    // setIsLoading(true);
    setEditable(true);
    var essaytemplate = {
      time: "",
      blocks: [
        {
          type: "",
          data: {
            text: ""
          }
        }
      ],
      version: "2.11.10"
    }

    essaytemplate = {
      time: "",
      blocks: [
        {
          type: "paragraph",
          data: {
            text: template.template
          }
        }
      ],
      version: "2.11.10"
    }
    editorinitializw(essaytemplate)
    setfeedbacksList({
      ...feedbacksList,
      feedbacks: [],
    });
    setisNewEssay(true);
    setessayId(createUniquieId());
    //resetEssayContent();
    setessayContent(template.template);
    setessayCollId(template.collageId);
    setessayReqId(template.essayReqId);
    showfeedbackPage();
  };

  const editEssay = (essay) => {
    setEditable(true);
    editorinitializw(essay.newEditorContentEssay, editable);
    setIsLoading(false);
    setfeedbacksList({
      ...feedbacksList,
      feedbacks: essay.feedbacks,
    });
    setisNewEssay(false);
    setessayId(essay.essayId);
    setessayContent(essay.content);
    setessayCollId(essay.collageId);
    setessayReqId(essay.essayReqId);
    showfeedbackPage();
  };
  const viewEssay = (essay) => {
    // setIsLoading(true);
    setEditable(false);
    editorinitializw(essay.newEditorContentEssay);
    setfeedbacksList({
      ...feedbacksList,
      feedbacks: essay.feedbacks,
    });

    setisNewEssay(false);
    setessayId(essay.essayId);
    setessayContent(essay.content);
    setNewEssayContent(essay.newEditorContentEssay)
    setessayCollId(essay.collageId);
    setessayReqId(essay.essayReqId);
    showfeedbackPage();
  };
  const uploadDocument = () => {
    if (isNewEssay) {
      addEssayToDb();
    } else {
      editEssayInDb();
    }
  };

  const showfeedbackPage = () => {
    settextEditorMain(false);
    setfeedbackPage(true);
  };

  const hidefeedbackPage = () => {
    getAllEssays();
    settextEditorMain(true);
    setfeedbackPage(false);
    setsidemenu(false);
  };

  const resetEssayContent = () => {
    setessayContent("<h1>HEADING</h1><p>TYPE OR PASTE YOUR TEXT HERE</p>");
  };
  // console.log("todos", todolist);
  const updateTodoList = (todoSelected) => {
    // console.log("todoSelected", todoSelected);
    setshowSaveButtonForTodoUpdate(true);
    settodolist(
      todolist.map((todo) => {
        if (todo.id == todoSelected.id) {
          if (todo.selected) {
            return {
              ...todo,
              selected: false,
            };
          } else {
            return {
              ...todo,
              selected: true,
            };
          }
        } else {
          return {
            ...todo,
            selected: todo.selected,
          };
        }
      })
    );
  };

  const deleteFeedback = (index) => {
    setIsLoading(true);
    var { feedbacks, currentPage, feedbackPerPage } = feedbacksList;

    //calculate the feedback index to delete
    var feedbackindextodelete = (currentPage - 1) * feedbackPerPage + index;

    feedbacks.splice(feedbackindextodelete, 1);

    var dataToSend = {
      essayId: essayId,
      feedbacks: feedbacks,
      updateId: createUniquieId(),
      recipientId: JSON.parse(localStorage.getItem("userData")).counsellorId,
      senderId: JSON.parse(localStorage.getItem("userData")).email,
      message:
        JSON.parse(localStorage.getItem("userData")).username +
        " deleted a feedback",
    };
    setIsLoading(true);
    axios
      .post(`${MW_URL}` + "updatefeedbacks", dataToSend)
      .then((response) => {
        var status = "NA";
        if (response && response.data && response.data.STATUS)
          status = response.data.STATUS;
        if (status.toUpperCase() === "SUCCESS") {
          setIsLoading(false);
          setfeedbacksList({
            ...feedbacksList,
            feedbacks: feedbacks,
          });
        } else {
        }
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };

  const handleOnChange = (e) => {
    setessayContent(e.target.getContent());
  };
  const handleOnEditorChange = (content) => {
    setessayContent(content);
  };
  const addEssayToDb = () => {
    var essayCont = []
    let essayCollege = listOfSelectedCollagesArr.find((col) => { return col.collageId == essayCollId })
    editorData.save().then((outputData) => {
      outputData.blocks.map((es) => {
        essayCont.push(es.data.text);
      })
      var esContent = essayCont.toString();
      var dataToSend = {
        essayId: essayId,
        content: esContent,
        newEditorContent: outputData,
        typeOfDocument: "essay",
        collageId: essayCollId,
        ownerId: JSON.parse(localStorage.getItem("userData")).email,
        feedbacks: feedbacksList.feedbacks,
        essayReqId: essayReqId,
        updateId: createUniquieId(),
        recipientId: JSON.parse(localStorage.getItem("userData")).counsellorId,
        senderId: JSON.parse(localStorage.getItem("userData")).email,
        message:
          JSON.parse(localStorage.getItem("userData")).username +
          " added " + essayCollege.name + "'s essay",
      };
      setIsLoading(true);
      axios
        .post(`${MW_URL}` + "addEssay", dataToSend)
        .then((response) => {
          var status = "NA";
          if (response && response.data && response.data.STATUS)
            status = response.data.STATUS;
          if (status.toUpperCase() === "SUCCESS") {
            setIsLoading(false);
            hidefeedbackPage();
            resetEssayContent();
          } else {
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    }).catch((error) => {
      console.log('Saving failed: ', error)
    });
  };

  const editEssayInDb = () => {
    console.log("h123")
    var essayCont = []
    let essayCollege = listOfSelectedCollagesArr.find((col) => { return col.collageId == essayCollId })
    editorData.save().then((outputData) => {
      outputData.blocks.map((es) => {
        essayCont.push(es.data.text);
      })
      var esContent = essayCont.toString();
      var dataToSend = {
        essayId: essayId,
        content: esContent,
        newEditorContent: outputData,
        typeOfDocument: "essay",
        collageId: essayCollId,
        ownerId: JSON.parse(localStorage.getItem("userData")).email,
        feedbacks: feedbacksList.feedbacks,
        essayReqId: essayReqId,
        updateId: createUniquieId(),
        recipientId: JSON.parse(localStorage.getItem("userData")).counsellorId,
        senderId: JSON.parse(localStorage.getItem("userData")).email,
        message:
          JSON.parse(localStorage.getItem("userData")).username +
          " edited " + essayCollege.name + "'s essay",
      };
      setIsLoading(true);
      console.log(dataToSend, "ffdata")
      axios
        .post(`${MW_URL}` + "editEssay", dataToSend)
        .then((response) => {
          var status = "NA";
          if (response && response.data && response.data.STATUS)
            status = response.data.STATUS;
          if (status.toUpperCase() === "SUCCESS") {
            hidefeedbackPage();
            resetEssayContent();
            setIsLoading(false);
          } else {
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    }).catch((error) => {
      console.log('Saving failed: ', error)
    });
  };

  const eventEditModalHandler = (dataObject) => {
    if (dataObject.type === "cancel") {
      seteventEditModalVisible(false);
    } else if (dataObject.type === "submit") {
      setfeedbacksList({
        ...feedbacksList,
        feedbacks: [
          ...feedbacksList.feedbacks,
          {
            feedback: feedbackInTextarea,
            usertype: JSON.parse(localStorage.getItem("userData")).userType,
          },
        ],
      });
      var dataToSend = {
        essayId: essayId,
        feedbacks: [
          ...feedbacksList.feedbacks,
          {
            feedback: feedbackInTextarea,
            usertype: JSON.parse(localStorage.getItem("userData")).userType,
          },
        ],
        updateId: createUniquieId(),
        recipientId: JSON.parse(localStorage.getItem("userData")).counsellorId,
        senderId: JSON.parse(localStorage.getItem("userData")).email,
        message:
          JSON.parse(localStorage.getItem("userData")).username +
          " added a feedback",
      };
      setIsLoading(true);
      axios
        .post(`${MW_URL}` + "updatefeedbacks", dataToSend)
        .then((response) => {
          var status = "NA";
          if (response && response.data && response.data.STATUS)
            status = response.data.STATUS;
          if (status.toUpperCase() === "SUCCESS") {
            setIsLoading(false);
            setfeedbackInTextarea("");
            seteventEditModalVisible(false);
            setfeedbacksList({
              ...feedbacksList,
              feedbacks: [
                ...feedbacksList.feedbacks,
                {
                  feedback: feedbackInTextarea,
                  usertype: JSON.parse(localStorage.getItem("userData"))
                    .userType,
                },
              ],
              currentPage: Math.ceil(
                (feedbacksList.feedbacks.length + 1) /
                feedbacksList.feedbackPerPage
              ),
            });
          } else {
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    }
  };
  const addTodoModalHandler = (dataObject) => {
    if (dataObject.type === "cancel") {
      setaddTodoModalVisible(false);
    } else if (dataObject.type === "submit") {
      addtodoList();
    }
  };

  function addtodoList() {
    setIsLoading(true);
    setaddTodoModalVisible(false);
    var currentCollageTodoLIst = todolistMap.get(ColId.colid);
    var todolistToPass = new Map(
      todolistMap.set(ColId.colid, [
        ...currentCollageTodoLIst,
        { id: createUniquieId(), title: todoInTextarea, selected: false },
      ])
    );
    // console.log("todolistToPass", todolistToPass);
    axios
      .post(`${MW_URL}` + "addtodoList", {
        todoList: Object.fromEntries(todolistToPass),
        email: JSON.parse(localStorage.getItem("userData")).email,
      })
      .then((response) => {
        settodolistMap(
          new Map(
            todolistMap.set(ColId.colid, [
              ...currentCollageTodoLIst,
              { id: createUniquieId(), title: todoInTextarea, selected: false },
            ])
          )
        );
        settodoInTextarea("");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err");
      });
  }

  const edittodolist = async (todolistforselectedcollage) => {
    setIsLoading(true);
    // console.log("todolistforselectedcollage", todolistforselectedcollage);
    var todolistToPass = new Map(
      todolistMap.set(ColId.colid, todolistforselectedcollage)
    );
    axios
      .post(`${MW_URL}` + "addtodoList", {
        todoList: Object.fromEntries(todolistToPass),
        email: JSON.parse(localStorage.getItem("userData")).email,
      })
      .then((response) => {
        settodolistMap(
          new Map(todolistMap.set(ColId.colid, todolistforselectedcollage))
        );
        setshowSaveButtonForTodoUpdate(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err");
      });
  };
  const handleChange = (colid) => {
    setColId({ colid });
  };
  const handleSort = (e) => {
    var Tempres = [];
    listOfSelectedCollagesArr.map((date) => {
      if (e.target.value == "sort") {
        var isevent = moment().isBefore(date.deadline, "day");
        if (isevent) {
          Tempres.push(date)
          setlistOfSelectedCollagesArr(Tempres)
        }
      } else if (e.target.value == "all") {
        setlistOfSelectedCollagesArr(listOfSelectedCollagesArrtemp)
      }
    })
  };
  const regex = /(<([^>]+)>)/ig;
  const handleSearch = (value) => {
    setSearchKeyword(value)
    var temp = [];
    var Tempres = [];
    listOfSelectedCollagesArr.map((collage) => {
      temp = collage.name
      var searchRes = temp.toLowerCase().includes(SearchKeyword.toLowerCase());
      if (searchRes) {
        Tempres.push(collage)
        setlistOfSelectedCollagesArr(Tempres)
        console.log(Tempres, "search res")
      }
      else {
        setlistOfSelectedCollagesArr([])
      }
    });
    if (SearchKeyword.length < 2) {
      setlistOfSelectedCollagesArr(listOfSelectedCollagesArrtemp)
    }
  };

  const editorinitializw = (newEssayContent) => {
    // const Editor = {
    //   holderId: 'editorjs',
    //   readOnly: editable,
    //   placeholder: 'Write Essay',
    //   autofocus: true,
    //   // data:newEssayContent,
    //   tools: {
    //     header: {
    //       class: Header,
    //       inlineToolbar: ['link']
    //     },
    //     list: {
    //       class: List,
    //       inlineToolbar: ['link', 'bold']
    //     },
    //   },
    //   data: typeof newEssayContent === "string" ? JSON.parse(newEssayContent) : newEssayContent
    // }
    // const editor = new EditorJS(Editor);
    // setEditorData(editor)
  }
  return (
    <div>
      {isLoading ? <LoadingAnimation isVisible={true} /> : null}
      <div
        className="text-editor-page-container pb-5"
        style={{ minHeight: height }}
      >
        {textEditorMain ? (
          <div>
            <div
              className="col-md-12 pl-5 pt-5  d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <div
                className="col-md-6 searchbox-style d-flex align-items-center"
                style={{ height: 30 }}
              >
                <div className="col-md-11">
                  <input
                    style={{ border: 0, width: "100%", outline: "none" }}
                    type="text"
                    id="search"
                    name="search"
                    placeholder="Search"
                    value={SearchKeyword}
                    onChange={(event) => {
                      handleSearch(event.target.value);
                    }}
                  />
                </div>
                <div className="col-md-1 p-0 m-0 d-flex justify-content-end">
                  <FaSearch style={{ fontSize: 12 }} />
                </div>
              </div>
              <div className="col-md-3 p-0 pr-4 d-flex justify-content-end align-items-center text-right">
                <div className="pr-2 col-md-8">
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: 16,
                      textTransform: "uppercase",
                    }}
                  >
                    {JSON.parse(localStorage.getItem("userData")).username}
                  </div>
                  <div style={{ fontSize: 7 }}>WELCOME BACK</div>
                </div>
                <div className="p-0 m-0 col-md-3" style={{ zIndex: 2 }}>
                  {notificationDiv ? (
                    <div className="col-md-12 m-0 p-0 animated fadeInDown">
                      <div
                        className="m-3 p-0 py-4 pl-4 card-design"
                        style={{
                          width: 240,
                          borderRadius: 15,
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        {updatesList.map((update) => {
                          if (update.updateDetails && update.updateDetails.type == "essay") {
                            return notificationRow(update);
                          }
                        })}

                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-12" style={{ zIndex: 2 }}>
                    <div
                      className="d-flex justify-content-center"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        fontSize: 10,
                        width: 15,
                        height: 15,
                        position: "absolute",
                        right: "5%",
                        top: 4,
                        borderRadius: "50%",
                      }}
                    >
                      6
                    </div>
                  </div>
                  <img
                    onClick={() => setnotificationDiv(!notificationDiv)}
                    className="col-md-12 p-0"
                    style={{ zIndex: 1, cursor: "pointer" }}
                    height={60}
                    src={ProfilePicSample}
                    alt="dpsaple"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 pl-4 pt-4 d-flex justify-content-between">
              {bottomLeftComponent()}
              <div className="col-md-6 pl-4">
                <div className="heaidng-style">essays</div>
                <div className="col-md-12 m-0 p-0 mt-3 d-flex flex-column align-items-center">
                  <div
                    className="col-md-12"
                    style={{
                      maxHeight: (window.innerHeight * 72) / 100 + "px",
                      overflow: "scroll",
                    }}
                  >
                    <div style={{ fontSize: 12, fontWeight: 600 }}>
                      Answered
                    </div>
                    {listOfSelectedCollagesArr.length == 0 ? (
                      <div
                        className="p-5 align-items-center justify-content-center link-text-style"
                        style={{ textAlign: "center", fontSize: 14 }}
                      >
                        No Collages Selected ,Please Select Collages from
                        Collage Select section
                      </div>
                    ) : null}
                    {essaysList.map((essay) => {
                      if (collageFilterCollageId == "") {
                        return essaysComponent(essay);
                      } else if (collageFilterCollageId == essay.collageId) {
                        return essaysComponent(essay);
                      } else {
                        return null;
                      }
                    })}
                    <div style={{ fontSize: 12, fontWeight: 600 }}>
                      Requirement
                    </div>
                    {listOfSelectedCollagesArr.length == 0 ? (
                      <div
                        className="p-5 align-items-center justify-content-center link-text-style"
                        style={{ textAlign: "center", fontSize: 14 }}
                      >
                        No Collages Selected ,Please Select Collages from
                        Collage Select section
                      </div>
                    ) : null}
                    {essaysTemplateList.map((template) => {
                      if (collageFilterCollageId == "") {
                        return essayTemplateComponent(template);
                      } else if (collageFilterCollageId == template.collageId) {
                        return essayTemplateComponent(template);
                      } else {
                        return null;
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {feedbackPage ? (
          <div style={{ minHeight: (window.innerHeight * 95) / 100 + "px" }}>
            {sidemenu ? (
              <div
                className={
                  sidemenu ? "animated fadeIn ml-1 sidemenustyle" : null
                }
                style={{
                  backgroundColor: "white",
                  width: 300,
                  minHeight: height,
                  position: "absolute",
                  zIndex: 2,
                }}
              >
                <div className="pt-5 align-items-center">
                  <div className="p-2 pt-5 mx-4 d-flex flex-column align-items-center justify-content-center">
                    <div
                      className="col-md-12 py-2 text-center border-bottom"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const params = new URLSearchParams(
                          window.location.search
                        );
                        if (params.get("id")) {
                          history.push("/textEditor");
                        }
                        hidefeedbackPage();
                      }}
                    >
                      Home
                    </div>
                    <div
                      className="col-md-12 py-2 text-center border-bottom"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        uploadDocument();
                      }}
                    >
                      Upload Document
                    </div>
                    <div
                      className="col-md-12 py-2 text-center border-bottom"
                      style={{ cursor: "pointer" }}
                    >
                      Harvard University
                    </div>
                    <div
                      className="col-md-12 py-2 text-center border-bottom"
                      style={{ cursor: "pointer" }}
                    >
                      Stanford University
                    </div>
                    <div
                      className="col-md-12 py-2 text-center border-bottom"
                      style={{ cursor: "pointer" }}
                    >
                      Support
                    </div>
                    <div
                      className="col-md-12 py-2 text-center"
                      style={{ cursor: "pointer", fontWeight: 800 }}
                      onClick={() => {
                        setsidemenu(!sidemenu);
                      }}
                    >
                      X
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div
              className="col-md-12 d-flex py-5 px-4"
              style={{ height: "100%" }}
            >
              <div className="col-md-1" style={{ zIndex: 3 }}>
                <div
                  className="featherdivstyle p-1 d-flex align-items-center justify-content-center"
                  style={{ width: 30 }}
                  onClick={() => {
                    setsidemenu(!sidemenu);
                  }}
                >
                  <FaBars style={{ fontSize: 20, cursor: "pointer" }} />
                </div>
              </div>
              <div className="col-md-6 pr-0">

                {/*  <Editor
                  apiKey="ja9tz9km2k08vicadj1vsr91jfmjaz9usmtzwc9zdywcx8wh"
                  value={essayContent}
                  disabled={editable}
                  // initialValue="<h1>HEADING</h1><p>TYPE OR PASTE YOUR TEXT HERE</p>"

                  init={{
                    height: 580,
                    menubar: false,

                    toolbar: editable
                      ? false
                      : "undo redo | formatselect | bold italic | \
                                        alignleft aligncenter alignright | \
                                        bullist numlist outdent indent | help",
                  }}
                  onChange={handleOnChange}
                  onEditorChange={handleOnEditorChange}
                  onInit={() => {
                    setIsLoading(false);
                  }}
                />*/}

                <div id='editorjs'></div>
                {editable == true ? (
                  <div
                    className="col-md-12 py-2 text-center border-bottom"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      uploadDocument();
                    }}
                  >
                    Upload Document
                  </div>
                ) : null}
              </div>
              <div
                className="col-md-1 d-flex align-items-center justify-content-center"
                style={{ height: (window.innerHeight * 80) / 100 + "px" }}
              >
                <div
                  style={{
                    backgroundColor: "#FAB566",
                    height: (window.innerHeight * 70) / 100 + "px",
                    width: "3px",
                  }}
                ></div>
              </div>
              <div className="col-md-4 pl-0 pt-5">
                <div
                  className="pb-2 pl-5 ml-2"
                  style={{ fontWeight: "800", fontSize: 20 }}
                >
                  FEEDBACK
                </div>
                {renderFeedback()}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <ModalComponent
        isVisible={eventEditModalVisible}
        mainHeading="Add a feedback"
        cancelButtonText="Cancel"
        submitButtonText="Save"
        callback={eventEditModalHandler}
      >
        <div className="pt-2 pb-5">
          <textarea
            value={feedbackInTextarea}
            placeholder="TYPE YOUR FEEDBACK HERE"
            onChange={(event) => {
              setfeedbackInTextarea(event.target.value);
            }}
            id="w3review"
            name="w3review"
            rows="4"
            cols="42"
          >
            Write a feedback
          </textarea>
        </div>
      </ModalComponent>
      <ModalComponent
        isVisible={addTodoModalVisible}
        mainHeading="Add todo"
        cancelButtonText="Cancel"
        submitButtonText="Add"
        callback={addTodoModalHandler}
      >
        <div className="pt-2 pb-5">
          <textarea
            value={todoInTextarea}
            placeholder="TYPE YOUR TODO HERE"
            onChange={(event) => {
              settodoInTextarea(event.target.value);
            }}
            id="w3review"
            name="w3review"
            rows="4"
            cols="42"
          >
            Write a todo
          </textarea>
        </div>
      </ModalComponent>
    </div>
  );

  function handleClick(event) {
    setfeedbacksList({
      ...feedbacksList,
      currentPage: Number(event.target.id),
    });
  }

  function renderFeedback() {
    const { feedbacks, currentPage, feedbackPerPage } = feedbacksList;

    // Logic for displaying todos
    const indexOfLastFeedback = currentPage * feedbackPerPage;
    const indexOfFirstFeedback = indexOfLastFeedback - feedbackPerPage;
    const currentFeedbacks = feedbacks.slice(
      indexOfFirstFeedback,
      indexOfLastFeedback
    );

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(feedbacks.length / feedbackPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div>
        <div>
          {currentFeedbacks.map((feedback, index) => {
            return feedbackComponent(feedback, index);
          })}
        </div>
        <div
          className="col-md-12 d-flex align-items-center pt-3 px-5"
          style={{
            justifyContent: pageNumbers.length > 0 ? "space-between" : "center",
          }}
        >
          {pageNumbers.map((number, index) => {
            return (
              <div
                style={{
                  cursor: "pointer",
                  color: currentPage == number ? "black" : "grey",
                }}
                key={number}
                id={number}
                onClick={handleClick}
              >
                {number}
              </div>
            );
          })}
          <img
            height={30}
            style={{ cursor: "pointer" }}
            src={PlusIconRound}
            alt="addicon"
            onClick={() => {
              seteventEditModalVisible(true);
            }}
          />
        </div>
      </div>
    );
  }

  function notificationRow(update) {
    return (
      <div
        className="d-flex justify-content-start align-items-center p-2"
        style={{ borderBottom: "1px solid #00000020", cursor: "pointer" }}
      >
        <div className="pl-00">
          <div style={{ fontSize: 10, fontWeight: 600 }}>{update.message.substring(0, 75).replace(regex, '')}...</div>
          <div style={{ fontSize: 8, color: "grey" }}>
          </div>
        </div>
      </div>

    );
  }

  function essaysComponent(essay) {
    var essayCollege = []
    // if(listOfSelectedCollagesArr.length>1){
    //  essayCollege = listOfSelectedCollagesArr.map((col) => { return col.collageId == essay.collageId })
    // }
    listOfSelectedCollagesArr.map((col) => {
      if (col.collageId == essay.collageId) {
        essayCollege.push(col.name)
      }
    })
    return (
      <div
        className="col-md-12 m-0 p-0 d-flex align-items-center"
        style={{ borderBottom: "1px solid #00000029" }}
      >
        <div className="col-md-2 p-0 m-0 d-flex align-items-center justify-content-center">
          <img height={65} src={EssayImg} alt="essayimg" />
        </div>
        <div className="p-0 pt-3 col-md-10">
          <div
            className="d-flex pr-1"
            style={{
              height: (window.innerHeight * 4) / 100 + "px",
              fontSize: 7,
              fontWeight: 600,
              whiteSpace: "pre-line",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
            }}
          >
            {essay.content.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, "")}
          </div>
          <div className="d-flex">
            <div className="pr-2" style={{ fontSize: 8, color: "grey" }}>
              {findNotifDate(essay.updatedDate)}
            </div>
            <div style={{ fontSize: 8 }}>
              {essayCollege}
            </div>
          </div>
          <div className="d-flex justify-content-end p-0 pr-3">
            <FaExpand
              className="pr-2"
              style={{ fontSize: 16, color: "grey", cursor: "pointer" }}
            />
            {essay.isApproved != true ? (
              <FaEdit
                className="pr-2"
                style={{ fontSize: 16, color: "grey", cursor: "pointer" }}
                onClick={() => {
                  editEssay(essay);
                }}
              />
            ) : null}
            <FaEye
              className="pr-1"
              style={{ fontSize: 16, color: "grey", cursor: "pointer" }}
              onClick={() => {
                viewEssay(essay);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  function essayTemplateComponent(template) {
    console.log(template, "templ")
    return (
      <div
        className="col-md-12 m-0 p-0 d-flex align-items-center"
        style={{ borderBottom: "1px solid #00000029" }}
      >
        <div className="col-md-2 p-0 m-0 d-flex align-items-center justify-content-center">
          <img height={65} src={EssayImg} alt="essayimg" />
        </div>
        <div className="p-0 pt-3 col-md-10">
          <div
            className="d-flex pr-1"
            style={{
              height: (window.innerHeight * 4) / 100 + "px",
              fontSize: 7,
              fontWeight: 600,
              whiteSpace: "pre-line",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
            }}
          >
            {template.template}
          </div>
          <div className="d-flex">
            <div className="pr-2" style={{ fontSize: 8, color: "grey" }}>
              8 mins ago
            </div>
            {/* <img height={15} src={UniNameImg} alt="uninameimg" /> */}
            <div className="pl-2" style={{ fontSize: 8, color: "grey" }}>
              {template.name}
            </div>
          </div>
          <div className="d-flex justify-content-end p-0 pr-3">
            {template.answered ? null : (
              <FaReply
                className="pr-1"
                style={{ fontSize: 16, color: "grey", cursor: "pointer" }}
                onClick={() => {
                  addNewEssay(template);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  function feedbackComponent(feedbackContent, index) {
    return (
      <div className="col-md-12 m-0 p-0 d-flex align-items-center">
        <img
          style={{ cursor: "pointer" }}
          height={15}
          src={CloseRed}
          alt="CloseRed"
          onClick={() => {
            deleteFeedback(index);
          }}
        />
        <div
          className="col-md-10 p-0 pt-3 pb-3 ml-3"
          style={{ borderBottom: "1px solid #00000029" }}
        >
          <div
            className=""
            style={{
              maxHeight: (window.innerHeight * 6) / 100 + "px",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              fontSize: 8,
              fontWeight: 600,
              textAlign: "left",
              textOverflow: "ellipsis",
              color: feedbackContent.usertype == "counsellor" ? "red" : "black",
            }}
          >
            {feedbackContent.feedback}
          </div>
        </div>
      </div>
    );
  }

  function bottomLeftComponent() {
    var coll = [];
    listOfSelectedCollagesArr.forEach((col) => {
      if (col) {
        const colls = {
          name: col.name,
          id: col.collageId,
        };
        coll.push(colls);
      }
    });
    var deadlinez = {
      deadline: "",
    };
    var todolistToPass = [];
    if (ColId) {
      listOfSelectedCollagesArr.forEach((col) => {
        if (ColId.colid == col.collageId) {
          deadlinez = {
            deadline: col.deadline,
          };
          //settodolist(todolistMap.get(ColId.colid));
          if (todolist != todolistMap.get(ColId.colid)) {
            // setTodoToPassFun(todolistMap.get(ColId.colid));
            settodolist(todolistMap.get(ColId.colid));
          }

          todolistToPass = todolistMap.get(ColId.colid);
        }
      });
    }
    return (
      <div className="col-md-6">
        <div className="d-flex justify-content-between">
          <div className="col-md-8 heaidng-style">documents</div>
          <div className="col-md-4 d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center">
              <div className="link-text-style">
                <select
                  name="Deadline"
                  style={{ border: "none" }}
                  id="deadline"
                  className="opacity"
                  onChange={handleSort}
                >
                  <option value="">Deadline</option>
                  <option value="sort">Filter By Deadline</option>
                  <option value="all">View All</option>

                </select>
              </div>
            </div>
            {/* <div className="link-text-style">View All</div>
            <FaCaretLeft style={{ fontSize: 12 }} />
    <FaCaretRight style={{ fontSize: 12 }} /> */}
          </div>
        </div>
        <div className="col-md-12 d-flex " style={{ overflow: "scroll" }}>
          {listOfSelectedCollagesArr.length == 0 ? (
            <div
              className="p-5 align-items-center justify-content-center link-text-style"
              style={{ textAlign: "center", fontSize: 14 }}
            >
              No Collages Selected ,Please Select Collages from Collage Select
              section
            </div>
          ) : (
            listOfSelectedCollagesArr.map((selectedColl) => {
              return documentComponent(selectedColl);
            })
          )}

        </div>
        <div className="pt-4 d-flex justify-content-between">
          <div className="col-md-6 pt-4">
            <div className="heaidng-style">updates</div>
            {updatesList.length == 0 ? (
              <div
                className="p-5 align-items-center justify-content-center link-text-style"
                style={{ textAlign: "center", fontSize: 14 }}
              >
                No updates
              </div>
            ) : (
              <div
                className="col-md-12 p-0 pt-2 mt-4 card-design"
                style={{ maxHeight: 250, overflow: "scroll" }}
              >
                {updatesList.map((update) => {
                  if (update.updateDetails && update.updateDetails.type == "essay") {
                    return updatesComponent(update);
                  }
                })}
              </div>
            )}
          </div>
          <div className="col-md-6 p-0 pt-4">
            <div>
              <div className="col-md-8 p-0 m-0 heaidng-style">to-do</div>
              <div className="col-md-12 p-4 mt-4 card-design">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="p-0 d-flex align-items-center">
                    <div className="p-1 dateStyleBig">
                      {defaultDateValue.month}
                    </div>
                    <div className="p-0 text-uppercase dateStyleSmall">
                      <div>{defaultDateValue.day}</div>
                      <div>{defaultDateValue.year}</div>
                    </div>
                  </div>
                  <div className=" text-uppercase dateStyleSmall">
                    {defaultDateValue.weekday}
                  </div>
                </div>

                <select
                  className="opacity selstyl"
                  style={{
                    width: "100%",
                    // marginLeft: "5px",
                    marginBottom: "5px",
                    marginTop: "5px",
                  }}
                  // name="colid"
                  // id="colid"
                  // className="opacity spacing"
                  onChange={(event) => handleChange(event.target.value)}
                >
                  {coll.map((col) => {
                    return (
                      <option key={col.id} value={col.id}>
                        {col.name}
                      </option>
                    );
                  })}
                </select>
                <TodoList
                  todolist={todolist}
                  updateTodoList={updateTodoList}
                  setaddTodoModalVisible={() => {
                    setaddTodoModalVisible(true);
                  }}
                  collageId={ColId.colid}
                  showSaveButtonForTodoUpdate={showSaveButtonForTodoUpdate}
                  setshowSaveButtonForTodoUpdate={(val) => {
                    setshowSaveButtonForTodoUpdate(val);
                  }}
                  edittodolist={edittodolist}
                />
              </div>
              {/* <div className="col-md-12 p-0 mt-4 card-design">
                            {statsComponent()}
                        </div> */}
            </div>
            {/* <div>
                        <div className="d-flex p-0 py-3 pt-4 m-0 justify-content-between">
                            <div className="col-md-10 p-0 m-0 heaidng-style">queries</div>
                            <div className="col-md-2 p-0 m-0 d-flex justify-content-between align-items-center">
                                <FaCaretLeft style={{ fontSize: 12 }} />
                                <FaCaretRight style={{ fontSize: 12 }} />
                            </div>
                        </div>
                        <div>
                            {queriesComponent()}
                        </div>
                    </div> */}
          </div>
        </div>
      </div>
    );
  }

  function documentComponent(selectedColl) {
    var essayArr = []
    var essayContent = {
      content: ""
    };
    essaysList.map((essay) => {
      // console.log("ss", essay)
      if (essay.collageId == selectedColl.collageId) {
        essayArr.push(essay)
        essayContent = {
          content: essayArr[0].content
        }
      }
    })

    return (
      <div
        key={selectedColl.collageId}
        className="col-md-4"
        style={{ height: 200, cursor: "pointer" }}
        onClick={() => {
          if (collageFilterCollageId == "") {
            setcollageFilterCollageId(selectedColl.collageId);
          } else {
            setcollageFilterCollageId("");
          }
        }}
      >
        <div
          className="p-4"
          style={{ position: "absolute", zIndex: 2, width: 150 }}
        >
          <div
            className=""
            style={{ fontSize: 10, fontWeight: 700, lineHeight: 1, height: 30 }}
          >
            {selectedColl.name}
          </div>
          <div
            className="pt-2"
            style={{
              fontSize: 6,
              lineHeight: 1,
              color: "grey",
              height: 40,
              overflow: "hidden",
            }}
          >
            {essayContent.content ? (essayContent.content.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, "")) : "No Essay Added"}
          </div>
          <div
            className="pt-2"
            style={{
              width: "100%",
              borderBottom: "1px solid #00000040",
              height: 2,
            }}
          ></div>
          <div className="d-flex align-items-end flex-column pt-2">
            <div style={{ fontSize: 6, color: "grey" }}>
              4 FEEDBACKS RECEIVED
            </div>
            <div style={{ fontSize: 6, color: "grey" }}>7 SUGGESTED EDITS</div>
          </div>
          <div className="pt-2 d-flex justify-content-end">
            <img height={10} src={RightArrow} alt="rightarrow" />
          </div>
        </div>
        <img
          style={{ position: "absolute" }}
          width={150}
          src={DocumentBg}
          alt="docimg"
        />
      </div>
    );
  }

  function updatesComponent(update) {
    return (
      <div
        className="d-flex justify-content-start align-items-center p-2"
        style={{ borderBottom: "1px solid #00000020", cursor: "pointer" }}
        onClick={() => {
          const selectedEssay = essaysList.find(
            (essay) => essay.essayId == update.updateDetails.essayId
          );
          // console.log("selectedEssay====>", selectedEssay);
          viewEssay(selectedEssay);
        }}
      >
        <img height={30} src={ProfilePicSample} alt="profilepic" />
        <div className="pl-2">
          <div style={{ fontSize: 10, fontWeight: 600 }}>{update.message.substring(0, 100).replace(regex, '')}...</div>
          <div style={{ fontSize: 8, color: "grey" }}>
            {findNotiTimeAgeFun(update.notiTimeAgo)}
          </div>
        </div>
      </div>
    );
  }

  function queriesComponent() {
    return (
      <div className="d-flex justify-content-between align-items-center p-2">
        <img height={50} src={ProfilePicSample} alt="profilepic" />
        <div className="pl-3 p-0" style={{ borderLeft: "2px solid #00000020" }}>
          <div style={{ fontSize: 8, fontWeight: 700 }}>
            harvard good concept.. need to work on the laguage and devlivery
          </div>
          <div style={{ fontSize: 8, color: "grey" }}>12 mins</div>
        </div>
      </div>
    );
  }

  function statsComponent() {
    return (
      <div className="col-md-12 p-2 d-flex justify-content-between overflow-hidden">
        <div
          style={{ position: "absolute", bottom: -20, left: -20 }}
          className="col-md-3 m-0 p-0"
        >
          <img height={80} src={StatsImg} alt="statsimg" />
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-11 d-flex py-3 justify-content-end">
          <div className="col-md-4 d-flex align-items-center flex-column">
            <div style={{ fontWeight: 800, fontSize: 20, lineHeight: 1 }}>
              1510
            </div>
            <div
              style={{
                fontWeight: 800,
                fontSize: 8,
                lineHeight: 1,
                color: "grey",
              }}
            >
              <div>SAT I</div>
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center flex-column">
            <div style={{ fontWeight: 800, fontSize: 20, lineHeight: 1 }}>
              780
            </div>
            <div
              style={{
                fontWeight: 800,
                fontSize: 8,
                lineHeight: 1,
                color: "grey",
              }}
            >
              MATH II
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center flex-column">
            <div style={{ fontWeight: 800, fontSize: 20, lineHeight: 1 }}>
              800
            </div>
            <div
              style={{
                fontWeight: 800,
                fontSize: 8,
                lineHeight: 1,
                color: "grey",
              }}
            >
              PHY I
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default TextEditorScreen;
