import React, { useEffect } from 'react'
import axios from 'axios';
import { MW_URL } from "../../../config";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Modal } from 'react-bootstrap';
import {GoPencil} from 'react-icons/go';
import {AiOutlineArrowLeft} from 'react-icons/ai';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {GrNext} from 'react-icons/gr'
import {GrPrevious} from 'react-icons/gr'
import ModalComponent from "../../../utils/modalComponent";
import { useState } from 'react';
import QuillEditor from '../quillEditor';
import { findNotifDate } from '../../../modules/parserHelper';
import LoadingAnimation from "../../../utils/loadingAnimation";
import {AiTwotoneDelete} from 'react-icons/ai'
import {BsCheckCircle} from 'react-icons/bs'
import {FaTrashAlt} from 'react-icons/fa'
import DeleteConfirmation from '../../widgets/AlertModal';
import AlertModal from '../../widgets/AlertModal';




const Essays = () => {

    const [openModal, setOpenModel] = useState(false)
    const [openModal2, setOpenModel2] = useState(false)
    const [selectedEssay, setSelectedEssay] = useState({})
    const [currentIndex, setCurrentIndex] = useState(0);
    const [tab, setTab] = useState("1")
    const [showDelete, setShowDelete] = useState(false)
    const [showFeedDelete, setShowFeedDelete] = useState(false)
    const [feedIndex, setFeedIndex] = useState(0)
    const [currentDateTime, setCurrentDateTime] = useState(new Date());



    const [isLoading, setIsLoading] = useState(true);
    const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState(
        []
      );
      const [listOfSelectedCollagesArrtemp, setlistOfSelectedCollagesArrtemp] = useState(
        []
      );
    const [essaysTemplateList, setessaysTemplateList] = useState([]);
    const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
    const [essaysList, setessaysList] = useState([]);
    const [updatesList, setupdatesList] = useState([]);
    const [essayCollId, setessayCollId] = useState("");
    const [essayReqId, setessayReqId] = useState("");
    const [editorData, setEditorData] = useState("");
    const [essayId, setessayId] = useState("");
    const [feedbackInTextarea, setfeedbackInTextarea] = useState("");
  
    const [feedbacksList, setfeedbacksList] = useState({
        feedbacks: [],
        currentPage: 1,
        feedbackPerPage: 4,
    });


    const createUniquieId = () => {
        const head = Date.now().toString(36);
        const tail = Math.random().toString(36).substr(2);
        return head + tail;
    };


    useEffect(() => {
        getAllEssays();
    }, [isUseEffectCallRequired]);

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentDateTime(new Date());
      }, 1000); // Update every second
  
      // Cleanup the interval when the component unmounts
      return () => clearInterval(interval);
    }, []);

    const formattedDateTime = currentDateTime.toLocaleString();

    const getAllEssays = () => {
        setIsLoading(true);
        var selectedCollages = [];
        var selectedCollagesIds = [];
        axios
          .post(`${MW_URL}` + "getSelectedListOfCollages", {
            email: JSON.parse(localStorage.getItem("userData")).email,
          })
          .then((getSelectedListOfCollagesresponse) => {
            if (
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
                .length > 0
            ) {
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
                (collage) => {
                  selectedCollages.push(collage);
                  selectedCollagesIds.push(collage.collageId);
                }
              );
              console.log("selectedCollages", selectedCollages)
              setlistOfSelectedCollagesArr(selectedCollages);
              setlistOfSelectedCollagesArrtemp(selectedCollages);
  
              var templateArr = [];
              var essaysRequirementIds = [];
              var essayReqIds = [];
              axios
                .post(`${MW_URL}` + "get_essay_requirement", selectedCollages)
                .then((getEssaysTemplateResponse) => {
                  // console.log(
                  //   "getEssaysTemplateResponse",
                  //   getEssaysTemplateResponse
                  // );
                  var status = "NA";
                  if (
                    getEssaysTemplateResponse &&
                    getEssaysTemplateResponse.data &&
                    getEssaysTemplateResponse.data.STATUS
                  )
                    status = getEssaysTemplateResponse.data.STATUS;
                  if (status.toUpperCase() === "SUCCESS") {
                    setessaysTemplateList(getEssaysTemplateResponse.data.data);
                    console.log(getEssaysTemplateResponse.data.data, "requirements")
                    getEssaysTemplateResponse.data.data.map(
                      (templateRes) => {
                        if (templateRes.essayRequirements.length != 0) {
                          templateRes.essayRequirements.map((esaaReq) => {
                            essayReqIds.push(esaaReq.essayReqId);
                            templateArr.push({
                              template: esaaReq.question,
                              collageId: templateRes.collageId,
                              name: templateRes.name,
                              essayReqId: esaaReq.essayReqId,
                              length: esaaReq.length,
                              requirement: esaaReq.requirement,
                              deadline: templateRes.deadline,
                              answered: essaysRequirementIds.includes(
                                esaaReq.essayReqId
                              ),
                            });
                          });
                        }
    
                      })
                    axios
                      .post(`${MW_URL}` + "getEssays", {
                        ownerId: JSON.parse(localStorage.getItem("userData")).email,
                      })
                      .then((getAllEssaysResponse) => {
                        setIsUseEffectCallRequired(false);
                        var essaysListToPush = [];
                        var essaysRequirementIds = [];
                        getAllEssaysResponse.data.map((essay) => {
                          if (
                            essay.ownerId ==
                            JSON.parse(localStorage.getItem("userData")).email &&
                            selectedCollagesIds.includes(essay.collageId) &&
                            essayReqIds.includes(essay.essayReqId)
                          ) {
                            essaysListToPush.push({
                              essayId: essay.essayId,
                              content: essay.content,
                              newEditorContentEssay: essay.newEditorcontentEssay,
                              typeOfDocument: essay.typeOfDocument,
                              collageId: essay.collageId,
                              ownerId: essay.ownerId,
                              feedbacks: essay.feedbacks,
                              essayReqId: essay.essayReqId,
                              updatedDate: essay.updatedDate,
                              isApproved: essay.isApproved,
                            });
                            essaysRequirementIds.push(essay.essayReqId);
                          }
                        });
                        setessaysList(essaysListToPush);
                        console.log(essaysListToPush, "ddf")
    
                        // const params = new URLSearchParams(
                        //   window.location.search
                        // );
                        // if (params.get("id")) {
                        //   if (params.get("id") == "recent") {
                        //     viewEssay(essaysListToPush[0]);
                        //   } else {
                        //     const selectedEssay = essaysListToPush.find(
                        //       (essay) => essay.essayId == params.get("id")
                        //     );
                        //     // console.log("selectedEssay====>", selectedEssay);
                        //     viewEssay(selectedEssay);
                        //   }
                        // } else {
                        //   console.log("No params");
                        // }
    
                        axios
                          .post(`${MW_URL}` + "getUpdates", {
                            recipientId: JSON.parse(
                              localStorage.getItem("userData")
                            ).email,
                            senderId: false,
                          })
                          .then((getUpdatesResponse) => {
    
                            setupdatesList(getUpdatesResponse.data);
                            setessaysTemplateList(
                              templateArr.map((reqEssay) => {
                                return {
                                  ...reqEssay,
                                  answered: essaysRequirementIds.includes(
                                    reqEssay.essayReqId
                                  ),
                                };
                              })
                            );
                            setIsLoading(false);
                          })
                          .catch((err) => {
                            console.log("err >> ", err);
                          });
                      })
                      .catch((err) => {
                        console.log("err >> ", err);
                      });
                  } else {
                    setIsLoading(false);
                  }
                })
                .catch((err) => {
                  console.log("err >> ", err);
                });
            } else {
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      };  

    const addEssayToDb = () => {
        let essayCollege = listOfSelectedCollagesArr.find((col) => { return col.collageId == selectedEssay?.collageId })
          var dataToSend = {
            essayId: createUniquieId(),
            content: editorData,
            newEditorContent: editorData,
            typeOfDocument: "essay",
            collageId: selectedEssay.collageId,
            ownerId: JSON.parse(localStorage.getItem("userData")).email,
            feedbacks: feedbacksList?.feedbacks,
            essayReqId: selectedEssay?.essayReqId,
            updateId: createUniquieId(),
            recipientId: JSON.parse(localStorage.getItem("userData")).counsellorId,
            senderId: JSON.parse(localStorage.getItem("userData")).email,
            message:
              JSON.parse(localStorage.getItem("userData")).username +
              " added " + essayCollege?.name + "'s essay",
          };
          console.log(dataToSend, "data new")
          setIsLoading(true);
          axios
            .post(`${MW_URL}` + "addEssay", dataToSend)
            .then((response) => {
              var status = "NA";
              if (response && response.data && response.data.STATUS)
                status = response.data.STATUS;
              if (status.toUpperCase() === "SUCCESS") {
                // hidefeedbackPage();
                // resetEssayContent();
                setIsUseEffectCallRequired(true)
                setOpenModel2(false)
              } else {
              }
            })
            .catch((err) => {
              console.log("err >> ", err);
            });

      };
    
    const editEssayInDb = () => {
        let essayCollege = listOfSelectedCollagesArr.find((col) => { return col.collageId == selectedEssay.collageId })
        console.log(essayCollege, selectedEssay.collageId, "1212")

        if (editorData === "" && !selectedEssay.content) {
          console.log(editorData, "null")
          setShowDelete(true)
        } else {
          console.log(editorData, "not null")
            var dataToSend = {
              essayId: selectedEssay?.essayId,
              content: editorData || selectedEssay.content,
              newEditorContent: editorData,
              typeOfDocument: "essay",
              collageId: selectedEssay.collageId,
              ownerId: JSON.parse(localStorage.getItem("userData")).email,
              feedbacks: feedbacksList.feedbacks,
              essayReqId: selectedEssay.essayReqId,
              updateId: createUniquieId(),
              recipientId: JSON.parse(localStorage.getItem("userData")).counsellorId,
              senderId: JSON.parse(localStorage.getItem("userData")).email,
              message:
                JSON.parse(localStorage.getItem("userData")).username +
                " edited " + essayCollege?.name + "'s essay",
            };
            setIsLoading(true);
            console.log("1215", dataToSend)
    
            axios
              .post(`${MW_URL}` + "editEssay", dataToSend)
              .then((response) => {
                var status = "NA";
                if (response && response.data && response.data.STATUS)
                  status = response.data.STATUS;
                if (status.toUpperCase() === "SUCCESS") {
                  // hidefeedbackPage();
                  // resetEssayContent();
                  setIsUseEffectCallRequired(true)
                  setOpenModel(false)
    
                } else {
                }
              })
              .catch((err) => {
                console.log("err >> ", err);
              });
        }

        // ***************************

        // editorData.save().then((outputData) => {
        //   outputData.blocks.map((es) => {
        //     essayCont.push(es.data.text);
        //   })
        //   console.log("clicked---12")
        //   var esContent = essayCont.toString();
        //   var dataToSend = {
        //     essayId: essayId,
        //     content: esContent,
        //     newEditorContent: outputData,
        //     typeOfDocument: "essay",
        //     collageId: essayCollId,
        //     ownerId: JSON.parse(localStorage.getItem("userData")).email,
        //     feedbacks: feedbacksList.feedbacks,
        //     essayReqId: essayReqId,
        //     updateId: createUniquieId(),
        //     recipientId: JSON.parse(localStorage.getItem("userData")).counsellorId,
        //     senderId: JSON.parse(localStorage.getItem("userData")).email,
        //     message:
        //       JSON.parse(localStorage.getItem("userData")).username +
        //       " edited " + essayCollege.name + "'s essay",
        //   };
        //   setIsLoading(true);
        //   axios
        //     .post(`${MW_URL}` + "editEssay", dataToSend)
        //     .then((response) => {
        //       var status = "NA";
        //       if (response && response.data && response.data.STATUS)
        //         status = response.data.STATUS;
        //       if (status.toUpperCase() === "SUCCESS") {
        //         // hidefeedbackPage();
        //         // resetEssayContent();
        //         setIsLoading(false);
        //       } else {
        //       }
        //     })
        //     .catch((err) => {
        //       console.log("err >> ", err);
        //     });
        // }).catch((error) => {
        //   console.log('Saving failed: ', error)
        // });
      };

    const deleteEssayInDb = (essayId) => {
      console.log(essayId, "essayId")
      axios
      .post(`${MW_URL}` + "deleteEssay", {essayId: essayId})
      .then((response) => {
        console.log(response,"res----")
        var status = "NA";
        if (response && response.data && response.data.STATUS)
          status = response.data.STATUS;
        if (status.toUpperCase() === "SUCCESS") {
          console.log(response, "success---")
          setIsUseEffectCallRequired(true)
          setOpenModel(false)
          setShowDelete(false)
        } else {
        }
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
    }
    
    const handlePrev = () => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + listOfSelectedCollagesArr.length) % listOfSelectedCollagesArr.length);
    };
  
    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % listOfSelectedCollagesArr.length);
    };
    
    const handleEssayEditClick = (essay) => {
        setOpenModel(true)
        setSelectedEssay(essay)
    }

    const handleEssayAddClick = (essay) => {
      setOpenModel2(true)
      setSelectedEssay(essay)
    }

    const addFeedbackBtnClick = () => {
       if(feedbackInTextarea) {
        selectedEssay?.feedbacks.push({
           feedback: feedbackInTextarea,
           usertype: JSON.parse(localStorage.getItem("userData")).userType,
           userName: JSON.parse(localStorage.getItem("userData")).username,
           updatedDate: formattedDateTime
        })
          
        var dataToSend = {
          essayId: selectedEssay.essayId,
          feedbacks: selectedEssay?.feedbacks,
          updateId: createUniquieId(),
          recipientId: JSON.parse(localStorage.getItem("userData")).counsellorId,
          senderId: JSON.parse(localStorage.getItem("userData")).email,
          message:
            JSON.parse(localStorage.getItem("userData")).username +
            " added a feedback",
        };
        setIsLoading(true);
        axios
          .post(`${MW_URL}` + "updatefeedbacks", dataToSend)
          .then((response) => {
            var status = "NA";
            if (response && response.data && response.data.STATUS)
              status = response.data.STATUS;
            if (status.toUpperCase() === "SUCCESS") {
              setIsLoading(false);
              setfeedbackInTextarea("");
              setfeedbacksList({
                ...feedbacksList,
                feedbacks: [
                  ...feedbacksList.feedbacks,
                  {
                    feedback: feedbackInTextarea,
                    usertype: JSON.parse(localStorage.getItem("userData"))
                      .userType,
                  },
                ],
                currentPage: Math.ceil(
                  (feedbacksList.feedbacks.length + 1) /
                  feedbacksList.feedbackPerPage
                ),
              });
            } else {
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      } else {
        console.log("no feedbackInTextarea")
      }
    }

    const handleFeedBackDelete = (i) => {
      setFeedIndex(i)
      setShowFeedDelete(true)
    }

    const feedBackDeleteBtnClick = () => {
      console.log(selectedEssay?.feedbacks, feedIndex , "feed delete clicked")
      selectedEssay?.feedbacks.splice(feedIndex, 1)
      var dataToSend = {
        essayId: selectedEssay?.essayId,
        feedbacks: selectedEssay?.feedbacks,
        updateId: createUniquieId(),
        recipientId: JSON.parse(localStorage.getItem("userData")).counsellorId,
        senderId: JSON.parse(localStorage.getItem("userData")).email,
        message:
          JSON.parse(localStorage.getItem("userData")).username +
          " deleted a feedback",
      };
      console.log(dataToSend, "data")
      setIsLoading(true);
      axios
        .post(`${MW_URL}` + "updatefeedbacks", dataToSend)
        .then((response) => {
          var status = "NA";
          if (response && response.data && response.data.STATUS)
            status = response.data.STATUS;
          if (status.toUpperCase() === "SUCCESS") {
            setIsLoading(false);
            setShowFeedDelete(false)
            // setfeedbacksList({
            //   ...feedbacksList,
            //   feedbacks: feedbacks,
            // });
            console.log("feed updated success")
          } else {
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    }

  return (
    <div className='essayBg'>
        {isLoading ? <LoadingAnimation isVisible={true} /> : null}
         <Row>
            <Col sm={8} className='p-2 pl-4'>
                <div className='my-2'>
                    <AiOutlineArrowLeft/>
                </div>

                <div>
                  <div className='d-flex'>
                    <div>
                      <button className='handleNextBtn m-2' onClick={() => handlePrev()}><GrPrevious /></button>
                    </div>
                    <p className='essayHeadText1'>{listOfSelectedCollagesArr[currentIndex]?.name}</p>
                    <div>
                      <button className='handleNextBtn m-2' onClick={() => handleNext()}><GrNext/></button>
                    </div>
                  </div>
                  <p className='essayNormalText1'>{listOfSelectedCollagesArr[currentIndex]?.description}</p>
                </div>
                {/* <div>
                  <button className='handleNextBtn' onClick={() => handlePrev()}><GrPrevious /></button>
                  <button className='handleNextBtn mx-5' onClick={() => handleNext()}><GrNext/></button>
                </div> */}
                
                <div className='d-flex justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <div>
                            <p className='essayHeadText2 mt-3'>Essays</p>
                        </div>
                        <div className='mx-3'>
                            <button className='essayBtn1'>Share</button>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <button className='essayBtn2'>Upload</button>
                    </div>
                </div>

                <div className='colTab mb-3'>
                    <button onClick={() => setTab("1")} className={tab === "1" ? 'colTabBtnSel': 'colTabBtn'}>Answered</button>
                    <button onClick={() => setTab("2")} className={tab === "2" ? 'colTabBtnSel': 'colTabBtn'}>Requirements</button>
                </div>

                <div className='essayListWrap'>
                  {tab === "1" && (
                    <div>
                      {essaysList.map((obj, index) => {
                        const isCurrentCollage = listOfSelectedCollagesArr[currentIndex].collageId === obj.collageId;
                        return (
                          <div key={index} className={`essaySec p-2 my-3 mx-2 ${isCurrentCollage ? 'active' : ''}`}>
                            {isCurrentCollage && (
                              <div className='d-flex justify-content-between'>
                                <p className='essayHeadText3'>{listOfSelectedCollagesArr[currentIndex]?.name}</p>
                                <div onClick={() => handleEssayEditClick(obj)} className='p-2'>
                                  <GoPencil/>
                                </div>
                              </div>
                            )}
                            {isCurrentCollage && (
                              <p className='essayNormalText2 '>
                                {obj.content.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '')}
                              </p>
                            )}
                            {isCurrentCollage && (
                              <div className='d-flex justify-content-between'>
                                <p className='queryLightText'>{findNotifDate(obj.updatedDate)}</p>
                                {obj?.isApproved && (<p className='approveBtn px-2'>approved <BsCheckCircle/> </p>)}
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {tab === "2" && (
                    <div>
                        {essaysTemplateList.map((obj, index) => {
                          if(obj.collageId === listOfSelectedCollagesArr[currentIndex].collageId) {
                              return (
                                <div className='essaySec p-2 my-3 mx-2'>
                                    <div className='d-flex justify-content-between'>
                                        <p className='essayHeadText3'>{listOfSelectedCollagesArr[currentIndex]?.name}</p>
                                        {!obj?.answered && (
                                            <div onClick={() => handleEssayAddClick(obj)} ><GoPencil/></div>
                                        )}
                                    </div>
                                    <p className='essayNormalText2 pb-1'>{obj.template}</p>
                                </div>
                              )
                          }
                        })}
                    </div>
                  )}
                  

{/* 
                    <div className='essaySec px-2 m-1'>
                        <div className='d-flex justify-content-between'>
                            <p className='essayHeadText3'>Section 2</p>
                            <div><GoPencil/></div>
                        </div>
                        <p className='essayNormalText2 pb-1'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s</p>
                    </div> */}


                </div>

                <button className='essayBtn1 mx-1'>Add Section</button>

            </Col>
            <Col sm={4} className='essayRightSec'>
                <p className='essayHeadText2 mt-3 pt-3'>Collaboration & Notes</p>
                {/* <div className='addNotesSec'>
                    <p className='essayLightText1'>Add notes</p>
                </div> */}
                <div>
                    <QuillEditor placeholder={'Add notes'} customClass={'addNotesSec'} />
                </div>
            </Col>
        </Row>

        <Modal  show={openModal} size='xl' onHide={()=> setOpenModel(false)} >
              <div className='d-flex justify-content-between '>
                <p className='essayHeadText4 m-3 p-2 '>Edit Essay </p>
                <p className='essayHeadText4 my-3 py-2' style={{"marginLeft":"30%"}}>Feedbacks </p>
                <div className='m-2'>
                    <AiOutlineCloseCircle onClick={() => setOpenModel(false)} className='essayCloseBtn'/>
                </div>
              </div>
              <div className='row'>
                    <div className='col-7'>
                      <div className='essayModal'>
                        {/* <div className='essayModalContent'>
                          <p className='essayNormalText1'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s</p>
                        </div> */}

                          {selectedEssay?.isApproved ? (
                            <div>
                              <QuillEditor readOnly={true} defaultContent={selectedEssay.content} customClass={'essayModalContent'} />
                              <div className='d-flex justify-content-end mx-2'>
                                 <p className='approveBtn px-2'>approved <BsCheckCircle/> </p>
                              </div>
                            </div>
                          ) : (
                            <div >
                              <QuillEditor editorData={editorData} setEditorData={setEditorData} defaultContent={selectedEssay.content} customClass={'essayModalContent'} />
                              <div className='modalSaveBtn mr-3'>
                                <button onClick={() => editEssayInDb()} className='essayBtn2 mx-3'>Save</button>
                                <button onClick={() => setShowDelete(true)} className='essayRejectBtn1'>Delete</button>
                              </div>
                           </div>
                          )}

                      </div>
                    </div>
                    <div className='col'>
                      {/* <p>feedback</p> */}
                      <div className='feedListWrap'>
                      {selectedEssay?.feedbacks?.map((obj, index) => (
                          <div className='feedbackWrap p-2 my-2'>
                            <div>
                                <p className='essayNormalText1 m-0'>{obj.feedback}</p>
                                <div className='d-flex'>
                                  <p className='essayNormalText2 m-0'>{obj.userName} -</p>
                                  <p className='queryLightText2 m-0 mx-2'>{obj.updatedDate}</p>
                                </div>
                            </div>
                            <div className='px-2'>
                              <FaTrashAlt onClick={() => handleFeedBackDelete(index)} fill='red' fontSize={18} />
                            </div>
                          </div>
                        // if(obj.usertype === "counsellor"){
                        //   return(
                        //     <div className='d-flex'>
                        //       <AiTwotoneDelete onClick={() => feedBackDeleteBtnClick(index)} className='m-1' />
                        //       <p className='text-danger essayNormalText1'>{obj.feedback}</p>
                        //     </div>)
                        // } else {
                        //   return(
                        //     <div className='d-flex'>
                        //       <AiTwotoneDelete onClick={() => feedBackDeleteBtnClick(index)} className='m-1' />
                        //       <p className='essayNormalText1'>{obj.feedback}</p>
                        //     </div>)
                        // }
                      ))}

                      </div>
                    
                      <div className='addFeedBackWrap'>
                          <textarea 
                            className='essayNormalText1 queryTextInbox'
                            value={feedbackInTextarea} 
                            onChange={(event) => {
                              setfeedbackInTextarea(event.target.value);
                            }}
                            placeholder='Type your feedback here'></textarea>

                            <button className='essayBtn1' onClick={() => addFeedbackBtnClick()}>submit</button>
                      </div>
                    
                    
                    </div>
              </div>

          
        </Modal>
        
        <Modal  show={openModal2} size='xl' onHide={()=> setOpenModel2(false)} >
              <div className='d-flex justify-content-between '>
                <p className='essayHeadText4 m-3 p-2 '>Add Essay </p>
                <div className='m-2'>
                    <AiOutlineCloseCircle onClick={() => setOpenModel2(false)} className='essayCloseBtn'/>
                </div>
              </div>
              <div>
                <div className='essayModal'>
                  {/* <div className='essayModalContent'>
                    <p className='essayNormalText1'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s</p>
                  </div> */}
                      <div >
                        <QuillEditor placeholder="Enter essay here" editorData={editorData} setEditorData={setEditorData} defaultContent={selectedEssay.content} customClass={'essayModalContent'} />
                      </div>

                  <div className='modalSaveBtn mr-3'>
                    <button onClick={() => addEssayToDb()} className='essayBtn2'>Save</button>
                  </div>



                </div>
              </div>
        </Modal>

        <AlertModal
          show={showDelete}
          onHide={() => setShowDelete(false)}
          title='Alert!'
          message='Are you sure you want to delete this item?'
          onCancel={() => setShowDelete(false)}
          onConfirm={() => deleteEssayInDb(selectedEssay.essayId)}
        />

        <AlertModal
          show={showFeedDelete}
          onHide={() => setShowFeedDelete(false)}
          title='Alert!'
          message='Are you sure you want to delete this feedback?'
          onCancel={() => setShowFeedDelete(false)}
          onConfirm={() => feedBackDeleteBtnClick()}
        />


    </div>
  )
}

export default Essays;