import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from 'axios';
import { MW_URL } from "../../../../config";
import LoadingAnimation from "../../../../utils/loadingAnimation";



const DeadlineSec = ({
    essaysTemplateList,
    listOfSelectedCollagesArr,
    selDeadlineCollageId,
    setSelectedDeadlineCollageId
    }) => {


    const [isLoading, setIsLoading] = useState(false);
    const [showFullContent, setShowFullContent] = useState(false);

   

    const handleCollageChange = (e) => {
        setSelectedDeadlineCollageId(e.target.value)
    }

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="dashLightText">
                {isReadMore ? text.slice(0, 20) : text}
                <span
                    onClick={toggleReadMore}
                    className="read-or-hide"
                    style={{ color: "green" }}
                >
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };
     
    const Content = ({Data}) => {
        console.log(Data, "data")
        return (
            <div className="container">
                {/* <h2> */}
                    <ReadMore>
                        {Data}
                    </ReadMore>
                {/* </h2> */}
            </div>
        );
    };
    


  return (
    <div className='dashStats2 pr-3' >
        {isLoading ? <LoadingAnimation isVisible={true} /> : null}
        <div className='row my-1'>
            <div className='col-6'>
                <h3 className='dashHead3'>Deadline</h3>
            </div>
            <div className='col-6 d-flex justify-content-end '>
                <select onChange={handleCollageChange} className="deadlineSelect">
                    {listOfSelectedCollagesArr < 1 && (<option>No Collages Selected</option>)}
                    { listOfSelectedCollagesArr?.map((obj, index) => (
                        <option key={index} defaultValue={obj.collageId} value={obj.collageId} >{obj.name}</option>
                    ))}
                </select>

  
            </div>
        </div>

        {essaysTemplateList.length > 0 ? essaysTemplateList?.map((obj, index) => {
            if(obj.collageId === selDeadlineCollageId){
                console.log(obj, "obj")
                return (
                    <div key={index} className='dashTodoCollage'>
                        <div className='row '>
                            <div className='col-8'>
                                <div className='dashNormalText2'>{obj.name} </div>
                                {/* <div className='dashLightText'>{obj.template}</div>  */}
                                <Content Data={obj.template}/>
                            </div>
                            <div className='col-4'>
                                <div className='dashDeadlineText '>{obj.deadline}</div>
                            </div>
                        </div>
                    </div>
                )
            }
          
        }) : <p className="dashNormalText2 noDataText">No deadline</p>}

        
{/* 
        <div className='dashTodoCollage'>
            <div className='row '>
                <div className='col-8'>
                    <div className='dashNormalText'>Title </div>
                    <div className='dashLightText'>Lorem Ipsum is simply dummy text of the printing and…</div> 
                </div>
                <div className='col-4 '>
                    <div className='dashDeadlineText'>08/02/2022</div>
                </div>
            </div>
        </div> */}

    </div>
    
  )
}

export default DeadlineSec