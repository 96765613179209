import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from 'axios';
import { MW_URL } from "../../../config";

import ProfileSection from './portal/profileSection'
import Stats from './portal/stats'
import Todo from './portal/todo'
import Updates from './portal/updates'
import CalenderSec from './portal/calenderSec'
import DeadlineSec from './portal/deadlineSec'


const Dashboard = () => {

    const [selCollageId, setSelectedCollageId] = useState()
    const [selCollage, setSelCollage] = useState("")
    const [selDeadlineCollageId, setSelectedDeadlineCollageId] = useState()
    const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState(
        []
    );
    const [essaysTemplateList, setessaysTemplateList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [essaysList, setessaysList] = useState([]);



    useEffect(() => {
        getSelectedListOfCollages(); 
    }, []);


    const getSelectedListOfCollages = () => {
        setIsLoading(true)
        var selectedCollages = []
        var selectedCollagesIds = [];
        axios.post(`${MW_URL}` + "getSelectedListOfCollages", {
            email: JSON.parse(localStorage.getItem("userData")).email,
        }).then((getSelectedListOfCollagesresponse) => {
            if (
                getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
                  .length > 0
              ) {
                getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
                  (collage) => {
                    selectedCollages.push(collage);
                    selectedCollagesIds.push(collage.collageId);
                  }
                );
                setlistOfSelectedCollagesArr(selectedCollages);
                setSelectedCollageId(selectedCollages[0].collageId)
                setSelCollage(selectedCollages[0].name)
                setSelectedDeadlineCollageId(selectedCollages[0].collageId)

                var templateArr = [];
                var essaysRequirementIds = [];
                var essayReqIds = [];

                axios
                    .post(`${MW_URL}` + "get_essay_requirement", selectedCollages)
                    .then((getEssaysTemplateResponse) => {
                        var status = "NA";
                        if(
                            getEssaysTemplateResponse &&
                            getEssaysTemplateResponse.data &&
                            getEssaysTemplateResponse.data.STATUS
                        ) 
                            status = getEssaysTemplateResponse.data.STATUS;
                        if (status.toLocaleUpperCase() === "SUCCESS") {
                            // setessaysTemplateList(getEssaysTemplateResponse.data.data);
                            console.log(getEssaysTemplateResponse.data.data, "requirements")
                            getEssaysTemplateResponse.data.data.map(
                            (templateRes) => {
                                if (templateRes.essayRequirements.length != 0) {
                                templateRes.essayRequirements.map((esaaReq) => {
                                    essayReqIds.push(esaaReq.essayReqId);
                                    templateArr.push({
                                    template: esaaReq.question,
                                    collageId: templateRes.collageId,
                                    name: templateRes.name,
                                    deadline: templateRes.deadline,
                                    essayReqId: esaaReq.essayReqId,
                                    length: esaaReq.length,
                                    requirement: esaaReq.requirement,
                                    answered: essaysRequirementIds.includes(
                                        esaaReq.essayReqId
                                    ),
                                    });
                                });
                                }
    
                            })

                            axios
                                .post(`${MW_URL}` + "getEssays", {
                                    ownerId: JSON.parse(localStorage.getItem("userData")).email,
                                })
                                .then((getAllEssaysResponse) => {
                                    // setIsUseEffectCallRequired(false);
                                    var essaysListToPush = [];
                                    var essaysRequirementIds = [];
                                    getAllEssaysResponse.data.map((essay) => {
                                    if (
                                        essay.ownerId ==
                                        JSON.parse(localStorage.getItem("userData")).email &&
                                        selectedCollagesIds.includes(essay.collageId) &&
                                        essayReqIds.includes(essay.essayReqId)
                                    ) {
                                        essaysListToPush.push({
                                        essayId: essay.essayId,
                                        content: essay.content,
                                        newEditorContentEssay: essay.newEditorcontentEssay,
                                        typeOfDocument: essay.typeOfDocument,
                                        collageId: essay.collageId,
                                        ownerId: essay.ownerId,
                                        feedbacks: essay.feedbacks,
                                        essayReqId: essay.essayReqId,
                                        updatedDate: essay.updatedDate,
                                        isApproved: essay.isApproved,
                                        });
                                        essaysRequirementIds.push(essay.essayReqId);
                                    }
                                    });
                                    setessaysList(essaysListToPush);
                                    console.log(essaysListToPush, "ddf")
                
                                    // const params = new URLSearchParams(
                                    //   window.location.search
                                    // );
                                    // if (params.get("id")) {
                                    //   if (params.get("id") == "recent") {
                                    //     viewEssay(essaysListToPush[0]);
                                    //   } else {
                                    //     const selectedEssay = essaysListToPush.find(
                                    //       (essay) => essay.essayId == params.get("id")
                                    //     );
                                    //     // console.log("selectedEssay====>", selectedEssay);
                                    //     viewEssay(selectedEssay);
                                    //   }
                                    // } else {
                                    //   console.log("No params");
                                    // }
            
                                    axios
                                    .post(`${MW_URL}` + "getUpdates", {
                                        recipientId: JSON.parse(
                                        localStorage.getItem("userData")
                                        ).email,
                                        senderId: false,
                                    })
                                    .then((getUpdatesResponse) => {
                
                                        // setupdatesList(getUpdatesResponse.data);
                                        setessaysTemplateList(
                                        templateArr.map((reqEssay) => {
                                            return {
                                            ...reqEssay,
                                            answered: essaysRequirementIds.includes(
                                                reqEssay.essayReqId
                                            ),
                                            };
                                        })
                                        );
                                        setIsLoading(false);
                                    })
                                    .catch((err) => {
                                        console.log("err >> ", err);
                                    });
                                })
                                .catch((err) => {
                                    console.log("err >> ", err);
                            });
                        } else {
                            setIsLoading(false);
                          }
                        })
                        .catch((err) => {
                          console.log("err >> ", err);
                        });
                    } else {
                      setIsLoading(false);
                    }
                  })
                  .catch((err) => {
                    console.log("err >> ", err);
                  });
        };  


  return (
    <div className='row dashborad'>
        <div className='col-3 p-0 '>
            <h3 className='dashTitle m-3'>Welcome</h3>
            <ProfileSection />
        </div>
        <div className='col-6 p-1 pl-3'>
            <div className='m-2'>
                <Stats />
            </div>
            <div className='m-2'>
                <Todo 
                    listOfSelectedCollagesArr={listOfSelectedCollagesArr} 
                    setSelectedCollageId={setSelectedCollageId} 
                    selCollageId={selCollageId}
                    setSelCollage={setSelCollage}
                    selCollage={selCollage}
                />
            </div>
            <div className='m-2 '>
                <Updates />
            </div>

        </div>
        <div className='col-3 p-0 my-2'>
            <div className=''>
                <CalenderSec />
            </div>
            <div className='p-1 my-2'>
                <DeadlineSec 
                    essaysTemplateList={essaysTemplateList} 
                    listOfSelectedCollagesArr={listOfSelectedCollagesArr}
                    setSelectedDeadlineCollageId={setSelectedDeadlineCollageId}
                    selDeadlineCollageId={selDeadlineCollageId}

                />
            </div>
        </div>
    </div>
  )
}

export default Dashboard