import { configureStore } from '@reduxjs/toolkit';
import generalReducer from './reducers/generalReducer';
import userDetailsReducer from './reducers/userDetailsReducer';
import portfolioDetailsReducer from './reducers/portfolioDetailsReducer';

export const store = configureStore({
  reducer: {
    general: generalReducer,
    userDetails: userDetailsReducer,
    portfolioDetails: portfolioDetailsReducer
  }
});

