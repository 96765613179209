import React, { useEffect, useState, useRef } from "react";
import LoadingAnimation from '../../utils/loadingAnimation';
import ModalComponent from "../../utils/modalComponent";
import { Input } from 'reactstrap';
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import moment from 'moment';
import axios from 'axios';
import { MW_URL } from "../../config";
import { FaCaretDown, FaTrash, FaTimes } from "react-icons/fa";
import GoogleMeet from "../../imageAssets/googleMeet.png";
import ProfilePicSample from "../../imageAssets/profilepicsample.png";
//import TimePicker from 'react-time-picker';
//import MomentUtils from '@date-io/moment';
// import {
//     DatePicker,
//     TimePicker,
//     DateTimePicker,
//     MuiPickersUtilsProvider,
// } from '@material-ui/pickers';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Stack from '@mui/material/Stack';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import MobileTimePicker from '@mui/lab/MobileTimePicker';
import DesktopTimePicker from '@mui/lab/DesktopTimePicker';
import MomentUtils from '@date-io/moment';
import AdapterMoment from '@mui/lab/AdapterMoment';

const AppoinmentsScreen = () => {

    const createUniquieId = () => {
        const head = Date.now().toString(36);
        const tail = Math.random().toString(36).substr(2);
        return head + tail;
    }
    let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today
    var currentTime = moment().format().split('+')[0] //2021-12-16T11:45:25
    var today = new Date();
    today = today.toLocaleDateString("en-US")
    today = today.split('/');
    const defaultValue = {
        year: parseInt(today[2]),
        month: parseInt(today[0]),
        day: parseInt(today[1])
    };
    const calendarRef = useRef();
    const [height, setHeight] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [currentEvents, setCurrentEvents] = useState([]);
    const [selectedDay, setSelectedDay] = useState(defaultValue);
    const [eventEditModalVisible, seteventEditModalVisible] = useState(false);
    const [eventAddModalVisible, setEventAddModalVisible] = useState(false);
    const [currentClickInfo, setCurrentClickInfo] = useState({});
    const [eventName, setEventName] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [eventGuestTxt, seteventGuestTxt] = useState("");
    const [eventSelectedInfo, setEventSelectedInfo] = useState({});
    const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
    const [dropdownDiv, setdropdownDiv] = useState(false);
    const [calanderViewType, setcalanderViewType] = useState("Day");
    const [googleMeetLinkAdded, setgoogleMeetLinkAdded] = useState(true);
    const [googleMeetId, setgoogleMeetId] = useState("");
    const [listOfUsers, setlistOfUsers] = useState([]);
    const [showGuestSuggestionsDiv, setshowGuestSuggestionsDiv] = useState(false);
    const [guestSuggestionList, setguestSuggestionList] = useState([]);
    const [guestSelectedList, setguestSelectedList] = useState([]);

    const [selectedStartTime, handleStartTimeChange] = useState(moment().format());
    const [selectedEndTime, handleEndTimeChange] = useState();

    const [value, setValue] = React.useState(new Date('2018-01-01T00:00:00.000Z'));

    useEffect(() => {
        var windowHeight = window.innerHeight;
        setHeight(windowHeight + "px");
        getlistofusersFromDb();
    }, [isUseEffectCallRequired]);

    const getlistofusersFromDb = () => {

        axios.post(`${MW_URL}` + "users", {})
            .then(getUsersResponse => {
                if (getUsersResponse && getUsersResponse.data && getUsersResponse.data[0]) {
                    var userList = []
                    for (var i = 0; i < getUsersResponse.data.length; i++) {
                        var currentObj = getUsersResponse.data[i];
                        if (currentObj.counsellorId === JSON.parse(localStorage.getItem("userData")).email) {
                            userList.push({ "key": currentObj.email, "username": currentObj.username, "email": currentObj.email, "userType": currentObj.userType })
                        }
                    }
                    console.log("userList", userList);
                    setlistOfUsers(userList);
                    setguestSuggestionList(userList);
                }
                getAllEventsFun();
            })
            .catch(err => {
                console.log("err >> ", err);
            })

    }

    const handleDateSelect = (selectInfo) => {
        setEventSelectedInfo(selectInfo);
        setgoogleMeetId(createUniquieId());
        console.log("selectInfo.startStr", selectInfo.startStr)
        handleStartTimeChange(selectInfo.startStr);
        handleEndTimeChange(selectInfo.endStr);
        setEventAddModalVisible(true)
    }

    const handleEventClick = (clickInfo) => {
        setCurrentClickInfo(clickInfo)
        seteventEditModalVisible(true)
    }

    const handleEvents = (events) => {
        setCurrentEvents(events)
    }

    const getAllEventsFun = () => {
        console.log("getAllEventsFun");
        axios.post(`${MW_URL}` + "getAllEvents", {})
            .then(getAllEventsResponse => {
                setIsLoading(false);
                setIsUseEffectCallRequired(false);
                var eventsList = [];
                getAllEventsResponse.data.map((event) => {
                    if (event.eventGuests.indexOf(JSON.parse(localStorage.getItem("userData")).email) != -1) {
                        eventsList.push({
                            id: event.eventId,
                            title: event.title,
                            start: event.start,
                            end: event.end,
                            googleMeetId: event.googleMeetId,
                            description: event.description,
                            eventGuests: event.eventGuests
                        })

                    }
                })
                setCurrentEvents(eventsList);
            })
            .catch(err => {
                console.log("err >> ", err);
            })
    }

    const eventEditModalHandler = (dataObject) => {
        if (dataObject.type === "cancel") {
            seteventEditModalVisible(false);
            setEventName("");
            setEventDescription("");
            seteventGuestTxt("");
            setguestSelectedList([]);
        } else if (dataObject.type === "submit") {
            setTimeout(() => {
                console.log("currentClickInfo", JSON.stringify(currentClickInfo.event));
                var googleMeetLinkAddedToSend = googleMeetLinkAdded ? googleMeetId : googleMeetLinkAdded;
                setEventName("");
                setEventDescription("");
                seteventGuestTxt("");
                var selectedGuestListToSend = []
                selectedGuestListToSend.push(JSON.parse(localStorage.getItem("userData")).email);
                if (guestSelectedList.length > 0) {
                    guestSelectedList.map((guest) => {
                        console.log("guest", guest);
                        selectedGuestListToSend.push(guest.email);
                    });
                }
                var dataToSend = {
                    "id": currentClickInfo.event.id,
                    "title": eventName,
                    "description": eventDescription,
                    "start": selectedStartTime,
                    "end": selectedEndTime,
                    "allDay": "false",
                    "googleMeetId": googleMeetLinkAddedToSend,
                    "eventHostId": JSON.parse(localStorage.getItem("userData")).email,
                    "eventGuests": selectedGuestListToSend
                }
                setgoogleMeetLinkAdded(true);
                console.log("dataToSend", dataToSend);
                setIsLoading(true)
                axios.post(`${MW_URL}` + "editEvent", dataToSend)
                    .then(response => {
                        var status = "NA";
                        if (response && response.data && response.data.STATUS) status = response.data.STATUS
                        console.log("status", status)
                        console.log("response", JSON.stringify(response));
                        if (status.toUpperCase() === "SUCCESS") {
                            //currentClickInfo.event.remove()
                            getAllEventsFun();
                            seteventEditModalVisible(false);
                            setguestSelectedList([]);
                        } else {
                            //setIsLoading(false)
                            seteventEditModalVisible(false);
                            setguestSelectedList([]);
                        }
                    })
                    .catch(err => {
                        console.log("err >> ", err);
                    })
            }, 500)
        }
    }

    const deleteEventHandler = () => {

        setEventName("");
        setEventDescription("");
        seteventGuestTxt("");
        setgoogleMeetLinkAdded(true);

        var dataToSend = {
            "id": currentClickInfo.event.id,
        }
        axios.post(`${MW_URL}` + "deleteEvent", dataToSend)
            .then(response => {
                var status = "NA";
                if (response && response.data && response.data.STATUS) status = response.data.STATUS
                console.log("status", status)
                console.log("response", JSON.stringify(response));
                if (status.toUpperCase() === "SUCCESS") {
                    currentClickInfo.event.remove()
                    getAllEventsFun();
                    seteventEditModalVisible(false)
                } else {
                    //setIsLoading(false)
                    seteventEditModalVisible(false)
                }
            })
            .catch(err => {
                console.log("err >> ", err);
            })
    }
    const eventAddModalHandler = (dataObject) => {
        if (dataObject.type === "cancel") {
            setEventAddModalVisible(false);
            setgoogleMeetLinkAdded(true);
            setshowGuestSuggestionsDiv(false);
            setEventName("");
            setEventDescription("");
            seteventGuestTxt("")
            setguestSuggestionList([]);
            setguestSelectedList([]);
        } else if (dataObject.type === "submit") {
            let calendarApi = eventSelectedInfo.view.calendar
            calendarApi.unselect() // clear date selection
            // calendarApi.addEvent({
            //     id: createUniquieId(),
            //     title: eventName,
            //     start: eventSelectedInfo.startStr,
            //     end: eventSelectedInfo.endStr,
            //     allDay: eventSelectedInfo.allDay
            // })
            setEventName("");
            setEventDescription("");
            seteventGuestTxt("");
            setshowGuestSuggestionsDiv(false);
            var googleMeetLinkAddedToSend = googleMeetLinkAdded ? googleMeetId : googleMeetLinkAdded;
            var selectedGuestListToSend = [];
            selectedGuestListToSend.push(JSON.parse(localStorage.getItem("userData")).email);
            if (guestSelectedList.length > 0) {
                guestSelectedList.map((guest) => {
                    console.log("guest", guest);
                    selectedGuestListToSend.push(guest.email);
                });
            }

            var dataToSend = {
                "id": createUniquieId(),
                "title": eventName,
                "description": eventDescription,
                "start": selectedStartTime,
                "end": selectedEndTime,
                "allDay": eventSelectedInfo.allDay,
                "googleMeetId": googleMeetLinkAddedToSend,
                "eventHostId": JSON.parse(localStorage.getItem("userData")).email,
                "eventGuests": selectedGuestListToSend,
            }
            setIsLoading(true);
            axios.post(`${MW_URL}` + "addEvent", dataToSend)
                .then(response => {
                    //setIsLoading(false)
                    var status = "NA";
                    if (response && response.data && response.data.STATUS) status = response.data.STATUS
                    if (status.toUpperCase() === "SUCCESS") {
                        getAllEventsFun();
                        setEventAddModalVisible(false);
                        setgoogleMeetLinkAdded(true);
                        setguestSelectedList([]);
                    } else if (status === "INVALID_USER") {
                        setEventAddModalVisible(false);
                        setgoogleMeetLinkAdded(true);
                        setguestSelectedList([]);
                    }
                })
                .catch(err => {
                    console.log("err >> ", err);
                })
        }
    }

    const fullcalendarViewChange = (viewType) => {
        setdropdownDiv(false);
        setcalanderViewType(viewType);
        if (viewType == "Day") {
            calendarRef.current.getApi().changeView('timeGridDay');
        } else if (viewType == "Week") {
            calendarRef.current.getApi().changeView('dayGridWeek');
        } else if (viewType == "Month") {
            calendarRef.current.getApi().changeView('dayGridMonth');
        }
    }

    const showGuestSuggestions = (guestTxt) => {
        seteventGuestTxt(guestTxt);
        if (guestTxt.length > 1) {
            setshowGuestSuggestionsDiv(true);
            var userList = []
            listOfUsers.map((users) => {
                if (users.email.match(guestTxt) != null || users.username.match(guestTxt)) {
                    //push if its not already been added 
                    guestSelectedList.indexOf(users) === -1 ? userList.push(users) : console.log("This item already exists");
                }
            });
            setguestSuggestionList(userList);
        } else {
            setshowGuestSuggestionsDiv(false)
        }
    }

    const addGuestToselectedList = (guest) => {
        setguestSelectedList([...guestSelectedList, guest]);
        console.log("guestSelectedList", guestSelectedList);
        setshowGuestSuggestionsDiv(false);
        seteventGuestTxt("");
    }

    const removeGuestFromSelectedLust = (guest) => {
        const newList = guestSelectedList.filter((user) => user.email !== guest.email);
        setguestSelectedList(newList);
    }

    return (
        <div>
            {isLoading ? <LoadingAnimation isVisible={true} /> : null}
            <div className="appoinments-page-container col-md-12 px-5 d-flex flex-column pt-sm-5 pt-2" style={{ minHeight: height }}>
                <div className="col-md-12 px-3 row m-0">
                    <div className="col-md-8">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="head-text">Today's Schedule</div>
                            <div>
                                <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }} onClick={() => setdropdownDiv(!dropdownDiv)}>
                                    <div className="Day pr-2">{calanderViewType}</div>
                                    <FaCaretDown style={{ fontSize: 12 }} />
                                </div>
                                {dropdownDiv ? (<div className="col-md-12 m-0 p-0">
                                    <div className="card-design p-3" style={{ borderRadius: 15, position: "absolute", right: 0, backgroundColor: "white", zIndex: 5, cursor: "pointer" }}>
                                        <div onClick={() => { fullcalendarViewChange("Day") }}>Day</div>
                                        <div onClick={() => { fullcalendarViewChange("Month") }}>Month</div>
                                        <div onClick={() => { fullcalendarViewChange("Week") }}>Week</div>
                                    </div>
                                </div>) : null}
                            </div>

                        </div>
                        <FullCalendar
                            ref={calendarRef}
                            //gotoDate={selectedDay}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            initialView='timeGridDay'
                            editable={true}
                            selectable={true}
                            selectMirror={true}
                            dayMaxEvents={true}
                            weekends={true}
                            allDaySlot={false}
                            events={currentEvents}
                            slotLaneClassNames='sloteLaneStyle'
                            slotDuration='01:00:00'
                            //initialEvents={currentEvents} // alternatively, use the `events` setting to fetch from a feed
                            select={handleDateSelect}
                            eventContent={renderEventContent} // custom render function
                            eventClick={function (info) {
                                alert("event click");
                                setCurrentClickInfo(info);
                                setEventName(info.event.title);
                                handleStartTimeChange(info.event.start);
                                handleEndTimeChange(info.event.end);
                                console.log("info.event", JSON.stringify(info.event));
                                setEventDescription(info.event.extendedProps.description);
                                console.log("info.event.extendedProps.googleMeetId", info.event.extendedProps.googleMeetId);
                                if (info.event.extendedProps.googleMeetId == "false") {
                                    setgoogleMeetLinkAdded(false);
                                    setgoogleMeetId(createUniquieId());
                                } else {
                                    setgoogleMeetLinkAdded(true);
                                    setgoogleMeetId(info.event.extendedProps.googleMeetId);
                                }
                                seteventGuestTxt("");
                                seteventEditModalVisible(true);
                                var guestListReceived = info.event.extendedProps.eventGuests;
                                var listToUpdate = []
                                listOfUsers.map((user) => {
                                    guestListReceived.map((guest) => {
                                        if (user.email == guest) {
                                            listToUpdate.push(user);
                                        }
                                    })
                                });
                                setguestSelectedList(listToUpdate);
                            }}
                            style={{ height: 400 }}
                            view={{
                                dayGridMonth: { // name of view
                                    titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' }
                                    // other view-specific options here
                                }
                            }}
                            contentHeight={window.innerHeight * 80 / 100 + "px"}
                            eventDragStop={function (info) {
                                 console.log("eventDragStop"+info.event.start + " " + info.event.startStr);
                            }}
                            eventResizeStop={function (info) {
                                 console.log("eventResizeStop"+info.event.startStr + " " + info.event.endStr);
                            }}
                        //eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                        /* you can update a remote database when these fire:
                        eventAdd={function () { }}
                        eventChange={function () { }}
                        eventRemove={function () { }}
                        */
                        />

                    </div>

                    <div className="col-md-4">{renderSidebar()}</div>
                </div>
                <ModalComponent
                    isVisible={eventEditModalVisible}
                    mainHeading="Alert"
                    cancelButtonText="Cancel"
                    submitButtonText="Save"
                    callback={eventEditModalHandler}
                >
                    <div className="pt-2 pb-5">
                        {Object.keys(currentClickInfo).length > 0 ?
                            <div>
                                <div className="col-md-12 p-0 text-left form-group">
                                    <div className="d-flex justify-content-center pb-2">Please edit your appoinment</div>
                                    <Input type="text" autoComplete="new-password" style={{ width: '95%' }} className="text-box-style mb-3 py-2 input_data mandatoryField" placeholder="Event Name" value={eventName} onChange={(event) => { setEventName(event.target.value) }} />
                                    <Input type="text" style={{ width: '95%' }} className="text-box-style mb-3 py-2 input_data mandatoryField" placeholder="Add Event description" value={eventDescription} onChange={(event) => { setEventDescription(event.target.value) }} />
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Stack spacing={3}>
                                            <div className="mb-3 d-flex" style={{ width: "95%" }}>
                                                <MobileTimePicker
                                                    label="Start Time"
                                                    value={selectedStartTime}
                                                    onChange={(newValue) => {
                                                        console.log("newValue", (newValue.format()));
                                                        handleStartTimeChange(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    minutesStep={5}
                                                />
                                                <MobileTimePicker
                                                    label="End Time"
                                                    value={selectedEndTime}
                                                    onChange={(newValue) => {
                                                        handleEndTimeChange(newValue.format());
                                                    }}
                                                    // minTime={selectedStartTime}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </div>
                                        </Stack>
                                    </LocalizationProvider>
                                    <div>
                                        <Input type="text" style={{ width: '95%' }} className="text-box-style  py-2 input_data mandatoryField" placeholder="Add Participants" value={eventGuestTxt} onChange={(event) => { showGuestSuggestions(event.target.value) }} />
                                        <div style={{ position: "absolute", width: "95%", zIndex: 3, backgroundColor: "white" }}>
                                            {showGuestSuggestionsDiv ? (<div style={{ maxHeight: "200px", overflow: "scroll" }}>
                                                {guestSuggestionList.map((user) => {
                                                    console.log("user", user)
                                                    return (<div className="p-2 d-flex align-items-center guest-suggestion" onClick={() => { addGuestToselectedList(user) }}>
                                                        <img height={40} src={ProfilePicSample} alt="profilepic" />
                                                        <div className="p-0 m-0 pl-2">
                                                            <div className="p-0 m-0" style={{ fontSize: 14, fontWeight: 600, textTransform: "capitalize" }}>{user.username}</div>
                                                            <div className="p-0 m-0" style={{ fontSize: 12 }}>{user.email}</div>
                                                        </div>
                                                    </div>)
                                                })}
                                            </div>) : null}</div>
                                        <div className="p-2 d-flex align-items-center">
                                            <div className="col-md-1 p-0 m-0">
                                                <img height={40} src={ProfilePicSample} alt="profilepic" />
                                            </div>
                                            <div className="col-md-9 p-0 m-0 pl-3">
                                                <div className="p-0 m-0" style={{ fontSize: 14, fontWeight: 600, textTransform: "capitalize" }}>{JSON.parse(localStorage.getItem("userData")).username}</div>
                                                <div className="p-0 m-0" style={{ fontSize: 12 }}>{JSON.parse(localStorage.getItem("userData")).email}</div>
                                            </div>
                                        </div>
                                        <div>
                                            {guestSelectedList.map((user) => {
                                                return (
                                                    <div className="p-2 d-flex align-items-center">
                                                        <div className="col-md-1 p-0 m-0">
                                                            <img height={40} src={ProfilePicSample} alt="profilepic" />
                                                        </div>
                                                        <div className="col-md-9 p-0 m-0 pl-3">
                                                            <div className="p-0 m-0" style={{ fontSize: 14, fontWeight: 600, textTransform: "capitalize" }}>{user.username}</div>
                                                            <div className="p-0 m-0" style={{ fontSize: 12 }}>{user.email}</div>
                                                        </div>
                                                        <div className="col-md-1" style={{ cursor: "pointer" }} onClick={() => { removeGuestFromSelectedLust(user) }}>
                                                            <FaTimes style={{ fontSize: 12 }} />
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                    <div className="mt-3 d-flex align-items-center">
                                        <img className="pr-1" height={25} src={GoogleMeet} alt="googlemeet" />
                                        <div className="col-md-9 p-2 ml-3" style={{ backgroundColor: "#6C63FF", color: "white", borderRadius: 10, fontSize: 14 }} onClick={() => { googleMeetLinkAdded ? window.open('https://meet.google.com/lookup/' + googleMeetId, '_blank') : setgoogleMeetLinkAdded(true) }}>{googleMeetLinkAdded ? "Google Meet Video Conferencing" : "Add Google Meet Video Conferencing"}</div>
                                        {googleMeetLinkAdded ? (<div className="col-md-1" style={{ fontWeight: 900, cursor: "pointer" }} onClick={() => { setgoogleMeetLinkAdded(!googleMeetLinkAdded) }}>X</div>) : null}
                                    </div>
                                    <div className="errorDesc"></div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center" style={{ cursor: "pointer" }} onClick={() => { deleteEventHandler() }}>
                                    <FaTrash style={{ fontSize: 12 }} />
                                    <div className="pl-2">Delete this event</div>
                                </div>
                            </div>
                            : null}
                    </div>
                </ModalComponent>
                <ModalComponent
                    isVisible={eventAddModalVisible}
                    mainHeading="Alert"
                    cancelButtonText="Close"
                    submitButtonText="Add"
                    callback={eventAddModalHandler}
                >
                    <div className="pt-2 pb-5">
                        <div className="col-md-12 p-0 text-left form-group">
                            <label>Please enter a new title for your appoinment</label>
                            <Input type="text" autoComplete="new-password" style={{ width: '95%' }} className="text-box-style mb-3 py-2 input_data mandatoryField" placeholder="Event Name" value={eventName} onChange={(event) => { setEventName(event.target.value) }} />
                            <Input type="text" style={{ width: '95%' }} className="text-box-style mb-3 py-2 input_data mandatoryField" placeholder="Add Event description" value={eventDescription} onChange={(event) => { setEventDescription(event.target.value) }} />
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Stack spacing={3}>
                                    <div className="mb-3 d-flex" style={{ width: "95%" }}>
                                        <MobileTimePicker
                                            label="Start Time"
                                            value={selectedStartTime}
                                            onChange={(newValue) => {
                                                console.log("newValue", (newValue.format()));
                                                handleStartTimeChange(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            minutesStep={5}
                                        />
                                        <MobileTimePicker
                                            label="End Time"
                                            value={selectedEndTime}
                                            onChange={(newValue) => {
                                                handleEndTimeChange(newValue.format());
                                            }}
                                            minTime={moment(selectedStartTime).add(15, 'minutes')}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </div>
                                </Stack>
                            </LocalizationProvider>
                            <div className="p-0 m-0">
                                <div>
                                    <Input type="text" style={{ width: '95%' }} className="text-box-style  py-2 input_data mandatoryField" placeholder="Add Participants" value={eventGuestTxt} onChange={(event) => { showGuestSuggestions(event.target.value) }} />
                                    <div style={{ position: "absolute", width: "95%", zIndex: 3, backgroundColor: "white" }}>
                                        {showGuestSuggestionsDiv ? (<div style={{ maxHeight: "200px", overflow: "scroll" }}>
                                            {guestSuggestionList.map((user) => {
                                                console.log("user", user)
                                                return (<div className="p-2 d-flex align-items-center guest-suggestion" onClick={() => { addGuestToselectedList(user) }}>
                                                    <img height={40} src={ProfilePicSample} alt="profilepic" />
                                                    <div className="p-0 m-0 pl-2">
                                                        <div className="p-0 m-0" style={{ fontSize: 14, fontWeight: 600, textTransform: "capitalize" }}>{user.username}</div>
                                                        <div className="p-0 m-0" style={{ fontSize: 12 }}>{user.email}</div>
                                                    </div>
                                                </div>)
                                            })}
                                        </div>) : null}</div>
                                    <div className="p-2 d-flex align-items-center">
                                        <div className="col-md-1 p-0 m-0">
                                            <img height={40} src={ProfilePicSample} alt="profilepic" />
                                        </div>
                                        <div className="col-md-9 p-0 m-0 pl-3">
                                            <div className="p-0 m-0" style={{ fontSize: 14, fontWeight: 600, textTransform: "capitalize" }}>{JSON.parse(localStorage.getItem("userData")).username}</div>
                                            <div className="p-0 m-0" style={{ fontSize: 12 }}>{JSON.parse(localStorage.getItem("userData")).email}</div>
                                        </div>
                                    </div>
                                    <div>
                                        {guestSelectedList.map((user) => {
                                            return (
                                                <div className="p-2 d-flex align-items-center">
                                                    <div className="col-md-1 p-0 m-0">
                                                        <img height={40} src={ProfilePicSample} alt="profilepic" />
                                                    </div>
                                                    <div className="col-md-9 p-0 m-0 pl-3">
                                                        <div className="p-0 m-0" style={{ fontSize: 14, fontWeight: 600, textTransform: "capitalize" }}>{user.username}</div>
                                                        <div className="p-0 m-0" style={{ fontSize: 12 }}>{user.email}</div>
                                                    </div>
                                                    <div className="col-md-1" style={{ cursor: "pointer" }} onClick={() => { removeGuestFromSelectedLust(user) }}>
                                                        <FaTimes style={{ fontSize: 12 }} />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                                <div className="mt-3 d-flex align-items-center">
                                    <img className="pr-1" height={25} src={GoogleMeet} alt="googlemeet" />
                                    <div className="col-md-9 p-2 ml-3" style={{ backgroundColor: "#6C63FF", color: "white", borderRadius: 10, fontSize: 14 }} onClick={() => { googleMeetLinkAdded ? window.open('https://meet.google.com/lookup/' + googleMeetId, '_blank') : setgoogleMeetLinkAdded(true) }}>{googleMeetLinkAdded ? "Google Meet Video Conferencing" : "Add Google Meet Video Conferencing"}</div>
                                    {googleMeetLinkAdded ? (<div className="col-md-1" style={{ fontWeight: 900, cursor: "pointer" }} onClick={() => { setgoogleMeetLinkAdded(!googleMeetLinkAdded) }}>X</div>) : null}
                                </div>
                            </div>

                            <div className="errorDesc"></div>
                        </div>
                    </div>
                </ModalComponent>
            </div>
        </div>
    )

    function renderSidebar() {
        return (
            <div className='col-md-12 p-0 right-side-bar pb-5'>
                <div className='col-md-12 p-0'>
                    <div>
                        <Calendar
                            value={selectedDay}
                            onChange={(date) => {
                                setSelectedDay(date);
                                var selectedDate = date.year + " " + date.month + " " + date.day
                                calendarRef.current.getApi().gotoDate(new Date(selectedDate));
                            }}
                            colorPrimary="#6C63FF"
                            calendarClassName="custom-calendar"
                            calendarTodayClassName="custom-today-day"
                        />
                    </div>
                </div>
                <div className='col-md-12 p-0'>
                    <div className="head-text">Scheduled</div>
                    <div className="col-md-12 p-0 pt-3" style={{ maxHeight: window.innerHeight * 32 / 100 + "px", overflow: "scroll" }}>
                        {currentEvents.map((event) => {
                            var startDate = moment(event.start, "YYYY-MM-DD");
                            var iscurrentDate = startDate.isSameOrAfter(new Date(), "day");
                            if (iscurrentDate) {
                                return (
                                    <div key={event.id} className="col-md-12 px-4 py-2 d-flex align-items-center justify-content-between schedule-box mb-3">
                                        <div>
                                            <div className="normal-text">{formatDate(event.start, { year: 'numeric', month: 'short', day: 'numeric' })}</div>
                                            <div className="small-text">{event.title}</div>
                                            <div className="extra-small-text">{formatDate(event.start, { hour: 'numeric', minute: 'numeric' }) + " - " + formatDate(event.end, { hour: 'numeric', minute: 'numeric' })}</div>
                                        </div>
                                        {event.googleMeetId != "false" ? (<img className="pr-1" height={25} src={GoogleMeet} style={{ cursor: "pointer" }} alt="googlemeet" onClick={() => { window.open('https://meet.google.com/lookup/' + event.googleMeetId, '_blank') }} />) : null}
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>
        )
    }

    function renderEventContent(eventInfo) {
        return (
            <div className="d-flex flex-column justify-content-center pl-3">
                <div className="event-name">{eventInfo.event.title}</div>
                <div className="event-time">{eventInfo.timeText}</div>
            </div>
        )
    }

}



export default AppoinmentsScreen;