import { createSlice } from "@reduxjs/toolkit";

export const generalSlice = createSlice({
    name: "general",
    initialState: {
        isMainLoading: false,
        currentPage: []
    },
    reducers: {
        setMainLoadingTrue: (state) => {
            state.isMainLoading = true;
        },
        setMainLoadingFalse: (state) => {
            state.isMainLoading = false;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        }
    }
});

export const { setMainLoadingTrue, setMainLoadingFalse, setCurrentPage } = generalSlice.actions;    // exporting reducer funtion

export const isMainLoading = state => state.general.isMainLoading;  // exporting redux state
export const currentPageScreen = state => state.general.currentPage;  // exporting redux state

export default generalSlice.reducer;