import React, { useEffect, useState, useRef, useCallback } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import axios from "axios";
import { MW_URL } from "../../../../config";


const Stats = () => {

    const percentage = 66;

    const [isLoading, setIsLoading] = useState(true);
    const [resumefromdb, setResume] = useState([]);
    const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState(
        []
    );
    const [todolistMap, settodolistMap] = useState(null);
    const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
    const [essaysList, setessaysList] = useState([]);
    const [essaysTemplateList, setessaysTemplateList] = useState([]);
    const [updatesList, setupdatesList] = useState([]);
    const [docId, setDocumentId] = useState([]);
    const [uploadedDoc, setUploadedDocPayload] = useState([]);

    const [newResult, setNewResult] = useState(0)
    const [sopResult, setSopResult] = useState(0)


    useEffect(() => {
        getAllEssays();        
      }, []);




    const getAllEssays = () => {
        setIsLoading(true);
        var selectedCollages = [];
        var selectedCollagesIds = [];
        axios
          .post(`${MW_URL}` + "getSelectedListOfCollages", {
            email: JSON.parse(localStorage.getItem("userData")).email,
          })
          .then((getSelectedListOfCollagesresponse) => {
            setResume(getSelectedListOfCollagesresponse.data[0].resume);
            if (
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
                .length > 0
            ) {
              getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
                (collage) => {
                  selectedCollages.push(collage);
                  selectedCollagesIds.push(collage.collageId);
                }
              );
              setlistOfSelectedCollagesArr(selectedCollages);
    
              if (getSelectedListOfCollagesresponse.data[0].todoList) {
                var mapToSet = new Map(
                  Object.entries(getSelectedListOfCollagesresponse.data[0].todoList)
                );
                selectedCollages.map((colgSelected) => {
                  if (mapToSet.get(colgSelected.collageId) == null)
                    mapToSet.set(colgSelected.collageId, []);
                });
                settodolistMap(mapToSet);
              } else {
                let todoMap = new Map();
                selectedCollages.map((colgSelected) => {
                  if (todoMap.get(colgSelected.collageId) == null)
                    todoMap.set(colgSelected.collageId, []);
                });
                settodolistMap(todoMap);
              }
        
              var templateArr = [];
              axios
                .post(`${MW_URL}` + "getEssays", {
                  ownerId: JSON.parse(localStorage.getItem("userData")).email,
                })
                .then((getAllEssaysResponse) => {
                  setIsUseEffectCallRequired(false);
                  var essaysListToPush = [];
                  var essaysRequirementIds = [];
                  getAllEssaysResponse.data.map((essay) => {
                    if (
                      essay.ownerId ==
                        JSON.parse(localStorage.getItem("userData")).email &&
                      selectedCollagesIds.includes(essay.collageId)
                    ) {
                      essaysListToPush.push({
                        essayId: essay.essayId,
                        content: essay.content,
                        typeOfDocument: essay.typeOfDocument,
                        collageId: essay.collageId,
                        ownerId: essay.ownerId,
                        feedbacks: essay.feedbacks,
                        essayReqId: essay.essayReqId,
                        updatedDate: essay.updatedDate,
                        isApproved: essay.isApproved,
                      });
                      essaysRequirementIds.push(essay.essayReqId);
                    }
                  });
                  setessaysList(essaysListToPush);
                //   defaultDropdownSet(selectedCollages);
                  axios
                    .post(`${MW_URL}` + "get_essay_requirement", selectedCollages)
                    .then((getEssaysTemplateResponse) => {
                      var status = "NA";
                      if (
                        getEssaysTemplateResponse &&
                        getEssaysTemplateResponse.data &&
                        getEssaysTemplateResponse.data.STATUS
                      )
                        status = getEssaysTemplateResponse.data.STATUS;
                      if (status.toUpperCase() === "SUCCESS") {
                        setessaysTemplateList(getEssaysTemplateResponse.data.data);
                        console.log("dd", getEssaysTemplateResponse.data.data)
                        getEssaysTemplateResponse.data.data.map(
                          (templateRes, temploopindex) => {
                            if (templateRes.essayRequirements.length != 0) {
                              templateRes.essayRequirements.map((esaaReq) => {
                                templateArr.push({
                                  template: esaaReq.question,
                                  collageId: templateRes.collageId,
                                  name: templateRes.name,
                                  essayReqId: esaaReq.essayReqId,
                                  length: esaaReq.length,
                                  requirement: esaaReq.requirement,
                                  answered: essaysRequirementIds.includes(
                                    esaaReq.essayReqId
                                  ),
                                });
                              });
                            }
                            if (
                              getEssaysTemplateResponse.data.data.length ==
                              temploopindex + 1
                            ) {
                              axios
                                .post(`${MW_URL}` + "getUpdates", {
                                  recipientId: JSON.parse(
                                    localStorage.getItem("userData")
                                  ).email,
                                  senderId: false,
                                })
                                .then((getUpdatesResponse) => {
                                  setupdatesList(getUpdatesResponse.data);
                                  setessaysTemplateList(templateArr);
                                  essayComponent(templateArr, essaysListToPush);
                                  console.log("dfd", templateArr)
    
    
                                  axios
                                    .post(
                                      `${MW_URL}` + "documentsbyuser",
                                      selectedCollages
                                    )
                                    .then((documentsbyuserresponse) => {
                                      //   console.log("documentsbyuser", documentsbyuserresponse);
                                      var documentId = [];
                                      var documentsByUserResFromApi =
                                        documentsbyuserresponse;
                                      documentsbyuserresponse.data.map((ids) => {
                                        {
                                          documentId.push(ids);
                                        }
                                      });
                                      var requredDoc = [];
                                      setDocumentId(documentId);
                                      documentId.map((doc) => {
                                        requredDoc.push({
                                          DocId: doc.documentId,
                                          CollageId: doc.collageId,
                                        });
                                      });
                                      axios
                                        .post(
                                          `${MW_URL}` + "getAllStudentsDocsCount",
                                          {
                                            email: JSON.parse(
                                              localStorage.getItem("userData")
                                            ).email,
                                          }
                                        )
                                        .then((getStudDOcResponse) => {
                                          setIsUseEffectCallRequired(false);
                                          var allDetail = [];
                                          if (
                                            getStudDOcResponse &&
                                            getStudDOcResponse.data &&
                                            getStudDOcResponse.data[0]
                                          ) {
                                            var DocMapArrTemp = [];
                                            var DocMapObjTemp = [];
                                            getStudDOcResponse.data.map(
                                              (documentsCollageMap) => {
                                                requredDoc.map((reqDocId) => {
                                                  if (
                                                    documentsCollageMap.documentId.includes(
                                                      reqDocId.DocId
                                                    ) &&
                                                    documentsCollageMap.collegeId.includes(
                                                      reqDocId.CollageId
                                                    )
                                                  ) {
                                                    DocMapObjTemp.push({
                                                      doc: documentsCollageMap.documentId,
                                                    });
                                                  }
                                                });
                                                if (
                                                  DocMapObjTemp.doc ==
                                                  requredDoc.DocId
                                                ) {
                                                  DocMapArrTemp.push(DocMapObjTemp);
                                                }
                                              }
                                            );
                                            // console.log("collageDocMapArrTemp",collageDocMapArrTemp);
                                            setUploadedDocPayload(DocMapArrTemp[0]);
                                            sopComponent(DocMapArrTemp[0], documentId)
                                          }
                                        //   getNewsFromApiAndLorcall();
                                        });
                                    });
                                })
    
                                .catch((err) => {
                                  console.log("err >> ", err);
                                })
                                .catch((err) => {
                                  console.log("err >> ", err);
                                })
                                .catch((err) => {
                                  console.log("err >> ", err);
                                })
                                .catch((err) => {
                                  console.log("err >> ", err);
                                });
                            }
                          }
                        );
                      } else {
                        // getNewsFromApiAndLorcall();
                      }
                    })
                    .catch((err) => {
                      console.log("err >> ", err);
                    });
                })
                .catch((err) => {
                  console.log("err >> ", err);
                });
            } else {
            //   getNewsFromApiAndLorcall();
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      };

    const essayComponent = (essaysTemplateList, essaysList) => {
        var templateList = [];
        essaysTemplateList.map((template) => {
            console.log(template, "tem")
          templateList.push(template.essayReqId);
        });
        var counter = 0;
        essaysList.map((essay) => {
            console.log(essay, "ess")
          if (templateList.includes(essay.essayReqId)) {
            if (essay.isApproved == true) {
              counter = counter + 1;
            }
          }
        });
        const result = Math.round((counter * 100) / essaysTemplateList.length);
        setNewResult(result)
    }

    const sopComponent = (uploadedDoc, DocId) => {
        const result = Math.round((uploadedDoc.length * 100) / DocId.length);
        setSopResult(result)
    }

  return (
    <div className='dashStats '>
        <h3 className='dashHead3'>Stats</h3>
        <div className='row'>
            <div className='col-4'>
                <div className='statBlocks '>
                    <div className='d-flex justify-content-around'>
                        <div className='py-2'>
                            {/* <CircularProgressbar value={newResult} text={`${newResult} %`} strokeWidth={8} */}
                             <CircularProgressbar value={20} text={`80%`} strokeWidth={8}
                                styles={buildStyles({
                                    strokeLinecap: 'round',
                                    pathColor: "#FF981D",
                                    // pathColor: `#FF981D, ${percentage / 100})`,
                                    textColor: '#FF981D',
                                    trailColor: '#d6d6d6',
                                })}
                            />
                        </div>
                        <div className='py-2 col-6'>
                            <p className='dashTitle2'>Essays</p>
                            <p className='profileTitle'>100</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-4'>
                <div className='statBlocks '>
                    <div className='d-flex justify-content-around'>
                        <div className='py-2'>
                            <CircularProgressbar value={20} text={"50%"} strokeWidth={8}
                                styles={buildStyles({
                                    strokeLinecap: 'round',
                                    pathColor: "#FF981D",
                                    // pathColor: `#FF981D, ${percentage / 100})`,
                                    textColor: '#FF981D',
                                    trailColor: '#d6d6d6',
                                })}
                            />
                        </div>
                        <div className='py-2 col-6'>
                            <p className='dashTitle2'>LOR</p>
                            <p className='profileTitle'>10</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-4'>
                <div className='statBlocks '>
                    <div className='d-flex justify-content-around'>
                        <div className='py-2'>
                            <CircularProgressbar value={sopResult} text={`${sopResult} %`} strokeWidth={8}
                                styles={buildStyles({
                                    strokeLinecap: 'round',
                                    pathColor: "#FF981D",
                                    // pathColor: `#FF981D, ${percentage / 100})`,
                                    textColor: '#FF981D',
                                    trailColor: '#d6d6d6',
                                })}
                            />
                        </div>
                        <div className='py-2 col-6'>
                            <p className='dashTitle2'>SOP</p>
                            <p className='profileTitle'>10</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Stats;