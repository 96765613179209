import React, { useEffect, useState } from "react";
import UnderConstruction from "../../imageAssets/underConstruction.png";
import TableComponent from "../../utils/tableComponent";
import LoadingAnimation from "../../utils/loadingAnimation";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import ModalComponent from "../../utils/modalComponent";
import SliderModal from "../../utils/sliderModal.js";
import ValidateForm from "../../utils/formValidation";
import { Button, Input } from "reactstrap";
import axios from "axios";
import { MW_URL } from "../../config";
import AlertModal from "../../components/widgets/AlertModal";

const AdminEssay = () => {
  const createUniquieId = () => {
    const head = Date.now().toString(36);
    const tail = Math.random().toString(36).substr(2);
    return head + tail;
  };

  const [height, setHeight] = useState("");
  const [showEssayDelete, setShowEssayDelete] = useState(false)
  const [requirement, setRequirement] = useState();


  const [isLoading, setIsLoading] = useState(true);
  const [viewMoreSliderVisible, setViewMoreSliderVisible] = useState(false);
  const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
  const [essayModalOpen, setEssayModalOpen] = useState(false);
  const [currentClgReqArr, setcurrentClgReqArr] = useState([]);
  const [collegeDetailsPayload, setCollegeDetailsPayload] = useState({
    totalRecords: 0,
    data: [],
  });
  const [selected, setSelected] = useState([]);
  const [editState, setEditState] = React.useState({
    question: "",
    length: "",
    requirement: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    var windowHeight = window.innerHeight;
    setHeight(windowHeight + "px");
    setIsLoading(true);
    setCollegeDetailsPayload({
      totalRecords: 0,
      data: [],
    });
    console.log("use Effect");
    axios
      .post(`${MW_URL}` + "getCollegeDetailsByOffset", {
        offset: 1,
        limit: 10,
        filter: false,
      })
      .then((getCollegeDetailsResponse) => {
        console.log("getCollegeDetailsResponse", getCollegeDetailsResponse);
        setIsUseEffectCallRequired(false);
        initializeCollages(getCollegeDetailsResponse);
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  }, [isUseEffectCallRequired]);

  const initializeCollages = (getCollegeDetailsResponse) => {
    var allCollegeDetail = [];
    if (
      getCollegeDetailsResponse &&
      getCollegeDetailsResponse.data &&
      getCollegeDetailsResponse.data.collageDetails
    ) {
      for (
        var i = 0;
        i < getCollegeDetailsResponse.data.collageDetails.length;
        i++
      ) {
        var currentObj = getCollegeDetailsResponse.data.collageDetails[i];
        console.log("currentObj", currentObj);
        var tempObj = {
          id: currentObj.collageId,
          name: currentObj.name,
          description: currentObj.description,
          country: currentObj.country,
          essayRequirements: currentObj.essayRequirements,
        };
        allCollegeDetail.push(tempObj);
      }
      setCollegeDetailsPayload({
        totalRecords: getCollegeDetailsResponse.data.totalCount,
        data: allCollegeDetail,
      });
      console.log("collegeDetailsPayload", collegeDetailsPayload);
      setIsLoading(false);
    }
  };

  const collegeDetailsTableHandler = (dataObject) => {
    console.log("collegeDetailsTableHandler", dataObject);
    if (dataObject.type === "pagination") {
      setIsLoading(true);
      axios
        .post(`${MW_URL}` + "getCollegeDetailsByOffset", {
          offset: dataObject.offset,
          limit: 10,
          filter: false,
        })
        .then((getCollegeDetailsResponse) => {
          setIsUseEffectCallRequired(false);
          initializeCollages(getCollegeDetailsResponse);
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    } else if (dataObject.type === "search") {
      setIsLoading(true);
      axios
        .post(`${MW_URL}` + "getCollegeDetailsByOffsetAndSearchKeyword", {
          offset: 0,
          limit: 10,
          keyword: dataObject.searchKeyword,
        })
        .then((getCollegeDetailsResponse) => {
          setIsUseEffectCallRequired(false);
          initializeCollages(getCollegeDetailsResponse);
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    }
  };

  const actionEssayBtnClicked = (type, receivedData) => {
    console.log("receivedData", receivedData);
    if (type === "essay") {
      setEditState({
        Id: receivedData.id,
      });
      setcurrentClgReqArr(receivedData.essayRequirements);
      setEssayModalOpen(true);
    }
  };

  const addReqToCurrentCollageReq = () => {
    if (ValidateForm.inputFieldMandatoryCheck("addEssayContainer", false)) {
      if (
        ValidateForm.inputFieldValueCompositionCheck("addEssayContainer", false)
      )
        setcurrentClgReqArr([
          ...currentClgReqArr,
          {
            essayReqId: createUniquieId(),
            question: editState.question,
            length: editState.count,
            requirement: editState.requirement,
          },
        ]);

      setEditState({
        question: "",
        requirement: "",
        count: "",
        Id: editState.Id,
      });
    }
  };
  const handleEssayDelete = (requirement) => {
    setShowEssayDelete(true)
    setRequirement(requirement)
  }
  const deleteReqFromCurrentCollageReq = () => {
      var updatedClgReqArr = currentClgReqArr.filter((essayReq) => {
        return essayReq.essayReqId != requirement.essayReqId;
      });
      setcurrentClgReqArr(updatedClgReqArr);
      setShowEssayDelete(false)
  };

  const editReqInCurrentCollageReq = (requirement) => {
    console.log("edit ", requirement);
  };

  const essayModalHandler = (dataObject) => {
    if (dataObject.type === "cancel") {
      setEssayModalOpen(false);
      setEditState({
        question: "",
        requirement: "",
        count: "",
        Id: "",
      });
    } else if (dataObject.type === "submit") {
      var dataToSend = {
        id: editState.Id,
        essay_requirements: currentClgReqArr,
      };
      setIsLoading(true);
      axios
        .post(`${MW_URL}` + "add_essay_requirement", dataToSend)
        .then((getAddEssayResponse) => {
          if (
            getAddEssayResponse &&
            getAddEssayResponse.data &&
            getAddEssayResponse.data.STATUS === "SUCCESS"
          ) {
            //setIsUseEffectCallRequired(true);
            setEssayModalOpen(false);
            setEditState({
              question: "",
              requirement: "",
              count: "",
              collegeId: "",
            });
            window.location.reload();
          } else {
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    }
    setViewMoreSliderVisible(false);
  };

  function essayload() {
    setIsLoading(true);
    axios
      .post(`${MW_URL}` + "webScrapListOfCollages", {})
      .then((webScrapListOfCollagesRes) => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  }

  return (
    <div>
      {isLoading ? <LoadingAnimation isVisible={true} /> : null}
      <div
        className="college-page-container col-md-12 px-5 d-flex flex-column pt-sm-5 pt-2"
        style={{ minHeight: height }}
      >
        {/* <button onClick={essayload}>essay load</button> */}
        <div className="col-md-12 px-3">
          <div className="col-md-12 pt-3 px-0">
            <div className="col-md-12 tab-data">
              <div className="col-md-12 college-details-container p-4">
                <TableComponent
                  headerArray={[
                    {
                      key: "name",
                      label: "Name",
                    },
                    {
                      key: "description",
                      label: "Description",
                    },
                    {
                      key: "country",
                      label: "Country",
                    },
                    {
                      key: "action",
                      label: "Action",
                      formatter: (rowData, callback) => {
                        return (
                          <div>
                            <span className="pl-2">
                              <FaEdit
                                onClick={() =>
                                  actionEssayBtnClicked("essay", rowData)
                                }
                                style={{ color: "#6C63FF", cursor: "pointer" }}
                              />
                            </span>
                          </div>
                        );
                      },
                    },
                  ]}
                  payload={collegeDetailsPayload}
                  isSearchRequired={false}
                  noDataContentText="No College data Found"
                  limit={10}
                  tableUniqueId="collegeDetailsTable"
                  paginationRequired={true}
                  paginationType="server"
                  callback={collegeDetailsTableHandler}
                />
              </div>
            </div>
          </div>
        </div>
        <ModalComponent
          isVisible={essayModalOpen}
          mainHeading="Add Essay"
          callback={essayModalHandler}
          modalSize="xl"
        >
          <div id="addEssayContainer">
            <div className="col-md-12 p-2 d-flex justify-content-start form-group flex-column">
              <textarea
                type="text"
                autoComplete="new-password"
                style={{ width: "100%" }}
                className="text-box-style my-3 py-2 input_data mandatoryField"
                name="question"
                placeholder="Question"
                value={editState.question}
                onChange={handleChange}
              />
              <div className="errorDesc"></div>
            </div>
            <div className="col-md-12 p-0 d-flex form-group">
              <div className="col-md-6 p-0 justify-content-start form-group flex-column mt-3 ">
                <select
                  name="requirement"
                  value={editState.requirement}
                  onChange={handleChange}
                  className="requirement input_data mandatoryField"
                  style={{ width: "95%", height: 40 }}
                >
                  <option value="">Choose Requirement</option>

                  <option value="Mandatory">Mandatory</option>
                  <option value="optional">Optional</option>
                </select>
                <div className="errorDesc"></div>
              </div>
              <div className="col-md-6 p-0 d-flex form-group">
                <div className="col-md-12 p-0 d-flex justify-content-start form-group flex-column">
                  <Input
                    type="text"
                    style={{ width: "95%", border: "1px solid black" }}
                    className="text-box-style my-3 py-2 input_data mandatoryField"
                    name="count"
                    placeholder="Min Word Count"
                    value={editState.count}
                    onChange={handleChange}
                  />
                  <div className="errorDesc"></div>
                </div>
              </div>
            </div>
            <div className="col-md-12 p-0 d-flex justify-content-center">
              <div
                className="py-1 mb-3 col-md-2 d-flex justify-content-center add-college-btn"
                onClick={() => {
                  addReqToCurrentCollageReq();
                }}
              >
                Add Essay Requirement
              </div>
            </div>

            <div>
              <div className="mb-2">
                {currentClgReqArr.length > 0 ? (
                  <div
                    className="d-flex col-md-12"
                    style={{ border: "1px solid #00000020" }}
                  >
                    <div
                      className="p-1 px-2 col-md-7"
                      style={{
                        fontWeight: "600",
                        alignSelf: "center",
                        borderRight: "1px solid #00000020",
                      }}
                    >
                      Question
                    </div>
                    <div
                      className="p-1 px-2 col-md-2"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #00000020",
                        borderLeft: "1px solid #00000020",
                      }}
                    >
                      Required or optional
                    </div>
                    <div
                      className="p-1 px-2 col-md-2"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #00000020",
                        borderLeft: "1px solid #00000020",
                      }}
                    >
                      Min word count
                    </div>
                    <div
                      className="p-1 px-2 col-md-1"
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      Action
                    </div>
                  </div>
                ) : null}

                {currentClgReqArr.map((essayReq) => {
                  return (
                    <div
                      id={essayReq.essayReqId}
                      className="d-flex col-md-12"
                      style={{ border: "1px solid #00000020" }}
                    >
                      <div
                        className="p-1 px-2 col-md-7"
                        style={{
                          alignSelf: "center",
                          borderRight: "1px solid #00000020",
                          alignItems: "center",
                        }}
                      >
                        {essayReq.question}
                      </div>
                      <div
                        className="p-1 px-2 col-md-2"
                        style={{
                          borderRight: "1px solid #00000020",
                          borderLeft: "1px solid #00000020",
                          alignItems: "center",
                        }}
                      >
                        {essayReq.requirement}
                      </div>
                      <div
                        className="p-1 px-2 col-md-2"
                        style={{
                          borderRight: "1px solid #00000020",
                          borderLeft: "1px solid #00000020",
                          alignItems: "center",
                        }}
                      >
                        {essayReq.length}
                      </div>
                      <div
                        className="p-1 px-2 col-md-1 d-flex"
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FaTrash
                          onClick={() => {
                            handleEssayDelete(essayReq);
                          }}
                          style={{
                            color: "#6C63FF",
                            cursor: "pointer",
                            paddingRight: 5,
                          }}
                        />
                        {/* <FaEdit
                          onClick={() => {editReqInCurrentCollageReq(essayReq)}}
                          style={{
                            color: "#6C63FF",
                            cursor: "pointer",
                            paddingLeft: 5,
                          }}
                        /> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </ModalComponent>

        <AlertModal
            show={showEssayDelete}
            onHide={() => setShowEssayDelete(false)}
            title='Alert!'
            message='Are you sure you want to delete this document?'
            onCancel={() => setShowEssayDelete(false)}
            onConfirm={() => deleteReqFromCurrentCollageReq()}
        />
      </div>
    </div>
  );
};

export default AdminEssay;
