import React, { useEffect, useState, useCallback } from "react";
import LoadingAnimation from "../../utils/loadingAnimation";
import SampleGraph from "../../assets/svg/samplegraph";
import BellIconBlue from "../../assets/svg/belliconblue";
import LorImg from "../../imageAssets/lorimg.png";
import SopImg from "../../imageAssets/sopimg.png";
import EssayImg from "../../imageAssets/essayimage.png";
import PlusIcon from "../../imageAssets/plusicon.png";
import ArrowDown from "../../imageAssets/arrowdown.png";
import ProfilePicSample from "../../imageAssets/profilepicsample.png";
import RadioButtonSelected from "../../imageAssets/radiobutonselected.png";
import RadioButtonUnSelected from "../../imageAssets/radiobutonunselected.png";
import RoundArrowRight from "../../imageAssets/roundArrowRight.png";
import RoundArrowLeft from "../../imageAssets/roundArrowLeft.png";
import GoogleMeet from "../../imageAssets/googleMeet.png";
import moment from "moment";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import axios from "axios";
import { MW_URL } from "../../config";
import { FaSearch } from "react-icons/fa";
import { findNotifDate, findNotiTimeAgeFun } from "../../modules/parserHelper";
import { Button, Input } from "reactstrap";
import ModalComponent from "../../utils/modalComponent";
import TodolistCouncellor from "../../components/todolistCouncellor";
import todolist from "../../components/todolist";
import { useHistory } from 'react-router-dom';
const HomeScreenCounsellor = () => {
  const createUniquieId = () => {
    const head = Date.now().toString(36);
    const tail = Math.random().toString(36).substr(2);
    return head + tail;
  };

  const [height, setHeight] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [notificationDiv, setnotificationDiv] = useState(false);
  const [todolist, settodolist] = useState([]);
  var [isevent, setIsEvent] = useState(false);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
  const [listOfStudents, setlistOfStudents] = useState([]);
  const [updatesList, setupdatesList] = useState([]);
  const [queriesList, setqueriesList] = useState([]);
  const [queryToFocus, setqueryToFocus] = useState(false);
  const [queryReply, setqueryReply] = useState("");
  const [studId, setStudId] = useState("");
  const [addTodoModalVisible, setaddTodoModalVisible] = useState(false);
  const [todolistMap, settodolistMap] = useState(null);
  const [todoInTextarea, settodoInTextarea] = useState("");
  const history = useHistory();
  const [copySuccess, setCopySuccess] = useState('');
  const [showSaveButtonForTodoUpdate, setshowSaveButtonForTodoUpdate] =
    useState(false);
  const setTodoToPassFun = useCallback(
    (todoListToSet) => {
      settodolist(todoListToSet);
    },
    [todolist]
  );
  useEffect(() => {
    var windowHeight = window.innerHeight;
    setHeight(windowHeight + "px");
    getAllEventsFun();
    getlistofusersFromDb();
    gettodo();
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [isUseEffectCallRequired]);
  var todayDate = moment().format("dddd/MMM/DD/YYYY");
  var today = todayDate.split("/");
  const defaultDateValue = {
    weekday: today[0],
    year: parseInt(today[3]),
    month: parseInt(today[2]),
    day: today[1],
  };

  const updateTodoList = (todoSelected) => {
    settodolist(
      todolist.map((todo) => {
        if (todo.id == todoSelected.id) {
          if (todo.selected) {
            return {
              ...todo,
              selected: false,
            };
          } else {
            return {
              ...todo,
              selected: true,
            };
          }
        } else {
          return {
            ...todo,
            selected: todo.selected,
          };
        }
      })
    );
  };
  const gettodo = () => {
    var selectedCollages = [];
    var selectedCollagesIds = [];
    axios
      .post(`${MW_URL}` + "getSelectedListOfCollages", {
        email: JSON.parse(localStorage.getItem("userData")).email,
      })
      .then((getSelectedListOfCollagesresponse) => {
        if (getSelectedListOfCollagesresponse.data[0].todoforcounsellor) {
          settodolist(
            getSelectedListOfCollagesresponse.data[0].todoforcounsellor
          );
        }
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };

  const getlistofusersFromDb = () => {
    axios
      .post(`${MW_URL}` + "users", {})
      .then((getUsersResponse) => {
        if (
          getUsersResponse &&
          getUsersResponse.data &&
          getUsersResponse.data[0]
        ) {
          var userList = [];
          for (var i = 0; i < getUsersResponse.data.length; i++) {
            var currentObj = getUsersResponse.data[i];
            if (
              currentObj.counsellorId ===
              JSON.parse(localStorage.getItem("userData")).email
            ) {
              userList.push({
                key: currentObj.email,
                username: currentObj.username,
                email: currentObj.email,
                userType: currentObj.userType,
              });
            }
          }
          setlistOfStudents(userList);
        }
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };
  const getAllEventsFun = () => {
    // console.log("getAllEventsFun");
    axios
      .post(`${MW_URL}` + "getAllEvents", {})
      .then((getAllEventsResponse) => {
        setIsUseEffectCallRequired(false);
        var eventsList = [];
        getAllEventsResponse.data.map((event) => {
          if (
            event.eventGuests.indexOf(
              JSON.parse(localStorage.getItem("userData")).email
            ) != -1
          ) {
            eventsList.push({
              id: event.eventId,
              title: event.title,
              start: event.start,
              end: event.end,
            });
          }
        });
        setCurrentEvents(eventsList);
        axios
          .post(`${MW_URL}` + "getUpdates", {
            recipientId: JSON.parse(localStorage.getItem("userData")).email,
            senderId: false,
          })
          .then((getUpdatesResponse) => {
            console.log("getUpdatesResponse", getUpdatesResponse.data);
            setupdatesList(getUpdatesResponse.data);

            axios
              .post(`${MW_URL}` + "getQueriesByRecipientId", {
                recipientId: JSON.parse(localStorage.getItem("userData")).email,
                senderId: false,
              })
              .then((getQueriesByRecipientIdResponse) => {
                setqueriesList(getQueriesByRecipientIdResponse.data);
                setIsLoading(false);
              })
              .catch((err) => {
                console.log("err >> ", err);
              });
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };

  function handleScroll() {
    window.scroll({
      top: 0,
      right: 0,
      behavior: "smooth",
    });
  }

  function setQueryToFocusHandler(query) {
    // console.log(" setQueryToFocusHandler queryToFocus", queryToFocus);
    if (queryToFocus == query) {
      setqueryToFocus(false);
    } else {
      setqueryToFocus(query);
    }
  }
  const copyLink = () => {
    navigator.clipboard.writeText("http://10.0.13.70/?counsellorId="
     +JSON.parse(localStorage.getItem("userData")).email );
    setCopySuccess('Referal Link Copied!');
  }
  const queryReplyHandler = (e) => {
    const { name, value } = e.target;
    setqueryReply(value);
  };

  const queryReplyButtonHandler = () => {
    var dataToSend = {
      queryId: queryToFocus.queryId,
      replys: [queryReply],
      updateId: createUniquieId(),
      recipientId: queryToFocus.senderId,
      senderId: JSON.parse(localStorage.getItem("userData")).email,
      message:
        JSON.parse(localStorage.getItem("userData")).username +
        " replayed to your query",
    };
    if (dataToSend.replys[0]) {
      setIsLoading(true);
      axios
        .post(`${MW_URL}` + "updateQueryReply", dataToSend)
        .then((response) => {
          var status = "NA";
          if (response && response.data && response.data.STATUS)
            status = response.data.STATUS;
          if (status.toUpperCase() === "SUCCESS") {
            setIsLoading(false);
            window.location.reload();
          } else {
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    }
  };
  
  var student = [];
  listOfStudents.map((stud) => {
    // console.log("stud",stud)
    if (stud) {
      const students = {
        name: stud.username,
        id: stud.email,
      };
      student.push(students);
    }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStudId((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const addTodoModalHandler = (dataObject) => {
    if (dataObject.type === "cancel") {
      setaddTodoModalVisible(false);
    } else if (dataObject.type === "submit") {
      addtodoList();
    }
  };
  function addtodoList() {
    setIsLoading(true);
    setaddTodoModalVisible(false);
    var todolistToPass = [
      ...todolist,
      { id: createUniquieId(), title: todoInTextarea, selected: false },
    ];

    axios
      .post(`${MW_URL}` + "addtodoListCounsellor", {
        todoList: todolistToPass,
        email: JSON.parse(localStorage.getItem("userData")).email,
      })
      .then((response) => {
        settodolist([
          ...todolist,
          { id: createUniquieId(), title: todoInTextarea, selected: false },
        ]);
        settodoInTextarea("");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err");
      });
  }

  const edittodolist = async (todolistforselectedcollage) => {
    setIsLoading(true);
    axios
      .post(`${MW_URL}` + "addtodoListCounsellor", {
        todoList: todolistforselectedcollage,
        email: JSON.parse(localStorage.getItem("userData")).email,
      })
      .then((response) => {
        settodolist([...todolistforselectedcollage]);
        setshowSaveButtonForTodoUpdate(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err");
      });
  };
  var todolistToPass = [];
  // if (todolist != todolistMap.get( JSON.parse(localStorage.getItem()).email)) {
  //   // setTodoToPassFun(todolistMap.get(ColId.colid));
  //   settodolist(todolistMap.get( JSON.parse(localStorage.getItem()).email));
  //   console.log("settodolist");
  // }

  // todolistToPass = todolistMap.get( JSON.parse(localStorage.getItem()).email);
  // console.log("todolistToPass ====>> inside if colId");
  // console.log("studId",studId)
  return (
    <div>
      {isLoading ? <LoadingAnimation isVisible={true} /> : null}
      <ModalComponent
        isVisible={addTodoModalVisible}
        mainHeading="Add todo"
        cancelButtonText="Cancel"
        submitButtonText="Add"
        callback={addTodoModalHandler}
      >
        <div className="pt-2 pb-5">
          <textarea
            value={todoInTextarea}
            placeholder="TYPE YOUR TODO HERE"
            onChange={(event) => {
              settodoInTextarea(event.target.value);
            }}
            id="w3review"
            name="w3review"
            rows="4"
            cols="42"
          >
            Write a todo
          </textarea>
        </div>
      </ModalComponent>

      <div className="home-page-container pb-5" style={{ minHeight: height }}>
        <div
          className="col-md-12 pl-5 pt-5  d-flex justify-content-between"
          style={{ width: "100%" }}
        >
         {/* <div
            className="col-md-6 searchbox-style d-flex align-items-center"
            style={{ height: 30 }}
          >
             <div className="col-md-11">
              <input
                style={{ border: 0, width: "100%" }}
                type="text"
                id="search"
                name="search"
                placeholder="TYPE ''MICHAEL''"
              />
            </div>  
            
            <div className="col-md-1 p-0 m-0 d-flex justify-content-end">
              <FaSearch style={{ fontSize: 12 }} />
            </div>
          </div>*/}
          <div style={{cursor:"pointer"}}></div>
          <div className="col-md-3 p-0 pr-4 d-flex justify-content-end align-items-center text-right">
            <div className="pr-2 col-md-8">
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  textTransform: "uppercase",
                }}
              >
                {JSON.parse(localStorage.getItem("userData")).username}
              </div>
              <div style={{ fontSize: 7 }}>WELCOME BACK</div>
            </div>
            <div className="p-0 m-0 col-md-3" style={{ zIndex: 2 }}>
              {notificationDiv ? (
                <div
                  className={
                    notificationDiv
                      ? "col-md-12 m-0 p-0 animated fade-in-right"
                      : "col-md-12 m-0 p-0"
                  }
                >
                  <div
                    className="m-3 p-0 py-4 pl-4 card-design"
                    style={{
                      width: 240,
                      borderRadius: 15,
                      position: "absolute",
                      right: 0,
                    }}
                  >
                    {notificationRow()}
                    {notificationRow()}
                    {notificationRow()}
                    {notificationRow()}
                    {notificationRow()}
                  </div>
                </div>
              ) : null}
              {/* <div className="col-md-12" style={{ zIndex: 2 }}>
                <div
                  className="d-flex justify-content-center"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    fontSize: 10,
                    width: 15,
                    height: 15,
                    position: "absolute",
                    right: "5%",
                    top: 4,
                    borderRadius: "50%",
                  }}
                >
                  5
                </div>
              </div> */}
              <img
                onClick={() => setnotificationDiv(!notificationDiv)}
                className="col-md-12 p-0"
                style={{ zIndex: 1, cursor: "pointer" }}
                height={60}
                src={ProfilePicSample}
                alt="dpsaple"
              />
            </div>
          </div>
        </div>
        <div className="pt-0 d-flex" style={{ zIndex: 0 }}>
          <div className="col-md-7">
            <div style={{}} className="px-3 d-flex justify-content-between">
              {lorComponent()}
              {essayComponent()}
            </div>
            <div className="pt-4 d-flex justify-content-end">
              <div className="col-md-4 p-0 pr-3">
                <div className="heaidng-style">updates</div>
                <select
                  className="opacity selstyl"
                  style={{
                    width: "80%",
                    marginLeft: "5px",
                    marginBottom: "5px",
                    marginTop: "5px",
                  }}
                  name="studid"
                  id="studid"
                  // className="opacity spacing"
                  onChange={handleChange}
                  defaultValue={""}
                >
                  <option value="">View all Update</option>
                  {student.map((stud) => {
                    // console.log("std",stud)
                    return (
                      <option key={stud.id} value={stud.id}>
                        {stud.name}
                      </option>
                    );
                  })}
                </select>

                <div
                  className="col-md-12 p-0 pt-2 mt-4 card-design-square"
                  style={{ maxHeight: 300, overflow: "scroll" }}
                >
                  {updatesList.map((update) => {
                    if (update) {
                      // console.log("id",studId.studid)
                      if (studId.studid == "" || studId.studid == undefined) {
                        if (update.updateDetails) {
                          return updatesComponent(update);
                        }
                      } else if (studId.studid) {
                        if (update.senderId == studId.studid) {
                          if (update.updateDetails) {
                            return updatesComponent(update);
                          }
                        }
                      }
                    }
                  })}
                </div>
              </div>
              <div className="col-md-4 p-0 px-2">
                <div>
                  <div className="col-md-8 p-0 m-0 heaidng-style">to-do</div>
                  <div className="col-md-12 p-4 mt-4 card-design-square">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="p-0 d-flex align-items-center">
                        <div className="p-1 dateStyleBig">
                          {defaultDateValue.month}
                        </div>
                        <div className="p-0 text-uppercase dateStyleSmall">
                          <div>{defaultDateValue.day}</div>
                          <div>{defaultDateValue.year}</div>
                        </div>
                      </div>
                      <div className=" text-uppercase dateStyleSmall">
                        {defaultDateValue.weekday}
                      </div>
                    </div>
                    <div className="pt-2">
                      {/* {console.log("abc", todolist)} */}
                      <TodolistCouncellor
                        todolist={todolist}
                        updateTodoList={updateTodoList}
                        setaddTodoModalVisible={() => {
                          setaddTodoModalVisible(true);
                        }}
                        showSaveButtonForTodoUpdate={
                          showSaveButtonForTodoUpdate
                        }
                        setshowSaveButtonForTodoUpdate={(val) => {
                          setshowSaveButtonForTodoUpdate(val);
                        }}
                        edittodolist={edittodolist}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            {queryComponent()}
            <div className="p-0 pt-4">
              {queryToFocus == false ? (
                <div className="heaidng-style">Scheduled</div>
              ) : null}

              {queryToFocus == false ? (
                <div
                  className="col-md-12 p-0 pt-3"
                  style={{
                    maxHeight: (window.innerHeight * 43) / 100 + "px",
                    overflow: "scroll",
                  }}
                >
                  {currentEvents.map((event) => {
                    var currentDate = new Date();
                    currentDate = currentDate.setDate(
                      currentDate.getDate() + 2
                    );
                    var startDate = moment(event.start, "YYYY-MM-DD");
                    isevent =
                      startDate.isSameOrAfter(new Date(), "day") &&
                      startDate.isSameOrBefore(currentDate, "day");
                    if (isevent) {
                      return (
                        <div
                          key={event.id}
                          className="col-md-12 px-4 py-2 d-flex align-items-center justify-content-between schedule-box mb-3"
                        >
                          <div>
                            <div className="normal-text">
                              {formatDate(event.start, {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              })}
                            </div>
                            <div className="small-text">{event.title}</div>
                            <div className="extra-small-text">
                              {formatDate(event.start, {
                                hour: "numeric",
                                minute: "numeric",
                              }) +
                                " - " +
                                formatDate(event.end, {
                                  hour: "numeric",
                                  minute: "numeric",
                                })}
                            </div>
                          </div>
                          {event.googleMeetId != "false" ? (
                            <img
                              className="pr-1"
                              height={25}
                              src={GoogleMeet}
                              style={{ cursor: "pointer" }}
                              alt="googlemeet"
                              onClick={() => {
                                window.open(
                                  "https://meet.google.com/lookup/" +
                                  event.googleMeetId,
                                  "_blank"
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      );
                    }
                  })}
                </div>
              ) : (
                queryFocus(queryToFocus)
              )}
            </div>
            <div>
              <Button
                style={{ backgroundColor: "#6C63FF" }}
                onClick={() => copyLink()}
              >
                Copy Referal Link
              </Button>
            </div>
            {copySuccess}
          </div>
        </div>
        <div className="col-md-12 d-flex justify-content-center"></div>
      </div>
    </div>
  );

  function notificationRow(
    head = "TRENDING",
    content = "Ivy league schools make SAT scores optional"
  ) {
    return (
      <div className="col-md-12 p-2 pl-2">
        <div className="trending-head text-left">{head}</div>
        <div className="col-md-4 divider-bar"></div>
        <div
          className="col-md-8 m-0 p-0 pt-1 text-left"
          style={{ fontSize: 10, lineHeight: 1 }}
        >
          {content}
        </div>
      </div>
    );
  }

  function lorComponent() {
    return (
      <div className="col-md-5  m-4 overflow-hidden card-design d-flex">
        <div className="col-md-12 p-0 d-flex">
          <div
            className="col-md-8 m-0 p-0 d-flex align-items-center"
            style={{ height: "100%" }}
          >
            <div className="col-md-12 p-0 pl-3 d-flex justify-content-center flex-column">
              <div className="m-0 p-0 lor-head">SOP</div>
              <div className="col-md-8 p-0 m-0 d-flex justify-content-between">
                <div className="col-md-4 p-0 m-0 progress-bar"></div>
                <div className="col-md-7 p-0 m-0" style={{ fontSize: 6 }}>
                  50% REMAINING
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-0" style={{}}>
            <div className="col-md-12 p-0">
              <img height={140} src={LorImg} alt="lorimg" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function essayComponent() {
    return (
      <div className="col-md-5  m-4 overflow-hidden card-design d-flex">
        <div className="col-md-12 p-0 d-flex">
          <div
            className="col-md-8 m-0 p-0 d-flex align-items-center"
            style={{ height: "100%" }}
          >
            <div className="col-md-12 p-0 pl-3 d-flex justify-content-center flex-column">
              <div className="m-0 p-0 lor-head">ESSAY</div>
              <div className="col-md-8 p-0 m-0 d-flex justify-content-between">
                <div className="col-md-4 p-0 m-0 progress-bar"></div>
                <div className="col-md-7 p-0 m-0" style={{ fontSize: 6 }}>
                  50% REMAINING
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-0" style={{}}>
            <div className="col-md-12 p-0">
              <img
                style={{ position: "absolute", left: -50 }}
                height={140}
                src={EssayImg}
                alt="essayimg"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function queryComponent() {
    return (
      <div className="overflow-hidden">
        <div className="d-flex justify-content-between align-items-center">
          <div className="col-md-6 d-flex">
            <div className="headings-style pr-2">queries</div>
            <div className="col-md-12 p-0">
              <img height={6} src={ArrowDown} alt="arrowdown" />
            </div>
          </div>
          <div className="col-md-4  p-0 ">
            <div className="d-flex justify-content-end align-items-center">
              <div
                onClick={() => {
                  handleScroll();
                  document.getElementById("queryDiv").scrollLeft -= 20;
                }}
                style={{ cursor: "pointer" }}
              >
                <img
                  className="pr-1"
                  height={20}
                  src={RoundArrowLeft}
                  alt="statsimg"
                />
              </div>
              <div
                onClick={() => {
                  handleScroll();
                  document.getElementById("queryDiv").scrollLeft += 20;
                }}
                style={{ cursor: "pointer" }}
              >
                <img height={20} src={RoundArrowRight} alt="statsimg" />
              </div>
            </div>
          </div>
        </div>
        <div
          id="queryDiv"
          className="col-md-12 p-0 mt-1 d-flex"
          style={{ overflow: "scroll" }}
        >
          {queriesList.map((query) => {
            return queryBox(query);
          })}
        </div>
      </div>
    );
  }

  function queryBox(query) {
    return (
      <div
        key={query.queryId}
        className="col-md-4 px-3 py-2 pb-4 mr-2 schedule-box mb-3"
        onClick={() => {
          setQueryToFocusHandler(query);
        }}
      >
        <div className="d-flex">
          <img height={30} src={ProfilePicSample} alt="profilepic" />
          <div>
            <div style={{ fontSize: 8, fontWeight: 800, textAlign: "left" }}>
              {query.senderName}
            </div>
            <div
              style={{
                fontSize: 8,
                fontWeight: 400,
                textAlign: "left",
                color: "grey",
              }}
            >
              {query.senderName}
            </div>
          </div>
        </div>
        <div style={{ fontSize: 8, fontWeight: 400, textAlign: "left" }}>
          {query.message}
        </div>
      </div>
    );
  }

  function queryFocus(query) {
    // console.log("query.replys", query.replys.length);
    return (
      <div className="col-md-12 px-3 py-2 pb-4 mr-2 mb-3">
        <div style={{ fontSize: 12, fontWeight: 800, textAlign: "left" }}>
          {query.message}
        </div>

        {query.replys.length > 0 ? (
          <div style={{ fontSize: 12, fontWeight: 200, textAlign: "left" }}>
            {query.replys[0]}
          </div>
        ) : (
          <div className="col-md-12 p-0 d-flex form-group">
            <div className="col-md-10 p-0 d-flex justify-content-start form-group flex-column">
              <Input
                type="text"
                style={{ width: "90%", textAlign: "center" }}
                className="text-box-style py-2 input_data mandatoryField inputstyl"
                name="Descriptions"
                placeholder="Type Reply"
                value={queryReply}
                onChange={queryReplyHandler}
              />
              <div className="errorDesc"></div>
            </div>
            {queryReply ? (
              <div
                style={{
                  color: "#6C62F6",
                  fontSize: 12,
                  fontWeight: 800,
                  cursor: "pointer",
                }}
                onClick={queryReplyButtonHandler}
              >
                reply
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }

  function updatesComponent(update) {
    return (
      <div
        key={update.updateId}
        onClick={() => {
          if (update.updateDetails.type == "essay") {
            history.push(
              "/counsellorStudents?id=" +
              update.updateDetails.essayId +
              "&studentEmail=" +
              update.senderId
            );
          } else if (update.updateDetails.type == "query") {
            var querytoset = queriesList.find(
              (query) => query.queryId == update.updateDetails.queryId
            );
            setQueryToFocusHandler(querytoset);
          }
        }}
        className="d-flex justify-content-start align-items-center p-2"
        style={{ borderBottom: "1px solid #00000020", cursor: "pointer" }}
      >
        <img height={30} src={ProfilePicSample} alt="profilepic" />
        <div className="pl-2">
          <div style={{ fontSize: 8, fontWeight: 600 }}>{update.message}</div>
          <div style={{ fontSize: 8, color: "grey" }}>
            {findNotiTimeAgeFun(update.notiTimeAgo)}
          </div>
        </div>
      </div>
    );
  }
};

export default HomeScreenCounsellor; 
