import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from 'axios'
import moment from "moment";
import { MW_URL } from "../../../../config";


const Updates = () => {

    const [updatesList, setupdatesList] = useState([]);
    const [essayList, setessayList] = useState([]);


    useEffect(() => {
        // getEssays();
        getUpdates();
    }, []);

    const getUpdates = () => {
        axios.post(`${MW_URL}` + "getUpdates", {
            recipientId: JSON.parse(
                localStorage.getItem("userData")
            ).email,
            senderId: false,
        })
        .then((getUpdatesResponse) => {
            setupdatesList(getUpdatesResponse.data);
            console.log(getUpdatesResponse.data, "updates")
        })
    }

    // const getEssays = () => {
    //     axios
    //         .post(`${MW_URL}` + "getEssays", {
    //             ownerId: JSON.parse(localStorage.getItem("userData")).email,
    //         })
    //         .then((getAllEssaysResponse) => {
    //             console.log(getAllEssaysResponse, "getAllEssaysResponse")
    //             setessayList(getAllEssaysResponse.data)
    //         })
    // }

    // const Feedback = (essayId) => {
    //     const essay = essayList?.find((essay) => essay?.essayId === essayId);
    //     return essay?.feedbacks?.length || 0;
    // }


  return (
    <div className='dashStats'>
        <h3 className='dashHead3'>Updates</h3>
        <div className="updateSec">
        {updatesList.length > 0 ? updatesList.map((obj, index) => (
            <div id={index} className='dashTodoCollage '>
                <div className="row">
                    <div className="col-sm-8 ">
                        <div className='row'>
                            <div className='col-sm-3'>
                                <div className='updateSquareBg'></div>
                            </div>
                            <div className='col-sm-9 '>
                                <p className='dashNormalText2 m-0'>{obj.updateDetails?.type}</p>
                                <p className='dashLightText m-0'>{obj.message}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 d-flex justify-content-end ">
                        <div className='textEnd'>
                            {/* <p className=' dashNormalText m-0 '>Edits : 10</p> */}
                            {/* {obj?.updateDetails?.type === "essay" && (
                                <p className='dashNormalText m-0'>FeedBack : {Feedback(obj?.updateDetails?.essayId)}</p>
                            )} */}
                            <p className="queryLightText">{moment(obj.updatedDate).format('YYYY-MM-DD')}</p>
                        </div>
                    </div>
                </div>
            </div>
        )) : <p className="dashNormalText2">No updates</p>
        }
        </div>

    </div>
  )
}

export default Updates