import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from 'axios'
import { MW_URL } from "../../../../config";
import moment from "moment";


const TimelineSec = () => {

    const [updatesList, setupdatesList] = useState([]);


    useEffect(() => {
        getUpdates();
    }, []);

    const getUpdates = () => {
        axios.post(`${MW_URL}` + "getUpdates", {
            recipientId: JSON.parse(
                localStorage.getItem("userData")
            ).email,
            senderId: false,
        })
        .then((getUpdatesResponse) => {
            setupdatesList(getUpdatesResponse.data);
            console.log(getUpdatesResponse.data, "updates")
        })
    }


    const EssayStatusComponent = ({ essayId }) => {
    const [essayStatus, setEssayStatus] = useState("Loading..."); 

    useEffect(() => {
        const fetchEssayStatus = async () => {
            try {
                const ownerId = JSON.parse(localStorage.getItem("userData")).email;
                const getAllEssaysResponse = await axios.post(`${MW_URL}` + "getEssays", {
                ownerId: ownerId,
                });

                const essay = getAllEssaysResponse.data.find((obj) => obj.essayId === essayId);
                if (essay) {
                setEssayStatus(essay.isApproved ? "Approved" : "Not Approved");
                } else {
                setEssayStatus("Not Approved");
                }
            } catch (error) {
                console.error("Error fetching essays:", error);
            }
            };

            fetchEssayStatus();
        }, [essayId]);

            // Render nothing if the status is still "Loading..."
            if (essayStatus === "Loading...") {
                return null;
            }

            return <td className='timelinetabledata'>{essayStatus}</td>;
        };




  return (
    <div className='py-3 studCollBottonWrap'>
            <table className="m-3 w-100">
                <thead className='timelineThead'>
                    <tr>
                        <th className=''>Date</th>
                        <th className='p-1'>Activity</th>
                        <th className=''>Status</th>
                        <th className='p-1'>Comments</th>
                    </tr>
                </thead>
                <tbody className='timelineTbody'>
                    {updatesList?.map((obj, index) => (
                         <tr key={index}>
                            <td className='timelinetabledata'>{moment(obj.updatedDate).format('DD/MM/yyyy')}</td>
                            <td className='py-2 timelinetabledata'>{obj.updateDetails?.type}</td>
                            {obj?.updateDetails?.type === "essay" && <EssayStatusComponent essayId={obj?.updateDetails?.essayId} />}
                            {obj?.updateDetails?.type === "query" && ( <td className='timelinetabledata'>Completed</td>)}
                            <td className='py-2 '>{obj.message}</td>
                        </tr>
                    ))}
                   
                </tbody>
            </table>
    </div>
  )
}

export default TimelineSec