import React, { useEffect, useState, useRef, useCallback } from "react";
import LoadingAnimation from "../../utils/loadingAnimation";
import BellIconBlue from "../../assets/svg/belliconblue";
import LorImg from "../../imageAssets/lorimg.png";
import SopImg from "../../imageAssets/sopimg.png";
import EssayImg from "../../imageAssets/essayimage.png";
import ProfilePicSample from "../../imageAssets/profilepicsample.png";
import axios from "axios";
import { MW_URL } from "../../config";
import { findNotifDate, findNotiTimeAgeFun } from "../../modules/parserHelper";
import DocumentBg from "../../imageAssets/documentbg.png";
import RightArrow from "../../imageAssets/rightarrow.png";
import StatsImg from "../../imageAssets/statsimg.png";
import moment from "moment";
import { formatDate } from "@fullcalendar/react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import Collapsible from "../../components/collapsible";
import ProgressCirclePart from "../../components/progresscircle";
import TodoList from "../../components/todolist";
import emailjs from "@emailjs/browser";
import ValidateForm from "../../utils/formValidation";
import GoogleMeet from "../../imageAssets/googleMeet.png";
import { FaFilter, FaCodeBranch, FaQuestion, FaTimes } from "react-icons/fa";
import ModalComponent from "../../utils/modalComponent";
import { Input, Button } from "reactstrap";
import { FaAngleLeft, FaAngleRight, FaUpload } from "react-icons/fa";
import { Accordion, Card } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
const HomeScreen = () => {
  const createUniquieId = () => {
    const head = Date.now().toString(36);
    const tail = Math.random().toString(36).substr(2);
    return head + tail;
  };
  const calendarRef = useRef();
  const [height, setHeight] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [notificationDiv, setnotificationDiv] = useState(false);
  const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
  const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState(
    []
  );
  const [docId, setDocumentId] = useState([]);
  const [uploadedDoc, setUploadedDocPayload] = useState([]);
  const [collageFilterCollageId, setcollageFilterCollageId] = useState("");
  const [essaysList, setessaysList] = useState([]);
  const [essaysTemplateList, setessaysTemplateList] = useState([]);
  const [updatesList, setupdatesList] = useState([]);
  var [ColId, setColId] = useState([]);
  var [ColIdSort, setColIdSort] = useState([]);
  var [Col, setCol] = useState([]);
  const [newsList, setNewsDetailsPayload] = useState([]);
  var [isevent, setIsEvent] = useState(false);
  var [count, setCount] = useState(1);
  const [mailModalOpen, setMailModalOpen] = useState(false);
  const [faqQuestionsDiv, setfaqQuestionsDiv] = useState(false);
  const [mainComponent, setmainComponent] = useState(true);
  const [todolist, settodolist] = useState([]);
  const setTodoToPassFun = useCallback(
    (todoListToSet) => {
      settodolist(todoListToSet);
    },
    [todolist]
  );
  //{"id":1,"title":"todo1","selected":false},{"id":2,"title":"todo2","selected":true},{"id":3,"title":"todo3","selected":false}
  const [todolistMap, settodolistMap] = useState(null);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [counsellorSuggestionList, setcounsellorSuggestionList] = useState([]);
  const [counsellorNameSelected, setcounsellorNameSelected] = useState("");
  const [counsellorEmailSelected, setcounsellorEmailSelected] = useState("");
  const [queriesList, setqueriesList] = useState([]);
  const [docValidation, setValidation] = useState(false);
  const parentRef = React.createRef();
  const [durationToInputIntoTimerState, setdurationToInputIntoTimerState] =
    useState(0);
  const [
    initialRemainingTimeToInputIntoTimerState,
    setinitialRemainingTimeToInputIntoTimerState,
  ] = useState(0);
  const [prospectName, setprospectName] = useState("");
  var [events, setevent] = useState(false);
  const [addTodoModalVisible, setaddTodoModalVisible] = useState(false);
  const [todoInTextarea, settodoInTextarea] = useState("");
  const [showSaveButtonForTodoUpdate, setshowSaveButtonForTodoUpdate] =
    useState(false);
  const [recommendationArray, setrecommendationArray] = useState([]);
  const [resumefromdb, setResume] = useState([]);
  useEffect(() => {
    var windowHeight = window.innerHeight;
    setHeight(windowHeight + "px");
    getAllEssays();
    // getInitialDashboardDetails();
  }, []);
  const [editState, setEditState] = React.useState({
    emailId: "",
    resume: "",
  });
  const [queryForCouncellor, setqueryForCouncellor] = React.useState("");
  const history = useHistory();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleIncrement = () => {
    setCount((count = count + 1));
  };

  const handleDecrement = () => {
    setCount((count = count - 1));
  };
  if (count < 1) {
    setCount(45);
  }
  if (count > 45) {
    setCount(1);
  }
  var todayDate = moment().format("dddd/MMM/DD/YYYY");
  var today = todayDate.split("/");
  const defaultDateValue = {
    weekday: today[0],
    year: parseInt(today[3]),
    month: parseInt(today[2]),
    day: today[1],
  };
  // setInterval( count=count+1, 3000);
  const actionMailBtnClicked = () => {
    setMailModalOpen(true);
  };
  const questionBtnClicked = () => {
    setfaqQuestionsDiv(true);
    setmainComponent(false);
    getlistofusersFromDb();
  };
  const componentsHandle = () => {
    setfaqQuestionsDiv(false);
    setmainComponent(true);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      setCount(count + 1);
    }, 7200);
  }, []);
  const getAllEssays = () => {
    setIsLoading(true);
    var selectedCollages = [];
    var selectedCollagesIds = [];
    axios
      .post(`${MW_URL}` + "getSelectedListOfCollages", {
        email: JSON.parse(localStorage.getItem("userData")).email,
      })
      .then((getSelectedListOfCollagesresponse) => {
        setResume(getSelectedListOfCollagesresponse.data[0].resume);
        if (
          getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
            .length > 0
        ) {
          getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
            (collage) => {
              selectedCollages.push(collage);
              selectedCollagesIds.push(collage.collageId);
            }
          );
          setlistOfSelectedCollagesArr(selectedCollages);

          if (getSelectedListOfCollagesresponse.data[0].todoList) {
            var mapToSet = new Map(
              Object.entries(getSelectedListOfCollagesresponse.data[0].todoList)
            );
            selectedCollages.map((colgSelected) => {
              if (mapToSet.get(colgSelected.collageId) == null)
                mapToSet.set(colgSelected.collageId, []);
            });
            settodolistMap(mapToSet);
          } else {
            let todoMap = new Map();
            selectedCollages.map((colgSelected) => {
              if (todoMap.get(colgSelected.collageId) == null)
                todoMap.set(colgSelected.collageId, []);
            });
            settodolistMap(todoMap);
          }

          var templateArr = [];
          axios
            .post(`${MW_URL}` + "getEssays", {
              ownerId: JSON.parse(localStorage.getItem("userData")).email,
            })
            .then((getAllEssaysResponse) => {
              setIsUseEffectCallRequired(false);
              var essaysListToPush = [];
              var essaysRequirementIds = [];
              getAllEssaysResponse.data.map((essay) => {
                if (
                  essay.ownerId ==
                    JSON.parse(localStorage.getItem("userData")).email &&
                  selectedCollagesIds.includes(essay.collageId)
                ) {
                  essaysListToPush.push({
                    essayId: essay.essayId,
                    content: essay.content,
                    typeOfDocument: essay.typeOfDocument,
                    collageId: essay.collageId,
                    ownerId: essay.ownerId,
                    feedbacks: essay.feedbacks,
                    essayReqId: essay.essayReqId,
                    updatedDate: essay.updatedDate,
                    isApproved: essay.isApproved,
                  });
                  essaysRequirementIds.push(essay.essayReqId);
                }
              });
              setessaysList(essaysListToPush);
              defaultDropdownSet(selectedCollages);
              axios
                .post(`${MW_URL}` + "get_essay_requirement", selectedCollages)
                .then((getEssaysTemplateResponse) => {
                  var status = "NA";
                  if (
                    getEssaysTemplateResponse &&
                    getEssaysTemplateResponse.data &&
                    getEssaysTemplateResponse.data.STATUS
                  )
                    status = getEssaysTemplateResponse.data.STATUS;
                  if (status.toUpperCase() === "SUCCESS") {
                    setessaysTemplateList(getEssaysTemplateResponse.data.data);
                    getEssaysTemplateResponse.data.data.map(
                      (templateRes, temploopindex) => {
                        if (templateRes.essayRequirements.length != 0) {
                          templateRes.essayRequirements.map((esaaReq) => {
                            templateArr.push({
                              template: esaaReq.question,
                              collageId: templateRes.collageId,
                              name: templateRes.name,
                              essayReqId: esaaReq.essayReqId,
                              length: esaaReq.length,
                              requirement: esaaReq.requirement,
                              answered: essaysRequirementIds.includes(
                                esaaReq.essayReqId
                              ),
                            });
                          });
                        }
                        if (
                          getEssaysTemplateResponse.data.data.length ==
                          temploopindex + 1
                        ) {
                          axios
                            .post(`${MW_URL}` + "getUpdates", {
                              recipientId: JSON.parse(
                                localStorage.getItem("userData")
                              ).email,
                              senderId: false,
                            })
                            .then((getUpdatesResponse) => {
                              setupdatesList(getUpdatesResponse.data);
                              setessaysTemplateList(templateArr);

                              axios
                                .post(
                                  `${MW_URL}` + "documentsbyuser",
                                  selectedCollages
                                )
                                .then((documentsbyuserresponse) => {
                                  //   console.log("documentsbyuser", documentsbyuserresponse);
                                  var documentId = [];
                                  var documentsByUserResFromApi =
                                    documentsbyuserresponse;
                                  documentsbyuserresponse.data.map((ids) => {
                                    {
                                      documentId.push(ids);
                                    }
                                  });
                                  var requredDoc = [];
                                  setDocumentId(documentId);
                                  documentId.map((doc) => {
                                    requredDoc.push({
                                      DocId: doc.documentId,
                                      CollageId: doc.collageId,
                                    });
                                  });
                                  axios
                                    .post(
                                      `${MW_URL}` + "getAllStudentsDocsCount",
                                      {
                                        email: JSON.parse(
                                          localStorage.getItem("userData")
                                        ).email,
                                      }
                                    )
                                    .then((getStudDOcResponse) => {
                                      setIsUseEffectCallRequired(false);
                                      var allDetail = [];
                                      if (
                                        getStudDOcResponse &&
                                        getStudDOcResponse.data &&
                                        getStudDOcResponse.data[0]
                                      ) {
                                        var DocMapArrTemp = [];
                                        var DocMapObjTemp = [];
                                        getStudDOcResponse.data.map(
                                          (documentsCollageMap) => {
                                            requredDoc.map((reqDocId) => {
                                              if (
                                                documentsCollageMap.documentId.includes(
                                                  reqDocId.DocId
                                                ) &&
                                                documentsCollageMap.collegeId.includes(
                                                  reqDocId.CollageId
                                                )
                                              ) {
                                                DocMapObjTemp.push({
                                                  doc: documentsCollageMap.documentId,
                                                });
                                              }
                                            });
                                            if (
                                              DocMapObjTemp.doc ==
                                              requredDoc.DocId
                                            ) {
                                              DocMapArrTemp.push(DocMapObjTemp);
                                            }
                                          }
                                        );
                                        // console.log("collageDocMapArrTemp",collageDocMapArrTemp);
                                        setUploadedDocPayload(DocMapArrTemp[0]);
                                      }
                                      getNewsFromApiAndLorcall();
                                    });
                                });
                            })

                            .catch((err) => {
                              console.log("err >> ", err);
                            })
                            .catch((err) => {
                              console.log("err >> ", err);
                            })
                            .catch((err) => {
                              console.log("err >> ", err);
                            })
                            .catch((err) => {
                              console.log("err >> ", err);
                            });
                        }
                      }
                    );
                  } else {
                    getNewsFromApiAndLorcall();
                  }
                })
                .catch((err) => {
                  console.log("err >> ", err);
                });
            })
            .catch((err) => {
              console.log("err >> ", err);
            });
        } else {
          getNewsFromApiAndLorcall();
        }
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };

  function getNewsFromApiAndLorcall() {
    axios
      .post(`${MW_URL}` + "getRecommendationByStudentId", {
        studentId: JSON.parse(localStorage.getItem("userData")).email,
      })
      .then((recByIdRes) => {
        // console.log("recByIdREs", recByIdRes);
        if (
          recByIdRes &&
          recByIdRes.data &&
          recByIdRes.data &&
          recByIdRes.data.STATUS &&
          recByIdRes.data.STATUS == "SUCCESS"
        ) {
          // console.log("recByIdREs", recByIdRes.data.data);
          setrecommendationArray(recByIdRes.data.data);
        }
        axios
          .post(`${MW_URL}` + "getNewsFromApi", {})
          .then((getNewsResponse) => {
            console.log("getNews", getNewsResponse);
            setIsUseEffectCallRequired(false);
            var allNewsDetail = [];
            if (
              getNewsResponse &&
              getNewsResponse.data.articles &&
              getNewsResponse.data.articles[0]
            ) {
              for (var i = 0; i < getNewsResponse.data.articles.length; i++) {
                var currentObj = getNewsResponse.data.articles[i];
                var tempObj = {
                  author: currentObj.author,
                  content: currentObj.content,
                  description: currentObj.summary,
                  publishedat: currentObj.publishedAt,
                  // source: currentObj.source.name,
                  title: currentObj.title,
                  url: currentObj.link,
                };
                allNewsDetail.push(tempObj);
              }
            }else{
                setIsLoading(false);
            }
            setIsLoading(false);
            setNewsDetailsPayload(allNewsDetail);
            getAllEventsFun();
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  }
  const getAllEventsFun = () => {
    // console.log("getAllEventsFun");
    axios
      .post(`${MW_URL}` + "getAllEvents", {})
      .then((getAllEventsResponse) => {
        setIsLoading(false);
        setIsUseEffectCallRequired(false);
        var eventsList = [];
        getAllEventsResponse.data.map((event) => {
          if (
            event.eventGuests.indexOf(
              JSON.parse(localStorage.getItem("userData")).email
            ) != -1
          ) {
            eventsList.push({
              id: event.eventId,
              title: event.title,
              start: event.start,
              end: event.end,
              googleMeetId: event.googleMeetId,
              description: event.description,
              eventGuests: event.eventGuests,
              eventHostId: event.eventHostId,
            });
          }
        });
        setCurrentEvents(eventsList);
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };
  const mailModalHandler = (dataObject) => {
    if (dataObject.type === "cancel") {
      setMailModalOpen(false);
      setEditState({
        emailId: "",
      });
    } else if (dataObject.type === "submit") {
      setIsLoading(true);
      var dataToSend = {
        emailId: editState.emailId,
        studentId: JSON.parse(localStorage.getItem("userData")).email,
        studentName: JSON.parse(localStorage.getItem("userData")).username,
        updateRecipientId: JSON.parse(localStorage.getItem("userData"))
          .counsellorId,
      };

      axios
        .post(`${MW_URL}` + "lorMailHandler", dataToSend)
        .then((lorMailHandlerRes) => {
          // console.log("lorMailHandlerRes====>", lorMailHandlerRes);
          if (
            lorMailHandlerRes &&
            lorMailHandlerRes.data &&
            lorMailHandlerRes.data.STATUS === "FINISHED"
          ) {
            setEditState({
              emailId: "",
            });

            axios
              .post(`${MW_URL}` + "getRecommendationByStudentId", {
                studentId: JSON.parse(localStorage.getItem("userData")).email,
              })
              .then((recByIdRes) => {
                setMailModalOpen(false);
                setIsLoading(false);
                // console.log("recByIdREs", recByIdRes);
                if (
                  recByIdRes &&
                  recByIdRes.data &&
                  recByIdRes.data &&
                  recByIdRes.data.STATUS &&
                  recByIdRes.data.STATUS == "SUCCESS"
                ) {
                  // console.log("recByIdREs", recByIdRes.data.data);
                  setrecommendationArray(recByIdRes.data.data);
                }
              })
              .catch((err) => {
                console.log("err >> ", err);
              });
          } else {
            console.log("HERE");
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    }
  };

  const handleChange = (colid) => {
    setColId({ colid });
  };
  const handleChangeColid = (colid) => {
    setColIdSort({ colid });
  };

  // get users list from db and filter out all counsellors to show in faq form
  const getlistofusersFromDb = () => {
    setIsLoading(true);
    axios
      .post(`${MW_URL}` + "users", {})
      .then((getUsersResponse) => {
        if (
          getUsersResponse &&
          getUsersResponse.data &&
          getUsersResponse.data[0]
        ) {
          var counsellorSuggList = [];
          for (var i = 0; i < getUsersResponse.data.length; i++) {
            var currentObj = getUsersResponse.data[i];
            if (currentObj.userType === "counsellor") {
              counsellorSuggList.push({
                key: currentObj.email,
                username: currentObj.username,
                email: currentObj.email,
                userType: currentObj.userType,
              });
            }
          }
          // console.log("userList", counsellorSuggList);

          setcounsellorSuggestionList(counsellorSuggList);

          axios
            .post(`${MW_URL}` + "getQueriesBysenderId", {
              senderId: JSON.parse(localStorage.getItem("userData")).email,
            })
            .then((getQueriesByRecipientIdResponse) => {
              setqueriesList(getQueriesByRecipientIdResponse.data);
              setIsLoading(false);
            })
            .catch((err) => {
              console.log("err >> ", err);
            });
        }
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };

  // handle change on the faq query input field
  const handleChangeQuery = (e) => {
    const { name, value } = e.target;
    setqueryForCouncellor(value);
  };

  // handle change on the counsellor select
  const handleChangeCounsellorSelect = (e) => {
    const { value, name } = e.target;
    // console.log(value);
    var counsellorDetails = value.split("+username+");
    setcounsellorEmailSelected(counsellorDetails[0]);
    setcounsellorNameSelected(counsellorDetails[1]);
  };

  //submit query form, add query into db.
  const onSubmitFaqQuery = () => {
    // if (queryForCouncellor != "" && counsellorEmailSelected != "") {

    if (ValidateForm.inputFieldMandatoryCheck("query", false)) {
      if (ValidateForm.inputFieldValueCompositionCheck("query", false))
        setIsLoading(true);
      var queryDataTosend = {
        queryId: createUniquieId(),
        recipientId: counsellorEmailSelected,
        recipientName: counsellorNameSelected,
        senderId: JSON.parse(localStorage.getItem("userData")).email,
        senderName: JSON.parse(localStorage.getItem("userData")).username,
        message: queryForCouncellor,
        updateId: createUniquieId(),
        recipientId: JSON.parse(localStorage.getItem("userData")).counsellorId,
        updateMessage:
          JSON.parse(localStorage.getItem("userData")).username +
          " added a query",
      };
      // console.log("queryDataTosend", queryDataTosend);

      axios
        .post(`${MW_URL}` + "addQuery", queryDataTosend)
        .then((response) => {
          var status = "NA";
          if (response && response.data && response.data.STATUS)
            status = response.data.STATUS;
          if (status.toUpperCase() === "SUCCESS") {
            setIsLoading(false);
            setcounsellorEmailSelected("");
            setcounsellorNameSelected("");
            setqueryForCouncellor("");
          } else {
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
      // }
      // else {
      //   console.log("fill all mandatory fields ");
      // }
    }
  };
  function sendEmail(e) {
    // console.log("localStorage.getItem(userData).username",JSON.parse(localStorage.getItem("userData")).username)
    // var inputF = document.getElementById("from_name");
    // inputF.setAttribute("value", JSON.parse(localStorage.getItem("userData")).email)
    setIsLoading(true);
    e.preventDefault(); //This is important, i'm not sure why, but the email won't send without it

    var templateParams = {
      name: "James",
      notes: "Check this out!",
    };

    // console.log("e.target", e.target.from_name);

    e.preventDefault(); //This is important, i'm not sure why, but the email won't send without it
    emailjs
      .sendForm(
        "service_uzw7g96", //YOUR_SERVICE_ID
        "template_1ugjqgm", //YOUR_TEMPLATE_ID
        e.target,
        "upzmlmKfZd98QTLai" //YOUR_USER_ID
      )
      .then(
        (result) => {
          setIsLoading(false);
          window.location.reload(); //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
        },
        (error) => {
          setIsLoading(false);
          console.log(error.text);
        }
      );

    // emailjs.send("service_uzw7g96", "template_f700ccd", templateParams).then(
    //   function (response) {
    //     console.log("SUCCESS!", response.status, response.text);
    //   },
    //   function (error) {
    //     console.log("FAILED...", error);
    //   }
    // );
  }

  const updateTodoList = (todoSelected) => {
    // console.log("todoSelected", todoSelected);
    setshowSaveButtonForTodoUpdate(true);
    settodolist(
      todolist.map((todo) => {
        if (todo.id == todoSelected.id) {
          if (todo.selected) {
            return {
              ...todo,
              selected: false,
            };
          } else {
            return {
              ...todo,
              selected: true,
            };
          }
        } else {
          return {
            ...todo,
            selected: todo.selected,
          };
        }
      })
    );
  };
  // function getInitialDashboardDetails() {
  //   setIsLoading(true);
  //   axios
  //     .post(`${MW_URL}` + "getUpdates", {
  //       recipientId: JSON.parse(localStorage.getItem("userData")).email,
  //       senderId: false,
  //     })
  //     .then((getUpdatesResponse) => {
  //       console.log("getUpdatesResponse", getUpdatesResponse);
  //       setupdatesList(getUpdatesResponse.data);
  //       setIsUseEffectCallRequired(false);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log("err >> ", err);
  //     });
  // }
  // const FULL_DASH_ARRAY = 283;
  // const WARNING_THRESHOLD = 10;
  // const ALERT_THRESHOLD = 5;

  // const COLOR_CODES = {
  //   info: {
  //     color: "green",
  //   },
  //   warning: {
  //     color: "orange",
  //     threshold: WARNING_THRESHOLD,
  //   },
  //   alert: {
  //     color: "red",
  //     threshold: ALERT_THRESHOLD,
  //   },
  // };

  // const TIME_LIMIT = 0;
  // let timePassed = 0;
  // let timeLeft = TIME_LIMIT;
  // let timerInterval = null;

  // console.log("timeleft", TIME_LIMIT);

  const addTodoModalHandler = (dataObject) => {
    if (dataObject.type === "cancel") {
      setaddTodoModalVisible(false);
    } else if (dataObject.type === "submit") {
      addtodoList();
    }
  };

  function addtodoList() {
    setIsLoading(true);
    setaddTodoModalVisible(false);
    var currentCollageTodoLIst = todolistMap.get(ColId.colid);
    var todolistToPass = new Map(
      todolistMap.set(ColId.colid, [
        ...currentCollageTodoLIst,
        { id: createUniquieId(), title: todoInTextarea, selected: false },
      ])
    );
    // console.log("todolistToPass", todolistToPass);
    axios
      .post(`${MW_URL}` + "addtodoList", {
        todoList: Object.fromEntries(todolistToPass),
        email: JSON.parse(localStorage.getItem("userData")).email,
      })
      .then((response) => {
        settodolistMap(
          new Map(
            todolistMap.set(ColId.colid, [
              ...currentCollageTodoLIst,
              { id: createUniquieId(), title: todoInTextarea, selected: false },
            ])
          )
        );
        settodoInTextarea("");
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err");
      });
  }

  const edittodolist = async (todolistforselectedcollage) => {
    setIsLoading(true);
    // console.log("todolistforselectedcollage", todolistforselectedcollage);
    var todolistToPass = new Map(
      todolistMap.set(ColId.colid, todolistforselectedcollage)
    );
    axios
      .post(`${MW_URL}` + "addtodoList", {
        todoList: Object.fromEntries(todolistToPass),
        email: JSON.parse(localStorage.getItem("userData")).email,
      })
      .then((response) => {
        settodolistMap(
          new Map(todolistMap.set(ColId.colid, todolistforselectedcollage))
        );
        setshowSaveButtonForTodoUpdate(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err");
      });
  };
  const uploadFile = async (e) => {
    const file = e.target.files[0];
    var fileExtension = file.name.split(".").pop();
    fileExtension = fileExtension.toUpperCase();
    if (fileExtension == "PDF") {
      const base64 = await convertBase64(file);
      setEditState({
        resume: base64,
      });
      setValidation(true);
    } else {
      toast.error("Please Upload PDF files", {
        autoClose: 1000,
      });
    }
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const uploadBtn = () => {
    if (docValidation) {
      var dataToSend = {
        resume: editState.resume,
        email: JSON.parse(localStorage.getItem("userData")).email,
      };
      // console.log("DatatoSent", dataToSend);
      setIsLoading(true);

      axios
        .post(`${MW_URL}` + "studentUploadresume", dataToSend)
        .then((getUploadDetailResponse) => {
          if (
            getUploadDetailResponse &&
            getUploadDetailResponse.data &&
            getUploadDetailResponse.data.STATUS === "SUCCESS"
          ) {
            setEditState({
              file: "",
            });
            axios
              .post(`${MW_URL}` + "getSelectedListOfCollages", {
                email: JSON.parse(localStorage.getItem("userData")).email,
              })
              .then((getSelectedListOfCollagesresponse) => {
                setResume(getSelectedListOfCollagesresponse.data[0].resume);
                // setMailModalOpen(false);
                setIsLoading(false);
              });
          } else {
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    } else {
      toast.error("Please Upload PDF files", {
        autoClose: 2500,
      });
    }
  };

  function download(data) {
    // console.log("decode", Name)
    const linkSource = `${data}`;
    const downloadLink = document.createElement("a");
    const fileName = "resume";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  const styles = {
    approved: {
      backgroundColor: " #6C63FF",
    },
    notapproved: {
      color: "red",
    },
  };

  function resendRequenstMail(recId) {
    setIsLoading(true);
    axios
      .post(`${MW_URL}` + "resendRecommendationByRecommendationId", {
        studentName: JSON.parse(localStorage.getItem("userData")).username,
        recommendationId: recId,
      })
      .then((resendRecRes) => {
        axios
          .post(`${MW_URL}` + "getRecommendationByStudentId", {
            studentId: JSON.parse(localStorage.getItem("userData")).email,
          })
          .then((recByIdRes) => {
            //  setMailModalOpen(false);
            setIsLoading(false);
            // console.log("recByIdREs", recByIdRes);
            if (
              recByIdRes &&
              recByIdRes.data &&
              recByIdRes.data &&
              recByIdRes.data.STATUS &&
              recByIdRes.data.STATUS == "SUCCESS"
            ) {
              // console.log("recByIdREs", recByIdRes.data.data);
              setrecommendationArray(recByIdRes.data.data);
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      });
  }
  const regex = /(<([^>]+)>)/gi;
  return (
    <div>
      {isLoading ? <LoadingAnimation isVisible={false} /> : null}

      <ModalComponent
        isVisible={mailModalOpen}
        mainHeading="Email"
        callback={mailModalHandler}
        modalSize="xl"
      >
        {/* {isLoading ? (
          <LoadingAnimation isVisible={false} />
        ) : ( */}
          <div className="col-md-12 p-0">
            <div className="d-flex col-md-12 p-o m-0">
              <div className=" col-md-8 d-flex justify-content-center align-items-center">
                <div className="col-md-4 d-flex tansfer-button ">
                  <input
                    className="justify-content-center align-items-center"
                    style={{ backgroundColor: "#6C63FF" }}
                    type="file"
                    id="resume"
                    hidden
                    onChange={(e) => {
                      uploadFile(e);
                    }}
                  />
                  <div>
                    <FaUpload className="ml-4 mr-1" style={{ fontSize: 10 }} />
                    <label
                      className="p-2 pl-0 m-0 mr-2 pr-4 m-0"
                      id="resume"
                      for="resume"
                      style={{ cursor: "pointer" }}
                    >
                      Select resume
                    </label>
                  </div>
                </div>

                <div className="col-md-3 p-0">
                  <Button
                    className="login-btn px-5"
                    style={editState.resume ? styles.approved : null}
                    onClick={() => uploadBtn()}
                  >
                    upload
                  </Button>
                </div>
              </div>
              {resumefromdb ? (
                <Button
                  className="login-btn px-5"
                  style={resumefromdb ? styles.approved : null}
                  onClick={() => download(resumefromdb)}
                >
                  Download
                </Button>
              ) : null}
            </div>

            {resumefromdb ? (
              <div className="col-md-10 p-0 d-flex justify-content-center form-group flex-column mt-3 ml-5">
                <Input
                  type="text"
                  style={{ width: "100%" }}
                  className="text-box-style my-3 py-2 input_data mandatoryField"
                  name="emailId"
                  placeholder="EmailIds separated by commas"
                  value={editState.emailId}
                  onChange={handleInputChange}
                />
                <div className="errorDesc"></div>
              </div>
            ) : (
              <div className="col-md-10 p-0 d-flex justify-content-center form-group flex-column mt-3 ml-5">
                Upload your resume to send lor requests!
              </div>
            )}
            <div>
              {recommendationArray.length > 0 ? (
                <div
                  className="d-flex col-md-12"
                  style={{ border: "1px solid #00000020" }}
                >
                  <div
                    className="p-1 px-2 col-md-7"
                    style={{
                      fontWeight: "600",
                      alignSelf: "center",
                      borderRight: "1px solid #00000020",
                    }}
                  >
                    Recipient Mail
                  </div>
                  <div
                    className="p-1 px-2 col-md-3"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #00000020",
                      borderLeft: "1px solid #00000020",
                    }}
                  >
                    Status
                  </div>
                  <div
                    className="p-1 px-2 col-md-2"
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    Action
                  </div>
                </div>
              ) : null}
              {recommendationArray.map((recommendation) => {
                return (
                  <div
                    className="d-flex col-md-12"
                    style={{ border: "1px solid #00000020" }}
                    key={recommendation.recommendationId}
                  >
                    <div
                      className="p-1 px-2 col-md-7"
                      style={{
                        fontWeight: "600",
                        alignSelf: "center",
                        borderRight: "1px solid #00000020",
                      }}
                    >
                      {recommendation.recipientId}
                    </div>
                    <div
                      className="p-1 px-2 col-md-3"
                      style={{
                        fontWeight: "600",
                        borderRight: "1px solid #00000020",
                        borderLeft: "1px solid #00000020",
                      }}
                    >
                      {recommendation.status}
                    </div>
                    <div
                      className="p-1 px-2 col-md-2"
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      {recommendation.action > 0 ? (
                        <div
                          onClick={() => {
                            resendRequenstMail(recommendation.recommendationId);
                          }}
                          style={{ color: "blueviolet", cursor: "pointer" }}
                        >
                          Remind
                        </div>
                      ) : (
                        <div>no action</div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        {/* // )} */}
      </ModalComponent>

      <ModalComponent
        isVisible={addTodoModalVisible}
        mainHeading="Add todo"
        cancelButtonText="Cancel"
        submitButtonText="Add"
        callback={addTodoModalHandler}
      >
        <div className="pt-2 pb-5">
          <textarea
            value={todoInTextarea}
            placeholder="TYPE YOUR TODO HERE"
            onChange={(event) => {
              settodoInTextarea(event.target.value);
            }}
            id="w3review"
            name="w3review"
            rows="4"
            cols="42"
          >
            Write a todo
          </textarea>
        </div>
      </ModalComponent>

      {faqQuestionsDiv ? (
        <div className="home-page-container pb-5" style={{ minHeight: height }}>
          <div
            className="p-5"
            style={{
              textTransform: "uppercase",
              color: "#D0D0D0",
              fontWeight: "800",
            }}
          >
            <div
              className="col-md-12 p-0 d-flex justify-content-end"
              onClick={() => {
                componentsHandle();
              }}
            >
              <FaTimes style={{ fontSize: 22, cursor: "pointer" }} />
            </div>
            <div className="row">
              <div className="col-md-6 ">
                <div
                  className="headings-style ml-5"
                  style={{ marginLeft: "5cm" }}
                >
                  Ask a Question !
                </div>
                <div
                  className="mt-3 ml-3 link-text-style mt-2"
                  style={{ fontWeight: "10", color: "black", fontSize: 10 }}
                >
                  <p>
                    Add A new Question for A Councellor . Enter the Details
                    Below
                  </p>{" "}
                </div>
                <div id="query">
                  <div style={{ width: "100%" }}>
                    <div className="col-md-12 p-0 d-flex form-group">
                      <div className="col-md-9 p-0 d-flex justify-content-start form-group flex-column mt-3 ml-5  inputstyl">
                        <select
                          className="opacity selstyl input_data mandatoryField"
                          style={{
                            width: "100%",
                            marginLeft: "5px",
                            marginBottom: "5px",
                            marginTop: "5px",
                            textAlign: "center",
                          }}
                          name="colid"
                          id="colid"
                          // className="opacity spacing"
                          onChange={handleChangeCounsellorSelect}
                        >
                          <option value="">Councellor</option>
                          {counsellorSuggestionList.map((counsellor) => {
                            return (
                              <option
                                key={counsellor.key}
                                value={
                                  counsellor.email +
                                  "+username+" +
                                  counsellor.username
                                }
                              >
                                {counsellor.username}
                              </option>
                            );
                          })}
                        </select>
                        <div className="errorDesc"></div>
                      </div>
                    </div>

                    {/* <div className="col-md-12 p-0 d-flex form-group">
                      <div className="col-md-10 p-0 d-flex justify-content-start form-group flex-column mt-3 ml-5">
                        <input
                          type="text"
                          autoComplete="new-password"
                          style={{ width: "90%", textAlign: "center" }}
                          className="text-box-style my-3 py-2 inputstyl"
                          name="Date"
                          placeholder="Date"
                        />
                        <div className="errorDesc"></div>
                      </div>
                    </div> */}

                    <div className="col-md-12 p-0 d-flex form-group">
                      <div className="col-md-10 p-0 d-flex justify-content-start form-group flex-column mt-3 ml-5">
                        <Input
                          type="text"
                          style={{ width: "90%", textAlign: "center" }}
                          className="text-box-style my-3 py-2 input_data mandatoryField inputstyl"
                          name="Descriptions"
                          placeholder="Description"
                          value={queryForCouncellor}
                          onChange={handleChangeQuery}
                        />
                        <div className="errorDesc"></div>
                      </div>
                    </div>
                    <div className="col-md-12 p-0 d-flex form-group ml-5">
                      <input
                        type="button"
                        placeholder="Submit"
                        value={"submit"}
                        onClick={onSubmitFaqQuery}
                        style={{
                          width: "77%",
                          textAlign: "center",
                          height: "45px",
                          backgroundColor: "#6C63FF",
                          color: "white",
                        }}
                        className="inputstyl "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="headings-style ml-3">FAQ</div>

                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    marginTop: "1cm",
                    marginLeft: "1cm",
                  }}
                ></div>
                <div className="col-md-12 m-0 p-0 d-flex align-items-center">
                  <div className="col-md-12" style={{ zIndex: 2 }}>
                    <div className="col-md-12">
                      <Accordion>
                        {queriesList.map((query) => {
                          return (
                            <div className="col-md-12 m-0 p-0">
                              <div className="col-md-12 m-0 p-0">
                                <Accordion.Toggle
                                  className="col-md-12 m-0 p-0"
                                  style={{
                                    border: "none",
                                    backgroundColor: "#f5f7fb",
                                    textAlign: "left",
                                    fontWeight: "800",
                                  }}
                                  variant="link"
                                  eventKey={query.queryId}
                                >
                                  {query.message}
                                </Accordion.Toggle>
                              </div>

                              <Accordion.Collapse eventKey={query.queryId}>
                                <div
                                  className="panel-body"
                                  style={{ textTransform: "none" }}
                                >
                                  {query.replys.length > 0
                                    ? query.replys[0]
                                    : "cousellor has not responded yet"}
                                </div>
                              </Accordion.Collapse>
                              <div
                                className="col-md-12 my-2"
                                style={{
                                  height: 2,
                                  backgroundColor: "#D0D0D0",
                                }}
                              ></div>
                            </div>
                          );
                        })}
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {mainComponent ? (
        <div className="home-page-container pb-5" style={{ minHeight: height }}>
          <div
            className="col-md-12 p-0 pt-2  d-flex flex-row-reverse"
            style={{ width: "100%", zIndex: 2 }}
          >
            <div className="col-md-3 p-0 pr-4 d-flex justify-content-end align-items-center text-right">
              <div className="pr-2 col-md-8">
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    textTransform: "uppercase",
                  }}
                >
                  {JSON.parse(localStorage.getItem("userData")).username}
                </div>
                <div style={{ fontSize: 7 }}>WELCOME BACK</div>
              </div>
              <div className="p-0 m-0 col-md-3" style={{ zIndex: 2 }}>
                {notificationDiv ? (
                  <div
                    className={
                      notificationDiv
                        ? "animated fadeInDown col-md-12 m-0 p-0"
                        : "col-md-12 m-0 p-0"
                    }
                  >
                    <div
                      className="m-3 p-0 py-4 pl-4 "
                      style={{
                        width: 50,
                        borderRadius: 15,
                        position: "absolute",
                        right: 0,
                      }}
                    >
                      {notificationRow()}
                    </div>
                  </div>
                ) : null}
                <div className="col-md-12" style={{ zIndex: 2 }}>
                  <div
                    className="d-flex justify-content-center"
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      fontSize: 10,
                      width: 15,
                      height: 15,
                      position: "absolute",
                      right: "5%",
                      top: 4,
                      borderRadius: "50%",
                    }}
                  >
                    5
                  </div>
                </div>
                <img
                  onClick={() => setnotificationDiv(!notificationDiv)}
                  className="col-md-12 p-0"
                  style={{ zIndex: 1, cursor: "pointer" }}
                  height={60}
                  src={ProfilePicSample}
                  alt="dpsaple"
                />
              </div>
            </div>
          </div>
          <div className="px-2 pt-2" style={{ zIndex: 0 }}>
            <div
              style={{}}
              className="col-md-10 px-4 d-flex justify-content-between"
            >
              {lorComponent()}
              {essayComponent()}
              {sopComponent()}
            </div>
          </div>
          <div className="row">
            <div className="px-5 pt-2 pl-0 pr-0">
              {topComponent()}
              {bottomComponent()}
            </div>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="col-md-1 d-flex align-items-center"
          >
            {/* <img height={36} width={21} src={PlusIcon} alt="lorimg" /> */}
          </div>
        </div>
      ) : null}
    </div>
  );

  function defaultDropdownSet(essaysListToPush) {
    var coll = [];
    var id = {
      id: "",
    };
    essaysListToPush.forEach((col) => {
      if (col) {
        const colls = {
          name: col.name,
          id: col.collageId,
        };
        coll.push(colls);
        if (coll[0]) {
          id = {
            id: coll[0].id,
          };
        }
      }
    });
    if (ColId.length == 0 && coll.length > 0) {
      handleChange(coll[0].id);
    }
  }

  function notificationRow() {
    return (
      <div
        className="animated fadeInDown pt-4 d-flex flex-column align-items-center justify-content-end"
        style={{ position: "absolute" }}
      >
        {/* <div className="mt-1">
          <FaQuestion onClick={() => questionBtnClicked()}></FaQuestion>
        </div>
        <div className="mt-1">
          <FaFilter></FaFilter>
        </div>
        <div className="mt-1">
          <FaPoll></FaPoll>
        </div> */}

        <div className="mt-1">
          <div
            className="d-flex flex-column align-items-center justify-content-center featherdropdownstyle"
            style={{
              backgroundColor: "white",
              height: 25,
              width: 25,
              borderRadius: 30,
            }}
            onClick={() => questionBtnClicked()}
          >
            <FaQuestion style={{ fontSize: 12, cursor: "pointer" }} />
          </div>
        </div>
        <div className="mt-1">
          <div
            className="d-flex flex-column align-items-center justify-content-center featherdropdownstyle"
            style={{
              backgroundColor: "white",
              height: 25,
              width: 25,
              borderRadius: 30,
            }}
            onClick={() => {}}
          >
            <FaFilter style={{ fontSize: 12, cursor: "pointer" }} />
          </div>
        </div>
        <div className="mt-1">
          <div
            className="d-flex flex-column align-items-center justify-content-center featherdropdownstyle"
            style={{
              backgroundColor: "white",
              height: 25,
              width: 25,
              borderRadius: 30,
            }}
            onClick={() => {}}
          >
            <FaCodeBranch style={{ fontSize: 12, cursor: "pointer" }} />
          </div>
        </div>
      </div>
    );
  }

  function lorComponent() {
    return (
      <div
        className="col-md-4  m-4 overflow-hidden card-design d-flex"
        style={{ borderRadius: "15px", cursor: "pointer" }}
        onClick={() => actionMailBtnClicked()}
      >
        <div className="col-md-12 p-0 d-flex">
          <div
            className="col-md-8 m-0 p-0 d-flex align-items-center"
            style={{ height: "100%" }}
          >
            <div className="col-md-12 p-0 pl-3 d-flex justify-content-center flex-column">
              <div className="m-0 p-0 lor-head">LOR</div>
              <div className="col-md-8 p-0 m-0 d-flex justify-content-between">
                <div className="col-md-4 p-0 m-0 progress-bar"></div>
                <div className="col-md-7 p-0 m-0" style={{ fontSize: 6 }}>
                  50 % REMAINING
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-0" style={{}}>
            <div className="col-md-12 p-0">
              <img height={140} src={LorImg} alt="lorimg" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function essayComponent() {
    var templateList = [];
    essaysTemplateList.map((template) => {
      templateList.push(template.essayReqId);
    });
    var counter = 0;
    essaysList.map((essay) => {
      if (templateList.includes(essay.essayReqId)) {
        if (essay.isApproved == true) {
          counter = counter + 1;
        }
      }
    });
    const result = Math.round((counter * 100) / essaysTemplateList.length);

    return (
      <div
        onClick={() => {
          history.push("/textEditor");
        }}
        className="col-md-4  m-4 overflow-hidden card-design d-flex"
        style={{ borderRadius: "15px", cursor: "pointer" }}
      >
        <div className="col-md-12 p-0 d-flex">
          <div
            className="col-md-8 m-0 p-0 d-flex align-items-center"
            style={{ height: "100%" }}
          >
            <div className="col-md-12 p-0 pl-3 d-flex justify-content-center flex-column">
              <div className="m-0 p-0 lor-head">ESSAY</div>
              <div className="col-md-8 p-0 m-0 d-flex justify-content-between">
                <div className="col-md-4 p-0 m-0 progress-bar"></div>
                <div className="col-md-7 p-0 m-0" style={{ fontSize: 6 }}>
                  {100 - result}% REMAINING
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-0" style={{}}>
            <div className="col-md-12 p-0">
              <img
                style={{ position: "absolute", left: -50 }}
                height={140}
                src={EssayImg}
                alt="essayimg"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function sopComponent() {
    const result = Math.round((uploadedDoc.length * 100) / docId.length);
    return (
      <div
        className="col-md-4  m-4 overflow-hidden card-design d-flex"
        style={{ borderRadius: "15px", cursor: "pointer" }}
        onClick={() => {
          history.push("/uploadDocs");
        }}
      >
        <div className="col-md-12 p-0 d-flex">
          <div
            className="col-md-8 m-0 p-0 d-flex align-items-center"
            style={{ height: "100%" }}
          >
            <div className="col-md-12 p-0 pl-3 d-flex justify-content-center flex-column">
              <div className="m-0 p-0 lor-head">SOP</div>
              <div className="col-md-8 p-0 m-0 d-flex justify-content-between">
                <div className="col-md-4 p-0 m-0 progress-bar"></div>
                <div className="col-md-7 p-0 m-0" style={{ fontSize: 6 }}>
                  {100 - result}% REMAINING
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 p-0">
            <div className="col-md-12 p-0">
              <img height={140} src={SopImg} alt="lorimg" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function topComponent() {
    //  var count=0;
    const essays = [];
    var essaylist = {
      content: "",
      collegeId: "",
    };
    var college = {
      CollageName: "",
    };

    essays.push(essaysList[0]);
    essays.map((essay) => {
      if (essay) {
        essaylist = {
          content: essay.content,
          collegeId: essay.collageId,
        };
        const ar = [];
        listOfSelectedCollagesArr.map((selectedColl) => {
          if (selectedColl.collageId === essaylist.collegeId) {
            college = {
              CollageName: selectedColl.name,
            };
          }
        });
      }
    });

    var coll = [];
    var id = {
      id: "",
    };
    listOfSelectedCollagesArr.forEach((col) => {
      if (col) {
        const colls = {
          name: col.name,
          id: col.collageId,
        };
        coll.push(colls);
        if (coll[0]) {
          id = {
            id: coll[0].id,
          };
        }
      }
    });
    // ColId={
    //   colid:id.id
    // }
    // console.log('ddd', Col)
    var deadlinez = {
      deadline: "",
    };
    var todolistToPass = [];
    if (ColId) {
      listOfSelectedCollagesArr.forEach((col) => {
        if (ColId.colid == col.collageId) {
          deadlinez = {
            deadline: col.deadline,
          };
          //settodolist(todolistMap.get(ColId.colid));
          if (todolist != todolistMap.get(ColId.colid)) {
            // setTodoToPassFun(todolistMap.get(ColId.colid));
            settodolist(todolistMap.get(ColId.colid));
          }
          todolistToPass = todolistMap.get(ColId.colid);
          // console.log("todolistToPass ====>> inside if colId");
        }
      });
    }
    const timerProps = {
      isPlaying: true,
      size: 120,
      strokeWidth: 6,
      updateInterval: 1,
    };

    var name = {
      name: "",
    };

    var durationToInputIntoTimer = 0;
    var initialRemainingTimeToInputIntoTimer = 0;
    if (deadlinez.deadline) {
      // console.log("deadlinez.deadline", deadlinez.deadline.split);
      var deadlineDate = new Date(
        deadlinez.deadline.split("-")[0],
        deadlinez.deadline.split("-")[1],
        deadlinez.deadline.split("-")[2]
      );
      var deadlineDateSeconds = deadlineDate.getTime();
      var startDateForRef = new Date(2022, 3, 24, 10, 33, 30, 0);
      var startDateForRefInSeconds = startDateForRef.getTime();
      var currentDateForRef = new Date();
      var currentDateForRefInSeconds = currentDateForRef.getTime();

      durationToInputIntoTimer =
        (deadlineDateSeconds - startDateForRefInSeconds) / 60000;
      initialRemainingTimeToInputIntoTimer =
        (deadlineDateSeconds - currentDateForRefInSeconds) / 60000;
      if (initialRemainingTimeToInputIntoTimer < 0) {
        initialRemainingTimeToInputIntoTimer = 0;
      }
      durationToInputIntoTimer = Math.round(durationToInputIntoTimer);
      initialRemainingTimeToInputIntoTimer = Math.round(
        initialRemainingTimeToInputIntoTimer
      );

      //setdurationToInputIntoTimerState(durationToInputIntoTimer);
      //setinitialRemainingTimeToInputIntoTimerState(initialRemainingTimeToInputIntoTimer);
    } else {
      //  setdurationToInputIntoTimerState(0);
      //  setinitialRemainingTimeToInputIntoTimerState(0);
    }

    // let remainingPathColor = COLOR_CODES.info.color;

    return (
      <div className="row">
        <div className="col-md-5">
          <div className="col-md 10">
            <div className="headings-style">Deadline</div>
            <div className="card-design align-items-center d-flex  mt-3">
              <div className="col-md-5">
                <ProgressCirclePart deadline={deadlinez.deadline} />
                <select
                  className="opacity selstyl"
                  style={{
                    width: "80%",
                    marginLeft: "5px",
                    marginBottom: "5px",
                    marginTop: "5px",
                    cursor: "pointer",
                  }}
                  // name="colid"
                  // id="colid"
                  // value={ColId}
                  // defaultValue={ColId}
                  onChange={(event) => handleChange(event.target.value)}
                >
                  {coll.map((col) => {
                    return (
                      <option key={col.id} value={col.id}>
                        {col.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-7">
                <div
                  className="p-0 d-flex mt-4"
                  style={{ alignItems: "center" }}
                >
                  <div className="p-1 dateStyleBig ">
                    {defaultDateValue.month}
                  </div>
                  <div
                    className="d-flex"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="p-0 text-uppercase dateStyleSmall">
                      <div>{defaultDateValue.day}</div>
                      <div>{defaultDateValue.year}</div>
                    </div>
                    <div className=" text-uppercase dateStyleSmall">
                      {defaultDateValue.weekday}
                    </div>
                  </div>
                </div>
                <TodoList
                  todolist={todolist}
                  updateTodoList={updateTodoList}
                  setaddTodoModalVisible={() => {
                    setaddTodoModalVisible(true);
                  }}
                  collageId={ColId.colid}
                  showSaveButtonForTodoUpdate={showSaveButtonForTodoUpdate}
                  setshowSaveButtonForTodoUpdate={(val) => {
                    setshowSaveButtonForTodoUpdate(val);
                  }}
                  edittodolist={edittodolist}
                />
              </div>

              {/* <div className="time-wrapper">
                <div className="time"> */}

              {/* </div>
              </div> */}

              {/* <div className="col-md-12 p-0 pl-2">
              <div className="trending-head">TRENDING</div>
              <div className="col-md-4 divider-bar"></div>
              <div className="col-md-8 m-0 p-0" style={{ fontSize: 10 }}>
                Ivy league schools make SAT scores optional
              </div>
            </div> */}
              {/* <div className="selstyl"> */}
            </div>
          </div>
        </div>

        {/* </div> */}
        <div className="col-md-4">
          <div className="col-md-12">
            <div className="col-md-6 m-0 p-0 headings-style"> UPDATES</div>
            <select
              className="opacity selstyl"
              style={{
                width: "80%",
                marginLeft: "5px",
                marginBottom: "5px",
                marginTop: "5px",
                cursor: "pointer",
                border: "none",
              }}
              // name="colid"
              // id="colid"
              // value={ColId}
              // defaultValue={ColId}
              defaultValue={""}
              onChange={(event) => handleChangeColid(event.target.value)}
            >
              <option value="">View all Update</option>
              {coll.map((col) => {
                return (
                  <option key={col.id} value={col.id}>
                    {col.name}
                  </option>
                );
              })}
            </select>
            {updatesList.length == 0 ? (
              <div
                className="p-5 align-items-center justify-content-center link-text-style"
                style={{ textAlign: "center", fontSize: 14 }}
              >
                No updates
              </div>
            ) : (
              <div
                className=" card-design col-md-10 m-0 p-0 mt-3 pl-0 "
                style={{ maxHeight: 200, overflow: "scroll" }}
              >
                {" "}
                {updatesList.map((update, index) => {
                  if (update) {
                    // console.log("id",ColIdSort.colid)
                    // console.log("id",update.collageId)
                    if (ColIdSort.colid == "" || ColIdSort.colid == undefined) {
                      if (update.updateDetails) {
                        return updateComponent(index, update);
                      }
                    } else if (ColIdSort.colid) {
                      if (update.collageId == ColIdSort.colid) {
                        if (update.updateDetails) {
                          return updateComponent(index, update);
                        }
                      }
                    }
                  }
                })}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="col-md-12">
            <div className="headings-style">RECENT</div>
            {college.CollageName ? (
              <div
                className="p-4"
                style={{
                  position: "absolute",
                  zIndex: 2,
                  width: 150,
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.push("/textEditor?id=recent");
                  // window.location.replace("/textEditor?id=recent");
                }}
              >
                <div
                  className=""
                  style={{
                    fontSize: 10,
                    fontWeight: 700,
                    lineHeight: 1,
                    height: 30,
                  }}
                >
                  {college.CollageName}
                </div>
                <div
                  className="pt-2"
                  style={{
                    fontSize: 6,
                    lineHeight: 1,
                    color: "grey",
                    height: 40,
                    overflow: "hidden",
                  }}
                >
                  {essaylist.content
                    .replace(/<[^>]*>/g, "")
                    .replace(/&nbsp;/g, "")}
                  {/* Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s */}
                </div>
                <div
                  className="pt-2"
                  style={{
                    width: "100%",
                    borderBottom: "1px solid #00000040",
                    height: 2,
                  }}
                ></div>
                <div className="d-flex align-items-end flex-column pt-2">
                  <div style={{ fontSize: 6, color: "grey" }}>
                    4 FEEDBACKS RECEIVED
                  </div>
                  <div style={{ fontSize: 6, color: "grey" }}>
                    7 SUGGESTED EDITS
                  </div>
                </div>
                <div className="pt-2 d-flex justify-content-end">
                  <img height={10} src={RightArrow} alt="rightarrow" />
                </div>
              </div>
            ) : null}
            {college.CollageName ? (
              <img
                style={{ position: "absolute" }}
                width={150}
                src={DocumentBg}
                alt="docimg"
              />
            ) : (
              <div
                className="p-5 align-items-center justify-content-center link-text-style"
                style={{ textAlign: "center", fontSize: 14 }}
              >
                No recent updates
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  function bottomComponent() {
    var name = {
      name: "",
    };
    const ar = [];

    ar.push(listOfSelectedCollagesArr[0]);
    {
      ar.map((selectedColl) => {
        if (selectedColl) {
          name = {
            name: selectedColl.name,
          };
        }
      });
    }
    var newss = {
      author: "",
      content: "",
      description: "",
      title: "",
      url: "",
    };
    var arr = [];

    if (newsList) {
      arr.push(newsList[count]);
      arr.map((news) => {
        if (news) {
          newss = {
            author: news.author,
            content: news.content,
            description: news.description,
            title: news.title,
            url: news.url,
          };
        }
      });
    }
    return (
      // <div
      //   className="col-md-4"
      //   style={{ height: 200, cursor: "pointer" }}
      //   onClick={() => {
      //     if (collageFilterCollageId == "") {
      //       setcollageFilterCollageId(selectedColl.id);
      //     } else {
      //       setcollageFilterCollageId("");
      //     }
      //   }}
      // >

      <div className="row">
        <div className="col-md-4 m-0 p-0 mt-4">
          <div className="col-md-12">
            <div className="headings-style">
              NEWS
              <FaAngleLeft
                style={{
                  fontSize: 15,
                  cursor: "pointer",
                  marginLeft: "5cm",
                  backgroundColor: "grey",
                  borderRadius: 50,
                }}
                onClick={handleDecrement}
              />
              <> </>
              <FaAngleRight
                style={{
                  fontSize: 15,
                  cursor: "pointer",
                  backgroundColor: "grey",
                  borderRadius: 50,
                }}
                onClick={handleIncrement}
              />
            </div>

            <div
              className=" col-md-12 card-design p-1 py-4 mt-3 d-flex  align-items-center"
              style={{ width: "fit-content", height: 170 }}
            >
              <div>
                <BellIconBlue />
              </div>
              <div className="col-md-12 p-0 pl-1">
                <div
                  className="col-md-9 trending-head m-0 p-0 pb-1 pt-1"
                  style={{ fontSize: 10, width: "fit-content" }}
                >
                  {newss.title}
                </div>
                <div className="col-md-8 divider-bar"></div>
                <div
                  className="col-md-9 m-0 p-0 pt-1"
                  style={{ fontSize: 10, width: "fit-content" }}
                >
                  {newss.description.substring(0, 200)}...
                </div>
                <div className="" style={{ fontSize: 10, marginLeft: "4cm" }}>
                  <a href={newss.url} target="_blank">
                    Read more...
                  </a>
                </div>
                {/* <FaAngleLeft
                  style={{ fontSize: 20, cursor: "pointer" }}
                  onClick={handleDecrement}
                />
                <FaAngleRight
                  style={{ fontSize: 20, cursor: "pointer" }}
                  onClick={handleIncrement}
                /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 m-0 p-0 mt-4 d-flex justify-content-between">
          <div className="col-md-11 p-0">
            <div className="headings-style">Scheduled</div>
            <div
              className="col-md-12 p-0 pt-3"
              style={{
                maxHeight: (window.innerHeight * 32) / 100 + "px",
                overflow: "scroll",
              }}
            >
              {currentEvents.map((event) => {
                var currentDate = new Date();
                currentDate = currentDate.setDate(currentDate.getDate() + 2);
                var startDate = moment(event.start, "YYYY-MM-DD");
                isevent =
                  startDate.isSameOrAfter(new Date(), "day") &&
                  startDate.isSameOrBefore(currentDate, "day");
                if (isevent) {
                  events = true;
                  return (
                    <div
                      key={event.id}
                      className="col-md-12 px-4 py-2 d-flex align-items-center justify-content-between schedule-box mb-3"
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push("/appoinments");
                        }}
                      >
                        <div className="normal-text">
                          {formatDate(event.start, {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}
                        </div>
                        <div className="small-text">{event.title}</div>
                        <div className="extra-small-text">
                          {formatDate(event.start, {
                            hour: "numeric",
                            minute: "numeric",
                          }) +
                            " - " +
                            formatDate(event.end, {
                              hour: "numeric",
                              minute: "numeric",
                            })}
                        </div>
                      </div>
                      {event.googleMeetId != "false" ? (
                        <img
                          className="pr-1"
                          height={25}
                          src={GoogleMeet}
                          style={{ cursor: "pointer" }}
                          alt="googlemeet"
                          onClick={() => {
                            window.open(
                              "https://meet.google.com/lookup/" +
                                event.googleMeetId,
                              "_blank"
                            );
                          }}
                        />
                      ) : null}
                    </div>
                  );
                }
              })}
              {events == false ? (
                <div
                  className="p-5 align-items-center justify-content-center link-text-style"
                  style={{ textAlign: "center", fontSize: 14 }}
                >
                  No Upcoming Schedules
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-4">
          <div className="headings-style">STATS</div>
          <div className="col-md-10 p-0 mt-4 card-design">
            {statsComponent()}
          </div>
        </div>
      </div>
    );
  }
  function updateComponent(index = 0, update) {
    return (
      <div
        key={index}
        className="col-md-12 m-0 p-3 d-flex"
        style={{
          cursor: "pointer",
          borderBottom:
            updatesList.length == index + 1 ? null : "1px solid #00000029",
        }}
        onClick={() => {
          if (update.updateDetails.type == "essay") {
            history.push("/textEditor?id=" + update.updateDetails.essayId);
          } else if (update.updateDetails.type == "query") {
            questionBtnClicked();
          }
        }}
      >
        <div className="p-0 m-0">
          <img height={50} src={ProfilePicSample} alt="dpsaple" />
        </div>
        <div className="px-3 d-flex flex-column justify-content-center">
          <div className="alumini-content" style={{ fontSize: 10 }}>
            {update.message.substring(0, 100).replace(regex, "")}...
          </div>
          <div className="alumini-time pt-1" style={{ fontSize: 8 }}>
            {findNotiTimeAgeFun(update.notiTimeAgo)}
          </div>
        </div>
      </div>
    );
  }
  function statsComponent() {
    var counter = 0;
    var templateList = [];
    essaysTemplateList.map((template) => {
      templateList.push(template.essayReqId);
    });
    essaysList.map((essay) => {
      if (templateList.includes(essay.essayReqId)) {
        if (essay.isApproved == true) {
          counter = counter + 1;
        }
      }
    });
    return (
      <div
        onClick={() => {
          history.push("/textEditor");
        }}
        style={{ cursor: "pointer" }}
        className="col-md-12 p-0 d-flex justify-content-between overflow-hidden"
      >
        <div
          style={{ position: "absolute", bottom: -20, left: -20 }}
          className="col-md-3 m-0 p-0"
        >
          <img height={80} src={StatsImg} alt="statsimg" />
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-11 d-flex py-3 justify-content-end">
          <div className="col-md-4 d-flex align-items-center flex-column">
            <div style={{ fontWeight: 800, fontSize: 20, lineHeight: 1 }}>
              {essaysTemplateList.length}
            </div>
            <div
              style={{
                fontWeight: 800,
                fontSize: 8,
                lineHeight: 1,
                color: "grey",
              }}
            >
              <div>ESSAYS</div>
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center flex-column">
            <div style={{ fontWeight: 800, fontSize: 20, lineHeight: 1 }}>
              {counter}
            </div>
            <div
              style={{
                fontWeight: 800,
                fontSize: 8,
                lineHeight: 1,
                color: "grey",
              }}
            >
              COMPLETED
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center flex-column">
            <div style={{ fontWeight: 800, fontSize: 20, lineHeight: 1 }}>
              {essaysTemplateList.length - counter}
            </div>
            <div
              style={{
                fontWeight: 800,
                fontSize: 8,
                lineHeight: 1,
                color: "grey",
              }}
            >
              INCOMPLETED
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
};

export default HomeScreen;
