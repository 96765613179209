import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { MW_URL } from "../../../../config";
import moment from "moment";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Button, Modal, Row, Col } from "react-bootstrap";
import LoadingAnimation from "../../../../utils/loadingAnimation";
import StudentProfile from "../../portals/StudentImage";
import {FaReply} from 'react-icons/fa'




const Queries = ({listOfStudents, setSelectedStudent, selectedStudent}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [updatesList, setupdatesList] = useState([]);
  const [queriesList, setqueriesList] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState("all")

  const [currentEvents, setCurrentEvents] = useState([]);
  const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
  const [openModal, setOpenModel] = useState(false);
  const [show, setShow] = useState(false);
  const [selQuery, setSelQuery] = useState({})
  const [queryReply, setqueryReply] = useState("");
  const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState(
    []
  );
  const [essaysTemplateList, setessaysTemplateList] = useState([]);
  const [essaysList, setessaysList] = useState([]);
  const [approvedEssayCount, setApprovedEssayCount] = useState(0)


  useEffect(() => {
    getAllEventsFun();
    // getlistofusersFromDb();
    // gettodo();
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    setIsLoading(true)
    getStudentEssays()
  }, [selQuery]);

  const getAllEventsFun = () => {
    // console.log("getAllEventsFun");
    axios
      .post(`${MW_URL}` + "getAllEvents", {})
      .then((getAllEventsResponse) => {
        setIsUseEffectCallRequired(false);
        var eventsList = [];
        getAllEventsResponse.data.map((event) => {
          if (
            event.eventGuests.indexOf(
              JSON.parse(localStorage.getItem("userData")).email
            ) != -1
          ) {
            eventsList.push({
              id: event.eventId,
              title: event.title,
              start: event.start,
              end: event.end,
            });
          }
        });
        setCurrentEvents(eventsList);
        axios
          .post(`${MW_URL}` + "getUpdates", {
            recipientId: JSON.parse(localStorage.getItem("userData")).email,
            senderId: false,
          })
          .then((getUpdatesResponse) => {
            console.log("getUpdatesResponse", getUpdatesResponse.data);
            setupdatesList(getUpdatesResponse.data);

            axios
              .post(`${MW_URL}` + "getQueriesByRecipientId", {
                recipientId: JSON.parse(localStorage.getItem("userData")).email,
                senderId: false,
              })
              .then((getQueriesByRecipientIdResponse) => {
                console.log(getQueriesByRecipientIdResponse.data, "qrlist----rec");
                setqueriesList(getQueriesByRecipientIdResponse.data);
                console.log(getQueriesByRecipientIdResponse.data, "qrlist");
                setIsLoading(false);
              })
              .catch((err) => {
                console.log("err >> ", err);
              });
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  };

  const getStudentEssays = () => {
    console.log("==============getStudentEssays fun  ========", selQuery?.senderId);
    // setIsLoading(true);
    setIsUseEffectCallRequired(false)
    var selectedCollages = [];
    var selectedCollagesIds = [];
    axios
      .post(`${MW_URL}` + "getSelectedListOfCollages", {
        email: selQuery?.senderId,
      })
      .then((getSelectedListOfCollagesresponse) => {
        if (
          getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
            .length > 0
        ) {
          getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
            (collage) => {
              selectedCollages.push(collage);
              selectedCollagesIds.push(collage.collageId);
            }
          );
          setlistOfSelectedCollagesArr(selectedCollages);
          var essayReqIds = [];
          var templateArr = [];
          var essaysListToPush = [];
          var essaysRequirementIds = [];
          axios
            .post(`${MW_URL}` + "get_essay_requirement", selectedCollages)
            .then((getEssaysTemplateResponse) => {
              var status = "NA";
              if (
                getEssaysTemplateResponse &&
                getEssaysTemplateResponse.data &&
                getEssaysTemplateResponse.data.STATUS
              )
                status = getEssaysTemplateResponse.data.STATUS;
              if (status.toUpperCase() === "SUCCESS") {
                setessaysTemplateList(getEssaysTemplateResponse.data.data);
                getEssaysTemplateResponse.data.data.map((templateRes) => {
                  if (templateRes.essayRequirements.length != 0) {
                    templateRes.essayRequirements.map((esaaReq) => {
                        console.log(templateRes, "temp -collage- de")
                      essayReqIds.push(esaaReq.essayReqId);
                      templateArr.push({
                        template: esaaReq.question,
                        collageId: templateRes.collageId,
                        name: templateRes.name,
                        essayReqId: esaaReq.essayReqId,
                        length: esaaReq.length,
                        requirement: esaaReq.requirement,
                        answered: essaysRequirementIds.includes(
                          esaaReq.essayReqId
                        ),
                      });
                    });
                  }
                });
                axios
                  .post(`${MW_URL}` + "getEssays", { ownerId: selQuery?.senderId })
                  .then((getAllEssaysResponse) => {
                    var essaysListToPush = [];
                    var essaysRequirementIds = [];
                    let approvedCount = 0
                    if (getAllEssaysResponse.data.length > 0) {
                      getAllEssaysResponse.data.map((essay) => {
                        // console.log(essay, "essay by stud")
                        if (
                          essay.ownerId == selQuery?.senderId &&
                          selectedCollagesIds.includes(essay.collageId) &&
                          essayReqIds.includes(essay.essayReqId)
                        ) {

                            if(essay.isApproved) {
                                approvedCount += 1
                            }

                          essaysListToPush.push({
                            essayId: essay.essayId,
                            content: essay.content,
                            newEditorContentEssay:essay.newEditorcontentEssay,
                            typeOfDocument: essay.typeOfDocument,
                            collageId: essay.collageId,
                            ownerId: essay.ownerId,
                            feedbacks: essay.feedbacks,
                            essayReqId: essay.essayReqId,
                            isApproved: essay.isApproved,
                            updatedDate: moment(essay.updatedDate).format("DD-MM-YYYY"),
                          });
                        }
                        essaysRequirementIds.push(essay.essayReqId);
                      });
                      const params = new URLSearchParams(
                        window.location.search
                      );
                      if (params.get("id")) {
                        const selectedEssay = essaysListToPush.find(
                          (essay) => essay.essayId == params.get("id")
                        );
                        // viewEssay(selectedEssay);
                      } else {
                        console.log("No params");
                      }
                      axios
                        .post(`${MW_URL}` + "getUpdates", {
                          recipientId: JSON.parse(
                            localStorage.getItem("userData")
                          ).email,
                          senderId: selQuery?.senderId,
                        })
                        .then((getUpdatesResponse) => {
                          setupdatesList(getUpdatesResponse.data);
                          setessaysTemplateList(
                            templateArr.map((reqEssay) => {
                              return {
                                ...reqEssay,
                                answered: essaysRequirementIds.includes(
                                  reqEssay.essayReqId
                                ),
                              };
                            })
                          );
                          setIsLoading(false);
                        })
                        .catch((err) => {
                          console.log("err >> ", err);
                        });
                    } else {
                      setessaysList([]);
                      setApprovedEssayCount(0)
                      setIsLoading(false);
                    }
                    setessaysList(essaysListToPush);
                    setApprovedEssayCount(approvedCount)
                    setIsLoading(false);
                    console.log(essaysListToPush, "essay list")
                  })
                  .catch((err) => {
                    console.log("err >> ", err);
                  });
              } else {
                setIsLoading(false);
              }
            })
            .catch((err) => {
              console.log("err >> ", err);
            });
        } else {
          setlistOfSelectedCollagesArr([]);
          setessaysTemplateList([]);
          setessaysList([]);
          setupdatesList([]);
          setIsLoading(false);
        }
      })
      .catch((err) => { });
  };

  const createUniquieId = () => {
    const head = Date.now().toString(36);
    const tail = Math.random().toString(36).substr(2);
    return head + tail;
  };

  const clickedViewThreads = (query) => {
    setOpenModel(true)
    setSelQuery(query)
  }

  const queryReplyHandler = (e) => {
    const { name, value } = e.target;
    setqueryReply(value);
  };

  const queryReplyButtonHandler = () => {
    var dataToSend = {
      queryId: selQuery.queryId,
      replys: [queryReply],
      updateId: createUniquieId(),
      recipientId: selQuery.senderId,
      senderId: JSON.parse(localStorage.getItem("userData")).email,
      message:
        JSON.parse(localStorage.getItem("userData")).username +
        " replayed to your query",
    };
    if (dataToSend.replys[0]) {
      setIsLoading(true);
      axios
        .post(`${MW_URL}` + "updateQueryReply", dataToSend)
        .then((response) => {
          var status = "NA";
          if (response && response.data && response.data.STATUS)
            status = response.data.STATUS;
          if (status.toUpperCase() === "SUCCESS") {
            setIsLoading(false);
            window.location.reload();
          } else {
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    }
  };

  const handleCollageChange = (e) => {
    console.log(e.target.value, "sel")
    setSelectedStudentId(e.target.value)
    // setSelectedStudent(
    //     listOfStudents.find((stud) => stud.email === e.target.value)
    // )
  }



  return (
    <div>
      <div className="d-flex justify-content-between">
            <p className="dashHead3">Queries</p>
            <select onChange={(e) => handleCollageChange(e)} className="deadlineSelect w-50">
                <option value="all" >All</option>
                {listOfStudents?.map((obj, index) => (
                    <option value={obj.email} key={index}>{obj.username}</option>
                ))}
                
            </select>
      </div>
      <div className="counQueriesWrapper">
        {queriesList.map((obj) => {
          console.log(obj, "counsellor-queries--")
          if(selectedStudentId === "all" || obj.senderId === selectedStudentId){
            return (
              <div className="counQueriesCard">
                <div className="mr-2 ">
                    <StudentProfile studentId={obj?.senderId} className="counsDashImgbg" />
                </div>
                <div className="w-100">
                  <div className="d-flex justify-content-between">
                    <p className="dashNormalText2 m-0">{obj.senderName}</p>
                    {obj?.replys?.length < 1 && (<FaReply fontSize={12} fill="red" />)}
                  </div>
                  <p className="counDashNormalText my-1">{obj.message}</p>
                  <div className="d-flex justify-content-between">
                    <p className="counDashLightText m-0">
                      {moment(obj.updatedDate).format("DD/MM/yyyy")}
                    </p>
                    <p
                      className="counDashLinkText m-0"
                      onClick={() => clickedViewThreads(obj)}
                    >
                      View Thread
                    </p>
                  </div>
                </div>
              </div>
            )}
        })}

        {/* <div className="counQueriesCard">
          <div className="counsDashImgbg"></div>
          <div>
            <p className="counDashNormalText">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s
            </p>
            <div className="d-flex justify-content-between">
              <p className="counDashLightText">03 jan 2022</p>
              <p className="counDashLinkText">View Thread</p>
            </div>
          </div>
        </div> */}
      </div>

      <Modal
        show={openModal}
        size="md"
        onHide={() => setOpenModel(false)}
        className="top-right-modal"
      >
        <div className="counselerModal">
        {isLoading ? <LoadingAnimation isVisible={true} /> : null}
          <div className="d-flex justify-content-between ">
            <div
              className="counQueriesCard mt-2"
              style={{ backgroundColor: "#fff" }}
            >
              {/* <div className="viewQueryImg"></div> */}
              <div className="mr-2 my-2">
                  <StudentProfile studentId={selQuery?.senderId} className="viewQueryImg" />
              </div>
              <div>
                <h3
                  className="dashHead3"
                  style={{
                    whiteSpace: "nowrap",
                    margin: "0px",
                    paddingTop: "15px",
                  }}
                >
                  {selQuery.senderName}
                </h3>
              </div>
            </div>
            <div className="m-2">
              <AiOutlineCloseCircle
                onClick={() => setOpenModel(false)}
                className="essayCloseBtn"
              />
            </div>
          </div>

          <div className="row" style={{ paddingLeft: "5px" }}>
            <div className="col-md-3" style={{ textAlign: "center" }}>
              <p
                className="counDashNormalText"
                style={{ marginBottom: "0rem" }}
              >
                Essays
              </p>
              <h3 className="dashHead3">{essaysList?.length}</h3>
            </div>
            <div className="col-md-3" style={{ textAlign: "center" }}>
              <p
                className="counDashNormalText"
                style={{ marginBottom: "0rem" }}
              >
                Approved
              </p>
              <h3 className="dashHead3">{approvedEssayCount}</h3>
            </div>
            <div className="col-md-3" style={{ textAlign: "center" }}>
              <p
                className="counDashNormalText"
                style={{ marginBottom: "0rem" }}
              >
                Not Approved
              </p>
              <h3 className="dashHead3">{essaysList?.length - approvedEssayCount}</h3>
            </div>
            {/* <div className="col-md-2" style={{ textAlign: "center" }}>
              <p
                className="counDashNormalText"
                style={{ marginBottom: "0rem" }}
              >
                stat{" "}
              </p>
              <h3 className="dashHead3">Value</h3>
            </div> */}
          </div>

          <div className="counQueriesCard2 m-2 p-2">
            <div className="d-flex">
                {/* <div className="counsQueryImgbg"></div> */}
                <div className="mr-2">
                    <StudentProfile studentId={selQuery?.senderId} className="counsQueryImgbg" />
                </div>
                <div>
                  <p className="dashNormalText2 m-0">{selQuery?.senderName}</p>
                  <p className="counDashNormalText">
                    {selQuery?.message}
                  </p>
                    {selQuery?.replys?.length > 0 ? (null) : (
                      <div>
                          <div className="d-flex justify-content-between">
                          {/* <p className="counDashLinkText" onClick={() => setShow(true)}>Reply</p> */}
                          {show && (
                            <textarea
                              className="relay-text"
                              style={{ width: "100%", backgroundColor : "#F4F4F4" }}
                              value={queryReply}
                              onChange={queryReplyHandler}
                            ></textarea>
                          )}
                        </div>
                          <p
                            className="counDashLinkText no-btn"
                            onClick={() => setShow(true)}
                          >
                            {show === true ? <p onClick={() => queryReplyButtonHandler()}>send</p> : <p >reply</p>}
                          </p>
                      </div>
                    )}
                   
                </div>
            </div>
            {selQuery?.replys?.length > 0 && (
                <div className="counQueriesCard3">
                  {/* <div className="counsQueryImgbg"></div> */}
                    <div className="mr-2">
                      <StudentProfile studentId={selQuery?.recipientId} className="counsQueryImgbg" />
                  </div>
                  <div>
                    <p className="dashNormalText2 m-0">{selQuery?.recipientName}</p>
                    <p className="counDashNormalText">{selQuery?.replys}</p>
                  </div>
                </div>
            )}
            

            {/* <div className="counQueriesCard3">
                <div className="counsQueryImgbg"></div>
                <div className="counsQueryTextWrap p-1 m-2">
                <p className="counDashNormalText">
                  Lorem Ipsum is simply dummy text of the printing and typesetting
                  industry. Lorem Ipsum has been the industry’s standard dummy
                  text ever since the 1500s
                </p>
              </div>
            </div> */}

          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Queries;
