import React, { useEffect, useState } from "react";
import UnderConstruction from "../../imageAssets/underConstruction.png";
import TableComponent from "../../utils/tableComponent";
import LoadingAnimation from "../../utils/loadingAnimation";
import { FaEdit, FaTrash, FaFile, FaFileDownload } from "react-icons/fa";
import AlertModal from "../../components/widgets/AlertModal";


import ModalComponent from "../../utils/modalComponent";
import ValidateForm from "../../utils/formValidation";
import { Button, Input } from "reactstrap";
import axios from "axios";
import { MW_URL } from "../../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
const CollegeScreen = () => {
  const [showCollageDelete, setShowCollageDelete] = useState(false)
  const [collageId, setCollageId] = useState("")
  const [showDocDelete, setShowDocDelete] = useState(false)
  const [docId, setDocId] = useState("")


  const [height, setHeight] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [payloadLength, setPayloadLength] = useState([]);
  const [selected, setSelected] = useState([]);
  const [editCollegeModalOpen, setEditCollegeModalOpen] = useState(false);
  const [fileUploadModalOpen, setfileUploadModalOpen] = useState(false);
  const [addCollegeModalOpen, setAddCollegeModalOpen] = useState(false);
  const [base64Value, setBase64] = useState(false);
  const [currentDocReqArr, setDocListPayload] = useState([]);
  var [isAllSelected, setIsallSelected] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  var [abc, setbuttunTrue] = useState(false);
  const [editState, setEditState] = React.useState({
    collegeName: "",
    collegeDescription: "",
    country: "",
    courses: [],
    website: "",
    essayscraplink: "",
    fileSize: "",
    fileType: "",
    collageId: "",
    logo: "",
    deadline: "",
  });
  const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
  const [collegeDetailsPayload, setCollegeDetailsPayload] = useState({
    totalRecords: 0,
    data: [],
  });
  const createUniquieId = () => {
    const head = Date.now().toString(36);
    const tail = Math.random().toString(36).substr(2);
    return head + tail;
  };
  useEffect(() => {
    var windowHeight = window.innerHeight;
    setHeight(windowHeight + "px");
    setIsLoading(true);
    axios
      .post(`${MW_URL}` + "getAllCollegeDetails", {})
      .then((getCollegeDetailsResponse) => {
        setIsLoading(false);
        setIsUseEffectCallRequired(false);
        var allCollegeDetail = [];
        if (
          getCollegeDetailsResponse &&
          getCollegeDetailsResponse.data &&
          getCollegeDetailsResponse.data[0]
        ) {
          for (var i = 0; i < getCollegeDetailsResponse.data.length; i++) {
            var currentObj = getCollegeDetailsResponse.data[i];
            var tempObj = {
              id: currentObj["_id"],
              name: currentObj.name,
              description: currentObj.description,
              country: currentObj.country,
              collegeId: currentObj.collageId,
              logo: currentObj.logo,
              deadline: currentObj.deadline,
            };
            allCollegeDetail.push(tempObj);
          }
        }
        setCollegeDetailsPayload({
          totalRecords: allCollegeDetail.length,
          data: allCollegeDetail,
        });
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
  }, [isUseEffectCallRequired]);

  const collegeDetailsTableHandler = (dataObject) => {
    console.log("dataObject", dataObject);
    if (
      dataObject.type === "firstTimeLoad" ||
      dataObject.type === "pagination"
    ) {
    }
  };

  const handleStudentDeleteBtnClick = (Id) => {
    setShowCollageDelete(true)
    setCollageId(Id)
  }

  const actionCollegeBtnClicked = (type, receivedData) => {
    if (type === "delete") {
      // setIsLoading(true);
      console.log("err >>12 ", collageId);
      axios
        .post(`${MW_URL}` + "deleteCollegeDetails/" + collageId, {})
        .then((getDeleteCollegeResponse) => {
          // setIsLoading(false);
          console.log("err >>1112 ");
          if (
            getDeleteCollegeResponse &&
            getDeleteCollegeResponse.data &&
            getDeleteCollegeResponse.data.STATUS === "SUCCESS"
          ) {
            setIsUseEffectCallRequired(true);
            setShowCollageDelete(false)
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    } else if (type === "edit") {
      console.log("receivedData", receivedData);
      setEditState({
        collegeId: receivedData.collegeId,
        collegeName: receivedData.name,
        website: receivedData.website,
        essayscraplink: receivedData.essayscraplink,
        collegeDescription: receivedData.description,
        country: receivedData.country,
        Id: receivedData.id,
        logo: receivedData.logo,
        deadline: receivedData.deadline,
      });
      setEditCollegeModalOpen(true);
    }
  };
  if (startDate) {
    const dates = startDate.toISOString();
    var deadline = dates.substring(0, dates.indexOf('T'));
  }
  const editCollegeModalHandler = (dataObject) => {
    if (dataObject.type === "cancel") {
      setEditCollegeModalOpen(false);
    } else if (dataObject.type === "submit") {
      if (ValidateForm.inputFieldMandatoryCheck("editCollegeContainer", false)) {
        if (
          ValidateForm.inputFieldValueCompositionCheck(
            "editCollegeContainer",
            false
          )
        )
          var dataToSend = {
            collegeId: editState.collegeId,
            name: editState.collegeName,
            website: editState.website,
            essayscraplink: editState.essayscraplink,
            description: editState.collegeDescription,
            country: editState.country,
            id: editState.Id,
            logo: editState.logo,
            deadline: deadline,
          };
        setIsLoading(true);
        console.log("dataToSend", dataToSend);
        axios
          .post(`${MW_URL}` + "editCollegeDetails", dataToSend)
          .then((getEditCollegeDetailResponse) => {
            setIsLoading(false);
            if (
              getEditCollegeDetailResponse &&
              getEditCollegeDetailResponse.data &&
              getEditCollegeDetailResponse.data.STATUS === "SUCCESS"
            ) {
              setIsUseEffectCallRequired(true);
              setEditCollegeModalOpen(false);
              // setStartDate({
              //   startDate:"",
              // })
            } else {
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
      }
    }
  };
  const addCollegeBtnClicked = () => {
    setEditState({
      collegeId: "",
      collegeName: "",
      website: "",
      collegeDescription: "",
      country: "",
      essayscraplink: "",
      logo: "",
      deadline: "",
    });
    setAddCollegeModalOpen(true);
  };
  const addCollegeModalHandler = (dataObject) => {
    if (dataObject.type === "cancel") {
      setAddCollegeModalOpen(false);
    } else if (dataObject.type === "submit") {
      //   const date = startDate.toISOString();
      //   var deadline = date.substring(0, date.indexOf('T'));
      // console.log("startdate",deadline)

      if (ValidateForm.inputFieldMandatoryCheck("addCollegeContainer", false)) {
        if (
          ValidateForm.inputFieldValueCompositionCheck(
            "addCollegeContainer",
            false
          )
        ) {
          var dataToSend = {
            collegeId: editState.collegeId,
            name: editState.collegeName,
            description: editState.collegeDescription,
            courses: ["Civil Engineering Technology"],
            website: editState.website,
            essayscraplink: editState.essayscraplink,
            country: editState.country,
            logo: editState.logo,
            deadline: deadline,
          };
          setIsLoading(true);
          axios
            .post(`${MW_URL}` + "addCollegeDetails", dataToSend)
            .then((getAddCollegeResponse) => {
              setAddCollegeModalOpen(false);
              setIsLoading(false);
              if (
                getAddCollegeResponse &&
                getAddCollegeResponse.data &&
                getAddCollegeResponse.data.STATUS === "SUCCESS"
              ) {
                setIsUseEffectCallRequired(true);
              }
            })
            .catch((err) => {
              console.log("err >> ", err);
            });
        }
      }
    }
  };
  // const OnChangeMultiSelect = (option) => {
  //   setSelected(option);
  //   if (collegeDetailsPayload.totalRecords == option.length) {
  //     isAllSelected = true;
  //     setIsallSelected(isAllSelected);
  //   }
  // };

  // const fileUploadBtnClicked = () => {
  //   setEditState({
  //     fileName: "",
  //     fileType: "",
  //     fileSize: "",
  //     file: "",
  //   });
  //   setfileUploadModalOpen(true);
  // };



  const fileUploadModalHandler = (dataObject) => {
    if (dataObject.type === "cancel") {
      setfileUploadModalOpen(false);
      // window.location.reload();

    } else if (dataObject.type === "submit") {

      if (ValidateForm.inputFieldMandatoryCheck("fileupload", false)) {
        if (
          ValidateForm.inputFieldValueCompositionCheck(
            "fileupload",
            false
          )
        )
          var dataToSend = {
            documentId: createUniquieId(),
            collegeId: editState.collegeId,
            fileName: editState.fileName,
            fileSize: editState.fileSize,
            fileType: editState.fileType,
            requirement: editState.requirement,
            file: base64Value,
            length: payloadLength,
            deadline: deadline,
            // isAllSelected: isAllSelected,
          };
        setIsLoading(true);
        axios
          .post(`${MW_URL}` + "uploadSingledocuments", dataToSend)
          .then((getAddEssayResponse) => {
            setIsLoading(false);
            if (
              getAddEssayResponse &&
              getAddEssayResponse.data &&
              getAddEssayResponse.data.STATUS === "SUCCESS"
            ) {
              setIsUseEffectCallRequired(true);
              setEditState({
                fileName: "",
                requirement: "",
                fileSize: "",
                collegeId: "",
                fileType: "",
                file: "",
              });
              setfileUploadModalOpen(false);
            } else {
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
        // window.location.reload()
      }
    }
  };
  const fileUploadBtnClicked = (type, rowData) => {
    console.log("acc", rowData);
    if (type === "fileUpload") {
      setEditState({
        collegeId: rowData.collegeId,
      });

      axios
        .post(`${MW_URL}` + "documents/" + rowData.collegeId)
        .then((getDocDetailsResponse) => {
          setIsUseEffectCallRequired(false);
          var allCollegeDetail = [];
          console.log("getDocDetailsResponse", getDocDetailsResponse);
          if (
            getDocDetailsResponse &&
            getDocDetailsResponse.data &&
            getDocDetailsResponse.data[0]
          ) {
            for (var i = 0; i < getDocDetailsResponse.data.length; i++) {
              var currentObj = getDocDetailsResponse.data[i];
              var tempObj = {
                collegeId: currentObj.collegeId,
                docList: currentObj.document_masters,
              };

              allCollegeDetail.push(tempObj.docList);
            }
          }
          setDocListPayload(allCollegeDetail);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("err >> ", err);
        });

      setfileUploadModalOpen(true);
    }
  };
  const handleDocDeleteBtnClick = (docId) => {
    console.log(docId, "stud id")
    setShowDocDelete(true)
    setDocId(docId)
  }
  const actionDocListBtnClicked = (type, receivedData) => {
    if (type === "delete") {
      setIsLoading(true);
      axios
        .post(`${MW_URL}` + "deleteCollegedocument/",
        { documentId:docId,
          collegeId:editState.collegeId})
        .then((getDeleteCollegeResponse) => {
          setIsLoading(false);
          if (
            getDeleteCollegeResponse &&
            getDeleteCollegeResponse.data &&
            getDeleteCollegeResponse.data.STATUS === "SUCCESS"
          ) {
            setIsUseEffectCallRequired(true);
            setShowDocDelete(false)
            setfileUploadModalOpen(false)
          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
      // window.location.reload();
    } else if (type === "edit") {
      setbuttunTrue(true);
      setEditState({
        documentId: receivedData.documentId,
        fileName: receivedData.fileName,
        fileSize: receivedData.fileSize,
        requirement: receivedData.requirement,
        fileType: receivedData.fileType,
        file: receivedData.file,
        Id: receivedData.id,
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const uploadFile = async (e) => {
    // console.log(e.target.files)
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBase64(base64);
  };

  const uploadLogo = async (e) => {
    // console.log(e.target.files)
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setBase64(base64);
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const addCollageToDb = () => {
    console.log("addCollageToDb");
    //setIsLoading(true);
    var selectedCollIds = [];
    axios
      .post(`${MW_URL}` + "collegescorecard", {})
      .then((collegescorecardresponse) => {
        console.log(collegescorecardresponse, "collegescorecardresponse");
      })
      .catch((err) => { });
  };

  const deleteallcollagedetails = () => {
    console.log("deleteallcollagedetails");
    var selectedCollIds = [];
    axios
      .post(`${MW_URL}` + "deleteallcollagedetails", {})
      .then((collegescorecardresponse) => {
        console.log("collegescorecardresponse");
      })
      .catch((err) => { });
  };
  function download(data, Name) {
    console.log("decode", Name);
    const linkSource = `${data}`;
    const downloadLink = document.createElement("a");
    const fileName = Name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  function downloadfile(doc) {
    download(doc.file, doc.fileName);
  }
  return (
    <div>
      {isLoading ? <LoadingAnimation isVisible={true} /> : null}
      <div
        className="college-page-container col-md-12 px-5 d-flex flex-column pt-sm-5 pt-2"
        style={{ minHeight: height }}
      >
        <div className="col-md-12 px-3">
          <div className="col-md-12 pt-3 px-0">
            <div className="col-md-12 tab-data">
              <div className="col-md-12 college-details-container p-4">
                <div className="p-0 d-flex">
                  {/* <div
                    className="py-1 mr-2 ml-0 my-2 col-md-2 d-flex justify-content-center add-college-btn"
                    onClick={() => addCollegeBtnClicked()}
                  >
                    Add College
                  </div> */}
                  <div
                    className="py-1 m-2 col-md-2 d-flex justify-content-center add-college-btn"
                    onClick={() => addCollageToDb()}
                  >
                    Load Collages
                  </div>
                </div>

                <TableComponent
                  headerArray={[
                    {
                      key: "name",
                      label: "Name",
                    },
                    {
                      key: "description",
                      label: "Description",
                    },
                    {
                      key: "country",
                      label: "Country",
                    },
                    {
                      key: "action",
                      label: "Action",
                      formatter: (rowData, callback) => {
                        return (
                          <div>
                            <span>
                              <FaTrash
                                onClick={() =>
                                  handleStudentDeleteBtnClick(rowData.id)
                                }
                                style={{ color: "#6C63FF", cursor: "pointer" }}
                              />
                            </span>
                            <span className="pl-2">
                              <FaEdit
                                onClick={() =>
                                  actionCollegeBtnClicked("edit", rowData)
                                }
                                style={{ color: "#6C63FF", cursor: "pointer" }}
                              />
                            </span>
                            <span className="pl-2">
                              <FaFile
                                onClick={() =>
                                  fileUploadBtnClicked("fileUpload", rowData)
                                }
                                style={{ color: "#6C63FF", cursor: "pointer" }}
                              />
                            </span>
                          </div>
                        );
                      },
                    },
                  ]}
                  payload={collegeDetailsPayload}
                  isSearchRequired={true}
                  noDataContentText="No College data Found"
                  limit={10}
                  tableUniqueId="collegeDetailsTable"
                  paginationRequired={true}
                  paginationType="client"
                  callback={collegeDetailsTableHandler}
                />
              </div>
            </div>
          </div>
        </div>
        <ModalComponent
          isVisible={addCollegeModalOpen}
          mainHeading="Add Collage"
          callback={addCollegeModalHandler}
        >
          <div id="addCollegeContainer">
            <div className="col-md-12 p-0 d-flex form-group">
              <div className="col-md-6 p-0 d-flex justify-content-start form-group flex-column">
                <Input
                  type="text"
                  autoComplete="new-password"
                  style={{ width: "95%" }}
                  className="text-box-style my-3 py-2 input_data mandatoryField"
                  name="collegeId"
                  placeholder="College Id"
                  value={editState.collegeId}
                  onChange={handleChange}
                />
                <div className="errorDesc"></div>
              </div>
              <div className="col-md-6 p-0 d-flex justify-content-start form-group flex-column">
                <Input
                  type="text"
                  autoComplete="new-password"
                  style={{ width: "95%" }}
                  className="text-box-style my-3 py-2 input_data mandatoryField"
                  name="collegeName"
                  placeholder="Name"
                  value={editState.collegeName}
                  onChange={handleChange}
                />
                <div className="errorDesc"></div>
              </div>
            </div>
            <div className="col-md-12 ml-2px p-0 d-flex form-group">
              <div className="col-md-6  p-0 d-flex ">
                <Input
                  type="text"
                  autoComplete="new-password"
                  style={{ width: "95%" }}
                  className="text-box-style my-3 py-2"
                  name="website"
                  placeholder="web site"
                  value={editState.website}
                  onChange={handleChange}
                />
                <div className="errorDesc"></div>
              </div>
              <div className="col-md-6 p-0 d-flex justify-content-start form-group flex-column">
                <Input
                  type="text"
                  autoComplete="new-password"
                  className="text-box-style my-3 py-2 "
                  name="essayscraplink"
                  placeholder="essayscraplink"
                  value={editState.essayscraplink}
                  onChange={handleChange}
                />
                <div className="errorDesc"></div>
              </div>
            </div>
            <div className="col-md-12 p-0 d-flex form-group">
              <Input
                type="text"
                autoComplete="new-password"
                className="text-box-style my-3 py-2 "
                name="collegeDescription"
                placeholder="collegeDescription"
                value={editState.collegeDescription}
                onChange={handleChange}
              />
              <div className="errorDesc"></div>
            </div>
            <div className="col-md-12 p-0 d-flex form-group">
              <Input
                type="text"
                autoComplete="new-password"
                className="text-box-style my-3 py-2  input_data mandatoryField"
                name="country"
                placeholder="country"
                value={editState.country}
                onChange={handleChange}
              />
              <div className="errorDesc"></div>
            </div>
            <div className="col-md-12 p-0 d-flex form-group">
              <Input
                type="text"
                autoComplete="new-password"
                className="text-box-style my-3 py-2"
                name="courses"
                placeholder="Courses"
                value={editState.courses}
                onChange={handleChange}
              />
              <div className="errorDesc"></div>
            </div>
            <div className="col-md-12 p-0 d-flex form-group">
              <div className="" style={{ width: "100%" }}>
                <label for="Date">Regular decision deadline

                </label>
                <DatePicker style={{ width: 200 }}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  // value={moment(startDate).format('DD/MM/YYYY')}
                  className="input_data mandatoryField"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select Date"
                  name="Date"
                  isClearable
                />
                <div className="errorDesc"></div>
              </div>

            </div>
            {logoUploadComponent()}
          </div>
        </ModalComponent>

        <ModalComponent
          isVisible={editCollegeModalOpen}
          mainHeading="Update College Details"
          callback={editCollegeModalHandler}
        >
          <div id="editCollegeContainer">
            <div className="col-md-12 p-0 d-flex justify-content-start form-group flex-column">
              <Input
                type="text"
                autoComplete="new-password"
                style={{ width: "100%" }}
                className="text-box-style my-3 py-2 input_data mandatoryField"
                name="collegeName"
                placeholder="Name"
                value={editState.collegeName}
                onChange={handleChange}
              />
              <div className="errorDesc"></div>
            </div>
            <div className="col-md-12 ml-2px p-0 d-flex form-group">
              <div className="col-md-6  p-0 d-flex ">
                <Input
                  type="text"
                  autoComplete="new-password"
                  style={{ width: "95%" }}
                  className="text-box-style my-3 py-2 "
                  name="website"
                  placeholder="web site"
                  value={editState.website}
                  onChange={handleChange}
                />
                <div className="errorDesc"></div>
              </div>
              <div className="col-md-6 p-0 d-flex justify-content-start form-group flex-column">
                <Input
                  type="text"
                  autoComplete="new-password"
                  className="text-box-style my-3 py-2 "
                  name="essayscraplink"
                  placeholder="essayscraplink"
                  value={editState.essayscraplink}
                  onChange={handleChange}
                />
                <div className="errorDesc"></div>
              </div>
            </div>
            <div className="col-md-12 p-0 d-flex form-group">
              <Input
                type="text"
                autoComplete="new-password"
                className="text-box-style my-3 py-2"
                name="collegeDescription"
                placeholder="collegeDescription"
                value={editState.collegeDescription}
                onChange={handleChange}
              />
              <div className="errorDesc"></div>
            </div>
            <div className="col-md-12 p-0 d-flex form-group">
              <Input
                type="text"
                autoComplete="new-password"
                className="text-box-style my-3 py-2 input_data mandatoryField"
                name="country"
                placeholder="country"
                value={editState.country}
                onChange={handleChange}
              />
              <div className="errorDesc"></div>
            </div>
            <div className="col-md-12 p-0 d-flex form-group">
              <Input
                type="text"
                autoComplete="new-password"
                className="text-box-style my-3 py-2 "
                name="courses"
                placeholder="Courses"
                value={editState.courses}
                onChange={handleChange}
              />
              <div className="errorDesc"></div>
            </div>
            <div className="col-md-12 p-0 d-flex form-group">
              <div className="" style={{ width: "100%" }}>
                <label for="Date">Select Deadline</label>
                <DatePicker style={{ width: 200 }}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  // value={moment(startDate).format('DD/MM/YYYY')}
                  className="input_data mandatoryField"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select Date"
                  name="Date"
                  isClearable
                />
              </div>
              <div className="errorDesc"></div>
            </div>
            {logoUploadComponentEdit()}
          </div>
        </ModalComponent>

        <ModalComponent
          isVisible={fileUploadModalOpen}
          mainHeading="File Upload"
          callback={fileUploadModalHandler}
          modalSize="xl"
        >
          {" "}
          <div id="fileupload">
            <div className="col-md-12 p-0 d-flex form-group">
              <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column mt-3">
                <Input
                  type="text"
                  autoComplete="new-password"
                  style={{ width: "95%" }}
                  className="text-box-style my-3 py-2 input_data mandatoryField"
                  name="fileName"
                  placeholder="File Name"
                  value={editState.fileName}
                  onChange={handleChange}
                />
                <div className="errorDesc"></div>
              </div>
              <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column">
                <label for="file-select">Choose File Type:</label>
                <select
                  name="fileType"
                  value={editState.fileType}
                  onChange={handleChange}
                  className="file-select input_data mandatoryField"
                  style={{ width: "95%", height: 40 }}
                >
                  <option value=""> choose an option</option>
                  <option value="jpeg">JPEG</option>
                  <option value="jpg">JPG</option>
                  <option value="png">PNG</option>
                  <option value="pdf">PDF</option>
                </select>
                <div className="errorDesc"></div>
              </div>
              <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column">
                <label for="file-select">Choose File Size:</label>
                <select
                  name="fileSize"
                  value={editState.fileSize}
                  onChange={handleChange}
                  className="file-select input_data mandatoryField"
                  style={{ width: "95%", height: 40 }}
                >
                  <option value=""> choose an option</option>
                  <option value="50">50 KB</option>
                  <option value="100">100 KB</option>
                  <option value="150">150 KB</option>
                  <option value="200">200 KB</option>
                  <option value="250">250 KB</option>
                  <option value="300">300 KB</option>
                </select>
                <div className="errorDesc"></div>
              </div>
            </div>
            <div className="col-md-12 p-0 d-flex form-group">
              <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column mt-4 ">
                <select
                  name="requirement"
                  value={editState.requirement}
                  onChange={handleChange}
                  className="requirement input_data mandatoryField"
                  style={{ width: "95%", height: 40 }}
                >
                  <option value="">Choose Requirement</option>
                  <option value="Mandatory">Mandatory</option>
                  <option value="optional">Optional</option>
                </select>
                <div className="errorDesc"></div>
              </div>
              <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column ">
                <label for="file">Upload File</label>
                <Input
                  type="file"
                  style={{ width: "95%" }}
                  id="file"
                  name="file"
                  className="input_data mandatoryField"
                  accept=".png, .jpg, .jpeg, .pdf"
                  onChange={(e) => {
                    uploadFile(e);
                  }}
                />
                 <div className="errorDesc"></div>
              </div>
              <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column mt-2 ">
                <label for="Date">Select Deadline</label>
                <DatePicker style={{ width: 100 }}
                  selected={startDate}
                  onChange={setStartDate}
                  value={moment(startDate).format('DD/MM/YYYY')}
                  format="dd/MM/yyyy"
                  // dateFormat={moment(startDate).format('dd/MM/yyyy')}
                  placeholderText="Select Date"
                  name="Date"
                  className="input_data mandatoryField"
                />
                 <div className="errorDesc"></div>
              </div>
              {/* { value=(abc)? (
               <div className="col-md-12 p-0 d-flex form-group">
               <Button
               onClick={""}
               ></Button>
               </div>
         ):
         ("")
        } */}
            </div>
          </div>
          <div>
            <div className="mb-2">
              {currentDocReqArr.length > 0 ? (
                <div
                  className="d-flex col-md-12"
                  style={{ border: "1px solid #00000020" }}
                >
                  <div
                    className="p-1 px-2 col-md-2"
                    style={{
                      fontWeight: "600",
                      alignSelf: "center",
                      borderRight: "1px solid #00000020",
                    }}
                  >
                    File Name
                  </div>
                  <div
                    className="p-1 px-2 col-md-2"
                    style={{
                      fontWeight: "600",
                      alignSelf: "center",
                      borderRight: "1px solid #00000020",
                    }}
                  >
                    File Type
                  </div>
                  <div
                    className="p-1 px-2 col-md-2"
                    style={{
                      fontWeight: "600",
                      alignSelf: "center",
                      borderRight: "1px solid #00000020",
                    }}
                  >
                    File Size
                  </div>
                  <div
                    className="p-1 px-2 col-md-2"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #00000020",
                      borderLeft: "1px solid #00000020",
                    }}
                  >
                    Required or optional
                  </div>
                  <div
                    className="p-1 px-2 col-md-2"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #00000020",
                      borderLeft: "1px solid #00000020",
                    }}
                  >
                    Deadline
                  </div>
                  <div
                    className="p-1 px-2 col-md-2"
                    style={{
                      fontWeight: "600",
                      borderRight: "1px solid #00000020",
                      borderLeft: "1px solid #00000020",
                    }}
                  >
                    Action
                  </div>
                </div>
              ) : null}

              {currentDocReqArr.map((docReq) => {
                return docReq.map((doc) => {
                  return (
                    <div
                      id={doc.collegeId}
                      className=" d-flex col-md-12"
                      style={{ border: "1px solid #00000020" }}
                    >
                      {console.log("fileName", doc.fileName)}
                      <div
                        className="p-1 px-2 col-md-2"
                        style={{
                          alignSelf: "center",
                          borderRight: "1px solid #00000020",
                          alignItems: "center",
                        }}
                      >
                        {doc.fileName}
                      </div>
                      <div
                        className="p-1 px-2 col-md-2"
                        style={{
                          borderRight: "1px solid #00000020",
                          borderLeft: "1px solid #00000020",
                          alignItems: "center",
                        }}
                      >
                        {doc.fileType}
                      </div>
                      <div
                        className="p-1 px-2 col-md-2"
                        style={{
                          borderRight: "1px solid #00000020",
                          borderLeft: "1px solid #00000020",
                          alignItems: "center",
                        }}
                      >
                        {doc.fileSize}
                      </div>
                      <div
                        className="p-1 px-2 col-md-2"
                        style={{
                          borderRight: "1px solid #00000020",
                          borderLeft: "1px solid #00000020",
                          alignItems: "center",
                        }}
                      >
                        {doc.requirement}
                      </div>
                      <div
                        className="p-1 px-2 col-md-2"
                        style={{
                          borderRight: "1px solid #00000020",
                          borderLeft: "1px solid #00000020",
                          alignItems: "center",
                        }}
                      >
                        {doc.deadline}
                      </div>
                      <div>
                        <FaTrash
                          className="ml-4"
                          onClick={() =>
                            handleDocDeleteBtnClick(doc.documentId)
                          }
                          style={{ color: "#6C63FF", cursor: "pointer" }}
                        />
                        <FaFileDownload
                          className="ml-4"
                          onClick={() => downloadfile(doc)}
                          style={{ color: "#6C63FF", cursor: "pointer" }}
                        />
                        {/* <FaEdit
                      className="ml-4"
                      onClick={() =>
                        actionDocListBtnClicked("edit",doc)
                      }
                      style={{ color: "#6C63FF", cursor: "pointer" }}
                    /> */}
                      </div>
                    </div>
                  );
                });
              })}
            </div>
          </div>
        </ModalComponent>

        <AlertModal
            show={showCollageDelete}
            onHide={() => setShowCollageDelete(false)}
            title='Alert!'
            message='Are you sure you want to delete this student?'
            onCancel={() => setShowCollageDelete(false)}
            onConfirm={() => actionCollegeBtnClicked("delete")}
        />
        <AlertModal
            show={showDocDelete}
            onHide={() => setShowDocDelete(false)}
            title='Alert!'
            message='Are you sure you want to delete this document?'
            onCancel={() => setShowDocDelete(false)}
            onConfirm={() => actionDocListBtnClicked("delete")}
        />
      </div>
    </div>
  );

  function logoUploadComponent() {
    var logo = require("../../imageAssets/CollegeLogo/addLogo.jpg").default;

    if (editState.logo) {
      console.log("editstate.logo ===>>>", editState.logo);
      logo = editState.logo;
    }

    return (
      <div className="d-flex">
        <div className="col-md-8 p-0 d-flex justify-content-start form-group flex-column ">
          <label for="file">Upload Collage Logo</label>
          <Input
            type="file"
            style={{ width: "95%" }}
            id="logo"
            name="logo"
            accept=".jpg,.png"
            onChange={async (e) => {
              const file = e.target.files[0];
              const base64 = await convertBase64(file);
              setBase64(base64);
              setEditState((prevState) => ({
                ...prevState,
                ["logo"]: base64,
              }));
            }}
          />
        </div>
        <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column ">
          <img
            className="image-design-uni"
            src={logo}
            width={80}
            alt="collage Logo"
          />
        </div>
      </div>
    );
  }

  function logoUploadComponentEdit() {
    var logo = require("../../imageAssets/CollegeLogo/addLogo.jpg").default;

    if (editState.logo) {
      console.log("editstate.logo ===>>>", editState.logo);
      logo = editState.logo;
    }

    return (
      <div className="d-flex">
        <div className="col-md-8 p-0 d-flex justify-content-start form-group flex-column ">
          <label for="file">Edit Collage Logo</label>
          <Input
            type="file"
            style={{ width: "95%" }}
            id="logo"
            name="logo"
            accept=".jpg,.png"
            onChange={async (e) => {
              const file = e.target.files[0];
              const base64 = await convertBase64(file);
              setBase64(base64);
              setEditState((prevState) => ({
                ...prevState,
                ["logo"]: base64,
              }));
            }}
          />
        </div>
        <div className="col-md-4 p-0 d-flex justify-content-start form-group flex-column ">
          <img
            className="image-design-uni"
            src={logo}
            width={80}
            alt="collage Logo"
          />
        </div>
      </div>
    );
  }
};

export default CollegeScreen;
