import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { MW_URL } from "../../../../config";
import AddLogoImg from '../../../../assets1/images/add-logo.jpg'
import LoadingAnimation from "../../../../utils/loadingAnimation";
import CollageFilters from "./collageFilters";
import { IoClose } from "react-icons/io5";
import FilterIcon from "../../../../assets/svg/filterIcon"



const CollegeLeft = ({
    collageMetaData,
    listOfCollageDetailsArr,
    setlistOfCollageDetailsArr,
    setcollageSearchKeyword,
    searchCollageByKeyword,
    setSelectedCollege,
    selectedCollege,
    setOpenCompareModal,
    setSelectColToCompare,
    selectColToCompare,
    listOfSelectedCollageDetailsArr,
    setShowFilterSec,
    showFilterSec,
    setfilterStringToSend,
    collagedetailsfromapi

}) => {

    const [tab, setTab] = useState("1")
    const [isLoading, setIsLoading] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedCollageRec, SetSelectedCollageRec] = useState([])
    const [addFilter, setAddFilter] = useState(false);


    const isAnyCheckboxChecked = selectColToCompare.length > 1;

    console.log(listOfCollageDetailsArr, "collage listOfCollageDetailsTempArr")

    useEffect(() => {
        getCollegeRecommend()
    }, []);


    function updateListofCollages() {
        setIsLoading(true);
        // console.log(
        //   "listOfSelectedCollageDetailsArr",
        //   listOfSelectedCollageDetailsArr
        // );
        axios
            .post(`${MW_URL}` + "addCollageList", {
                listOfSelectedCollages: listOfSelectedCollageDetailsArr,
                email: JSON.parse(localStorage.getItem("userData")).email,
            })
            .then((response) => {
                setIsLoading(false);
                // setAddUniversitySecVisible(false);
            })
            .catch((err) => { });
    }

    const handleCheckboxChange = (event, obj) => {
        const checkboxValue = event.target.value;
        setIsChecked(event.target.checked);
        if (event.target.checked) {
            // Checkbox is checked, add the value to the state
            setSelectColToCompare((prevSelectedCols) => [...prevSelectedCols, obj]);
        } else {
            // Checkbox is unchecked, remove the value from the state
            setSelectColToCompare((prevSelectedCols) =>
                prevSelectedCols.filter((col) => col._id !== obj._id)
            );
        }
    };

    const getRecommendedColList = () => {
        axios.post(`${MW_URL}` + "getRecommendedCollages", {})
            .then((getRecommendedCollages) => {
                console.log(getRecommendedCollages, "getRecommendedCollages")
            })
    }

    const getCollegeRecommend = () => {
        axios
            .post(`${MW_URL}` + "getCollegeRecommend", { recipientId: JSON.parse(localStorage.getItem("userData")).email })
            .then((getAddCollageRecommendationResponse) => {
                console.log(getAddCollageRecommendationResponse, "getAddCollageRecommendationResponse")
                if (getAddCollageRecommendationResponse?.data[0]?.recommendedCollages) {
                    console.log(getAddCollageRecommendationResponse, "ddddd")
                    SetSelectedCollageRec(getAddCollageRecommendationResponse?.data[0]?.recommendedCollages)
                } else {
                    SetSelectedCollageRec([])
                }
            })
            .catch((err) => {
                console.log("err >> ", err);
            });
    }

    const handleSearchKeyChange = (value) => {
        console.log(value, "value")
        if (value === '') {
            console.log("not have value", value)
            console.log("listOfCollageDetailsArr", listOfCollageDetailsArr)
            setcollageSearchKeyword(value)
            setlistOfCollageDetailsArr(listOfCollageDetailsArr);
        } else {
            console.log("have value", value)
            setcollageSearchKeyword(value)
        }
    }


    return (
        <div className='collegeLeftSide'>
            {isLoading ? <LoadingAnimation isVisible={true} /> : null}
            <div className='colSearchBox mt-5'>
                <input type='search' onChange={(e) => handleSearchKeyChange(e.target.value)} placeholder='Search Colleges' />
                <div onClick={() => searchCollageByKeyword()} className='px-2 py-1'><FaSearch /></div>
            </div>

            <div className='colLeftTab'>
                <div
                    onClick={() => setTab("1")}
                    className={tab === "1" ? 'colLeftSelectedBtn' : 'colLeftBtn'} >All
                    <span
                        className={tab === "1" ? 'colLeftSelectedBtnSpan' : 'colLeftBtnSpan'}>
                        {collageMetaData.length}
                    </span>
                </div>

                <div
                    onClick={() => setTab("2")}
                    className={tab === "2" ? 'colLeftSelectedBtn' : 'colLeftBtn'}>Recomended
                    <span
                        className={tab === "2" ? 'colLeftSelectedBtnSpan' : 'colLeftBtnSpan'}>
                        {selectedCollageRec?.length}
                    </span>
                </div>

                <div
                    onClick={() => setTab("3")}
                    className={tab === "3" ? 'colLeftSelectedBtn' : 'colLeftBtn'}>Selected
                    <span
                        className={tab === "3" ? 'colLeftSelectedBtnSpan' : 'colLeftBtnSpan'}>
                        {listOfSelectedCollageDetailsArr?.length}
                    </span>
                </div>
            </div>

            {showFilterSec ? (<div className="d-flex justify-content-between align-items-center" >
                <div className="d-flex align-items-center" style={{ gap: "0.5rem" }}>
                    <div style={{ fontWeight: "bold" }}>
                        Filters</div>
                    <button onClick={() => setAddFilter(true)} className="essayBtn2">Add</button>
                </div>
                <div onClick={() => setShowFilterSec(false)} style={{ cursor: "pointer" }}>
                    <IoClose color="grey" fontSize={"1.5rem"} /></div>
            </div>) : (<>
                <div className="d-flex justify-content-between align-items-end">
                    <p className='m-0 px-2 colNormalSmallText' onClick={() => setAddFilter(true)}>Check Boxes to Compare</p>
                    <div onClick={() => { setShowFilterSec(true) }} style={{ cursor: "pointer" }} >
                        <FilterIcon />
                    </div>
                </div>
            </>)}

            {showFilterSec ? (
                <div>
                    <CollageFilters
                        setfilterStringToSend={setfilterStringToSend}
                        collagedetailsfromapi={collagedetailsfromapi}
                        setShowFilterSec={setShowFilterSec}
                        addFilter={addFilter}
                        setAddFilter={setAddFilter}
                    />
                </div>
            ) : (
                <>
                    {tab === '1' && (
                        <div >
                            <div className={isAnyCheckboxChecked ? 'colSelectList , showCompareColSelect' : 'colSelectList'}>
                                {collageMetaData.map((obj, index) => (
                                    <div key={index} className={selectedCollege?.collageId === obj?.collageId ? 'colBoxSelected' : 'colBox'} onClick={() => setSelectedCollege(obj)}>
                                        <div className="row my-2 mx-1 align-items-center">
                                            <div className="col-3 p-1 ">
                                                {obj.logo ? (<img width={50} height={50} src={obj?.logo} alt='logo' />)
                                                    : (<img width={60} height={60} src={AddLogoImg} alt="logo" />
                                                    )}
                                            </div>
                                            <div className="col-8 p-1">
                                                <p className='colNormalText m-0'>{obj?.name}</p>
                                                <p className='colNormalText2 my-1'>{obj?.state}, {obj?.country}</p>
                                                <p className='colLightText m-0'>Public 4 years</p>
                                            </div>
                                            <div className=" col-1 p-1 align-self-start">
                                                <input
                                                    className="colCheckbox"
                                                    onChange={(event) => handleCheckboxChange(event, obj)}
                                                    type="checkbox"
                                                    checked={selectColToCompare.some(selectedObj => selectedObj?._id === obj?._id)}
                                                    value={obj?._id}
                                                    id={obj?._id}
                                                />
                                                <label htmlFor={obj?._id} className="checkbox-label"></label>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {/* <div className='colBoxSelected'>
                        <div className="row my-2 mx-1 align-items-center">
                            <div className="col-3 p-1 ">
                                <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAATCAMAAADyMz7ZAAAAZlBMVEX/////zQD/zAD/ygD/ywD/yQD/3HT/0Cf/8cv//PX/6Kj/9+X/0TL//vv/22//+en/5Jf/5qL/6rP/78P/6a3/5Z3/11f/2WP/7bz/1Uz/4pD/0z7/zhX/9NX/2mn/3n7/9t3/4IjqDqDnAAABmklEQVQ4jZ1T27aqMAycpuWiCCggooCX///JPRM96+z9oA/moSXNTDpNAvC1rU1Oa0pgm7vtaxx01uyxuebXCoN7R8CR+fVF7DOjZQPGaG5pQaPPNKLMLA64uXdC60jLqn/EQMu6VntKXGKZayNxn4LluLl3Qh9DMH5NT+L9Qcdua6njy35hZJjOFuxMEQwtmLjZ3HkaBtLlpbXmVbGFiBHYMbJFR8wN0FXZBo8U0gE4MTQwML6IGxGrv8Q18g5UUh97MbhCMlvp+kCUCsPkj7vojADhComghrdESGP9SPYwOzsYruLqScoPRKmrzhYpLdZ+FbZ86RG5+XvfEi9ETSwmZkuq1AmihHnc8Q3NB+Kk6hnxlDs+W7BT20xNL963A3dvNkUpAyvUAVEljloz57VsDRGtiGnqi+BEh4W4wgcqxHt7JCxu6lWwY/tr5O7/R47pUUhUrL17qnCnsD0fFrJexGfuDotIigqOhp7t4PUNltBJ+ywp5kMBlFbQlo7/UOE2+vRvFx5qKg+LzjDJz+nPdK38/jf+2n4A7IwUnI2VF1MAAAAASUVORK5CYII=' />
                            </div>
                            <div className="col-8 p-1">
                                <h3 className='colNormalText m-0'>The University of lowa</h3>
                                <p className='colNormalText2 my-1'>lowa City, IA</p>
                                <p className='colLightText m-0'>Public 4 years</p>
                            </div>
                            <div className=" col-1 p-1 ">
                                <input type="checkbox" value="" id="flexCheckChecked" />
                            </div>
                        </div>
                    </div>

                    <div className='colBox'>
                        <div className="row my-2 mx-1 align-items-center">
                            <div className="col-3 p-1 ">
                                <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAATCAMAAADyMz7ZAAAAZlBMVEX/////zQD/zAD/ygD/ywD/yQD/3HT/0Cf/8cv//PX/6Kj/9+X/0TL//vv/22//+en/5Jf/5qL/6rP/78P/6a3/5Z3/11f/2WP/7bz/1Uz/4pD/0z7/zhX/9NX/2mn/3n7/9t3/4IjqDqDnAAABmklEQVQ4jZ1T27aqMAycpuWiCCggooCX///JPRM96+z9oA/moSXNTDpNAvC1rU1Oa0pgm7vtaxx01uyxuebXCoN7R8CR+fVF7DOjZQPGaG5pQaPPNKLMLA64uXdC60jLqn/EQMu6VntKXGKZayNxn4LluLl3Qh9DMH5NT+L9Qcdua6njy35hZJjOFuxMEQwtmLjZ3HkaBtLlpbXmVbGFiBHYMbJFR8wN0FXZBo8U0gE4MTQwML6IGxGrv8Q18g5UUh97MbhCMlvp+kCUCsPkj7vojADhComghrdESGP9SPYwOzsYruLqScoPRKmrzhYpLdZ+FbZ86RG5+XvfEi9ETSwmZkuq1AmihHnc8Q3NB+Kk6hnxlDs+W7BT20xNL963A3dvNkUpAyvUAVEljloz57VsDRGtiGnqi+BEh4W4wgcqxHt7JCxu6lWwY/tr5O7/R47pUUhUrL17qnCnsD0fFrJexGfuDotIigqOhp7t4PUNltBJ+ywp5kMBlFbQlo7/UOE2+vRvFx5qKg+LzjDJz+nPdK38/jf+2n4A7IwUnI2VF1MAAAAASUVORK5CYII=' />
                            </div>
                            <div className="col-8 p-1">
                                <p className='colNormalText m-0'>The University of lowa</p>
                                <p className='colNormalText2 my-1'>lowa City, IA</p>
                                <p className='colLightText m-0'>Public 4 years</p>
                            </div>
                            <div className=" col-1 p-1 ">
                                <input type="checkbox" value="" id="flexCheckChecked" />
                            </div>
                        </div>
                    </div> */}

                            </div>
                            {isAnyCheckboxChecked && (
                                <button className="compareBtn" onClick={() => setOpenCompareModal(true)}>Compare</button>
                            )}
                        </div>
                    )}

                    {tab === '2' && (
                        <div className='colSelectList'>
                            {selectedCollageRec?.map((obj, index) => (
                                <div key={index} className={selectedCollege?.collageId === obj?.collageId ? 'colBoxSelected' : 'colBox'} onClick={() => setSelectedCollege(obj)}>
                                    <div className="row my-2 mx-1 align-items-center">
                                        <div className="col-3 p-1 ">
                                            {obj.logo ? (<img width={50} height={50} src={obj.logo} alt='logo' />)
                                                : (<img width={60} height={60} src={AddLogoImg} alt="logo" />
                                                )}
                                        </div>
                                        <div className="col-8 p-1">
                                            <p className='colNormalText m-0'>{obj.name}</p>
                                            <p className='colNormalText2 my-1'>{obj?.state}, {obj.country}</p>
                                            <p className='colLightText m-0'>Public 4 years</p>
                                        </div>
                                        {/* <div className=" col-1 p-1 ">
                                    <input className="colCheckbox" type="checkbox" value="" id={obj._id} />
                                    <label htmlFor={obj._id} className="checkbox-label"></label>
                                </div> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {tab === '3' && (
                        <div>
                            <div className='colSelectList'>
                                {listOfSelectedCollageDetailsArr?.map((obj, index) => (
                                    <div key={index} className={selectedCollege?.collageId === obj?.collageId ? 'colBoxSelected' : 'colBox'} onClick={() => setSelectedCollege(obj)}>
                                        <div className="row my-2 mx-1 align-items-center">
                                            <div className="col-3 p-1 ">
                                                {obj.logo ? (<img width={50} height={50} src={obj.logo} alt='logo' />)
                                                    : (<img width={60} height={60} src={AddLogoImg} alt="logo" />
                                                    )}
                                            </div>
                                            <div className="col-8 p-1">
                                                <p className='colNormalText m-0'>{obj.name}</p>
                                                <p className='colNormalText2 my-1'>lowa City, {obj.country}</p>
                                                <p className='colLightText m-0'>Public 4 years</p>
                                            </div>
                                            {/* <div className=" col-1 p-1 ">
                                    <input className="colCheckbox" type="checkbox" value="" id={obj._id} />
                                    <label htmlFor={obj._id} className="checkbox-label"></label>
                                </div> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <button className="compareBtn" onClick={() => updateListofCollages()}>Save</button>
                        </div>
                    )}
                </>)
            }

        </div>
    )
}

export default CollegeLeft;