import * as React from 'react';
// import './Collapsible.scss';

// interface ICollapsibleProps {
//   children: React.ReactNode,
//   collapsedMinHeight?: string;
//   initial?: 'collapsed' | 'expanded';
// }

function Collapsible({ children,  initial = 'collapsed', collapsedMinHeight = '80' }) {
  console.log("collapsedMinHeight",collapsedMinHeight);
  const parentRef = React.createRef();
  const childRef = React.createRef();
  const [expanded, setExpanded] = React.useState(() => initial === 'expanded');
  const [expandedHeight, setExpandedHeight] = React.useState(() => expanded ? parentRef.current?.scrollHeight : collapsedMinHeight);

  React.useEffect(() => {
    setExpandedHeight(parentRef.current?.scrollHeight);
  }, [parentRef.current?.scrollHeight]);

  React.useEffect(() => {
    if (!expanded) {
      setExpandedHeight(collapsedMinHeight);
    } else {
      setExpandedHeight(parentRef.current?.scrollHeight);
    }
  }, [expanded]);

  const toggleCollapse = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <div className='' ref={parentRef} style={{ height:expanded? parentRef.current?.scrollHeight:80, overflow:"hidden" }}>
      <div  className='col-md-12 d-flex flex-column justify-content-center align-items-center' >
        <div style={{position:"absolute",top:10}} onClick={toggleCollapse} className='col-md-7 p-5'>

        </div>
      </div>
      <div className="collapsible-content-container" ref={childRef}>
        {children}
      </div>
    </div>
  );
}

export default Collapsible;



// function Collapsible({ children,  initial = 'collapsed', collapsedMinHeight = '10' }) {
//     const parentRef = React.createRef();
//     const childRef = React.createRef();
//     const [expanded, setExpanded] = React.useState(() => initial === 'expanded');
//     const [expandedHeight, setExpandedHeight] = React.useState(() => expanded ? parentRef.current?.scrollHeight : collapsedMinHeight);
  
//     const parentHeightResizeObserver = new ResizeObserver((entries) => {
//       if (expanded) {
//         setExpandedHeight(entries[0].target.scrollHeight);
//       }
//     });
  
//     React.useEffect(() => {
//       setExpandedHeight(parentRef.current?.scrollHeight);
//       console.log("parentRef.current?.scrollHeight",parentRef.current?.scrollHeight);
//     }, [parentRef.current?.scrollHeight]);
  
//     React.useEffect(() => {
//         console.log("expanded",expanded);
//         console.log("expandedHeight",expandedHeight);
//         console.log("collapsedMinHeight",collapsedMinHeight);
//         console.log(parentRef.current?.scrollHeight);
//       if (!expanded) {
//         setExpandedHeight(collapsedMinHeight);
//       } else {
//         setExpandedHeight(parentRef.current?.scrollHeight);
//       }
//     }, [expanded]);
  
//     React.useEffect(() => {
//       if (childRef.current) {
//         parentHeightResizeObserver.observe(childRef.current);
//       }
  
//       return () => {
//         if (childRef.current) {
//           parentHeightResizeObserver.unobserve(childRef.current);
//         }
//       };
//     });
  
//     const toggleCollapse = () => {
//       setExpanded((prev) => !prev);
//     };
  
//     return (
//       <div className="collapsible-container" ref={parentRef} style={{ height: expandedHeight }}>
//         <div className="collapsible-controls" onClick={toggleCollapse} role="button" tabIndex={0}>
//           <span>ggg </span>
//         </div>
//         <div className="collapsible-content-container" ref={childRef}>
//           {children}
//         </div>
//       </div>
//       // <div className="collapsible-container" ref={parentRef} style={{ height: expandedHeight , overflow:"hidden"}}>
//       //     {children}
//       // </div>
//     );
//   }