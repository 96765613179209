import React, { useEffect } from "react";
import "../styleAssets/mainStyles.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import Portal from "./portalScreen";
import Landing from "./landingScreen";
import { isUserLoggedIn, setUserLoggedInTrue, setUserLoggedInFalse } from "../stateManager/reducers/userDetailsReducer";
import { useDispatch, useSelector } from "react-redux";
import LandingScreen2 from "./landingScreen2";

const SplashScreen = () => {

    const isUserLoggedInn = useSelector(isUserLoggedIn);
    const dispatch = useDispatch();

    useEffect(() => {
        var isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
        console.log("isUserLoggedIn >> ", isUserLoggedInn);
        if (isUserLoggedIn === "true") {
            dispatch(setUserLoggedInTrue());
        } else {
            dispatch(setUserLoggedInFalse());
        }
    }, [])

    if (isUserLoggedInn) {
        if (window.location.pathname == "/lor") {
            return (<LandingScreen2 />)
          }
        return (<Portal />)
    } else {
        
        return (<LandingScreen2 />)
    }
}


export default SplashScreen;