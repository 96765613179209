import React from "react";

function SVGComponent() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 37 37"
    >
      <g data-name="Group 3835" transform="translate(-285 -203)">
        <rect
          width="37"
          height="37"
          fill="#fff"
          data-name="Rectangle 3326"
          rx="5"
          transform="translate(285 203)"
        ></rect>
        <path
          fill="#6a5dfb"
          d="M10.021 19.764h-1.81v-9.4L1.687 2.593V1.125h17.707v1.461l-6.213 7.766V16.6zm-.568-1.243h.054l2.432-2.432V9.916l6.038-7.548H3.12l6.333 7.54z"
          transform="translate(292.959 211.055)"
        ></path>
      </g>
    </svg>
  );
}

export default SVGComponent;
