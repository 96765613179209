import React ,{ useState, useEffect } from 'react';
import axios from 'axios';
import { MW_URL } from "../../../config";
import moment from "moment";
import { Button, Modal, Row, Col } from 'react-bootstrap';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import QuillEditor from '../quillEditor';
import {GoPencil} from 'react-icons/go';
import ValidateForm from "../../../utils/formValidation";
import LoadingAnimation from "../../../utils/loadingAnimation";
import { ToastContainer, toast } from "react-toastify";





const Queries = () => {

    const [openModal, setOpenModel] = useState(false)
    const [queriesList, setqueriesList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [counsellorSuggestionList, setcounsellorSuggestionList] = useState([]);
    const [counsellorEmailSelected, setcounsellorEmailSelected] = useState("");
    const [counsellorNameSelected, setcounsellorNameSelected] = useState("");
    const [queryForCouncellor, setqueryForCouncellor] = useState("");
    const [selQuery, setSelQuery] = useState({})




    const createUniquieId = () => {
      const head = Date.now().toString(36);
      const tail = Math.random().toString(36).substr(2);
      return head + tail;
    };




    useEffect(() => {
      getAllQueries();   
  }, [queryForCouncellor]);

    const getAllQueries = () => {
      setIsLoading(true);
       axios
        .post(`${MW_URL}` + "getQueriesBysenderId", {
          senderId: JSON.parse(localStorage.getItem("userData")).email,
        })
        .then((getQueriesByRecipientIdResponse) => {
          setqueriesList(getQueriesByRecipientIdResponse.data);
          console.log(getQueriesByRecipientIdResponse.data, "ja")
          setIsLoading(false);
        })

    }

    // get users list from db and filter out all counsellors to show in faq form
  const getlistofusersFromDb = () => {
    setIsLoading(true);
    axios
      .post(`${MW_URL}` + "users", {})
      .then((getUsersResponse) => {
        if (
          getUsersResponse &&
          getUsersResponse.data &&
          getUsersResponse.data[0]
        ) {
          var counsellorSuggList = [];
          for (var i = 0; i < getUsersResponse.data.length; i++) {
            var currentObj = getUsersResponse.data[i];
            if (currentObj.userType === "counsellor") {
              counsellorSuggList.push({
                key: currentObj.email,
                username: currentObj.username,
                email: currentObj.email,
                userType: currentObj.userType,
              });
            }
          }
          console.log("userList", counsellorSuggList);

          setcounsellorSuggestionList(counsellorSuggList);
          setIsLoading(false);

        }
      })
      .catch((err) => {
        console.log("err >> ", err);
      });
    };

  const handleAddQueryBtnClick = () => {
      setOpenModel(true);
      getlistofusersFromDb();
  }

   // handle change on the counsellor select
  const handleChangeCounsellorSelect = (e) => {
    const { value, name } = e.target;
    console.log(value);
    var counsellorDetails = value.split("+username+");
    setcounsellorEmailSelected(counsellorDetails[0]);
    setcounsellorNameSelected(counsellorDetails[1]);
    console.log(counsellorDetails[0], counsellorDetails[1], "em", 'name')
  };


   //submit query form, add query into db.
   const onSubmitFaqQuery = () => {
    if (counsellorEmailSelected != "") {
    if (queryForCouncellor != "") {
      if (ValidateForm.inputFieldValueCompositionCheck("queries", false))
        setIsLoading(true);
      var queryDataTosend = {
        queryId: createUniquieId(),
        recipientId: counsellorEmailSelected,
        recipientName: counsellorNameSelected,
        senderId: JSON.parse(localStorage.getItem("userData")).email,
        senderName: JSON.parse(localStorage.getItem("userData")).username,
        message: queryForCouncellor,
        updateId: createUniquieId(),
        updateMessage:
          JSON.parse(localStorage.getItem("userData")).username +
          " added a query",
      };
      console.log("queryDataTosend", queryDataTosend);

      axios
        .post(`${MW_URL}` + "addQuery", queryDataTosend)
        .then((response) => {
          var status = "NA";
          if (response && response.data && response.data.STATUS)
            status = response.data.STATUS;
          if (status.toUpperCase() === "SUCCESS") {
            setIsLoading(false);
            setcounsellorEmailSelected("");
            setcounsellorNameSelected("");
            setqueryForCouncellor("");
            setOpenModel(false)
          } else {

          }
        })
        .catch((err) => {
          console.log("err >> ", err);
        });

      // }
      // else {
      //   console.log("fill all mandatory fields ");
      // }
      } else {
        console.log("err")
        toast.error("Please Enter Your Query", {
          autoClose: 2000,
          pauseOnHover: true,
        });
      }
    } else {
      console.log("err")
      toast.error("Please Select Councellor", {
        autoClose: 2000,
        pauseOnHover: true,
      });
    }
  };



  return (
    <div className='queries' >
        {isLoading ? <LoadingAnimation isVisible={true} /> : null}
        <div className='m-2 d-flex align-items-center'>
          <div className='pt-3'>
            <p className='essayHeadText4'>Queries</p>
          </div>
          {queriesList.length > 0 && (
              <div className='mx-3'>
                <button onClick={() => handleAddQueryBtnClick()} className='essayBtn1'>Add</button>
              </div>
          )}
        </div>

        {queriesList.length > 0 ? (
          <div>
              <Row className='p-2'>
                <Col sm={8} style={{"height":"82vh", "overflow":"scroll"}}>
                  {queriesList.map((obj, index) => (
                       <div onClick={() => setSelQuery(obj)} key={index} className='essaySec px-2 m-1'>
                          <div className='d-flex justify-content-between'>
                              <p className='essayHeadText3'>{obj.senderName}</p>
                              {obj?.replys?.length > 0 && (<div className={selQuery === obj ? 'essayNormalText2 selectedText' : 'essayNormalText2'}>Replied</div>)}
                              
                          </div>
                          <p className='essayNormalText2 m-0'>{obj.message}</p>
                          <p className='queryLightText py-2'>{obj.recipientName} | {moment(obj.updatedDate).format('DD/MM/yyyy')}</p>
                      </div>
                  ))}

                  {/* <div className='essaySec px-2 m-1'>
                      <div className='d-flex justify-content-between'>
                          <p className='essayHeadText3'>Title Here</p>
                          <div><GoPencil/></div>
                      </div>
                      <p className='essayNormalText2 m-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s</p>
                      <p className='queryLightText py-2'>Counsellor Name | 03 Jan 2022</p>
                  </div> */}
                </Col>
                <Col sm={4}>
                      {/* rightside */}
                    {selQuery?.replys?.length > 0 ? (
                        <div className="counQueriesCard3">
                          {/* <div className="counsQueryImgbg"></div> */}
                            {/* <div className="mr-2 my-2"> */}
                              {/* <StudentProfile studentId={selQuery?.recipientId} className="counsQueryImgbg" /> */}
                            {/* </div> */}
                            <div className="p-1 m-2">
                              <p className='essayHeadText3'>{selQuery.recipientName}</p>
                              <p className="counDashNormalText m-0">
                                {selQuery?.replys}
                              </p>
                              <p className='queryLightText pt-2'>{moment(selQuery.updatedDate).format('DD/MM/yyyy')}</p>
                          </div>
                        </div>
                    ) : (<p className='dashSpanText'>No Reply</p>)}
                </Col>
              </Row>
          </div>            
        ) : (
          <div className='noQueriesWrap '>
              <div>
                  <p className='essayNormalText1'>No Queries </p>
                  <button onClick={() => handleAddQueryBtnClick()} className='essayBtn2 px-4'>Add</button>
              </div>
          </div>
        )}

        

        <Modal  show={openModal} size='lg' onHide={()=> setOpenModel(false)} >
            <div id="query" className='essayModal'>
              <div className='d-flex justify-content-between '>
                <div>
                    <p className='essayHeadText4 m-3 p-2 '>Add Query </p>
                    <div className='querylabelWrapper'>
                        <label className='my-0 mx-3' htmlFor="queries">Choose an option:</label>
                    </div>
                    <select id='queries' name='queries' onChange={(e) => handleChangeCounsellorSelect(e)} className='selectQuery my-2 mx-3'>
                      <option value="" >councellor</option>
                      {counsellorSuggestionList.map((obj, index) => (
                        <option key={index}  value={obj.email + "+username+" + obj.username} className='selectQuery'>{obj.username}</option>
                      ))}
                    </select>
                    <ToastContainer />

                </div>
                <div className='m-2'>
                    <AiOutlineCloseCircle onClick={() => setOpenModel(false)} className='essayCloseBtn'/>
                </div>
              </div>
              <div className='queryModalContent'>
                {/* <p className='essayNormalText1'>Enter your query here</p> */}
                  <textarea 
                    type='text'
                    className="essayNormalText1 queryTextInbox"
                    value={queryForCouncellor}
                    onChange={(e) => setqueryForCouncellor(e.target.value)}
                    placeholder='Enter your query here' 
                  />
              </div>
              {/* <div>
                  <QuillEditor placeholder={'Enter your query here'} customClass={'queryModalContent'} />
              </div> */}

              <div className='modalSaveBtn mr-3'>
                <button onClick={() => onSubmitFaqQuery()} className='essayBtn2'>Send</button>
              </div>
            </div>
        </Modal>

    </div>
  )
}

export default Queries;