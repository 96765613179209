import React, { useEffect, useState, useRef, useCallback } from "react";
import BlankProfile from "../../../../imageAssets/profilepic.png"
import {AiOutlinePlus} from 'react-icons/ai'
import axios from "axios";
import { MW_URL } from "../../../../config";




const ProfileSection = () => {

    const username = JSON.parse(localStorage.getItem("userData")).username
    const [resumeDetails, setResumeDetails] = useState([])
    const [profileImage, setProfileImg] = useState()

    const inputRef = useRef(null);

    useEffect(() => {
        getProfileImg();
        getResumeDetails();
    }, [])

    const createUniquieId = () => {
        const head = Date.now().toString(36);
        const tail = Math.random().toString(36).substr(2);
        return head + tail;
        };

    const getResumeDetails = () => {
         axios
            .post(`${MW_URL}` + "getStudentResumeDetails", {senderId : JSON.parse(localStorage.getItem("userData")).email})
                .then((getStudentResumeDetails) => {
                    console.log(getStudentResumeDetails, "getStudentResumeDetails")
                    if(getStudentResumeDetails.status === 200) {
                        setResumeDetails(getStudentResumeDetails.data[0])
                        console.log("ok")
                    }
                })
            .catch((err) => {
                console.log("err >> ", err);
                });
    }


    const onChangePicture = (e) => {
        const file = e.target.files[0];
        if(profileImage){
            console.log("have img")
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    var dataToSend = {
                        profileImageId: createUniquieId(),
                        profileImage: e.target.result,
                        senderId: JSON.parse(localStorage.getItem("userData")).email,
                    }
        
                    console.log(dataToSend, "datatosnd")
        
                    axios.post(`${MW_URL}` + "updateStudentProfileImage", dataToSend)
                        .then((getResponse) => {
                            console.log(getResponse, "getResponse")
                            if(getResponse.status === 200){
                                getProfileImg()
                            }
                        })
            };
            reader.readAsDataURL(file);
            }
        }else{
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    var dataToSend = {
                        profileImageId: createUniquieId(),
                        profileImage: e.target.result,
                        senderId: JSON.parse(localStorage.getItem("userData")).email,
                    }
        
                    console.log(dataToSend, "datatosnd")
        
                    axios.post(`${MW_URL}` + "addStudentProfileImage", dataToSend)
                        .then((getResponse) => {
                            console.log(getResponse, "getResponse")
                            if(getResponse.status === 200){
                                getProfileImg()
                            }
                        })
            };
            reader.readAsDataURL(file);
            }
        }
        
    };

    const getProfileImg = () => {
        axios.post(`${MW_URL}` + "getStudentProfileDetails", {senderId : JSON.parse(localStorage.getItem("userData")).email})
            .then((getResponse) => {
                console.log(getResponse?.data[0]?.profileImage, "getprofileimage")
                setProfileImg(getResponse?.data[0]?.profileImage)
            })
    }

    const handleAddIconClick = () => {
        inputRef.current.click();
      };

  return (
    <div className='profileSec'>
                <div className='proImageSec'>
                    <div className='text-center'>
                        {profileImage ? (<img className="proImg " width={70} height={70} src={profileImage} />) : (
                                <img className="proImg" width={50} height={50} src={BlankProfile} />
                        )}
                        {!profileImage && (<p className='dashLightText py-1'>no profile image added</p>)}
                        <div className="progress mt-3" style={{"height": "5px"}}>
                            <div className="progress-bar" style={{"width": "75%"}} ></div>
                        </div>
                    </div>
                    {/* <div >
                        <AiOutlinePlus className='addImgBtn' />
                        <input 
                            id="xxxx"  
                            accept="image/*" 
                            onChange={onChangePicture}
                            type="file" />
                    </div> */}

                         <div >
                            <AiOutlinePlus onClick={handleAddIconClick} className='addImgBtn' />
                        </div>
                        <input
                            id="xxxx"
                            accept=".png, .jpg, .jpeg"
                            ref={inputRef}
                            onChange={onChangePicture}
                            type="file"
                            style={{ display: 'none' }} // Hide the input visually
                        />
                    
                </div>
                <div className='proDetailSec'>
                    <h1 className='profileTitle mb-0'>{username}</h1>
                    <p className='dashLightText my-1'>{resumeDetails?.profession || "Proffession"}</p>
                    <p className='dashNormalText'>{resumeDetails?.studentBio || "Student Bio"}</p>
                    <p className='dashTitle2'>Skills</p>
                    <div className='d-flex'>
                        {resumeDetails?.skills?.split(',').map((skill, index) => (
                            <p key={index} className='skillBg dashNormalText'>
                                {skill.trim()}
                            </p>
                        ))}
                    </div>
                    <p className='dashTitle2'>Contact</p>
                    <div className='pl-2 mb-3' style={{"background":"#F5F7FB"}}>
                        <div className='row py-1 pr-3 border-bottom'>
                                <div className='col-6 d-flex justify-content-start dashLightText'>Phone Number</div>
                                <div className='col-6 d-flex justify-content-end dashNormalText'>{resumeDetails?.phoneNumber || "1234567890"}</div>
                        </div>
                        <div className='row py-1 pr-3 border-bottom'>
                                <div className='col-6 d-flex justify-content-start dashLightText'>Email</div>
                                <div className='col-6 d-flex justify-content-end dashNormalText'>{resumeDetails?.email || "@gmail.com"}</div>
                        </div>
                        <div className='row py-1 pr-3 border-bottom'>
                                <div className='col-6 d-flex justify-content-start dashLightText'>Address</div>
                                <div className='col-6 d-flex justify-content-end dashNormalText'>
                                    <div>
                                        <p className='m-0'>{resumeDetails?.address || "Address"}</p>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <p className='dashTitle2 my-1'>Work</p>
                    <div className='pl-2 mb-3' style={{"background":"#F5F7FB"}}>
                        {resumeDetails?.work?.map((obj, index) => (
                            <div key={index} className='' style={{"background":"#F5F7FB"}}>
                                <p className='dashNormalText m-0'>{obj.experiencePosition || "Designation"}</p>
                                <p className='dashNormalText my-1'>{obj.experienceProjectName || "Company"}</p>
                                <p className='dashLightText m-0'>{obj.experienceStartDate || "Start Date"} - {obj.experienceEndDate || "End Date"}</p>
                            </div>
                        ))}
                        {!resumeDetails?.work && (
                                <div className='border-bottom pb-2'>
                                    <p className='dashNormalText m-0'>Designation</p>
                                    <p className='dashNormalText my-1'>Company</p>
                                    <p className='dashLightText m-0'>Year</p>
                                </div> 
                        )}
                    </div>

                    <p className='dashTitle2 my-1'>Education</p>
                    <div className='pl-2 mb-3' style={{"background":"#F5F7FB"}}>
                        {resumeDetails?.education?.map((obj, index) => (
                            <div key={index} className='border-bottom pb-2'>
                                <p className='dashNormalText m-0'>{obj?.educationDetailsCourse || "Course"}</p>
                                <p className='dashNormalText my-1'>{obj?.educationDetailsUniversity || "College"}</p>
                                <p className='dashLightText m-0'>{obj?.educationYear || "Year"}</p>
                            </div>
                        ))}
                        {!resumeDetails?.education && (
                             <div className='border-bottom pb-2'>
                                <p className='dashNormalText m-0'>Course</p>
                                <p className='dashNormalText my-1'>College</p>
                                <p className='dashLightText m-0'>Year</p>
                            </div> 
                        )}
                        
                    </div>
                </div>
            </div>
  )
}

export default ProfileSection