import React, {useState, useEffect} from 'react'
import axios from "axios";
import { MW_URL } from "../../../config";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import LoadingAnimation from "../../../utils/loadingAnimation";
import ModalComponent from "../../../utils/modalComponent";
import AddLogoImg from '../../../assets1/images/add-logo.jpg'



import {FaSearch} from "react-icons/fa";
import Queries from './portals/queries';
import Documents from './portals/documents';
import Essays from './portals/essays';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {IoIosPaperPlane} from 'react-icons/io';
import {FiPaperclip} from 'react-icons/fi';
import TimelineSec from './portals/TimelineSec';
import {BiMessageAltDots} from 'react-icons/bi'
import College from '../../students/college';
import StudentProfile from '../portals/StudentImage';



const CounsellorStud = () => {

    const [tab, setTab] = useState("1")
    const [tab2, setTab2] = useState("1")

    const [show, setShow] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [chatList, setChatList] = useState([]);
    const [chat, setChat] = useState("");


    const [selectedStudent, setSelectedStudent] = useState({})
    const [searchStudentKey, setSearchStudentKey] = useState([])

    const [listOfStudents, setlistOfStudents] = useState([]);
    const [listOfStudentsTemp, setlistOfStudentsTemp] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);

    const [listOfSelectedCollagesArr, setlistOfSelectedCollagesArr] = useState(
        []
      );
    const [essaysTemplateList, setessaysTemplateList] = useState([]);
    const [updatesList, setupdatesList] = useState([]);
    const [essaysList, setessaysList] = useState([]);
    const [approvedEssayCount, setApprovedEssayCount] = useState(0)
    const [resumeDetails, setResumeDetails] = useState([])

    const [listOfCollageDetailsArr, setlistOfCollageDetailsArr] = useState([]);
    const [collageMetaData, setcollageMetaData] = useState({
        page: 0,
        total: 0,
        per_page: 100,
      });
    const [selectedCollege, setSelectedCollege] = useState([])
    const [selectedCollageId, setSelectedCollageId] = useState("all")
    const [selectedCollageRec, SetSelectedCollageRec] = useState([])
    const [isAnyRecommendation, setIsAnyRecommendation] = useState(false)
    const [listOfSelectedCollageDetailsArr, setlistOfSelectedCollageDetailsArr] = useState([]);
    const [collageSearchKeyword, setcollageSearchKeyword] = useState("");







    useEffect(() => {
        getlistofusersFromDb();
        collageListfromDb(1, false)
      }, []);
    
    useEffect(() => {
        getResumeDetails()
        getStudentEssays(selectedStudent)
        setApprovedEssayCount(0)
        getCollegeRecommend()
      }, [selectedStudent]);

    useEffect(() => {
        getAllChats()
      }, [isUseEffectCallRequired]);


    const createUniquieId = () => {
        const head = Date.now().toString(36);
        const tail = Math.random().toString(36).substr(2);
        return head + tail;
        };
    
    const getStudentEssays = (student) => {
            console.log("==============getStudentEssays fun  ========", student);
            // setIsLoading(true);
            // setIsUseEffectCallRequired(false)
            var selectedCollages = [];
            var selectedCollagesIds = [];
            axios
              .post(`${MW_URL}` + "getSelectedListOfCollages", {
                email: student?.email,
              })
              .then((getSelectedListOfCollagesresponse) => {
                if (
                  getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
                    .length > 0
                ) {
                  setlistOfSelectedCollageDetailsArr(
                    getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages
                  );
                  getSelectedListOfCollagesresponse.data[0].listOfSelectedCollages.map(
                    (collage) => {
                      selectedCollages.push(collage);
                      selectedCollagesIds.push(collage.collageId);
                    }
                  );
                  setlistOfSelectedCollagesArr(selectedCollages);
                  var essayReqIds = [];
                  var templateArr = [];
                  var essaysListToPush = [];
                  var essaysRequirementIds = [];
                  axios
                    .post(`${MW_URL}` + "get_essay_requirement", selectedCollages)
                    .then((getEssaysTemplateResponse) => {
                      var status = "NA";
                      if (
                        getEssaysTemplateResponse &&
                        getEssaysTemplateResponse.data &&
                        getEssaysTemplateResponse.data.STATUS
                      )
                        status = getEssaysTemplateResponse.data.STATUS;
                      if (status.toUpperCase() === "SUCCESS") {
                        setessaysTemplateList(getEssaysTemplateResponse.data.data);
                        getEssaysTemplateResponse.data.data.map((templateRes) => {
                          if (templateRes.essayRequirements.length != 0) {
                            templateRes.essayRequirements.map((esaaReq) => {
                                console.log(templateRes, "temp -collage- de")
                              essayReqIds.push(esaaReq.essayReqId);
                              templateArr.push({
                                template: esaaReq.question,
                                collageId: templateRes.collageId,
                                name: templateRes.name,
                                essayReqId: esaaReq.essayReqId,
                                length: esaaReq.length,
                                requirement: esaaReq.requirement,
                                answered: essaysRequirementIds.includes(
                                  esaaReq.essayReqId
                                ),
                              });
                            });
                          }
                        });
                        axios
                          .post(`${MW_URL}` + "getEssays", { ownerId: student?.email })
                          .then((getAllEssaysResponse) => {
                            var essaysListToPush = [];
                            var essaysRequirementIds = [];
                            let approvedCount = 0
                            if (getAllEssaysResponse.data.length > 0) {
                              getAllEssaysResponse.data.map((essay) => {
                                // console.log(essay, "essay by stud")
                                if (
                                  essay.ownerId == student?.email &&
                                  selectedCollagesIds.includes(essay.collageId) &&
                                  essayReqIds.includes(essay.essayReqId)
                                ) {

                                    if(essay.isApproved) {
                                        approvedCount += 1
                                    }

                                  essaysListToPush.push({
                                    essayId: essay.essayId,
                                    content: essay.content,
                                    newEditorContentEssay:essay.newEditorcontentEssay,
                                    typeOfDocument: essay.typeOfDocument,
                                    collageId: essay.collageId,
                                    ownerId: essay.ownerId,
                                    feedbacks: essay.feedbacks,
                                    essayReqId: essay.essayReqId,
                                    isApproved: essay.isApproved,
                                    updatedDate: moment(essay.updatedDate).format("DD-MM-YYYY"),
                                  });
                                }
                                essaysRequirementIds.push(essay.essayReqId);
                              });
                              const params = new URLSearchParams(
                                window.location.search
                              );
                              if (params.get("id")) {
                                const selectedEssay = essaysListToPush.find(
                                  (essay) => essay.essayId == params.get("id")
                                );
                                // viewEssay(selectedEssay);
                              } else {
                                console.log("No params");
                              }
                              axios
                                .post(`${MW_URL}` + "getUpdates", {
                                  recipientId: JSON.parse(
                                    localStorage.getItem("userData")
                                  ).email,
                                  senderId: student?.email,
                                })
                                .then((getUpdatesResponse) => {
                                  setupdatesList(getUpdatesResponse.data);
                                  setessaysTemplateList(
                                    templateArr.map((reqEssay) => {
                                      return {
                                        ...reqEssay,
                                        answered: essaysRequirementIds.includes(
                                          reqEssay.essayReqId
                                        ),
                                      };
                                    })
                                  );
                                  // setIsLoading(false);
                                })
                                .catch((err) => {
                                  console.log("err >> ", err);
                                });
                            } else {
                              setessaysList([]);
                              setApprovedEssayCount(0)
                            }
                            setessaysList(essaysListToPush);
                            setApprovedEssayCount(approvedCount)
                            console.log(essaysListToPush, "essay list")
                          })
                          .catch((err) => {
                            console.log("err >> ", err);
                          });
                      } else {
                        // setIsLoading(false);
                      }
                    })
                    .catch((err) => {
                      console.log("err >> ", err);
                    });
                } else {
                  setlistOfSelectedCollagesArr([]);
                  setessaysTemplateList([]);
                  setessaysList([]);
                  setupdatesList([]);
                  // setIsLoading(false);
                }
              })
              .catch((err) => { });
        };

    const getAllChats = () => {
      const recipientId = JSON.parse(localStorage.getItem("userData")).email;
      const senderId = JSON.parse(localStorage.getItem("userData")).email; // Use the same user for sender and recipient for this example
  
      const getChatsByRecipient = axios.post(`${MW_URL}` + "getChatsByRecipientId", { recipientId });
      const getChatsBySender = axios.post(`${MW_URL}` + "getChatsBysenderId", { senderId });

      console.log(getChatsByRecipient, "getChatsByRecipient")
      console.log(getChatsBySender, "getChatsBySender")
  
      Promise.all([getChatsByRecipient, getChatsBySender])
          .then(([recipientResponse, senderResponse]) => {
              // Concatenate the arrays and sort by updatedDate
              const combinedChats = recipientResponse.data.concat(senderResponse.data);
              const sortedChats = combinedChats.sort((a, b) => new Date(a.updatedDate) - new Date(b.updatedDate));
  
              setChatList(sortedChats);
              console.log(sortedChats, "sortedChats")
              setIsUseEffectCallRequired(false);
              // setIsLoading(false)
          })
          .catch(error => {
              console.error("Error fetching chat data:", error);
              // setIsLoading(false)
          });
        }
    
    const onSubmitChat = () => {
            var queryDataTosend = {
              chatId: createUniquieId(),
              recipientId: selectedStudent?.email,
              recipientName: selectedStudent?.username,
              senderId: JSON.parse(localStorage.getItem("userData")).email,
              senderName: JSON.parse(localStorage.getItem("userData")).username,
              message: chat,
              updateId: createUniquieId(),
              updateMessage:
                JSON.parse(localStorage.getItem("userData")).username +
                " added a chat",
            };
            console.log("queryDataTosend", queryDataTosend);
            axios
            .post(`${MW_URL}` + "addChat", queryDataTosend)
            .then((response) => {
              var status = "NA";
              if (response && response.data && response.data.STATUS)
                  status = response.data.STATUS;
                  console.log(response.data, "data")
              if (status.toUpperCase() === "SUCCESS") {
                  setIsUseEffectCallRequired(true)
                  setChat("")
                  console.log("completed")
              }
            })
            .catch((err) => {
              console.log("err >> ", err);
            });
      }

    const getlistofusersFromDb = () => {
        axios
          .post(`${MW_URL}` + "users", {})
          .then((getUsersResponse) => {
            if (
              getUsersResponse &&
              getUsersResponse.data &&
              getUsersResponse.data[0]
            ) {
              // setIsLoading(true)
              axios
                .post(`${MW_URL}` + "getUpdates", {
                  recipientId: JSON.parse(localStorage.getItem("userData")).email,
                  senderId: false,
                })
                .then((getUpdatesResponse) => {
                  // console.log("getUpdatesResponse", getUpdatesResponse.data);
                  //  setupdatesList(getUpdatesResponse.data);
    
                  var userList = [];
                  for (var i = 0; i < getUsersResponse.data.length; i++) {
                    var currentObj = getUsersResponse.data[i];
                    var currentDate = new Date();
                    currentDate = currentDate.setDate(currentDate.getDate() - 2);
                    if (
                      currentObj.counsellorId ===
                      JSON.parse(localStorage.getItem("userData")).email
                    ) {
                      userList.push({
                        key: currentObj?.email,
                        username: currentObj?.username,
                        email: currentObj?.email,
                        userType: currentObj?.userType,
                        major: currentObj?.major,
                        updates: getUpdatesResponse?.data,
                        updatesNum: getUpdatesResponse?.data.filter(
                          (updte) =>
                            updte.senderId == currentObj?.email &&
                            moment(updte.updatedDate, "YYYY-MM-DD").isSameOrBefore(
                              new Date(),
                              "day"
                            ) &&
                            moment(updte.updatedDate, "YYYY-MM-DD").isSameOrAfter(
                              currentDate,
                              "day"
                            )
                        ).length,
                      });
                    }
                  }
                  console.log("userList===>", userList);
                  setlistOfStudents(userList);
                  setlistOfStudentsTemp(userList)
                  setSelectedStudent(userList[0])
                  let params = new URLSearchParams(window.location.search);
                  if (params.get("studentEmail")) {
                    // console.log("studentEmail", params.get("studentEmail"));
                    const selectedUser = userList.find(
                      (user) => user?.email == params.get("studentEmail")
                    );
                    // console.log("selectedUser", selectedUser);
                    // showTextEditorMain(selectedUser);
                  }
                  setIsLoading(false)
                })
                .catch((err) => {
                  setIsLoading(false)
                  console.log("err >> ", err);
                });
            }
          })
          .catch((err) => {
            setIsLoading(false)
            console.log("err >> ", err);
          });
      };
    
    const searchSelectedStudent = () => {
        if (searchStudentKey?.length >= 2) {
            const lowerCaseSearchKey = searchStudentKey?.toLowerCase();
            const filteredStudents = listOfStudentsTemp?.filter((student) => {
              const studentName = student?.username.toLowerCase();
              return studentName?.includes(lowerCaseSearchKey);
            });
            setlistOfStudents(filteredStudents);
          } else {
            setlistOfStudents(listOfStudentsTemp);
          }

    }

    const getResumeDetails = () => {
      axios
         .post(`${MW_URL}` + "getStudentResumeDetails", {senderId : selectedStudent?.email})
             .then((getStudentResumeDetails) => {
                 console.log(getStudentResumeDetails, "getStudentResumeDetails")
                 if(getStudentResumeDetails.status === 200) {
                     setResumeDetails(getStudentResumeDetails.data[0])
                     console.log("ok")
                    //  setIsLoading(false)
                 }
             })
         .catch((err) => {
             console.log("err >> ", err);
             });
    }

    const collageListfromDb = (page, filter) => {
        // setIsLoading(true)
        var selectedCollIds = [];
        axios
          .post(`${MW_URL}` + "getCollegeDetailsByOffset", {
            offset: page,
            limit: 100,
            filter: filter,
          })
          .then((collegescorecardresponse) => {
            // console.log(
            //   "collegescorecardresponse",
            //   collegescorecardresponse.data
            // );
            var tempArr = [];
            collegescorecardresponse?.data?.collageDetails?.map((coll, index) => {
              selectedCollIds.includes(coll?.collageId)
                ? tempArr.push({ ...coll, selected: true })
                : tempArr.push({ ...coll, selected: false });
            });
            // console.log("tempArr", tempArr);
            setSelectedCollege(tempArr[0])
            if (page == 0) {
              setlistOfCollageDetailsArr(tempArr);
            } else {
              setlistOfCollageDetailsArr([
                ...listOfCollageDetailsArr,
                ...tempArr,
              ]);
            }
            setcollageMetaData(collegescorecardresponse.data.metadata);
            // console.log(collegescorecardresponse.data.metadata, "meta")
            // setIsLoading(false);
          })
          .catch((err) => { });
    }

    const getCollegeRecommend = () => {
        console.log(selectedStudent?.email, "email")
        axios
          .post(`${MW_URL}` + "getCollegeRecommend", { recipientId: selectedStudent?.email })
          .then((getAddCollageRecommendationResponse) => {
            console.log(getAddCollageRecommendationResponse, "getAddCollageRecommendationResponse")
            if(getAddCollageRecommendationResponse?.data[0]?.recommendedCollages){
              console.log(getAddCollageRecommendationResponse,"ddddd")
              SetSelectedCollageRec(getAddCollageRecommendationResponse?.data[0]?.recommendedCollages)
              setIsAnyRecommendation(true)
            } else {
              SetSelectedCollageRec([])
              setIsAnyRecommendation(false)
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
    }

    const collageRecSelected = (collage) => {
        console.log(collage, "sel")
        if(selectedCollageRec?.some(item => item.collageId === collage.collageId)){
            // console.log("includes")
            const updatedList = selectedCollageRec.filter(item => item.collageId !== collage.collageId);
            SetSelectedCollageRec(updatedList);
            console.log(selectedCollageRec, "selected colrec")
        } else {
              SetSelectedCollageRec([...selectedCollageRec, collage])
              console.log(selectedCollageRec, "selected colrec")
        }
        
    }

    const recommendBtnClick = () => {
      console.log(isAnyRecommendation, "isany")
        if(isAnyRecommendation) {
          console.log("update")
          var dataToSend = {
            recipientId: selectedStudent?.email,
            recommendedCollages: selectedCollageRec,
            senderId: JSON.parse(localStorage.getItem("userData")).email,
          };
          console.log("dataToSend-update", dataToSend)
          axios
          .post(`${MW_URL}` + "updateCollegeRecommend", dataToSend)
          .then((getUpdateCollegeRecommend) => {
            console.log(getUpdateCollegeRecommend, "getUpdateCollegeRecommend")
            if(getUpdateCollegeRecommend.status === 200){
              SetSelectedCollageRec(getUpdateCollegeRecommend?.data?.recommendedCollages)
              setIsAnyRecommendation(true)
              setShow(false)
            }
          })
          .catch((err) => {
            console.log("err >> ", err);
          });
        }else {
          if (selectedCollageRec.length > 0) {
            var dataToSend = {
              recipientId: selectedStudent?.email,
              recipientname: selectedStudent?.username,
              recommendedCollages: selectedCollageRec,
              senderId: JSON.parse(localStorage.getItem("userData")).email,
              sendername: JSON.parse(localStorage.getItem("userData")).username,
              recommendedId: createUniquieId(),
            };
            console.log("dataToSend-add", dataToSend)
            axios
            .post(`${MW_URL}` + "addCollegeRecommend", dataToSend)
            .then((getAddCollageRecommendationResponse) => {
              console.log(getAddCollageRecommendationResponse, "getAddCollageRecommendationResponse")
              if(getAddCollageRecommendationResponse.status === 200){
                SetSelectedCollageRec(getAddCollageRecommendationResponse?.data?.recommendedCollages)
                setIsAnyRecommendation(true)
                setShow(false)
              }
            })
            .catch((err) => {
              console.log("err >> ", err);
            });
          }
        }     
    }

    const searchCollageByKeyword = () => {
      // console.log("collageSearchKeyword", collageSearchKeyword);
      var selectedCollIds = [];
      listOfSelectedCollageDetailsArr.map((collage) => {
        selectedCollIds.push(collage?.collageId);
      });
  
      // setIsLoading(true);
      axios
        .post(`${MW_URL}` + "getCollegeDetailsByOffsetAndSearchKeyword", {
          offset: 0,
          limit: 10,
          keyword: collageSearchKeyword,
        })
        .then((getCollegeDetailsResponse) => {
          // console.log("getCollegeDetailsResponse", getCollegeDetailsResponse);
          var tempArr = [];
          getCollegeDetailsResponse.data.collageDetails.map((coll, index) => {
            selectedCollIds.includes(coll?.collageId)
              ? tempArr.push({ ...coll, selected: true })
              : tempArr.push({ ...coll, selected: false });
          });
          // console.log("tempArr", tempArr);
          setlistOfCollageDetailsArr(tempArr);
          setcollageMetaData(getCollegeDetailsResponse.data.metadata);
          // setIsLoading(false);
        })
        .catch((err) => {
          console.log("err >> ", err);
        });
    };

    const handleCollageChange = (e) => {
      const selectedValue = e.target.value;
      setSelectedCollageId(selectedValue)
      const selectedCollageObj = listOfSelectedCollagesArr.find(obj => obj.collageId === selectedValue);
    }



  return (
    <div style={{"background":"#FAFBFD"}}>
        {isLoading ? <LoadingAnimation isVisible={true} /> : null}
        <div className='row'>
            <div className='col-4 p-0'>
                {/* leftside */}
                <div className='counStudLeft'>
                    <p className='counStudHeadText p-2'>Students</p>
                    <div className='row'>
                        <div className='col-6 '>
                            <div className='counStudStatCard m-1' >
                                <div className='counStudBgSquare m-2'></div>
                                <div className='py-1' >
                                    <p className='counStudLightText px-2 my-0'>Essays</p>
                                    <p className='counStudBoldText px-2 my-0'>{essaysList?.length}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='counStudStatCard m-1' >
                                <div className='counStudBgSquare m-2'></div>
                                <div className='py-1' >
                                    <p className='counStudLightText px-2 my-0'>Approved</p>
                                    <p className='counStudBoldText px-2 my-0'>{approvedEssayCount}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='counStudStatCard m-1' >
                                <div className='counStudBgSquare m-2'></div>
                                <div className='py-1' >
                                    <p className='counStudLightText px-2 my-0'>Not Approved</p>
                                    <p className='counStudBoldText px-2 my-0'>{essaysList?.length - approvedEssayCount}</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-6'>
                            <div className='counStudStatCard m-1' >
                                <div className='counStudBgSquare m-2'></div>
                                <div className='py-1' >
                                    <p className='counStudLightText px-2 my-0'>stat</p>
                                    <p className='counStudBoldText px-2 my-0'>value</p>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className='colSearchBox my-3 mx-2'>
                        <input onChange={(e) => setSearchStudentKey(e.target.value)} type='search'placeholder='Search Students' />
                        <div onClick={() => searchSelectedStudent()} className='px-2 py-1'><FaSearch /></div>
                    </div>


                    <div className='counColLeftTab'>
                        <div onClick={() => setTab2("1")} className={tab2 === "1" ? 'colLeftSelectedBtn': 'colLeftBtn'} >OnGoing <span className={tab2 === "1" ? 'colLeftSelectedBtnSpan': 'colLeftBtnSpan'}>{listOfStudents?.length}</span></div>
                        <div onClick={() => setTab2("2")} className={tab2 === "2" ? 'colLeftSelectedBtn': 'colLeftBtn'} >Requests <span className={tab2 === "2" ? 'colLeftSelectedBtnSpan': 'colLeftBtnSpan'}>00</span></div>
                    </div>

                    {tab2 === "1" && (
                        <div className='counStudListWrap'>
                            {listOfStudents?.map((obj, index) => (
                                <div key={index}
                                    className={obj.key === selectedStudent.key ? 'counStudNameCard counStudNameCardactive': 'counStudNameCard'}
                                    onClick={() => setSelectedStudent(obj)}
                                >
                                    <div className=' m-2'>
                                      <StudentProfile studentId={obj?.email} className="counStudBgCircle" />
                                    </div>
                                    <p className='counStudNormalText py-1 my-2'>{obj?.username}</p>
                                    {/* <p className=''>{obj?.updatesNum}</p> */}
                                </div>
                            ))}
                        </div>
                    )}

                    {tab2 === "2" && (
                        <p>requested students</p>
                    )}

                    

                </div>
            </div>
            <div className='col-8 p-2'>
                {/* rightside */}
                {selectedStudent && Object.keys(selectedStudent).length > 0 && (
                    <div>
                        <div className='counStudRightHeadWrap'>
                            <StudentProfile studentId={selectedStudent?.email} className="counStudBgCircle2" />
                            <div className='mx-2'>
                                <p className='counStudHeadText2 m-0'>{selectedStudent?.username || "Student Name"}</p>
                                <p className='counStudLightText2 m-0'>last seen 1 min ago</p>
                            </div>
                            <div className='mt-2'>
                                <button onClick={() => setShowChat(true)} className="essayBtn1 px-4">Chat</button>
                            </div>
                        </div>

                        <div className='counStudNormalText2 mx-2 px-2'>
                            {resumeDetails?.studentBio || "Student Bio"}
                        </div>

                        <div className='m-3'>
                            <div className='row'>
                                <div className='col-3 '>
                                    <div className='counStudStatCard m-1' >
                                        <div className='counStudBgSquare m-2'></div>
                                        <div className='py-1' >
                                            <p className='counStudLightText px-2 my-0'>Essays</p>
                                            <p className='counStudBoldText px-2 my-0'>{essaysList?.length}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className='counStudStatCard m-1' >
                                        <div className='counStudBgSquare m-2'></div>
                                        <div className='py-1' >
                                            <p className='counStudLightText px-2 my-0'>Approved</p>
                                            <p className='counStudBoldText px-2 my-0'>{approvedEssayCount}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className='counStudStatCard m-1' >
                                        <div className='counStudBgSquare m-2'></div>
                                        <div className='py-1' >
                                            <p className='counStudLightText px-2 my-0'>Not Approved</p>
                                            <p className='counStudBoldText px-2 my-0'>{essaysList?.length - approvedEssayCount}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-3'>
                                    <div className='counStudStatCard m-1' >
                                        <div className='counStudBgSquare m-2'></div>
                                        <div className='py-1' >
                                            <p className='counStudLightText px-2 my-0'>stat</p>
                                            <p className='counStudBoldText px-2 my-0'>value</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div className='counStudRightUniversityWrap m-3'>
                            <div className='d-flex justify-content-between mx-2'>
                                <p className='counStudBoldText2'>Universities</p>
                                <p onClick={() => setShow(true)} className='counStudBoldText3'>Recommend</p>
                            </div>
                            <div className='row'>
                                <div className='col-10 mx-1 '>
                                    <div className='row'>
                                        {selectedCollageRec?.map((obj, index) => (
                                            <div className='col-3'>
                                                <div className='text-center'>
                                                    {/* <div className='counStudBgCircle3 mx-5 my-1 '></div> */}
                                                    {obj.logo? (<img width={30} height={30} src={obj?.logo} alt='logo' />)
                                                          :(<img width={30} height={30} src={AddLogoImg} alt="logo" />
                                                          )}
                                                    <p className='counStudLightText3'>{obj?.name}</p>
                                                </div>
                                            </div>
                                        ))}

                                        {/* <div className='col-3'>
                                            <div className='text-center'>
                                                <div className='counStudBgCircle3 mx-5 my-1 '></div>
                                                <p className='counStudLightText3'>University Name</p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal show={show} size='sm' onHide={() => setShow(false)}>
                            <div className='colRecomModal'>
                                <div className='d-flex justify-content-between  '>
                                    <p className='essayHeadText4 m-2 p-1'>Recommend Universities to NAME</p>
                                    <div className='m-2'>
                                        <AiOutlineCloseCircle onClick={() => setShow(false)} className='essayCloseBtn'/>
                                    </div>
                                </div>

                                <div className='colSearchBox2 mx-3 ' style={{"width":"320px"}}>
                                    <input type='search' onChange={(e) => setcollageSearchKeyword(e.target.value)} placeholder='Search Colleges' />
                                    <div onClick={() => searchCollageByKeyword()} className='px-2 py-1'><FaSearch /></div>
                                </div>

                                <div className='mx-2'>
                                    <p className='counStudBoldText2 m-1'>Recommended</p>
                                    <div className='' style={{"overflow":"scroll", "height":"25vh"}}>
                                        
                                        {selectedCollageRec?.map((obj, index) =>(
                                          <div key={index} className='colBoxSelected' style={{"maxHeight":"300px"}}>
                                              <div className="row mx-2 align-items-center">
                                                  <div className="col-2">
                                                      {obj.logo? (<img width={50} height={50} src={obj?.logo} alt='logo' />)
                                                          :(<img width={50} height={50} src={AddLogoImg} alt="logo" />
                                                          )}
                                                  </div>
                                                  <div className="col-10">
                                                      <h3 className='colNormalText m-0'>{obj?.name}</h3>
                                                      <p className='colNormalText2 my-1'>{obj?.state}, {obj?.country}</p>
                                                      <p className='colLightText m-0'>Public 4 years</p>
                                                  </div>
                                              </div>
                                          </div>
                                        ))}

                                        {selectedCollageRec?.length < 1 && (<p className='colLightText'>No selected collages</p>)}
                                        
                                        {/* <div className='colBoxSelected' style={{"maxHeight":"300px"}}>
                                            <div className="row mx-2 align-items-center">
                                                <div className="col-2">
                                                    <img  src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAATCAMAAADyMz7ZAAAAZlBMVEX/////zQD/zAD/ygD/ywD/yQD/3HT/0Cf/8cv//PX/6Kj/9+X/0TL//vv/22//+en/5Jf/5qL/6rP/78P/6a3/5Z3/11f/2WP/7bz/1Uz/4pD/0z7/zhX/9NX/2mn/3n7/9t3/4IjqDqDnAAABmklEQVQ4jZ1T27aqMAycpuWiCCggooCX///JPRM96+z9oA/moSXNTDpNAvC1rU1Oa0pgm7vtaxx01uyxuebXCoN7R8CR+fVF7DOjZQPGaG5pQaPPNKLMLA64uXdC60jLqn/EQMu6VntKXGKZayNxn4LluLl3Qh9DMH5NT+L9Qcdua6njy35hZJjOFuxMEQwtmLjZ3HkaBtLlpbXmVbGFiBHYMbJFR8wN0FXZBo8U0gE4MTQwML6IGxGrv8Q18g5UUh97MbhCMlvp+kCUCsPkj7vojADhComghrdESGP9SPYwOzsYruLqScoPRKmrzhYpLdZ+FbZ86RG5+XvfEi9ETSwmZkuq1AmihHnc8Q3NB+Kk6hnxlDs+W7BT20xNL963A3dvNkUpAyvUAVEljloz57VsDRGtiGnqi+BEh4W4wgcqxHt7JCxu6lWwY/tr5O7/R47pUUhUrL17qnCnsD0fFrJexGfuDotIigqOhp7t4PUNltBJ+ywp5kMBlFbQlo7/UOE2+vRvFx5qKg+LzjDJz+nPdK38/jf+2n4A7IwUnI2VF1MAAAAASUVORK5CYII=' />
                                                </div>
                                                <div className="col-10">
                                                    <h3 className='colNormalText m-0'>The University of lowa</h3>
                                                    <p className='colNormalText2 my-1'>lowa City, IA</p>
                                                    <p className='colLightText m-0'>Public 4 years</p>
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>
                                </div>

                                <div className='mx-2'>
                                    <p className='counStudBoldText2 m-1'>Collages</p>
                                    <div className='' style={{"overflow":"scroll", "height":"35vh"}}>
                                
                                        {listOfCollageDetailsArr.map((obj, index) =>(
                                             <div 
                                                onClick={() => collageRecSelected(obj)} 
                                                key={index} 
                                                className={selectedCollageRec?.some(item => item?.collageId === obj?.collageId) ? 'colBoxSelected' : 'colBox'}
                                                style={{"maxHeight":"300px"}}
                                            >
                                                <div className="row mx-2 align-items-center">
                                                    <div className="col-2">
                                                        {obj.logo? (<img width={50} height={50} src={obj?.logo} alt='logo' />)
                                                            :(<img width={50} height={50} src={AddLogoImg} alt="logo" />
                                                            )}
                                                    </div>
                                                    <div className="col-10">
                                                        <h3 className='colNormalText m-0'>{obj?.name}</h3>
                                                        <p className='colNormalText2 my-1'>{obj?.state}, {obj?.country}</p>
                                                        <p className='colLightText m-0'>Public 4 years</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        {/* <div className='colBox' style={{"maxHeight":"300px"}}>
                                            <div className="row mx-2 align-items-center">
                                                <div className="col-2">
                                                    <img  src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAATCAMAAADyMz7ZAAAAZlBMVEX/////zQD/zAD/ygD/ywD/yQD/3HT/0Cf/8cv//PX/6Kj/9+X/0TL//vv/22//+en/5Jf/5qL/6rP/78P/6a3/5Z3/11f/2WP/7bz/1Uz/4pD/0z7/zhX/9NX/2mn/3n7/9t3/4IjqDqDnAAABmklEQVQ4jZ1T27aqMAycpuWiCCggooCX///JPRM96+z9oA/moSXNTDpNAvC1rU1Oa0pgm7vtaxx01uyxuebXCoN7R8CR+fVF7DOjZQPGaG5pQaPPNKLMLA64uXdC60jLqn/EQMu6VntKXGKZayNxn4LluLl3Qh9DMH5NT+L9Qcdua6njy35hZJjOFuxMEQwtmLjZ3HkaBtLlpbXmVbGFiBHYMbJFR8wN0FXZBo8U0gE4MTQwML6IGxGrv8Q18g5UUh97MbhCMlvp+kCUCsPkj7vojADhComghrdESGP9SPYwOzsYruLqScoPRKmrzhYpLdZ+FbZ86RG5+XvfEi9ETSwmZkuq1AmihHnc8Q3NB+Kk6hnxlDs+W7BT20xNL963A3dvNkUpAyvUAVEljloz57VsDRGtiGnqi+BEh4W4wgcqxHt7JCxu6lWwY/tr5O7/R47pUUhUrL17qnCnsD0fFrJexGfuDotIigqOhp7t4PUNltBJ+ywp5kMBlFbQlo7/UOE2+vRvFx5qKg+LzjDJz+nPdK38/jf+2n4A7IwUnI2VF1MAAAAASUVORK5CYII=' />
                                                </div>
                                                <div className="col-10">
                                                    <h3 className='colNormalText m-0'>The University of lowa</h3>
                                                    <p className='colNormalText2 my-1'>lowa City, IA</p>
                                                    <p className='colLightText m-0'>Public 4 years</p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center m-2'>
                                    <button onClick={() => recommendBtnClick()} className='essayBtn2'>Recommend</button>
                                </div>


                            </div>
                        </Modal>

                        <Modal className="top-right-modal" show={showChat} onHide={() => setShowChat(false)} >
                            <div className='councInboxRightSec p-3'>
                                <div className='d-flex justify-content-end'>
                                    <AiOutlineCloseCircle onClick={() => setShowChat(false)} className='essayCloseBtn'/>
                                </div>
                                <div className='d-flex '>
                                    <div className=' m-2'>
                                      <StudentProfile studentId={selectedStudent?.email} className="counStudBgCircle" />
                                    </div>
                                    <div className='my-1'>
                                        <p className='counInboxBoldText m-0'>{selectedStudent?.username}</p>
                                        <div className='d-flex '>
                                            <p className='colLightText m-0'>Online</p>
                                            <div className='counOnlineCircle m-1'></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='chatArea'>
                                  {chatList?.map((obj, index) => {
                                      if(obj.recipientId === selectedStudent?.email && obj.senderId === JSON.parse(localStorage.getItem("userData")).email ){
                                          return(
                                              <div key={index} className='d-flex justify-content-end m-3'>
                                                  <div className='messageWrapOutSm p-2'>
                                                      <div className='d-flex justify-content-between'>
                                                          <p className='colLightText'>{obj.message}</p>
                                                          <p className='queryLightText'>{moment(obj.updatedDate).format("HH:mm")}</p>
                                                      </div>
                                                  </div>
                                              </div> 
                                          )
                                      }if(obj.senderId === selectedStudent?.email){
                                          return(
                                              <div key={index} className='d-flex justify-content-start m-3'>
                                                  <div className='messageWrapInSm p-2'>
                                                      <div className='d-flex justify-content-between'>
                                                          <p className='colLightText'>{obj.message}</p>
                                                          <p className='queryLightText'>{moment(obj.updatedDate).format("HH:mm")}</p>
                                                      </div>
                                                  </div>
                                              </div> 
                                          )
                                      }
                                  })}

                                    {/* <div className='d-flex justify-content-end m-3'>
                                        <div className='messageWrapOutLg'></div>
                                    </div> */}

                                </div>

                                <div className='inboxTextArea my-3'>
                                    <input value={chat} onChange={(e) => setChat(e.target.value)} type='text' placeholder='Write your message' />
                                    <div className='d-flex'>
                                        <div className='councInboxDoc mx-2'><FiPaperclip /></div>
                                        {chat?.length > 0 ? (
                                            <div onClick={() => onSubmitChat()} className='councInboxSend'><IoIosPaperPlane fill='#FFFFFF' /></div> 
                                        ) : (
                                            <div className='councInboxSend'><BiMessageAltDots fill='#FFFFFF' /></div>
                                        )}
                                    </div>
                                    
                                </div>

                            </div>
                        </Modal>

            

                        <div>
                        <div className='colTab m-3'>
                            <button onClick={() => setTab("1")} className={tab === "1" ? 'colTabBtnSel': 'colTabBtn'}>Queries</button>
                            <button onClick={() => setTab("2")} className={tab === "2" ? 'colTabBtnSel': 'colTabBtn'}>Documents</button>
                            <button onClick={() => setTab("3")} className={tab === "3" ? 'colTabBtnSel': 'colTabBtn'}>Essays</button>
                            <button onClick={() => setTab("4")} className={tab === "4" ? 'colTabBtnSel': 'colTabBtn'}>Time Lines</button>
                            {tab === '3' && (
                              <select onChange={handleCollageChange}  className="deadlineSelect mx-3 w-25">
                                {listOfSelectedCollagesArr < 1 && (<option>No Collages Selected</option>)}
                                <option value="all" >All</option>
                              { listOfSelectedCollagesArr.map((obj, index) => (
                                  <option key={index} defaultValue={obj.collageId} value={obj.collageId} >{obj.name}</option>
                              ))}
                              </select>
                            )}
                        </div>
                            {tab === '1' && (<Queries selectedStudent={selectedStudent}/>)}
                            {tab === '2' && (<Documents selectedStudent={selectedStudent}/>)}
                            {tab === '3' && (<Essays selectedStudent={selectedStudent} selectedCollageId={selectedCollageId} />)}
                            {tab === '4' && (<TimelineSec selectedStudent={selectedStudent} />)}
                            
                        </div>

                    </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default CounsellorStud;