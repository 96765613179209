import React, { useEffect, useState } from "react";
import UnderConstruction from "../../imageAssets/underConstruction.png";
import TableComponent from '../../utils/tableComponent';
import LoadingAnimation from '../../utils/loadingAnimation'
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import ModalComponent from "../../utils/modalComponent";
import SliderModal from '../../utils/sliderModal.js';
import ValidateForm from "../../utils/formValidation";
import { Input } from 'reactstrap';
import axios from 'axios';
import { MW_URL } from "../../config";
import AlertModal from "../../components/widgets/AlertModal";
import { ToastContainer, toast } from "react-toastify";




const UsersScreen = () => {


    const [height, setHeight] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [studentActiveTab, setStudentActiveTab] = useState(true);
    const [counsellorActiveTab, setCounsellorActiveTab] = useState(false);
    const [currentTab, setCurrentTab] = useState("students");
    const [addCounsellorModalOpen, setAddCounsellorModalOpen] = useState(false);
    const [editCounsellorModalOpen, setEditCounsellorModalOpen] = useState(false);
    const [isUseEffectCallRequired, setIsUseEffectCallRequired] = useState(false);
    const [viewMoreSliderVisible, setViewMoreSliderVisible] = useState(false);
    // setTimeout(() => {
    //     setIsLoading(false)
    // }, 2000);
    const [editState, setEditState] = React.useState({
        counsellorName: "",
        counsellorEmail: "",
        CounsellorMobileNum: ""
    })

    const [studentDetailsPayload, setStudentDetailsPayload] = useState({
        totalRecords: 0,
        data: []
    });
    const [counsellorDetailsPayload, setCounsellorDetailsPayload] = useState({
        totalRecords: 0,
        data: []
    });
    const [showUserDelete, setShowUserDelete] = useState(false)
    const [showCouncellorDelete, setShowCouncellorDelete] = useState(false)
    const [studentId, setStudentId] = useState("")




    useEffect(() => {
        var windowHeight = window.innerHeight;
        setHeight(windowHeight + "px");
        setIsLoading(true)
        axios.post(`${MW_URL}` + "users", {})
            .then(getUsersResponse => {
                // setTimeout(() => {
                //     setIsLoading(false)
                // }, 2000);
                // setIsUseEffectCallRequired(false);
                var allStudentDetail = [];
                var allCounsellorDetail = [];
                if (getUsersResponse && getUsersResponse.data && getUsersResponse.data[0]) {
                    setIsLoading(false)
                    for (var i = 0; i < getUsersResponse.data.length; i++) {
                        var currentObj = getUsersResponse.data[i];
                        if (currentObj.userType === "student") {
                            var tempObj = {
                                "id": currentObj["_id"],
                                "name": currentObj.username,
                                "email": currentObj.email,
                                "mobileNum": currentObj.mobile
                            }
                            allStudentDetail.push(tempObj)
                        }
                        if (currentObj.userType === "counsellor") {
                            var tempObj = {
                                "id": currentObj["_id"],
                                "name": currentObj.username,
                                "email": currentObj.email,
                                "mobileNum": currentObj.mobile
                            }
                            allCounsellorDetail.push(tempObj)
                        }
                    }
                }
                setStudentDetailsPayload({
                    "totalRecords": allStudentDetail.length,
                    "data": allStudentDetail
                })
                setCounsellorDetailsPayload({
                    "totalRecords": allCounsellorDetail.length,
                    "data": allCounsellorDetail
                })
            })
            .catch(err => {
                setIsLoading(false)
                console.log("err >> ", err);
            })
    }, [currentTab])

    const tablinkClicked = (type) => {
        if (type === "students") {
            setStudentActiveTab(true)
            setCounsellorActiveTab(false)
            setCurrentTab(type)
        } else {
            setStudentActiveTab(false)
            setCounsellorActiveTab(true)
            setCurrentTab(type)
        }
    }

    const studentDetailsTableHandler = (dataObject) => {
        if (dataObject.type === "firstTimeLoad" || dataObject.type === "pagination") {

        }
    }

    const counsellorDetailsTableHandler = (dataObject) => {
        if (dataObject.type === "firstTimeLoad" || dataObject.type === "pagination") {

        }
    }

    const handleStudentDeleteBtnClick = (studentId) => {
        setShowUserDelete(true)
        setStudentId(studentId)
    }

    const actionStudentBtnClicked = (type) => {
        if (type === "delete") {
            setIsLoading(true)
            axios.post(`${MW_URL}` + "deleteUser/" + studentId, {})
                .then(getDeleteUsersResponse => {
                    console.log("clicked-14h", getDeleteUsersResponse)
                    if (getDeleteUsersResponse && getDeleteUsersResponse.data.STATUS === 'SUCCESS') {
                        console.log("clicked-1414")
                        setIsUseEffectCallRequired(true)
                        setShowUserDelete(false)
                        toast.success("User deleted successfully", {
                            autoClose: 1000,
                          });
                    }
                })
                .catch(err => {
                    setIsLoading(false)
                    setShowUserDelete(false)
                    console.log("err >> ", err);
                })
        }
    }

    const actionCounsellorBtnClicked = (type, receivedData) => {
        if (type === "delete") {
            setIsLoading(true)
            axios.post(`${MW_URL}` + "deleteUser/" + studentId, {})
                .then(getDeleteUsersResponse => {
                    console.log("getDeleteUsersResponse>>>", getDeleteUsersResponse)
                    if (getDeleteUsersResponse && getDeleteUsersResponse.data.STATUS === 'SUCCESS') {
                        console.log("clicked-1414")
                        setIsUseEffectCallRequired(true)
                        setShowCouncellorDelete(false)
                    }
                    // if (getDeleteUsersResponse && getDeleteUsersResponse.data && getDeleteUsersResponse.data.STATUS === "SUCCESS") {
                    //     setIsUseEffectCallRequired(true)
                    // }
                })
                .catch(err => {
                    setIsLoading(false)
                    console.log("err >> ", err);
                })
        } else if (type === "edit") {
            setEditState({
                counsellorName: receivedData.name,
                counsellorEmail: receivedData.email,
                counsellorMobileNum: receivedData.mobileNum,
                counsellorId: receivedData.id
            })
            setEditCounsellorModalOpen(true)
        } else if (type === "viewMore") {
            setEditState({
                counsellorName: receivedData.name,
                counsellorEmail: receivedData.email,
                counsellorMobileNum: receivedData.mobileNum,
                counsellorId: receivedData.id
            })
            setViewMoreSliderVisible(true)
        }
    }

    const editCounsellorModalHandler = (dataObject) => {
        if (dataObject.type === "cancel") {
            setEditCounsellorModalOpen(false)
        } else if (dataObject.type === "submit") {
            if (ValidateForm.inputFieldMandatoryCheck("editCounsellorContainer", false)) {
                if (ValidateForm.inputFieldValueCompositionCheck("editCounsellorContainer", false)) {
                    var dataToSend = {
                        "username": editState.counsellorName,
                        "email": editState.counsellorEmail,
                        "mobile": editState.counsellorMobileNum,
                        "id": editState.counsellorId
                    }
                    setIsLoading(true)
                    axios.post(`${MW_URL}` + "editCounsellor", dataToSend)
                        .then(getDeleteUsersResponse => {
                            setIsLoading(false)
                            if (getDeleteUsersResponse && getDeleteUsersResponse.data && getDeleteUsersResponse.data.STATUS === "SUCCESS") {
                                setIsUseEffectCallRequired(true)
                            }
                        })
                        .catch(err => {
                            setIsLoading(false)
                            console.log("err >> ", err);
                        })
                    setEditCounsellorModalOpen(false)
                }
            }
        }
    }

    const addCounsellorModalHandler = (dataObject) => {
        if (dataObject.type === "cancel") {
            setAddCounsellorModalOpen(false)
        } else if (dataObject.type === "submit") {
            if (ValidateForm.inputFieldMandatoryCheck("addCounsellorContainer", false)) {
                if (ValidateForm.inputFieldValueCompositionCheck("addCounsellorContainer", false)) {
                    var dataToSend = {
                        "username": editState.counsellorName,
                        "password": "Admin@123",
                        "email": editState.counsellorEmail,
                        "mobile": editState.counsellorMobileNum
                    }
                    setAddCounsellorModalOpen(false)
                    setIsLoading(true)
                    axios.post(`${MW_URL}` + "addCounsellor", dataToSend)
                        .then(getAddCounsellorResponse => {
                            if (getAddCounsellorResponse && getAddCounsellorResponse.data && getAddCounsellorResponse.data.STATUS === "SUCCESS") {
                                setIsUseEffectCallRequired(true)
                            }
                        })
                        .catch(err => {
                            setIsLoading(false)
                            console.log("err >> ", err);
                        })
                }
            }
        }
    }

    const addCouncellorBtnClicked = () => {
        setEditState({
            counsellorName: "",
            counsellorEmail: "",
            counsellorMobileNum: ""
        })
        setAddCounsellorModalOpen(true)
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setEditState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const triggerCallBack = () => {
        setEditState({
            counsellorName: "",
            counsellorEmail: "",
            counsellorMobileNum: "",
            counsellorId: ""
        })
        setViewMoreSliderVisible(false)
    }



    return (
        <div>
            {isLoading ? <LoadingAnimation isVisible={true} /> : null}
            <div className="user-page-container col-md-12 px-5 d-flex flex-column pt-sm-5 pt-2" style={{ minHeight: height }}>
                <div className="col-md-12 px-3">
                    <div className="row m-0 user-page-top-div-container pt-2" >
                        <div className={studentActiveTab === true ? "tab-header active-tab-header" : "tab-header"} onClick={() => tablinkClicked('students')} >Students</div>
                        <div className={counsellorActiveTab === true ? "tab-header active-tab-header" : "tab-header"} onClick={() => tablinkClicked('counsellors')}>Counsellors</div>
                    </div>
                    <div className="col-md-12 pt-3 px-0">
                        {currentTab === "students" ?
                            <div className="col-md-12 tab-data" >
                                <div className="col-md-12 user-details-container p-4">
                                    <TableComponent
                                        headerArray={[
                                            {
                                                key: "name",
                                                label: "Name"
                                            },
                                            {
                                                key: "email",
                                                label: "Email ID"
                                            },
                                            {
                                                key: "mobileNum",
                                                label: "Mobile Number"
                                            },
                                            {
                                                key: "action",
                                                label: "Action",
                                                formatter: (rowData, callback) => {
                                                    return (
                                                        <div>
                                                            <span><FaTrash onClick={() => handleStudentDeleteBtnClick(rowData.id)} style={{ color: '#6C63FF', cursor: 'pointer' }} /></span>
                                                            {/* <span className="pl-2"><FaEdit onClick={() => actionStudentBtnClicked("edit",rowData)} style={{ cursor: 'pointer'}}/></span> */}
                                                        </div>
                                                    );
                                                }
                                            }

                                        ]}
                                        payload={studentDetailsPayload}
                                        isSearchRequired={false}
                                        noDataContentText="No Student data Found"
                                        limit={10}
                                        tableUniqueId="userDetailsTable"
                                        paginationRequired={true}
                                        paginationType="client"
                                        callback={studentDetailsTableHandler}
                                    />
                                </div>
                            </div>
                            :
                            <div className="col-md-12 tab-data">
                                <div className="col-md-12 counsellor-details-container p-4">
                                    <div className="py-1 col-md-2 d-flex justify-content-center add-user-btn" onClick={() => addCouncellorBtnClicked()}>Add counsellor</div>
                                    <TableComponent
                                        headerArray={[
                                            {
                                                key: "name",
                                                label: "Name"
                                            },
                                            {
                                                key: "email",
                                                label: "Email ID"
                                            },
                                            {
                                                key: "mobileNum",
                                                label: "Mobile Number"
                                            },
                                            {
                                                key: "action",
                                                label: "Action",
                                                formatter: (rowData, callback) => {
                                                    return (
                                                        <div className="d-flex justify-content-around">
                                                            <span><FaEye onClick={() => actionCounsellorBtnClicked("viewMore", rowData)} style={{ color: '#6C63FF', cursor: 'pointer' }} /></span>
                                                            <span><FaTrash onClick={() => handleStudentDeleteBtnClick(rowData.id)} style={{ color: '#6C63FF', cursor: 'pointer' }} /></span>
                                                            <span><FaEdit onClick={() => actionCounsellorBtnClicked("edit", rowData)} style={{ color: '#6C63FF', cursor: 'pointer' }} /></span>
                                                        </div>
                                                    );
                                                }
                                            }
                                        ]}
                                        payload={counsellorDetailsPayload}
                                        isSearchRequired={false}
                                        noDataContentText="No Counsellor Detail Found"
                                        limit={10}
                                        tableUniqueId="counsellorDetailsTable"
                                        paginationRequired={true}
                                        paginationType="client"
                                        callback={counsellorDetailsTableHandler}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <ModalComponent
                    isVisible={addCounsellorModalOpen}
                    mainHeading="Add Counsellor"
                    callback={addCounsellorModalHandler}
                >
                    <div id="addCounsellorContainer">
                        <div className="col-md-12 p-0 d-flex form-group">
                            <div className="col-md-6 p-0 d-flex justify-content-start form-group flex-column">
                                <Input type="text" autoComplete="new-password" style={{ width: '95%' }} className="text-box-style my-3 py-2 input_data mandatoryField" name="counsellorName" placeholder="Name" value={editState.counsellorName} onChange={handleChange} />
                                <div className="errorDesc"></div>
                            </div>
                            <div className="col-md-6 p-0 d-flex justify-content-end form-group">
                                <Input type="text" autoComplete="new-password" style={{ width: '95%' }} className="text-box-style my-3 py-2 input_data mandatoryField typeEmail" name="counsellorEmail" placeholder="Email" value={editState.counsellorEmail} onChange={handleChange} />
                                <div className="errorDesc"></div>
                            </div>
                        </div>
                        <div className="col-md-12 p-0 d-flex form-group">
                            <Input type="number" autoComplete="new-password" className="text-box-style my-3 py-2  input_data mandatoryField typeContactNum" name="counsellorMobileNum" placeholder="Mobile Number" value={editState.counsellorMobileNum} onChange={handleChange} />
                            <div className="errorDesc"></div>
                        </div>
                    </div>
                </ModalComponent>
                <ModalComponent
                    isVisible={editCounsellorModalOpen}
                    mainHeading="Update Counsellor Details"
                    callback={editCounsellorModalHandler}
                >
                    <div id="editCounsellorContainer">
                        <div className="col-md-12 p-0 d-flex form-group">
                            <div className="col-md-6 p-0 d-flex justify-content-start form-group">
                                <Input type="text" autoComplete="new-password" style={{ width: '95%' }} className="text-box-style my-3 py-2 input_data mandatoryField" name="counsellorName" placeholder="Name" value={editState.counsellorName} onChange={handleChange} />
                                <div className="errorDesc"></div>
                            </div>
                            <div className="col-md-6 p-0 d-flex justify-content-end form-group">
                                <Input type="text" autoComplete="new-password" style={{ width: '95%' }} className="text-box-style my-3 py-2 input_data mandatoryField typeEmail" name="counsellorEmail" placeholder="Email" value={editState.counsellorEmail} onChange={handleChange} />
                                <div className="errorDesc"></div>
                            </div>
                        </div>
                        <div className="col-md-12 p-0 d-flex form-group">
                            <Input type="text" autoComplete="new-password" className="text-box-style my-3 py-2  input_data mandatoryField typeNumber" name="counsellorMobileNum" placeholder="Mobile Number" value={editState.counsellorMobileNum} onChange={handleChange} />
                            <div className="errorDesc"></div>
                        </div>
                    </div>
                </ModalComponent>
                <SliderModal
                    isVisible={viewMoreSliderVisible}
                    uniqueModalReferenceId="viewMore"
                    mainHeading="Counsellor Details"
                    leftPartFlexValue={2}
                    rightPartFlexValue={1.5}
                    isResetButtonVisible={false}
                    isSubmitButtonVisible={false}
                    contentPartBackgroundColor="#f7f7f7"
                    cancelButtonText="Close"
                    callback={triggerCallBack}
                >
                    <div className="col-md-12">
                        <div className="col-md-12">
                            <div className="col-md-12 p-0">
                                <div className="col-md-12 row m-0 p-0 mb-2 mt-4">
                                    <div className="col-md-6 p-0">
                                        <div style={{ color: '#231F20', fontSize: 12, fontWeight: 500 }}>Name: <span style={{ fontSize: 14, fontWeight: 700 }}>{editState.counsellorName}</span></div>
                                    </div>
                                    <div className="col-md-6 p-0">
                                        <div style={{ color: '#231F20', fontSize: 12, fontWeight: 500 }}>Email : <span style={{ fontSize: 14, fontWeight: 700 }}>{editState.counsellorEmail}</span></div>
                                    </div>
                                </div>
                                <div className="col-md-12 row m-0 p-0 mb-2">
                                    <div className="col-md-6 p-0">
                                        <div style={{ color: '#231F20', fontSize: 12, fontWeight: 500 }}>Contact Number: <span style={{ fontSize: 14, fontWeight: 700 }}>{editState.counsellorMobileNum}</span></div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </SliderModal>
                <AlertModal
                    show={showUserDelete}
                    onHide={() => setShowUserDelete(false)}
                    title='Alert!'
                    message='Are you sure you want to delete this student?'
                    onCancel={() => setShowUserDelete(false)}
                    onConfirm={() => actionStudentBtnClicked("delete")}
                />
                <AlertModal
                    show={showCouncellorDelete}
                    onHide={() => setShowCouncellorDelete(false)}
                    title='Alert!'
                    message='Are you sure you want to delete this councellor?'
                    onCancel={() => setShowCouncellorDelete(false)}
                    onConfirm={() => actionCounsellorBtnClicked("delete")}
                />

            </div>
            <ToastContainer />
        </div>
    )
}

export default UsersScreen;