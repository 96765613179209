import React, { forwardRef, useImperativeHandle } from 'react'
import {
    FaMailBulk,
    FaPhone,
    FaAddressCard,
    FaLinkedinIn,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
    professionaltitle,
    phonenumber,
    profiledescription,
    emailadd,
    portfolioaddress,
    linkedinusername,
    EducationDetials,
    languages,
    skills,
    experienceDetails,
    basicDetailsLabel,
    educationLable,
    experienceLabel,
    languageLabel,
    skillsLabel,
} from "../../stateManager/reducers/portfolioDetailsReducer";

const Template1 = forwardRef((props, ref) => {
    const professionaltitlefromredux = useSelector(professionaltitle);
    const phfromredux = useSelector(phonenumber);
    const profiledescfromredux = useSelector(profiledescription);
    const emailaddfromredux = useSelector(emailadd);
    const portfolioaddfromredux = useSelector(portfolioaddress);
    const linkedinunfromredux = useSelector(linkedinusername);
    const languageDetailsfromredux = useSelector(languages);
    const experienceDetailsfromredux = useSelector(experienceDetails);
    const educationDetailsfromredux = useSelector(EducationDetials);
    const skillsDetailsfromredux = useSelector(skills);
    const basicDetailsLabelfromredux = useSelector(basicDetailsLabel);
    const EduDetailsLabelfromredux = useSelector(educationLable);
    const ExpDetailsLabelfromredux = useSelector(experienceLabel);
    const LangDetailsLabelfromredux = useSelector(languageLabel);
    const skillsDetailsLabelfromredux = useSelector(skillsLabel);

    //   useImperativeHandle(ref, () => ({
    //     handleSaveToPDF(event) {
    //         event.preventDefault();
    //         window.print();
    //     },
    //   }))

    const handleSaveToPDF = (event) => {
        event.preventDefault();
        window.print();
    };
    var languagess = languageDetailsfromredux.split(',');
    var skillss = skillsDetailsfromredux.split(',');
    var langList;
    var skillList;
    return (
        <div style={{ backgroundColor: "white", padding: 10,width:"210mm", height: "297mm" }}>

            <div style={{ textTransform: "uppercase", textAlign: "center", padding: 10, fontSize: 40 }}>{JSON.parse(localStorage.getItem("userData")).username}</div>
            <div style={{ backgroundColor: "#F5F5F5", height: 2, marginRight: 50, marginLeft: 50 }}></div>
            <div style={{ textTransform: "uppercase", textAlign: "center", padding: 5 }}>{professionaltitlefromredux}</div>
            <div style={{ backgroundColor: "#F5F5F5", height: 2, marginRight: 50, marginLeft: 50 }}></div>

            <div className='col-md-12 d-flex p-4'>
                <div className='col-md-3 p-0 m-0' >
                    <div className='pb-2' style={{ textTransform: "uppercase" }}>contact</div>
                    <div className='d-flex col-md-12 p-0 m-0' style={{ alignItems: "center" }}>
                        <div className='col-md-2 p-0 m-0'>
                            <FaPhone style={{ fontSize: 12 }} />
                        </div>
                        <div className='col-md-10 p-0 m-0' style={{ fontSize: 8, wordBreak: "break-all" }}>{phfromredux}</div>
                    </div>
                    <div className='d-flex col-md-12 p-0 m-0' style={{ alignItems: "center" }}>
                        <div className='col-md-2 p-0 m-0'>
                            <FaMailBulk style={{ fontSize: 12 }} />
                        </div>
                        <div className='col-md-10 p-0 m-0' style={{ fontSize: 8, wordBreak: "break-all" }}>{emailaddfromredux}</div>
                    </div>

                    <div className='d-flex col-md-12 p-0 m-0' style={{ alignItems: "center" }}>
                        <div className='col-md-2 p-0 m-0'>
                            <FaAddressCard style={{ fontSize: 12 }} />
                        </div>
                        <div className='col-md-10 p-0 m-0' style={{ fontSize: 8, wordBreak: "break-all" }}>{portfolioaddfromredux}</div>
                    </div>

                    <div className='d-flex col-md-12 p-0 m-0' style={{ alignItems: "center" }}>
                        <div className='col-md-2 p-0 m-0'>
                            <FaLinkedinIn style={{ fontSize: 12 }} />
                        </div>
                        <div className='col-md-10 p-0 m-0' style={{ fontSize: 8, wordBreak: "break-all" }}>{linkedinunfromredux}</div>
                    </div>
                </div>
                <div style={{ backgroundColor: "#F5F5F5", width: 2, marginRight: 10, marginLeft: 10 }}></div>
                <div className='col-md-8'>
                    <div className='pb-2' style={{ textTransform: "uppercase" }}>{basicDetailsLabel}</div>
                    <div style={{ fontSize: 10, }}>
                        {profiledescfromredux}
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: "#F5F5F5", height: 2, marginRight: 50, marginLeft: 50 }}></div>

            <div className='col-md-12 d-flex p-4'>
                <div className='col-md-3 p-0 m-0' >
                    <div className='pb-2' style={{ textTransform: "uppercase" }}>{EduDetailsLabelfromredux}</div>
                    <div className='pt-2' style={{ alignItems: "center" }}>
                        {educationDetailsfromredux.map(eduDetails => {
                            return (
                                <div>
                                    <div style={{ textTransform: "uppercase", fontSize: 10, fontWeight: "800" }}>{eduDetails.educationDetailsCourse}</div>
                                    <div style={{ fontSize: 8, }}>{eduDetails.educationDetailsUniversity}</div>
                                    <div style={{ fontSize: 8, }}>{eduDetails.educationYear}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div style={{ backgroundColor: "#F5F5F5", height: 2, margin: 10, marginLeft: 0, marginRight: 30 }}></div>

                    <div style={{ textTransform: "uppercase" }}>{skillsDetailsLabelfromredux}</div>
                    <div>
                        <ul>
                            {skillList = skillss.map((skill) =>
                                <li>{skill}</li>
                            )}
                        </ul>
                    </div>
                </div>
                <div style={{ backgroundColor: "#F5F5F5", width: 2, marginRight: 10, marginLeft: 10 }}></div>
                <div className='col-md-8'>
                    <div className='pb-2' style={{ textTransform: "uppercase" }}>{ExpDetailsLabelfromredux}</div>
                    <div style={{ fontSize: 10, }}>
                        {experienceDetailsfromredux.map(expDetails => {
                            return (
                                <div>
                                    <b className='mt-5'>{expDetails.experienceProjectName} -{expDetails.experienceStartDate} {expDetails.experienceEndDate}</b><br></br>
                                    <div><b><i>{expDetails.experiencePosition}</i></b>
                                    </div>
                                    <div>
                                        {expDetails.experienceDescription}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>


        </div>
    )
});

export default Template1;
