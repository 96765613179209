import React, { useEffect, useState, useRef, memo } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const ProgressCirclePart = (deadline) => {
  const [durationToInputIntoTimerState, setdurationToInputIntoTimerState] =
    useState(0);
  const [
    initialRemainingTimeToInputIntoTimerState,
    setinitialRemainingTimeToInputIntoTimerState,
  ] = useState();
  const [key, setKey] = useState(0);

  var durationToInputIntoTimer = 0;
  var initialRemainingTimeToInputIntoTimer = 0;

  useEffect(() => {
    setKey(deadline.deadline);
    if (deadline.deadline) {
      var deadlineDate = new Date(
        deadline.deadline.split("-")[0],
        deadline.deadline.split("-")[1] - 1,
        deadline.deadline.split("-")[2]
      );
      var deadlineDateSeconds = deadlineDate.getTime();
      var startDateForRef = new Date(2022, 3, 24, 10, 33, 30, 0);
      var startDateForRefInSeconds = startDateForRef.getTime();
      var currentDateForRef = new Date();
      var currentDateForRefInSeconds = currentDateForRef.getTime();

      durationToInputIntoTimer =
        (deadlineDateSeconds - startDateForRefInSeconds) / 1000; // converting into seconds from milliseconds
      initialRemainingTimeToInputIntoTimer =
        (deadlineDateSeconds - currentDateForRefInSeconds) / 1000; // converting into seconds from milliseconds
      if (initialRemainingTimeToInputIntoTimer < 0) {
        initialRemainingTimeToInputIntoTimer = 0;
      }

      durationToInputIntoTimer = Math.round(durationToInputIntoTimer);
      initialRemainingTimeToInputIntoTimer = Math.round(
        initialRemainingTimeToInputIntoTimer
      );
      setdurationToInputIntoTimerState(durationToInputIntoTimer);
      setinitialRemainingTimeToInputIntoTimerState(
        initialRemainingTimeToInputIntoTimer
      );
    } else {
      setdurationToInputIntoTimerState(0);
      setinitialRemainingTimeToInputIntoTimerState();
    }
  }, [deadline]);

  const timerProps = {
    isPlaying: true,
    size: 120,
    strokeWidth: 6,
    updateInterval: 1,
  };
  const renderTime = (dimension) => {
    var days = Math.floor(
      initialRemainingTimeToInputIntoTimerState / (3600 * 24)
    );
    var hours = Math.floor(initialRemainingTimeToInputIntoTimerState / 3600);
    var minutes = Math.floor(
      (initialRemainingTimeToInputIntoTimerState % 3600) / 60
    );
    if (initialRemainingTimeToInputIntoTimerState <= 0) {
      return (
        <div className="time-wrapper">
          <div className="timer">
            <div className="text" style={{ color: "red" }}>
              Time Is Over
            </div>
          </div>
        </div>
      );
    } else if (
      typeof initialRemainingTimeToInputIntoTimerState == "undefined"
    ) {
      return (
        <div className="time-wrapper" style={{ color: "#6C62F6" }}>
          <div className="timer">
            <div className="text " style={{ color: "#6C62F6" }}>
              Select Collage
            </div>
          </div>
        </div>
      );
    } 
    else if (days<1) {
      return (
        <div className="time-wrapper ml-2">
        <div className="timer">
          <div className="hoursvalue ml-4">
            <h3>
              <b>{hours}</b>
            </h3>
            <></>
          </div>
          <div className="text">Hours Left</div>
          {/* <div className="text1">Left</div> */}
        </div>
      </div>
      );
    }else {
      return (
        <div className="time-wrapper ml-2">
          <div className="timer">
            <div className="hoursvalue ml-4">
              <h3>
                <b>{days}</b>
              </h3>
              <></>
            </div>
            <div className="text">Days Left</div>
            {/* <div className="text1">Left</div> */}
          </div>
        </div>
      );
    }
  };
  return (
    <div>
      <CountdownCircleTimer
        {...timerProps}
        key={key}
        isPlaying
        initialRemainingTime={initialRemainingTimeToInputIntoTimerState}
        duration={durationToInputIntoTimerState}
        colors={["#6C62F6", "#F7B801", "#A30000", "#A30000"]}
        // colorsTime={[7, 5, 2, 0]}
      >
        {({ remainingTime, color }) => (
          <span style={{ color }}>{renderTime()}</span>
        )}
      </CountdownCircleTimer>
    </div>
  );
};

export default memo(ProgressCirclePart);
