import React, {useState, useEffect} from 'react'
import axios from "axios";
import { MW_URL } from "../../../config";
import BlankProfile from "../../../imageAssets/profilepic.png"


const StudentProfile = ({ studentId, className }) => {
    const [profileImage, setProfileImage] = useState('');

    useEffect(() => {
      // Make an API request to retrieve the profile image URL for the given student ID
      axios.post(`${MW_URL}` + "getStudentProfileDetails", {senderId : studentId})
        .then((getResponse) => {
            console.log(getResponse?.data[0]?.profileImage, "getprofileimage")
            setProfileImage(getResponse?.data[0]?.profileImage)
        })
        .catch(error => {
          console.error('Error fetching profile image:', error);
        });
    }, [studentId]);

    return (
        <img className={className} src={profileImage || BlankProfile} alt='profileImg' />
    );
  };
export default StudentProfile